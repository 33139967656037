/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["WORKFLOW_UNSUPERVISED_CLASSIFICATION_LABEL"] = "Create unsupervised classification   "
Blockly.Msg["WORKFLOW_UNSUPERVISED_CLASSIFICATION_ALGORITHM_LABEL"] = "Select algorithm"
Blockly.Msg["WORKFLOW_UNSUPERVISED_CLASSIFICATION_CLASSES_LABEL"] = "Number of classes"
Blockly.Msg["WORKFLOW_UNSUPERVISED_CLASSIFICATION_PARAMS_LABEL"] = "Change parameters"
Blockly.Msg["WORKFLOW_UNSUPERVISED_CLASSIFICATION_INPUT_LABEL"] = "Input data"
Blockly.Msg["WORKFLOW_UNSUPERVISED_CLASSIFICATION_OUTPUT_LABEL"] = "Output"

export default Blockly.Msg;