<template>
    <div>
        <component 
            :is="variableComponent"
            :varType="variable.type"
            :variable="variable"
            :is-new="isNew"
            :are-warnings="areWarnings"
            @save-variable="handleVariableSaved"
            @edit-variable="handleEditVariable"
            @cancel-editing="handleEditCancel"
            />
    </div>
</template>

<script>

import DateRangeVariableExplorer from '@/components/Variables/DateRangeVariable/DateRangeVariableExplorer.vue'
import DateRangeVariableCreator from '@/components/Variables/DateRangeVariable/DateRangeVariableCreator.vue'
import {VARIABLE_NEW_PREFIX} from '@/constants/nextGenConstants';
import { range } from 'lodash';
export default {
    name: 'DateRangeVariable',
    components: {
        DateRangeVariableExplorer,
        DateRangeVariableCreator
    },
    emits: [
        'save-variable',
        'edit-variable',
        'cancel-editing'
    ],
    props: {
        variable: {
            type: Object,
            required: false,
            default: () => {
                return {
                    type: 'date range',
                    description: null,
                    id: VARIABLE_NEW_PREFIX,
                    title: undefined,
                    value: [
                        null,
                        null
                    ],
                    available_years: range(1950, 2101),
                    cadence: null,
                    extent_dates: []
                }
            },
        },
        isExplorer: {
            type: Boolean,
            required: false
        }, 
        areWarnings: { 
            type: Boolean, 
            required: false 
        },
        isNew: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    computed: {
        variableComponent() {
            if (this.isNew) {
                return 'DateRangeVariableCreator'
            } else {
                return this.variable.vue_component
            }
        }
    },
    methods: {
        handleVariableSaved(variable) {
            if(this.isNew === false) {
                variable.id = this.variable.id;
            }
            this.$emit('save-variable', variable, 'date range');
        },
        handleEditVariable() {
            this.$emit('edit-variable');
            this.isEditingVariable = true;
        },
        handleEditCancel() {
            this.$emit('cancel-editing');
            this.isEditingVariable = false;
        }
    }
}
</script>