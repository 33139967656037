<template>
    <div class="alert" :class="alertClass" >
        <div class="alert--icon">
            <md-icon>{{displayedIcon}}</md-icon>
        </div>
        <div class="alert--content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        theme: {
            type: String,
            default: 'info'
        },
        icon: {
            type: String,
            default: null
        }
    },
    computed: {
        alertClass() {
            return 'alert--theme-' + this.theme 
        },
        displayedIcon() {
            if(this.icon !== null) {
                return this.icon
            }
            switch(this.theme) {
                case 'info':
                    return 'info';
                case 'warning':
                    return 'warning'
                case 'danger':
                    return 'dangerous'
                default: 
                    return 'info'
            }
        }
    }
}
</script>