<template>
<div>
<h1>Auth0 Authentication Test</h1>

<!-- <md-field v-if="!user">
    <label for="accessType">full</label>
    <md-input id="accessType" ref="accessType" model="accessType" />
    <md-button class="md-raised" @click="auth0signup">Sign Up for an EBX Account</md-button>
    <md-button class="md-raised" @click="auth0signin">Log in</md-button>
</md-field> -->

    <md-button class="md-raised" @click="auth0signin">Log in or Sign Up</md-button>
    <md-button class="md-raised" @click="auth0signout">Sign Out</md-button>
</div>
</template>

<script>
import auth0js from 'auth0-js';
import * as authConstants from "@/constants/authConstants";
import { AuthService } from "@/services/auth.service";

export default {
    name: "StaticSignIn",
    data: () => ({
        auth0: null,
        webAuth: null,
        authenticatedUser: null,
        user: null,
        accessType: null
    }),
    created: function() {
        console.log("StaticSignIn:created");
        this.subscription = AuthService.loggedUser$.subscribe(user => {
            this.authenticatedUser = user;
        })

        // Initialise Auth0
        // See params at https://auth0.github.io/auth0.js/index.html#auth0webauth
        this.auth0 = new auth0js.Authentication({
            domain: authConstants.AUTH0_DOMAIN,
            clientID: authConstants.AUTH0_CLIENT_ID,
            redirectUri: authConstants.AUTH0_REDIRECT_URI,
            //audience: authConstants.AUTH0_API_AUDIENCE,
            //audience: authConstants.AUTH0_USER_INFO_API,
            response_type: 'code',
            prompt: 'login',
            scope: 'openid profile'
        });
        this.webAuth = new auth0js.WebAuth({
            domain: authConstants.AUTH0_DOMAIN,
            clientID: authConstants.AUTH0_CLIENT_ID,
            redirectUri: authConstants.AUTH0_REDIRECT_URI,
            response_type: 'code',
            prompt: 'login',
            scope: 'openid profile'
        });
    },
    methods: {

        auth0signin() {            
            let authUrl = this.auth0.buildAuthorizeUrl({
                domain: authConstants.AUTH0_DOMAIN,
                clientID: authConstants.AUTH0_CLIENT_ID,
                redirectUri: authConstants.AUTH0_REDIRECT_URI,
                responseType: 'code', // token id_token
                responseMode: 'query',
                state: 'YOUR_STATE',
                nonce: 'YOUR_NONCE'
            });
            window.location.href = authUrl;

            // this.webAuth.authorize({
            //     responseType: 'token id_token'
            // })
        },
        auth0signout() {
            AuthService.signOut();
            this.webAuth.logout({
                returnTo: location.host,
                client_id: authConstants.AUTH0_CLIENT_ID
            });
        },
    }
}

</script>