<template>
    <div v-if="!loading" class="nicfi">
        <div class="nicfi--date-selection">
            <md-field class="field-dropdown-button">
                <md-select v-model="selectedOption" class="field-dropdown-select" md-dense>
                    <md-option v-for="dateOption in mosaicOptionsReversed" :key="dateOption.value" :value="dateOption.value" class="field-dropdown-option">
                        {{dateOption.label}}
                    </md-option>
                </md-select>
            </md-field>
        </div>
        <div class="nicfi--timeline" v-if="timelineOptions.length">
            <EbxTimeline :navigation="true" :options="timelineOptions" :maxPointsShown="4" :modelValue="timelineSelection" @update:modelValue="setSelectedOption"/>
        </div>
        <div class="nicfi--attribution">
            <div class="nicfi--attribution-container">
                <a class="ebx-tertiary" href="https://www.planet.com/nicfi/" target="_blank" title="NICFI Program">NICFI Program<span class="material-icons-outlined ebx-icon">open_in_new</span></a>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import EbxTimeline from '@/components/EbxComponents/EbxTimeline.vue'

export default {
    props: {
        options: {
            type: Object,
            required: true
        },
        map: {
            type: Object,
            required: true
        },
    },
    emits: [
        'shown',
        'change'
    ],
    components: {
        EbxTimeline,
    },
    data() {
        return {
            selectionOptions: [],
            loading: true,
            userSelectedYear: null,
            userSelectedMonth: null,
            timelineSelection: 0
        }
    },
    async mounted() {
        try {
            this.loading = true
            await this.loadMosaicPage(0)
            this.$nextTick(() => {
                this.$emit('shown')
            })
        } catch (error) {
            console.error(error)
        } finally {
            this.loading = false
        }
    },
    methods: {
        requestMosaics(page) {
            const apiKey = this.options.key
            const basemapOptions = this.options
            return this.options.requestMosaics(apiKey, basemapOptions, page)
        },
        async loadMosaicPage(page) {
            const data = await this.requestMosaics(page)
            this.handleMosaicsResponse(data)
            if(data.mosaics.length >= this.options.pageSize) {
                return await this.loadMosaicPage(page + 1)
            }
            return data
        },
        handleMosaicsResponse(data) {
            this.selectionOptions = this.selectionOptions.concat(data.mosaics)
        },
        getSelectedMonthAndYearFromSelectedName() {
            const mosaic = this.mosaicByName[this.options.selectedName]
            if(mosaic) {
                const firstAcquired = moment(mosaic.first_acquired)
                return {
                    year: parseInt(firstAcquired.format('YYYY')),
                    month: parseInt(firstAcquired.format('M'))
                }
            }
            return {
                year: null,
                month: null
            }
        },
        findBestSelectedMosaic() {
            const selectedYear = this.userSelectedYear
            const selectedMonth = this.userSelectedMonth
            if(selectedYear && selectedMonth) {
                const selectedDate = moment(`${selectedYear}-${selectedMonth}`, 'YYYY-M')
                const selectedMosaic = this.selectionOptions.find(mosaic => {
                    const firstAcquired = moment(mosaic.first_acquired)
                    const lastAcquired = moment(mosaic.last_acquired).subtract(1,'day')
                    return selectedDate.isBetween(firstAcquired, lastAcquired, 'month', '[]')
                })
                if(selectedMosaic) {
                    return selectedMosaic
                }
            }
            return this.selectionOptions[0]
        },
        setSelectedOption(newValue) {
            // find the id of the timelineSelection index
            this.timelineSelection = newValue
            const timelineSelectionValue = this.timelineOptions[this.timelineSelection].value
            if (timelineSelectionValue && (this.selectedOption !== timelineSelectionValue)) {
                this.selectedOption = timelineSelectionValue
            }
        },
        formatDateName(dateString) {
            // the dateString can be of the type "Sep YYYY" or "Sep YYYY - Oct YYYY"
            // return only the months as either "Sep" or "Sep - Oct"
            let dateName = dateString
            const dateParts = dateString.split(' ')
            if(dateParts.length === 2) {
                dateName = dateParts[0]
            } else if(dateParts.length === 5) {
                dateName = `${dateParts[0]}-${dateParts[3]}`
            }
            return dateName
        }
    },
    watch: {
        selectedOption(newValue) {
            const newIndex = this.timelineOptions.findIndex(mosaic => mosaic.value === newValue)
            if (newIndex !== this.timelineSelection) {
                this.timelineSelection = newIndex
            }
            return newValue
        }
    },
    computed: {
        selectedOption: {
            get() {
                return this.mosaicsNametoId[this.options.selectedName]
            },
            set(value) {
                const name = this.mosaicsIdtoName[value]
                this.$emit('change', name) 
            }
        },
        mosaicOptions() {
            return this.selectionOptions.map(mosaic => {
                const firstDate = moment(mosaic.first_acquired).format('MMM YYYY');
                const lastDate = moment(mosaic.last_acquired).subtract(1,'day').format('MMM YYYY')
                const label = firstDate === lastDate ? firstDate : `${firstDate} - ${lastDate}`
                return {
                    value: mosaic.id,
                    label: label
                }
            })
        },
        mosaicOptionsReversed() {
            const mosasicOptions = this.mosaicOptions
            return mosasicOptions.reverse()
        },
        timelineOptions() {
            // a version of mosaicOptions with an additional "name" property
            const mosaicOptions = this.mosaicOptions
            return mosaicOptions.map(mosaic => {
                return {
                    ...mosaic,
                    name: this.formatDateName(mosaic.label)
                }
            })
        },
        mosaicsIdtoName() {
            return this.selectionOptions.reduce((acc, mosaic) => {
                acc[mosaic.id] = mosaic.name
                return acc
            }, {})
        },
        mosaicsNametoId() {
            return this.selectionOptions.reduce((acc, mosaic) => {
                acc[mosaic.name] = mosaic.id
                return acc
            }, {})
        }
    }

}
</script>