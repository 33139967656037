import {usersCollection, organisationsCollection} from '@/firebase.js'
import { ManageDatasetApi, ApiError } from '@/services/manageDatasetApi.service'
import { findIndex } from 'lodash'


const manageDatasetApi = new ManageDatasetApi();

const state = {
    userDatasets: [],
    displayDatasetId: null,
    userDatasetsMetadata: [],
    orgDatasetsMetadata: [],
    queryUserMetadataSnapshot: null,
    queryOrgMetadataSnapshot: null,
    queryUserDatasetsSnapshot: null,
    queryOrgDatasetsSnapshot: null,
    datasetImages: {},
    saveState: {},
    userDatasetsMetadataLoading: false,
    orgDatasetsMetadataLoading: false,
    userDatasetsLoading: false,
}

const getters = {
    hasDatasetImages: (state) => (datasetId) => { 
        return state.datasetImages[datasetId] !== undefined;
    },
    getFirstDatasetImage: (state) => (datasetId) => {
        return state.datasetImages[datasetId] !== undefined ? state.datasetImages[datasetId][0] : null;
    },
    getFormState: (state) => (key) => {
        return state.saveState[key] !== undefined ? state.saveState[key] : {
            state: 'idle',
            error: null
        }
    },
    datasetExists: (state) => (id) => {
        return state.userDatasets.some(dataset => dataset.id === id);
    },
    getDatasetLocation: (state) => (id) => {
        const userDataset = state.userDatasetsMetadata.find(dataset => dataset.id === id);
        if (userDataset) {
            return 'user';
        }
        const orgDataset = state.orgDatasetsMetadata.find(dataset => dataset.id === id);
        if (orgDataset) {
            return 'org';
        }

        return null
    },
    canEditDataset: (state) => (id, uid) => {
        const userDataset = state.userDatasetsMetadata.find(dataset => dataset.id === id);
        if (userDataset) {
            return true;
        }
        const orgDataset = state.orgDatasetsMetadata.find(dataset => dataset.id === id);
        if (orgDataset && orgDataset.permission !== 'protected' && (orgDataset.permission === 'editable' || orgDataset.user_id === uid)) {
            return true;
        }
        return false
    },
    getDisplayDataset: (state) => (id) => {
        return state.userDatasets.find(dataset => dataset.id === id);
    },
    datasetsLoading: (state) => {
        return state.userDatasetsLoading || state.userDatasetsMetadataLoading || state.orgDatasetsMetadataLoading;
    }
}

const mutations = {
    setUserDatasets(state, userDatasets) {
        state.userDatasets = userDatasets;
    },
    upsertUserDataset(state, userDataset) {
        const datasetIndex = state.userDatasets.findIndex(dataset => dataset.id === userDataset.id);
        if (datasetIndex > -1) {
            state.userDatasets[datasetIndex] = userDataset;
        } else {
            state.userDatasets.push(userDataset);
        }
    },
    appendUserDataset(state, userDataset) {
        state.userDatasets.push(userDataset);
    },
    setDisplayDatasetId(state, datasetId) {
        state.displayDatasetId = datasetId;
    },
    setUserDatasetsMetadata(state, userDatasetsMetadata) {
        state.userDatasetsMetadata = userDatasetsMetadata;
    },
    setOrgDatasetsMetadata(state, orgDatasetsMetadata) {
        state.orgDatasetsMetadata = orgDatasetsMetadata;
    },
    setUserMetadataSnapshot(state, snapshot) {
        state.queryUserMetadataSnapshot = snapshot;
    },
    setOrgMetadataSnapshot(state, snapshot) {
        state.queryOrgMetadataSnapshot = snapshot;
    },
    setQueryUserDatasetsSnapshot(state, snapshot) {
        state.queryUserDatasetsSnapshot = snapshot;
    },
    setQueryOrgDatasetsSnapshot(state, snapshot) {
        state.queryOrgDatasetsSnapshot = snapshot;
    },
    setDatasetImages(state, {datasetId, images}) {
        state.datasetImages[datasetId] = images
    },
    setUserDatasetsMetadataLoading(state, loading) {
        state.userDatasetsMetadataLoading = loading;
    },
    setOrgDatasetsMetadataLoading(state, loading) {
        state.orgDatasetsMetadataLoading = loading;
    },
    setUserDatasetsLoading(state, loading) {
        state.userDatasetsLoading = loading;
    },

    resetSaveState(state, key) {
        if (key === null || key === undefined){
            state.saveState = {}
            return
        }
        state.saveState[key] = {
            state: 'idle',
            error: null
        }
    },
    setSaveState(storeState, {key, state, error, data}) {
        if (storeState.saveState[key] === undefined){
            storeState.saveState[key] = {
                state: 'idle',
                error: null
            }
        }
        if (state !== undefined){
            storeState.saveState[key].state = state
        }
        if (error !== undefined){
            storeState.saveState[key].error = error
        }
        if (data !== undefined) {
            storeState.saveState[key].data = data
        }
        
    },
    updateDatasetImage(state, {datasetId, imageId, imageData}) {
        if (state.datasetImages[datasetId]) {
            const idx = findIndex(state.datasetImages[datasetId], {id: imageId})
            if (idx !== -1) {
                state.datasetImages[datasetId][idx] = imageData
                return
            } else {
                state.datasetImages[datasetId].push(imageData)
            }  
        } else {
            state.datasetImages[datasetId] = [imageData]
        }
    },
    removeDataset(state, dataset) {
        // remove dataset from user datasets
        state.userDatasets = state.userDatasets.filter((userDataset) => {
            return userDataset.id !== dataset.id;
        });
        // remove from user datasets metadata
        state.userDatasetsMetadata = state.userDatasetsMetadata.filter((userDataset) => {
            return userDataset.id !== dataset.id;
        });
        // remove from org datasets metadata
        state.orgDatasetsMetadata = state.orgDatasetsMetadata.filter((orgDataset) => {
            return orgDataset.id !== dataset.id;
        });
    },
    unregisterSnapshots(state) {
        if (state.queryUserMetadataSnapshot !== null) {
            state.queryUserMetadataSnapshot();
            state.queryUserMetadataSnapshot = null;
        }
        if (state.queryOrgMetadataSnapshot !== null) {
            state.queryOrgMetadataSnapshot();
            state.queryOrgMetadataSnapshot = null;
        }
        if (state.queryUserDatasetsSnapshot !== null) {
            state.queryUserDatasetsSnapshot();
            state.queryUserDatasetsSnapshot = null;
        }
        if (state.queryOrgDatasetsSnapshot !== null) {
            state.queryOrgDatasetsSnapshot();
            state.queryOrgDatasetsSnapshot = null;
        }
    },
}

const actions = {
    async queryUserDatasets(context, {userId, orgId}) {
        const userSnapshotLoading = new Promise((resolve, reject) => {
            if (context.state.queryUserDatasetsSnapshot !== null) {
                return resolve()
            }
            const snapshot = usersCollection.doc(userId).collection('datasets').onSnapshot(docs => {
                docs.forEach(doc => {
                    context.commit('upsertUserDataset', doc.data())
                })
                resolve()
            }, reject)
            context.commit('setQueryUserDatasetsSnapshot', snapshot)
        });
        const orgSnapshotLoading = new Promise((resolve, reject) => {
            if (context.state.queryOrgDatasetsSnapshot !== null) {
                return resolve()
            }
            const snapshot = organisationsCollection.doc(orgId).collection('datasets').onSnapshot(docs => {
                docs.forEach(doc => {
                    context.commit('upsertUserDataset', doc.data())
                })
                resolve();
            }, reject)
            context.commit('setQueryOrgDatasetsSnapshot', snapshot)
        });
        try {
            context.commit('setUserDatasetsLoading', true);
            await Promise.all([userSnapshotLoading, orgSnapshotLoading])
        } finally {
            context.commit('setUserDatasetsLoading', false);
        }
    },
    async queryUserDataset(context, {userId, orgId, datasetId}) {
        if(context.getters.datasetExists(datasetId)) {
            return
        }
        const userDatasetSnapshot = await usersCollection.doc(userId).collection('datasets').doc(datasetId).get();
        if (userDatasetSnapshot.exists) {
            context.commit('upsertUserDataset',userDatasetSnapshot.data());
        } else {
            const orgDatasetSnapshot = await organisationsCollection.doc(orgId).collection('datasets').doc(datasetId).get();
            if (orgDatasetSnapshot.exists) {
                context.commit('upsertUserDataset',orgDatasetSnapshot.data());
            }
        }
    },
    queryUserDatasetsMetadata(context, userId) {
        return new Promise((resolve, reject) => {
            if(context.state.queryUserMetadataSnapshot !== null) {
                return resolve();
            }
            context.commit('setUserDatasetsMetadataLoading', true);
            const snapshot = usersCollection.doc(userId).collection('metadata').doc('datasets').onSnapshot((doc) => {
                if(doc.exists && doc.data().stac_dataset_ids) {
                    let userDatasetsMetadata = [];
                    const metadataMap = doc.data().stac_dataset_ids;
                    // iterate over object and put the entries into an array
                    for (const entry in metadataMap) {
                        userDatasetsMetadata.push({...metadataMap[entry], id: entry, location: 'user'});
                    }
                    context.commit('setUserDatasetsMetadata', userDatasetsMetadata);
                }
                context.commit('setUserDatasetsMetadataLoading', false);
                resolve();
            }, (err) =>{
                context.commit('setUserDatasetsMetadataLoading', false);
                reject(err)
            });
            context.commit('setUserMetadataSnapshot', snapshot);
        });
        
    },
    queryOrgDatasetsMetadata(context, orgId) {
        return new Promise((resolve, reject) => {
            if(context.state.queryOrgMetadataSnapshot !== null) {
                return resolve();
            }
            context.commit('setOrgDatasetsMetadataLoading', true);
            const snapshot = organisationsCollection.doc(orgId).collection('metadata').doc('datasets').onSnapshot((doc) => {
                if(doc.exists && doc.data().stac_dataset_ids) {
                    let orgDatasetsMetadata = [];
                    const metadataMap = doc.data().stac_dataset_ids;
                    // iterate over object and put the entries into an array
                    for (const entry in metadataMap) {
                        orgDatasetsMetadata.push({...metadataMap[entry], id: entry, location: 'org'});
                    }
                    context.commit('setOrgDatasetsMetadata', orgDatasetsMetadata);
                }
                context.commit('setOrgDatasetsMetadataLoading', false);
                resolve()
            }, (err) => {
                context.commit('setOrgDatasetsMetadataLoading', false);
                reject(err)
            });
            context.commit('setOrgMetadataSnapshot', snapshot);
        })
    },
    async loadDatasetImages(context, datasetId) {
        try {
            const images = await manageDatasetApi.getDatasetImages(datasetId)
            context.commit('setDatasetImages', {datasetId, images});
        } catch (error) {
            console.error(error);
        }
    },
    async removeDataset(context, dataset) {
        try {
            await manageDatasetApi.deleteDatasetById(dataset.id);
            context.commit('removeDataset', dataset);
        } catch (error) {
            console.error(error);
        }
    },
    async updateImageProperties(context, {datasetId, imageId, properties}) {
        try {
            context.commit('setSaveState', {key:'updateImageProperties', state: 'saving' })
            const imageData = await manageDatasetApi.updateDatasetImageByName(datasetId, imageId, {properties});
            context.commit('updateDatasetImage', {datasetId, imageId, imageData})
            context.commit('setSaveState', {key:'updateImageProperties', state: 'idle' })
            return imageData
        } catch (error) {
            if (error instanceof ApiError){
                context.commit('setSaveState', {key:'updateImageProperties', state: 'errored', error: error.getFirstMessage() })
            } else {
                console.error(error)
                context.commit('setSaveState', {key:'updateImageProperties', state: 'errored', error: 'Uknown Error Saving State' })
            }
        }
    },
    async updateImage(context, {datasetId, imageId, data}) {
        try {
            context.commit('setSaveState', {key:'updateImageProperties', state: 'saving' })
            const imageData = await manageDatasetApi.updateDatasetImageByName(datasetId, imageId, data);
            context.commit('updateDatasetImage', {datasetId, imageId, imageData, cadence: data.cadence})
            context.commit('setSaveState', {key:'updateImageProperties', state: 'idle' })
            return imageData
        } catch (error) {
            if (error instanceof ApiError){
                context.commit('setSaveState', {key:'updateImageProperties', state: 'errored', error: error.getFirstMessage() })
            } else {
                console.error(error)
                context.commit('setSaveState', {key:'updateImageProperties', state: 'errored', error: 'Uknown Error Saving State' })
            }
        }
    },
    async updateDatasetBandByName(context, {datasetId, bandName, data, queryParams}) {
        try {
            context.commit('setSaveState', {key:'updateBand', state: 'saving', data: { bandName, inputData: data } })
            const bandData = await manageDatasetApi.updateDatasetBandByName(datasetId, bandName, data, queryParams || {});
            context.commit('setSaveState', {key:'updateBand', state: 'idle', data: { bandName, inputData: data } })
            return bandData
        } catch (error) {
            if (error instanceof ApiError){
                context.commit('setSaveState', {key:'updateBand', state: 'errored', error: error.getFirstMessage(), data: { bandName, inputData: data, errors: error.getMessages() } })
            } else {
                console.error(error)
                context.commit('setSaveState', {key:'updateBand', state: 'errored', error: 'Uknown Error Saving State', data: { bandName, inputData: data } })
            }
        }
    },
    async updateDatasetProperties(context, {datasetId, properties}) {
        try {
            context.commit('setSaveState', {key:'updateDatasetProperties', state: 'saving' })
            await manageDatasetApi.updateDatasetById(datasetId, properties);
            context.commit('setSaveState', {key:'updateDatasetProperties', state: 'idle' })
        } catch (error) {
            if (error instanceof ApiError){
                context.commit('setSaveState', {key:'updateDatasetProperties', state: 'errored', error: error.getFirstMessage() })
            } else {
                console.error(error)
                context.commit('setSaveState', {key:'updateDatasetProperties', state: 'errored', error: 'Uknown Error Saving State' })
            }
        }
    },
    async moveDataset(context, {datasetId, permission}) {
        try {
            context.commit('setSaveState', {key:'moveDataset', state: 'saving' })
            await manageDatasetApi.moveDatasetById(datasetId, permission);
            context.commit('setSaveState', {key:'moveDataset', state: 'idle' })
        } catch (error) {
            if (error instanceof ApiError){
                context.commit('setSaveState', {key:'moveDataset', state: 'errored', error: error.getFirstMessage() })
            } else {
                console.error(error)
                context.commit('setSaveState', {key:'moveDataset', state: 'errored', error: 'Uknown Error Saving State' })
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};