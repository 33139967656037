<template>
    <div class="variable-detail variable-detail__explorer">
        <div class="variable-detail--container">
            <div :class="{'variable-detail--title--disabled' : areWarnings, 'variable-detail--title': !areWarnings }">
            <div class="ebx-primary-bold">{{variable.title}}</div>
            </div>
            <div :class="{'variable-detail--description--disabled': areWarnings}">
                <p class="ebx-secondary">{{variable.description}}</p>
            </div>
            <div class="variable-detail--variable-type">
                <!-- add a slot for variable type so we can add custom markup depending on variable type -->
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExplorerVariableDetail',
    props: {
        variable: {
            type: Object,
            required: true,
        },
        areWarnings: { 
            type: Boolean, 
            required: false
        }
    }
}
</script>