<template>
    <div>
    <md-dialog v-model:md-active="computedModalShown" class="blockly-modals">
        <md-dialog-title>
            <span>WekaKMeans Options</span>
        </md-dialog-title>

        <form>
        
            <md-field>
                <label for="minClusters">Min Number of Clusters</label>
                <md-input name="minClusters" v-model="formData.minClusters" type="number" :placeholder="minClusters ? minClusters.toString() : ''"></md-input>
            </md-field>
            <md-field>
                <label for="maxClusters">Max Number of Clusters</label>
                <md-input name="maxClusters" v-model="formData.maxClusters" type="number" :placeholder="maxClusters ? maxClusters.toString() : ''"></md-input>
            </md-field>
            <md-field>
                <label for="maxIterations">Maximum Iterations</label>
                <md-input name="maxIterations" v-model="formData.maxIterations" type="number" :placeholder="maxIterations ? maxIterations.toString() : ''"></md-input>
            </md-field>
            <md-field>
                <label for="maxKMeans">Maximum number of iterations in KMeans</label>
                <md-input name="maxKMeans" v-model="formData.maxKMeans" type="number" :placeholder="maxKMeans ? maxKMeans.toString() : ''"></md-input>
            </md-field>
            <md-field>
                <label for="maxForChildren">maximum number for children iterations in KMeans</label>
                <md-input name="maxForChildren" v-model="formData.maxForChildren" type="number" :placeholder="maxForChildren ?maxForChildren.toString() : ''"></md-input>
            </md-field>
            <div>
                <md-switch name="useKD" v-model="formData.useKD">Use a KDTree</md-switch>
            </div>
            <md-field>
                <label for="cutoffFactor">Cutoff Factor</label>
                <md-input name="cutoffFactor" v-model="formData.cutoffFactor" type="number" :placeholder="cutoffFactor ? cutoffFactor.toString() : ''"></md-input>
            </md-field>
            <md-field>
                <label for="distanceFunction">Distance Function</label> 
                <md-select name="distanceFunction" v-model="formData.distanceFunction">
                    <md-option v-for="option in distanceOptions" :value="option[1]" :key="option[1]">
                        {{ option[0]}}
                    </md-option>
                </md-select>
            </md-field>
            <md-field>
                <label for="seed">Randomization Seed</label>
                <md-input name="seed" v-model="formData.seed" type="number" :placeholder="seed ? seed.toString() : ''"></md-input>
            </md-field>
        
           
        </form>

        <md-dialog-actions>
            <md-button class="md-primary" @click="computedModalShown = false">Close</md-button>
            <md-button class="md-primary" @click="saveModal">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
    </div>
</template>

<script>
import { globalEventBus } from '@/eventbus.js'
import blocklyModalsMixin from '../blocklyModalsMixin.js'

export default {
    name: 'UnsupervisedClassificationWekaKMeans',
    mixins: [blocklyModalsMixin],
    props: {
        distanceFunction: {
            type: String,
            default: 'Euclidean'
        },
        minClusters: {
            type: Number,
            default: 2
        },
        maxClusters: {
            type: Number,
            default: 8
        },
        maxIterations: {
            type: Number,
            default: 3
        },
        maxKMeans: {
            type: Number,
            default: 1000
        },
        maxForChildren: {
            type: Number,
            default: 1000
        },
        useKD: {
            type: Boolean,
            default: false
        },
        cutoffFactor: {
            type: Number,
            default: 0
        },
        seed: {
            type: Number,
            default: 10,
        },
        showModal: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            distanceOptions: [
                ['Chebyshev','Chebyshev'],
                ['Euclidean','Euclidean'],
                ['Manhattan','Manhattan']
            ],
            formData: {
                distanceFunction: this.distanceFunction,
                minClusters: this.minClusters,
                maxClusters: this.maxClusters,
                maxIterations: this.maxIterations,
                maxKMeans: this.maxKMeans,
                maxForChildren: this.maxForChildren,
                useKD: this.useKD,
                cutoffFactor: this.cutoffFactor,
                seed: this.seed
            }
        }
    },
    methods: {
        getFieldValue() {
            return {
                minClusters: this.formData.minClusters,
                maxClusters: this.formData.maxClusters,
                maxIterations: this.formData.maxIterations,
                maxKMeans: this.formData.maxKMeans,
                maxForChildren: this.formData.maxForChildren,
                useKD: this.formData.useKD,
                cutoffFactor: this.formData.cutoffFactor,
                distanceFunction: this.formData.distanceFunction,
                seed: this.formData.seed
            };
        },
        saveModal() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        },
    },
    watch: {
        showModal(val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if(val) {
                this.formData = {
                    distanceFunction: this.distanceFunction,
                    minClusters: this.minClusters,
                    maxClusters: this.maxClusters,
                    maxIterations: this.maxIterations,
                    maxKMeans: this.maxKMeans,
                    maxForChildren: this.maxForChildren,
                    useKD: this.useKD,
                    cutoffFactor: this.cutoffFactor,
                    seed: this.seed
                }
            }
        }
    }
}
</script>