import {AbstractAbility} from "./abstractAbility"
import { AreaService } from '../../services/area.service'
import { MapAreaCollection } from "./collections/MapAreaCollection"
class ProvidesMapArea extends AbstractAbility{
    constructor(state) {
        super(state)
        this.stateKey = 'map_areas'
    }

    getAreaIds() {
        const state = this.getState();
        if(state.areaId !== undefined) {
            return [state.areaId]
        }
        if(state.collectionId !== undefined) {
            return AreaService.getAreasForCollection(state.collectionId).map(area => area.id)
        }
        return []
    }

    static newCollection(abilites) {
        return new MapAreaCollection(abilites)
    }
}

export {ProvidesMapArea}