const state = {
    lastRunId: null,
    runCompleted: false
}

const getters = {}

const mutations = {
    setLastRunId(state, runId) {
        state.lastRunId = runId
    },
    setRunCompleted(state, completed) {
        state.runCompleted = completed
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};