<template>
    <div>
    <md-dialog v-model:md-active="computedModalShown" class="blockly-modals">
        <md-dialog-title>
            <span>WekaCobweb Options</span>
        </md-dialog-title>

        <form>
        
            <md-field>
                <label for="acuity">Acuity (minimum standard deviation)</label>
                <md-input name="acuity" v-model="formData.acuity" type="number" :placeholder="acuity ? acuity.toString() : ''"></md-input>
            </md-field>

            <md-field>
                <label for="cutoff">Cutoff (minimum category utility)</label>
                <md-input name="cutoff" v-model="formData.cutoff" type="number" :placeholder="cutoff ? cutoff.toString() : ''"></md-input>
            </md-field>

            <md-field>
                <label for="seed">Random number seed.</label>
                <md-input name="seed" v-model="formData.seed" type="number" :placeholder="seed ? seed.toString() : ''"></md-input>
            </md-field>
        </form>

        <md-dialog-actions>
            <md-button class="md-primary" @click="computedModalShown = false">Close</md-button>
            <md-button class="md-primary" @click="saveModal">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
    </div>
</template>

<script>
import { globalEventBus } from '@/eventbus.js'
import blocklyModalsMixin from '../blocklyModalsMixin.js'

export default {
    name: 'UnsupervisedClassificationWekaCobweb',
    mixins: [blocklyModalsMixin],
    props: {
        acuity: {
            type: Number,
            default: 1,
        },
        cutoff: {
            type: Number,
            default: 0.002,
        },
        seed: {
            type: Number,
            default: 42,
        },
        showModal: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            formData: {
                acuity: this.acuity,
                cutoff: this.cutoff,
                seed: this.seed
            }
        }
    },
    methods: {
        getFieldValue() {
            return {
                acuity: this.formData.acuity,
                cutoff: this.formData.cutoff,
                seed: this.formData.seed
            };
        },
        saveModal() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        }
    },
    watch: {
        showModal(val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if(val) {
                this.formData = {
                    acuity: this.acuity,
                    cutoff: this.cutoff,
                    seed: this.seed
                }
            }
        }
    }
}
</script>