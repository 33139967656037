/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import Blockly from "blockly";

/**
 * For a given ID, checks if the ID is in the registry scope.
 * @param {string} id - the id of the context menu option
 * @returns {boolean}
 */
function isIdInRegistryScope(id) {
    const registry = Blockly.ContextMenuRegistry.registry;

    const item = registry.getItem(id);

    if (item == null) {
        return false;
    }

    return true;
}

/**
 * Register a new context menu option for the workspace.
 * documentation: https://blocklycodelabs.dev/codelabs/context-menu-option/index.html?index=../..index#0
 */

function registerBlockContextMenuOptions() {
    const id = 'about_block';
    const aboutOption = {
        displayText: 'About this block',
        /**
         * The return value should be one of 'enabled', 'disabled', or 'hidden'.
         * An enabled option is shown with black text and is clickable. A disabled option is shown with grey text and is not clickable. A hidden option is not included in the context menu at all.
         * @param {*} scope 
         * @returns {string}
         */
        preconditionFn: function(scope) {
            // hide if the block isInFlyout, or no about_block_url is defined
            if (scope.block.isInFlyout || !scope.block.about_block_url) {
                return 'hidden';
            } else {
                return 'enabled';
            }
        },
        callback: function(scope) {
            let about_link = scope.block.about_block_url;
            // go to a link in a new tab
            window.open(about_link, '_blank');
        },
        scopeType: Blockly.ContextMenuRegistry.ScopeType.BLOCK,
        id: id,
        weight: 0, // The weight property is a number that determines the order of the items in the context menu. A higher number means your option will be lower in the list.
    };

    if (isIdInRegistryScope(id)) {
        console.warn(`Caution id ${id} is already in the registry scope, this may cause unexpected behaviour`);
    }

    Blockly.ContextMenuRegistry.registry.register(aboutOption);
}

/**
 * Registers a context menu, in which you can specify the text of the option, the callback function and the the scope type.
 * @param {string} displayText - the text that will be displayed in the context menu
 * @param {function} callback - receives one argument, the blockly scope, called on click
 * @param {function} preconditionFn - a method which takes a scope variable and returns either hidden or enabled, defaults to returning enabled
 * @param {string} scopeType - defaults to workspace scope
 * @param {string} id - the id of the context menu option
 * @param {number} weight - the weight of the context menu option, defaults to 0
 */
function registerBlocklyContextMenuOption(
    displayText = "", 
    callback, 
    preconditionFn=null, 
    scopeType=Blockly.ContextMenuRegistry.ScopeType.WORKSPACE, 
    id, 
    weight = 0
    ) {

    if (callback === undefined || callback === null) {
        throw new Error('callback is required for context menu option registration');
    }

    if (isIdInRegistryScope(id)) {
        console.warn(`Caution id ${id} is already in the registry scope, this may cause unexpected behaviour`);
    }

    const defaultPreconditionFn = () => {return 'enabled'};

    preconditionFn = preconditionFn || defaultPreconditionFn;

    const option = {
        displayText: displayText,
        preconditionFn: preconditionFn,
        callback: callback,
        scopeType: scopeType,
        id: id,
        weight: weight,
    };

    Blockly.ContextMenuRegistry.registry.register(option);
}


export { registerBlockContextMenuOptions, registerBlocklyContextMenuOption };