<template>
    <div>
        <div class="asset-card--item" :class="{'asset-card--item__action': isCreator}" v-for="(asset,i) in assetsList" :key="asset.id + '_' + asset.location + '_' + i">
            <div class="asset-card--item-body" @click="handleShowAsset(asset)">
                <div class="asset-card--item-title">
                    <div class="asset-card--thumbnail">
                        <img :src="getImageUrl(asset)" alt="Folder">
                    </div>
                    <div class="ebx-primary-bold">
                        {{ asset.title }}
                    </div>
                </div>
            </div>
            <div class="asset-card--item-actions">
                <md-menu>
                    <md-button class="md-icon-button" md-menu-trigger>
                        <md-icon>more_vert</md-icon>
                    </md-button>
                    <md-menu-content class="asset-options-menu">
                        <template v-if="userDatasetCanEdit(asset.id, user.uid)">

                            <template v-if="isCreator">
                                <md-menu-item class="asset-options-menu--option" @click="handleAssetToMap(asset)">
                                    <span class="material-icons-outlined ebx-icon">add</span>
                                    <span>Add to new project</span>
                                </md-menu-item>

                                <template v-if="canShareWorkflows">
                                    <md-menu-item v-if="userDatasetLocation(asset.id) === 'user'" class="asset-options-menu--option" @click="moveDataset(asset)">
                                        <span class="material-icons-outlined ebx-icon">drive_file_move</span>
                                        <span>Move to team</span>
                                    </md-menu-item>
                                    <md-menu-item v-if="userDatasetLocation(asset.id) === 'org' " class="asset-options-menu--option" @click="moveDataset(asset)">
                                        <span class="material-icons-outlined ebx-icon">drive_file_move</span>
                                        <span>Remove team access</span>
                                    </md-menu-item>
                                </template>

                                <md-menu-item class="asset-options-menu--option" @click="handleShowAsset(asset)">
                                    <span class="material-icons-outlined ebx-icon">mode</span>
                                    <span>Edit</span>
                                </md-menu-item>
                            </template>
                        
                            <md-menu-item class="asset-options-menu--option" @click="handleDeleteAsset(asset)">
                                <span class="material-icons-outlined ebx-icon">delete_outline</span>
                                <span>Delete</span>
                            </md-menu-item>

                        </template>
                        <template v-else>
                            <md-menu-item class="asset-options-menu--option" >
                                <md-menu md-size="small">
                                    <div md-menu-trigger>
                                        <span class="material-icons-outlined ebx-icon">add</span>
                                        <span>Add to new project</span>
                                    </div>
                                    <md-menu-content>
                                        <md-menu-item
                                            v-for="area in customAreas"
                                            :key="area.id"
                                            @click.stop="handleAssetToMap(asset, area)"
                                        >
                                            <md-icon>map_outline</md-icon>
                                            <span>{{ area.name }}</span>
                                        </md-menu-item>
                                        <md-menu-item key="null" @click.stop="handleAssetToMap(asset, null)">
                                            <md-icon>add</md-icon>
                                            <span>Create new area</span>
                                        </md-menu-item>
                                        <!-- <md-menu-item key="null" @click.stop="loadAssetClassesToMap(asset, null)">
                                            <md-icon>add</md-icon>
                                            <span>Create new areas</span>
                                        </md-menu-item> -->
                                    </md-menu-content>
                                </md-menu>
                            </md-menu-item>
                            <md-menu-item class="asset-options-menu--option" @click="$emit('asset-loaded')">
                                <span class="material-icons-outlined ebx-icon">mode</span>
                                <span>View</span>
                            </md-menu-item>
                        </template>
                        
                    </md-menu-content>
                </md-menu>
            </div>
        </div>

        <!-- Needed to render the above for some reason -->
        <span v-for="(asset, i) in assetsList" :key="'dummy_' + i + '_' + asset.id"></span>

        <md-dialog :md-active="showDialog" @md-closed="showDialog = false" class="dialog choose-area-dialog ebx-dialog">
            <md-dialog-title>
                <div class="top-actions--container">
                <p class="ebx-header-2 top-actions--title">
                    Add area
                </p>
            </div>
            </md-dialog-title>
            <md-dialog-content class="dialog-content">
                <md-checkbox class="ebx-checkbox mb-0" v-model="splitByCategory">
                    <div class="md-body-2">Categorise area by attribute</div>
                </md-checkbox>
                <md-field class="inset-dropdown" v-if="splitByCategory">
                    <md-select v-model="classProperty" name="classProperty" id="asset" :placeholder=placeholderText>
                        <md-option v-for="property in selectableProperties" :value="property" :key="property">{{property}}</md-option>
                    </md-select>
                </md-field>
                <div class="attribute-error" v-if="unselectedAttributeError">
                    <span class="ebx-secondary text-negative">{{AttributeErrorMessage}}</span>
                </div>
            </md-dialog-content>
            <md-dialog-actions>
                <button class="ebx-button ebx-button--secondary" :disabled="showProgressBar" @click="showDialog = false">
                    Cancel
                </button>
                <button v-if="showProgressBar === false" class="ebx-button ebx-button--primary" @click="chooseSelection" >
                    Add area
                </button>
                <md-button v-else-if="showProgressBar" class="ebx-button--spinner__disabled md-raised md-accent" :disabled="true">
                    <md-progress-spinner class="md-accent" md-mode="indeterminate" :md-diameter="20" :md-stroke="2"></md-progress-spinner>
                    Loading
                </md-button>
            </md-dialog-actions>
        </md-dialog>
        <md-snackbar
            :md-position="'center'"
            v-model:md-active="snackbarActive"
            :md-duration="10000"
            md-persistent
            >
            <span>{{ snackbarMessage }}</span>
        </md-snackbar>
        <confirmation-modal ref="modal" :hide-title="!showPermission" :ok-button-text="buttonText" :if-warning="false">
            <template v-slot:title>
                <div class="ebx-header-2">Permissions</div>
            </template>
            <div v-if="!showPermission">
                {{confirmMessage}}
            </div>
            <div v-if="showPermission">
                <md-radio v-model="movePermission" value="viewable" class="ebx-md-radio">Team members can only view</md-radio>
                <md-radio v-model="movePermission" value="editable" class="ebx-md-radio ebx-md-radio-editable">Team members can edit</md-radio>
            </div>
        </confirmation-modal>
    </div>  

</template>

<script>
import userDatasetsMixin from '@/components/mixins/userDatasetsMixin';
import { sharedWorkflowMixin } from '@/components/mixins/projectMixin.js'
import { AreaService } from "@/services/area.service";
import { functions } from "@/firebaseFunctions.js";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import assetsMixin from "@/components/mixins/assetsMixin.js" 

export default {
    name: "AssetsList",
    mixins: [
        userDatasetsMixin, 
        sharedWorkflowMixin,
        assetsMixin
    ],
    components: {
        ConfirmationModal
    },
    data() {
        return {
            activeAsset: null,
            customAreas: [],
            snackbarMessage: "",
            snackbarActive: false,
            selectableProperties: [],
            classProperty: null,
            selectedArea: null,
            selectedAsset: null,
            showDialog: false,
            loadedMeta: [],
            splitByCategory: false,
            showProgressBar: false,
            confirmMessage: '',
            movePermission: 'viewable',
            showPermission: true,
            buttonText: "Share", 
            unselectedAttributeError: false, 
            AttributeErrorMessage: ''
        }
    },
    props: {
        assetsList: {
            type: Array,
            required: true
        },
        user: {
            type: Object
        },
        isCreator: {
            type: Boolean,
            default: true
        }
    },
    emits: [
        'remove-asset',
        'asset-loaded'
    ],
    methods: {
        async handleDeleteAsset(asset) {
            const message = 'Are you sure you want to delete this asset'
            const confirmed = await this.$confirmModal(message ,{title: 'Delete Asset', okButtonText:'Delete'})
            if (confirmed) {
                this.$emit('remove-asset', asset)
                this.snackbarMessage = "Asset successfully deleted";
                this.snackbarActive = true;
            }
        },
        async moveDataset(asset) {
            
            if(this.userDatasetLocation(asset.id) === 'user') {
                this.buttonText = "Share"
                this.confirmMessage = `Are you sure you want to share this dataset with the team?`
                this.showPermission = true
                this.permission = 'viewable'
            } else {
                this.buttonText = "Remove access"
                this.confirmMessage = `Are you sure you want to remove team access to this dataset? Any workflows using this dataset in team projects, or your team’s private projects will no longer run.`
                this.showPermission = false
                this.permission = 'editable'
            }
            
            const confirmed = await this.$refs.modal.confirm();
            if (!confirmed) return;

            await this.$store.dispatch('userdatasets/moveDataset', {
                datasetId: asset.id,
                permission: this.movePermission
            })
        },
        async chooseSelection() {
            if (this.splitByCategory === true && this.classProperty !== undefined || this.splitByCategory === false) {
                try {
                    this.showProgressBar = true
                    await this.loadAssetToMap(this.selectedAsset, this.selectedArea, this.loadedMeta)
                    this.loadedMeta = {}
                    this.showDialog = false
                } catch {
                    this.snackbarMessage = "Error loading asset to map, please try again later"
                    this.snackbarActive = true;
                    this.showDialog = false; 
                }
                finally { 
                    this.showProgressBar = false
                }
            } else if (this.splitByCategory === true && this.classProperty === undefined) {
                    this.unselectedAttributeError = true 
                    this.AttributeErrorMessage = 'Please select an attribute'
                    this.showDialog = true
            }
        },
        getImageUrl(asset) {
            return asset.thumbnail_url ? asset.thumbnail_url : this.assets.icons.datasetVectorPlaceholder
        },
        handleShowAsset(asset) {
            if(this.isCreator) {
                this.$router.push({ name: 'Projects-Dataset', params: { datasetId: asset.id } })
            }
        },
        getCustomAreas() {
            this.customAreas = AreaService.getCustomAreas();
        },
        async handleAssetToMap(asset, area) {
            this.getCustomAreas()
            this.selectableProperties = []
            this.splitByCategory = false
            this.classProperty = this.ASSET_NO_SPLIT_VALUE
            this.loadingAsset = true;
            const firstFeatureProperties = await this.userDatasetsProperties(asset.id)
            if(Array.isArray(firstFeatureProperties) && firstFeatureProperties.length > 0) {
                const propertyClasses = firstFeatureProperties.map(a => a.name)
                if (!area) {
                    area = {}
                }
                    area.meta = {}
                this.snackbarActive = false;
                if(propertyClasses.length > 0) {
                    this.activeAsset = asset
                    this.selectableProperties = propertyClasses
                    this.selectedArea = area
                    this.selectedAsset = asset
                    this.showDialog = true
                    this.loadedMeta = firstFeatureProperties
                } else {
                    await this.loadAssetToMap(asset, area, {})
                }
            } else {
                await this.loadAssetToMap(asset, area, {})
            }
        },
        async loadAssetToMap(asset, area, meta) {
            console.info("asked to map" , asset)
            console.info("with user data", this.user)
            // table is feature collection
            if(asset.type === "FeatureCollection") {
                try {

                    let data = {
                        "org_id": this.user.orgId,
                        "user_id": this.user.uid,
                        "asset_id": asset.id,
                        "collection_shapes": [],
                        "class_property": this.ASSET_NO_SPLIT_VALUE
                    }
                    if(this.classProperty !== this.ASSET_NO_SPLIT_VALUE) {
                        data.class_property = this.classProperty
                    }else {
                        meta.classProperty = this.ASSET_NO_SPLIT_VALUE
                        meta.filterByShapes = []
                    }

                    const response = await functions.getTable(data)
                    if (!meta) {
                        meta = await this.userDatasetsProperties(asset.id)
                    }
                    let areas = response.data.areas
                    let mapURL = response.data.mapURL
                    let bbox = response.data.bbox
                    if(areas !== undefined) {
                        areas.forEach(a => {
                            if(this.classProperty !== this.ASSET_NO_SPLIT_VALUE) {
                                meta.areaName = a.class
                                meta.className = a.index
                                meta.properties = a.properties
                                meta.classProperty = this.classProperty
                            }
                            AreaService.addUserUploadedShape(area ? area.id: null, bbox, a.mapURL, asset.id, Object.assign({},meta))
                        })
                    }
                    if(mapURL !== undefined) {
                        AreaService.addUserUploadedShape(area ? area.id: null, bbox, mapURL, asset.id,  Object.assign({},meta))
                    }
                    this.$emit('asset-loaded')

                    this.snackbarMessage = "Asset added to map"
                    this.snackbarActive = true;
                    
                } catch(error) {
                    console.error("error", error)
                    this.snackbarMessage = "Error loading asset to map, please try again later"
                    this.snackbarActive = true;
                } finally {
                    this.loadingAsset = false;
                }
        
            }
        },

    }, 
    computed : { 
        placeholderText() { 
            if (this.selectableProperties.length === 1) {
                return this.selectableProperties[0]
            } else {
                return 'Select attribute'
            }
        }
    }, 
    watch: { 
        classProperty(classProperty) {
            if (classProperty !== undefined) {
                this.unselectedAttributeError = false
            }
        }

    }
}
</script>
