/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import { 
    NO_DATA_DROPDOWN_VALUE,VALID_BANDNAME_REGEX,INVALID_BANDNAME_ERROR
} from '@/constants/nextGenConstants';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock';
import { ContentService } from '@/services/content.service';

const DEFAULT_SELECT_OPTIONS = [["Select an option", NO_DATA_DROPDOWN_VALUE]];
const DEFAULT_USER_BAND_NAME = "predicted_band";
const ENABLE_LOGGING = true;
const BLOCK_NAME = 'analysis_regression';
const FIELD = Object.freeze({
    METHOD: "regression_method",
    BAND_NAME: "regression_band_name",
    TRAINING_DATASET: "training_dataset",
    TRAINING_BANDS: "training_bands"
})

const methods = [
    ['Linear regression', 'leastSquares'],
    // ['Robust linear regression', 'robust'], disable due to memory errors
]

var regressionJson = {
    "type": BLOCK_NAME,
    "message0": "%1 %2 %3 %4 %5 %6 %7 %8 %9 %10 %11 %12 %13",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "regression_title",
            "text": "%{BKY_ANALYSIS_REGRESSION_BLOCK_TITLE}", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "regression_method_label",
            "text": "%{BKY_ANALYSIS_REGRESSION_BLOCK_METHOD}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.METHOD,
            "options": DEFAULT_SELECT_OPTIONS
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "training_data_label",
            "text": "%{BKY_ANALYSIS_REGRESSION_BLOCK_TRAINING_DATA}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.TRAINING_DATASET,
            "options": DEFAULT_SELECT_OPTIONS, //These options will come from the GDVs
        },
        {
            "type": "input_dummy",
            "name": "post_training_dataset"
        },
        {
            "type": "field_label_serializable",
            "name": "training_band_label",
            "text": "%{BKY_ANALYSIS_REGRESSION_BLOCK_TRAINING_BAND}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.TRAINING_BANDS,
            "options": DEFAULT_SELECT_OPTIONS
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "regression_band_name_label",
            "text": "%{BKY_ANALYSIS_REGRESSION_BLOCK_OUTPUT_PREFIX}"
        },
        {
            "type": "field_input",
            "name": FIELD.BAND_NAME,
            "text": DEFAULT_USER_BAND_NAME,
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "calculate",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks[BLOCK_NAME] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,

    FIELD: FIELD,
    onInit: function() {
        this.jsonInit(regressionJson);
        this.about_block_url = ContentService.getAboutBlockUrl(BLOCK_NAME)
        this.blockLog("Regression Block INITIALISING.");
        this.blockLog("Regression Block INIT complete.");
        this.getField(FIELD.BAND_NAME).setValidator(this.bandNameValidator);
    },

    accept: async function (visitor) {
        let message = "Accepting the populate state visitor";
        console.log(message);
        this.blockLog(message)
        await visitor.visitRegressionBlock(this);
    },

    blockLog: function(message){
        /*
        disable by setting ENABLE_LOGGING to false for production
        a logger that takes a message and adds some useful block specific information like block id and some of the states we use for the EBX app
        */
        let prefix = "ANALYSIS:"; // useful for making something stand out in the console
        if (ENABLE_LOGGING){
            console.log(`BLOCK:${this.id} ${prefix}: ${message}`);
            console.log(`\t${prefix} isLoadingState: ${this.isLoadingState()}`);
        }
    },

    onLoadExtraState(state) {
        this.blockLog("In onLoadExtraState");
        this.blockLog("Completed onLoadExtraState");
        return state
    },

    updateShape_: function() {
        this.blockLog("In updateShape_ for Regression Block");

        let isRaster = this.getState('isRaster');
        if (isRaster){
            this.getField(FIELD.METHOD).updateOptions(methods);
            // update training dataset options
            const trainingDatasetOptions = this.getState(FIELD.TRAINING_DATASET);
            this.getField(FIELD.TRAINING_DATASET).updateOptions(trainingDatasetOptions);
            const trainingBandOptions = this.getState(FIELD.TRAINING_BANDS);
            this.getField(FIELD.TRAINING_BANDS).updateOptions(trainingBandOptions);
        } else {
            // reset block
            this.getField(FIELD.TRAINING_DATASET).updateOptions(DEFAULT_SELECT_OPTIONS);
            this.getField(FIELD.TRAINING_BANDS).updateOptions(DEFAULT_SELECT_OPTIONS);
            this.getField(FIELD.METHOD).updateOptions(DEFAULT_SELECT_OPTIONS);
        }
    },

    bandNameValidator: function(newValue) {
        const block = this.getSourceBlock();

        block.setState(block.FIELD.BAND_NAME, newValue);
        
        return newValue;
    },

    ebxValidate: function(toolTips) { 
        this.blockLog("Validating regression block")
        console.log(JSON.stringify(toolTips));
        const training_dataset = this.getFieldValue(FIELD.TRAINING_DATASET);
        const training_band = this.getFieldValue(FIELD.TRAINING_BANDS);
        const band_name = this.getFieldValue(FIELD.BAND_NAME);

        this.setWarningText(null);

        if (!training_dataset || training_dataset === NO_DATA_DROPDOWN_VALUE) {
            if (toolTips) {
                const warning = toolTips['no_training_dataset'] || "Please select a training dataset";
                this.setWarningText(warning, "no_training_dataset");
                return
            }
        }

        if (!training_band || training_band === NO_DATA_DROPDOWN_VALUE) {
            if (toolTips) {
                const warning = toolTips['no_training_band'] || "Please select a training band";
                this.setWarningText(warning, "no_training_band");
                return
            }
        }

        if (!band_name || band_name === "") {
            if (toolTips) {
                const warning = toolTips['no_output_band_name'] || "Please enter an output band name";
                this.setWarningText(warning, "no_output_band_name");
                return
            }
        } 

        const test = VALID_BANDNAME_REGEX.test(band_name);
        if(!test) {
            return this.setWarningText(INVALID_BANDNAME_ERROR)
        }
        
        this.setWarningText(null);
    },
    
}