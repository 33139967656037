<template>
    <button :class="buttonClass" :disabled="isDisabled" @click.prevent="$emit('click', $event)">
        <template v-if="isText">
            <span v-if="hasIcon && !hasRightIcon" class="material-icons-outlined ebx-icon">{{icon}}</span>
            <slot></slot>
            <span v-if="hasRightIcon" class="material-icons-outlined ebx-icon">{{icon}}</span>
        </template>
        <template v-else-if="isTertiary">
            <div class="md-ripple">
                <div class="md-button-content">
                    <i v-if="hasIcon && !hasRightIcon" class="md-icon md-icon-font md-theme-default">{{icon}}</i>
                    <span><slot></slot></span>
                    <i v-if="hasRightIcon" class="md-icon md-icon-font md-theme-default">{{icon}}</i>
                </div> 
            </div>
        </template>
        <template v-else>
            <i v-if="hasIcon && !hasRightIcon && !hasSpinner" class="md-icon md-icon-font md-theme-default">{{icon}}</i>
            <md-progress-spinner v-if="hasSpinner" v-show="loading" class="md-accent" md-mode="indeterminate" :md-diameter="20" :md-stroke="2"></md-progress-spinner>
            <slot></slot>
            <i v-if="hasRightIcon && !hasSpinner" class="md-icon md-icon-font md-theme-default">{{icon}}</i>
        </template>
    </button>
</template>

<script>
export default {
    props: {
        theme: {
            type: String,
            default: 'primary'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        iconPosition: {
            type: String,
            default: 'left'
        },
        allowDisabledClicks: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'click'
    ],
    computed: {
        isDisabled() {
            return this.disabled && !this.allowDisabledClicks
        },
        hasSpinner() {
            return this.loading !== null
        },
        hasIcon() {
            return this.icon !== null
        },
        hasRightIcon() {
            return this.iconPosition === 'right'
        },
        isTertiary() {
            return this.theme === 'tertiary'
        },
        isText() {
            return this.theme === 'text' || this.theme === 'action'
        },
        buttonClass() {
            let classes = []
            if (this.theme === 'primary') {
                classes.push('ebx-button')
                if (this.disabled) {
                    classes.push('ebx-button--primary__disabled')
                } else {
                    classes.push('ebx-button--primary')
                }
            }
            if (this.theme === 'secondary') {
                classes.push('ebx-button')
                if (this.disabled) {
                    classes.push('ebx-button--secondary__disabled')
                } else {
                    classes.push('ebx-button--secondary')
                }
            }
            if (this.theme === 'secondary__highlight') { 
                classes.push('ebx-button')
                classes.push('ebx-button--secondary__highlight')
            }
            if (this.theme === 'text' || this.theme === 'action') {
                classes.push('ebx-action-text')
                if (this.disabled) {
                    classes.push('ebx-action-text--primary__disabled')
                } else {
                    classes.push('ebx-action-text--primary')
                }
                if(this.hasIcon) {
                    classes.push('ebx-action-text__icon')
                }
            }
            if (this.theme === 'tertiary') {
                classes = ['md-button md-secondary transparent md-theme-default']
            }
            if (this.theme === 'destructive' || this.theme === 'danger' || this.theme === 'delete') {
                classes.push('ebx-button')
                if (this.disabled) {
                    classes.push('ebx-button--destructive__disabled')
                } else {
                    classes.push('ebx-button--destructive')
                }
            }
            if (this.hasSpinner) {
                classes.push('ebx-button--spinner')
                if (this.loading) {
                    classes.push('ebx-button--spinner__disabled')
                }
            }
            return classes
        }
    }
}
</script>