/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/*
* COMPARE
*/

import * as Blockly from 'blockly/core';
import {togglePlayButton, addRunButtonInput, addStopButtonInput} from '@/blocks/mixins/togglePlayButtons'
import { AbstractBlock, AbstractFieldHelpers } from '../_abstractBlock';

var compareJson ={
    "type": "workflow_empty_container",
    "lastDummyAlign0": "RIGHT",
    "message0": "%1 %2 %3 %4 %5",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "compare_title",
            "text": "%{BKY_WORKFLOW_EMPTY_CONTAINER_TITLE}",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_statement",
            "name": "dataset"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_statement",
            "name": "actions"
        }
    ],
    "style": "analyse",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['workflow_empty_container'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    togglePlayButton,
    addRunButtonInput,
    addStopButtonInput,
    onInit: function() {
        this.jsonInit(compareJson);

        this.setState('definition', {});
        this.addRunButtonInput();
    },

    accept: async function (visitor) {
        await visitor.visitWorkflowEmptyContainerBlock(this);
    },

    updateShape_: function() {
        // check if block has parent, then remove the play button
        this.togglePlayButton()
    },
};