<template>
    <div class="calculator--evaulation" :class="containerClasses">
        <div class="flag">
            <md-icon v-if="isInvalid">warning_amber</md-icon>
            <md-icon v-else-if="isValid">done</md-icon>
        </div>
        <textarea ref="textArea" v-model="expression" :disabled="checkingExpression" @selectionchange="selectionChanged"></textarea>
        <div class="expression-message" @click="handleCheckExpression">
            <template v-if="isInvalid">
                Expression Invalid
            </template>
            <template v-else-if="isValid">
                Expression Valid
            </template>
            <template v-else-if="isEmpty">
                Enter Expression
            </template>
            <template v-else>
                Check Expression
            </template>
            <template v-if="checkingExpression">
                <md-progress-spinner md-mode="indeterminate" class="expression-loader static-loading-spinner md-accent" :md-diameter="18" :md-stroke="2" /> 
            </template>
        </div>
    </div>
</template>

<script>
import { CALCULATOR_EXPRESSION_STATE } from '../../constants/nextGenConstants'
import valueMixin from '@/components/mixins/valueMixin'

export default {
    props: {
        modelValue: {
            type: String,
            required: true
        },
        checkingExpression: {
            type: Boolean,
            required: true
        },
        expressionState: {
            type: String,
            required: true
        }
    },
    mixins: [valueMixin],
    emits: [
        'update:modelValue',
        'selection',
        'check-expression'
    ],
    computed: {
        expression: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        isInvalid() {
            return this.containerClasses.indexOf('invalid') >= 0
        },
        isValid() {
            return this.containerClasses.indexOf('valid') >= 0
        },
        isEmpty() {
            return this.containerClasses.indexOf('empty') >= 0
        },
        containerClasses() {
            // classes are empty, valid, invalid
            const classes = []
            if(this.expression.length === 0) {
                classes.push('empty')
            }
            if (this.expressionState === CALCULATOR_EXPRESSION_STATE.VALID) {
                classes.push('valid')
            }
            if (this.expressionState === CALCULATOR_EXPRESSION_STATE.INVALID) {
                classes.push('invalid')
            }
            return classes
        }
    },
    methods:{
        selectionChanged(evnt) {
            this.$emit('selection', {
                start: evnt.target.selectionStart,
                end: evnt.target.selectionEnd
            })
        },
        setPosition(pos) {
            setTimeout(() => {
                this.$refs.textArea.focus()
                this.$refs.textArea.setSelectionRange(pos,pos)
            })
        },
        handleCheckExpression() {
            if(this.checkingExpression) {
                return
            }
            this.$emit('check-expression', this.expression)
        }
    }
}
</script>