<template>
    <div class="dashboard-table">
        <md-table v-model="tableData" :md-sort="columnNames[0]" md-sort-order="asc">
            <template v-slot:md-table-row="{ item }">
                <md-table-row>
                    <md-table-cell 
                        v-for="column in getColumns(item)" 
                        :key="column"
                        :md-label="column"
                        :md-sort-by="column"
                        >
                        <span style="white-space: pre;">{{ formatTableCell(item[column]) }}</span>
                    </md-table-cell>
                </md-table-row>
            </template>
            <md-table-empty-state
                md-icon="table_chart"
                md-label="No data available"
                md-description="There is no data available for the selected area and time range."
                />
        </md-table>
        <div class="dashboard-table--table-footer">
            <p v-if="hasResolution" class="ebx-secondary">
                {{ resolutionMessage }}
                <a href="https://support.earthblox.io/support/solutions/articles/101000488317-resolutions-in-table-calculations" target="_blank">Learn more</a>
            </p>
            <EbxButton v-if="!isTableEmpty" theme="action" icon="file_download" icon-position="right" @click="$emit('save-csv', tableData)">Download</EbxButton>
        </div>
    </div>
</template>

<script>
export default {
    name: "DashboardTable",
    props: {
        df: {
            type: Array,
            required: true
        },
        resolution: {
            type: Number,
            required: false
        },
        crs: {
            type: String,
            required: false
        }
    },
    emits: [
        'save-csv'
    ],
    data: () => ({
        tableData: []
    }),
    watch: {
        df: {
            handler(val) {
                this.tableData = val
            },
            immediate: true, 
            deep: true
        }
    },
    computed: {
        columnNames() {
            return this.getColumns(this.tableData[0])
        },
        hasResolution() {
            return this.resolution !== undefined && this.resolution !== null;
        },
        hasCrs() {
            return this.crs !== undefined && this.crs !== null;
        },
        isTableEmpty() {
            return Array.isArray(this.tableData) && this.tableData.length === 0
        },
        resolutionMessage() {
            if (!this.hasResolution && !this.hasCrs) {
                return ''
            }

            if (this.hasResolution && this.hasCrs) {
                return `Calculated at ${this.resolution}m resolution in coordinate system ${this.crs}.`
            }

            if (!this.hasCrs) {
                return `Calculated at ${this.resolution}m resolution in the dataset’s native coordinate system`
            }

            // don't show if no resolution
            return ''
        }
    },
    methods: {
        getColumns(item) {
            if(typeof item !== 'object') {
                return []
            }
            return Object.keys(item)
        },
        formatTableCell(item) {
            if(typeof item === 'number') {
                var abs_value = Math.abs(item)
                if (abs_value === 0) {
                    return item
                } else if (abs_value > 0.001) { 
                     return Number(parseFloat(item).toFixed(4)).toLocaleString()
                } else { 
                      return Number(item).toExponential(4)
                } 
            } else {
                return item
            }
        },
    }
}
</script>
