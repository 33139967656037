<template>
    <div
        class="timeline"
        ref="timeline"
        v-if="timelineLayer.isGroup" :class="timelineClass">
        <div class="timeline--header">
            <div class="timeline--header-container">
                <p class="timeline--header__title ebx-secondary">Time series</p>
                <p class="timeline--header__step ebx-primary-bold">{{timelineLayer.layers[currentLayer].label}}-{{currentLayer + 1}}</p>
            </div>
            <md-button class="md-icon-button md-raised timeline-button" 
            @click="playTimeline(timelineLayer)"
            :disabled="(playing && interval === null) || timelineDisabled"
            >
                <md-icon v-if="playing">pause</md-icon>
                <md-icon v-else>play_arrow</md-icon>
            </md-button>
        </div>
        
        <div class="timeline--container" >
            <vue-slider
                :key="timelineLayer.id"
                :adsorb="true"
                :disabled="timelineDisabled"
                v-model="currentLayer"
                :min=0
                :max="timelineLayer.layers.length-1"
                :marks="timelineLayer.labels"
                :interval=1
                class="timeline-range"
                ref="timeline"
            />
        </div>
    </div>
</template>
<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import { LAZYLOAD_MAP_TILE_IMAGES } from '../../constants/nextGenConstants';

export default {
    name: "GoogleMapTimeline",
    components: {
        VueSlider
    },
    data: () => ({
        playing: false,
        dotOptions: {},
        interval: null,
        lazyLoadLayers: LAZYLOAD_MAP_TILE_IMAGES,
        loadingImages: false,
        loadedTimeoutId: null,
        intervalMs: 1000,
        continueAfterLoadMs: 2000, // After all the images have loaded, wait for x ms before continuing
    }),
    props: {
        google: {
            type: Object,
            required: true
        },
        map: {
            type: Object,
            required: true
        },
        mapLayer: {
            type: Object,
            required: true
        },
        mapLayerTilesLoading: {
            type: Object,
            required: true
        },
        timelineDisabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        // refactor this so selected layer is emitted
        playTimeline(group) {
            if(this.playing){
                clearInterval(this.interval);
                this.interval=null;
                this.playing = false;
            } else {
                this.playing = true;
                if(this.currentLayer === group.layers.length - 1){
                    this.currentLayer = 0
                    // mutate store
                    this.$store.commit('maplayers/updateSelectedLayer', {
                        id: group.id,
                        layerIdx: 0
                    })
                }       
                this.interval = setInterval(() => {
                    if(this.lazyLoadLayers && this.mapLayerLoading) {
                        this.loadingImages = true
                        //keep polling 
                        return 
                    }
                    if(this.loadingImages && this.loadedTimeoutId === null) {
                        this.loadedTimeoutId = setTimeout(() =>{
                            this.loadingImages = false
                            this.loadedTimeoutId = null
                        }, this.continueAfterLoadMs)
                    }
                    if(this.loadedTimeoutId) {
                        return
                    }
                    if(this.currentLayer < group.layers.length - 1) {
                        this.currentLayer ++
                        // mutate store
                        this.$store.commit('maplayers/updateSelectedLayer', {
                            id: group.id,
                            layerIdx: this.currentLayer
                        })
                    } else {
                        this.playing = false;
                        clearInterval(this.interval)
                        this.interval=null;
                    }
                }, this.intervalMs);   
            }
        }
    },
    computed: {
        timelineClass() {
            if (this.timelineDisabled) {
                return 'timeline__disabled'
            }
            return ''
        },
        mapLayerLoading() {
            return this.mapLayerTilesLoading[this.mapLayer.id] === true
        },
        currentLayer: {
            get() {
                return this.$store.getters['maplayers/getSelectedLayer'][this.mapLayer.id]
            },
            // setter
            set(currentLayer) {
                this.$store.commit('maplayers/updateSelectedLayer', {
                    id: this.mapLayer.id,
                    layerIdx: currentLayer
                })
                return currentLayer
            }
        },
        timelineLayer() {// checks for more than one layer in a single block, this should be a property in data structure down the line
            let timelineGroup = this.mapLayer.isGroup ? this.mapLayer : null

            if (timelineGroup) {
                timelineGroup.labels = {};
                timelineGroup.tooltips = {};
                // this.interval = null;
                let len = timelineGroup.layers.length;
                timelineGroup.layers.forEach((layer, index) => {
                    if(index === 0 || index === len - 1) {
                        timelineGroup.labels[index] = layer.label;
                    } else {
                        timelineGroup.labels[index] = "";
                    }
                    timelineGroup.tooltips[index] = layer.label;
                })
            }
            return timelineGroup;
        }
    },
    beforeUnmount() {
        clearInterval(this.interval)
    },
}
</script>