/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["ANALYSIS_ZONAL_STATISTICS_BLOCK_TITLE"] = "Calculate zonal statistics";
Blockly.Msg["ANALYSIS_ZONAL_STATISTICS_BLOCK_BAND"] = "Using band";
Blockly.Msg["ANALYSIS_ZONAL_STATISTICS_BLOCK_BAND_NAME"] = "Create band";
Blockly.Msg["ANALYSIS_ZONAL_STATISTICS_BLOCK_ATTRIBUTE_NAME"] = "Save as attribute";
Blockly.Msg["ANALYSIS_ZONAL_STATISTICS_BLOCK_DATE"] = "Date";
Blockly.Msg["ANALYSIS_ZONAL_STATISTICS_BLOCK_METHOD"] = "Calculate the";
Blockly.Msg["ANALYSIS_ZONAL_STATISTICS_BLOCK_ZONE_DATASET_LABEL"] = "Use zones from dataset"
Blockly.Msg["ANALYSIS_ZONAL_STATISTICS_BLOCK_ZONE_RASTER_DATASET_LABEL"] = "Use dataset"
Blockly.Msg["ANALYSIS_ZONAL_STATISTICS_BLOCK_ZONE_BAND_LABEL"] = "Using band"
Blockly.Msg["ANALYSIS_ZONAL_STATISTICS_BLOCK_OUTPUT_ERROR"] = 'Please give a name to the output parameter'
Blockly.Msg["ANALYSIS_ZONAL_STATISTICS_BLOCK_DATASET_ERROR"] = "Please choose a dataset"
Blockly.Msg["ANALYSIS_ZONAL_STATISTICS_BLOCK_DATE_ERROR"] = "Please choose a date"
Blockly.Msg["ANALYSIS_ZONAL_STATISTICS_BLOCK_CADENCE_ERROR"] = "Only the first image in this series will be used to calculate zonal statistics.\nUse the Aggregate images in time block on your selected dataset."

export default Blockly.Msg;
