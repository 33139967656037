/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/**
 * Enum of names of configurable features of the interface. These are used as keys to FEATURE_CONFIGS.
 * Features can be shown/hidden by setting the name to true/false in a config file.object,
 * and disabled by setting a key with the suffix ".disabled" to true.
 * For example:
 *
 *     "map.lineDrawing.disabled": true
 *
 * will disable the line drawing tool.
 */
let FEATURES_LIST = Object.freeze({
    DOWNLOADS: "downloads.assetDownload",
    LINE_DRAWING_DOWNLOAD: "downloads.lineDrawingDownload",
    MAP_MEASUREMENT: "map.mapMeasurement",
    MAP_LINE_DRAWING: "map.lineDrawing",
    POLYGON_UPLOAD: "map.polygonUpload",
    CLEAR_MAP: "map.clearMap",
    // drawing tools - draw rectangle, shape, stop drawing, delete polygon, assign area
    MAP_DRAWING_TOOLS: "map.mapDrawing",
    // area buttons - draw rectangle, shape; add area; area edit
    MAP_AREA_TOOLS: "map.areaTools",
    TAB_DASHBOARD: "tabs.dashboard",
    CODE_PREVIEW: "code.codePreview",
    WORKFLOWS_LIBRARY: "workflows.library",
    WORKFLOWS_SAVE: "workflows.save",
    WORKFLOWS_LOAD: "workflows.load",
    WORKFLOWS_MODIFIABLE: "workflows.modifiable",
    EARTH_TALKS: "earthTalks",
    AI_CHARTS: "aiCharts",
    DOWNLOAD_BLOCKLY_JSON: "downloadBlocklyJSON",

    // Whether to show the Globe debug mode (not fucntional yet - need to get config value in src/blocks/strata/strata_custom_threshold_fields.js)
    GLOBE_DEBUG_MODE_AVAILABLE: "globe.debugMode.available",
});

/**
 * An object to represent properties of a configurable feature.
 *
 * @class ConfigFeature
 */
class ConfigFeature {
    /**
     * Creates an instance of ConfigFeature.
     * @param {String} description a description of the feature, for presentation to a user
     * @param {boolean} enabledByDefault whether the feature is enabled by default
     * @param {boolean} [superadmin=false] whether the feature is only available to superadmins
     * @memberof ConfigFeature
     */
    constructor(description, enabledByDefault, superadmin=false) {
        this.description = description;
        this.default = enabledByDefault;
        this.superadmin = superadmin;
    }
}


// Freeze the object and export as a module for Node.js:
// Field in org for config set by EBX admins
export const EBX_CONFIG_FIELD = "ebxConfig";
// Field in org for config set by org admins
export const ORG_CONFIG_FIELD = "config";
export const ORG_ACCESS_TYPE_FIELD = "accessType";
export const CONFIG_DISABLED_MODIFIER = ".disabled";
export const BLOCKLY_DISABLED_ATTRIBUTE = "disabled";

// Category style attribute
export const BLOCKLY_CATEGORY_STYLE_ATTRIBUTE = "categorystyle";
export const CATEGORY_STYLE_DISABLED = "disabled_category";
export const CATEGORY_STYLE_SUFFIX = "_category";
export const DISABLED_STYLE_SUFFIX = "_disabled";

// The doc and field names for toolbox spec in the configuration collection:
export const CONFIG_TOOLBOX_DOC = "toolbox";
export const CONFIG_TOOLBOX_DOC_FIELD = "toolboxXML";

// Default licence URLs
export const DEFAULT_NON_COMMERCIAL_LICENCE = "https://storage.googleapis.com/ebx-app.appspot.com/assets/licences/ebx-saas-license-non-commercial-2023-02-22.html";
export const DEFAULT_COMMERCIAL_LICENCE = "https://storage.googleapis.com/ebx-app.appspot.com/assets/licences/ebx-saas-license-commercial-2023-02-22.html";

/**
 * Earth Blox access categories for organisations.
 * These imply a particular set of features, header text, and other things.
 */
export const EBX_ACCESS = {
    NEXTGEN: "nextgen", // Clean set of v2.0 block for demo purposes at geo4good
    FULL: "full", // Normal set of features
    TRIAL: "trial", // Cut down features
    EDUCATION: "education", // Academic features, may include extra features
    MOOC: "mooc", // MOOC features
    // For Earth Blox use:
    DEV: "development", // All features and full toolbox plus code preview tab - for dev use
    DEMO: "demo", // All features and full toolbox, for demos
};

/**
 * Types of config. There are different configuration documents to configure
 * functionally different parts of the UI. For example:
 * feature configuration (enable/disable/hide features),
 * toolbox configuration (enable/disable/hide blocks),
 * and other UI customisation like themes etc. These are all represented as
 * JSON with dotted keys representing flattened structure.
 */
export const CONFIG_TYPE = {
    FEATURES: "features",
    TOOLBOX: "toolbox",
    CUSTOMISATION: "customisation",
    PREFERENCES: "preferences",
    VISIBLE_DATASETS: "visibleDatasets",
};

/**
 * Types of element that we recognise in Blockly toolbox XML, for showing/hiding or  enabling/disabling.
 */
export const TOOLBOX_ELEMENT_TYPE = {
    CATEGORY: "category",
    BLOCK: "block",
    BUTTON: "toolboxbutton",
};

// Export features list
export const FEATURES = FEATURES_LIST;

/**
 * Configurable features of the interface, with a display name and a default enabled value.
 */
export const FEATURE_CONFIGS = {
    [FEATURES.WORKFLOWS_SAVE]: new ConfigFeature("Save Workflows", true),
    [FEATURES.WORKFLOWS_LOAD]: new ConfigFeature("Load Workflows", true),
    [FEATURES.WORKFLOWS_LIBRARY]: new ConfigFeature("Workflows Library", true),
    [FEATURES.WORKFLOWS_MODIFIABLE]: new ConfigFeature("Pre-Built Workflows are Modifiable", true, true),
    [FEATURES.CODE_PREVIEW]: new ConfigFeature("Code Preview Window", true, true),
    [FEATURES.DOWNLOADS]: new ConfigFeature("Asset Download", true),
    [FEATURES.LINE_DRAWING_DOWNLOAD]: new ConfigFeature("Line Drawing Download", true),
    [FEATURES.MAP_MEASUREMENT]: new ConfigFeature("Map Measurement Tools", true),
    [FEATURES.MAP_LINE_DRAWING]: new ConfigFeature("Line Drawing Tool", true),
    [FEATURES.POLYGON_UPLOAD]: new ConfigFeature("Upload Polygons Tool", true),
    [FEATURES.CLEAR_MAP]: new ConfigFeature("Clear Map Button", true),
    [FEATURES.EARTH_TALKS]: new ConfigFeature("Earth Talks Workflow AI", true),
    [FEATURES.AI_CHARTS]: new ConfigFeature("AI Charts Workflow AI", false, true),

    // Map features
    // drawing tools - draw rectangle, shape, stop drawing, delete polygon, assign area
    [FEATURES.MAP_DRAWING_TOOLS]: new ConfigFeature("Map Drawing Tools", true),
    // area buttons - draw rectangle, shape; add area; area edit
    [FEATURES.MAP_AREA_TOOLS]: new ConfigFeature("Map Area Tools", true),

    // UI Tabs
    [FEATURES.TAB_DASHBOARD]: new ConfigFeature("Dashboard Tab", true),
    
    // Globe debug mode in indicators
    [FEATURES.GLOBE_DEBUG_MODE_AVAILABLE]: new ConfigFeature("Show Debug mode option in indicators", true),
};

/**
 * Configurable toolbox elements. These correspond to the XML definition and
 * are a mapping of toolbox ids (category toolboxitemid, or block name) to a
 * meaningful name which can be presented in a configuration customisation dialog.
 * Note that styles are defined in the XML for categories and blocks.
 */
export const TOOLBOX = {
    "input.imagery": "Input > Imagery",
    "input.terrain": "Input > Terrain",
    "input.atmosphere": "Input > Atmosphere",
    "input.anthropogenic": "Input > Anthropogenic",
    "input.landcover": "Input > Landcover",
    "input.forest": "Input > Forest",
    "input.water": "Input > Water",
    "input.fires-and-droughts": "Input > Fires and Droughts",

    "analyse.comparison": "Analyse > Comparison",
    "analyse.time-series": "Analyse > Time Series",
    "analyse.indices": "Analyse > Indices",
    "analyse.band-math": "Analyse > Band Math",
    "analyse.classification": "Analyse > Classification",
    "analyse.change-detection": "Analyse > Change Detection",

    "output.map-visualisation": "Output > Map Visualisation",
    "output.download": "Output > Download",
    "output.dashboards": "Output > Dashboards",
};

/**
 * Customisations for the User Interface.
 */
export const UI = {
    "ebx.dark.mode": "Dark Mode",
    "ebx.background": "Background Colour",
    "ebx.map.position": "Default Map Position",
}