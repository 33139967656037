/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import { DatasetService } from '@/services/dataset.service';
import { intersection } from 'lodash';
import Blockly from "blockly";
import {VARIABLE_BLOCKLY_ERROR_PREFIX,NO_DATA_DROPDOWN_VALUE } from "@/constants/nextGenConstants";
import { ContentService } from '@/services/content.service';
import { VariablesService } from '@/services/variable.service';

/**
 * takes top block of strata workflow and checks if a convergence block is present
 * if there isn't but there are indicators present then it will set an error that there
 * is a loose block
 * @param {*} topBlock 
 */
async function validateLooseBlock(block) {
    let rootBlock = block.getRootBlock()
    
    if (rootBlock.type === 'output_add_table') {
        let tooltips = ContentService.getWarningText('lblock_validation')
        await rootBlock.ebxValidate(tooltips)
        if(rootBlock.getChildren().length > 0) {
            block.setWarningText(tooltips['add_table_decendants'], "role_warning");
        }else {
            block.setWarningText(null, "role_warning");
        }
    }else if(!rootBlock.type.startsWith('workflow')) {
        console.log("WARNING ROOT BLOCK: " + rootBlock.type )
        let tooltips = ContentService.getWarningText('lblock_validation')
        console.warn('Block loose', block.type)
        block.setWarningText(tooltips['workflow'], "loose-block-warning")
    } else {
        block.setWarningText(null, "loose-block-warning")
    }
    await blockVariableValidation(block)
}

/**
 * Call ebxValidate with block-specific tooltip error content.
 */
async function validateBlockSpecific(block) {
    if (typeof block.ebxValidate === 'function') {
        const tooltips = ContentService.getWarningText(block.type)
        await block.ebxValidate(tooltips)
    }
    await blockVariableValidation(block)
}


/**
 * Validate variables in a block
 * @param {*} block 
 */
async function blockVariableValidation(block) {
    if(typeof block.getVariables === 'function') {
        const warningText = block.getWarningText()
        // Clear any old warnings todo with variables
        if(warningText) {
            Object.keys(warningText).forEach(warningKey => {
                if(warningKey.startsWith(VARIABLE_BLOCKLY_ERROR_PREFIX)) {
                    block.setWarningText(null, warningKey)
                }
            })
        }

        const variables = block.getVariables()
        for(let i = 0; i < variables.length; i++) {
            const variable = variables[i]
            if(variable.getIsDeleted()) {
                const warningKey = VARIABLE_BLOCKLY_ERROR_PREFIX + variable.getId() + '_deleted'
                block.setWarningText(variable.getTitle()+' no longer exists', warningKey)
            }else {
                // Validate the variable using rules in the variable class
                await variable.validate()

                if (!(await variable.isValid())) {
                    // get only errors not any warnings
                    variable.getErrors(true, false).forEach(error => {
                        const warningKey = VARIABLE_BLOCKLY_ERROR_PREFIX + variable.getId() + '_' + error.key
                        block.setWarningText(error.message, warningKey)
                    })
                }
            }
        }
    }
}

/**
 * function to validate Lblocks
 * @param {*} topBlock
 */
async function validateLBlock(topBlock) {
    let blockValidationSpec = []
    let usedModifiers = []

    //Clear Variables
    VariablesService.getVariables().forEach(variable => {
        variable.clearWarnings()
    })

    topBlock.setWarningText(null)
    // Validate dates will set appropriate warnings
    if ("validateDates" in topBlock) {
        topBlock.validateDates()
    }
    
    if(topBlock.type == 'workflow_insert_dataset')
    {
        console.log("workflow_insert_dataset", topBlock)
        let stacID = topBlock.getFieldValue('dataset_options');
        if(stacID == NO_DATA_DROPDOWN_VALUE) {
            topBlock.setWarningText("Choose a dataset", "dataset_warning")
            return
        }
        console.log("Stac ID:" + stacID);
        blockValidationSpec = await DatasetService.getBlockValidationSpec(stacID)
    }

    if(topBlock.type == 'output_add_table') {
        await validateBlockSpecific(topBlock)
    }
    let descendants = topBlock.getDescendants()

    // check if top block is enabled, otherwise skip validation as all it's descendants will be disabled
    if (topBlock.isEnabled()) {
        const promises = descendants.map(async (descendant) => {
            // Ignore disabled blocks
            if (descendant.isEnabled() === false) {
                return
            }
            await validateLooseBlock(descendant)
            await validateBlockSpecific(descendant)
            // Keep track of which modifiers and fields have been used so can compare with default_modifiers
            console.debug(`Adding ${descendant.type} to usedModifiers`)
            usedModifiers.push(descendant.type)
            descendant.inputList.forEach((input) => {
                if (input.name && input.isVisible() ) {
                    console.debug(`Adding input ${input.name} to usedModifiers`)                
                    usedModifiers.push(`${descendant.type}.${input.name}`)
                    input.fieldRow.forEach((field) => {
                        // console.debug(`INPUT ${input.name} (${input.isVisible()}) FIELD ${field.name} = ${field.getValue()}`)
                        if (field.name && !(field instanceof Blockly.FieldLabel) && 
                            field.getValue() && field.getValue() !== NO_DATA_DROPDOWN_VALUE) {
                                console.debug(`Adding field ${field.name} to usedModifiers`)                
                                usedModifiers.push(`${descendant.type}.${input.name}.${field.name}`)
                        }
                    })
                }
            })
        })

        await Promise.all(promises)
    }
    
    console.debug(`Compare \n${usedModifiers.join("\n")}\n-- with --\n${blockValidationSpec.join("\n")}\n`)
    // Function to split defaultModifiers and check if usedModifiers exist in them
    blockValidationSpec.forEach((validationSpec) => {
        if (!isValidationSatisfied(validationSpec, usedModifiers)) {
            console.log(`Unmet modifier requirement ${validationSpec}`)
            topBlock.setWarningText(userModifierWarningFor(validationSpec), `${validationSpec}-warning`)
        }
    });
}

/**
 * Take a validation spec, process it and check if it is satisfied by usedModifiers.
 * Recognises the following specs:
 * 
 * 1. A simple modifier name: 
 *     "study_area"
 * 2. The name of an attribute within a modifier:
 *     "orbit.orbit_selection"
 * 3. Modifiers or attributes, at least one of which must appear:
 *     "modifier_date_period OR date_range"
 *     "modifier_date_period, date_range"
 * 4. Modifiers or attributes, only one of which must appear:
 *     "orbit.orbit_selection XOR modifier_orbit"
 * 5. Modifier or attribute which must not appear:
 *     "NOT modifier_date_period"
 * 
 * NOT cannot be used in an ORed or XORed list.
 * 
 * @param {*} validationSpec
 * @param {*} usedModifiers
 * @return {*} 
 */
function isValidationSatisfied(validationSpec, usedModifiers) {
    if (validationSpec.includes("XOR")) {
        let mods = validationSpec.split(/\s+XOR\s+/)
        return intersection(mods, usedModifiers).length == 1
    } else if (validationSpec.match(/(OR|,)/)) {
        let mods = validationSpec.split(/\s+(?:,|OR)\s+/)
        return intersection(mods, usedModifiers).length >= 1
    } else if (validationSpec.startsWith("NOT ")) {
        let mod = validationSpec.replace(/^NOT\s+/, '')
        return !usedModifiers.includes(mod)
    } else if (validationSpec.includes(".")) {
        return usedModifiers.includes(validationSpec)
    } else {
        return usedModifiers.includes(validationSpec)
    }
}


/**
 * Generate a user-readable warning to reflect an unmet modifier requirement spec.
 * Recognises the following specs:
 * 
 * 1. A simple modifier name: 
 *     "study_area"
 * 2. The name of an attribute within a modifier:
 *     "orbit.orbit_selection"
 * 3. Modifiers or attributes, at least one of which must appear:
 *     "modifier_date_period OR date_range"
 *     "modifier_date_period, date_range"
 * 4. Modifiers or attributes, only one of which must appear:
 *     "orbit.orbit_selection XOR modifier_orbit"   
 * 5. Modifier or attribute which must not appear:
 *     "NOT modifier_date_period"
 * 
 * NOT cannot be used in an ORed or XORed list.
 * 
 * @param {*} validationSpec
 * @return {*} 
 */
function userModifierWarningFor(validationSpec) {
    if (validationSpec.includes("XOR")) {
        let mods = validationSpec.split(/\s+XOR\s+/).map(element => ContentService.getBlockName(element))
        const block_names = "'" + mods.join("' block, '") + "' block"

        return `To use this dataset, add exactly one of ${block_names} to the workflow`
    } else if (validationSpec.match(/(OR|,)/)) {
        let mods = validationSpec.split(/\s+(?:,|OR)\s+/).map(element => ContentService.getBlockName(element))
        const block_names = "'" + mods.join("' block, '") + "' block"

        return `To use this dataset, add one of ${block_names} to the workflow`;
    } else if (validationSpec.startsWith("NOT ")) {
        let mod = ContentService.getBlockName(validationSpec.replace(/^NOT\s+/, ''))
        return `To use this dataset, remove the '${mod}' block from the workflow`
    } else if (validationSpec.includes(".")) {
        let specComponents = validationSpec.split(".").map(element => ContentService.getBlockName(element))
        return `To use this dataset, you must use '${specComponents[0]}' block and fill in the '${specComponents.slice(-1)}' field`
    } else {
        let blockName = ContentService.getBlockName(validationSpec)
        return `To use this dataset, add the '${blockName}' block to the workflow`
    }
}

export {validateLBlock}