/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/* eslint-disable no-prototype-builtins */
import {
    CONFIG_TYPE,
    ORG_CONFIG_FIELD,
    ORG_ACCESS_TYPE_FIELD,
    EBX_CONFIG_FIELD,
    CONFIG_DISABLED_MODIFIER,
    FEATURE_CONFIGS,
} from "./constants.js";

import firebase from "firebase/compat/app";

import "firebase/compat/firestore";

const firestore = firebase.firestore();

// NB This breaks emulation so only do it if we're not in a dev/localhost environment
if (import.meta.env.PROD && location.hostname !== "localhost") {
    // The setting experimentalAutoDetectLongPolling should detect polling issues 
    // and turn on long polling when necessary. However reports suggest it is not
    // entirely reliable and we may need to decide ourselves when to enable
    // experimentalForceLongPolling instead.
    console.info("Enabling experimentalAutoDetectLongPolling on primary Firestore service")
    firestore.settings({ 
      experimentalAutoDetectLongPolling: true, 
      merge: true
    });
}

import { ConfigService } from "../services/config.service";
import { applyToToolboxXml } from "./toolboxFunctions";
import { merge } from "lodash";


/**
 * A configuration object for an organisation, providing access to processed configurations for a particular organisation.
 * This object combines default and custom configurations in order to produce a usable configuration document. These can
 * fulfil one of two purposes:
 *
 * 1. a comprehensive customisation configuration to prevent to the org admin user for editing; or
 * 2. a final, practical configuration spec to apply to the UI
 *
 * These configuration documents can be in JSON, or in the case of toolbox definitions, XML.
 *
 * Ideally the configuration object will be cached at the page level or in a global/session state.
 *
 * The base config can be customised for an organisation by configuration supplied by an EBX
 * superadmin user. This is saved in the org's ebxConfig field.
 * Currently there is no UI for creating/updating this, but to add that capability we must provide analogs of
 * getEditableConfig() and saveCustomConfigForOrg().
 */
export class OrgConfig {

    /** Organisation doc reference. */
    orgDoc = null;
    /** Organisation id. */
    orgId = null;
    /** Access type from the org doc. */
    accessType = null;
    /** Configuration loaded from the org doc. */
    configuration = {};
    /** Earthblox/superadmin configuration loaded from the org doc. */
    ebxConfiguration = {};
    /** Processed configurations reasdy to apply to the UI; these are updated when source docs change. */
    processedConfigs = {};
    /** registry for snapshot listeners */
    snapshotListeners = [];

    /**
     * Constructor - create an OrgConfig object for an organisation id.
     * Note: The object is not ready to use until the asynchronous intialize()
     * method is called on it and has compleleted.
     * @param {*} orgId
     */
    constructor(orgId) {
        this.orgId = orgId;
        this.snapshotListeners = [];
    }

    unsubscribeListeners() {
        this.snapshotListeners.forEach(unsubscribe => unsubscribe());
        this.snapshotListeners = [];
    }
    

    /**
     * Initialize the OrgConfig object with data from Firestore, and return a Promise.
     * The caller can `await` the result of the Promise before using the OrgConfig object.
     * Also sets up a reference to an Organisation doc snapshot.
     * When the snapshot updates, we will update the configuration in this object.
     */
    async initialize() {
        this.orgDoc = firestore.doc(`/organisations/${this.orgId}`);
        // Watch a snapshot for changes
        const unsubscribe = this.orgDoc.onSnapshot(docSnapshot => {
            // console.debug(`Org snapshot changed - calling _update on OrgConfig`)
            this._update(docSnapshot);
        }, error => {
            console.error('main org', error);
        });
        let doc = await this.orgDoc.get();
        this.snapshotListeners.push(unsubscribe);
        this._update(doc);
    }

    /**
     * Update member variables from a document or snapshot retrieved from Firestore.
     * Updates values for accessType and orgConfig, then updates the processed configs.
     * @param {*} doc a document or document snapshot
     */
    _update(doc) {
        // console.debug(`Updating OrgConfig`)
        if (!doc.exists) {
            console.info(`Organisation doc ${this.orgId} not found`);
        } else {
            // Record/update the access type
            this.accessType = doc.data()[ORG_ACCESS_TYPE_FIELD];
            // Record/update the config data for the org
            let orgConfig = doc.data()[ORG_CONFIG_FIELD] || {};
            if (typeof orgConfig === 'string') {
                try {
                    orgConfig = JSON.parse(orgConfig);
                } catch {
                    console.error("Cannot parse organisational config as JSON");
                    orgConfig = {}
                }
            }
            this.configuration = orgConfig;
            // Record/update the superadmin config data for the org
            let ebxConfig = doc.data()[EBX_CONFIG_FIELD] || {};
            if (typeof ebxConfig === 'string') {
                try {
                    ebxConfig = JSON.parse(ebxConfig);
                } catch {
                    console.error("Cannot parse superadmin organisational config as JSON");
                    ebxConfig = {}
                }
            }
            this.ebxConfiguration = ebxConfig;
            // Update the processed/derived configs after these changes
            this.updateProcessedConfigs();
        }
    }

    /**
     * Update all the processed configs stored in this object. This should be
     * called when initialising the OrgConfig object and when base configs change.
     */
    updateProcessedConfigs() {
        console.debug(`Updating processed configs with new info`);
        Object.values(CONFIG_TYPE).forEach(configType => {
            console.debug(`Updating ${configType} config`);
            this.processedConfigs[configType] = this._constructFinalConfig(configType);
        });
    }

    /**
     * Get the org's base config, which is the default base config optionally modified by
     * a config block supplied by a superadmin user and stored in an org under ebxConfig.
     * @returns a base config from the config service
     */
    getBaseConfig(configType=CONFIG_TYPE.FEATURES) {
        let defaultConfig = ConfigService.getBaseConfig(this.accessType, configType);
        // console.debug(`Default config: ${JSON.stringify(defaultConfig, null, 4)}`);
    
        // Modify the base config with superadmin config, if it exists.
        if (this.ebxConfiguration.hasOwnProperty(configType)) {
            let configMods = this.ebxConfiguration[configType];
            // console.debug(`Applying superadmin-level config: ${JSON.stringify(configMods, null, 4)}`);
            // Merge the configs, letting ebxConfig override the base:
            return merge({}, defaultConfig, configMods);
        }
        return defaultConfig;
    }

    /**
     * Get a configuration for a particular config type. This will represent the
     * final, current config which a client can apply to the UI. It is constructed
     * by applying org customisations to the base config.
     * @param {*} configType
     * @returns a JSON or XML config spec as appropriate, or undefined if the config doesn't exist
     */
    getConfig(configType) {
        return this.processedConfigs[configType];
    }

    /**
     * Check if a particular feature should be shown.
     * @param {OrgConfig} orgConfig an orgConfig object
     * @param {String} feature the name of the configurable feature
     * @returns the value of the configuration variable, or the default if the config doesn't exist; or true if the feature is not configurable
     */
    static isFeatureShown(orgConfig, feature) {
        let fc = FEATURE_CONFIGS[feature];
        // If the feature doesn't exist as a configurable feature, return true
        if (!fc) {
            console.warn(`Tried to access configuration on a non-configurable feature ${feature}. Returning true.`);
            return true;
        }
        let defaultResponse = fc.default;
        if (!orgConfig) {
            console.warn(`Tried to access configuration without a valid orgConfig object, for feature ${feature}. Returning default ${defaultResponse}.`);
            return defaultResponse;
        }
        let configOption = orgConfig.getConfig(CONFIG_TYPE.FEATURES)[feature]
        let show = (typeof configOption === 'boolean' ? configOption : defaultResponse)
        return show;
    }

    /**
     * Check if a particular feature should be disabled.
     * @param {OrgConfig} orgConfig an orgConfig object
     * @param {String} feature the name of the configurable feature
     * @returns the value of the configuration variable, or the default if the config doesn't exist; or false if the feature is not configurable
     */
    static isFeatureDisabled(orgConfig, feature) {
        let fc = FEATURE_CONFIGS[feature];
        // If the feature doesn't exist as a configurable feature, return false
        if (!fc) {
            console.warn(`Tried to access configuration on a non-configurable feature ${feature}. Returning false.`);
            return false;
        }
        // The default for 'disabled' is the negation of the default (show) property
        let defaultResponse = !fc.default;
        if (!orgConfig) {
            console.warn(`Tried to access configuration without a valid orgConfig object, for feature ${feature}. Returning default ${defaultResponse}.`);
            return defaultResponse;
        }
        // Look up feature.disabled, and if it doesn't exist 
        let configOption = orgConfig.getConfig(CONFIG_TYPE.FEATURES)[feature+CONFIG_DISABLED_MODIFIER]
        let disable = (typeof configOption === 'boolean' ? configOption : defaultResponse)
        // console.debug(`isDisabled ${configKey}: ${disable}`);
        return disable;
    }

    /**
     * Get the final config for the org, after super-admin and admin customisations have been applied to the base config.
     * This is for applying to the UI.
     * @param {*} configType
     * @returns JSON config representation, or toolbox XML, showing the final config taking into account priority of users' configs
     */
    _constructFinalConfig(configType=CONFIG_TYPE.FEATURES) {
        let config = this.getBaseConfig(configType);
        // console.debug(`Base config: ${JSON.stringify(config, null, 4)}`);

        // If the configuration type has mods in the org's configuration, apply them
        if (this.configuration.hasOwnProperty(configType)) {
            let configMods = this.configuration[configType];
            // console.debug(`Applying org-level config: ${JSON.stringify(configMods, null, 4)}`);
            
            Object.keys(config).forEach(configKey => {
                // If the base config specifies a disabled modifier, move to the next key; org admin does not have power to override this value
                if (configKey.endsWith(CONFIG_DISABLED_MODIFIER)) return;
                // If a config key appears in the mods, apply the mod
                if (configMods.hasOwnProperty(configKey)) {
                    // True (enabled) if both are true
                    config[configKey] = config[configKey] && configMods[configKey]
                }
            });
            // console.debug(`Final config: ${JSON.stringify(config, null, 4)}`);
        }
        return configType === CONFIG_TYPE.TOOLBOX ? applyToToolboxXml(config) : config;
    }

    /**
     * Get the config for an org, after super-admin and admin customisations have been applied to the base config.
     * This is the version that can be used to construct a representation of the configuration for the admin user to edit.
     * It therefore contains all configurable options for this org, and admin customisation override the defaults.
     * @param {*} configType
     * @returns JSON config representation showing the selections made at the user's level
     */
    getEditableConfig(configType=CONFIG_TYPE.FEATURES) {
        let config = this.getBaseConfig(configType);
        // If the configuration type has mods in the org's configuration, apply them to override the base config
        if (this.configuration.hasOwnProperty(configType)) {
            let configMods = this.configuration[configType];
            Object.keys(config).forEach(configKey => {
                // If the base config specifies a disabled modifier, remove it; org admin does not have power to override this value
                if (configKey.endsWith(CONFIG_DISABLED_MODIFIER)) {
                    console.debug(`Deleting ${configKey}`);
                    delete config[configKey];
                    return
                }
                // If a config key appears in the mods, apply the mod over the base value
                if (configMods.hasOwnProperty(configKey)) {
                    config[configKey] = configMods[configKey]
                }
            });
        }
        return configType===CONFIG_TYPE.FEATURES ? this._formatFeaturesForEditor(config) : config;
    }

    /**
     * Save user level config selections to database.
     * @param {*} config a JSON config
     */
    saveCustomConfigForOrg(config, configType) {
        if (configType===CONFIG_TYPE.FEATURES) {
            config = this._formatEditorFeaturesForSaving(config);
        }
        this.configuration[configType] = config;
        console.info(`Saving custom config ${configType}`);
        let field = `${ORG_CONFIG_FIELD}.${configType}`;
        console.debug(`Saving custom config to ${field}`);
        // Note to use the dynamic field name it must be enclosed within square brackets as follows:
        this.orgDoc.update({[field]: config});
        // Alternatively, if there's a chance the field is a string, we should update the whole field from this.configuration
    }

    /**
     * A utility method to turn a features config into a format that is preferable for the UI editor.
     * For example, turn this:
     *
     * {
     *   "downloads.assetDownload": true,
     *   "downloads.assetDownload.disabled": true,
     *   "downloads.lineDrawingDownload": false
     * }
     *
     * into this:
     * {
     *   "downloads.assetDownload": {
     *     name: 'Asset Download',
     *     isAvailable: true,
     *     isDisabled: true
     *   },
     *   "downloads.lineDrawingDownload": {
     *     name: 'Area Download',
     *     isAvailable: false
     *   }
     * }
     *
     * using names defined in constants.js.
     *
     * @returns
     */
    _formatFeaturesForEditor(config) {
        let formattedConfig = {};
        let disabledFeatures = [];
        for (let feat in config) {
            // If the entry is a ".disabled" entry and is true, record the feature in the list
            if (feat.endsWith(CONFIG_DISABLED_MODIFIER)) {
                if (config[feat] == true) {
                    feat = feat.substring(0, feat.length - CONFIG_DISABLED_MODIFIER.length);
                    disabledFeatures.push(feat);
                }
                continue;
            }
            // If it's a standard feature entry, record its name and availability
            formattedConfig[feat] = {
                'name': FEATURE_CONFIGS[feat].description || feat,
                'isAvailable': config[feat]
            };
        }
        // Add disabled property to listed features
        // but don't include superadmin-only features
        disabledFeatures.filter(f => !FEATURE_CONFIGS[f].superadmin).forEach(feat => {
            // Add the property to an existing feature
            if (formattedConfig[feat]) {
                formattedConfig[feat]['isDisabled'] = true;
            } else {
                // Or create a feature entry, defaulting to available
                formattedConfig[feat] = {
                    'name': FEATURE_CONFIGS[feat].description || feat,
                    'isAvailable': true,
                    'isDisabled': true,
                };
            }
        });
        return formattedConfig;
    }

    /**
     * The inverse of _formatFeaturesForEditor; turn a features config from the editor into a format for the db.
     * For example, turn this:
     *
     * {
     *   "downloads.assetDownload": {
     *     name: 'Asset Download',
     *     isAvailable: true,
     *     isDisabled: true
     *   },
     *   "downloads.lineDrawingDownload": {
     *     name: 'Area Download',
     *     isAvailable: false
     *   }
     * }
     *
     * into this:
     * {
     *   "downloads.assetDownload": true,
     *   "downloads.assetDownload.disabled": true,
     *   "downloads.lineDrawingDownload": false
     * }
     *
     * @returns
     */
    _formatEditorFeaturesForSaving(formattedConfig) {
        let config = {};
        for (let feat in formattedConfig) {
            let spec = formattedConfig[feat];
            config[feat] = spec['isAvailable'];
            if (spec['isDisabled']) {
                config[feat+CONFIG_DISABLED_MODIFIER] = spec['isDisabled'];
            }
        }
        return config;
    }

    /**
     * Stringify a JSON object or string representation with formatting, for pretty printing.
     * @param {*} json
     * @returns
     */
    _jsonPrettify(json) {
        try {
            return json ? JSON.stringify(JSON.parse(json), null, 2) : "";
        } catch {
            return json ? JSON.stringify(json, null, 2) : "";
        }
    }

}


/**
 * Create an OrgConfig object, initialize it with Firestore data,
 * and return it when it's initialized.
 * This method is asynchronous; it returns a Promise which will return
 * the OrgConfig when it is fully initialized.
 * @async
 */
export async function createOrgConfig(orgId) {
    const orgConfig = new OrgConfig(orgId);
    await orgConfig.initialize();
    return orgConfig;
}
