<template>
    <div class="table-creator">
        <TableWorkspace 
            :datasets="datasets"
            :createdRows="createdRows"
            :tableDescription="tableDescription"
            @update-rows="(rows) => $emit('update-rows', rows)"
            @update-preview="(table, rowCount) => updatePreview(table, rowCount)"
            @update-description="(description, isValid) => $emit('update-description', description, isValid)"
        />

        <!-- <TablePreview :table="previewTable" :rowCount="rowCount"/> -->
    </div>
</template>

<script>
import TableWorkspace from "@/components/TableCreator/TableWorkspace.vue";
//import TablePreview from "@/components/TableCreator/TablePreview.vue";

/* 
Schema of dummy data is of the form:
{
    "datasets": [
        {
            "title": "string",
            "type": "image_collection",
            "id": "string",
            "bands": [
                {
                    "name": "string",
                    "ebx:datatype": "thematic",
                    "gee:classes": {
                        class_number: {
                            "color": "string",
                            "description": "string"
                        }
                    }
                },
                {
                    "name": "string",
                    "ebx:datatype": "numeric",
                    "ebx:units": "string",
                }
            ],
            "multi_image_temporal": {
                "temporal": [
                    {
                        "name": "string",
                        "interval": {
                            "start": "string",
                            "end": "string"
                        }
                    }
                ]
            }
            
        },
        {
            "title": "string",
            "type": "feature_collection",
            "id": "string",
            "attributes": [
                {
                    "name": "string",
                    "ebx:datatype": "thematic",
                    "classes": {
                        "class": {
                            "color": "string",
                            "description": "string",
                            "short_description": "string"
                        }
                    }
                },
                {
                    "name": "string",
                    "datatype": "numeric"
                },
            ]
        }
    ]
}
 */
export default {
    name: 'TableCreator',
    components: {
        TableWorkspace,
        //TablePreview
    },
    props: {
        datasets: {
            type: Array,
            default: () => []
        },
        createdRows: {
            type: Array,
            default: () => []
        },
        tableDescription: {
            type: String,
            default: ''
        }
    },
    emits: [
        'update-rows',
        'update-description'
    ],
    data() {
        return {
            table: {},
            previewTable: {},
            rowCount: 0
        }
    },
    methods: {
        updatePreview(table, rowCount) {
            this.previewTable = table;
            this.rowCount = rowCount;
        }
    }

}
</script>