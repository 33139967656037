import {FieldNoTrimDropdown} from "@/fields/FieldNoTrimDropdown";
import { RegistryService } from "@/services/registry.service";
import { VariablesService } from "@/services/variable.service";
import { VARIABLE_BLOCKLY_WARNING_PREFIX } from "@/constants/nextGenConstants";

/**
 * A set of functions to be added to a block to provide block_state management.
 * example:
 * 
 * Blockly.Blocks['my_awesome_block'] = {
 *      ...AbstractBlock,
 * }
 */
export const AbstractBlock = {
    /**
     * block state is stored in this variable as extraState. 
     * this is also used to store other "runtime" variables. used by this set of methods e.g. stateChanged.
     * This should not really be accessed directly but interacted with through the methods below.
     */
    block_state: {},
    /**
     * Standard Blockly init method. 
     * Use onInit to provide your own init functionality to blocks
     */
    init: function() {
        this.block_state = {
            extraState: {},
            stateChanged: {},
            ignoredStateKeysOnSave: [],
            isInit: false,
            isLoadState: false,
        }
        if(typeof this.onInit === 'function') {
            this.block_state.isInit = true;
            this.onInit();
            this.block_state.isInit = false;
        }
        
        if(typeof this.onDispose === 'function') {
            const oldDispose = this.dispose;
            this.dispose = (healStack) => {
                this.onDispose();
                oldDispose.call(this, healStack);
            }
        }
    },
    /**
     * Handles saving state for blockly. 
     * @returns Object
     */
    saveExtraState: function() {   
        let extraState =  Object.assign({}, this.block_state.extraState);
        if(this.block_state.ignoredStateKeysOnSave.length > 0) {
            this.block_state.ignoredStateKeysOnSave.forEach(k => {
                if(extraState[k] !== undefined) {
                    delete extraState[k]
                }
            })
        }
        if(typeof this.onSaveExtraState === 'function') {
            extraState = this.onSaveExtraState(extraState)
        }
        return extraState
    },
    /**
     * Loading state from blockly. Handles the setup of state and if its has changed. 
     * This tries to standarise all the ways blockly loads state so `hasStateChanged` is consitant as possible.
     * @param {*} state 
     */
    loadExtraState: function(state) {
        if (Object.keys(state).length === 0) {
            return;
        }

        state = Object.assign({}, state);
        if(typeof this.onLoadedFieldData === 'function') {
            setTimeout(() => {
                this.onLoadedFieldData(state)
            })
        }

        if(typeof this.onLoadExtraState === 'function') {
            state = this.onLoadExtraState(state);
        }

        Object.keys(state).forEach(k => this.setState(k, state[k]));
        this.block_state.isLoadState = true;
        if(typeof this.updateShape_ === 'function') {
            this.updateShape_();
        }
        this.block_state.isLoadState = false;
        this.block_state.stateChanged = {};
    },
    /**
     * Used to set state on the block. Anything that needs to be tracked for changes needs to be added to state.
     * This is used primerally in PopulateStateVisitor to set any outside variables.
     * This can also be used in validators to set state of fields, which can be used to check for changes and get the state value back.
     * 
     * The reason to set a field value in state is because when loading state from a saved workflow for example.
     * Blocks sometimes need to know a fields value to create additional field logic. Blockly when loading state will load state and the fields after
     * It is not consitant that getFieldValue will always be correct.
     * @param {string|object} key
     * @param {*} value 
     * @returns {self}
     */
    setState: function(variableName, value = null, ignoreSaveState = false, forceSetObject = false) {
        if(typeof variableName !== 'string' && forceSetObject === false) {
            // check if variableName is an object and not an array
            if(variableName instanceof Object && !Array.isArray(variableName) && variableName !== null) {
                // if it then loop through and call setState for each key value pair
                Object.keys(variableName).forEach(k =>  this.setState(k, variableName[k], ignoreSaveState))
                return this;
            }

            throw new Error('setState variableName must be a string or object')
        }

        if(this.block_state.isInit === false) {
            if(this.block_state.extraState[variableName] === undefined) {
                this.block_state.stateChanged[variableName] = value
            }else if(JSON.stringify({value}) !== JSON.stringify({value: this.block_state.extraState[variableName]})){
                this.block_state.stateChanged[variableName] = this.block_state.extraState[variableName] 
            }
            if(ignoreSaveState) {
                if(this.block_state.ignoredStateKeysOnSave.indexOf(variableName) < 0) {
                    this.block_state.ignoredStateKeysOnSave.push(variableName)
                }
            } else {
                const ignoreKeyIdx = this.block_state.ignoredStateKeysOnSave.indexOf(variableName)
                if(ignoreKeyIdx >=0) {
                    this.block_state.ignoredStateKeysOnSave.splice(ignoreKeyIdx, 1)
                }
            }
        }
        this.block_state.extraState[variableName] = value
        return this;
    },

    setNonPersistentState(variableName, value) {
        return this.setState(variableName, value, true)
    },

    /**
     * Convenience method to check if a block is in a loadingState. intended to be used in updateShape_
     * @returns 
     */
    isLoadingState: function() {
        return this.block_state.isLoadState
    },

    /**
     * 
     * @returns Get if we are loading the workflow or not via the project loader
     */
    isLoadingWorkflow: function() {
        return RegistryService.getLoadingWorkflow() || false
    },
    /**
     * Tries to get a value from block state. If it does not exist use the default value
     * @param {*} variableName 
     * @param {*} defaultValue 
     * @returns {*}
     */
    getState: function(variableName, defaultValue = null) {
        if(this.hasState(variableName)) {
            return this.block_state.extraState[variableName]
        }
        return defaultValue
    },
    /**
     * Remove a value from the state by totally deleting it.
     * @param {*} variableName 
     * @returns {self}
     */
    removeState: function(variableName) {
        if(this.block_state.extraState[variableName] !== undefined) {
            delete this.block_state.extraState[variableName]
        }
        return this
    },
    /**
     * Convenience method to be able 
     * @param {*} variableName 
     * @returns {Boolean}
     */
    hasState: function(variableName) {
        return this.block_state.extraState[variableName] !== undefined;
    },
    /**
     * Used to tell if a state value has changed between the last time it was set.
     * @param {*} variableName 
     * @returns {Boolean}
     */
    hasStateChanged: function(variableName = null) {
        if(variableName === null) {
            return Object.keys(this.block_state.stateChanged).length > 0;
        }
        return this.block_state.stateChanged[variableName] !== undefined;
    },
    /**
     * This is added to PopulateStateVisitor after you have set all the setState methods.
     * This will only trigger an update on the block if anything has changed in the state.
     * @returns {self}
     */
    updateShapeOnChange: function() {
        if(this.hasStateChanged() && typeof this.updateShape_ === 'function') {
            this.updateShape_()
        }
        this.block_state.stateChanged = {}
        return this;
    },

    /**
     * Helper function to get the warning text from a block. 
     * @returns {Object}
     */
    getWarningText() {
        if(this.warning === null) {
            return null
        }
        const warningText = this.warning.text
        if (warningText === null) {
            return null
        }
        if(typeof warningText === 'object') {
            return warningText
        }
        return  {
            default: warningText
        }
    }
}


/**
 * A set of convenience functions to make working with blockly a much nicer experiance
 * example:
 * 
 * Blockly.Blocks['my_awesome_block'] = {
 *      ...AbstractFieldHelpers,
 * }
 */
export const AbstractFieldHelpers = {
    /**
     * if using a FieldNoTrimDropdown this will update the options if the field exists
     * @param {*} fieldName 
     * @param {*} options 
     * @returns 
     */
    updateOptionsIfSet(fieldName, options) {
        if(this.fieldExists(fieldName)) {
            const field = this.getField(fieldName)
            field.updateOptions(options)
            return field;
        }
        return null
    },
    /**
     * Remove a input on the block if it only exists
     * @param {*} inputName 
     * @returns 
     */
    removeInputIfExists(inputName) {
        if(this.inputExists(inputName)){
            this.removeInput(inputName)
        }
        return this
    },
    /**
     * Create a dropdown field with a default value. This does not trigger a change event on the block.
     * This is because the field it not yet attached to an input/block.
     * This is to be combined with:
     *      this.getInput(INPUT.IMAGETYPE).appendField(this.createDropdownWithValue(options, value))
     * @param {*} options 
     * @param {*} defaultValue 
     * @returns 
     */
    createDropdownWithValue(options, defaultValue = null) {
        const field = new FieldNoTrimDropdown(options);
        field.setValue(defaultValue)
        return field;
    },
    /**
     * convenience method to check if a field exists on a block
     * @param {*} fieldName 
     * @returns 
     */
    fieldExists(fieldName) {
        return this.getField(fieldName) !== null
    },
     /**
     * convenience method to check if a input exists on a block
     * @param {*} fieldName 
     * @returns 
     */
    inputExists(inputName) {
        return this.getInput(inputName) !== null
    },
     /**
     * moves a input to after a specified block.
     * @param {*} fieldName 
     * @returns 
     */
    moveInputAfter(input, parentInput) {
        if(input && parentInput) {
            this.moveInputBefore(input,parentInput)
            this.moveInputBefore(parentInput, input)
        }
        return this
    },
    /**
     * trigger a validator on a field without setting value. This will run the validator but not trigger an
     * update event on blockly. 
     * 
     * This is used when you create a field which has set a default value and you need
     * to use that field to update/create other fields on the block. 
     * 
     * Once the field has been created as the validator is already attached there is no need to call this.
     * @param {*} fieldName 
     * @returns 
     */
    triggerValidator(fieldName) {
        const field = this.getField(fieldName)
        if(field) {
            const validator = field.getValidator();
            if(validator) {
                validator.call(field, field.getValue());
            }
        }
        return this
    },
    /**
     * Override the default behaviour of blockly to update disabled state on collapsed blocks.
     */
    updateDisabled() {
        const children = this.getChildren(false);
        this.applyColour();
        // commented code left in to show what was changed.
        // if (this.isCollapsed()) {
        //   return;
        // }
        for (let i = 0, child; (child = children[i]); i++) {
          if (child.rendered) {
            child.updateDisabled();
          }
        }
    },
    updateLabel(fieldName, label) {
        this.setFieldValue(label, fieldName)
    }
}

/***
 * A set of convenience functions to make working with variables in blockly a much nicer experience
 *
 */
export const AbstractVariableHelpers = {
    /**
     * Gets the variables from the block
     * @returns {Array<Variable>}
     */
    getVariables: function(includeDeleted = true) {
        return this.inputList
            .map(i => i.fieldRow)
            .flat()
            .map(f => typeof f.isVariable === 'function' ? f.isVariable(includeDeleted) ? f.getVariable(includeDeleted) : null : null)
            .filter(v => v !== null)
    },
    /**
     * Returns the variable id of a field if it is a variable and is set
     * @returns {Array<string>}
     */
    getVariableIds: function(includeDeleted = true) {
        return this.getVariables(includeDeleted).map(v => v.getId())
    },

    /**
     * Get all defined variabels in the variable service regardless of those set in the block
     * @param {*} includeDeleted 
     * @returns 
     */
    getAllVariableIds: function(includeDeleted = true) {
        return VariablesService.getVariables(includeDeleted).map(v => v.getId())
    },

    /**
     * Method to append a warning to a variable in variables.service
     * @param {String} variableId 
     * @param {String} warning
     * @param {String} key
     */
    appendWarningToVariable(variableId, warning, key) {
        // get the variable from the service
        let variable = VariablesService.getVariableById(variableId);
        // append the warning to the variable
        if(variable) {
            variable.appendWarning(warning, VARIABLE_BLOCKLY_WARNING_PREFIX + '_' + this.id + '_' +key);
        }
    },
    /**
     * Method to remove a warning from a variable in variables.service
     * @param {String} variableId 
     * @param {String} key
     */
    removeWarningFromVariable(variableId, key) {
        // get the variable from the service
        let variable = VariablesService.getVariableById(variableId);
        // remove the warning from the variable
        if(variable) {
            variable.removeWarning(VARIABLE_BLOCKLY_WARNING_PREFIX + '_' + this.id + '_' +key);
        }
    },

    /**
     * get the variable by id and return the value
     * @param {*} variableId 
     * @returns 
     */
    getVariableValueById(variableId) {
        let variable = VariablesService.getVariableById(variableId);
        if(variable) {
            return variable.getValue();
        }
        return null;
    }, 

        /**
     * get the variable by id and return the type
     * @param {*} variableId 
     * @returns 
     */
    getVariableTypeById(variableId) {
        let variable = VariablesService.getVariableById(variableId);
        if(variable) {
            return variable.getType();
        }
        return null;
    }

}