<template>
    <div class="ebx-search-bar" :class="{'no-search': hideSearch}">
        <div v-if="hideSearch === false" class="ebx-search-bar--layout-item ebx-search-bar--search-container">
            <slot>
                <div class="ebx-search-bar--search-input">
                    <md-icon>search</md-icon>
                    <input type="text" :placeholder="placeholder" v-model="form.searchInput" @keydown="$emit('keydown', $event)" @keyup="$emit('keyup', $event)">
                </div>
            </slot>
        </div>
        <div v-if="hasFilterOptions && hideOptions === false" class="ebx-search-bar--layout-item ebx-search-bar--option-container">
            <div class="ebx-search-bar--filter-inline">
                <div class="label">
                    {{ filterLabel }}
                </div>
                <div>
                    <md-field>
                        <md-select v-model="form.filterBy">
                            <md-option v-for="option in filterOptions" :value="option.value" :key="option.value">{{ option.label }}</md-option>
                        </md-select>
                    </md-field>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import valueMixin from '@/components/mixins/valueMixin'
export default {
    props: {
        modelValue: {
            type: Object,
            required: true
        },
        filterLabel: {
            type: String,
            default: 'Filter by'
        },
        filterOptions: {
            type: Array,
            default: null
        },
        hideSearch: {
            type: Boolean,
            default: false
        },
        hideOptions: {
            type: Boolean,
            default: false
        }, 
        placeholder: {
            type: String,
            default: 'Search'
        }
    },
    mixins: [valueMixin],
    emits: [
        'update:modelValue',       
        'keydown',
        'keyup'
    ],
    computed: {
        hasFilterOptions() {
            return this.filterOptions !== null
        },
        form: {
            get() {
                return this.value
            },
            set(form) {
                this.$emit('update:modelValue', form)
            }
        },
        searchTerm: {
            get() {
                return this.form.searchTerm
            },
            set(value) {
                this.form = Object.assign({}, this.form, {
                    searchTerm: value
                })
            }
        },
        filterBy: {
            get() {
                return this.form.option
            },
            set(value) {
                this.form = Object.assign({}, this.form, {
                    filterBy: value
                })
            }
        }
    }
}
</script>