<template>
    <div class="calculator--operators--container">
        <div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('+')">+</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('-')">-</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('(')">(</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('*')">*</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('/')">/</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator(')')">)</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('**')">y<sup>x</sup></md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('sqrt')">sqrt</md-button>
            </div>
        </div>
        <div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('sin')">sin</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('cos')">cos</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('tan')">tan</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('asin')">asin</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('acos')">acos</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('atan')">atan</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('log10')">log<sub>10</sub></md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('log')">log</md-button>
            </div>
        </div>
        <div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('min')">min</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('==')">==</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('!=')">!=</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('max')">max</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('<')">&lt;</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('>')">&gt;</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('abs')">abs</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('<=')">&lt;=</md-button>
            </div>
            <div class="button-container">
                <md-button class="md-primary" @click="handleOperator('>=')">&gt;=</md-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    emits: [
        'add-operator'
    ],
    methods: {
        handleOperator(operator) {
            this.$emit('add-operator', operator)
        }
    }
}

</script>