
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import {AbstractBlock, AbstractFieldHelpers} from './_abstractBlock';
import ThematicProcessing from '@/modals/ThematicProcessing.vue'
import { ContentService } from '@/services/content.service';
import assets from '@/assets.js';

const FIELD = {
    MODAL: 'thematic_processing'
}

var thematicProcessingJson ={
    "type": "analysis_thematic_processing",
    "lastDummyAlign0": "RIGHT",
    "message0": "%1 %2",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "thematic_title",
            "text": "%{BKY_ANALYSIS_THEMATIC_PROCESSING_BLOCK_TITLE}",
            "class": "boldTitleField"
        },
        {
            "type": "field_modal",
            "name": FIELD.MODAL,
            "src": assets.blockly.settingsWhite24dp,
            "width": 24,
            "height": 24,
            "opt_alt": "ALT STRING",
            "modal_component": ThematicProcessing,
            "modal_data": {
                newBandName: null,
                band: null,
                thresholds : [
                    {
                        id: 0,
                        minThreshold: 0,
                        maxThreshold: 1,
                        value: 0,
                        description: 'Class One',
                        color: '#FF0000'
                    },
                    {
                        id: 1,
                        maxThreshold: 3,
                        value: 1,
                        description: 'Class Two',
                        color: '#b4b4b4'
                    }
                ]
            }
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "classify",
    "tooltip": "",
    "helpUrl": ""
}




Blockly.Blocks['analysis_thematic_processing'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    onInit: function() {
        this.jsonInit(thematicProcessingJson)
        this.about_block_url = ContentService.getAboutBlockUrl('analysis_thematic_processing')
    },
    accept: async function (visitor) {
        console.log('accept analysis_thematic_processing visitor')
        await visitor.visitAnalysisThematicProcessingBlock(this);
    },
    ebxValidate: function(tooltips) {
        this.setWarningText(null, 'not_raster')
        const isRaster = this.getState('isRaster')
        if(!isRaster) {
            this.setWarningText(tooltips['not_raster'] || 'This block is only compatible with raster datasets.', 'not_raster')
            return
        }
        
        const modalField = this.getFieldValue(FIELD.MODAL)
        if(modalField !== null) {
            if(modalField.newBandName === null || modalField.newBandName.length === 0) {
                return this.setWarningText(tooltips['no_band_name_defined'] || 'Add a name for the band', 'no_band_name_defined')
            }
            if(modalField.band === null || modalField.band.length === 0) {
                return this.setWarningText(tooltips['no_band_name'] || 'Choose a band', 'no_band_name')
            }

            
            if(Array.isArray(modalField.thresholds) && modalField.thresholds.length >= 2) {
                let invalidValueCount = 0
                let existingValues = []
                let currentMinValue = -1
                let thresholdsInvalid = false
                const lastThresholdIds = modalField.thresholds - 1
                
                modalField.thresholds.forEach((threshold,rowIdx) => {
                    if(isNaN(parseInt(threshold.value))) {
                        invalidValueCount++
                    } else if (existingValues.indexOf(threshold.value) >=0) {
                        invalidValueCount++
                    }
                    existingValues.push(threshold.value)
                    if(threshold.minThreshold) {
                        currentMinValue = threshold.minThreshold
                    } else if(rowIdx === 0) {
                        currentMinValue = -999999
                    }
                    let checkLastValue = rowIdx < lastThresholdIds || [null,''].indexOf(threshold.maxThreshold) === -1
                    if(checkLastValue && currentMinValue >= threshold.maxThreshold) {
                        thresholdsInvalid = true
                    }

                    currentMinValue = threshold.maxThreshold
                })

                if(invalidValueCount > 0) {
                    return this.setWarningText(tooltips['thematic_thresholds_invalid_values'] || 'Choose unique values for each class', 'thematic_thresholds_invalid_values')
                }
                if(thresholdsInvalid) {
                    return this.setWarningText(tooltips['thematic_thresholds_invalid_thresholds'] || 'Choose a valid range for each threshold', 'thematic_thresholds_invalid_thresholds')
                }

            }else {
                return this.setWarningText(tooltips['thematic_thresholds_invalid'] || 'Thresholds are invalid', 'thematic_thresholds_invalid')
            }

            this.setWarningText(null, 'no_band_name_defined')
            this.setWarningText(null, 'no_band_name')
            this.setWarningText(null, 'thematic_thresholds_invalid')
            this.setWarningText(null, 'thematic_thresholds_invalid_values')
            this.setWarningText(null, 'thematic_thresholds_invalid_thresholds')
        }
        return true
    },
    updateShape_: function() {
        if(this.isLoadingState()) {
            this.setNonPersistentState('loadingState', true)
        }

        if(this.hasStateChanged('bands')) {
            this.getField(FIELD.MODAL).setModalData('bands', this.getState('bands'))
        }
        if(this.hasStateChanged('min_max') && this.hasState('loadingState') === false) {
            const min_max = this.getState('min_max')
            if (typeof min_max === 'object') {
                this.updateMinMaxSettings_(min_max.min || 0, min_max.max || 0)
            }
        }
        if(this.hasState('loadingState') && this.isLoadingState() === false) {
            this.removeState('loadingState')
        }
    },
    updateMinMaxSettings_: function(min, max) {
        const modelData = this.getFieldValue(FIELD.MODAL)
        const divisor = 10
        const range = (max + min) / 2
        const mid = (range * divisor).toFixed(0) / divisor //round to 1dp

        modelData.thresholds[0].minThreshold = min
        modelData.thresholds[0].maxThreshold = mid
        modelData.thresholds[1].maxThreshold = max

        this.setFieldValue(modelData, FIELD.MODAL)
    },
    onSaveExtraState(state) {
        state.modalField = this.getFieldValue(FIELD.MODAL)
        return state
    },
    onLoadExtraState(state) {
        if(state.modalField) {
            this.setFieldValue(state.modalField, FIELD.MODAL)
        }
        // remove modalField from state
        delete state.modalField
        return state
    }
}; 