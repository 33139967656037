<template>
    <md-dialog v-model:md-active="showDialog" :md-close-on-esc="closeOnEsc" :md-click-outside-to-close="closeOnClickOutside" @md-closed="handleDismissAction" class="ebx-dialog dialog-confirmation modal-m">
        <md-dialog-title v-if="hideTitle === false">
            <div class="top-actions--container">
                <p class="ebx-header-2">{{ title }}</p>
                <div class="close-button">
                    <md-button class="md-primary" @click="handleDismissAction()"><md-icon>close</md-icon></md-button>
                </div>
            </div>
        </md-dialog-title>
        <div class="ebx-dialog--content" v-html="dialogContent">
        </div>
        <md-dialog-actions>
            <slot name="actions">
                <button class="ebx-button ebx-button--primary" @click.prevent="handleDismissAction()">
                    {{ okButtonText }}
                </button>
            </slot>
        </md-dialog-actions>
    </md-dialog>
</template>
<script>
import valueMixin from '@/components/mixins/valueMixin'

export default {
    props: {
        hideTitle: {
            type: Boolean,
            default: false
        },
        hideCloseButton: {
            type: Boolean,
            default: false
        },
        closeOnEsc: {
            type: Boolean,
            default: true
        },
        closeOnClickOutside: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: "What’s new in Earth Blox"
        },
        okButtonText: {
            type: String,
            default: 'Got it'
        },
        modelValue: {
            type: Boolean,
            default: false
        },
        dialogContent: {
            type: String,
            default: null
        },
    },
    mixins: [valueMixin],
    emits: [
        'dismiss-product-update',
        'update:modelValue'
    ],
    data() {
        return {
            text: null,
            resolve: null,
            reject: null,
            showDialog: false,
            tempText: null
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.showDialog = value
            }
        },
        showDialog(value) {
            this.$emit('update:modelValue', value)
        }
    },
    methods: {
        handleDismissAction() {
            this.showDialog = false;
            this.$emit('dismiss-product-update');
        },
    }
}

</script>