<template>
    <div id="Projects" class="projects-page">
        <div class="projects">
            <div class="projects--menu-section">
                <ul class="projects--list">
                    <li v-if="!isExplorer" class="projects--list-item" :class="currentPage === 'projects' ? 'active' : ''" @click="goToPage('/projects')">
                        <p :class="currentPage === 'projects' ? 'ebx-primary-bold' : 'ebx-primary'">Projects</p>
                    </li>
                    <li class="projects--list-item" :class="currentPage === 'explore' ? 'active' : ''" @click="goToPage('/projects/explore')">
                        <p :class="currentPage === 'explore' ? 'ebx-primary-bold' : 'ebx-primary'">Explore</p>
                    </li>
                    <li class="projects--list-item" :class="currentPage === 'assets' ? 'active' : ''" @click="goToPage('/projects/assets')">
                        <p :class="currentPage === 'assets' ? 'ebx-primary-bold' : 'ebx-primary'">Assets</p>
                    </li>
                    <li class="projects--list-item" :class="currentPage === 'exports' ? 'active' : ''" @click="goToPage('/projects/exports')">
                        <p :class="currentPage === 'exports' ? 'ebx-primary-bold' : 'ebx-primary'">Exports</p>
                    </li>
                </ul>
            </div>
            <div class="projects--content-section">
                <router-view class="projects--content-pages"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import authMixin from '@/components/mixins/authMixin';
import { isNotNullOrUndefined } from '@/helpers/generalHelperFunctions.js'
 
export default {
    data() {
        return {
            currentPage: '',
            currentPath: '',
        }
    },
    mixins: [authMixin],
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (isNotNullOrUndefined(to)) { 
                    const pathEls = to.path.split('/');
                    this.currentPath = to.path;
                    if (pathEls.findIndex(el => el === 'assets') > -1) {
                        this.currentPage = 'assets';
                    } else if (pathEls.findIndex(el => el === 'exports') > -1) {
                        this.currentPage = 'exports';
                    } else if (pathEls.findIndex(el => el === 'dataset') > -1) {
                        this.currentPage = 'assets';
                    } else if (pathEls.findIndex(el => el === 'explore') > -1) {
                        this.currentPage = 'explore';
                    } else if (pathEls.findIndex(el => el === 'projects') > -1) {
                        this.currentPage = 'projects';
                    } else {
                        this.currentPage = '';
                    }
                }
            },
        },
    },
    methods: {
        goToPage(path) {
            if (this.currentPath !== path) {
                this.$router.push(path);
            }
        },
    },
}
</script>
