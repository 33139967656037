<template>
    <md-dialog class="transform-dialog" v-model:md-active="showDialog">

        <md-dialog-content class="transform-dialog--container">
            <slot />
        </md-dialog-content>
    
        <md-dialog-actions>
            <md-button class="md-primary" @click="showDialog = false">Cancel</md-button>
            <md-button class="md-primary" @click="handleSave">OK</md-button>
        </md-dialog-actions>
    </md-dialog>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/

import valueMixin from '@/components/mixins/valueMixin'

export default {
    name: 'TransformDialog',
    props: {
        modelValue: {
            type: Boolean,
            required: true
        }
    },
    mixins: [valueMixin],
    emits: [
        'update:modelValue',
        'save'
    ],
    computed: {
        showDialog: {
            get() {
                return this.value
            },
            set(newValue){
                this.$emit('update:modelValue', newValue)
            }
        }
    },
    methods: {
        handleSave() {
            this.$emit('save')
            this.showDialog = false
        }
    }

}
</script>