import Blockly from "blockly";
import {FieldModal} from '@/fields/FieldModal';
import {FieldPlayButton} from '@/fields/FieldPlayButton';
import {FieldStopButton} from '@/fields/FieldStopButton';
import {FieldMultiDropdown} from '@/fields/FieldMultiDropdown';
import {FieldNoTrimDropdown} from '@/fields/FieldNoTrimDropdown';
import {FieldDatasetChoose} from '@/fields/FieldDatasetChoose';
import {FieldVariableDropdown} from '@/fields/FieldVariableDropdown';
import {FieldDateVariable} from "@/fields/FieldDateVariable";
import En from '@/msg/en';
import {init as BlocklyClipboardPatchInit} from './clipboard'
import {init as GesturePatchInit} from './gesture'
import {init as EBXShortcutsInit} from './shortcuts';
import { registerBlockContextMenuOptions } from "@/contextMenus";


//Register Locale Messages/Labels
Blockly.setLocale(En);

// Register Blockly Fields
Blockly.fieldRegistry.register('field_modal', FieldModal);
Blockly.fieldRegistry.register('field_play_button', FieldPlayButton);
Blockly.fieldRegistry.register('field_stop_button', FieldStopButton);
Blockly.fieldRegistry.register('field_multi_dropdown', FieldMultiDropdown);
Blockly.fieldRegistry.register('field_notrim_dropdown', FieldNoTrimDropdown);
Blockly.fieldRegistry.register('field_dataset_choose', FieldDatasetChoose);
Blockly.fieldRegistry.register('field_variable_dropdown', FieldVariableDropdown);
Blockly.fieldRegistry.register('field_date_variable', FieldDateVariable);

registerBlockContextMenuOptions();

//Monkey Patch Duplicate Button in Context Menu to Fire Event for Visitor
BlocklyClipboardPatchInit(Blockly)
// Register Blockly Shortcuts
EBXShortcutsInit(Blockly);
// Register inverse CMD+Drag behaviour on blocks
GesturePatchInit(Blockly);

