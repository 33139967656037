/**
 * Map events is used to handle edge case events globally.
 * For example, when an overlay is added to the map, it stops map events from being triggered. 
 * As such, the overlay can call the store for when events have been triggered.
 * Events are stored such that:
 * {
 *  type: string,
 *  event: any
 * }
 */

const state = {
    // events: []
    lastEvent: null
}

const getters = {
    // events: state => state.events,
    lastEvent: state => state.lastEvent
}

const mutations = {
    addEvent(state, event) {
        // state.events.push(event);
        state.lastEvent = event;
    },
    // clearEvents(state) {
    //     state.events = [];
    // }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}