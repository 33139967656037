export class Definition {
    constructor(state, definitionId, definitionName) {
        this.state = state
        this.definitionId = definitionId
        this.definitionName = definitionName
    }

    getId() {
        return this.definitionId
    }
    getName() {
        return this.definitionName
    }
    getState() {
        return this.state.state.definitions[this.definitionId]
    }

    setDefinitionOnlyState(state) {
        this.state.state.definitions[this.definitionId] = Object.assign({}, this.state.state.definitions[this.definitionId], state)
        return this
    }
}