

import { Subject } from 'rxjs';
import { RxEvents } from '../variables/providers/events'
import { VARIABLE_PREFIX } from '../constants/nextGenConstants';
import { reactive } from 'vue';

/**
 * @class VariablesServiceClass
 * @description The variables service holds a set of providers that manage variables. 
 * This is intended as a proxy between the vue js components and blockly providing a single place to manage variables.
 * 
 * This class is defined as a singleton and can be accessed via the VariablesService variable below which is exported. 
 * Providers are indented to only be added onnce where clearVaribles must be used to reset variables
 */

class VariablesServiceClass extends RxEvents {
    constructor() {
        super();
        this.variablePrefix = VARIABLE_PREFIX
        this.providers = []
        this.globalDatasetService = null;
        this.variables = {}
        this.removedVariables = [] // used to store removed variables
        this.userType = 'creator'
        this.events = {
            update: new Subject(),
            updatedValidateMessages: new Subject(),
            updatedExtraState: new Subject()
        }
    }

    /**
     * Set the user type. This is used to determine what values and components are available to the user
     * @param {*} userType 
     * @returns 
     */
    setUserType(userType) {
        if (['creator', 'explorer'].indexOf(userType) === -1) {
            throw new Error(`User type ${userType} not valid`)
        }
        if(this.userType !== userType) {
            this.userType = userType
            // update default values
            this.setVariableDefaultValues(userType)
            this.events.update.next({service: this})
        }
        return this;
    }

    /**
     * Sets variable default values. This is used to set the default value of a variable when the user type changes
     * @returns
     */
    setVariableDefaultValues(userType) {
        if (userType === 'explorer') {
            // explorers need a default but should be able to set their own value
            this.getVariables().forEach(variable => {
                if (variable.value) {
                    variable.setDefaultValue(variable.value)
                    if(variable.getSetForExplorer() === false) {
                        variable.setValue(null, true)
                    }
                }
            })
        } else {
            // creators should see the default value as the value
            this.getVariables().forEach(variable => {
                const defaultValue = variable.getDefaultValue()
                if (defaultValue) {
                    variable.setValue(defaultValue, true)
                }
            })
        }
        return this
    }

    /**
     * Get the user type
     * @returns {String}
     */
    getUserType() {
        return this.userType
    }

    /**
     * Checks if the user is a explorer
     * @returns {Boolean}
     */
    getIsExplorerMode() {
        return this.userType === 'explorer'
    }

    /*
    * registerVariable. Register a variable class with the service. This is used to create new variables in the service
     * @param {*} constructor 
     * @param {*} name 
     * @returns 
     */
    registerVariableType(constructor, name) {
        this.variables[constructor.TYPE] = {constructor, name} 
        return this
    }

    /**
     * Returns a list of variable types that have been registered with the service. this can be used to add variables to the service
     * and list the available variable types
     * @returns 
     */
    getVariableTypes(){
        return Object.keys(this.variables).map(key => {
            return {
                type: key,
                name: this.variables[key].name,
                constructor: this.variables[key].constructor
            }
        })
    }

    /**
     * addProvider. Add a provider to the service. Providers manage a set to variable classes that can be loaded and saved to various sources of the appplication
     * the provider will trigger an update event when a variable is added/updated/deleted
     * @param {*} provider 
     * @returns  self
     */
    addProvider(provider) {
        provider.setVariableService(this)
        this.providers.push(provider)
        provider.addEventListener('update', (variable) => {
            this.events.update.next({variable, provider, service: this})
        })
        provider.addEventListener('updatedValidateMessages', (variable) => {
            this.events.updatedValidateMessages.next({variable, provider, service: this})
        })
        provider.addEventListener('updatedExtraState', (variable) => {
            this.events.updatedExtraState.next({variable, provider, service: this})
        })
        provider.addEventListener('removed', (variable) => {
            variable.setDeleted()
            this.removedVariables.push(variable)
        })
        return this
    }

    /**
     * Get a provider class by scope
     * @param {*} scope 
     * @param {*} throwError 
     * @returns provider
     */
    getProviderByScope(scope, throwError = false) {
        const provider = this.providers.find(provider => provider.scope === scope)
        if(!provider && throwError) {
            throw new Error(`Provider with scope ${scope} not found`)
        }
        return provider
    }

    /**
     * Get all variables from all providers
     * @returns Array of variables
     */
    getVariables() {
        return this.providers.map(provider => provider.getVariables()).flat()
    }
    
    /**
     * Get all date variables by type from all providers
     * @param {*} type
     * @returns Array of variables
     */
    getVariablesByType(type) {
        return this.getVariables().filter(variable => variable.getType() === type)
    }

        /**
     * Get all date and date range variables by type from all providers
     * @param {*} type
     * @returns Array of variables
     */
    getVariablesByTypes(types) {
        return this.getVariables().filter(variable => types.includes(variable.getType()));
    }
    
    /**
     * Get any deleted variable by id
     * @returns Array of variables
     */
    getDeletedVariableById(id) {
        return this.removedVariables.find(variable => variable.getId() === id)
    }

    /**
     * clears all the variables from all providers
     * @returns self
     */
    clearVariables() {
        this.providers.forEach(provider => provider.clearVariables())
        this.clearDeletedVariables()
        return this
    }

    /**
     * Clears deleted variables
     * @returns 
     */
    clearDeletedVariables() {
        this.removedVariables = []
        return this
    }

    /**
     * 
     * @returns 
     */
    async validate() {
        const variables = this.getVariables()
        for(let i = 0; i < variables.length; i++) {
            const variables = variables[i]
            await variables.validate()
        }
        return this
    }
    
    /**
     * For use in vue js components. This will return an observable object that will update when variables when they are added/updated/deleted
     * usage within a vue component by adding the variable service as a data attribute
     * ```
     * data() {
     *    return {
     *       variablesService: VariablesService.getAsReactiveObject()
     *   }
     * }
     * ```
     * One can then access the variables by using `variablesService.variables`
     * ```
     * <div v-for="variable in variablesService.variables">
     *   {{ variable.title }}
     *   <input v-model="variable.value" />
     * </div>
     *  ```
     * @returns 
     */
    getAsReactiveObject() {
        const reactiveService = reactive({
            variables: this.getVariables().map(variable => variable.createReativeObject()),
            providers: this.providers.map(provider => {
                return {
                    title: provider.getTitle(),
                    scope: provider.getScope()
                }
            }),
            types: this.getVariableTypes(),
        })

        this.addEventListener('update', () => {
            reactiveService.variables = this.getVariables().map(variable => variable.createReativeObject())
        })
        this.addEventListener('updatedValidateMessages', () => {
            reactiveService.variables = this.getVariables().map(variable => variable.createReativeObject())
        })
        this.addEventListener('updatedExtraState', () => {
            reactiveService.variables = this.getVariables().map(variable => variable.createReativeObject())
        })

        return reactiveService
    }

    /**
     * Gets a variable by its id and returns the class instance of the variable
     * @param {*} id 
     * @returns 
     */
    getVariableById(id) {
        return this.getVariables().find(variable => variable.getId() === id)
    }

    /**
     * Used to add a variable to a provider. The provider is determined by the providerScope. e.g. 'project'
     * 
     * Example:
     * ```
     * VariablesService.addVariable(AreaVariable, 'project', 'My Area') // where AreaVariable is the class of the variable
     * ```
     * Or
     * ```
     * VariablesService.addVariable('area', 'project', 'My Area') // where 'area' is the type of the variable
     * ```
     * @param {*} constructor 
     * @param {*} providerScope 
     * @param {*} title 
     * @param {*} description 
     * @param {*} value (optional)
     * @return Variable
     */
    
    addVariable(constructor, providerScope, title, description, value) {
        const id = this.generateVariableId()
        if (typeof constructor === 'string' && this.variables[constructor]) {
            constructor = this.variables[constructor].constructor
        }
        if (!this.variables[constructor.TYPE]) {
            throw new Error(`Variable constructor with type ${constructor.TYPE} not found`)
        }
        return this.getProviderByScope(providerScope, true).addVariable(constructor, id, title, description, value)
    }

    /**
     * remove a variable by its id
     * @param {*} id 
     * @returns 
     */
    removeVariable(id) {
        const variable = this.getVariableById(id)
        if (!variable) {
            throw new Error(`Variable with id ${id} not found`)
        }
        this.getProviderByScope(variable.getScope(), true).removeVariable(id)
        return this
    }

    /**
     * This will load the variables from a json object. The providerScope is used to determine which provider to load the variables into e.g. "project"
     * this is intented to be used when loading a project from a the firestore database. the json will be the variables object on the firestore document
     * @param {*} providerScope 
     * @param {*} json 
     * @returns 
     */
    fromJSONForSaveScope(providerScope, json) {
        const provider = this.getProviderByScope(providerScope)
        if (!provider) {
            throw new Error(`Provider with scope ${providerScope} not found`)
        }
        this.getProviderByScope(providerScope, true).fromJSONForSave(json)
        return this
    }

    /**
     * Gets a list of variable ids
     * @returns 
     */
    getVariableIds() {
        return this.getVariables().map(variable => variable.getId())
    }


    /**
     * Generates a unique variable id using the prefix then a unique id
     * @returns 
     */
    generateVariableId() {
        const variableIds = this.getVariableIds()
        const deletedVariableIds = this.removedVariables.map(variable => variable.getId())
        const allVariables = variableIds.concat(deletedVariableIds)
        let next = allVariables.length + 1
        while (allVariables.includes(this.variablePrefix+next)) {
            next++
        }
        return this.variablePrefix + next
    }

    /**
     * reduces the variables to a json object that can be saved to a firestore document for the run doc
     * @returns 
     */
    toJSONForRunDoc() {
        return this.getVariables()
            .map(variable => {
                return {
                    key: variable.getId(),
                    type: variable.getType(),
                    value: variable.getValue(),
                }
            })
            .filter(variable => variable.value !== null)
    }

    /**
     * Formats the variables in a format that can be used in the global dataset service.
     * This is used in Blockly to display the variables in the dropdowns
     * Using the ID and this service to get a variable by id is the best way to get the variable instance
     * @returns 
     */
    async getGlobalDatasetData() {
        const variables = this.getVariables()
        return variables.map(variable => {
            return {
                id: variable.getId(),
                title: variable.getBlocklyText(),
                origin: 'workspace_variables',
                type: 'variable',
                variable: {
                    title: variable.getTitle(),
                    display_text: variable.getDisplayText(),
                    type: variable.getType(),
                    scope: variable.getScope(),
                    display_type: variable.getDisplayType(),
                    description: variable.getDescription(),
                    display_order: variable.getDisplayOrder(),
                    value: variable.getValue(),
                    default_value: variable.getDefaultValue(),
                },
                ...variable.getGlobalDatasetData()
            }
        
        })
    }

    /**
     * On update of a variable in the service, this will notify the global dataset service to re-call getGlobalDatasetData
     * @param {*} globalDatasetService 
     */
    attachGlobalDatasetUpdateListener(globalDatasetService) {
        this.globalDatasetService = globalDatasetService
        this.events.update.subscribe((data) => {
            this.globalDatasetService.change(data)
        })
    }
}

/**
 * @description The VariablesService is a singleton service that manages variables in the application.
 */
export const VariablesService = new VariablesServiceClass()