<template>
  <md-dialog
    v-model:md-active="isDialogVisible"
    :md-click-outside-to-close="false"
    @md-opened="dialogOpened"
  >
    <md-dialog-title>Change Password</md-dialog-title>
    <form id="this.form_id" @submit.prevent="save">
      <md-dialog-content>
        <md-field :class="getValidationClass('currentPassword')">
          <label>Current Password</label>
          <md-input v-model="model.currentPassword" ref="currentPassword" type="password" />
          <span class="md-error" v-if="v$.model.currentPassword.required.$invalid">Current password is required</span>          
        </md-field>

        <md-field :class="getValidationClass('newPassword')">
          <label>New Password</label>
          <md-input v-model="model.newPassword" type="password" />
          <span class="md-error" v-if="v$.model.currentPassword.required.$invalid">New password is required</span>
          <span class="md-error" v-else-if="owaspViolations.length > 0">{{ owaspViolations[0] }}</span>
        </md-field>

        <md-field :class="getValidationClass('newPasswordConfirmation')">
          <label>New Password Confirmation</label>
          <md-input v-model="model.newPasswordConfirmation" type="password" />
          <span class="md-error" v-if="v$.model.newPasswordConfirmation.required.$invalid">New password confirmation is required</span>
          <span class="md-error" v-else-if="v$.model.newPasswordConfirmation.sameAsPassword.$invalid">
            New password confirmation must match the new password.
          </span>
        </md-field>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="isDialogVisible = false">Close</md-button>
        <md-button type="submit" class="md-primary">Save</md-button>
      </md-dialog-actions>
    </form>
  </md-dialog>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { OWASP_CONFIG } from "@/constants/appConstants.js";
import * as owasp from 'owasp-password-strength-test';
owasp.config(OWASP_CONFIG);

export default {
  name: "MyAccountChangePassword",
  setup: () => ({ v$: useVuelidate() }),
  data: () => ({
    model: {},
    isDialogVisible: false,
    owaspViolations: [],
  }),
  emits: [
    "save"
  ],
  validations: {
    model: {
      currentPassword: {
        required
      },
      newPassword: {
        required,
        validatePassword: function (value) {
          let result = owasp.test(value);
          // this catches short passwords (so the previous code setting min length is removed)
          // but also catches other security problems
          this.owaspViolations = result.errors; // store the errors messages returned by owasp to display
          return result.strong; // returning this will fail the validation if result.strong is false
        }
      },
      newPasswordConfirmation: {
        required,
        sameAsPassword: function (value) {
          return value === this.model.newPassword;
        }
      }
    }
  },
  methods: {
    showDialog() {
      this.isDialogVisible = true;
    },
    dialogOpened() {
      this.model = {};

      this.v$.$reset();

      setTimeout(() => {
        this.$refs.currentPassword.$el.focus();
      }, 200);
    },
    getValidationClass(fieldName) {
      const field = this.v$.model[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    save() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.$emit("save", this.model);

      this.isDialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.md-dialog-title {
  margin-bottom: 0;
}
</style>
