<template>
    <md-dialog v-model:md-active="showDialog" class="ebx-dialog modal-m">
        <md-dialog-title>
            <div class="top-actions--container">
                Workflow settings
                <div class="close-button">
                    <md-button class="md-primary" @click.prevent="handleCancelApplySettings"><md-icon>close</md-icon></md-button>
                </div>
            </div>
        </md-dialog-title>
        <md-dialog-content>
            <!-- Dropdown field with resolution options -->
            <md-field >
                <label>Resolution</label>
                <md-select v-model="form.scale">
                    <!-- Options are: 5m, 10m, 50m, 100m, 250m, 500m, 1km, 5km -->
                    <md-option v-for="option in scaleOptions" :key="option[0]" :value="option[0]">{{ option[1] }}</md-option>
                </md-select>
                <span class="md-helper-text ebx-secondary">Workflows run at a lower resolution will run faster</span>
            </md-field>
            <!-- checkbox field to set bestEffort boolean -->
            <md-checkbox class="ebx-checkbox" v-model="form.bestEffort">
                <div class="ebx-primary-bold">Best effort</div>
                <div class="ebx-secondary">Runs the workflow at the highest possible resolution</div>
            </md-checkbox>
            <!-- number input field to set the maximim number of pixels -->
            <md-field :class="maxPixelsExceeded ? 'md-invalid' : ''">
                <label for="maxPixels">Maximum number of pixels</label>
                <md-input name="maxPixels" id="maxPixels" v-model="form.maxPixels" type="number" />
                <span v-if="maxPixelsExceeded" class="md-error">Maximum pixels cannot be higher than 100,000,000,000,000</span>
                <span v-else class="md-helper-text ebx-secondary">Increasing the maximum number of pixels allows you to export datasets with larger areas.</span>
            </md-field>
        </md-dialog-content>
        <md-dialog-actions>
            <button class="ebx-button ebx-button--secondary" @click.prevent="handleCancelApplySettings">
                Cancel
            </button>
            <button class="ebx-button ebx-button--primary"  :disabled="cannotSave" @click.prevent="handleApplySettings()">
                Apply settings 
            </button>
        </md-dialog-actions>
    </md-dialog>
</template>

<script>
import {EBX_CORE_CONFIG,CONFIG_MAX_PIXELS } from '../../constants/appConstants'
import valueMixin from '@/components/mixins/valueMixin'

export default {
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        defaultSettings: {
            type: Object,
            required: false
        }
    },
    mixins: [valueMixin],
    emits: [
        'update:modelValue',
        'apply-settings'
    ],
    data() {
        return {
            scaleOptions: [
                [5, '5m'],
                [10, '10m'],
                [50, '50m'],
                [100, '100m'],
                [250, '250m'],
                [500, '500m'],
                [1000, '1km'],
                [5000, '5km']
            ],
            form: {
                scale: EBX_CORE_CONFIG.scale,
                bestEffort: EBX_CORE_CONFIG.bestEffort,
                maxPixels: EBX_CORE_CONFIG.maxPixels
            }
        }
    },
    created() {
        if (this.defaultSettings && Object.keys(this.defaultSettings).length > 0){
            const bestEffortValid = [undefined, null, ''].includes(this.defaultSettings.bestEffort) === false
            this.form = {
                scale: this.defaultSettings.scale || EBX_CORE_CONFIG.scale,
                maxPixels: this.defaultSettings.maxPixels || EBX_CORE_CONFIG.maxPixels,
                bestEffort: bestEffortValid ? this.defaultSettings.bestEffort : EBX_CORE_CONFIG.bestEffort
            }
        }
    },
    computed: {
        showDialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        maxPixelsExceeded() {
            return this.form.maxPixels > CONFIG_MAX_PIXELS
        },
        cannotSave() {
            return this.maxPixelsExceeded
        },
    },
    methods: {
        handleApplySettings() {
            if (this.cannotSave) {
                return
            }
            this.showDialog = false
            this.$emit('apply-settings', this.form)
        },
        handleCancelApplySettings() {
            this.showDialog = false
        }
    }
}
</script>