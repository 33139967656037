import { AbstractCollection  } from "./AbstractCollection"

export class VariableCollection extends AbstractCollection {

    getVariables() {
        return this.abilities.map(ability => {
            const state = ability.getState()
            return {
                ...ability.getVariableData(),
                block_id: state.block_id,
            }
        });
    }
}