import {AbstractPopup} from "./AbstractPopup";

/**
 * Popup for a right click on the map, always passes latitude, longitude and zoom to the component.
 * Optionally passes a calculated scale in meters for the selected point at this zoom level.
 * Right click popup will also add --render-top-right, --render-bottom-right, or --render-bottom-left to your 
 * component if its about to appear off map, it's up to you to implement the styles for these classes. Pixel Inspector can be used as an example.
 */
export class RightClickPopup extends AbstractPopup {

    /**
     * Whether or not scale should be included in the popup.
     * @type {Boolean}
     */
    includeScale_;

    /**
     * @param {*} component
     * @param {Boolean} includeScale
     */
    constructor(component, includeScale=false) {
        super(component);
        this.includeScale_ = includeScale;
        this.withoutBindingClickEvents_ = false;
    }

    withoutBindingClickEvents() {
        this.withoutBindingClickEvents_ = true;
        return this
    }

    /**
     * Registers the on click handlers for the map.
     */
    register(google, map) {
        super.register(google, map);
        if( this.withoutBindingClickEvents_ === false) {
            this._google.maps.event.addListener(map, 'contextmenu', this.handleRightClick.bind(this));
        }
        this._google.maps.event.addListener(map, 'click', this.handleLeftClick.bind(this));
    }

    /**
     * On right click, show the popup, removing the previous one if it exists.
     * @param {*} event 
     */
    handleRightClick(event) {
        if (this._popup !== null) {
            this.hide();
        }

        const latLng = event.latLng;
        const zoom = this._map.getZoom();

        const latitude = latLng.lat();
        const longitude = latLng.lng();

        const props = {
            latitude,
            longitude,
            zoom
        };

        if (this.includeScale_) {
            props.scale = this.calculateScale(latitude, longitude, zoom);
        }

        this.render(latLng, props);

        // create resize observer on container div
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (this._popup === null) {
                    // popup removed/not rendered, remove observer
                    resizeObserver.unobserve(entry.target);
                    break;
                }

                const divPosition = this._popup.getElementPosition();
                const left = divPosition.x;
                const top = divPosition.y;
        
                if (left < 0 && top < 0) {
                    return;
                }
        
                // we need to check for the popup appearing to the right of the map, the bottom of the map, or both
                const mapDiv = this._map.getDiv();
                const mapWidth = mapDiv.offsetWidth;
                const mapHeight = mapDiv.offsetHeight;

                // get popup container height and width
                const popupWidth = entry.target.offsetWidth;
                const popupHeight = entry.target.offsetHeight;

                // left and top measured from centre of map so halfing the width and height
                const offRightSide = left + popupWidth > (mapWidth / 2);
                const offBottom = top + popupHeight > (mapHeight / 2);

                if (offRightSide && offBottom) {
                    this._popup.addClassToElement('render-bottom-right')
                } else if (offRightSide) {
                    this._popup.addClassToElement('render-top-right')
                } else if (offBottom) {
                    this._popup.addClassToElement('render-bottom-left')
                }

                // stop observing after the first event
                resizeObserver.unobserve(entry.target);
                break; // exit the loop after handling the first entry
            }
        });

        // start observing the popup container
        resizeObserver.observe(this._popup.containerDiv);
        return this
    }

    /**
     * Calculates the estimated scale in meters for the given latitude, longitude and zoom.
     * @param {*} latitude 
     * @param {*} longitude 
     * @param {*} zoom 
     * @returns 
     */
     
    calculateScale(latitude, longitude, zoom) {
        // https://groups.google.com/g/google-maps-js-api-v3/c/hDRO4oHVSeM/m/osOYQYXg2oUJ
        const metersPerPixel = 156543.03392 * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoom);
        return metersPerPixel;
    }

    /**
     * Hides the popup on left click anywhere on the map.
     * @returns 
     */
    handleLeftClick() {
        if (this._popup === null) {
            return;
        }

        this.hide();
    }

    handleMapEvent(event) {
        switch (event.type) {
            case "click":
                this.handleLeftClick(event.event);
                break;
            case "contextmenu":
                this.handleRightClick(event.event);
                break;
        }
    }
}