<template>
    <div class="layer-result">
        <md-card class="sub-level-card">
            <ebx-card-expand 
            v-model="expand" 
            class="layer-control--layer"  
            :class="layerClass">
                <div class="layer-control--layer-items">
                    <div class="layer-control--layer-items__lhs-cluster">
                        <!-- Expander CTA -->
                        <md-card-expand-trigger v-if="mapLayerHasExpandedDetails(mapLayer)">
                            <md-button class="ebx-button--icon">
                                <div class="sub-level folder-carat">
                                    <img v-if="!layerDisabled" :src="assets.icons.carat" alt="Toggle expanded section">
                                    <img v-else :src="assets.icons.caratDisabled" alt="Toggle expanded section">
                                </div>
                            </md-button>
                        </md-card-expand-trigger>
                        <div class="list-item-text-container" :class="[!mapLayerHasExpandedDetails(mapLayer) ? 'ml-12' : '']">
                            <p class="ebx-primary-bold">
                                    {{mapLayer.name}}
                                <md-tooltip md-direction="bottom">
                                    {{mapLayer.name}}
                                </md-tooltip>
                            </p>
                        </div>
                    </div>
                    <md-card-actions>
                        <md-button class="md-icon-button" @click="toggleLayerVisibility(mapLayer)">
                            <div>
                                <span v-if="mapLayer.opacity > 0" class="material-icons-outlined ebx-icon">visibility</span>
                                <span v-else class="material-icons-outlined ebx-icon">visibility_off</span>
                            </div>
                            <md-tooltip md-direction="bottom">
                                Toggle visibility of layer
                            </md-tooltip>
                        </md-button>
                        <!-- Context Menu CTA -->
                        <md-menu>
                            <md-button class="md-icon-button" md-menu-trigger>
                                <md-icon>more_vert</md-icon>
                            </md-button>
                            <md-menu-content class="layer-options-menu">
                                    <md-menu-item class="layer-options-menu--option" @click="downloadLayer">
                                        <span class="material-icons-outlined ebx-icon">file_download</span>
                                        <span>{{ mapLayer.isGroup ? 'Export GIF' : 'Export PNG' }}</span>
                                    </md-menu-item>
                                    <md-menu-item class="layer-options-menu--option" @click="removeLayer(mapLayer.id)">
                                        <span class="material-icons-outlined ebx-icon">delete</span>
                                        <span>Delete</span>
                                    </md-menu-item>



                                    <md-menu-item class="layer-options-menu--option layer-options-menu--option__menu" :ignore-click="true">
                                        <!-- the ignore-click prevents the click event that opens the opacity sub menu from closing its own parent -->
                                        <md-menu md-align-trigger>
                                            <div class="menu-trigger" md-menu-trigger>
                                                <button class="ebx-icon-button ebx-icon-button__no-shadow" ><span class="material-icons-outlined ebx-icon">opacity</span></button>
                                                <span>Opacity</span>
                                            </div>
                                            <md-menu-content class="opacity-menu">
                                                <md-menu-item>
                                                    <div class="opacity-container">
                                                        <input
                                                            type="range"
                                                            :value="mapLayer.opacity"
                                                            @input="updateOpacity(mapLayer, $event.target.value)"
                                                            min="0"
                                                            max="1"
                                                            step="0.01"
                                                        />
                                                        <div class="opacity-labels">
                                                            <span>0%</span>
                                                            <span>100%</span>
                                                        </div>
                                                        <md-tooltip v-if="mapLayer.isGroup">Change opacity of timeseries</md-tooltip>
                                                        <md-tooltip v-else>Change opacity of layer</md-tooltip>
                                                    </div>
                                                </md-menu-item> 
                                                
                                            </md-menu-content>
                                        </md-menu>
                                    </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </md-card-actions>
                </div> 
                <md-progress-bar 
                    v-show="showLoadingBar"
                    class="md-accent" 
                    md-mode="indeterminate" 
                    />
                <!-- Expander Hidden content -->
                <md-card-expand-content>
                    <md-card-content>
                        <div>
                            <p v-if="mapLayer.description" class="layer-description ebx-secondary">{{mapLayer.description}}</p>
                            <GoogleMapLegend
                                v-if="layerLegend(mapLayer)"
                                :legend="layerLegend(mapLayer) ? layerLegend(mapLayer) : {}"
                            />
                            <GoogleMapTimeline
                                v-if="mapLayer.isGroup"
                                :google="google"
                                :map="map"
                                :map-layer-tiles-loading="mapLayerTilesLoading"
                                :mapLayer="mapLayer"
                                :timelineDisabled="layerDisabled"
                                data-html2canvas-ignore="true"
                            />
                        </div>
                    </md-card-content>
                </md-card-expand-content>
            </ebx-card-expand>
        </md-card>
        <md-dialog-alert
            class="ebx-error-dialog"
            v-model:md-active="thumbnailError"
            md-content="There was an error exporting this layer, this may be because your image is too large"
            md-confirm-text="OKAY" />
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import GoogleMapLegend from '@/components/ResultMap/GoogleMapLegend.vue';
import GoogleMapTimeline from '@/components/ResultMap/GoogleMapTimeline.vue';
import EbxCardExpand from '@/components/EbxComponents/EbxCardExpand.vue';
import { functions } from '@/firebaseFunctions';
import assetsMixin from "@/components/mixins/assetsMixin.js" 

export default {
    mixins: [assetsMixin],
    props: {
        google: {
            type: Object,
            required: true
        },
        map: {
            type: Object,
            required: true
        },
        mapLayer: {
            type: Object,
            required: true
        },
        mapLayerTilesLoading: {
            type: Object,
            required: true
        }
    },
    components: {
        GoogleMapLegend,
        GoogleMapTimeline,
        EbxCardExpand
    },
    data() {
        return {
            expand: true,
            thumbnailLoading: false,
            thumbnailError: false,
            prevOpacity: 1
        }
    },
    methods: {
        mapLayerHasExpandedDetails(mapLayer) {
            if(mapLayer.isGroup || mapLayer.description) {
                return true
            }
            return this.layerLegend(mapLayer) !== null
        },
        updateOpacity(mapLayer, opacity) {
            // remember the previous opacity value, so we can revert to it if the user sets opacity to 0
            this.prevOpacity = mapLayer.opacity
            this.$store.commit('maplayers/updateLayer', {
                id: mapLayer.id, 
                opacity: typeof opacity === 'string' ? parseFloat(opacity) : opacity
            });
        },
        toggleLayerVisibility(mapLayer) {
            let opacity;
            // if the layer is visible, set opacity to 0
            if (mapLayer.opacity > 0) {
                opacity = 0
            } 
            // if the layer is not visible, set opacity to the previous opacity value where available
            else if (this.prevOpacity > 0) {
                opacity = this.prevOpacity
            } 
            // if the layer is not visible and there is no previous opacity value, set opacity to 1
            else {
                opacity = 1
            }
            this.updateOpacity(mapLayer, opacity)
        },
        removeLayer(layerId) {
            this.$store.commit('maplayers/removeLayer', layerId)
            this.$store.commit('maplayers/removeLegendById', layerId)
        },
        downloadLayer() {
            this.thumbnailLoading = true;
            const runid = this.$store.state.runresult.lastRunId;
            const layerName = this.mapLayer.name;

            functions.getThumbnail({
                run_id: runid,
                layer_name: layerName,
            }).then( res => res.ok ? res.blob(): Promise.reject(res))
                .then((response) => {
                const url = window.URL.createObjectURL(response);

                // create a new anchor element
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;

                if(this.mapLayer.layers.length > 1) {
                    a.download = `${layerName}.gif`;
                } else {
                    a.download = `${layerName}.png`;
                }

                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
                this.thumbnailLoading = false;
            }).catch((error) => {
                console.log(error);
                this.thumbnailLoading = false;
                this.thumbnailError = true;
            });
        },
        layerLegend(layer) {
            return this.$store.getters['maplayers/getLegendForMapId'](layer.id)
        },
    },
    computed: {
        showLoadingBar() {
            return this.thumbnailLoading;
        },
        layerClass() {
            if (this.mapLayer.opacity === 0) {
                return 'layer-control--layer__disabled'
            }
            return ''
        },
        layerDisabled() {
            return this.mapLayer.opacity === 0
        }
    }
}
</script>