import * as Blockly from 'blockly/core';

import MultiChannelMapLayer from '@/modals/MultiChannelMapLayer.vue';
import SingleChannelMapLayer from '@/modals/SingleChannelMapLayer.vue';
import SingleVectorMapLayer from '@/modals/SingleVectorMapLayer.vue';
import MultiVectorMapLayer from '@/modals/MultiVectorMapLayer.vue';
import {FieldNoTrimDropdown} from '@/fields/FieldNoTrimDropdown';
import { addModalInput, singleChannelModalData_, multiChannelModalData_ } from '@/blocks/mixins/visualisationModal';
import { PaletteService } from '@/services/palette.service';
import { find } from 'lodash'
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock'
import {VIS_BANDS_R,VIS_BANDS_G,VIS_BANDS_B, VIS_BANDS_VALUE, VIS_BANDS_PALETTE, VIS_BANDS_INVERT } from '@/constants/ebxStacConstants';
import { ContentService } from '@/services/content.service';
import { DEFAULT_LAYER_NAME, UPDATE_DEFAULT_LAYER_NAME, NON_BLOCKING_BLOCKLY_WARNING_PREFIX, NO_DATA_DROPDOWN_VALUE} from '@/constants/nextGenConstants';
import { v2ContrastVisToValue } from './helper_functions'


const INPUT = Object.freeze({
    MULTIRGB: 'multiRGB',
    SINGLEBAND: 'single_band',
    MULTIMODALINPUT: 'multi_modal_input',
    SINGLEMODALINPUT: 'single_modal_input',
    IMAGETYPE: 'image_type',
    VECTORSTYLE: 'vector_style',
    VECTORATTRIBUTE: 'vector_attribute_input',
    VECTORSINGLEMODALINPUT: 'vector_single_modal_input',
    VECTORMULTIPLEMODALINPUT: 'vector_multi_modal_input',
})
const FIELD = Object.freeze({
    IMAGETYPE: 'image_type_selection',
    VECTORSTYLE: 'vector_style',
    VECTORATTRIBUTE: 'vector_attribute',
    BAND: 'band_selection',
    BAND1: 'band_selection1',
    BAND2: 'band_selection2',
    BAND3: 'band_selection3'
})

var addMapLayerJson = {
    "type": "output_map_layer",
    "message0":`%1 %2 %3 %4 %5`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "block_title",
            "text": "%{BKY_OUTPUT_MAP_LAYER_BLOCK_TITLE}",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy",
        },
        {
            "type": "field_label_serializable",
            "name": "layer_label",
            "text": "%{BKY_OUTPUT_MAP_LAYER_NAME_LABEL}"
        },
        {
            "type": "field_input",
            "name": "layer_name",
            "text": DEFAULT_LAYER_NAME,
        },
        {
            "type": "input_dummy",
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "mapLayer",
    "tooltip": "",
    "helpUrl": ""
};


const VectorMapLayer = {
    vector_styleValidator(newValue) {
        this.getSourceBlock().vector_updateAttribute_(newValue)
        this.getSourceBlock().vector_updateModalInput_(newValue)
        this.getSourceBlock().vector_updateShape_()
        return newValue
    },
    vector_attributeValidator(newValue) {
        const block = this.getSourceBlock()
        if(block.supressAttributeValidator_ === true) {
            block.supressAttributeValidator_ = false
            return newValue
        }
        if(block.inputExists(INPUT.VECTORMULTIPLEMODALINPUT)) {
            const oldValue = block.getFieldValue(FIELD.VECTORATTRIBUTE)
            if(newValue !== oldValue) {
                const modalValue = block.getFieldValue('modal_field')
                modalValue.attribute = newValue
                modalValue.type = null
                modalValue.theme = null
                modalValue.classes = 5
                block.setFieldValue(modalValue,'modal_field')
            }
            
        }
        return newValue
    },
    vector_updateInputSelectionInput_() {
        const imageOptions = [["Single colour", "single"]]
        if(this.hasState('attributes')) {
            imageOptions.push(["Multiple colours", "multi"])
        }
        if(this.inputExists(INPUT.VECTORSTYLE) === false) {
            this.appendDummyInput(INPUT.VECTORSTYLE)
            this.getInput(INPUT.VECTORSTYLE).appendField("Style ", "style")
            const field = this.createDropdownWithValue(imageOptions, 'single')
            field.setValidator(this.vector_styleValidator)
            this.getInput(INPUT.VECTORSTYLE).appendField(field, FIELD.VECTORSTYLE)
        }
    },
    vector_updateAttribute_(style) {
        if(this.inputExists(INPUT.VECTORSTYLE)) {
            if(style === 'multi' && this.inputExists(INPUT.VECTORATTRIBUTE) === false) {
                this.appendDummyInput(INPUT.VECTORATTRIBUTE)
                this.moveInputAfter(INPUT.VECTORATTRIBUTE, INPUT.VECTORSTYLE)
                this.getInput(INPUT.VECTORATTRIBUTE).appendField("Use attribute ", "attribute")
                const field = this.createDropdownWithValue(this.getState('attributes'), FIELD.VECTORATTRIBUTE)
                field.setValidator(this.vector_attributeValidator)
                this.getInput(INPUT.VECTORATTRIBUTE).appendField(field, FIELD.VECTORATTRIBUTE)
            } else if (style !== 'multi') {
                this.removeInputIfExists(INPUT.VECTORATTRIBUTE)  
            }
        }

    },
    vector_updateModalInput_(vectorStyleSection){
        if(vectorStyleSection === 'single' && this.inputExists(INPUT.VECTORSINGLEMODALINPUT) === false) {
            if(this.inputExists(INPUT.VECTORMULTIPLEMODALINPUT)) {
                this.removeInputIfExists(INPUT.VECTORMULTIPLEMODALINPUT)
            }
            const field = this.addModalInput(this, SingleVectorMapLayer, {
                colour: '#EE7944',
                customColours: [], 
            },  INPUT.VECTORSINGLEMODALINPUT)
            this.getInput(INPUT.VECTORSINGLEMODALINPUT).setAlign(Blockly.ALIGN_RIGHT)
            field.setValueKeyValue('layerDescription','')
        }
        if(vectorStyleSection === 'multi' && this.inputExists(INPUT.VECTORMULTIPLEMODALINPUT) === false) {
            if(this.inputExists(INPUT.VECTORSINGLEMODALINPUT)) {
                this.removeInputIfExists(INPUT.VECTORSINGLEMODALINPUT)
            }
            const field = this.addModalInput(this, MultiVectorMapLayer, {
                attribute: this.getFieldValue(FIELD.VECTORATTRIBUTE),
                classes: 5,
            },  INPUT.VECTORMULTIPLEMODALINPUT)
            this.getInput(INPUT.VECTORMULTIPLEMODALINPUT).setAlign(Blockly.ALIGN_RIGHT)
            field.addEventListener('modal-save', attrs => {
                this.supressAttributeValidator_ = true
                this.setFieldValue(attrs.attribute, FIELD.VECTORATTRIBUTE)
            })
            field.setModalData('attributes', this.getState('attributes'))
            field.setValueKeyValue('layerDescription','')
        }
    },
    vector_cleanup_() {
        if (this.getState('isVector')) {
            this.removeInputIfExists(INPUT.MULTIRGB)
            this.removeInputIfExists(INPUT.SINGLEBAND)
            this.removeInputIfExists(INPUT.MULTIMODALINPUT)
            this.removeInputIfExists(INPUT.SINGLEMODALINPUT)
            this.removeInputIfExists(INPUT.IMAGETYPE)
        } else {
            this.removeInputIfExists(INPUT.VECTORATTRIBUTE)
            this.removeInputIfExists(INPUT.VECTORSTYLE)
            this.removeInputIfExists(INPUT.VECTORSINGLEMODALINPUT)
            this.removeInputIfExists(INPUT.VECTORMULTIPLEMODALINPUT)
        }
    },
    vector_updateShape_() {
        if(this.hasStateChanged('attributes')) {
            if(this.inputExists(INPUT.VECTORATTRIBUTE)) {
                this.getField(FIELD.VECTORATTRIBUTE).updateOptions(this.getState('attributes'))
            }
            
            if(this.inputExists(INPUT.VECTORMULTIPLEMODALINPUT)) {
                const field = this.getField('modal_field')
                field.setModalData('attributes', this.getState('attributes'))
            }
            if(this.inputExists(INPUT.VECTORSTYLE)) {
                const field = this.getField(FIELD.VECTORSTYLE) 
                if(this.getState('attributes').length === 0) {
                    field.updateOptions([["Single colour", "single"]])
                } else {
                    field.updateOptions([["Single colour", "single"], ["Multiple colours", "multi"]])
                }
            }


        }
        if(this.hasState('attributeData')) {
            if(this.inputExists(INPUT.VECTORMULTIPLEMODALINPUT)) {
                const field = this.getField('modal_field')
                field.setModalData('attributeData', this.getState('attributeData'))
            }
        }
        
    }
}



Blockly.Blocks['output_map_layer'] = {
    // Visulation Modal Options
    addModalInput,
    singleChannelModalData_,
    multiChannelModalData_,
    // Block State Management
    ...AbstractBlock,
    // Block Field convenience methods
    ...AbstractFieldHelpers,
    // add Vector functionality
    ...VectorMapLayer,
    
    /**
     * When the block is created this is triggered after AbstractBlock has been setup
     */
    onInit: function() {
        this.jsonInit(addMapLayerJson);
        this.singleChannelModalData = Object.assign({}, this.singleChannelModalData_)
        this.multiChannelModalData = Object.assign({}, this.multiChannelModalData_)

        this.about_block_url = ContentService.getAboutBlockUrl('output_addMapLayer')

        this.getField('layer_name').setValidator(this.layerNameValidator)
    },

    /**
     * Visitor accept method to call the visitor with providing itself
     * @param {*} visitor 
     */
    accept: async function (visitor) {
        await visitor.visitAddMapLayerBlock(this);
    },

    /**
     * Update shape is called if any state is changed in block state
     * @returns null
     */
    updateShape_: function() {
        if (this.isLoadingState()) {
            this.setNonPersistentState('isLoadingState', true)
        }

        this.updateDefaultClasses_()

        if(this.hasState('dataset_id') === false) {
            return
        }

        this.vector_cleanup_()

        if(this.getState('isVector')) {
            this.vector_updateInputSelectionInput_()
            this.vector_updateModalInput_(this.getFieldValue(FIELD.VECTORSTYLE))
            this.vector_updateAttribute_(this.getFieldValue(INPUT.VECTORSTYLE))
            this.vector_updateShape_()
        } else {
            this.updateInputSelectionInput_()
            this.updateInputBandsInput_()
            this.updateModalInput_()
            if (this.isLoadingState()){
                this.updateDefaultClasses_()
            }
        }

        

    },

    updateDefaultClasses_: function() {
        if(this.hasStateChanged('defaultClasses') || this.isLoadingState()) {
            const palette = this.getState('defaultClasses')
            const modalField = this.getField('modal_field')
            // let bandChosen = this.getFieldValue('band_selection')
            // console.log('bandChosen', bandChosen)
            // console.log("does block have lastBandSelectionValue", this)
            if(modalField) {
                const default_pallete = palette
                const modalFieldValue = this.getFieldValue('modal_field')
                if((this.isLoadingState() || this.hasState('isLoadingState')) && modalFieldValue.custom_palette) {
                    // map saved values back into the custom palette
                    Object.keys(modalFieldValue.custom_palette).forEach(k => {
                        if(palette[k]) {
                            palette[k].color = modalFieldValue.custom_palette[k]
                        }
                    })
                    this.removeState('isLoadingState')
                }
                modalField.setModalData('default_palette',default_pallete || [])
                modalFieldValue.custom_palette = this.paletteColourKeyPair(palette) || []
            }
        }
    },

    /**
     * Handle the creation/update of the Single/Multiple Band dropdown
     */
    updateInputSelectionInput_: function() {
        const bands = this.getState('bands') || []
        let defaultVisBands = this.getState('visBands') || []
        const size = bands.length || 0
        // Use multiple bands unless the bands object specifies a single "value" or has an array of one
        let useMultipleBands = !Object.hasOwn(defaultVisBands, 'value') && size > 1 
        if(this.isLoadingState()) {
            useMultipleBands = this.getState('image_type_selection') === 'multi'
        }
        const imageOptions = [["Select single band", "single"]] 
        if(size > 1) {
            imageOptions.unshift(["Use Multiple Bands", "multi"])
        }
        if(this.inputExists(INPUT.IMAGETYPE) === false) {
            this.appendDummyInput(INPUT.IMAGETYPE)
            const field = this.createDropdownWithValue(imageOptions, useMultipleBands ? 'multi' : 'single')
            this.getInput(INPUT.IMAGETYPE).appendField(field, FIELD.IMAGETYPE)
            this.getField(FIELD.IMAGETYPE).setValidator(this.imageTypeSelectionValidator)
            this.getField(FIELD.IMAGETYPE).setValue(useMultipleBands ? 'multi' : 'single')
            this.setState(FIELD.IMAGETYPE, useMultipleBands ? 'multi' : 'single')
        } else if (this.hasStateChanged('bands')) {
            this.getField(FIELD.IMAGETYPE).updateOptions(imageOptions)
            if(this.hasStateChanged('dataset_id')) {
                this.getField(FIELD.IMAGETYPE).setValue(useMultipleBands ? 'multi' : 'single')
            }
        }
    },

    /**
     * Handle the creation/update of the bands dropdowns. This changes depending on the single/multiple
     * bands dropdown. So this should always be called after updateInputSelectionInput_ as it sets State 
     * for the dropdown so it can be remembered.
     * @returns 
     */
    updateInputBandsInput_: function() {
        if(this.hasStateChanged(FIELD.IMAGETYPE) || this.hasStateChanged('bands')) {
            const imageType = this.getState(FIELD.IMAGETYPE)
            let input;
            const bandsArray = this.getState('bands')
            const bandDropdown = bandsArray.length > 0 ? bandsArray : [["No bands available", NO_DATA_DROPDOWN_VALUE]]
            const vizBands = this.getState('visBands', {})

            if(imageType === 'multi') {
                if(this.inputExists(INPUT.MULTIRGB) === false) {
                    this.removeInputIfExists(INPUT.SINGLEBAND)
                    this.appendDummyInput(INPUT.MULTIRGB)
                    this.moveInputAfter(INPUT.MULTIRGB, INPUT.IMAGETYPE)
    
                    let red, green, blue
                    if (vizBands && Object.hasOwn(vizBands,VIS_BANDS_R)) {
                        red = vizBands[VIS_BANDS_R]
                        green = vizBands[VIS_BANDS_G]
                        blue = vizBands[VIS_BANDS_B]
                    } else if (vizBands && Object.hasOwn(vizBands,VIS_BANDS_VALUE)) {
                        const bandName = vizBands[VIS_BANDS_VALUE]
                        red = bandName
                        green = bandName
                        blue = bandName
                    }
                    input = this.getInput(INPUT.MULTIRGB)
    
                    input.appendField("R ", "r")
                    input.appendField(new FieldNoTrimDropdown(bandDropdown), FIELD.BAND1);
                    input.appendField("G ", "g")
                    input.appendField(new FieldNoTrimDropdown(bandDropdown), FIELD.BAND2);
                    input.appendField("B ", "b")
                    input.appendField(new FieldNoTrimDropdown(bandDropdown), FIELD.BAND3);
    
                    if(red !== undefined) {
                        this.getField(FIELD.BAND1).setValue(red);
                    }
    
                    if(green !== undefined) {
                        this.getField(FIELD.BAND2).setValue(green);
                    }
                    
                    if(blue !== undefined) {
                        this.getField(FIELD.BAND3).setValue(blue);
                    }
                } else {
                    this.updateOptionsIfSet(FIELD.BAND1, bandDropdown)
                    this.updateOptionsIfSet(FIELD.BAND2, bandDropdown)
                    this.updateOptionsIfSet(FIELD.BAND3, bandDropdown)
                }
            
                return
            }

            if(imageType === 'single') {
                if(this.inputExists(INPUT.SINGLEBAND) === false) {
                    this.removeInputIfExists(INPUT.MULTIRGB)
                    this.appendDummyInput(INPUT.SINGLEBAND)
                    this.moveInputAfter(INPUT.SINGLEBAND, INPUT.IMAGETYPE)
                    let field = new FieldNoTrimDropdown(bandDropdown)
                    if(vizBands && vizBands[VIS_BANDS_VALUE]) {
                        field.setValue(vizBands[VIS_BANDS_VALUE])
                    } 
                    this.getInput(INPUT.SINGLEBAND).appendField(field, FIELD.BAND);
                } else {
                    this.updateOptionsIfSet(FIELD.BAND, bandDropdown)
                }
                return
            }
        }
    },
    /**
     * creates/updates the visualisation modal fields based on bands and imageTypeSelection stored in state
     * @returns 
     */
    updateModalInput_() {
        if(this.hasStateChanged(FIELD.IMAGETYPE) || this.hasStateChanged('bands') || this.hasStateChanged('min_max') || this.hasStateChanged('visContrast')) {
            const imageType = this.getState(FIELD.IMAGETYPE)
            const contrastType = this.getState('visContrast', 'custom')

            if(imageType === 'multi') {
                
                if(this.hasStateChanged('min_max') || this.hasStateChanged(FIELD.IMAGETYPE)) {
                    const minMax = this.getState('min_max')
                    this.multiChannelModalData.minR = minMax.rgb ? minMax.rgb[0][0] : minMax.min ? minMax.min : 0
                    this.multiChannelModalData.maxR = minMax.rgb ? minMax.rgb[0][1] : minMax.max ? minMax.max : 1
                    this.multiChannelModalData.minG = minMax.rgb ? minMax.rgb[1][0] : minMax.min ? minMax.min : 0
                    this.multiChannelModalData.maxG = minMax.rgb ? minMax.rgb[1][1] : minMax.max ? minMax.max : 1
                    this.multiChannelModalData.minB = minMax.rgb ? minMax.rgb[2][0] : minMax.min ? minMax.min : 0
                    this.multiChannelModalData.maxB = minMax.rgb ? minMax.rgb[2][1] : minMax.max ? minMax.max : 1
                }
                
                if(this.hasStateChanged('visContrast') || this.hasStateChanged(FIELD.IMAGETYPE)) {
                    this.multiChannelModalData.contrastValue = contrastType;
                }
                
                if(this.inputExists(INPUT.MULTIMODALINPUT) === false) {
                    this.removeInputIfExists(INPUT.SINGLEMODALINPUT)
                    this.addModalInput(this, MultiChannelMapLayer, this.multiChannelModalData,  INPUT.MULTIMODALINPUT)
                    this.getInput(INPUT.MULTIMODALINPUT).setAlign(Blockly.ALIGN_RIGHT)
                    this.moveInputAfter(INPUT.MULTIMODALINPUT, INPUT.MULTIRGB)
                }else if(this.hasStateChanged('min_max') || this.hasStateChanged('visContrast') || this.hasStateChanged(FIELD.IMAGETYPE)) {
                    const modalValue = this.getFieldValue('modal_field')
                    if(modalValue) {
                        modalValue.minR = this.multiChannelModalData.minR
                        modalValue.maxR = this.multiChannelModalData.maxR
                        modalValue.minG = this.multiChannelModalData.minG
                        modalValue.maxG = this.multiChannelModalData.maxG
                        modalValue.minB = this.multiChannelModalData.minB
                        modalValue.maxB = this.multiChannelModalData.maxB
                        modalValue.contrastValue = this.multiChannelModalData.contrastValue
                        this.setFieldValue(modalValue, 'modal_field')
                    }
                }

                this.getField('modal_field').setValueKeyValue('layerDescription','')
            }

            if(imageType === 'single') {
                const bands = this.getState('visBands', {})
                let bandName, palette
                if (bands && Object.hasOwn(bands, VIS_BANDS_VALUE)) {
                    bandName = bands[VIS_BANDS_VALUE]
                    palette = bands[VIS_BANDS_PALETTE]
                    console.debug(`We're using band ${bandName} and palette ${palette}`)
                    this.singleChannelModalData.rampValue = palette
                    this.singleChannelModalData.contrastValue = contrastType
                    if(bands[VIS_BANDS_INVERT] === true) {
                        this.singleChannelModalData.invertRamp = true
                    }
                }    

                if(this.hasStateChanged('min_max') || this.hasStateChanged('visContrast') || this.hasStateChanged(FIELD.IMAGETYPE)) {
                    if(this.hasStateChanged('min_max') || this.hasStateChanged(FIELD.IMAGETYPE)) {
                        this.singleChannelModalData.min = this.getState('min_max').min || 0
                        this.singleChannelModalData.max = this.getState('min_max').max || 1
                    }
                    if(this.hasStateChanged('visContrast') || this.hasStateChanged(FIELD.IMAGETYPE)){
                        this.singleChannelModalData.contrastValue = contrastType
                    }
                    this.singleChannelModalData.custom_palette_option = this.getState('custom_palette_option', 'predefined')
                
                    const modalValue = this.getFieldValue('modal_field')
                    if(modalValue) {
                        if(this.hasStateChanged('min_max') || this.hasStateChanged(FIELD.IMAGETYPE)) {
                            modalValue.min = this.singleChannelModalData.min
                            modalValue.max = this.singleChannelModalData.max
                        }
                        if(this.hasStateChanged('visContrast') || this.hasStateChanged(FIELD.IMAGETYPE)){
                            modalValue.contrastValue = this.singleChannelModalData.contrastValue
                        }
                        this.setFieldValue(modalValue, 'modal_field')
                    }

                }

                if(this.inputExists(INPUT.SINGLEMODALINPUT) === false) {
                    this.removeInputIfExists(INPUT.MULTIMODALINPUT)
                    this.addModalInput(this, SingleChannelMapLayer, this.singleChannelModalData, INPUT.SINGLEMODALINPUT)
                    this.getInput(INPUT.SINGLEMODALINPUT).setAlign(Blockly.ALIGN_RIGHT)
                    this.moveInputAfter(INPUT.SINGLEMODALINPUT, INPUT.SINGLEMODALINPUT)
                    this.getField(FIELD.BAND).setValidator(this.bandSelectionChangedValidator)
                    this.triggerValidator(FIELD.BAND)
                }

                const modalField = this.getField('modal_field')
                
                // Note: The modal data doesn't get serialised
                modalField.setModalData('palette_options',  PaletteService.getDropdownOptions())
                modalField.setValueKeyValue('layerDescription','')
                
            }
            
        }
    },

    /**
     * Validator for the imageTypeSelection dropdown. This set block state so it can be remembered and used on other fields
     * @param {*} newValue 
     * @returns 
     */
    imageTypeSelectionValidator(newValue) {
        const block = this.getSourceBlock();
        block.setState(FIELD.IMAGETYPE, newValue)
        if(newValue === 'single') {
            block.setLayerNameValue(block.getFieldValue(FIELD.BAND))
        }else {
            const vis = block.getState('vis')
            if(vis && vis.display_name) {
                block.setLayerNameValue(vis.display_name)
            }
        }
        return newValue
    },
    
    /**
     * Validator is only set on single band selection. This updates the current band into the SingleChannelMapLayer Modal 
     * Used to build out thematic options.
     * @param {*} value 
     * @returns 
     */
    bandSelectionChangedValidator(value) {
        const block = this.getSourceBlock()
        const selectedBand = find(block.getState('bandTypes'), b => b[1] === value)
        const modalField = block.getField('modal_field')
        const modalFieldValue = block.getFieldValue('modal_field')

        if (value !== block.getFieldValue('band_selection')) {
            modalFieldValue.custom_palette = []
            block.setFieldValue(modalFieldValue, 'modal_field')
        }
        
        if(block.isLoadingState()) {
            block.setNonPersistentState('lastBandSelectionValue', value)
        }
        if(selectedBand && modalField && block.isLoadingState() === false && block.initialState !== value) {
            modalField.setModalData('selectedBand', selectedBand)
            const visBands = block.getState('visBands', {})
            let setDefaultRampValue = true
            if (visBands && Object.hasOwn(visBands, VIS_BANDS_VALUE)) {
                const palette = visBands[VIS_BANDS_PALETTE]
                const inverted = visBands[VIS_BANDS_INVERT]
                const paletteValue = visBands[VIS_BANDS_VALUE]
                if(palette && paletteValue === value) {
                    modalFieldValue.rampValue = palette
                    // Double negation to ensure it's a boolean
                    // https://stackoverflow.com/questions/784929/what-is-the-not-not-operator-in-javascript
                    modalFieldValue.invertRamp = !!inverted
                    setDefaultRampValue = false
                } 
            } 
            if(setDefaultRampValue) {
                const paletteOptions = PaletteService.getDropdownOptions()
                modalFieldValue.rampValue = paletteOptions[0][1]
            }
            const contrast = block.getState('visContrast', 'custom')
            modalFieldValue.contrastValue = contrast

            modalFieldValue.chosenStyleType = selectedBand[0]
            if(modalFieldValue.chosenStyleType === 'thematic') {
                const palette = block.getState('defaultClasses')
                modalFieldValue.custom_palette = block.paletteColourKeyPair(palette) || []
            }
            modalFieldValue.min =  block.getState('min_max').min || 0
            modalFieldValue.max =  block.getState('min_max').max || 1
            block.setFieldValue(modalFieldValue, 'modal_field')

            block.setNonPersistentState('lastBandSelectionValue', value)

            block.setLayerNameValue(value)
        }
        return value
    },
    paletteColourKeyPair(default_palette) {
        if(default_palette) {
            const keys = Object.keys(default_palette)
            return keys.reduce((c, key) => {
                c[key] = default_palette[key].color
                return c
            },{})
        }
        return null
    },
    onLoadExtraState(state) {
        if(Object.keys(state).length > 0 && state.userChangedLayerName === undefined && UPDATE_DEFAULT_LAYER_NAME) {
            if(this.isLoadingWorkflow()) {
                this.setState('userChangedLayerName', true)
                this.setNonPersistentState('autoChangeLayerName', false)
            } else {
                this.setNonPersistentState('autoChangeLayerName', true)
            }
        }
        if(typeof state.visContrast === 'object' && state.visContrast !== null) {
            state.visContrast = v2ContrastVisToValue(state.visContrast)
        }
        return state
    },
    layerNameValidator(value) {
        if(UPDATE_DEFAULT_LAYER_NAME === false) {
            return value
        }
        const block = this.getSourceBlock()
        if(block.isLoadingState() === false && block.hasState('autoChangeLayerName') === false && block.getFieldValue('layer_name') !== value) {
            block.setState('userChangedLayerName', true)
        }
        block.removeState('autoChangeLayerName')
        return value
    },
    setLayerNameValue(layerName, forceChange = false) {
        if(UPDATE_DEFAULT_LAYER_NAME === false) {
            return
        }
        if(this.hasState('autoChangeLayerName') && forceChange === false) {
            return
        }
        if(this.hasState('userChangedLayerName') === false || forceChange === true) {
            this.setNonPersistentState('autoChangeLayerName', true)
            this.setFieldValue(layerName, 'layer_name')
            this.removeState('autoChangeLayerName')
        }
    },
    ebxValidate: function(tooltips) {
        if (this.getFieldValue(FIELD.IMAGETYPE) === 'single' && ['sum','mean'].indexOf(this.getState('compositeMethod')) > -1) {
            const selectedBand = this.getState('availableBands',[]).find(b => b['ebx:name'] === this.getFieldValue(FIELD.BAND))
            const modalFieldValue = this.getFieldValue('modal_field')
            if (modalFieldValue && selectedBand) {
                if(modalFieldValue.chosenStyleType === 'thematic' && selectedBand['ebx:origin'] !== 'user'){ 
                    const message = tooltips['invalid_composite_thematic'] || 'Using a thematic band that has been aggregated using {method} may produce invalid results. Consider aggregating images using mode.'
                    return this.setWarningText(message.replace('{method}',this.getState('compositeMethod').toUpperCase()), NON_BLOCKING_BLOCKLY_WARNING_PREFIX+'invalid_composite_thematic')
                }
            }
             
        }
        this.setWarningText(null, NON_BLOCKING_BLOCKLY_WARNING_PREFIX+'invalid_composite_thematic')
        
    }
};

