<template>
    <md-dialog v-model:md-active="showDialog" :md-click-outside-to-close="false" class="image-upload ebx-dialog modal-m">
        <span class="md-dialog-title md-title">
            <div class="top-actions--container">
                <div class="title--container">
                    <span>{{ titleType }} Date</span>
                </div>
                <div class="close-button">
                    <md-button class="md-primary" @click="closeModal"><md-icon>close</md-icon></md-button>
                </div>
            </div>
        </span>
        <md-dialog-content class="dialog-content">
            <md-content class="content">
                <md-radio v-model="imageType" value="point">
                    Point in time
                    <p class="imageTypeLabel muted">{{ titleType }} taken on a specific day or time</p>
                </md-radio>

                <div v-if="imageType=='point'" class="radio-options-container">
                    <div class="radio-options-container--column">
                        <div class="first">
                            <md-datepicker v-model="selectedDate" placeholder="Select Date"/>
                            <p class="md-error" v-if="v$.selectedDate.$invalid">A valid date is required</p>
                        </div>
                    </div>
                </div>

                <md-radio v-model="imageType" value="period">
                    Period of time
                    <p class="imageTypeLabel muted">Combined {{ titleType.toLowerCase() }}s representing a time period</p>
                </md-radio>
                <div v-if="imageType=='period'" class="radio-options-container">
                    <div class="radio-options-container--column">
                        <div class="first">
                            <md-datepicker v-model="selectedDate" placeholder="Select Date"/>
                        </div>
                        <div class="last">
                            <md-field> 
                                <md-select v-model="cadence" name="cadence">
                                    <md-option value="day">Daily</md-option>
                                    <md-option value="week">Weekly</md-option>
                                    <md-option value="month">Monthly</md-option>
                                    <md-option value="year">Yearly</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                    <p class="md-error" v-if="v$.selectedDate.requiredIf.$invalid">A valid from date is required</p>
                    <p class="md-error" v-if="v$.cadence.requiredIf.$invalid">Choose a time period</p>
                </div>

                <md-radio v-if="dateOptional" v-model="imageType" value="none">
                    No period of time
                    <p class="imageTypeLabel muted">These features have no time period</p>
                </md-radio>
                
            </md-content>
        </md-dialog-content>
        <md-dialog-actions>
            <EbxButton theme="secondary" @click="closeModal">
                Cancel
            </EbxButton>
            <EbxButton :disabled="v$.$invalid" @click="saveDate">
                Save
            </EbxButton>
      </md-dialog-actions>
    </md-dialog>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import moment from 'moment';
import valueMixin from '@/components/mixins/valueMixin'

export default {
    mixins: [valueMixin],
    setup: () => ({ v$: useVuelidate() }),
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        date: {
            type: Object,
            required: true
        },
        dateOptional: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'image'
        }
    },
    emits: [
        'update:modelValue',
        'save'
    ],
    data() {
        return {
            imageType: 'point',
            selectedDate: null,
            cadence: 'year'
        }
    },
    computed: {
        showDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        },
        titleType() {
            return this.type === 'image' ? 'Image':'Feature Collection'
        }
    },
    methods: {
        closeModal() {
            this.showDialog = false
        },
        saveDate() {
            this.v$.$touch();
            if (this.v$.$invalid) {
                return;
            }
            this.showDialog = false
            this.$emit('save', {
                imageType: this.imageType,
                start_date: this.imageType === 'none' ? null: moment.utc(this.selectedDate).format('YYYY-MM-DD'),
                cadence: this.cadence
            })
        },
        valueToDate(date) {
            if (date == null || date == undefined) {
                return null;
            }
            if (date instanceof Date) {
                return date;
            }
            return moment.utc(date).toDate();
        }
    },
    validations () {
        return {
            imageType: {
                required
            },
            cadence: {
                requiredIf: requiredIf(this.imageType !== 'none'),
                timeframe: function (value) {
                    if (this.imageType === 'point') {
                        return true;
                    }
                    return ['year','month','day','week'].indexOf(value) > -1;
                }
            },
            
            selectedDate: {
                requiredIf: requiredIf(this.imageType !== 'none'),
                date: function (value) {
                    if (value === null || value === undefined) {
                        return false;
                    }
                    return value instanceof Date;
                }
            }
        }
    },
    watch: {
        showDialog(shown) {
            if(shown) {
                this.imageType = this.date.start_date === null && this.dateOptional ? 'none' : this.date.imageType;
                this.selectedDate = this.valueToDate(this.date.start_date),
                this.cadence = this.date.cadence;
            }
        }
    }
}
</script>