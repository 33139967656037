<template>
    <div>
    <md-dialog v-model:md-active="computedModalShown" class="blockly-modals">
        <md-dialog-title>
            <span>WekaLVQ Options</span>
        </md-dialog-title>

        <form>
        
            <md-field>
                <label for="numClusters">The number of clusters</label>
                <md-input name="numClusters" v-model="formData.numClusters" type="number" :placeholder="numClusters ?numClusters.toString() : ''"></md-input>
            </md-field>

            <md-field>
                <label for="learningRate">Learning Rate</label>
                <md-input name="learningRate" v-model="formData.learningRate" type="number" :placeholder="learningRate ? learningRate.toString() : ''"></md-input>
            </md-field>

            <md-field>
                <label for="epochs">Number of training epochs</label>
                <md-input name="epochs" v-model="formData.epochs" type="number" :placeholder="epochs ? epochs.toString() : ''"></md-input>
            </md-field>

            <div>
                <md-switch name="normalizeInput" v-model="formData.normalizeInput">Skip normalizing the attributes</md-switch>
            </div>

        </form>

        <md-dialog-actions>
            <md-button class="md-primary" @click="computedModalShown = false">Close</md-button>
            <md-button class="md-primary" @click="saveModal">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
    </div>
</template>

<script>
import { globalEventBus } from '@/eventbus.js'
import blocklyModalsMixin from '../blocklyModalsMixin.js'

export default {
    name: 'UnsupervisedClassificationWekaLVQ',
    mixins: [blocklyModalsMixin],
    props: {
        numClusters: {
            type: Number,
            default: 7,
        },
        learningRate: {
            type: Number,
            default: 1,
        },
        epochs: {
            type: Number,
            default: 1000,
        },
        normalizeInput: {
            type: Boolean,
            default: false
        },
        showModal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            formData: {
                numClusters: this.numClusters,
                learningRate: this.learningRate,
                epochs: this.epochs,
                normalizeInput: this.normalizeInput
            }
        }
    },
    methods: {
        getFieldValue() {
            return {
                numClusters: this.formData.numClusters,
                learningRate: this.formData.learningRate,
                epochs: this.formData.epochs,
                normalizeInput: this.formData.normalizeInput
            };
        },
        saveModal() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        },
    },
    watch: {
        showModal(val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if(val) {
                this.formData = {
                    numClusters: this.numClusters,
                    learningRate: this.learningRate,
                    epochs: this.epochs,
                    normalizeInput: this.normalizeInput
                }
            }
        }
    }
}
</script>