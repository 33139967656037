/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["OUTPUT_ADD_TABLE_BLOCK_TITLE"] = "Create table";
Blockly.Msg["OUTPUT_ADD_TABLE_NAME_LABEL"] = "Name ";

export default Blockly.Msg;