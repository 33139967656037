<template>
    <div class="thematic-processing--table-range">
        <div class="row--header">
            <div class="value">Value</div>
            <div class="description">Description</div>
            <div class="colour">Colour</div>
            <div class="threshold">Threshold</div>
        </div>
        <div v-for="row in thresholds" :key="row.id" @mouseover="overId = row.id" @mouseleave="overId = null">
            <div v-if="hasProperty(row,'minThreshold')" class="row--threshold">
                <div class="line">
                    <div></div>
                </div>
                <div class="threshold-input">
                    <input :value="row.minThreshold" @input="handleThresholdChange('minThreshold', row, $event)" @blur="handleThresholdBlur('minThreshold', row, $event)" />
                </div>
            </div>
            <div class="row--detail">
                <div class="value">
                    <input :value="row.value" @input="handleValueChange(row, $event)" /> 
                </div>
                <div class="description">
                    <input v-model="row.description" class="description-input" type="text"/>   
                </div>
                <div class="colour">
                    <EbxColorPicker 
                    v-model="row.color" 
                    :include-hash="false" 
                    theme="wide"></EbxColorPicker>
                </div>
                <div class="threshold">
                    <template v-if="overId === row.id">
                        <a href="#" title="add a new class threshold" @click.prevent="splitThreshold(row)"> 
                            <md-icon>add</md-icon>
                        </a>
                        <a v-if="canRemoveThreshold(row)" title="Delete this threshold" href="#" @click.prevent="removeThreshold(row)">
                            <md-icon>delete</md-icon>
                        </a>
                    </template>
                    <template v-else>&nbsp;</template>
                </div>
            </div>
            <div v-if="hasProperty(row,'maxThreshold')" class="row--threshold">
                <div class="line">
                    <div></div>
                </div>
                <div class="threshold-input">
                    <input :value="row.maxThreshold" @input="handleThresholdChange('maxThreshold', row, $event)" @blur="handleThresholdBlur('maxThreshold', row, $event)"  />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import chroma from 'chroma-js'
import EbxColorPicker from '../EbxComponents/EbxColorPicker.vue'
import valueMixin from '@/components/mixins/valueMixin'

export default {
    components: {
        EbxColorPicker
    },
    props: {
        selectedBand: {
            type: Object,
            default: () => {
                return null
            }
        },
        modelValue: {
            type: Array,
            required: true
        }
    },
    mixins: [valueMixin],
    emits: [
        'update:modelValue',
        'add-threshold',
        'remove-threshold'
    ],
    data() {
        return {
            overId: null
        }
    },
    computed: {
        thresholds: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        hasSelectedBand() {
            return this.selectedBand !== null
        }
    },
    methods: {
        hasProperty(object, property) {
            return [undefined, null].indexOf(object[property]) < 0
        },
        splitThreshold(row) {
            const previousRow = this.getPreviousRow(row)
            const nextRow = this.getNextRow(row)
            const previousColor = previousRow ? previousRow.color : row.color
            const nextColor = previousRow ? row.color : nextRow.color
            let min = parseFloat((previousRow ? previousRow.maxThreshold : row.minThreshold) || 0)
            let max = parseFloat(row.maxThreshold)
            
            // If max is empty just assume the current value is double the min
            if(row.maxThreshold === '') {
                max = min * 2
            }

            const colors = chroma.scale([this.formatColour(previousColor),this.formatColour(nextColor)]).colors(1)

            min = isNaN(min) ? 0 : min
            max = isNaN(max) ? 0 : max
            
            const divisor = 10
            const range = (max + min) / 2
            const mid = (range * divisor).toFixed(0) / divisor //round to 1dp

            return this.$emit('add-threshold', {
                maxThreshold: mid,
                after: previousRow ? previousRow.id : row.id,
                color: colors[0],
                value: 0
            })
        },
        formatColour(color) {
            if(color[0] === '#') {
                return color
            }
            return '#' + color
        },
        getPreviousRow(row) {
            for (let i = 0; i < this.thresholds.length; i++) {
                if(row.id === this.thresholds[i].id) {
                    if (i > 0) {
                        return this.thresholds[i - 1]
                    }
                    i = this.thresholds.length
                }
            }
            return null
        },
        getNextRow(row) {
            for (let i = 0; i < this.thresholds.length; i++) {
                if(row.id === this.thresholds[i].id) {
                    if (this.thresholds[i + 1]) {
                        return this.thresholds[i + 1]
                    }
                    i = this.thresholds.length
                }
            }
            return null
        },
        canRemoveThreshold(row) {
            return this.thresholds.length > 2 && row !== this.thresholds[0] && row !== this.thresholds[this.thresholds.length -1]
        },
        removeThreshold(row) {
            if(this.canRemoveThreshold(row)) {
                this.$emit('remove-threshold', row)
            }
        },
        handleValueChange(row, event) {
            let value = event.target.value
            if(value.length > 0) {
                value = parseInt(value)
                if (!isNaN(value)) {
                    row.value = value
                    this.$emit('update:modelValue', this.thresholds.slice(0))
                    return
                }
            }
            row.value = ''
            this.$emit('update:modelValue', this.thresholds.slice(0))
        },
        canThresholdBeEmpty(key,row){
            return (key === 'minThreshold' && row === this.thresholds[0]) || (key === 'maxThreshold' && row === this.thresholds[this.thresholds.length -1])
        },
        handleThresholdChange(key, row, event) {
            let value = event.target.value

            if(this.canThresholdBeEmpty(key,row) && value.length === 0) {
                row[key] = ''
                this.$emit('update:modelValue', this.thresholds.slice(0))
                return
            }
            value = value.replaceAll(/[^0-9.-]/g, '')

            if(value === '-') {
                row[key] = '-'
                return
            }
            if(value === '-0') {
                row[key] = '-0'
                return
            }
            if(value.match(/[-]*[0-9]+.[0]+/)) {
                row[key] = value
                return
            }
            if(value[value.length -1] === '.') {
                row[key] = value
                return
            }
            if(value.length > 0) {
                value = parseFloat(value)
                if (!isNaN(value)) {
                    row[key] = value
                    this.$emit('update:modelValue', this.thresholds.slice(0))
                    return
                }
            }
            row[key] = ''
            this.$emit('update:modelValue', this.thresholds.slice(0))
        },
        handleThresholdBlur(key, row, event) {
            let value = event.target.value
            if(this.canThresholdBeEmpty(key,row) && value.length === 0) {
                return
            }
            if(value.length > 0) {
                value = parseFloat(value)
                if (!isNaN(value)) {
                    row[key] = value
                    this.$emit('update:modelValue', this.thresholds.slice(0))
                    return
                }
            }
            row[key] = 0
            this.$emit('update:modelValue', this.thresholds.slice(0))
        }
    }
}
</script>