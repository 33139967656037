/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import * as Blockly from 'blockly/core';
import {NO_DATA_DROPDOWN_VALUE} from '@/constants/nextGenConstants';
import {AbstractBlock, AbstractFieldHelpers} from '@/blocks/_abstractBlock';

const defaultSelectOptions = [["Select an option", NO_DATA_DROPDOWN_VALUE]]
const modifierFilterAttributesJson = {
    "type": "modifier_filter_attributes",
    "message0": ` %1 %2 %3`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "cloud_title",
            "text": "%{BKY_MODIFIER_FILTER_ATTRIBUTES_TITLE} ",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_multi_dropdown",
            "name": "filter_attributes",
            "options": defaultSelectOptions
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "secondaryFilter",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['modifier_filter_attributes'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    onInit: function() {
        this.jsonInit(modifierFilterAttributesJson);
    },
    accept: async function (visitor) {
        console.log('accept define visitor')
        await visitor.visitModifierFilterAttributesBlock(this);
    },
    updateShape_: function() {
        if(this.hasStateChanged('attributes')) {
            const attributeOptions = this.getState('attributes').map(datum => [datum.name, datum.name])
            if (attributeOptions.length > 0) {
                this.updateOptionsIfSet('filter_attributes', [].concat(attributeOptions))
            } else {
                this.updateOptionsIfSet('filter_attributes', [].concat(defaultSelectOptions))
            }
        }
    }
}