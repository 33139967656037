/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["ANALYSIS_REGRESSION_BLOCK_TITLE"] = "Create regression";
Blockly.Msg["ANALYSIS_REGRESSION_BLOCK_BAND"] = "Using band";
Blockly.Msg["ANALYSIS_REGRESSION_BLOCK_METHOD"] = "Algorithm";
Blockly.Msg["ANALYSIS_REGRESSION_BLOCK_TRAINING_DATA"] = "Training data";
Blockly.Msg["ANALYSIS_REGRESSION_BLOCK_TRAINING_BAND"] = "Band"
Blockly.Msg["ANALYSIS_REGRESSION_BLOCK_OUTPUT_PREFIX"] = "Band prefix"

export default Blockly.Msg;
