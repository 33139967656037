/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import { DatasetService } from '@/services/dataset.service'
import {AbstractVisitor} from "@/blocks/visitors/helpers/AbstractVisitor";
import {ProvidesAttributes} from '@/workflow/abilities/providesAttributes';
import {ProvidesDataset} from '@/workflow/abilities/providesDataset';
import {ProvidesBands} from '@/workflow/abilities/providesBands';
import {ProvideTimePeriod} from '@/workflow/abilities/providesTimePeriod';
import {ProvidesMapArea} from '@/workflow/abilities/providesMapArea';
import {ProvideCompositeGroupTimePeriod} from '@/workflow/abilities/providesCompositeGroupTimePeriod';
import {NO_DATA_DROPDOWN_VALUE, EBX_BAND_NAME,EBX_BAND_TYPE,THEMATIC,   } from '@/constants/nextGenConstants';
import {DEFAULT_COMPOSITE_METHOD, DEFAULT_COMPOSITE_PERIOD,DEFAULT_COMPOSITE_INTERVAL} from '../../constants/ebxStacConstants';
import { v2ContrastVisToValue, processValidIndices } from '../helper_functions'


class PopulateStateVisitor extends AbstractVisitor {
    constructor(workflowRegistry,globalDatasetService, blockId) {
        super(workflowRegistry, globalDatasetService, blockId)
        this._name = "PopulateStateVisitor";
    }

    // WORKFLOW VISITOR METHODS

    visitWorkflowCompareBlock(block) {
        console.log('Populate visitor -> workflow_compare block', block);
        block.setState('isLoading', this._workflowRegistry.getIsBlockLoading(block.id))
        block.updateShapeOnChange()
    }

    async visitWorkflowClassifyBlock(block) {
        console.log('Capture visitor -> workflow_classify block', block);
        const globalVectors = (await this.getGlobalDatasets())
            .onlyProjectOrigins()
            .onlyVectors()
            .asOptions()

        block.setState('vectors', globalVectors)
        let studyAreas= this._workflowState.gatherAbilities(ProvidesMapArea, true, false).getStudyAreas()
        block.setState('studyAreas', studyAreas)
        block.setState('isLoading', this._workflowRegistry.getIsBlockLoading(block.id))
        block.updateShapeOnChange()
    }

    visitWorkflowUnsupervisedClassificationBlock(block) {
        block.setState('isLoading', this._workflowRegistry.getIsBlockLoading(block.id))
        block.updateShapeOnChange()
    }

    /**
     * Function that stores the actions we perform when visitor visits define block
     * @param {*} block 
     */
    async visitWorkflowDefineDatasetBlock(block) {
        console.log('Populate visitor -> workflow define dataset block', block)

        const datasets = (await this.getGlobalDatasets())
        block.setNonPersistentState('global_datasets', datasets.toArray())
        block.setState('isLoading', this._workflowRegistry.getIsBlockLoading(block.id))
        block.updateShapeOnChange()
    }

    visitWorkflowEmptyContainerBlock(block) {
        console.log('Populate visitor -> workflow_empty_container block', block);
        block.setState('isLoading', this._workflowRegistry.getIsBlockLoading(block.id))
        block.updateShapeOnChange()
    }

    async visitModifierAttributeSelectBlock(block) {
        console.log('Populate visitor -> attribute select block', block);
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);

        if(definition === null) {
            return
        }
        
        const providedAttributes = this._workflowState
            .gatherAbilities(ProvidesAttributes)
            .allAttributes(true)

        if (providedAttributes && providedAttributes.length > 0) {
            block.setState('gee_schema', providedAttributes)
            .setState('isVector', definition.isVector())
            .updateShapeOnChange()
            return
        }

        const workflowStateDatasetId = definition.getDatasetId();
        const attributes = await DatasetService.getGEESchemaForDatasetId(workflowStateDatasetId)

        block
            .setState('gee_schema', attributes)
            .setState('isVector', definition.isVector())
            .updateShapeOnChange()
    }

    async visitModifierRemoveAttributesBlock(block) {
        console.log('Populate visitor -> modifier remove attributes block', block);
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        let hasDefinition = false;

        if(definition === null) {
            return
        }

        const allAttributes = this._workflowState
            .gatherAbilities(ProvidesAttributes)
            .allAttributes()

        hasDefinition = true;

        let attributes = []
        if (definition.isVector()) {
            if (allAttributes && allAttributes.length > 0) {
                attributes = allAttributes
            }
        }

        block
            .setState('attributes', attributes)
            .setState('isVector', definition.isVector())
            .setState('hasDefinition', hasDefinition)
            .updateShapeOnChange()
    }

    visitModifierBandsBlock(block) {
        console.log('Capture visitor -> modifier bands block', block);
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }
        const providedBands = this._workflowState
            .gatherAbilities(ProvidesBands)
            .availableBands(block.id)
            .map(b => {
                return [b['ebx:name'], b['ebx:name']]
            })

        block
            .setState('bands', providedBands)
            .updateShapeOnChange()
    }

    visitModifierRemoveBandsBlock(block) {
        console.log('Capture visitor -> modifier remove bands block', block);
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        let hasDefinition = false;

        if(definition === null) {
            return
        }

        hasDefinition = true;
        const providedBands = this._workflowState
            .gatherAbilities(ProvidesBands)
            .availableBands(block.id)
            .map(b => {
                return [b['ebx:name'], b['ebx:name']]
            })

        block
            .setState('bands', providedBands)
            .setState('isRaster', definition.isRaster())
            .setState('hasDefinition', hasDefinition)
            .updateShapeOnChange()
    }

    async visitAnalysisZonalStatisticsBlock(block) {
        console.info('Populate visitor -> analysis zonal statistics block', block);
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);

        if(definition === null) {
            console.debug("dataset definition null - NOOP");
            return;
        }

        const empty_bands = [["No thematic bands available", NO_DATA_DROPDOWN_VALUE]];
        const globalDatasets = (await this.getGlobalDatasets())

        let providedBands = []
        let options = [["No datasets available", NO_DATA_DROPDOWN_VALUE]]
        let bandsDropdown = empty_bands
        let selectedDataset = null
        let bandProps = []
        let dates = [["No dates available", NO_DATA_DROPDOWN_VALUE]]
        let cadence = null
        let collectionParams = {}

        if(definition.isVector()) {
            const filterDatasetsByCadence = function(dataset) {
                // only allow datasets with these cadences or unique months
                if(['range','single_composite','year'].indexOf(dataset.multi_image_temporal?.cadence) > -1){
                    return true
                }
                // if cadence is month check for unique months over time period
                if(dataset.multi_image_temporal?.cadence === 'month' && Math.abs(dataset.multi_image_temporal.timePeriod.end_date.diff(dataset.multi_image_temporal.timePeriod.start_date, 'months')) <= 12) {
                    return true
                }
                return false
            }
            const rasterDatasets = globalDatasets.onlyProjectOrigins().onlyRasters().filter(filterDatasetsByCadence)
            if(rasterDatasets.toArray().length > 0) {
                options = rasterDatasets.asOptions()
            }
            const selectedDatasetId = block.getFieldValue(block.FIELD.DATASET);
            selectedDataset = rasterDatasets.findById(selectedDatasetId);
            if(selectedDataset) {
                if( selectedDataset.multi_image_temporal?.temporal && Array.isArray(selectedDataset.multi_image_temporal.temporal)) {
                    dates = selectedDataset.multi_image_temporal.temporal.map(d => [d.name, d.value])
                }
                if(selectedDataset.multi_image_temporal?.cadence) {
                    cadence = selectedDataset.multi_image_temporal.cadence
                }
            }
        } else {
            providedBands = this._workflowState
            .gatherAbilities(ProvidesBands)
            .availableBands(block.id)
            .map(b => {
                return [b['ebx:name'], b['ebx:name']]
            });

            const creatorDatasets = globalDatasets.onlyProjectOrigins();
            // filter creatorDatasets for those that have at least one thematic band
            const definitions = creatorDatasets.onlyRasters().hasThematicBands().asOptions();
            const vectors = creatorDatasets.isCollection().onlyVectors().asOptions();

            options = definitions.concat(vectors)

            const selectedDatasetId = block.getFieldValue(block.FIELD.DATASET);
            selectedDataset = creatorDatasets.findById(selectedDatasetId);

            // filter the collectio params bands to only that of the selection
            if(block.getFieldValue(block.FIELD.ZONE_BANDS) !== NO_DATA_DROPDOWN_VALUE && block.getFieldValue(block.FIELD.ZONE_BANDS) !== null) {
                if(selectedDataset) {
                    collectionParams.type = selectedDataset.type
                }
                if(selectedDataset && selectedDataset.bands && Array.isArray(selectedDataset.bands)) {
                    collectionParams.bands = selectedDataset.bands.filter(b => b[EBX_BAND_NAME] === block.getFieldValue(block.FIELD.ZONE_BANDS))
                }
            }
        }

        if(selectedDataset && selectedDataset.bands && Array.isArray(selectedDataset.bands)) { //If dataset is an aoi, don't try to get bands from it 
            bandProps = selectedDataset.bands;
            bandsDropdown = selectedDataset
                .bands
                // if the current def is a vector we want all bands from selected dataset, otherwise we only want thematic bands
                .filter(b => definition.isVector() || b[EBX_BAND_TYPE] == THEMATIC)
                .map(b => [b[EBX_BAND_NAME], b[EBX_BAND_NAME]])
        } 

        block
            .setState(block.FIELD.BANDS, providedBands)
            .setState(block.FIELD.DATASET, options)
            .setState(block.ZONE_BANDS_DROPDOWN, bandsDropdown)
            .setState(block.FIELD.ZONE_BANDS, bandProps)
            .setState("collectionParams", collectionParams)
            .setState('isVector', definition.isVector())
            .setState('dates', dates)
            .setState('cadence', cadence)
            .updateShapeOnChange()
    }
    
    async visitRegressionBlock(block) {
        console.log('Populate visitor -> regression block', block);
        let hasDefinition = false;

        const definition =  this._workflowState.gatherFirstAbility(ProvidesDataset)
        if(definition === null) {
            return
        }
        hasDefinition = true;

        const requireCurrentWorkflow = false
        let creatorDatasets = (await this.getGlobalDatasets(requireCurrentWorkflow))
            .onlyProjectOrigins()
            .onlyRasters()
            .toArray() 
        
        const definitions = creatorDatasets.map(dataset => [dataset.title, dataset.id])
        
        const defaultOption =  [["Select an option", NO_DATA_DROPDOWN_VALUE]]
        let training_bands = defaultOption

        if (block.getFieldValue(block.FIELD.TRAINING_DATASET) !== NO_DATA_DROPDOWN_VALUE) {
            const training_dataset = block.getFieldValue(block.FIELD.TRAINING_DATASET);
            const selectedDataset = creatorDatasets.find(dataset => {
                return dataset.id === training_dataset
            })
            if (selectedDataset) {
                training_bands = defaultOption.concat(selectedDataset.bands.map(b => [b[EBX_BAND_NAME], b[EBX_BAND_NAME]]))
            }
        }

        block
            .setState(block.FIELD.TRAINING_DATASET, defaultOption.concat(definitions))
            .setState('isRaster', definition.isRaster())
            .setState('hasDefinition', hasDefinition)
            .setState(block.FIELD.TRAINING_BANDS, training_bands)
            .updateShapeOnChange()
    }

    async visitModifierCloudMaskBlock(block) {
        console.log('Populate visitor -> cloud mask block', block);

        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }

        const isRaster = definition.isRaster();
        const stacID = definition.getDatasetId();
        const masks = await DatasetService.getCloudFiltersForDataset(stacID);

        block
            .setState('masks', masks)
            .setState('isRaster', isRaster)
            .updateShapeOnChange()
    }

    async visitModifierCompositeBlock(block) {
        console.log('Populate visitor -> modifier composite block', block)
        var definition;


        definition =  this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }

        // Reset the field values if the block has dragged from another dataset into another
        if(block.hasState('definition_id') && block.getState('definition_id') !== definition.getDatasetId()) {
            block.removeState('defaultCompositeMethod')
            block.removeState('defaultCompositePeriod')
            block.removeState('defaultCompositeInterval')
            block.setFieldValue('none', 'group')
        }

        const serviceMetadata = await DatasetService.getMetadata(definition.getDatasetId()); // this will be caught if the parent block doesnt have the right type to have a dataset
        const defaultCompMethod = serviceMetadata[DEFAULT_COMPOSITE_METHOD];
        const defaultCompPeriod = serviceMetadata[DEFAULT_COMPOSITE_PERIOD];
        const defaultCompInterval = serviceMetadata[DEFAULT_COMPOSITE_INTERVAL];

        if(defaultCompMethod && block.hasState('defaultCompositeMethod') === false ) {
            block.setState('defaultCompositeMethod', defaultCompMethod)
        }
        if(defaultCompPeriod && block.hasState('defaultCompositePeriod') === false) {
            block.setState('defaultCompositePeriod', defaultCompPeriod)
        }
        if(defaultCompInterval  && block.hasState('defaultCompositeInterval') === false) {
            block.setState('defaultCompositeInterval', defaultCompInterval)
        }

        const bandOptions = this.
            _workflowState
            .gatherAbilities(ProvidesBands)
            .toDropDownOptions();
            

        block
        .setState('bands',bandOptions)
        .setState('definition_id',definition.getDatasetId())
        .setState('isRaster', definition.isRaster())
        .updateShapeOnChange()

    }

    visitModifierDaterangeBlock(block) {
        console.log('Populate visitor -> date range block', block);

        const definition = this._workflowState.gatherAbilities(ProvideTimePeriod).current();
        const datasetDefinition = this._workflowState.gatherFirstAbility(ProvidesDataset);

        if(definition === null) {
            return
        }

        // set a isRaster flag to determine if the dataset is a raster
        block.setState('isRaster', datasetDefinition.isRaster())

        let startDate = definition.getStartDate();
        let endDate = definition.getEndDate();

        if(startDate) {
            block.setState('datasetStartDate', startDate.format('YYYY-MM-DD'))
        }
        if(endDate) {
            block.setState('datasetEndDate', endDate.format('YYYY-MM-DD'))
        }
        
        block.updateShapeOnChange()
    }

    /**
     * Function that stores the actions we perform when visitor visits modifier_mask block
     * populates block state with workflow state data from capture
     * @param {*} block 
     */
    async visitModifierMaskBlock(block) {
        const requireCurrentWorkflow = true
        let creatorDatasets = (await this.getGlobalDatasets(requireCurrentWorkflow))
            .onlyProjectOrigins()
            .onlyRasters()
            .toArray() 
        
        const definitions = creatorDatasets.map(dataset => [dataset.title, dataset.id])
        let bandsDropdown = [["No bands available", NO_DATA_DROPDOWN_VALUE]]
        let bandProps = []

        const currentDefinition = this._workflowState.gatherFirstAbility(ProvidesDataset)
        const isRaster = currentDefinition.isRaster()
        block.setState('isRaster', isRaster)

        const selectedDatasetId = block.getFieldValue('dataset')
        const selectedDataset = creatorDatasets.find(dataset => {
            return dataset.id === selectedDatasetId
        })

        if(selectedDataset) {
            bandsDropdown = selectedDataset.bands.map(b => [b[EBX_BAND_NAME], b[EBX_BAND_NAME]])
            bandProps = selectedDataset.bands
        }

        if (definitions) {
            // update block dropdown with definitions
            let datasetField = block.getField('dataset')
            if (datasetField) {
                block
                    .setState('datasets', definitions)
                    .setState('bandsDropdown', bandsDropdown)
                    .setState('bands', bandProps)
                    .updateShapeOnChange()
            }
        }
    }

    visitModifierOrbitBlock(block) {
        console.log('Populate visitor -> modifier orbit block', block)
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }
        const isRaster = definition.isRaster();
        block.setState('isRaster', isRaster)
        block.updateShapeOnChange();
    }

    async visitModifierTimePeriodBlock(block) { 
        console.log('Populate visitor -> time period block', block);
        // Load the global datasets
        const globalDatasets = (await this.getGlobalDatasets())
        // get all variables used within the project
        const globalDateVariables = globalDatasets
            .onlyWorkspaceVariables()
            .onlyWorkspaceVariablesOfType(['date'])
            .asOptions()
        
        const globalDateRangeVariables = globalDatasets
            .onlyWorkspaceVariables()
            .onlyWorkspaceVariablesOfType(['date range'])
            .asOptions()

        const dataset = this._workflowState.gatherFirstAbility(ProvidesDataset);
        const isRaster = dataset.isRaster();
        const startDate = this._workflowState.gatherLastAbility(ProvideTimePeriod,false).getStartDate();
        const endDate = this._workflowState.gatherLastAbility(ProvideTimePeriod,false).getEndDate();
        const ranges = this._workflowState.gatherLastAbility(ProvideTimePeriod,false).getDateRanges(true);
        let workflowStateDatasetId = dataset?.getDatasetId();
        if(workflowStateDatasetId) {
            block.setState('currentDatasetId', workflowStateDatasetId)
                .setState('dateVariables', globalDateVariables)
                .setState('dateRangeVariables', globalDateRangeVariables)
                .setState('extentStartDateUnix', startDate.unix())
                .setState('extentEndDateUnix', endDate.unix())
                .setState('isRaster', isRaster)
                .setNonPersistentState('selectableDateRanges', ranges)
        }
        
        block.updateShapeOnChange();
    }

    // INPUT VISITOR METHODS (alphabetical order)

    async visitInputDatasetBlock(block) {
        console.log('Populate visitor -> input dataset block', block);
        const dropDownOptions = (await this.getGlobalDatasets()).onlyProjectOrigins().onlyRasters().asOptions()
        const defaultOption =  [["Select an option", NO_DATA_DROPDOWN_VALUE]]
        block.setState('selected_dataset', defaultOption.concat(dropDownOptions));
        block.updateShapeOnChange();
    }

    // OUTPUT VISITOR METHODS (alphabetical order)

    async visitAddMapLayerBlock(block) {
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }
        const definitions = this._workflowState.gatherAbilities(ProvidesDataset).map(d => d.getNameForDisplay());
        const allBands = this._workflowState.gatherAbilities(ProvidesBands);
        const attributes = this._workflowState.gatherAbilities(ProvidesAttributes);

        const composite = this._workflowState.gatherFirstAbility(ProvideCompositeGroupTimePeriod);
        const compositeMethod = composite ? composite.getMethod() : null;
   
        const bandOptions = allBands.toDropDownOptions(definitions.length <= 1);

        const bandDataTypes = allBands.toDropDownOptions(definitions.length <= 1, 'ebx:datatype');

        const vis = definition.getVis()
        const visBands = definition.getVisBands() || []
        let visContrast = definition.getVisContrast()
        let minMax = definition.getMinMax()

        if(block.getFieldValue('image_type_selection') === 'single'){
            const band = block.getFieldValue('band_selection')
            const bandMinMax = await DatasetService.getVisualizationMinMax(definition.getDatasetId(), band)
            if(bandMinMax && bandMinMax.min !== null && bandMinMax.max !== null) {
                minMax = bandMinMax
            }
            const bandVis = await DatasetService.getVisualizationContrastType(definition.getDatasetId(), band)
            if(bandVis !== null) {
                visContrast = bandVis
            }
        }

        // Set block data needed for vis params etc
        block
            .setState('dataset_id', definition.getDatasetId())
            .setState('bands', bandOptions)
            .setState('bandTypes', bandDataTypes)
            .setState('vis', vis)
            .setState('visBands', visBands)
            .setState('visContrast', v2ContrastVisToValue(visContrast))
            .setState('min_max', minMax)
            .setState('attributes', attributes.allAttributes())
            .setNonPersistentState('compositeMethod', compositeMethod)
            .setNonPersistentState('availableBands', allBands.availableBands())
            .setNonPersistentState('attributeData', attributes.allAttributes(true))
            .setState('isVector', definition.isVector())
            .updateShapeOnChange()
        
        // handle default classes. We need to wait until the block has updated its fields to determine the selected band
        const selectedSingleBandName = block.getFieldValue('band_selection')
        if(selectedSingleBandName !== null) {
            const datasets = (await this.getGlobalDatasets(true)).onlyProjectOrigins().onlyRasters().toArray()
            if(datasets.length > 0) {
                // First dataset is always the first dataset
                const currentWorflowDatasetBands = datasets[0].bands
                // Matching bands based on name
                const matchingBands = currentWorflowDatasetBands.filter(band => band['ebx:name'] === selectedSingleBandName)
                if(matchingBands.length > 0){
                    const band = matchingBands[0]
                    if(band['ebx:datatype'] === 'thematic' && band['gee:classes']){
                        block
                            .setState('defaultClasses', band['gee:classes'],false,true)
                            .updateShapeOnChange()
                    } 
                }
            }
    
        }
    }

    async visitOutputAddTableBlock(block) {
        const requireCurrentWorkflow = !block.isTopBlock()
        const datasets = (await this.getGlobalDatasets(requireCurrentWorkflow)).onlyProjectOrigins().withoutType('area')
        block.setNonPersistentState('global_datasets', datasets.toArray())

        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);

        block.setState('isLoading', this._workflowRegistry.getIsBlockLoading(block.id))

        const dataset_type = definition ? definition.getType() : 'raster'
        block.setState('dataset_type', dataset_type)

        if(this._workflowRegistry.isDragging === false || block.isLoadingState()) {
            block.togglePlayButton();
        }

        if(this.hasBlockDuplicated(block)) {
            block.renameDefaultDataset(this._workflowRegistry.currentEvent.previousBlock.id, this._workflowRegistry.currentEvent.block.id)
        } else {
            // only update the block if we are not dragging, otherwise we'll get internal blockly errors, due to
            // the changes happening below with connections
            if (!this._workflowRegistry.isDragging) { 
                block.updateShapeOnChange();
            }
        }
        
        if(this._workflowRegistry.isDragging === false && block.getSurroundParent() === null) {
            if(block.previousConnection === null || (block.previousConnection && block.previousConnection.targetConnection === null)) {
                block.setPreviousStatement(false)
            }
            if(block.nextConnection === null || (block.nextConnection && block.nextConnection.targetConnection === null)) {
                block.setNextStatement(false)
            }
        }
        
        if(this._workflowRegistry.isDragging) {
            if(block.previousConnection === null) {
                block.setPreviousStatement(true)
            }
            if(block.nextConnection === null) {
                block.setNextStatement(true)
            }
        }
        
    }

    async visitOutputMultitemporalBlock(block) {
        console.log('Populate visitor -> output multitemporal block', block);

        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }
        const definitions = this._workflowState.gatherAbilities(ProvidesDataset);
        const compositeTimeGroup = this._workflowState.gatherLastAbility(ProvideCompositeGroupTimePeriod);
        if([null, undefined].indexOf(compositeTimeGroup) < 0) {
            const timePeriods = this._workflowState.gatherAbilities(ProvideTimePeriod);
            const timePeriodDates = compositeTimeGroup.getOptionsForTimePeriodAbility(timePeriods.currentAbilities())
            block.setState('dates',timePeriodDates)
        }else if(block.hasState('dates')) {
            block.setState('dates',[['None','none']])
        }
    
        const allBands = this._workflowState.gatherAbilities(ProvidesBands);
        const bandOptions = allBands.toDropDownOptions(definitions.length <= 1);

        const viz = definition.getVis()
        const visBands = definition.getVisBands()
        const visContrast = definition.getVisContrast()
        const minMax = definition.getMinMax()

        block
            .setState('dateset_id', definition.getDatasetId())
            .setState('bands',bandOptions)
            .setState('viz', viz)
            .setState('min_max', minMax)
            .setState('visBands', visBands)
            .setState('visContrast', v2ContrastVisToValue(visContrast))
            .updateShapeOnChange()
    }

    async visitOutputImage(block) {
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }
        const datasets = (await this.getGlobalDatasets(true)).onlyProjectOrigins().onlyRasters().toArray()
        block
            .setNonPersistentState('datasets', datasets)
            .setState('isVector', definition.isVector())
            .updateShapeOnChange()
    }

    visitOutputSaveDatasetBlock(block) {
        console.log('Populate visitor -> saved dataset block', block)
    }

    // MISCELLANEOUS VISITOR METHODS (alphabetical order) (to be categorized)

    visitIndicesBlock(block) {
        console.info('Populate visitor -> indices block', block);

        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(!definition) {
            return;
        }

        // get indices information
        let indicesObj = definition.getIndices()
        const isRaster = definition.isRaster();
        console.info('indicesObj', indicesObj)
        // update the indices block
        let indexContent = processValidIndices(indicesObj)
        console.info('indexContent', indexContent)
        const indexCategories = indexContent['categories_dropdown']
        console.info('indexCategories', indexCategories)

        block.setState(block.FIELD.CATEGORY + "_options", indexCategories)
        block.setState('isRaster', isRaster)

        // remove categories dropdowwn from indexContent
        delete indexContent['categories_dropdown']

        // TODO: workout a way to update the index dropdown directly without
        // having to update the block with all the content
        block.setState('category_content', indexContent)
        block.updateShapeOnChange()
    }

    async visitStudyAreaBlock(block) {
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }
        // Load the global datasets
        const globalDatasets = (await this.getGlobalDatasets())
        
        // get all variables within the project and map
        const globalVectors = globalDatasets
            .onlyProjectOrigins()
            .onlyVectors(['feature_collection','area'])
            .asOptions()

        // get all variables used within the project
        const globalVariables = globalDatasets
            .onlyWorkspaceVariables()
            .onlyWorkspaceVariablesOfType(['area'])
            .asOptions()
            
        // Set States and update the block
        block
            .setState('vectors', [...globalVectors, ...globalVariables])
            .setState('type', definition.getType())
            .updateShapeOnChange()

        console.log('Populate visitor -> workflow_classify block', block);
    }
   

    async visitAnalysisCalculatorBlock(block) {

        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }
        const isRaster = definition.isRaster();

        block.setState('isRaster', isRaster)

        const datasets = (await this.getGlobalDatasets(true)).onlyProjectOrigins().onlyRasters().toArray()
        block.setNonPersistentState('image_collections', datasets)
        if(this.hasBlockDuplicated(block)) {
            block.renameDefaultDataset(this._workflowRegistry.currentEvent.previousBlock.id, this._workflowRegistry.currentEvent.block.id)
        } else {
            block.updateShapeOnChange();
        }
    }

    visitAnalysisFocalAnalysisBlock(block) { 
        console.log('Populate visitor -> focal analysis block',block)
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }
        const isRaster = definition.isRaster();
        
        const definitions = this._workflowState.gatherAbilities(ProvidesDataset);
        const allBands = this._workflowState.gatherAbilities(ProvidesBands);
        const bandOptions = allBands.toDropDownOptions(definitions.length <= 1);

        block
            .setState('gsd',definition.getGsd())
            .setState('bands',bandOptions)
            .setState('isRaster', isRaster)
            .updateShapeOnChange()
    }
    
    async visitAnalysisThematicProcessingBlock(block) {
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }
        const isRaster = definition.isRaster();
        const datasets = (await this.getGlobalDatasets(true)).onlyProjectOrigins().onlyRasters().toArray()

        let bands; 
        if(datasets.length > 0) {
            bands = datasets[0].bands
        } else {
            bands = []
        }

        block 
            .setState('isRaster', isRaster)
            .setNonPersistentState('bands', bands)
            .setNonPersistentState('min_max', definition.getMinMax())
            .updateShapeOnChange()

    }

    visitBufferBlock(block) {
        console.log('Capture visitor -> Buffer block', block);
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        let hasDefinition = false;
        if(definition === null) {
            return
        }
        hasDefinition = true;

        block
            .setState('isVector', definition.isVector())
            .setState('hasDefinition', hasDefinition)
            .updateShapeOnChange()
    }
    
    async visitAnalysisVectorAttributeBlock(block) {
        console.log('Populate visitor -> vector attribute block', block)
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);

        if(definition === null) {
            return
        }

        const type = definition.getType();

        block
            .setState('type', type)
            .updateShapeOnChange()

    }

    async visitAnalysisGeoprocessingBlock(block) {
        console.log('Populate visitor -> analysis geoprocessing block', block)
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);

        if(definition === null) {
            return
        }

        const type = definition.getType();

        block
            .setState('type', type)
            .setState('vectors', (await this.getGlobalDatasets()).onlyProjectOrigins().onlyVectors().asOptions())
            .updateShapeOnChange()

    }
    async visitAnalysisConvertFeaturesBlock(block) {
        console.log('Populate visitor -> convert features block', block)

        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        let hasDefinition = false;

        if(definition === null) {
            return
        }

        const allAttributes = this._workflowState
            .gatherAbilities(ProvidesAttributes)
            .numericAttributes()

        hasDefinition = true;

        let attributes = []
        if (definition.isVector()) { 
            if (allAttributes && allAttributes.length > 0) {
                attributes = allAttributes
            }
        }

        block
        .setState('attributes', attributes)
        .setState('isVector', definition.isVector())
        .setState('hasDefinition', hasDefinition)
        .updateShapeOnChange()

    }

    visitModifierUnMaskBlock(block) {
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }
        const providedBands = this._workflowState
            .gatherAbilities(ProvidesBands)
            .availableBands(block.id)
            .map(b => {
                return [b['ebx:name'], b['ebx:name']]
            })

        block
            .setState('bands', providedBands)
            .setState('isRaster', definition.isRaster())
            .updateShapeOnChange()
    }

    async visitModifierReAssignClassesBlock(block) {
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }

        const isRaster = definition.isRaster();

        const dataset = await this.currentGlobalDataset()
        if(dataset){
            block
                .setState('bands', dataset.bands)
                .setState('isRaster', isRaster)
                .updateShapeOnChange()
        }
    }

    async visitAnalysisConvertImagesBlock(block) {
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }

        const dataset = await this.currentGlobalDataset()
        const bands = dataset.bands.filter(b => b[EBX_BAND_TYPE] == THEMATIC)
        if(dataset){
            block
                .setState('bands', bands.map(b => [b[EBX_BAND_NAME], b[EBX_BAND_NAME]]))
                .setState('isVector', definition.isVector())
                .updateShapeOnChange()
        }
    }
}

export {PopulateStateVisitor}