/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

 

import { reactive } from "vue";

let service = reactive({
    loadingWorkflow: false,
    globalDatasetService: null,
});

const RegistryService = {
    setLoadingWorkflow: (loading) => {
        service.loadingWorkflow = loading;
    },
    getLoadingWorkflow: () => {
        return service.loadingWorkflow;
    },
    setGlobalDatasetService: (globalDatasetService) => {
        service.globalDatasetService = globalDatasetService;
    },
    getGlobalDatasetService: () => {
        return service.globalDatasetService;
    },
    getGlobalDatasets: () => {
        return service.globalDatasetService.getObservable();
    }
};

export {
    RegistryService
}
