/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["WORKFLOW_INSERT_DATASET_LABEL"] = "Use dataset ";
Blockly.Msg["WORKFLOW_INSERT_MODAL_LABEL"] = "Select a dataset";

export default Blockly.Msg;