
import * as Blockly from 'blockly/core';
import {NO_DATA_DROPDOWN_VALUE} from '@/constants/nextGenConstants';
import {AbstractBlock, AbstractFieldHelpers} from '@/blocks/_abstractBlock';

const defaultSelectOptions = [["Select an option", NO_DATA_DROPDOWN_VALUE]]
const modifierBandsJson = {
    "type": "modifier_bands",
    "message0": ` %1 %2 %3`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "cloud_title",
            "text": "%{BKY_MODIFIER_BANDS_TITLE} ",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_multi_dropdown",
            "name": "bands",
            "options": defaultSelectOptions
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "secondaryFilter",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['modifier_bands'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    onInit: function() {
        this.jsonInit(modifierBandsJson);
    },
    accept: async function (visitor) {
        console.log('accept define visitor')
        await visitor.visitModifierBandsBlock(this);
    },
    updateShape_: function() {
        if(this.hasStateChanged('bands')) {
            this.updateOptionsIfSet('bands', this.getState('bands'))
        }
    }
}