<template>
    <div class="dataset-page--overview">
        <div v-if="!isFeatureCollection" class="dataset-page--overview-section">
            <div v-if="hasDate" class="dataset-page--date-section">
                <div class="dataset-page--date-section--date">
                    <p class="ebx-label dataset-page--overview-section__label">Date</p>
                    <div class="ebx-inline-input">
                        <div class="ebx-inline-input--view-only">
                            <span class="non-editable-date">{{ date.start_date }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="date.imageType==='period'" class="dataset-page--date-section--period">
                    <p class="ebx-label dataset-page--overview-section__label">Time Period</p>
                    <div class="ebx-inline-input">
                        <div class="ebx-inline-input--view-only">
                            <span class="non-editable-date">{{ cadenceToText(date.cadence) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="canEdit">
                <EbxButton theme="tertiary" @click="dateModalShown = true" class="no-padding">
                    {{ hasDate ? 'Edit' : 'Add' }} {{ isFeatureCollection ? 'Features' : 'Image' }} Date
                </EbxButton>
            </div>
            
        </div>
        <div class="dataset-page--overview-section">
            <p class="ebx-label dataset-page--overview-section__label">License</p>
            <EbxInlineInput 
                v-model="datasetLicense" 
                :disabled="!canEdit"
                :field-being-edited="fieldBeingEdited"
                :id="'dataset-license'"
                :input-placeholder="licensePlaceholder" 
                :input-type="`text`"
                :input-style-classes="['ebx-primary']"
                :input-validation="licenseValidation"
                :edit-actions-position="`right`"
                @editing-ebx-field="updateFieldBeingEdited"
                @save="updateLicense">
            </EbxInlineInput>
        </div>
        <div class="dataset-page--overview-section">
            <p class="ebx-label dataset-page--overview-section__label">Provider</p>
            <EbxInlineInput 
                v-model="datasetProvider"
                :disabled="!canEdit"
                :field-being-edited="fieldBeingEdited"
                :id="'dataset-provider'"
                :input-placeholder="providerPlaceholder" 
                :input-type="`text`"
                :input-style-classes="['ebx-primary']"
                :input-validation="providerValidation"
                :edit-actions-position="`right`"
                @editing-ebx-field="updateFieldBeingEdited"
                @save="updateProvider">
            </EbxInlineInput>
        </div>
        <div class="dataset-page--overview-section">
            <p class="ebx-label dataset-page--overview-section__label">Provider link</p>
            <EbxInlineInput 
                v-model="datasetProviderLink"
                :disabled="!canEdit"
                :field-being-edited="fieldBeingEdited"
                :id="'dataset-provider-link'"
                :input-placeholder="providerLinkPlaceholder" 
                :input-type="`text`"
                :input-style-classes="['ebx-primary']"
                :input-validation="providerLinkValidation"
                :edit-actions-position="`right`"
                @editing-ebx-field="updateFieldBeingEdited"
                @save="updateProviderLink">
            </EbxInlineInput>
        </div>
        <div class="dataset-page--overview-section">
            <p class="ebx-label dataset-page--overview-section__label">Description</p>
            <EbxInlineInput 
                v-model="datasetDescription"
                :disabled="!canEdit"
                :field-being-edited="fieldBeingEdited"
                :id="'dataset-description'"
                :input-placeholder="descriptionPlaceholder" 
                :input-type="`text`"
                :input-style-classes="['ebx-primary']"
                :input-validation="descriptionValidation"
                :edit-actions-position="`right`"
                @editing-ebx-field="updateFieldBeingEdited"
                @save="updateDescription">
            </EbxInlineInput>
        </div>
        <OverviewDateModal v-model="dateModalShown" :date="date" @save="updateDate" :date-optional="isFeatureCollection" :type="isFeatureCollection ? 'feature':'image'" ></OverviewDateModal>
    </div>
</template>

<script>
import EbxInlineInput from '@/components/EbxComponents/EbxInlineInput.vue'
import OverviewDateModal from './OverviewDateModal.vue'
import moment from 'moment'

export default {
    name: 'UserDatasetView',
    components: {
        EbxInlineInput,
        OverviewDateModal
    },
    props: {
        dataset: {
            type: Object,
            required: true
        },
        datasetImage: {
            type: Object,
            required: false,
            default: null
        },
        canEdit: {
            type: Boolean,
            required: true
        },
        fieldBeingEdited: {
            type: String,
            required: true
        }
    },
    emits: [
        'editing-ebx-field'
    ],
    data() {
        return {
            dateValidation: {
                error: '',
                active: false
            },
            descriptionValidation: {
                error: '',
                active: false
            },
            licenseValidation: {
                error: '',
                active: false
            },
            providerValidation: {
                error: '',
                active: false
            },
            providerLinkValidation: {
                error: '',
                active: false
            },
            datePlaceholder: 'Add a start date',
            datasetDescription: '',
            descriptionPlaceholder: 'Add a description to show in the dataset finder',
            datasetLicense: '',
            licensePlaceholder: 'Add licence details',
            datasetProvider: '',
            providerPlaceholder: 'Add provider',
            datasetProviderLink: '',
            providerLinkPlaceholder: 'Add provider URL',
            datasetId: '',
            date: {},
            dateModalShown: false
        }
    },
    computed: {
        isFeatureCollection() {
            return this.dataset.type === 'FeatureCollection';
        },
        hasDate() {
            return this.date.start_date !== null;
        }
    },
    watch: {
        dataset(newVal) {
            this.setLocalDatasetProperties(newVal);
        }
    },
    created() {
        this.setLocalDatasetProperties(this.dataset);
    },
    methods: {
        setLocalDatasetProperties(dataset) {
            this.datasetDescription = dataset.description || '';
            this.datasetLicense = dataset.license || '';
            this.datasetProvider = dataset.provider || '';
            this.datasetProviderLink = dataset.provider_url || '';
            this.datasetId = dataset.id || '';
            this.date = {
                start_date: dataset.start_date,
                cadence: dataset['ebx:cadence'].unit,
                imageType: dataset['ebx:cadence'].unit === 'epoch' ? 'point' : 'period'
            }
        },
        updateDate(updatedDate) {
            this.date = updatedDate
            const apiData = {
                start_time: moment.utc(updatedDate.start_date).toISOString(),
                end_time: this.setSelectedToDate(updatedDate.start_date, updatedDate.imageType === 'point' ? 'day' : updatedDate.cadence),
                cadence: updatedDate.imageType === 'point' ? 'epoch' : updatedDate.cadence
            }
            if (this.isFeatureCollection) {
                this.$store.dispatch('userdatasets/updateDatasetProperties', {
                    datasetId: this.datasetId,
                    properties: apiData
                })
            }else {
                this.$store.dispatch('userdatasets/updateImage', {
                    datasetId: this.datasetId,
                    imageId: this.datasetImage.id,
                    data: apiData
                })
            }
        },
        updateLicense() {
            this.$store.dispatch('userdatasets/updateDatasetProperties', {
                datasetId: this.datasetId,
                properties: { license: this.datasetLicense}
            })
        },
        updateProvider() {
            this.$store.dispatch('userdatasets/updateDatasetProperties', {
                datasetId: this.datasetId,
                properties: { provider: this.datasetProvider}
            })
        },
        updateProviderLink() {
            this.$store.dispatch('userdatasets/updateDatasetProperties', {
                datasetId: this.datasetId,
                properties: { providerUrl: this.datasetProviderLink}
            })
        },
        updateDescription() {
            this.$store.dispatch('userdatasets/updateDatasetProperties', {
                datasetId: this.datasetId,
                properties: { description: this.datasetDescription}
            })
        },
        updateFieldBeingEdited(field) {
            this.$emit('editing-ebx-field', field);
        },
        setSelectedToDate(value, cadence) {
            switch(cadence) {
                case 'year':
                    return moment.utc(value).add(1, 'years').subtract(1,'seconds').toISOString();
                case 'month':
                    return moment.utc(value).add(1, 'months').subtract(1,'seconds').toISOString();
                case 'week':
                    return moment.utc(value).add(1, 'weeks').subtract(1,'seconds').toISOString();
                case 'day':
                    return moment.utc(value).add(1, 'days').subtract(1,'seconds').toISOString();
            }
        },
        cadenceToText(cadence) {
            switch(cadence) {
                case 'year':
                    return 'Yearly';
                case 'month':
                    return 'Monthly';
                case 'week':
                    return 'Weekly';
                case 'day':
                    return 'Daily';
                default:
                    return 'Unknown';
            }
        }
    },
}
</script>