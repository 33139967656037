/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import { DatasetService } from '@/services/dataset.service';
import { AreaService } from '@/services/area.service'
import {ProvidesAttributes} from '@/workflow/abilities/providesAttributes';
import {ProvidesDataset} from '@/workflow/abilities/providesDataset';
import {ProvidesBands} from '@/workflow/abilities/providesBands';
import {ProvidesDatasetType} from '@/workflow/abilities/providesDatasetType';
// import ProvidesSavedVector from '@/workflow/abilities/providesSavedVector';
import {ProvidesImageExport} from '@/workflow/abilities/providesImageExport';
import {AbstractVisitor}from "./helpers/AbstractVisitor";
import {ProvideTimePeriod} from '@/workflow/abilities/providesTimePeriod';
import {ProvideCompositeGroupTimePeriod} from '@/workflow/abilities/providesCompositeGroupTimePeriod';
import {ProvidesMapArea} from '@/workflow/abilities/providesMapArea';
import {ProvidesVariable} from '../../workflow/abilities/providesVariable';
import {THEMATIC,NO_DATA_DROPDOWN_VALUE, NUMERIC, SAVED_WORKFLOW_ID_SEPERATOR, } from '@/constants/nextGenConstants';

class CaptureStateVisitor extends AbstractVisitor {
    constructor(workflowRegistry,globalDatasetService, blockId) {
        super(workflowRegistry, globalDatasetService, blockId)
        this._name = "CaptureStateVisitor";
    }

    // WORKFLOW VISITOR METHODS (alphabetical order)

    visitWorkflowCompareBlock(block) {
        console.log('Capture visitor -> workflow_compare block', block);
    }

    visitWorkflowClassifyBlock(block) {
        console.log('Capture visitor -> workflow_classify block', block.id);
        const collectionId = block.getFieldValue('training_data')
        const areas = AreaService.getAreasForCollection(collectionId)

        const startEndDates = this
            ._workflowState
            .gatherAbilities(ProvidesDataset)
            .getDateRanges()
            .reduce((acc, timePeriod) => {
                if(acc.startDate === null || timePeriod.getStartDate() > acc.startDate) {
                    acc.startDate = timePeriod.getStartDate()
                }
                if(acc.endDate === null || timePeriod.getEndDate() < acc.endDate) {
                    acc.endDate = timePeriod.getEndDate()
                }
                return acc
            },{startDate: null, endDate: null})


        var newAreas = {}
        areas.forEach((item,index) => {
            let areaObj = {}
            areaObj[index] = {
                "color":item.colour.slice(1),
                "description": item.name,
                "ebx:short_description":item.name,
            }
            newAreas = {...newAreas, ...areaObj}
        })
        const indexBand = {
            "description": 'Classification',
            "ebx:name": 'classification',
            "ebx:datatype": THEMATIC,
            "ebx:display": true,
            "ebx:origin": "user",
            "ebx:scale": 1,
            "gsd": 100, // probably should actually calculate this
            "name": 'classification', 
            "gee:classes": newAreas
        }

        const blockDefinition = {
            bands: [indexBand],
            indices: null,
            dataset_id: 'classification_'+block.id,
            vis: {
                bands: {
                    palette: 'gee:classes',
                    value: 'Classification'
                },
                display_name: 'Classification',
                min: Math.min(...Object.keys(newAreas).map(k => parseInt(k))),
                max: Math.max(...Object.keys(newAreas).map(k => parseInt(k)))
            },
            visBands: {
                palette: 'gee:classes',
                value: 'Classification'
            },
            visContrast: null,
            min_max: {
                min: Math.min(...Object.keys(newAreas).map(k => parseInt(k))),
                max: Math.max(...Object.keys(newAreas).map(k => parseInt(k)))
            },
            // user_defined_name: null,
            dataset_name: 'Classification',
            gsd: 100,
            type: 'raster'
        }
        
        const datasetAbility = this._workflowState.shareAbilityPrepend(ProvidesDataset, blockDefinition).stopAfter();
        this._workflowState.setCurrentDefinitionId(datasetAbility.currentDefinitionKey)
        this._workflowState.shareAbilityPrepend(ProvideTimePeriod)
            .setRange('dataset', startEndDates?.startDate?.format('YYYY-MM-DD'), startEndDates?.endDate?.format('YYYY-MM-DD'))
            .setDefaultDates({start_date: startEndDates?.startDate?.format('YYYY-MM-DD'), end_date: startEndDates?.endDate?.format('YYYY-MM-DD')})
            .setCadenceAndInterval({cadence: 'year', interval: 1})
            .stopAfter();
        this._workflowState.shareAbilityPrepend(ProvidesBands).setIndex(datasetAbility.getIndex())
            .stopAfter();

        if(block.getFieldValue('training_data') !== null && block.getFieldValue('training_data') !== NO_DATA_DROPDOWN_VALUE) {
            this._workflowState.shareAbilityPrepend(ProvidesMapArea).commit({ collectionId: block.getFieldValue('training_data') }).stopAfter();
        }

    }

    visitWorkflowUnsupervisedClassificationBlock(block) {
        console.log('Capture visitor -> workflow_classify block', block);
        let indexBand = {
            "description": 'Clusters',
            "ebx:name": 'cluster',
            "ebx:datatype": NUMERIC,
            "ebx:display": true,
            "ebx:origin": "user",
            "ebx:scale": 1,
            "gsd": 100, // probably should actually calculate this
            "name": 'cluster'
        }
        // Remove only to allow all other

        const startEndDates = this
            ._workflowState
            .gatherAbilities(ProvidesDataset)
            .getDateRanges()
            .reduce((acc, timePeriod) => {
                if(acc.startDate === null || timePeriod.getStartDate() > acc.startDate) {
                    acc.startDate = timePeriod.getStartDate()
                }
                if(acc.endDate === null || timePeriod.getEndDate() < acc.endDate) {
                    acc.endDate = timePeriod.getEndDate()
                }
                return acc
            },{startDate: null, endDate: null})
       
        const blockDefinition = {
            bands: [indexBand],
            indices: null,
            dataset_id: 'unsupervised_classification_'+block.id,
            vis: {
                bands: {
                    palette: 'gee:classes',
                    value: 'Classification'
                },
                display_name: 'Classification',
                min: 0,
                max: null
            },
            visBands: {
                palette: 'gee:classes',
                value: 'Classification'
            },
            visContrast: null,
            min_max: {
                min: 0,
                max: null
            },
            // user_defined_name: null,
            dataset_name: 'Classification',
            gsd: 100,
            type: 'raster'
        }
        
        const datasetAbility = this._workflowState.shareAbilityPrepend(ProvidesDataset, blockDefinition).stopAfter();
        this._workflowState.setCurrentDefinitionId(datasetAbility.currentDefinitionKey)
        this._workflowState.shareAbilityPrepend(ProvideTimePeriod)
            .setRange('dataset', startEndDates?.startDate?.format('YYYY-MM-DD'), startEndDates?.endDate?.format('YYYY-MM-DD'))
            .setDefaultDates({start_date: startEndDates?.startDate?.format('YYYY-MM-DD'), end_date: startEndDates?.endDate?.format('YYYY-MM-DD')})
            .setCadenceAndInterval({cadence: 'year', interval: 1})
            .stopAfter();
        this._workflowState.shareAbilityPrepend(ProvidesBands).setIndex(datasetAbility.getIndex()).stopAfter();
    }
    /**
     * Function that stores the actions we perform when visitor visits define block
     * @param {*} block 
     */
    async visitWorkflowDefineDatasetBlock(block) {
        console.log('Capture visitor -> workflow define dataset block', block)

        const datasetId = block.getFieldValue('dataset_options');

        const blockEnabled = block.isEnabled()
        block.isEnabled(false)

        const globalDatasets = await this.getGlobalDatasets()
        let globalDataset = globalDatasets.findById(datasetId)
        const metaData = await DatasetService.getMetadata(datasetId)

        // we need to pick up the definition attributes from block state where available
        if (globalDataset || metaData) {

            let start_date = '';
            let end_date = '';
            let default_dates = null;
            let cadence = null;

            const blockDefinition = {
                bands: [],
                indices: [],
                dataset_id: datasetId,
                vis: null,
                visBands: null, 
                visContrast: globalDataset ? globalDataset.visContrast || null : null,
                min_max: {},
                dataset_name: globalDataset ? globalDataset.title : null,
                gsd: null,
                type: (globalDataset && globalDataset.type === 'image_collection' ? 'raster':'vector'),
                attributes: globalDataset ? globalDataset.attributes || null : null,
            }

            if (metaData) {
                //create a time period
                const datasetDates = await DatasetService.getDatasetStartAndEndDateRange(block.getFieldValue('dataset_options'));
                start_date = datasetDates.start_date
                end_date =  datasetDates.end_date
                default_dates = await DatasetService.getDatasetDefaultTimeRange(block.getFieldValue('dataset_options'));
                cadence = await DatasetService.getDatasetDefaultCadenceAndInterval(block.getFieldValue('dataset_options'))

                blockDefinition.bands = await DatasetService.getBandsForDisplay(datasetId);
                blockDefinition.indices = await DatasetService.getValidIndices(datasetId);
                blockDefinition.vis = await DatasetService.getVisualizationBlock(datasetId);
                blockDefinition.visBands = await DatasetService.getVisualizationBands(datasetId);
                blockDefinition.visContrast = await DatasetService.getVisualizationContrastType(datasetId);
                blockDefinition.min_max = await DatasetService.getVisualizationMinMax(datasetId);
                blockDefinition.dataset_name = await DatasetService.getDatasetTitle(datasetId);
                blockDefinition.gsd = await DatasetService.getDatasetGsd(datasetId);
                blockDefinition.type = await DatasetService.getDatasetType(datasetId);
                blockDefinition.attributes = await DatasetService.getGEESchemaForDatasetId(datasetId);
            
            } else {
                // Get the state at a saved definition
                const definitions = this._workflowRegistry.gatherAbilities(ProvidesDataset).map(ability => {
                    const defs = ability.getDefinitions()
                    const definitonKey = (datasetId.split(SAVED_WORKFLOW_ID_SEPERATOR).length > 1 ? datasetId.split(SAVED_WORKFLOW_ID_SEPERATOR)[1] : null)
                    const foundDef = defs.find(def => def.currentDefinitionKey === definitonKey)
                    return foundDef ? foundDef.getState() : undefined
                }).filter(def => def !== undefined)
                
                if(definitions.length > 0) {
                    const oldDefinition = definitions[0]
                    blockDefinition.bands = oldDefinition.bands;
                    blockDefinition.indices = oldDefinition.indices;
                    blockDefinition.vis = oldDefinition.vis;
                    blockDefinition.visBands = oldDefinition.visBands;
                    blockDefinition.visContrast = oldDefinition.visContrast;
                    blockDefinition.min_max = oldDefinition.min_max;
                    blockDefinition.dataset_name = oldDefinition.dataset_name;
                    blockDefinition.gsd = oldDefinition.gsd;
                    blockDefinition.type = oldDefinition.type;
                    blockDefinition.attributes = oldDefinition.attributes;

                    if(oldDefinition.date_range) {
                        start_date = oldDefinition.date_range.start;
                        end_date = oldDefinition.date_range.end;
                        cadence = oldDefinition.date_range.cadence_and_interval;
                        default_dates = oldDefinition.date_range.default_dates;
                    }
                    
                }
            }

            const datasetAbility = this._workflowState.shareAbility(ProvidesDataset, blockDefinition);
            this._workflowState.setCurrentDefinitionId(datasetAbility.currentDefinitionKey)
            // bands declared on dataset, no extra state is needed. However allows for other blocks to provide bands
            // set index shares the abilty state across both dataset and bands. usually want this to not be set and be seperate state indexes,
            this._workflowState.shareAbility(ProvidesBands).setIndex(datasetAbility.getIndex());
            this._workflowState.shareAbility(ProvidesAttributes).setIndex(datasetAbility.getIndex());

            this._workflowState.shareAbility(ProvidesDatasetType,{'type': blockDefinition.type});

            this._workflowState
                .shareAbility(ProvideTimePeriod)
                .setRange('dataset', start_date, end_date)
                .setDefaultDates(default_dates)
                .setCadenceAndInterval(cadence);

            if(globalDataset && globalDataset.origin === 'area_service'){
                this._workflowState.shareAbility(ProvidesMapArea).commit({ areaId: globalDataset.id, studyArea: true });
            }

            block.setEnabled(blockEnabled)

             // Adds the variables to the ability
            const userVariables = block.getVariables()
            const blockId = block.id;
            userVariables.forEach(variable => {
                this._workflowState.shareAbility(ProvidesVariable).commit({variable: variable, block_id: block.id})
                variable.appendBlockId(blockId, this._workflowState.id)
            })
        }
    }

    visitWorkflowEmptyContainerBlock(block) {
        console.log('Capture visitor -> workflow_empty_container block', block);
    }

    // MODIFIER VISITOR METHODS (alphabetical order)

    visitModifierAttributeSelectBlock(block) {
        console.log('Capture visitor -> attribute select block', block);
    }

    visitModifierFilterAttributesBlock(block) {
        console.log('Capture visitor -> modifier filter attributes block', block);
    }

    async visitModifierRemoveAttributesBlock(block) {
        console.log('Capture visitor -> modifier remove attributes block', block);
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }

        const attributesToRemove = block.getFieldValue('remove_attributes').split(';')

        if (!attributesToRemove) {
            return
        }
        attributesToRemove.map(a => [a, a])
        this._workflowState.shareAbility(ProvidesAttributes, {filtered_attributes: attributesToRemove})
    }

    visitModifierBandsBlock(block) {
        console.log('Capture visitor -> modifier bands block', block);
        const bands = block.getFieldValue('bands')
        const definition =  this._workflowState.gatherFirstAbility(ProvidesDataset, false)
        if(definition && bands.length > 0) {
            definition.setFilteredBands(bands.split(';'), block.id)
        }
    }

    visitModifierRemoveBandsBlock(block) {
        console.log('Capture visitor -> modifier remove bands block', block);
        const bandsToRemove = block.getFieldValue('bands').split(';')
        const bands = this._workflowState
            .gatherAbilities(ProvidesBands)
            .availableBands(block.id)
            .map(b => {
                return b['ebx:name']
            })

        // remove bands from the list of available bands
        if (bandsToRemove.length > 0) {
            bandsToRemove.forEach(b => {
                const index = bands.indexOf(b)
                if (index > -1) {
                    bands.splice(index, 1)
                }
            })
        }
        const definition =  this._workflowState.gatherFirstAbility(ProvidesDataset, false)
        if(definition) {
            definition.setFilteredBands(bands, block.id)
        }
    }

    visitModifierCloudMaskBlock(block) {
        console.log('Capture visitor -> cloud mask block', block);
    }

    visitModifierCompositeBlock(block) {
        console.log('Capture visitor -> modifier composite block', block)
        const cadence = block.getFieldValue(block.FIELD.GROUP);
        const interval = block.getFieldValue(block.FIELD.INTERVAL);
        const method = block.getFieldValue(block.FIELD.METHOD);
        this._workflowState.shareAbility(ProvideCompositeGroupTimePeriod,{ cadence, interval, method })
    }

    visitModifierDaterangeBlock(block) {
        console.log('Capture visitor -> date range block', block);

        let cadence = block.getState(block.FIELD.DATE_RANGE_OPTION);

        // TODO get the start and end date from the workflow state
        // We're gonna need the start and end dates to compile the date ranges
        const definition = this._workflowState.gatherAbilities(ProvideTimePeriod).current();
        const startDate = definition.getStartDate();
        const startYear = startDate.year();
        const endDate = definition.getEndDate();
        const endYear = endDate.year();

        if (cadence === 'day_of_year' || cadence === 'day_of_month') {
            let start = block.getFieldValue(block.FIELD.START);
            let end = block.getFieldValue(block.FIELD.END);
            if(start !== null && end !== null) {

                this._workflowState.shareAbility(ProvideTimePeriod)
                .setRange(cadence, start, end, startYear, endYear)
                .setDefaultDates({start_date: start, end_date: end})
                .setCadenceAndInterval({cadence: cadence, interval: 1})
                .setAsFilter(true)
            }
        }

        if (cadence === 'year' || cadence === 'month') {
            let multipleDates = block.getState(block.FIELD.MULTIPLE);
            if (multipleDates.length > 0 && multipleDates[0]) { 
                this._workflowState.shareAbility(ProvideTimePeriod)
                .setMultipleRanges(cadence, multipleDates, startDate, endDate)
                .setAsFilter(true)
            }
        }

    }

    /**
     * Function that stores the actions we perform when visitor visits modifier_mask block
     * @param {*} block 
     */
    visitModifierMaskBlock(block) {
        console.log('Capture visitor -> modifier mask block', block)
    }

    visitModifierOrbitBlock(block) {
        console.log('Capture visitor -> modifier orbit block', block)
    }

    visitModifierTimePeriodBlock(block) {
        console.log('Capture visitor -> time period block', block);

        let start = block.getField(block.FIELD.FROM).getVariableValue();
        let end = block.getField(block.FIELD.TO).getVariableValue();

        // get the current period to add to the variable for validation.
        // do not add the new period we are creating here as variables in this block need to ignore these value as that is what they are setting.
        const currentTimePeriod = this._workflowState.gatherAbilities(ProvideTimePeriod).last();

        //If date range variable, get the dates from the start/end array
        if(Array.isArray(start) && Array.isArray(end)){
            start = start[0]
            end = end[1]
        }
        const timePeriodAbility = this._workflowState.shareAbility(ProvideTimePeriod).setRange('custom', start, end)
        timePeriodAbility.setDefaultDates({start_date: timePeriodAbility.getStartDate().format('YYYY-MM-DD'), end_date: timePeriodAbility.getEndDate().format('YYYY-MM-DD')});
        timePeriodAbility.setCadenceAndInterval({cadence: timePeriodAbility.getCadence(), interval: 1});

        // Adds the variables to the ability
        const userVariables = block.getVariables()
        const blockId = block.id; 
        userVariables.forEach(variable => {
            this._workflowState.shareAbility(ProvidesVariable).commit({variable: variable, block_id: block.id})  
            //Add the block id to the variable so know it is used in a workflow.
            variable.appendBlockId(blockId, this._workflowState.id)
            if(variable.getType() === 'date' || variable.getType() === 'date range') {
                variable.setDateRanges(currentTimePeriod.getDateRanges(true), this._workflowState.id)
            }
        })
    }

    // INPUT VISITOR METHODS (alphabetical order)

    visitInputDatasetBlock(block) {
        console.log('Capture visitor -> input dataset block', block);
        if(block.getFieldValue('selected_dataset') && block.getFieldValue('selected_dataset') !== NO_DATA_DROPDOWN_VALUE) {
            this._workflowState.setCurrentDefinitionId(block.getFieldValue('selected_dataset'))
        }
    }

    // OUTPUT VISTOR METHODS (alphabetical order)

    visitAddMapLayerBlock(block) {
        console.log('Capture visitor -> add map layer block', block.id);
        let imageType = block.getFieldValue('image_type_selection');
        if(this._workflowState.imageTypeSelection === undefined) {
            this._workflowState.imageTypeSelection = {}
        }
        if(this._workflowState.advancedImageOptions === undefined) {
            this._workflowState.advancedImageOptions = {}
        }

        this._workflowState.imageTypeSelection[block.id] = imageType ;

        let modalValue = block.getFieldValue("modal_field")
        this._workflowState.advancedImageOptions[block.id] = modalValue;
    }

    visitOutputAddTableBlock(block) {
        const modal = block.getFieldValue(block.FIELD.TABLE)

        if (modal === null) {
            return;
        }

        const datasetType = block.getState('dataset_type');

        // non raster datasets don't have ability to subscribe to other datasets
        if (datasetType !== 'raster') {
            return;
        }

        // validate created rows exists
        if (!modal.createdRows) {
            return;
        }

        const userRows = modal.createdRows;
        // filter for rows where type is "feature_collection"
        const featureCollectionRows = userRows.filter(row => row.type === 'feature_collection');
        const collectionIds = featureCollectionRows.map(row => row.dataset);

        // provide area ids to workflow state
        collectionIds.forEach(id => {
            this._workflowState
                .shareAbility(ProvidesMapArea)
                .commit({
                    collectionId: id,
                })
            })
    }

    visitOutputMultitemporalBlock(block) {
        console.log('Capture visitor -> output_multitemporal block', block);
    }

    visitOutputSaveDatasetBlock(block) {
        console.log('Capture visitor -> save dataset block', block);
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(!definition) {
            return;
        }
        const userDefinedName = block.getFieldValue('dataset_name')
        this._workflowState.createSavedDefinition(block.id, userDefinedName) 
        const datasetState = Object.assign({}, definition.getState())
        const timePeriod = this._workflowState.gatherAbilities(ProvideTimePeriod).last();
        const bands = this._workflowState.gatherAbilities(ProvidesBands)
        const attributes = this._workflowState.gatherAbilities(ProvidesAttributes)
        const type = this._workflowState.gatherAbilities(ProvidesDatasetType).last()
        
        if(timePeriod) {
            datasetState.date_range = {
                'start':timePeriod.getStartDate().format('YYYY-MM-DD'),
                'end': timePeriod.getEndDate().format('YYYY-MM-DD'),
                'cadence_and_interval': timePeriod.getCadenceAndInterval(),
                'default_dates': timePeriod.getDefaultDates(),
            }
        }
        if (bands) {
            datasetState.bands = bands.availableBands(block.id)
        }
        if (attributes) {
            datasetState.attributes = attributes.allAttributes(true)
        }
        if(type) {
            datasetState.type = type.getType()
        }

        //set state variable ONLY for this definiton to allow use to load this instead of the default dataset data
        const currentDefinition = this._workflowState.getDefinition(block.id)
        currentDefinition.setDefinitionOnlyState({
            newDataset:datasetState, 
            savedBlockId: block.id
        })
    }


    // MISCAELANEOUS VISITOR METHODS (alphabetical order) (to be categorized)

    visitIndicesBlock(block) {
        console.info('Capture visitor -> indices block', block);
        const newIndex = block.getState(block.FIELD.INDEX)

        if(newIndex === null) {
            return;
        }
        
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);

        if(!definition) {
            return;
        }

        const indexBand = {
            description: newIndex,
            "ebx:name": newIndex,
            "ebx:datatype": NUMERIC,
            "ebx:display": true,
            "ebx:datatype_theme": "index",
            "ebx:scale": "1.0",
            "ebx:wavelength_center_nm": null,
            "ebx:wavelength_min_nm": null,
            "ebx:wavelength_max_nm": null,
            "gsd": Math.max(...definition.getBands().map(band => band['gsd'])), // probably should actually calculate this
            "name": newIndex
        }

        this._workflowState.shareAbility(ProvidesBands).asExtraBand(indexBand).prependBand()

        definition.updateState({
            user_defined_name: definition.isSaved() ? definition.getUserDefinedName() : null
        })
    }

    visitAnalysisZonalStatisticsBlock(block) {
        // adds the zonal stats band to the dataset
        console.info('Capture State visitor -> zonal statistics block', block);
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);

        if(!definition) {
            return;
        }

        if (definition.isVector()) {
            const attributeName = block.getFieldValue(block.FIELD.ATTRIBUTE_NAME)
            this._workflowState.shareAbility(ProvidesAttributes, {
                attributes: [{
                    description: attributeName,
                    name: attributeName, // The name to use as a variable, what the user types in
                    type: 'DOUBLE',
                    'ebx:datatype': 'numeric',
                    'ebx:datatheme': 'calculation',
                    'ebx:name': attributeName, // The name to use as a variable, what the user types in
                    'ebx:short_description': attributeName,
                    'date': 'date',
                    'date_group': 'date_group',
                }]
            })
            return
        }

        const newBandName = block.getFieldValue(block.FIELD.BAND_NAME)

        if(!newBandName || newBandName.length === 0) {
            return;
        }

        const selectedBand = block.getFieldValue(block.FIELD.BANDS)
        if (!selectedBand || selectedBand === NO_DATA_DROPDOWN_VALUE) {
            return;
        }

        // If not vector then add a band

        const selectedBandDef = definition.getBands().find(band => band["ebx:name"] === selectedBand)
        let bandGsd = 100 // default val

        if(selectedBandDef && selectedBandDef['gsd']) {
            // TODO: workout why index band is not available on definition.getBands()
            bandGsd = selectedBandDef['gsd']
        }

        const zonalStatsBand = {
            description: newBandName,
            "ebx:name": newBandName,
            "ebx:datatype": NUMERIC,
            "ebx:display": true,
            "ebx:datatype_theme": "zonal_statistics",
            "ebx:scale": "1.0",
            "ebx:wavelength_center_nm": null,
            "ebx:wavelength_min_nm": null,
            "ebx:wavelength_max_nm": null,
            "gsd": bandGsd,
            "name": newBandName
        }

        this._workflowState.shareAbility(ProvidesBands).asExtraBand(zonalStatsBand).prependBand()

        definition.updateState({
            user_defined_name: definition.isSaved() ? definition.getUserDefinedName() : null
        })

        const collectionID = block.getFieldValue(block.FIELD.DATASET)
        this._workflowState
                    .shareAbility(ProvidesMapArea)
                    .commit({
                        collectionId: collectionID,
                    })
    }

    visitRegressionBlock(block) {
        console.log('Capture visitor -> regression block', block);

        const regressionBand = block.getState(block.FIELD.BAND_NAME)

        if(regressionBand === null) {
            return;
        }
        
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);

        if(!definition) {
            return;
        }

        const newBand = {
            description: regressionBand,
            "ebx:name": regressionBand,
            "ebx:datatype": NUMERIC,
            "ebx:display": true,
            "ebx:wavelength_center_nm": null,
            "ebx:wavelength_min_nm": null,
            "ebx:wavelength_max_nm": null,
            "gsd": Math.max(...definition.getBands().map(band => band['gsd'])), // probably should actually calculate this
            "name": regressionBand
        }

        this._workflowState.shareAbility(ProvidesBands).asExtraBand(newBand).prependBand()

        definition.updateState({
            user_defined_name: definition.isSaved() ? definition.getUserDefinedName() : null
        })
    }

    visitStudyAreaBlock(block) {
        console.log('Capture visitor -> study area block', block)
        // Field is always a type of field_variable_dropdown
        const studyAreaField = block.getField('study_area_options')
        if(studyAreaField !== null && studyAreaField.getValue() !== NO_DATA_DROPDOWN_VALUE) {
            // get the area id, if it is a variable get the value of the variable
            //Previously named areaId which was misleading as it could be a collection
            let aoiId = studyAreaField.getVariableValue() // falls back to field value if no variable is selected
           
            //Check whether the id has come from an area or collection
            let type = AreaService.isAreaOrCollection(aoiId)

            // areaId could be null here as its not be added to the variable, but the variable has been selected.
            if(aoiId !== null && type === 'area') {
                this._workflowState.shareAbility(ProvidesMapArea).commit({areaId: aoiId, studyArea: true});
            } else if(aoiId !== null && type === 'collection') {
                this._workflowState.shareAbility(ProvidesMapArea).commit({collectionId: aoiId, studyArea: true});
            }

            // Adds the variables to the ability
            const userVariables = block.getVariables()
            const blockId = block.id;
            userVariables.forEach(variable => {
                this._workflowState.shareAbility(ProvidesVariable).commit({variable: variable, block_id: block.id})
                variable.appendBlockId(blockId, this._workflowState.id)
            })
        }
    }

    visitAnalysisCalculatorBlock(block) {
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);

        if(!definition) {
            return;
        }

        const calculatorOptions = block.getFieldValue('calculator')
        if(!calculatorOptions || !calculatorOptions.name || calculatorOptions.name.length === 0) {
            return
        }
        
        if(calculatorOptions.type === 'band') {
            const indexBand = {
                description: calculatorOptions.name,
                "ebx:name": calculatorOptions.name,
                "ebx:datatype": NUMERIC,
                "ebx:display": true,
                "ebx:datatype_theme": "index",
                "ebx:scale": "1.0",
                "ebx:wavelength_center_nm": null,
                "ebx:wavelength_min_nm": null,
                "ebx:wavelength_max_nm": null,
                "gsd": Math.max(...definition.getBands().map(band => band['gsd'])), // probably should actually calculate this
                "name": calculatorOptions.name
            }
    
            this._workflowState.shareAbility(ProvidesBands).asExtraBand(indexBand).prependBand()
        }

        if(calculatorOptions.type === 'dataset') {

            const timePeriod = this._workflowState.gatherAbilities(ProvideTimePeriod).last();

            this._workflowState.createSavedDefinition(block.id, calculatorOptions.name) 
            const datasetState = Object.assign({}, definition.getState())
            const varList = calculatorOptions.definedVariables.map(variable => {
                return variable.properties.map(p => p['ebx:name'])
            })
            // get all combinations of values, based on an array of arrays that are strings
            const cartesian = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())));
            datasetState.bands = cartesian(...varList).map(bands => {
                const name = Array.isArray(bands) ? bands.join('_') : bands
                return {
                    description: name,
                    "ebx:name": name,
                    "ebx:datatype": NUMERIC,
                    "ebx:display": true,
                    "ebx:datatype_theme": "index",
                    "ebx:scale": "1.0",
                    "ebx:wavelength_center_nm": null,
                    "ebx:wavelength_min_nm": null,
                    "ebx:wavelength_max_nm": null,
                    "gsd": Math.max(...definition.getBands().map(band => band['gsd'])), // probably should actually calculate this
                    "name": name
                }
            })
            //set start end dates
            if(timePeriod) {
                datasetState.date_range = {
                    'start':timePeriod.getStartDate().format('YYYY-MM-DD'),
                    'end': timePeriod.getEndDate().format('YYYY-MM-DD'),
                    'cadence_and_interval': timePeriod.getCadenceAndInterval(),
                    'default_dates': timePeriod.getDefaultDates(),
                }
            }

            //set state variable ONLY for this definiton to allow use to load this instead of the default dataset data
            const currentDefinition = this._workflowState.getDefinition(block.id)
            currentDefinition.setDefinitionOnlyState({'newDataset':datasetState})
        }
    }

    async visitAnalysisFocalAnalysisBlock(block) { 
        console.log('Capture visitor -> Focal Analysis block', block);
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);

        if(!definition) {
            return;
        }

        const definitions = this._workflowState.gatherAbilities(ProvidesDataset);
        const allBands = this._workflowState.gatherAbilities(ProvidesBands);
        const bandOptions = allBands.toDropDownOptions(definitions.length <= 1);
        const datasetBands = bandOptions.map(innerArray => innerArray[0]); // bands available from dataset

        const focalAnalysisOptions = block.getFieldValue('focal_modal')
        const selectedBands = focalAnalysisOptions.selectedBands //bands selected by user in modal
        const suffix = focalAnalysisOptions.suffix //suffix selected by user in modal

        if (selectedBands && selectedBands.length > 0) {
            selectedBands.forEach((band) => { //for each selected band 
                if (datasetBands.includes(band)) { //if that band is in the current dataset 
                    band = band.concat(suffix) //add the suffix 
                    var newBand = { //add as new band as before
                        description: band, 
                        "ebx:name": band, 
                        "ebx:datatype": NUMERIC,
                        "ebx:display": true,
                        "ebx:datatype_theme": "spectral_band",
                        "ebx:scale": "1.0",
                        "ebx:wavelength_center_nm": null,
                        "ebx:wavelength_min_nm": null,
                        "ebx:wavelength_max_nm": null, 
                        "gsd": Math.max(...definition.getBands().map(band => band['gsd'])), // probably should actually calculate this
                        "name": band
                    }
                    this._workflowState.shareAbility(ProvidesBands).asExtraBand(newBand).prependBand()
                }
        })
        }
    }
    
    async visitAnalysisThematicProcessingBlock(block) {
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);

        if(!definition) {
            return;
        }

        const thematicProcessingOptions = block.getFieldValue('thematic_processing')

        if(
            typeof thematicProcessingOptions === 'object' && 
            typeof thematicProcessingOptions.newBandName === 'string' && 
            thematicProcessingOptions.newBandName.length > 0 
        ) {
            const classes = {}
            thematicProcessingOptions.thresholds.forEach(threshold => {
                classes[threshold.value] = {
                    color: threshold.color.slice(1),
                    description: threshold.description,
                    "ebx:short_description":threshold.description,
                }
            })

            const indexBand = {
                description: thematicProcessingOptions.newBandName,
                "ebx:name": thematicProcessingOptions.newBandName,
                "ebx:datatype": THEMATIC,
                "ebx:display": true,
                "ebx:datatype_theme": "index",
                "ebx:scale": "1.0",
                "ebx:wavelength_center_nm": null,
                "ebx:wavelength_min_nm": null,
                "ebx:wavelength_max_nm": null,
                "ebx:origin": "user",
                "gsd": Math.max(...definition.getBands().map(band => band['gsd'])), // probably should actually calculate this
                "name":  thematicProcessingOptions.newBandName,
                "gee:classes":classes
            }
            
            this._workflowState.shareAbility(ProvidesBands).asExtraBand(indexBand).prependBand() // prepend band - adds new bands to top of dropdown list

        }
    }

    async visitModifierReAssignClassesBlock(block) {
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);

        if(!definition) {
            return;
        }

        const reassignClassesOptions = block.getFieldValue('classes')
        const convertToString = v => '' + v
        if(
            typeof reassignClassesOptions === 'object' && 
            typeof reassignClassesOptions.newBandName === 'string' && 
            reassignClassesOptions.newBandName.length > 0 
        ) {
            const classes = {}
            let usedClassValues = []
            reassignClassesOptions.newClasses.forEach(newClass => {
                classes[newClass.value] = {
                    color: newClass.color.slice(1),
                    description: newClass.description,
                    "ebx:short_description":newClass.description,
                }
                usedClassValues = usedClassValues.concat(newClass.classes.map(convertToString))
            })
            const bands = block.getState('bands')
            if (Array.isArray(bands)) {
                const band = bands.find(band => band['ebx:name'] === reassignClassesOptions.band)
                if (band && band['gee:classes']) {
                    const oldClassKeys = Object.keys(band['gee:classes'])
                    const diffClasses = oldClassKeys.filter(x => !usedClassValues.includes(convertToString(x)));
                    for (const diffClass of diffClasses) {
                        classes[diffClass] = band['gee:classes'][diffClass]
                    }
                }
            }
            
            const indexBand = {
                description: reassignClassesOptions.newBandName,
                "ebx:name": reassignClassesOptions.newBandName,
                "ebx:datatype": THEMATIC,
                "ebx:display": true,
                "ebx:datatype_theme": "index",
                "ebx:scale": "1.0",
                "ebx:wavelength_center_nm": null,
                "ebx:wavelength_min_nm": null,
                "ebx:wavelength_max_nm": null,
                "ebx:origin": "user",
                "gsd": Math.max(...definition.getBands().map(band => band['gsd'])), // probably should actually calculate this
                "name":  reassignClassesOptions.newBandName,
                "gee:classes":classes
            }
            
            this._workflowState.shareAbility(ProvidesBands).asExtraBand(indexBand).prependBand() // prepend band - adds new bands to top of dropdown list
        }
    }

    visitBufferBlock(block) {
        console.log('Capture visitor -> Buffer block', block);
    }

    async visitOutputImage(block) {
        this._workflowState.shareAbility(ProvidesImageExport, {
            settings: block.getSettings(),
            name: block.getName()
        })
    }

    async visitAnalysisVectorAttributeBlock(block) { 
        console.log('Capture visitor -> vector attribute block', block);
        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }

        const attributeValue = block.getFieldValue('calculate_options')
        const attributeOptions = block.getField('calculate_options').getOptions()
        const attributeName = block.getFieldValue('vector_attribute_name_input')
        // find the text of the selected option
        const attributeToAdd = attributeOptions.find(option => option[1] === attributeValue)
        if (attributeToAdd) {
            this._workflowState.shareAbility(ProvidesAttributes, {
                attributes: [{
                    description: attributeToAdd[0],
                    name: attributeName, // The name to use as a variable, what the user types in
                    type: 'DOUBLE',
                    'ebx:datatype': 'numeric',
                    'ebx:datatheme': 'calculation',
                    'ebx:name': attributeName, // The name to use as a variable, what the user types in
                    'ebx:short_description': attributeName,
                    'date': 'date',
                    'date_group': 'date_group',
                }]
            })
        }
    }

    async visitAnalysisGeoprocessingBlock(block) {
        console.log('Capture visitor -> geoprocessing block', block);

        const geoprocessingType = block.getFieldValue(block.FIELD.PROCESSING_OPTIONS)
        // no collection to subscribe to
        if (block.NO_COLLECTION_VALS.includes(geoprocessingType)) {
            return;
        }

        const collectionID = block.getFieldValue(block.FIELD.COLLECTION_OPTIONS)

        this._workflowState
                    .shareAbility(ProvidesMapArea)
                    .commit({
                        collectionId: collectionID,
                    })
    } 
    async visitAnalysisConvertFeaturesBlock(block) {
        console.log('Capture visitor -> convert features block', block);

        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }

        definition.removeAllBands(block.id)
        
        const datesOptions = block.getFieldValue('dates_modal')
        const start = datesOptions.selectedFromDate
        const end  = datesOptions.selectedToDate

        const timePeriod = this._workflowState.shareAbility(ProvideTimePeriod)
            .setRange('custom', start, end)
            .setDefaultDates({start_date: start, end_date: end});
        timePeriod.setCadenceAndInterval({cadence: timePeriod.getCadence(), interval: 1})

        //This sets the type to raster
        this._workflowState.shareAbility(ProvidesDatasetType,{'type':'raster'})

        const rasterizedImage = {
            description: "rasterised image",
            "ebx:name": "RasterisedImage",
            "ebx:datatype": NUMERIC,
            "ebx:display": true,
            "ebx:datatype_theme": "convert_features",
            "ebx:scale": "1.0",
            "ebx:wavelength_center_nm": null,
            "ebx:wavelength_min_nm": null,
            "ebx:wavelength_max_nm": null,
            "ebx:origin": "user",
            "gsd": Math.max(...definition.getBands().map(band => band['gsd'])),
            "name":  "RasterisedImage",
        }

        this._workflowState.shareAbility(ProvidesBands).asExtraBand(rasterizedImage).prependBand()
    }

    async visitAnalysisConvertImagesBlock(block) {

        const definition = this._workflowState.gatherFirstAbility(ProvidesDataset);
        if(definition === null) {
            return
        }

        // todo Dates? do we need them or are they already set from the raster above?

        this._workflowState.shareAbility(ProvidesDatasetType,{'type':'vector'})

        const newName = block.getFieldValue('name')
        if (newName && newName.length > 0) {
            const selectedBandName = block.getFieldValue('band')
            const selectedBand = definition.getBands().find(band => band['ebx:name'] === selectedBandName)
            let newAttribute = {
                description: newName,
                name: newName, // The name to use as a variable, what the user types in
                type: 'DOUBLE',
                'ebx:datatype': 'thematic',
                'ebx:datatheme': 'calculation',
                'ebx:name': newName, // The name to use as a variable, what the user types in
                'ebx:short_description': newName,
            }
            if(selectedBand) {
                newAttribute = Object.assign({}, selectedBand)
                newAttribute['ebx:name'] = newName
                newAttribute['ebx:short_description'] = newName
                newAttribute['name'] = newName
                newAttribute['description'] = newName
                if(newAttribute['gee:classes']) {
                    const keys = Object.keys(newAttribute['gee:classes'])
                    newAttribute['selector_title'] = newName
                    const selector_options = keys.reduce((acc, key) => {
                        acc[newAttribute['gee:classes'][key]['ebx:short_description']] = {
                            operator: "EQ",
                            value: key
                        }
                        return acc
                    },{})
                    newAttribute['selector_options'] = selector_options
                }
            }

            let classNameAttribute = Object.assign({}, newAttribute)
            classNameAttribute.type = 'STRING'
            classNameAttribute['ebx:datatype'] = 'string'
            classNameAttribute['ebx:name'] = newName + ' Class Name'
            classNameAttribute['ebx:short_description'] = newName + ' Class Name'
            classNameAttribute['name'] = newName + ' Class Name'
            classNameAttribute['description'] = newName + ' Class Name'
            
            this._workflowState.shareAbility(ProvidesAttributes, {
                replaces: true,
                attributes: [
                    newAttribute,
                    classNameAttribute,
                    {
                        description: 'num_points',
                        name: 'num_points', // The name to use as a variable, what the user types in
                        type: 'DOUBLE',
                        'ebx:datatype': 'numeric',
                        'ebx:datatheme': 'calculation',
                        'ebx:name': 'num_points', // The name to use as a variable, what the user types in
                        'ebx:short_description': 'num_points',
                    }
            ]
            })

        }

        
    }

}

export {CaptureStateVisitor}
