<template>
    <div class="table-creator--row-body table-creator--row-statistic">
        <div class="md-table-cell--width-x2" colspan="2">
            <label>
                Collection
            </label>
            <md-field>
                <md-select v-model="selected.dataset" placeholder="Select">
                    <md-option v-for="option in datasetOptions" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </md-option>
                </md-select>
            </md-field>
        </div>
        <div>
            <template v-if="showAttributeOptions">
                <label>
                    Properties
                </label>
                <TableMultiSelect :selected="selected.properties" :options="datasetProperties"
                    :allName="'All bands'" 
                    :display-text="selectedBandsDisplayText" 
                    v-model="selected.properties"
                    :enableSelectAll="false" mode="single"
                    label="Image band" ref="propertySelector"
                >
                    <!-- <template v-slot:after="data">
                        <button v-if="selectedMultipleProperties"
                            class="md-list-item-button md-list-item-container md-button-clean md-list-item-button--transformation"
                            @click.prevent="handleLoadTransformDialog('properties', data)">
                            <div class="md-list-item-content md-ripple">
                                <template v-if="hasPropertiesTransform">
                                    Edit Transform
                                </template>
                                <template v-else>
                                    Transform +
                                </template>
                            </div>
                        </button>
                    </template> -->
                </TableMultiSelect>
            </template>
        </div>
        <div>&nbsp;</div>
        <div class="md-table-cell--delete">
            <md-button class="md-icon-button" @click="$emit('delete-row', row.id)">
                <md-icon>delete</md-icon>
            </md-button>
        </div>
    </div>
</template>

<script>
import tableCreatorRowMixin from '../../mixins/tableCreatorRowMixin'
import TableMultiSelect from '../TableMultiSelect.vue';

export default {
    name: 'TableCreatorGroupFeatureRow',
    components: {
        TableMultiSelect
    },
    mixins: [tableCreatorRowMixin],
    data() {
        let filteredProperties = this.filterDatasetProperties(['thematic','string'], 'attributes', this.row.dataset)
        let selectedProperties = this.row.properties.length ? this.getPreselectedProperties(this.row.properties, filteredProperties) : []
        selectedProperties = selectedProperties.length ? [selectedProperties[0]] : []
        return {
            selected: {
                dataset: this.row.dataset || null,
                dates: this.row.dates,
                properties: selectedProperties,
                role: this.row.aggregation || this.row.role || null,
                propertiesTransform: this.row.propertiesTransform || null,
                datesTransform: this.row.datesTransform || null,
                transformPreference: this.row.transformPreference || null,
                displayType: this.row.displayType
            },
        }
    },
    props: {
        row: {
            type: Object,
            default: () => { }
        },
        datasets: {
            type: Array,
            default: () => []
        }
    },
    emits: [
        'update-row',
        'delete-row'
    ],
    computed: {
        //override mixin
        datasetProperties() {
            return this.filterDatasetProperties(['thematic','string'], 'attributes', this.selected.dataset)
        },
        //override mixin
        datasetOptions() {
            return this.filterDatasetOptions(['thematic','string'], 'attributes')
        },
        showAttributeOptions() {
            return this.selected.dataset !== null &&  this.datasetProperties.length > 1
        },
    },
    watch: {
        /**
         * detect changes to transforms and update selected data with changes
         */
        row: {
            handler(newRow) {
                this.handleRowUpdated(newRow)
            },
            deep: true
        },
        selected: {
            handler() {
                if (!this.selected.dataset) {
                    this.$emit('update-row', this.selected)
                    return;
                }

                const dataset = this.getDataset(this.selected.dataset);
                var selected = this.computeSelectedRow(dataset, 'rows', 'role')
                this.$emit('update-row', selected);
            },
            deep: true
        }
    }
}
</script>