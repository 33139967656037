
import * as Blockly from 'blockly/core';

/**
 * Class for an editable multi section dropdown field.
 * @extends {Blockly.FieldDropdown}
 * @alias Blockly.FieldNoTrimDropdown
 */
class FieldNoTrimDropdown extends Blockly.FieldDropdown {
  constructor(menuGenerator,validator,config) {
    if (Array.isArray(menuGenerator)) {
      const tempOptions = menuGenerator
      menuGenerator = function() {
        return tempOptions
      }
    }
    super(menuGenerator,validator,config)
  }
  /**
   * Update the options for the dropdown field, this will update the dropdown options without the need to recreate the field.
   * @param {*} options 
   * @param {*} newValue 
   * @returns {this}
   */
  updateOptions(options, newValue = null) {
    const oldGeneratedItems = this.generatedOptions_ || this.menuGenerator_;
    this.menuGenerator_ = options
    this.generatedOptions_ = null;
    this.selectedMenuItem_ = [];
    const newOptions = options.map(o => o[1])
    const oldLabel = oldGeneratedItems.filter(o => o[1] === this.value_)
    if(newValue !== null) {
      this.value_ = newValue
    }
    const newLabel = options.filter(o => o[1] === this.value_)
    
    if(newOptions.indexOf(this.value_) < 0 ){ 
      this.setValue(newOptions[0])
    }
    if(oldLabel.length === 0 || (newLabel.length === 1 && oldLabel[0][0] !== newLabel[0][0])){
      this.selectedOption_ = newLabel[0]
      this.forceRerender()
    }
    return this
  }

  /**
   * Use the value to find the text value from the dropdown, regardless of the current selection.
   * Note: getText will get the current selected value text.
   * @param {*} value 
   * @returns {String}
   */
  getTextFromOptionsUsingValue(value) {
    const options = this.getOptions()
    const selected = options.find(o => o[1] === value)
    return selected ? selected[0] : value
  }
}

export {FieldNoTrimDropdown};