import { AbstractCollection  } from "./AbstractCollection"

export class MapAreaCollection extends AbstractCollection {


    getStudyAreas() { 
        //get last study area from each workflow state
        return Array.from(new Set(Object.values(this.abilities
            .filter(ability => {
                const state = ability.getState()
                return state && state.studyArea === true
            })
            .reduce((areas, ability) => {
                const state = ability.getState()
                areas[ability.workflowState.id] = state.areaId || state.collectionId
                return areas
            }, {}))))
        }

}