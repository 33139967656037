<template>
  <md-dialog v-model:md-active="isDialogVisible" class="vue-swatches-dialog">
    <md-dialog-title>Select Colour</md-dialog-title>
    <md-dialog-content>
      <v-swatches v-model="model.colour" shapes="circles" inline :swatches="colours" @update:modelValue="onColourSelected" />
    </md-dialog-content>
  </md-dialog>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { VSwatches } from "vue3-swatches";

export default {
  name: "ColourPickerDialog",
  components: {
    VSwatches
  },
  data: () => ({
    colours: [     
      "#84da59",
      "#00965f",
      "#00Beaf",
      "#49B8ff",
      "#6D95cc",
      "#001958",
      "#370850",
      "#8C0d70",
      "#d256a0",
      "#ff72a4",
      "#ef4748",
      "#ff7132",
      "#f7ec39",
      "#d8e25b"
    ],
    isDialogVisible: false,
    model: {}
  }),
  emits: ["colour-selected"],
  methods: {
    showDialog(model) {
      this.model = model;
      this.isDialogVisible = true;
    },
    onColourSelected() {
      this.isDialogVisible = false;
      this.$emit("colour-selected", this.model);
    }
  }
};
</script>