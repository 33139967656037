<template>
    <div class="map-container">
        <div class="loading-overlay" v-show="showLoadingOverlay">
            <img :src="assets.loading" />
        </div>
        <div class="static-loading-overlay" v-show="staticMapLayersLoading">
            <!-- spinner will work with determinate and indeterminate, currently set to indeterminate as it looks better, thumbnails load very quickly -->
            <md-progress-spinner md-mode="indeterminate" class="static-loading-spinner md-accent" :md-value="percentStaticLayersLoaded">
            </md-progress-spinner>
        </div>
        <div class="google-map" ref="googleMap" id="googleMap"></div>

        <!-- waits until google maps finishes loading to set the custom components up - this allows bbox to be properly set -->
        <template v-if="mapLoaded">
            <GoogleMapBasemapView
                :google="google"
                :map="map"
                :orgCustomisations="orgCustomisations"
            />
            <GoogleMapSave
                v-if="showOverlays"
                :google="google"
                :map="map"
                data-html2canvas-ignore="true"
            />
            <GoogleMapMeasure
                v-if="showOverlays"
                :google="google"
                :map="map"
                :showDrawingManager="isDrawingModeEnabled"
                data-html2canvas-ignore="true"
            />
            <GoogleMapDrawing
                v-if="!isDashboardMap && showMapDrawing"
                :google="google"
                :map="map"
                :selected-area="selectedArea"
                :showDrawingManager="isDrawingModeEnabled"
                :isExplorer="isExplorer"
                ref="mapDrawing"
                data-html2canvas-ignore="true"
                @disable-run="disableRun"
                @add-area="addArea"
                @done-overlays="handleHideExplorerOverlayControls"
            >
                <template v-slot:default="drawing">
                    <GoogleMapPolygonUploadWrapper
                        v-if="showMapPolygonUpload"
                        v-model="showPolygonUploadDialog"
                        :google="google"
                        :map="map"
                        :selected-area="selectedArea"
                        :polygon-add-mode="polyAddMode"
                        :mapMeasureMode="drawing.mapMeasureMode"
                        :add-to-map="addToMap"
                        :added-to-map-callback="uploadedPolygonToMapCallback"
                        :hide-splitting-features="hideSplittingFeaturesOnUpload"
                        @polygon-clicked="drawing.polygonClicked"
                        @add-area="addArea"
                        data-html2canvas-ignore="true"
                        @set-polygon-listeners="(value) => drawing.setPolygonListeners(value)"
                        @set-overlay-listeners="(polygon, type) => drawing.setOverlayListeners(polygon, type)"
                    />
                </template>
                
            </GoogleMapDrawing>

            <GoogleMapLayersView
                :google="google"
                :map="map"
                :mapBBox="getTotalBBox"
                :map-layer-tiles-loading="mapLayerTilesLoading"
                :show-areas="isExplorer === false"
                :show-collections="isExplorer === false"
                ref="layerView"
                @upload-polygon-to-collection="handleOpenPolygonAreaDialog"
                @choose-area="handleOpenChooseAreaDialog"
                @removed-collection="handleRemovedCollection"
                data-html2canvas-ignore="true"
            />
            <GoogleMapMarker
                v-for="marker in markers"
                :key="marker.id"
                :marker="marker"
                :google="google"
                :map="map"
            />
            <GoogleMapLayerContainer
                v-for="(mapLayer, index) in mapLayers"
                :key="`mapLayer-${mapLayer.id}-${index}`"
                :index-key="`mapLayer-${mapLayer.id}-${index}`"
                :google="google"
                :map="map"
                :mapLayer="mapLayer"
                @loading-map-url="loadingNewMapUrl"
                @map-url-loaded="mapUrlLoaded"
                @tiles-loading="setTilesLoading(mapLayer, $event)"
            />
            <GoogleMapLoadingIndicator
                :google="google"
                :map="map"
                :tilesLoading="tilesLoading"
                data-html2canvas-ignore="true"
            />
            <GoogleMapChooseAreaDialog 
                v-model="showChooseAreaDialog" 
                :selected-collection-id="selectedCollectionId" 
                :loadIntoCollection="loadIntoCollection"
                @add-area="addArea"
                @error-dialog-fired="handleErrorDialogFired"
            />
            <EbxErrorDialog
                :showErrorDialog="showErrorDialog"
                :errorDialogMessage="errorDialogMessage"
                @error-dialog-active="handleErrorDialogActive"
            />
        </template>

    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import GoogleMapMarker from "@/components/ResultMap/GoogleMapMarker.vue";
import GoogleMapLayersView from "@/components/ResultMap/GoogleMapLayersView.vue";
import GoogleMapDrawing from "@/components/ResultMap/GoogleMapDrawing.vue";
import GoogleMapLayerContainer from "@/components/ResultMap/GoogleMapLayerContainer.vue";
import GoogleMapSave from "@/components/ResultMap/GoogleMapSave.vue";
import GoogleMapMeasure from "@/components/ResultMap/GoogleMapMeasure.vue";
import GoogleMapLoadingIndicator from "@/components/ResultMap/GoogleMapLoadingIndicator.vue";
import GoogleMapBasemapView from "@/components/ResultMap/GoogleMapBasemapView.vue";
import GoogleMapPolygonUploadWrapper from "@/components/ResultMap/GoogleMapPolygonUploadWrapper.vue";
import GoogleMapChooseAreaDialog from './GoogleMapChooseAreaDialog.vue';
import EbxErrorDialog from '@/components/EbxComponents/EbxErrorDialog.vue';
import PopupMixin from "./CustomPopups/PopupMixin";
import {RightClickPopup} from "./CustomPopups/RightClickPopup";
import PixelInspector from "@/components/ResultMap/CustomPopups/PixelInspector.vue";
import { mapSettings } from "@/constants/mapSettings";
import { mapsApiKey, firebaseConfig } from "@/firebase.js";
import { AuthService } from "@/services/auth.service";
import { AreaService } from "@/services/area.service";
import { ConfigService } from '@/services/config.service';
import * as configConstants from "@/config/constants";
import { OrgConfig } from "@/config/main";
import { Loader } from "@googlemaps/js-api-loader";
import { MAP_STUDY_AREA_COLLECTION_ID } from '../../constants/nextGenConstants';
import { globalEventBus } from '@/eventbus';
import assetsMixin from "@/components/mixins/assetsMixin.js" 
import { markRaw } from "vue";

export default {
    name: "TheResultMap",
    components: {
        GoogleMapMarker,
        GoogleMapLayersView,
        GoogleMapDrawing,
        GoogleMapLayerContainer,
        GoogleMapSave,
        GoogleMapMeasure,
        GoogleMapLoadingIndicator,
        GoogleMapBasemapView,
        GoogleMapPolygonUploadWrapper,
        GoogleMapChooseAreaDialog,
        EbxErrorDialog
    },
    props: {
        orgCustomisations: {
            type: Object,
            required: true,
        },
        showLoadingOverlay: {
            type: Boolean,
        },
        isDashboardMap: {
            type: Boolean,
            required: true,
        },
        isExplorer: {
            type: Boolean,
            required: true,
        }
    },
    emits: [
        'loaded',
        'disable-run'
    ],
    mixins: [PopupMixin, assetsMixin],
    data: () => ({
        markers: [
            /*{
        id: "edinburgh",
        position: { lat: 55.9533, lng: -3.1883 }
        }*/
        ],
        selectedArea: null,
        selectedCollectionId: null,
        mapCenter: {
            lat: 0,
            lng: 0,
        },
        mapBlocks: {},
        layerCounter: 0,
        noStaticMapLayersLoading: 0,
        totalNoStaticMapLayers: 0,
        mapLayerTilesLoading: {},
        mapLoaded: false,
        user: null,
        orgConfig: null,
        google: null,
        map: null,
        showPolygonUploadDialog: false,
        showChooseAreaDialog: false,
        polyAddMode: 'area',
        showErrorDialog: false,
        errorDialogMessage: 'Error',
        loadIntoCollection: false,
        addToMap: false,
        mapLayers: [],
        mapInitallyLoaded: false,
        showOverlays: false,
        subscriptions: [],
        uploadedPolygonToMapCallback: () => {},
        hideSplittingFeaturesOnUpload: false
    }),
    computed: {
        isDrawingModeEnabled() {
            return this.$store.state.maplayers.drawingMode.enabled === true;
        },
        storeMapLayers() {
            return this.$store.getters['maplayers/getOrderedMapLayers']
        },
        workflowMapLayerCount() {
            return this.$store.state.maplayers.mapLayers.length;
        },
        tilesLoading() {
            return this.workflowMapLayerCount > 0 && Object.keys(this.mapLayerTilesLoading).length > 0
        },
        /**
         * Return the mapsApiKey constant from firebase.js, or if it's not set, the default project apiKey.
         */
        mapsApiKey() {
            return mapsApiKey || firebaseConfig.apiKey;
        },
        mapConfig() {
            return {
                ...mapSettings,
                center: this.mapCenter,
                fullscreenControlOptions: {
                    position: this.google.maps.ControlPosition.BOTTOM_RIGHT,
                },
                zoomControlOptions: {
                    position: this.google.maps.ControlPosition.RIGHT_BOTTOM,
                },
            };
        },
        getTotalBBox() {
            let totalBBox = null;
            const visibleLayers = this.mapLayers.filter(layer => layer.bbox);
            if (visibleLayers.length > 0) {
                let coords = [];
                for (var i = 0; i < visibleLayers.length; i++) {
                    let layer = visibleLayers[i];
                    coords.push(layer.bbox["NE"]);
                    coords.push(layer.bbox["SW"]);
                }

                var lats = [];
                var lngs = [];

                for (var j = 0; j < coords.length; j++) {
                    lats.push(coords[j][1]);
                    lngs.push(coords[j][0]);
                }

                // calc the min and max lng and lat
                var minlat = Math.min.apply(null, lats),
                    maxlat = Math.max.apply(null, lats);
                var minlng = Math.min.apply(null, lngs),
                    maxlng = Math.max.apply(null, lngs);

                // create a bounding rectangle that can be used in leaflet
                totalBBox = [
                    [minlng, minlat],
                    [maxlng, maxlat],
                ];
            }
            return totalBBox;
        },
        staticMapLayersLoading() {
            // if there are layers still loading
            if (this.noStaticMapLayersLoading > 0) {
                return true;
            } else {
                return false;
            }
        },
        percentStaticLayersLoaded() {
            return (
                ((this.totalNoStaticMapLayers - this.noStaticMapLayersLoading) /
                    this.totalNoStaticMapLayers) *
                100
            );
        },
        // Map features
        showMapDrawing() {
            return OrgConfig.isFeatureShown(this.orgConfig, configConstants.FEATURES.MAP_DRAWING_TOOLS);
        },
        showMapAreas() {
            return OrgConfig.isFeatureShown(this.orgConfig, configConstants.FEATURES.MAP_AREA_TOOLS);
        },
        showMapPolygonUpload() {
            return OrgConfig.isFeatureShown(this.orgConfig, configConstants.FEATURES.POLYGON_UPLOAD);
        },
        exportAreaId() {
            return this.$store.state.maplayers.exportAreaId
        }
    },
    watch: {
        // DO NOT DO A DEEP WATCH ON storeMapLayers
        storeMapLayers(val){ 
            // This is a horrible workaround to fix issues with tileloaded events not firing on map layers
            // if a layer is removed after we have created them the events don't fire correctly
            // So this forces all the layers to be removed, Vue to re-render and then add them back in
            this.mapLayers = []
            setTimeout(() => {
                this.mapLayers = val;
            })

            this.hideAllPopups();
        },
        exportAreaId(areaId) {
            if(areaId) {
                this.exportPolygons({id: areaId});
            }
        }
    },
    mounted() {
        globalEventBus.$on('upload-polygon-to-collection', args =>{
            this.handleOpenPolygonAreaDialog(args.id, args.addToMap, args.hideSplit, args.callback);
        });
        this.subscriptions.push(AuthService.loggedUser$.subscribe(
            (user) => (this.user = user)
        ))
        ConfigService.getOrgConfig(this.user.orgId).then(function(config){
            this.orgConfig = config;
        }.bind(this));

        const loader = new Loader({
            apiKey: this.mapsApiKey,
            libraries: ["geometry", "drawing"], // MUST BE DOUBLE QUOTES ""
            version: "weekly"
        })
        loader.load().then((google) => {
            this.google = google;
            this.initializeMap();

            const pixelInspectorPopup = new RightClickPopup(PixelInspector, true)
            this.registerPopup(pixelInspectorPopup);
        }).catch(e => {
            console.error("Failed to load map", e)
        })

        this.subscriptions.push(AreaService.selectedAreaUpdate$.subscribe((area) => {
            if(area.id) {
                this.selectedArea = area
            } else {
                this.selectedArea = null
            }
        }));
    },
    methods: {
        handleErrorDialogActive(value) {
            this.showErrorDialog = value;
        },
        handleErrorDialogFired(errorText) {
            this.showErrorDialog = true;
            this.errorDialogMessage = errorText;
        },
        initializeMap() {
            const mapContainer = this.$refs.googleMap;
            this.map = markRaw(new this.google.maps.Map(mapContainer, this.mapConfig));
            this.map.addListener('tilesloaded', () => {
                if(this.mapInitallyLoaded === false) {
                    this.mapInitallyLoaded = true
                    setTimeout(() => {
                        this.$emit('loaded')
                        this.showOverlays = true
                    })
                }
            });

            this.google.maps.event.addListenerOnce(this.map, 'tilesloaded', ()=> {
                console.log("map finished loading")
                this.mapLoaded = true;
            })
        },
        handleOpenChooseAreaDialog(collectionId) {
            if (collectionId === MAP_STUDY_AREA_COLLECTION_ID) {
                AreaService.addStudyAreaIfNotExists()
            }
            this.selectedCollectionId = collectionId
            AreaService.setSelectedCollection(this.selectedCollectionId)
            this.polyAddMode = 'collection'
            this.selectedArea = null
            this.showChooseAreaDialog = true
            // if collectionId is STUDY AREA collection ID, then we aren't loading into a collection
            collectionId === MAP_STUDY_AREA_COLLECTION_ID ? this.loadIntoCollection = false : this.loadIntoCollection = true
        },
        handleRemovedCollection(collectionId) {
            if(this.selectedCollectionId === collectionId) {
                this.selectedArea = null
                this.selectedCollectionId = null
                this.polyAddMode = 'area'
            }
        },
        handleOpenPolygonAreaDialog(collectionId, addToMap = false, hideSplittingFeaturesOnUpload = false, callbackFunction = null) {
            this.selectedCollectionId = collectionId
            this.polyAddMode = 'collection'
            this.selectedArea = null
            this.addToMap = addToMap;
            this.uploadedPolygonToMapCallback = callbackFunction
            this.hideSplittingFeaturesOnUpload = hideSplittingFeaturesOnUpload
            this.showPolygonUploadDialog = true
        },
        clearLayers(clearWorkflowLayers = true, clearAssetLayers = true) {
            this.displayBlocks = [];
            this.totalNoStaticMapLayers = 0;
            //reset stores
            if(clearWorkflowLayers && clearAssetLayers) {
                this.$store.commit('maplayers/clearAllMapLayers');
            } else if(clearWorkflowLayers) {
                this.$store.commit('maplayers/clearWorkflowMapLayers');
            } else if(clearAssetLayers) {
                this.$store.commit('maplayers/clearAssetMapLayers');
            }
            this.$store.commit('maplayers/clearLegends');
            this.$store.commit('pixelInspector/clearPixels');
            this.mapLayerTilesLoading = {};
            this.hideAllPopups();
        },
        addArea(areaName) {
            this.$refs.layerView.addArea(areaName, this.selectedCollectionId);
        },
        loadingNewMapUrl() {
            // loading static thumbnail url
            this.noStaticMapLayersLoading++;
            this.totalNoStaticMapLayers++;
        },
        mapUrlLoaded() {
            // static thumbnail url loaded
            this.noStaticMapLayersLoading--;
        },
        setTilesLoading(mapLayer, loading) {
            // update whether tiles are loading or not
            if(loading) {
                this.mapLayerTilesLoading[mapLayer.id] = true
            } else {
                delete this.mapLayerTilesLoading[mapLayer.id]
            }
        },
        disableRun(value) {
            this.$emit("disable-run", value);
        },
        handleHideExplorerOverlayControls(lastSelectedArea) {
            this.$store.commit('maplayers/setDrawingModeEnabled', false)
            // Force a trigger, even if the last selected area is the same as the current selected area
            this.$store.commit('maplayers/setLastCreatedArea', null)
            this.$store.commit('maplayers/setLastCreatedArea', lastSelectedArea)
        },
        exportPolygons(area) {
           let polygons = AreaService.getShapesForArea(area.id);

           // get only polgyons that have type UserDrawn, and get the overlay
           let userDrawnPolygons = polygons.filter(polygon => polygon.type === 'UserDrawn');
           userDrawnPolygons = userDrawnPolygons.map(polygon => polygon.overlay);

           // create a new google maps Data object, which we'll then use to create geojson
           let polygonData = new this.google.maps.Data();

           if(!userDrawnPolygons.length > 0) {
               return;
           }

           userDrawnPolygons.forEach(polygon => { // this needs to be updated if more geometry types get added to the map
               var coords;
               // rectangle
               try {
                   let bounds = polygon.getBounds();
                   let NE = bounds.getNorthEast();
                   let SW = bounds.getSouthWest();
                   let NW = new this.google.maps.LatLng(NE.lat(), SW.lng());
                   let SE = new this.google.maps.LatLng(SW.lat(), NE.lng());
               
                   coords = [NE, NW, SW, SE];
               } catch { // polygon
                   coords = polygon.getPath().getArray();
               }

           
               // add to data object
               polygonData.add(new this.google.maps.Data.Feature({
                   geometry: new this.google.maps.Data.Polygon([coords]),
                   properties: {"name": area.name}
               }))
           })
           
           this.$store.commit('maplayers/exportPolygonsForAreaId', null);
           // export data to geojson
           polygonData.toGeoJson(function(json) {
               let string = JSON.stringify(json,null, 2);
               let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(string);
               let downloadAnchorNode = document.createElement('a');
               
               downloadAnchorNode.setAttribute("href", dataStr);
               let fileName = prompt('Please name the file being downloaded:')
               if(!fileName || fileName === '') {
                   fileName = 'polygons';
               }

               downloadAnchorNode.setAttribute("download", `${fileName}.geojson`);
               document.body.appendChild(downloadAnchorNode);
               downloadAnchorNode.click();
               downloadAnchorNode.remove();
           })
       },
    },
    beforeUnmount() {
        this.unregisterAllPopups();
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];
    }
};
</script>
<style scoped>
.map-container {
    display: grid;
    height: 100%;
    width: 100%;
}

.google-map {
    width: 100%;
    height: 100%;
    grid-area: 1 / 1;
}

.loading-overlay {
    display: flex;
    grid-area: 1 / 1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99;
}

.loading-overlay > img {
    display: block;
    max-height: 150px;
    max-width: 150px;
    margin: auto;
}

.static-loading-overlay {
    display: flex;
    grid-area: 1 / 1;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.7);
}

.static-loading-spinner {
    max-height: 150px;
    max-width: 150px;
    margin: auto;
}

</style>