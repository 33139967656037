<template>
    <div>

        <div v-if="hasGlobalPackages && !hidden" class="ebx-templates ebx-templates--getting-started">
            <div class="top-actions--container">
                <div class="ebx-header-2">
                    Getting started
                </div>
                <div class="close-button">
                    <md-button class="md-primary" @click="handleHide"><md-icon>close</md-icon></md-button>
                </div>
            </div>

            <div class="ebx-templates--template-cards" v-if="packages.length > 0">
                <div class="md-layout md-gutter">
                    <div v-if="!isExplorer" @click="handleNewBlankProject"
                        class="ebx-template-card ebx-template-card--blank md-layout-item" :class="cardClasses">
                        <EbxSimpleCard title="Create new blank project">
                            <template v-slot:image>
                                <md-icon>add</md-icon>
                            </template>
                        </EbxSimpleCard>
                    </div>
                    <div v-for="pkg in packages" :key="pkg.id"
                        class="ebx-template-card md-layout-item" :class="cardClasses">
                        <EbxFolderCard :details="{
                            title: pkg.title,
                            description: pkg.description,
                            images: pkg.projectsAssigned.projectThumbnails
                            }" @goToPage="() => packageOpen(pkg.id)" />
                    </div>
                </div>
            </div>

            <div class="bottom-actions--container">
                <EbxButton theme="tertiary" @click="handleGoToExplore" class="no-padding mr-0">
                    Explore all templates
                    <md-icon>chevron_right</md-icon>
                </EbxButton>
            </div>

        </div>
    </div>
</template>

<script>
import EbxFolderCard from '@/components/EbxComponents/EbxFolderCard.vue'
import EbxSimpleCard from '@/components/EbxComponents/EbxSimpleCard.vue'
import packageMixin from "@/components/mixins/packageMixin";

export default {
    components: {
        EbxFolderCard,
        EbxSimpleCard,
    },
    mixins: [packageMixin],
    props: {
        packages: {
            type: Array,
            required: true
        },
        isExplorer: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'new'
    ],
    data() {
        return {
            hidden: window.localStorage.getItem('gettingStartedPanelHidden') === 'true'
        }
    },
    watch: {
        hidden(value) {
            window.localStorage.setItem('gettingStartedPanelHidden', value === true ? 'true' : 'false')
        }
    },
    computed: {
        hasGlobalPackages() {
            return this.globalPackages && Array.isArray(this.globalPackages) && this.globalPackages.length > 0
        },
        cardClasses() {
            let cardCount = this.packages.length;
            if (!this.isExplorer) {
                cardCount += 1;
            }
            return {
                'md-size-25': cardCount >=4,
                'md-size-33': cardCount === 3,
                'md-size-50': cardCount === 2,
                'md-size-100': cardCount === 1,
                'md-small-size-50': true,
                'md-xsmall-size-100': true
            }
        }
    },
    methods: {
        handleNewBlankProject() {
            this.$emit('new')
        },
        handleHide() {
            this.hidden = true
        },
        handleGoToExplore() {
            this.$router.push({ name: 'Projects-Explore' })
        },
        packageOpen(id) {
            const selectedPackage = this.globalPackages.find(pkg => pkg.id === id);
            this.$store.commit('router/setPageState', {
                    origin: 'Projects'
            })
            this.$router.push( {
                name:'Package',
                params: {
                    packageId: selectedPackage.id,
                }
            })
        },
    }
}
</script>
