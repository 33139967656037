import { fromBlob as geotiffFromBlob } from 'geotiff'
import getAttribute from 'xml-utils/get-attribute.js';
import findTagsByName from 'xml-utils/find-tags-by-name.js';

export class GeotiffFileHandler {
    constructor(file) {
        this.file = file;
        this.tiff = null
        this.image = null
        this.bands = []
        this.bandCount = null
        this.meta = null
    }
    async open() {
        if (this.tiff === null) {
            this.tiff = await geotiffFromBlob(this.file)
            this.image = await this.tiff.getImage()
        }
        return this;
    }

    async compute() {
        if (this.bandCount !== null) {
            return this.bandCount
        }
        await this.open()
        //Check we have some image data
        if(this.image.getWidth() < 1 || this.image.getHeight() < 1){
            throw new Error(`File contains no image data`)
        }
        const xPx = 0
        const yPx = 0
        const window = [ xPx, yPx, xPx + 1, yPx + 1 ];
        const data = await this.image.readRasters( {window} );
        //Check we have some band information
        if(data.length < 1){
            throw new Error(`File contains no band information`)
        }
        this.bandCount = data.length
        const bands = []
        const gdalBands = await this.parseGDALBandInfo()
        for(let i=0;i<this.bandCount;i++){
            const gdalBand = gdalBands.find(b => b.sample === i)

            bands.push(gdalBand ? gdalBand.DESCRIPTION : `b${i+1}`)
        }
        this.bands = bands;
        this.meta = {
            width: this.image.getWidth(),
            height: this.image.getHeight(),
            bbox: this.image.getBoundingBox(),
            bytesPerPixel: this.image.getBytesPerPixel(),
            GDALMeta: this.image.getGDALMetadata(),
            noDataValue: this.image.getGDALNoData(),
            geoKeys: this.image.getGeoKeys(),
            origin: this.image.getOrigin(),
            resolution: this.image.getResolution(),
            samplePixels: this.image.getSamplesPerPixel(),
            tiePoints: this.image.getTiePoints(),
            tileHeight: this.image.getTileHeight(),
            tileWidth: this.image.getTileWidth(),
            pixelIsArea: this.image.getGeoKeys() ? this.image.pixelIsArea() : false
        }
        return this
    }

    async getBands(){
        await this.compute()
        return this.bands
    }
    async getBandCount(){
        await this.compute()
        return this.bandCount
    }
    async getMeta(){
        await this.compute()
        return this.meta
    }
    async getESPG() {
        const meta = await this.getMeta()
        if(meta.geoKeys) {
            return meta.geoKeys.ProjectedCSTypeGeoKey || meta.geoKeys.GeographicTypeGeoKey || null
        }
        return null
    }

    async parseGDALBandInfo(){
        await this.open()
        const xml = this.image.fileDirectory.GDAL_METADATA
        if(!xml){
            return []
        }
        const itemTags = findTagsByName(xml,'Item')
        return itemTags
            .filter(tag => getAttribute(tag, 'sample') !== undefined)
            .map(tag => {
                const sampleNumber = Number(getAttribute(tag, 'sample'))
                return {
                    ...this.image.getGDALMetadata(sampleNumber),
                    sample: sampleNumber
                }
            })
    }

    close() {
        if(this.tiff) {
            this.tiff.close()
            this.tiff = null
            this.image = null
        }
        return this
    }

}