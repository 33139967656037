<template>
    <div class="area-variable">
        <!-- explorer version -->
        <VariableDetailExplorer
            :variable="variable"
            :are-warnings="areWarnings"
            >
            <div v-if="selectedArea" class="area-variable--selected-area">
                <div class="area-variable--selection-card">
                    <div class="area-variable--area">
                        <div class="roundel" :style="{'background-color': selectedArea.colour}"></div>
                        <p class="ebx-secondary">Area</p>
                    </div>
                    <div class="area-variable--action">
                        <md-menu v-if="isDrawn" md-size="small" v-model:md-active="editMenuOpen" :md-offset-x="editMenuOpenXOffset" :md-offset-y="-4">
                            <div class="ebx-icon-button ebx-icon-button__no-shadow"  @click="toggleEditMenu"><span class="material-icons-outlined ebx-icon">more_vert</span></div>
                            <md-menu-content class="ebx-options-menu">
                                <md-menu-item @click="handleEditArea">Edit shapes</md-menu-item>
                                <md-menu-item @click="handleRemoveArea">Remove</md-menu-item>
                                <md-menu-item @click="exportPolygons">Download</md-menu-item>
                            </md-menu-content>
                        </md-menu>
                        <button v-else @click="handleRemoveArea" class="ebx-icon-button ebx-icon-button__no-shadow"><span class="material-icons-outlined ebx-icon">delete</span></button>
                    </div>
                </div>
            </div>
            <md-menu v-else md-size="small" md-align-trigger v-model:md-active="areaMenuOpen" :md-offset-x="areaMenuOpenXOffset" :md-offset-y="-10">
                <button :disabled="areWarnings || disabled" class="ebx-button ebx-button--secondary ebx-button--secondary__highlight" @click="toggleAreaMenu">
                    Select area
                </button>
                <md-menu-content class="ebx-options-menu">
                    <md-menu-item class="add-area-menu--option" :disabled="areWarnings" @click="handleDrawArea">
                        <span class="material-icons-outlined ebx-icon">edit</span>
                        <span>Draw area</span>
                    </md-menu-item>
                    <md-menu-item class="add-area-menu--option" :disabled="areWarnings" @click="handleChooseArea">
                        <span class="material-icons-outlined ebx-icon">folder</span>
                        <span>Choose from assets</span>
                    </md-menu-item>
                    <md-menu-item class="add-area-menu--option" :disabled="areWarnings" @click="handleUploadArea">
                        <span class="material-icons-outlined ebx-icon">file_upload</span>
                        <span>Upload area</span>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>

            <button 
                v-if="!selectedArea" 
                @click="handleSelectDefaultArea"
                :disabled="areWarnings"
                class="ebx-action-text ebx-action-text--primary"
                ><p>Use example area</p></button>

        </VariableDetailExplorer>
    </div>
</template>

<script>
import VariableDetailExplorer from '@/components/Variables/VariableDetail/VariableDetailExplorer.vue'
export default {
    name: 'AreaVariableExplorer',
    components: {
        VariableDetailExplorer
    },
    props: {
        variable: {
            type: Object,
            required: true
        },
        selectedArea: {
            type: Object,
            required: false,
            default: null
        },
        hasValue: {
            type: Boolean,
            required: false,
            default: false
        },
        areWarnings : { 
            required: false, 
            default: false
        },
        isDrawn: {
            type: Boolean,
            default: false,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
    },
    emits: [
        'choose-from-assets',
        'remove-area',
        'select-default-area',
        'draw-area',
        'edit-drawn-area',
        'upload-area',
        'export-polygons'
    ],
    data() {
        return {
            areaMenuOpen: false,
            areaMenuOpenXOffset: 0,
            editMenuOpen: false,
            editMenuOpenXOffset: 0
        }
    },
    methods: {
        handleChooseArea() {
            this.$emit('choose-from-assets');
        },
        handleRemoveArea() {
            this.$emit('remove-area', true);
        },
        handleSelectDefaultArea() {
            this.$emit('select-default-area');
        },
        handleDrawArea() {
            this.$emit('draw-area');
        },
        handleEditArea() {
            this.$emit('edit-drawn-area');
        },
        handleUploadArea(){
            this.$emit('upload-area');
        },
        exportPolygons(){
            this.$emit('export-polygons', this.selectedArea);
        },
        toggleAreaMenu(evnt) {
            if(this.disabled) {
                return;
            }
            this.areaMenuOpen = !this.areaMenuOpen;
            this.areaMenuOpenXOffset = Math.max(0,evnt.target.clientWidth - 34)
        },
        toggleEditMenu(evnt) {
            if(this.disabled) {
                return;
            }
            this.editMenuOpen = !this.editMenuOpen;
            this.editMenuOpenXOffset = Math.max(0,evnt.target.clientWidth - 34)
        }
    }
    
}
</script>