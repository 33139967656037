<template>
    <div>
        <md-dialog class="blockly-modals" v-model:md-active="computedModalShown">
            <form>
                
                <md-field v-if="isBandUnknown && hasDefaultPalette">
                    <label for="palette_options">Style Type</label>
                    <md-select name="palette_options" v-model="formData.chosenStyleType">
                        <md-option class="palette-element--container" v-for="option in style_options" :value="option[1]" :key="option[1]">
                         {{option[0]}}
                        </md-option>
                    </md-select>
                </md-field>

                <template v-if="isBandNumeric">
                    <div class="md-layout">
                    <div class="md-layout-item">
                            <md-field>
                                <label for="palette_options">Choose Colour Ramp</label>
                                    <md-select name="palette_options" v-model="formData.rampValue">
                                        <md-option class="palette-element--container" v-for="option in palette_options" :value="option[1]" :key="option[1]">
                                            {{option[0]}}
                                            <img 
                                                class="palette-element--ramp" 
                                                :src="getPaletteImage(option[1].split('.').at(-1))"
                                                :alt="`The palette visualisation image for ${option[1].split('.').at(-1)}`"/>
                                        </md-option>
                                    </md-select>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-layout-item--invert">
                            <md-switch v-model="formData.invertRamp">Invert</md-switch>
                        </div>
                    </div>
                    
                    <md-field>
                        <label for="channel_options">Contrast Options</label>
                        <md-select name="channel_options" v-model="formData.contrastValue">
                            <md-option v-for="option in contrast_options" :value="option[1]" :key="option[1]">
                                {{ option[0] }}
                            </md-option>
                        </md-select>
                    </md-field>
                    <md-field v-if="formData.contrastValue === 'custom'">
                        <label for="min">Min</label>
                        <md-input name="min" v-model="formData.min" type="number" :placeholder="`${min}`"></md-input>
                    </md-field>
                    <md-field v-if="formData.contrastValue === 'custom'">
                        <label for="max">Max</label>
                        <md-input name="max" v-model="formData.max" type="number" :placeholder="`${max}`"></md-input>
                    </md-field>
                </template>
                <template v-if="isBandThematic">
                    <div class="md-layout">
                        <div class="md-layout-item">

                        <md-field>
                            <label for="palette_settings">Options</label>
                            <md-select name="palette_settings" v-model="formData.custom_palette_option">
                                <md-option class="palette-element--container" v-for="option in defaultPaletteOptions" :value="option[1]" :key="option[1]">
                                    {{option[0]}}
                                    <img 
                                        v-if="['predefined','random'].indexOf(option[1]) < 0"
                                        class="palette-element--ramp" 
                                        :src="getPaletteImage(option[1].split('.').at(-1))"
                                        :alt="`The palette visualisation image for ${option[1].split('.').at(-1)}`"/>
                                </md-option>
                            </md-select>
                        </md-field>
                        </div>
                        <div class="md-layout-item md-layout-item--invert">
                            <md-button @click="updateColorValues">Classify</md-button>
                        </div>
                    </div>
                    <md-table>
                        <md-table-row>
                            <md-table-head>Value</md-table-head>
                            <md-table-head>Description</md-table-head>
                            <md-table-head>Colour</md-table-head>
                        </md-table-row>
                        <template v-for="palette in default_palette_as_array" :key="palette.value">
                            <md-table-row v-if="formData.custom_palette && formData.custom_palette[palette.value]" class="md-table--map-layer-modal">
                                <md-table-cell>
                                    {{ palette.value }}
                                </md-table-cell>
                                <md-table-cell>
                                    {{ palette['ebx:short_description'] || palette.description }}
                                </md-table-cell>
                                <md-table-cell>
                                    <EbxColorPicker :modelValue="'#' + formData.custom_palette[palette.value]" @update:modelValue="updatePaletteColour(palette.value, $event)" :include-hash="false" theme="wide"/>
                                </md-table-cell>
                            </md-table-row>
                        </template>
                    </md-table>
                </template>
                <div class="single-channel--description">
                    <h3 class="ebx-header-4">Description</h3>
                    <md-field :class="invalidClass">
                        <label hidden>Description</label>
                        <md-textarea v-model="formData.layerDescription" placeholder="Add a description" md-autogrow :md-counter="maxDescriptionLength"></md-textarea>
                        <span class="md-error">{{descriptionErrorMessage}}</span>
                        <span class="md-helper-text">Descriptions show alongside the map layer.</span>
                    </md-field>
                </div>       
            </form>

            <md-dialog-actions>
                <md-button class="md-primary" @click="computedModalShown = false">Close</md-button>
                <md-button class="md-primary" @click="saveModal">Save</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import { PaletteService } from '@/services/palette.service';
import {VIS_STYLE_OPTIONS,VIS_CHANNEL_OPTIONS,  } from '@/constants/nextGenConstants';
import chroma from 'chroma-js'
import { globalEventBus } from '@/eventbus.js'
import EbxColorPicker from '../components/EbxComponents/EbxColorPicker.vue';
import blocklyModalsMixin from './blocklyModalsMixin.js'

export default {
    name: 'SingleChannelMapLayer',
    mixins: [blocklyModalsMixin],
    components: {
        EbxColorPicker
    },
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        min: {
            type: Number,
            default: null
        },
        max: {
            type: Number,
            default: null
        },
        contrastValue: {
            type: String,
            default: ''
        },
        rampValue: {
            type: String,
            default: ""
        },
        invertRamp: {
            type: Boolean,
            default: false
        },
        custom_palette: {
            type: Array,
            default: () => []
        },
        custom_palette_option: {
            type: String,
            default: 'predefined'
        },
        chosenStyleType: {
            type: String,
            default: ''
        },
        layerDescription: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            default_palette: null,
            palette_options: [],
            style_options: VIS_STYLE_OPTIONS,
            contrast_options: VIS_CHANNEL_OPTIONS,
            selectedBand: ['unknown','unknown'],
            invalidClass: '',
            invalidDescription: false,
            maxDescriptionLength: 500, 
            descriptionErrorMessage: '',
            formData: {
                min: this.min,
                max: this.max,
                contrastValue: this.contrastValue,
                rampValue: this.rampValue,
                invertRamp: this.invertRamp,
                custom_palette: this.custom_palette,
                custom_palette_option: this.custom_palette_option,
                chosenStyleType: this.chosenStyleType, 
                layerDescription: this.layerDescription
            }
        }
    },
    computed: {
        /**
         * Return a copy of the default palette, with extra "value" field reflecting the palette key.
         */
        default_palette_as_array() {
            if(this.default_palette) {
                const keys = Object.keys(this.default_palette)
                return keys.map(key => {
                    const option = Object.assign({}, this.default_palette[key])
                    option.value = key
                    return option
                })
            }
            return null
        },
        hasKnownBandDataType() {
            return ['thematic','numeric'].indexOf(this.selectedBand[0]) >=0
        },
        isBandNumeric() {
            if(this.hasDefaultPalette === false) {
                    return true
                }
            if(this.isBandUnknown) {
                return this.formData.chosenStyleType === 'numeric'
            }
            return this.selectedBand[0] === 'numeric'
        },
        isBandThematic() {
            if(this.hasDefaultPalette === false) {
                return false
            }
            if(this.isBandUnknown) {
                return this.formData.chosenStyleType === 'thematic'
            }
            return this.selectedBand[0] === 'thematic'
        },
        isBandUnknown() {
            return this.selectedBand[0] === 'unknown'
        },
        hasDefaultPalette() {
            return this.default_palette_as_array !== null
        },
        hasDefaultPaletteColours() {
            return this.defaultColourKey !== null
        },
        defaultPaletteOptions() {
            const options = []
            if(this.hasDefaultPaletteColours) {
                options.push(['Pre-defined','predefined'])
            }
            options.push(['Random','random'])
            this.palette_options.forEach(paletteOption => options.push(paletteOption))
            return options
        },
        layerDescriptionFormData() {
            return this.formData.layerDescription
        }
    },
    methods: {
        getPaletteImage(palette) {
            return PaletteService.getExampleImages()[palette]
        },
        updatePaletteColour(value, color) {
            if(color.substring(0,1) === '#') {
                color = color.substring(1)
            }
            this.formData.custom_palette[value] = color
        },
        getFieldValue() {
            return {
                rampValue: this.formData.rampValue,
                invertRamp: this.formData.invertRamp,
                contrastValue: this.formData.contrastValue,
                min: this.formData.min,
                max: this.formData.max,
                custom_palette: this.formData.custom_palette,
                custom_palette_option: this.formData.custom_palette_option,
                chosenStyleType: this.formData.chosenStyleType, 
                layerDescription: this.formData.layerDescription
            };
        },
        saveModal() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        },
        updateColorValues() {
            if(this.formData.custom_palette === null) {
                return;
            }
            const paletteKeys = Object.keys(this.formData.custom_palette)
            let paletteValues;
            switch(this.formData.custom_palette_option) {
                case 'random':
                    this.formData.custom_palette = paletteKeys.reduce((c, k) => {
                        const currentValues = Object.values(c)
                        let safeCounter = 0
                        c[k] = chroma.random().hex().substring(1)
                        while(currentValues.indexOf(c[k]) >= 0 && safeCounter < 1000) {
                            c[k] = chroma.random().hex().substring(1)
                            safeCounter++;
                        }
                        return c
                    },{})
                    break;
                case 'predefined':
                    this.formData.custom_palette = this.default_palette_as_array.reduce((c, p) => {
                        c[p.value] = p.color
                        return c
                    },{})  
                    break;
                default:
                    paletteValues = PaletteService.getColourRampFromPaletteForNumberOfItems(this.formData.custom_palette_option, paletteKeys.length)    
                    this.formData.custom_palette = paletteKeys.reduce((c, k) => {
                        c[k] = paletteValues[Object.keys(c).length].substring(1)
                        return c;
                    }, {})
                    break;
            }
        },
        constructSingleChannelCustomPalette() {
            if(Array.isArray(this.default_palette_as_array)) {
                return this.default_palette_as_array
                    .reduce((c,p) => {
                        c[p.value] = p.color
                        return c
                    },{})
            }
            return null;
        },
        setDefaultRampValue() {
            if(Array.isArray(this.palette_options) && this.palette_options.length > 0 && this.formData.rampValue === '') {
                console.log(`Setting default ramp value from ${this.formData.rampValue} to ${this.palette_options[0][1]}`)
                this.formData.rampValue = this.palette_options[0][1]
            }
        },
        validateDescription(layerDescription) {
            if (layerDescription.length > this.maxDescriptionLength) {
                this.invalidDescription = true
                this.invalidClass = 'md-invalid'
                this.descriptionErrorMessage = `Description must be less than ${this.maxDescriptionLength} characters'`
                return false
            }
            // if (nextGenConstants.ESCAPE_SEQUENCE_REGEX.test(description)) {
            //     this.invalidDescription = true
            //     this.invalidClass = 'md-invalid'
            //     this.descriptionErrorMessage = 'Description cannot contain escape sequences'
            //     return false
            // }
            this.invalidDescription = false
            this.invalidClass = ''
            return true
        },
    },
    watch: {
        showModal(val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if(val) {
                this.formData = {
                    min: this.min,
                    max: this.max,
                    contrastValue: this.contrastValue,
                    rampValue: this.rampValue,
                    invertRamp: this.invertRamp,
                    custom_palette: this.custom_palette,
                    custom_palette_option: this.custom_palette_option,
                    chosenStyleType: this.chosenStyleType, 
                    layerDescription: this.layerDescription
                }
            }
        },
        selectedBand: {
            handler: function() {
                if(this.showModal) {
                    this.formData.custom_palette = this.constructSingleChannelCustomPalette()
                    this.updateColorValues()
                }   
            },
            deep: true 
        },
        layerDescriptionFormData: {
            handler: function (val) {
                this.validateDescription(val)
                return val
            },
            deep: true
        },
    }
}
</script>
<style lang="scss" scoped>
    .palette-element {
        &--container{
            display: flex;
            flex-direction: column;
            height: 60px;
            min-width: 200px;
        }
        &--ramp {
            height: 20px;
            width: 100%
        }
    }
    .md-layout-item--invert{
        display: flex; 
        align-items: center;
        justify-content: flex-end;
        margin-left: 15px;
    }
    .single-channel { 
        &--description {
            .md-field {
                padding-top: 0;
                min-height: auto;
        }
        }
    }

</style>