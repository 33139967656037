<template>
    <div>
        <ProductUpdateModal 
            :modelValue="productUpdate.show"
            :dialog-content="productUpdate.template"
            @dismiss-product-update="handleProductUpdateDismiss"
            @update:modelValue="handleProduceUpdateShown"
        />
        <ConfirmationModal 
            ref="confirmation" 
            :title="confirmMessage.title" 
            :hide-title="confirmMessage.hideTitle"
            :if-warning="confirmMessage.ifWarning" 
            :cancelButtonText="confirmMessage.cancelButtonText" 
            :okButtonText="confirmMessage.okButtonText"
            :okButtonClass="confirmMessage.okButtonClass"
            :cancelButtonClass="confirmMessage.cancelButtonClass"   
            :showOkButton="confirmMessage.showOkButton"
            :showCancelButton="confirmMessage.showCancelButton"
            :hideCloseButton="confirmMessage.hideCloseButton"
            :closeOnEscape="confirmMessage.closeOnEscape"
            :closeOnClickOutside="confirmMessage.closeOnClickOutside"
            :closeButtonAsAction="confirmMessage.closeButtonAsAction"
        />
        <APIConfiguration 
            ref="APIConfiguration"
            :apiProjectId="APIConfig.projectId"
            :apiProject="APIConfig.project"
            v-model="APIConfig.showModal"
        />

        <md-snackbar :md-position="'center'" :md-duration="snackbar.duration" v-model:md-active="snackbar.visible" md-persistent
            @md-closed="handleSnackBarClosing">
            <span>{{ snackbar.message }}</span>
            <md-button v-if="snackbar.showUndo" class="md-raised md-accent" @click="handleUndoAction">
                {{ undoButtonText }}
            </md-button>
        </md-snackbar>
    </div>
</template>
<script>
import ConfirmationModal from './ConfirmationModal.vue';
import ProductUpdateModal from './ProductUpdateModal.vue'
import APIConfiguration from './APIConfiguration.vue';
import { globalEventBus } from '../eventbus';

export default {
    props: {
        productUpdate: {
            type: Object,
            default: function(){
                return {show: false, template: null}
            }
        }
    },
    emits: [
        'dismiss-product-update'
    ],
    data() {
        return {
            snackbar: {
                visible: false,
                message: '',
                showUndo: false,
                undoAction: null,
                duration: 10000
            },
            undoButtonText: 'Undo'
        }
    },
    components: {
        ConfirmationModal,
        ProductUpdateModal, 
        APIConfiguration
    },
    computed: {
        confirmMessage() {
            return this.$store.state.global.confirmation;
        },
        APIConfig() { 
            return this.$store.state.global.APIConfiguration;
        }
    },
    watch: {
        async confirmMessage(confirm) {
            if (confirm.shown) {
                const answer = await this.$refs.confirmation.confirm(confirm.message);
                this.$store.commit('global/setConfirmationDetails', {
                    shown: false
                });
                confirm.callback.resolve(answer);
            }
        },

    },
    created() {
        this.checkForServiceWorkerUpdates();
        globalEventBus.$on('swUpdated', this.checkForServiceWorkerUpdates)
    },
    beforeUnmount() {
        globalEventBus.$off('swUpdated', this.checkForServiceWorkerUpdates)
    },
    methods: {
        handleProduceUpdateShown(value) {
            this.$emit('show-product-update', value);
        },
        handleProductUpdateDismiss() {
            this.$emit('dismiss-product-update', 'dismiss');
        },
        checkForServiceWorkerUpdates() {
            if (window.localStorage.getItem('swUpdated') === 'updated') {
                this.snackbar.message = `A new version of Earth Blox is available`;
                this.snackbar.showUndo = true
                this.undoButtonText = 'Refresh'
                this.snackbar.undoAction = () => {
                    window.localStorage.removeItem('swUpdated')
                    window.location = '/'
                    this.undoButtonText = 'Undo'
                }
                this.snackbar.visible = true;
            }
        },
        handleSnackBarClosing() {
            this.snackbar.undoAction = null
            this.snackbar.showUndo = false
        },
        handleUndoAction() {
            if (typeof this.snackbar.undoAction === 'function') {
                this.snackbar.undoAction()
                this.snackbar.undoAction = null
            }
        }
    }
}
</script>
