/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

// Constants for next gen blocks (v2.0)
export const DEFAULT_ORG_ID = 'ofpPZMliszP5trXVmQeV';
export const INDIVIDUAL_ORGS = ['ofpPZMliszP5trXVmQeV', 'Professional_and_Education_Self_Service'];
export const NEXT_GEN_BLOCKS = [
    'workflow_insert_dataset', 
    'workflow_compare',
    'workflow_empty_container',
    'workflow_classify_image',
    'workflow_unsupervised_classification',
    'analysis_calculator',
    'analysis_detect_change',
    'analysis_indices',
    'analysis_zonal_statistics',
    'analysis_focal_analysis',
    'analysis_thematic_processing',
    'analysis_buffer',
    'analysis_vector_attribute',
    'analysis_geoprocessing',
    'analysis_convert_features',
    'input_dataset',
    'modifier_attribute',
    'modifier_filter_attributes',
    'modifier_remove_attributes',
    'analysis_regression',
    'modifier_bands',
    'modifier_remove_bands',
    'modifier_cloud_mask', 
    'modifier_composite',
    'modifier_mask', 
    'modifier_unmask',
    'modifier_orbit',
    'modifier_date_range', 
    'modifier_time_period', 
    'output_map_layer', 
    'output_add_table', 
    'output_save_dataset',
    'study_area', 
    'output_multitemporal',
    'output_export',
    'modifier_reassign_classes',
    'analysis_convert_images'
];
export const NEXT_GEN_TOP_BLOCKS = [
    'workflow_insert_dataset', 
    'workflow_compare',
    'workflow_empty_container',
    'workflow_classify_image',
    'workflow_unsupervised_classification',
    'output_add_table'
];
export const NO_DATA_DROPDOWN_VALUE = "nodata";
export const BOOLEAN_DROPDOWN_OPTIONS = [['True',1], ['False',1]];
export const GEE_SCHEMA_ATTR_TYPES = {
    STRING: 'STRING',
    DOUBLE: 'DOUBLE',
    INT: 'INT',
    FLOAT: 'FLOAT',
};
export const WORKFLOW_SAVED_TYPES = {
    XML: 'xml',
    JSON: 'json',
    UNKNOWN: 'unknown'
};
export const VIS_PALETTE_DROPDOWN = [
    [
        "Greys",
        "colorbrewer.Greys"
    ],
    [
        "Spectral",
        "colorbrewer.Spectral"
    ],
    [
        "Blues",
        "colorbrewer.Blues"
    ],
    [
        "Greens",
        "colorbrewer.Greens"
    ],
    [
        "Reds",
        "colorbrewer.Reds"
    ],
    [
        "Orange-Red",
        "colorbrewer.OrRd"
    ],
    [
        "Pink-Green Diverging",
        "colorbrewer.PiYG"
    ],
    [
        "Blue-Green",
        "colorbrewer.BuGn"
    ],
    [
        "Red-Blue Diverging",
        "colorbrewer.RdBu"
    ],
    [
        "Red-Purple",
        "colorbrewer.RdPu"
    ],
    [
        "Red-Yellow-Green Diverging",
        "colorbrewer.RdYlGn"
    ],
    [
        "Speed",
        "cmocean.Speed"
    ],
    [
        "Delta",
        "cmocean.Delta"
    ],
    [
        "Ice",
        "cmocean.Ice"
    ],
    [
        "Burn",
        "crameri.lajolla"
    ],
    [
        "Batlow",
        "crameri.batlow"
    ],
    [
        "Roma",
        "crameri.roma"
    ],
    [
        "Cividis",
        "misc.cividis"
    ],
    [
        "Cool-Warm",
        "misc.coolwarm"
    ],
    [
        "Viridis", 
        "matplotlib.viridis"
    ],
    [
        "Yellow-Blue", 
        "colorbrewer.YlGnBu"
    ],
    [
        "Brown-Blue Diverging", 
        "colorbrewer.BrBG"
    ],
    [
        "Red-Yellow-Blue Diverging", 
        "colorbrewer.RdYlBu"
    ],
    [
        "Magma", 
        "matplotlib.magma"
    ],
    [
        "Kry",
        "kovesi.linear_kry_5_95_c72"
    ],
    [
        "Elevation", 
        "kovesi.linear_gow_65_90_c35"
    ],
    [
        "Jet",
        "misc.jet"
    ]
];
export const VIS_CHANNEL_OPTIONS = [ 
    [
        'Default Dataset Visualisation',
        'default'
    ],
    [
        'Dataset Min and Max',
        'dataset-min-max'
    ],
    [
        'Mean +/- 1 Standard Deviation',
        'mean-1-std'
    ],
    [
        'Mean +/- 2 Standard Deviations',
        'mean-2-std'
    ],
    [
        '90th percentile',
        'p90'
    ],
    [
        '98th percentile',
        'p98'
    ],
    [
        'Custom',
        'custom'
    ]
];
export const NUMERIC = "numeric";
export const THEMATIC = "thematic";
export const EBX_THEMATIC_CLASSES = "gee:classes";
export const EBX_BAND_NAME = "ebx:name";
export const EBX_BAND_TYPE = "ebx:datatype";
export const VIS_STYLE_OPTIONS = [
    ['Colour Ramp', NUMERIC],
    ['Unique Values', THEMATIC]
];
export const MAP_STUDY_AREA_COLLECTION_ID = 'STUDY_AREA';
export const MAP_AREA_DEFAULT_KEY = '__default__';
export const MAP_AREA_DEFAULT_SPLIT_PROPERTY = '_none_';
export const MONTHS = ['January','February','March','April','May','June','July','August','September','October','November','December'];
export const ASSET_NO_SPLIT_VALUE = '_none_';
export const SAVED_WORKFLOW_ID_SEPERATOR = ' - ';
export const LAZYLOAD_MAP_TILE_IMAGES = true;
export const LAZYLOAD_MAP_TILE_PRELOAD_NEXT = true;
export const LAZYLOAD_MAP_TILE_PRELOAD_MODE = 'sequential';
export const LAZYLOAD_MAP_TILE_PRELOAD_OFFSET = 1;
export const MAP_TILE_PLACEHOLDER_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
export const CALCULATOR_EXPRESSION_STATE = {
    CHECK: 'check',
    VALID: 'valid',
    INVALID: 'invalid'
};
export const SHOW_PROJECT_ON_START = true;
export const DEFAULT_LAYER_NAME = 'Layer 1';
export const UPDATE_DEFAULT_LAYER_NAME = true;
export const MAP_LAYER_TYPE = {
    RESULT: 'result',
    ASSET: 'asset',
    BASEMAP: 'basemap'
};
export const DEFAULT_FEATURE_COLOURS = [
    {
        value: '#061854',
        label: 'Midnight Blue'
    },
    {
        value: '#431B61',
        label: 'Midnight Purple'
    },
    {
        value: '#801D6D',
        label: 'Disco Purple'
    },
    {
        value: '#EE7AA3',
        label: 'Rose Pink'
    },
    {
        value: '#DD544F',
        label: 'Blood Orange'
    },
    {
        value: '#EE7944',
        label: 'Sunset Orange'
    },
    {
        value: '#F5EC5F',
        label: 'Lemon Yellow'
    },
    {
        value: '#DAE270',
        label: 'Lime Green'
    },
    {
        value: '#98D86B',
        label: 'Leaf Green'
    },
    {
        value: '#429464',
        label: 'Forest Green'
    },
    {
        value: '#55BBAF',
        label: 'Ocean Blue'
    },
    {
        value: '#69B6F9',
        label: 'Sky Blue'
    }
];
export const VALID_BANDNAME_REGEX = /^[a-zA-Z0-9_ -]+$/;
export const INVALID_BANDNAME_ERROR = 'Name can only contain letters, numbers, underscores and dashes';
export const VALID_PROPNAME_REGEX = /^[a-zA-Z0-9_-\s]+$/;
export const VALID_EXPORT_DESCRIPTON_REGEX = /^[a-zA-Z0-9_-\s.;_,:]{1,100}$/;
export const INVALID_EXPORT_DESCRIPTON_MESSAGE = 'Description can only contain letters, numbers, underscores, dashes, spaces, periods, semicolons, commas and colons and be less than 100 characters';
export const BLOCKLY_JSON_EXPORT_IGNORE_KEYS = ['extraState', 'gee:classes','x','y','id'];
export const TABLE_DATE_CONSTANTS = [
    {
        name: 'All Dates',
        value: 'all'
    },
    {
        name: 'First',
        value: 'first',
        allow: ['last']
    },
    {
        name: 'Last',
        value: 'last',
        allow: ['first']
    },
    {
        name: 'All except first',
        value: 'all-except-first'
    },
    {
        name: 'All except last',
        value: 'all-except-last'
    },
];
export const NON_BLOCKING_BLOCKLY_WARNING_PREFIX = "warning__";
export const VARIABLE_NEW_PREFIX = 'new_var';
export const VARIABLE_PREFIX = 'var_';
export const VARIABLE_BLOCKLY_ERROR_PREFIX = "variable_error_";
export const VARIABLE_BLOCKLY_WARNING_PREFIX = "variable_warning_";
export const VARIABLE_PROVIDERS = {
    PROJECT: {
        id: 'project',
        title: 'Project Variables',
    }
};
export const EMPTY_VARIABLES = ['', null, undefined, 'Invalid date'];