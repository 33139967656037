<template>
  <md-dialog
    v-model:md-active="isDialogVisible"
    :md-click-outside-to-close="false"
    @md-opened="dialogOpened"
  >
    <md-dialog-title>Change Email</md-dialog-title>
    <form id="this.form_id" @submit.prevent="save">
      <md-dialog-content>
        <md-field :class="getValidationClass('currentPassword')">
          <label>Current Password</label>
          <md-input v-model="model.currentPassword" ref="currentPassword" type="password" />
          <span class="md-error" v-if="v$.model.currentPassword.required.$invalid">Current password is required</span>          
        </md-field>

        <md-field :class="getValidationClass('newEmail')">
          <label>New Email</label>
          <md-input v-model="model.newEmail" type="email" />
          <span class="md-error" v-if="v$.model.newEmail.required.$invalid">New email is required</span>
          <span
            class="md-error"
            v-else-if="v$.model.newEmail.email.$invalid"
          >New email must be a valid email address.</span>
        </md-field>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="isDialogVisible = false">Close</md-button>
        <md-button type="submit" class="md-primary">Save</md-button>
      </md-dialog-actions>
    </form>
  </md-dialog>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  name: "MyAccountChangeEmail",
  setup: () => ({ v$: useVuelidate() }),
  data: () => ({
    model: {},
    isDialogVisible: false
  }),
  emits: [
    "save"
  ],
  validations: {
    model: {
      currentPassword: {
        required
      },
      newEmail: {
        required,
        email
      }
    }
  },
  methods: {
    showDialog() {
      this.isDialogVisible = true;
    },
    dialogOpened() {
      this.model = {};

      this.v$.$reset();

      setTimeout(() => {
        this.$refs.currentPassword.$el.focus();
      }, 200);
    },
    getValidationClass(fieldName) {
      const field = this.v$.model[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    save() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.$emit("save", this.model);

      this.isDialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
form {
  min-width: 360px;
}

.md-dialog-title {
  margin-bottom: 0;
}
</style>
