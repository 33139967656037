<template>
    <div ref="StyleguideTypography">
        <!-- TYPOGRAPHY -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Header 1</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_header_1)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <p class="ebx-header-1">ebx-header-1</p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showHeader1 = !showHeader1">{{showHeader1 ? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showHeader1">
                <p class="ebx-primary ebx-primary text-primary-invert">{{styleguideConstants.ebx_header_1}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_header_1)">content_copy</span>
                </button>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Header 2</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_header_2)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <p class="ebx-header-2">ebx-header-2</p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showHeader2 = !showHeader2">{{showHeader2 ? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showHeader2">
                <p class="ebx-primary ebx-primary text-primary-invert">{{styleguideConstants.ebx_header_2}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_header_2)">content_copy</span>
                </button>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Header 3</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_header_3)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <p class="ebx-header-3">ebx-header-3</p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showHeader3 = !showHeader3">{{showHeader3 ? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showHeader3">
                <p class="ebx-primary ebx-primary text-primary-invert">{{styleguideConstants.ebx_header_3}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_header_3)">content_copy</span>
                </button>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Header 4</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_header_4)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <p class="ebx-header-4">ebx-header-4</p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showHeader4 = !showHeader4">{{showHeader4 ? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showHeader4">
                <p class="ebx-primary ebx-primary text-primary-invert">{{styleguideConstants.ebx_header_4}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_header_4)">content_copy</span>
                </button>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Primary</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_primary)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <p class="ebx-primary">ebx-primary</p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showEbxPrimary = !showEbxPrimary">{{showEbxPrimary ? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showEbxPrimary">
                <p class="ebx-primary ebx-primary text-primary-invert">{{styleguideConstants.ebx_primary}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_primary)">content_copy</span>
                </button>
            </div>
            
            <div class="styleguide--component-header">
                <h3>Ebx Primary Text Invert</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_primary_text_invert)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body styleguide--component-body__dark">
                <p class="ebx-primary text-primary-invert">ebx-primary text-primary-invert</p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showEbxPrimaryTextInvert = !showEbxPrimaryTextInvert">{{showEbxPrimaryTextInvert ? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showEbxPrimaryTextInvert">
                <p class="ebx-primary text-primary-invert">{{styleguideConstants.ebx_primary_text_invert}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_primary_text_invert)">content_copy</span>
                </button>
            </div>

            <div class="styleguide--component-header">
                <h3>Ebx Primary Text Disabled</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_primary_text_disabled)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <p class="ebx-primary text-disabled">ebx-primary text-disabled</p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showEbxPrimaryTextDisabled = !showEbxPrimaryTextDisabled">{{showEbxPrimaryTextDisabled ? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showEbxPrimaryTextDisabled">
                <p class="ebx-primary text-primary-invert">{{styleguideConstants.ebx_primary_text_disabled}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_primary_text_disabled)">content_copy</span>
                </button>
            </div>

            <div class="styleguide--component-header">
                <h3>Ebx Body (strong tag)</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_body_strong)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <p class="ebx-primary">ebx-body <strong>strong</strong></p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showEbxBodyStrong = !showEbxBodyStrong">{{showEbxBodyStrong ? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showEbxBodyStrong">
                <p class="ebx-primary text-primary-invert">{{styleguideConstants.ebx_body_strong}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_body_strong)">content_copy</span>
                </button>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Primary Bold</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_primary_bold)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <p class="ebx-primary-bold">ebx-primary-bold</p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showEbxPrimaryBold = !showEbxPrimaryBold">{{showEbxPrimaryBold ? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showEbxPrimaryBold">
                <p class="ebx-primary text-primary-invert">{{styleguideConstants.ebx_primary_bold}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_primary_bold)">content_copy</span>
                </button>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Secondary</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_body_caption)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <p class="ebx-secondary">ebx-secondary</p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showEbxSecondary = !showEbxSecondary">{{showEbxSecondary ? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showEbxSecondary">
                <p class="ebx-primary text-primary-invert">{{styleguideConstants.ebx_body_caption}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_body_caption)">content_copy</span>
                </button>
            </div>

            <div class="styleguide--component-header">
                <h3>Ebx Secondary Text Invert</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_secondary_text_invert)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body styleguide--component-body__dark">
                <p class="ebx-secondary text-primary-invert">ebx-secondary text-primary-invert</p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showEbxPrimaryTextInvert = !showEbxPrimaryTextInvert">{{showEbxPrimaryTextInvert ? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showEbxPrimaryTextInvert">
                <p class="ebx-primary text-primary-invert">{{styleguideConstants.ebx_secondary_text_invert}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_secondary_text_invert)">content_copy</span>
                </button>
            </div>

            <div class="styleguide--component-header">
                <h3>Ebx Secondary Text Negative</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_secondary_text_negative)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <p class="ebx-secondary text-negative">ebx-secondary text-negative</p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showEbxSecondaryTextNegative = !showEbxSecondaryTextNegative">{{showEbxSecondaryTextNegative ? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showEbxSecondaryTextNegative">
                <p class="ebx-primary text-primary-invert">{{styleguideConstants.ebx_secondary_text_negative}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_secondary_text_negative)">content_copy</span>
                </button>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Tertiary</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_tertiary)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <p class="ebx-tertiary">ebx-tertiary</p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showEbxTertiary = !showEbTertiary">{{showEbxTertiary? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showEbxTertiary">
                <p class="ebx-primary text-primary-invert">{{styleguideConstants.ebx_tertiary}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_tertiary)">content_copy</span>
                </button>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Action</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_action)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <p class="ebx-action">ebx-action</p>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showEbxAction = !showEbxAction">{{showEbxTertiary? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showEbxAction">
                <p class="ebx-primary text-primary-invert">{{styleguideConstants.ebx_action}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_action)">content_copy</span>
                </button>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Ul</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_ul)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <p class="ebx-primary">The below is an ebx-ul list</p>
                <ul class="ebx-ul">
                    <li class="ebx-primary">ebx-ul element</li>
                    <li class="ebx-primary">ebx-ul el</li>
                    <li class="ebx-primary">ebx-ul</li>
                </ul>
            </div>
            <button class="ebx-button ebx-button--secondary" @click="showEbxUl = !showEbxUl">{{showEbxUl ? 'Hide Code' : 'Show Code'}}</button>
            <div class="styleguide--component-body styleguide--component-body__dark styleguide--component-body__action" v-if="showEbxUl">
                <p class="ebx-primary text-primary-invert">{{styleguideConstants.ebx_ul}}</p>
                <button class="ebx-icon-button ebx-icon-button__dark">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_ul)">content_copy</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { styleguideConstants } from '@/constants/styleguideConstants.js'

export default {
    name: 'StyleguideTypography',
    data() {
        return {
            styleguideConstants: styleguideConstants,
            showHeadingLarge: false,
            showHeader1: false,
            showEbxTitle: false,
            showHeader2: false,
            showHeader3: false,
            showHeader4: false,
            showEbxPrimary: false,
            showEbxPrimaryTextInvert: false,
            showEbxPrimaryTextDisabled: false,
            showEbxBodyStrong: false,
            showEbxPrimaryBold: false,
            showEbxSecondary: false,
            showEbxSecondaryTextNegative: false,
            showEbxTertiary: false,
            showEbxAction: false,
            showEbxUl: false,
        }
    },
}
</script>