import { sharedCollection, organisationsCollection } from '@/firebase.js';
import { PROJECT_MODE } from '@/components/mixins/projectMixin';
import assets from "@/assets.js"

const state = {
    globalPackages: null,
    globalProjects: null,
    teamProjects: null,
    gettingStartedPackages: null,
    globalProjectsTags: null,
    subscriptions: {
        globalProjects: null,
        teamProjects: null
    }
}

const getters = {
    getPackageById: (state) => (id) => {
        if (state.globalPackages) {
            return state.globalPackages.find(pkg => pkg.id === id);
        } else {
            return null;
        }
    },
}

const mutations = {
    setGlobalPackages(state, packages) {
        state.globalPackages = packages;
    },
    setGlobalProjects(state, projects) {
        state.globalProjects = projects;
    },
    setTeamProjects(state, projects) {
        state.teamProjects = projects;
    },
    setGettingStartedPackages(state, packages) {
        state.gettingStartedPackages = packages;
    }, 
    setGlobalProjectsTags(state, tags) {
        state.globalProjectsTags = tags
    },
    setSubscription(state, { key, value }) {
        state.subscriptions[key] = value;
    }
}

const actions = {
    async getGlobalPackages(context) {
        if (context.state.globalPackages) {
            return context.state.globalPackages;
        }
        const ref = sharedCollection.doc('default');

        const packagesCollection = ref.collection('packages');
        let packages = await packagesCollection.get();
        packages = packages.docs.map((doc) => {
            return {
                id: doc.id,
                ...doc.data(),
            }
        });

        // get getting started packages
        const gettingStartedPackages = packages.filter(pkg => pkg.isGettingStartedPackage === true);
        context.commit('setGettingStartedPackages', gettingStartedPackages);

        await Promise.all(packages.map(async (pkg) => {
            let projectIds = [];
            let projectThumbnails = [];
            let packageIds = [];
            if (Array.isArray(pkg.projectsAssigned) && pkg.projectsAssigned.length > 0) {
                for (let i = 0; i < pkg.projectsAssigned.length; i++) {
                    const project = pkg.projectsAssigned[i];

                    if (project.ref) {
                        //get the project document from ref
                        const projectDoc = await project.ref.get();
                        if (projectDoc.exists) {
                            projectIds.push(projectDoc.id);
                            projectThumbnails.push(projectDoc.data().thumbnail || assets.icons.datasetImagePlaceholder);
                        }
                    }
                }
            }
            if (Array.isArray(pkg.packagesAssigned) && pkg.packagesAssigned.length > 0) {
                for (let i = 0; i < pkg.packagesAssigned.length; i++) {
                    const pkgs = pkg.packagesAssigned[i];  //Nested package

                    if (pkgs.ref) { 
                        //get the package document from ref
                        const packageDoc = await pkgs.ref.get();
                        if (packageDoc.exists) {
                            packageIds.push(packageDoc.id); //Get ID of nested package

                            const packageData = packageDoc.data();

                            if (Array.isArray(packageData.projectsAssigned) && packageData.projectsAssigned.length > 0) {
                                for (let i = 0; i < packageData.projectsAssigned.length; i++) {
                                    const project = packageData.projectsAssigned[i];

                                    if (project.ref) {
                                        const projectDoc = await project.ref.get()
                                        if (projectDoc.exists) {
                                            projectThumbnails.push(projectDoc.data().thumbnail || assets.icons.datasetImagePlaceholder);
                                        }
                                    }
                                }
                            }
                        }
                    } 
                }
            }
            pkg.projectsAssigned = {
                projectIds,
                projectThumbnails
            
            }
            pkg.packagesAssigned = {
                packageIds
            }
        }));
        // set the global packages in the store
        context.commit('setGlobalPackages', packages);
    },
    async getGlobalProjects(context) {
        if (context.state.subscriptions.globalProjects !== null) {
            return context.state.globalProjects;
        }
        const ref = sharedCollection.doc('default');
        const projectsCollection = ref.collection('workflows');
        return new Promise((resolve, reject) => {
            const subscription = projectsCollection.onSnapshot(projects => {
                try {
                    const mappedProjects = projects.docs.map(doc => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                            mode: PROJECT_MODE.GLOBAL_TEMPLATE
                        }
                    });
                    context.commit('setGlobalProjects', mappedProjects);
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
            context.commit('setSubscription', { key: 'globalProjects', value: subscription });
        });
    },
    async getTeamProjects(context, orgId) {
        if (context.state.subscriptions.teamProjects !== null) {
            return context.state.teamProjects;
        }
        const ref = organisationsCollection.doc(orgId);
        const projectsCollection = ref.collection('published_workflows');
        return new Promise((resolve, reject) => {
            const subscription = projectsCollection.onSnapshot(projects => {
                try {
                    const mappedProjects = projects.docs.map(doc => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                            mode: PROJECT_MODE.ORG_TEMPLATE
                        }
                    });
                    context.commit('setTeamProjects', mappedProjects);
                    
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
            context.commit('setSubscription', { key: 'teamProjects', value: subscription });
        });
    },
    async getGlobalProjectsTags(context) { 
        if (context.state.globalProjectsTags) {
            return context.state.globalProjectsTags;
        }

        const docRef = sharedCollection.doc('default'); 
        const tags = await docRef.get()
        const tagData = tags.data()
        const tagsList = tagData["tags"]
        context.commit('setGlobalProjectsTags', tagsList)
    },
    unregisterAllSubscription(context, {key}) {
        if (context.state.subscriptions[key] !== null) {
            context.state.subscriptions[key](); // unsubscribe
            context.commit(key, null) // reset templates
            context.commit('setSubscription', { key, value: null })
        }
    },
    unregisterAllSubscriptions(context) {
        context.dispatch('unregisterAllSubscription', {key: 'globalProjects'})
        context.dispatch('unregisterAllSubscription', {key: 'teamProjects'})
    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}