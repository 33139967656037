import { keyBy } from 'lodash'
export default {
    props: {
        row: {
            type: Object,
            default: () => {}
        },
        datasets: {
            type: Array,
            default: () => []
        }
    },
    emits: [
        'show-transform-dialog'
    ],
    computed: {
        selectedMultipleProperties() {
            return this.selected.properties.length > 1
        },
        selectedMultipleDates() {
            const aggregration_options = ['all', 'all-except-first', 'all-except-last']
            if (this.selected.cadence === 'range' || this.selected.cadence === 'single_composite') {
                return false 
            }
            if (this.selected.dates.length > 1 || this.selected.dates.some(item => aggregration_options.includes(item))) { 
                return true
            } 
            return false
        },
        hasDateTransform() {
            return this.selectedMultipleDates && this.selected.datesTransform !== null
        },
        hasPropertiesTransform() {
            return this.selectedMultipleProperties && this.selected.propertiesTransform !== null
        },
        selectedBandsDisplayText() {
            const bandMap = keyBy(this.datasetProperties,'id')
            const displayBands = this.selected.properties.filter(id => bandMap[id] !== undefined).map(id => bandMap[id].name)
            const count = displayBands.length

            if(count === 0) {
                return ''
            }

            if(this.hasPropertiesTransform) {
                if(count === this.datasetProperties.length) {
                    return this.selected.propertiesTransform + ' of all bands'
                }
                return this.selected.propertiesTransform + ' of [' + count + '] bands'
            }
            
            if(displayBands.length > 2) {
                return displayBands.slice(0,2).join(', ') + '... [' + count + ']'
            }
            return displayBands.join(', ') + '[' + count + ']'
        },
        datasetOptions() {
            return this.datasets.map(dataset => {
                return {
                    name: dataset.title,
                    id: dataset.id
                }
            })
        },
        /**
         * Generates a list of dates for the selected dataset
         */
         datasetDates() {
            if(!this.selected.dataset) {
                return [];
            }

            const selectedDataset = this.getDataset(this.selected.dataset);

            if(!selectedDataset) {
                return [];
            }

            // if date variables are defined then return empty array
            if(Array.isArray(selectedDataset.variables) && selectedDataset.variables.filter(v => v.type === 'date').length > 0) {
                return []
            }

            if(!selectedDataset.multi_image_temporal?.temporal || Array.isArray(selectedDataset.multi_image_temporal?.temporal) === false) {
                return [];
            }

            return selectedDataset.multi_image_temporal?.temporal.map((date, index) => {
                return {
                    name: date.name,
                    id: index,
                    value: date.value
                }
            })
        },
        /**
         * Generates a list of either bands or attributes for the selected dataset
         * Based on whether it's an IC or FC
         */
        datasetProperties() {
            if((this.datasetDates.length && !this.selected.dates.length) || !this.selected.dataset) {
                return [];
            }

            const dataset = this.getDataset(this.selected.dataset);
            /**
             * Maps over items and returns the correct list item object,
             * based on datatype.
             */
            const mapItems = (item, index) => {
                return {
                    name: item['ebx:name'] || item.name,
                    datatype: item['ebx:datatype'],
                    id: index,
                    value: item.value
                }
            }

            if(dataset.type === "image_collection") {
                return dataset.bands.map(mapItems);
            }

            if(dataset.type === "feature_collection") {
                return dataset.attributes.map(mapItems);
            }

            return []
        }
    },
    methods: {
        getDataset(id) {
            return this.datasets.find(d => d.id === id);
        },
        getSelectedProperties(ids, datasetId, selector="name") {
            const dataset = this.getDataset(datasetId || this.selected.dataset);
            const selectedProps = this.datasetProperties.filter(prop => ids.includes(prop.id));
            const selectedPropNames = selectedProps.map(prop => prop[selector]);

            if(dataset.type === "image_collection") {
                return dataset.bands.filter(b =>{
                    if(b['ebx:name'] !== undefined) {
                        return selectedPropNames.includes(b['ebx:name'])
                    }
                    return selectedPropNames.includes(b[selector])
                });
            } 
            if(dataset.type === "feature_collection") {
                return dataset.attributes.filter(p => selectedPropNames.includes(p[selector]) || selectedPropNames.includes(p['ebx:name']));
            }

            return [];
        },
        handleLoadTransformDialog(columnKey, select) {
            if(select) {
                select.selectActions.closeSelect()
            }
            this.$emit('show-transform-dialog', columnKey, this.row.id)
        },
        filterDatasetProperties(dataType, filterAttribute, datasetId) {
            if(!datasetId) {
                return [];
            }
            const dataset = this.getDataset(datasetId);
            if(!dataset) {
                return [];
            }

            const mapItems = (item, index) => {
                return {
                    name: item['ebx:name'] || item.name,
                    key: item.name,
                    datatype: item['ebx:datatype'],
                    id: index,
                    value: item.value
                }
            }
            const filterNumericBands = item => {
                if(Array.isArray(dataType)){
                    return dataType.includes(item['ebx:datatype'])
                }
                return item['ebx:datatype'] === dataType
            }

            return dataset[filterAttribute]? dataset[filterAttribute]
                .filter(filterNumericBands)
                .map(mapItems): [];
        },
        filterDatasetOptions(dataType,filterAttribute) {

            const filterHasBandsType = dataset => {
                if (dataset[filterAttribute] === undefined) {
                    return false
                }
                const numericBands = dataset[filterAttribute].filter(b => {
                    if(Array.isArray(dataType)){
                        return dataType.includes(b['ebx:datatype'])
                    }
                    return b['ebx:datatype'] === dataType
                })
                return numericBands.length > 0
            }

            const selectNameAndId= dataset => {
                return {
                    name: dataset.title,
                    id: dataset.id
                }
            }

            return this.datasets
                .filter(filterHasBandsType)
                .map(selectNameAndId)
        },
        getPreselectedProperties(properties, datasetSelection) {
            if(!this.row.dataset) {
                return [];
            }
            let dataset = this.getDataset(this.row.dataset);
            let names = properties.map(p => p['ebx:name'] || p.name);

            const getIndexes = (properties) => {
                return properties
                    .map(p => p['ebx:name'] || p.name)
                    .map((name, i) => names.includes(name) ? i : null)
                    .filter(i => i !== null);
            }

            // Detect presense of computed dataset properties
            if (datasetSelection) {
                return getIndexes(datasetSelection);
            }

            // fallback to getting indexes from dataset
            if(dataset.type === "image_collection") {
                let indexes = getIndexes(dataset.bands);
                return indexes;
            }

            if(dataset.type === "feature_collection") {
                let indexes = getIndexes(dataset.attributes);
                return indexes;
            }
        },
        computeSelectedRow(dataset, role, roleType, extraData = {}) {
            return {
                id: this.row.id,
                dataset: this.selected.dataset,
                title: dataset.title,
                type: dataset.type,
                properties: this.getSelectedProperties(this.selected.properties),
                dates: this.selected.dates,
                role: role,
                aggregation: this.selected.role,
                roleType: roleType,
                propertiesTransform: this.selected.propertiesTransform,
                datesTransform: this.selected.datesTransform,
                transformPreference: this.selected.transformPreference,
                cadence: dataset.multi_image_temporal ? dataset.multi_image_temporal.cadence: null,
                displayType: this.selected.displayType,
                ...extraData
            }
        },
        handleRowUpdated(newRow) {
            const updateColumns = ['propertiesTransform','datesTransform','transformPreference']
            updateColumns.forEach(col => {
                if(newRow[col] !== undefined && newRow[col] !== this.selected[col]) {
                    this.selected[col] = newRow[col]
                }
            })
        }
    },
}
