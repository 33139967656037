<template>
    <md-empty-state v-bind="$props" class="md-table-empty-state">
      <slot />
    </md-empty-state>
  </template>
  
  <script>
    import MdEmptyState from '../MdEmptyState/MdEmptyState.vue'
    import MdEmptyStateProps from '../MdEmptyState/MdEmptyStateProps.js'
  
    export default {
      name: 'MdTableEmptyState',
      components: { MdEmptyState },
      props: MdEmptyStateProps,
      inject: ['MdTable']
    }
  </script>
  
  <style lang="scss">
    @import "../MdAnimation/variables";
  
    .md-table-empty-state {
      padding-left: 24px;
    }
  </style>