/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import { AbstractBlock, AbstractFieldHelpers } from '../_abstractBlock'
import {togglePlayButton, addRunButtonInput, addStopButtonInput} from '@/blocks/mixins/togglePlayButtons'
import {FieldModal} from '@/fields/FieldModal';
import WekaKMeansModal from '@/modals/unsupervised_classification/WekaKMeans.vue'; 
import WekaCascadeKMeansModal from '@/modals/unsupervised_classification/WekaCascadeKMeans.vue'; 
import WekaCobWebModal from '@/modals/unsupervised_classification/WekaCobweb.vue'; 
import WekaLVQModal from '@/modals/unsupervised_classification/WekaLVQ.vue'; 
import WekaXMeansModal from '@/modals/unsupervised_classification/WekaXMeans.vue'; 
import { ContentService } from '@/services/content.service';
import assets from '@/assets.js';

const INPUT = Object.freeze({
    CLASSES: 'classes_input',
    ALGO: 'algorithm',
    MODAL: 'algorithm_modal_input'
})

const FIELD = Object.freeze({
    ALGO: 'algorithm',
    CLASSES: 'classes',
    MODAL: 'algorithm_modal'
})

const algorihmOptions = [
    ['CascadeKMeans','wekaCascadeKMeans'],
    ['Cobweb','wekaCobweb'],
    ['KMeans','wekaKMeans'],
    ['LVQ','wekaLVQ'],
    ['XMeans','wekaXMeans']
]

const wekaKMeansOptions =  {
    nClusters: 2,
    init: 0,
    canopies: false,
    maxCandidates: 100,
    periodicPruning: 10000,
    minDensity: 2,
    t1: -1.5,
    t2: -1,
    distanceFunction: 'Euclidean',
    maxIterations: null,
    preserveOrder: false,
    fast: false,
    seed: 10
}

const wekaCascadeKMeansOptions =  {
    minClusters: 2,
    maxClusters: 10,
    restarts: 10,
    manual: false,
    init: false,
    distanceFunction: 'Euclidean',
    maxIterations: null
}

const wekaCobwebOptions =  {
    acuity: 1,
    cutoff: 0.002,
    seed: 42
}

const wekaLVQOptions =  {
    numClusters: 7,
    learningRate: 1,
    epochs: 1000,
    normalizeInput: false
}

const wekaXMeansOptions =  {
    minClusters: 2,
    maxClusters: 10,
    maxIterations: 3,
    maxKMeans: 1000,
    maxForChildren: 1000,
    useKD: false,
    cutoffFactor: 0,
    distanceFunction: 'Euclidean',
    seed: 10
}

const defineUnsupervisedClassificationJson = {
    "type": "workflow_unsupervised_classification",
    "lastDummyAlign0": "RIGHT",
    "message0": "%1 %2 %3 %4 %5 %6 %7 %8 %9 %10 %11 %12 %13 %14 %15 %16",
    "args0": [
        {
            "type": "field_label",
            "text": "%{BKY_WORKFLOW_UNSUPERVISED_CLASSIFICATION_LABEL}", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label",
            "text": "%{BKY_WORKFLOW_UNSUPERVISED_CLASSIFICATION_ALGORITHM_LABEL}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.ALGO,
            "options": algorihmOptions
        },
        {
            "type": "input_dummy",
            "name": INPUT.ALGO,
        },
        {
            "type": "field_label",
            "text": "%{BKY_WORKFLOW_UNSUPERVISED_CLASSIFICATION_PARAMS_LABEL}"
        },
        {
            "type": "field_modal",
            "name": FIELD.MODAL,
            "modal_component": WekaKMeansModal,
            "modal_data": Object.assign({}, wekaKMeansOptions),
            "src": assets.blockly.settingsWhite24dp,
            "width": 24,
            "height": 24,
            "opt_alt": "ALT STRING"
        },
        {
            "type": "input_dummy",
            "name": INPUT.MODAL
        },
        {
            "type": "field_label",
            "text": "%{BKY_WORKFLOW_UNSUPERVISED_CLASSIFICATION_INPUT_LABEL}"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_statement",
            "name": 'dataset'
        },
        {
            "type": "field_label",
            "text": "%{BKY_WORKFLOW_UNSUPERVISED_CLASSIFICATION_OUTPUT_LABEL}"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_statement",
            "name": 'output_data'
        },
        {
            "type": "field_label",
            "name": "run_button_label",
            "text": "Run"
        },
        {
            "type": "field_play_button",
            "name": "run_button_field",
        }
    ],
    "style": "classify",
    "tooltip": "",
    "helpUrl": ""
}


//

Blockly.Blocks['workflow_unsupervised_classification'] = {
    ... AbstractBlock,
    ... AbstractFieldHelpers,
    togglePlayButton,
    addRunButtonInput,
    addStopButtonInput,
    onInit: function () {
        this.jsonInit(defineUnsupervisedClassificationJson);

        this.about_block_url = ContentService.getAboutBlockUrl('workflow_unsupervised_classification')

        let runButtonField = this.getField('run_button_field')
        runButtonField.getParentInput().name = "run_button_input"

        this.getField(FIELD.ALGO).setValidator(this.algorithmValidator)
    },
    algorithmValidator(newValue) {
        this.getSourceBlock().setState(FIELD.ALGO, newValue)
        return newValue
    },
    
    accept:async function (visitor) {
        console.log('accept workflow_unsupervised_classification visitor')
        await visitor.visitWorkflowUnsupervisedClassificationBlock(this);
    },
    
    updateShape_: function() {
        if(this.hasStateChanged(FIELD.ALGO)) {
            this.removeInput(INPUT.MODAL)
            switch(this.getState(FIELD.ALGO)) {
                case 'wekaCascadeKMeans':
                    this.addModalInput(this, WekaCascadeKMeansModal, wekaCascadeKMeansOptions, INPUT.MODAL, FIELD.MODAL)
                    break;
                case 'wekaKMeans':
                    this.addModalInput(this, WekaKMeansModal, wekaKMeansOptions, INPUT.MODAL, FIELD.MODAL)
                    break;    
                case 'wekaCobweb':
                    this.addModalInput(this, WekaCobWebModal, wekaCobwebOptions, INPUT.MODAL, FIELD.MODAL)
                    break;  
                case 'wekaLVQ':
                    this.addModalInput(this, WekaLVQModal, wekaLVQOptions, INPUT.MODAL, FIELD.MODAL)
                    break;  
                case 'wekaXMeans':
                    this.addModalInput(this, WekaXMeansModal, wekaXMeansOptions, INPUT.MODAL, FIELD.MODAL)
                    break;           
            }
        }

        this.togglePlayButton()
        
    },
    addModalInput: function(block, modal, modalData, inputName, fieldName = 'modal_field') {
        const field = new FieldModal(modal, modalData, assets.blockly.settingsWhite24dp, 30, 30)
        block.appendDummyInput(inputName)
            .appendField(new Blockly.FieldLabelSerializable(Blockly.Msg.WORKFLOW_UNSUPERVISED_CLASSIFICATION_PARAMS_LABEL, "modal_options_label"))
            .appendField(field,fieldName)

        block.moveInputAfter(INPUT.MODAL, INPUT.ALGO)

        return field
    },
}