/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly'

Blockly.Msg["MODIFIER_DATERANGE_BLOCK_TITLE"] = "SELECT DATE RANGE";
Blockly.Msg["MODIFIER_DATERANGE_TIMEPERIOD_INPUT_LABEL"] = "Time period ";
Blockly.Msg["MODIFIER_DATERANGE_FROM_VARIABLE_LABEL"] = "From ";
Blockly.Msg["MODIFIER_DATERANGE_TO_VARIABLE_LABEL"] = "To"; 

export default Blockly.Msg;
