<template>
    <div>
        <div 
            class="assets-tab--container" 
            v-for="(asset, index) in exportedAssets" 
            :key="index">
                <ExportedAsset
                    :export="asset"
                    :uid="user.uid"
                />
        </div>
    </div>
</template>

<script>
import ExportedAsset from "@/components/ExportedAsset.vue";

export default {
    name: "Exportlist",
    components: {
        ExportedAsset
    },
    props: {
        exportedAssets: {
            type: Array,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    }
}
</script>