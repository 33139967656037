<template>
    <md-field class="md-multi-select">
        <md-menu md-align-trigger ref="dropdown">

            <md-input class="md-multi-select--input" :model-value="displayTextComputed" :placeholder="placeholder" readonly md-menu-trigger/>
            <md-icon class="md-icon-image" md-menu-trigger>
                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 10l5 5 5-5z" />
                <path d="M0 0h24v24H0z" fill="none" />
                </svg>
            </md-icon>

            <md-menu-content class="md-multi-select--dropdown">
                <div class="ebx-secondary md-multi-select--header">
                    Select date ranges
                </div>
                <md-menu-item :key="single.value" v-for="single in singleValues">
                    <div class="md-checkbox md-primary md-theme-default" :class="{'md-checked': isChecked(single.value)}">
                        <div class="md-checkbox-container" @mousedown="handleFauxClick(single.value, true)">
                            <div class="md-ripple">
                                <input type="checkbox" :id="single.value" :value="single.value" :checked="isChecked(single.value)" @change="handleOptionChoice($event,true)" />
                            </div>
                        </div>
                        <label class="md-checkbox-label ebx-primary" :for="single.value">{{ single.name }}</label>
                    </div>
                </md-menu-item>
                <template v-if="options.length > 0">
                    <md-divider></md-divider>
                    <div class="ebx-secondary md-multi-select--header">
                        Select individual dates
                    </div>
                    <md-menu-item :key="option.value" v-for="option in options">
                        <div class="md-checkbox md-primary md-theme-default" :class="{'md-checked': isChecked(option.value)}">
                            <div class="md-checkbox-container" @mousedown="handleFauxClick(option.value, false)">
                                <div class="md-ripple">
                                    <input type="checkbox" :id="option.value" :value="option.value" :checked="isChecked(option.value)" @change="handleOptionChoice($event,false)" />
                                </div>
                            </div>
                            <label class="md-checkbox-label ebx-primary" :for="option.value">{{ option.name }}</label>
                        </div>
                    </md-menu-item>
                </template>
                <slot name="after" v-bind:selectActions="selectActions"></slot>
            </md-menu-content>

        </md-menu>
        
    </md-field>
</template>

<script>


import {TABLE_DATE_CONSTANTS} from '@/constants/nextGenConstants'
import valueMixin from '@/components/mixins/valueMixin'

export default {
    name: 'TableMultiSelect',
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        displayText: {
            type: String,
            default: null
        }
    },
    emits: [
        'update:modelValue'
    ],
    mixins: [valueMixin],
    computed: {
        displayTextComputed() {
            if(this.displayText !== null && this.displayText.length > 0)
                return this.displayText
            
            return this.selection
                .map(item => {
                    const staticOptions = this.singleValues.find(single => single.value === item)
                    const allDates = this.options.find(single => single.value === item)
                    if (staticOptions) {
                        return staticOptions.name
                    } else if (allDates) {
                        return allDates.name
                    } else {
                        return item
                    }
                })
                .join(', ')
        },
        singleValuesValues() {
            return this.singleValues.map(item => item.value)
        },
        selectActions() {
            return {
                closeSelect: this.closeSelect
            }
        },
    },
    data() {
        return {
            selection: this.modelValue,
            singleValues: TABLE_DATE_CONSTANTS
        }
    },
    methods: {
        isChecked(value) {
            return this.selection.indexOf(value) > -1
        },
        getAllowedOptionsFor(value) {
            const constOptions = this.singleValues.find(option => option.value === value)
            return constOptions && constOptions.allow ? constOptions.allow : []
        },
        handleFauxClick(value, singularOption) {
            this.handleOptionChoice({target: {value: value}}, singularOption)
        },
        handleOptionChoice(event,singularOption = false) {
            const value = event.target.value
            if(singularOption) {
                let selection = this.selection.slice(0)
                if (this.selection.indexOf(value) > -1) {
                    selection = this.selection.slice(0).filter(item => item !== value)
                } else {
                    const allowedValues = [value, ...this.getAllowedOptionsFor(value)]
                    selection.push(value)
                    selection = selection.filter(item => allowedValues.includes(item))
                }
                this.selection = selection
            } else {
                let selection = this.selection.slice(0).filter(item => !this.singleValuesValues.includes(item))
                if (selection.indexOf(value) > -1) {
                    selection= selection.filter(item => item !== value)
                } else {
                    selection = [...selection, value]
                }
                this.selection = selection
            }
            this.$emit('update:modelValue', this.selection)
        },
        closeSelect() {
            setTimeout(() => {
                this.$refs.dropdown.toggleContent()
            })
        },
    }
}
</script>