/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

// //-------------------------------------------------COUNTRIES LIST--------------------------------------------------------------
// // FIPS Country list

const strataZones = [["Horn of Africa", "hoa"]];

const strataCountries = {
    "hoa": [
        ["All Countries", "hoa"],
        ["Djibouti", '70'],
        ["Eritrea", '77'],
        ["Ethiopia", '79'],
        ["Kenya", '133'],
        ["Somalia", '226'],
        ["South Sudan", '74'],
        ["Sudan", '6'],
        ["Uganda", '253'],
    ]
}

// const countriesStrata = [
//     ['Somalia', '226'],
//     // ['Colombia', '57']
// ];

// generated from USDOS/LSIB_SIMPLE/2017
const countries = [
    [
      "Afghanistan",
      "AF"
    ],
    [
      "Akrotiri",
      "AX"
    ],
    [
      "Albania",
      "AL"
    ],
    [
      "Algeria",
      "AG"
    ],
    [
      "American Samoa",
      "AQ"
    ],
    [
      "Andorra",
      "AN"
    ],
    [
      "Angola",
      "AO"
    ],
    [
      "Anguilla",
      "AV"
    ],
    [
      "Antarctica",
      "AY"
    ],
    [
      "Antigua & Barbuda",
      "AC"
    ],
    [
      "Argentina",
      "AR"
    ],
    [
      "Armenia",
      "AM"
    ],
    [
      "Aruba",
      "AA"
    ],
    [
      "Ashmore & Cartier Is",
      "AT"
    ],
    [
      "Australia",
      "AS"
    ],
    [
      "Austria",
      "AU"
    ],
    [
      "Azerbaijan",
      "AJ"
    ],
    [
      "Bahamas, The",
      "BF"
    ],
    [
      "Bahrain",
      "BA"
    ],
    [
      "Bangladesh",
      "BG"
    ],
    [
      "Barbados",
      "BB"
    ],
    [
      "Belarus",
      "BO"
    ],
    [
      "Belgium",
      "BE"
    ],
    [
      "Belize",
      "BH"
    ],
    [
      "Benin",
      "BN"
    ],
    [
      "Bermuda",
      "BD"
    ],
    [
      "Bhutan",
      "BT"
    ],
    [
      "Bolivia",
      "BL"
    ],
    [
      "Bosnia & Herzegovina",
      "BK"
    ],
    [
      "Botswana",
      "BC"
    ],
    [
      "Bouvet Island",
      "BV"
    ],
    [
      "Brazil & Invernada Area",
      "BR"
    ],
    [
      "British Indian Ocean Terr",
      "IO"
    ],
    [
      "British Virgin Is",
      "VI"
    ],
    [
      "Brunei",
      "BX"
    ],
    [
      "Bulgaria",
      "BU"
    ],
    [
      "Burkina Faso",
      "UV"
    ],
    [
      "Burma",
      "BM"
    ],
    [
      "Burundi",
      "BY"
    ],
    [
      "Cabo Verde",
      "CV"
    ],
    [
      "Cambodia",
      "CB"
    ],
    [
      "Cameroon",
      "CM"
    ],
    [
      "Canada",
      "CA"
    ],
    [
      "Cayman Is",
      "CJ"
    ],
    [
      "Central African Rep",
      "CT"
    ],
    [
      "Chad",
      "CD"
    ],
    [
      "Chile",
      "CI"
    ],
    [
      "China",
      "CH"
    ],
    [
      "Christmas I",
      "KT"
    ],
    [
      "Clipperton Island",
      "IP"
    ],
    [
      "Cocos (Keeling) Is",
      "CK"
    ],
    [
      "Colombia",
      "CO"
    ],
    [
      "Comoros",
      "CN"
    ],
    [
      "Cook Is",
      "CW"
    ],
    [
      "Coral Sea Is",
      "CR"
    ],
    [
      "Costa Rica",
      "CS"
    ],
    [
      "Cote d'Ivoire",
      "IV"
    ],
    [
      "Croatia",
      "HR"
    ],
    [
      "Cuba",
      "CU"
    ],
    [
      "Cuba",
      "CU"
    ],
    [
      "Curacao",
      "UC"
    ],
    [
      "Cyprus",
      "CY"
    ],
    [
      "Czechia",
      "EZ"
    ],
    [
      "Dem Rep of the Congo",
      "CG"
    ],
    [
      "Denmark",
      "DA"
    ],
    [
      "Dhekelia",
      "DX"
    ],
    [
      "Djibouti",
      "DJ"
    ],
    [
      "Dominica",
      "DO"
    ],
    [
      "Dominican Republic",
      "DR"
    ],
    [
      "Ecuador",
      "EC"
    ],
    [
      "Egypt & Bir Tawil Area",
      "EG"
    ],
    [
      "El Salvador",
      "ES"
    ],
    [
      "Equatorial Guinea",
      "EK"
    ],
    [
      "Eritrea",
      "ER"
    ],
    [
      "Estonia",
      "EN"
    ],
    [
      "Ethiopia",
      "ET"
    ],
    [
      "Falkland Islands",
      "FA"
    ],
    [
      "Faroe Is",
      "FO"
    ],
    [
      "Fed States of Micronesia",
      "FM"
    ],
    [
      "Fiji",
      "FJ"
    ],
    [
      "Finland",
      "FI"
    ],
    [
      "France",
      "FR"
    ],
    [
      "French Guiana",
      "FG"
    ],
    [
      "French Polynesia",
      "FP"
    ],
    [
      "French S & Antarctic Lands",
      "FS"
    ],
    [
      "Gabon",
      "GB"
    ],
    [
      "Gambia, The",
      "GA"
    ],
    [
      "Gaza Strip",
      "GZ"
    ],
    [
      "Georgia",
      "GG"
    ],
    [
      "Germany",
      "GM"
    ],
    [
      "Ghana",
      "GH"
    ],
    [
      "Gibraltar",
      "GI"
    ],
    [
      "Greece",
      "GR"
    ],
    [
      "Greenland",
      "GL"
    ],
    [
      "Grenada",
      "GJ"
    ],
    [
      "Guadeloupe",
      "GP"
    ],
    [
      "Guam",
      "GQ"
    ],
    [
      "Guatemala",
      "GT"
    ],
    [
      "Guernsey",
      "GK"
    ],
    [
      "Guinea",
      "GV"
    ],
    [
      "Guinea-Bissau",
      "PU"
    ],
    [
      "Guyana",
      "GY"
    ],
    [
      "Haiti",
      "HA"
    ],
    [
      "Sudan & Halaib Triangle",
      "SU"
    ],
    [
      "Heard I & McDonald Is",
      "HM"
    ],
    [
      "Honduras",
      "HO"
    ],
    [
      "Hong Kong",
      "HK"
    ],
    [
      "Hungary",
      "HU"
    ],
    [
      "Iceland",
      "IC"
    ],
    [
      "India",
      "IN"
    ],
    [
      "Indonesia",
      "ID"
    ],
    [
      "Iran",
      "IR"
    ],
    [
      "Iraq",
      "IZ"
    ],
    [
      "Ireland",
      "EI"
    ],
    [
      "Isle of Man",
      "IM"
    ],
    [
      "Israel",
      "IS"
    ],
    [
      "Italy",
      "IT"
    ],
    [
      "Jamaica",
      "JM"
    ],
    [
      "Jan Mayen",
      "JN"
    ],
    [
      "Japan",
      "JA"
    ],
    [
      "Jersey",
      "JE"
    ],
    [
      "Jordan",
      "JO"
    ],
    [
      "Kazakhstan",
      "KZ"
    ],
    [
      "Kenya",
      "KE"
    ],
    [
      "Kiribati",
      "KR"
    ],
    [
      "Korea, North",
      "KN"
    ],
    [
      "Korea, South",
      "KS"
    ],
    [
      "Kosovo",
      "KV"
    ],
    [
      "Kuwait",
      "KU"
    ],
    [
      "Kyrgyzstan",
      "KG"
    ],
    [
      "Laos",
      "LA"
    ],
    [
      "Latvia",
      "LG"
    ],
    [
      "Lebanon",
      "LE"
    ],
    [
      "Lesotho",
      "LT"
    ],
    [
      "Liberia",
      "LI"
    ],
    [
      "Libya",
      "LY"
    ],
    [
      "Liechtenstein",
      "LS"
    ],
    [
      "Lithuania",
      "LH"
    ],
    [
      "Luxembourg",
      "LU"
    ],
    [
      "Macau",
      "MC"
    ],
    [
      "Macedonia",
      "MK"
    ],
    [
      "Madagascar",
      "MA"
    ],
    [
      "Malawi",
      "MI"
    ],
    [
      "Malaysia",
      "MY"
    ],
    [
      "Maldives",
      "MV"
    ],
    [
      "Mali",
      "ML"
    ],
    [
      "Malta",
      "MT"
    ],
    [
      "Marshall Is",
      "RM"
    ],
    [
      "Martinique",
      "MB"
    ],
    [
      "Mauritania",
      "MR"
    ],
    [
      "Mauritius",
      "MP"
    ],
    [
      "Mayotte",
      "MF"
    ],
    [
      "Mexico",
      "MX"
    ],
    [
      "Moldova",
      "MD"
    ],
    [
      "Monaco",
      "MN"
    ],
    [
      "Mongolia",
      "MG"
    ],
    [
      "Montenegro",
      "MJ"
    ],
    [
      "Montserrat",
      "MH"
    ],
    [
      "Morocco",
      "MO"
    ],
    [
      "Mozambique",
      "MZ"
    ],
    [
      "Namibia",
      "WA"
    ],
    [
      "Nauru",
      "NR"
    ],
    [
      "Navassa I",
      "BQ"
    ],
    [
      "Nepal",
      "NP"
    ],
    [
      "Netherlands",
      "NL"
    ],
    [
      "New Caledonia",
      "NC"
    ],
    [
      "New Zealand",
      "NZ"
    ],
    [
      "Nicaragua",
      "NU"
    ],
    [
      "Niger",
      "NG"
    ],
    [
      "Nigeria",
      "NI"
    ],
    [
      "Niue",
      "NE"
    ],
    [
      "No Man's Land",
      "NM"
    ],
    [
      "Norfolk I",
      "NF"
    ],
    [
      "Northern Mariana Is",
      "CQ"
    ],
    [
      "Norway",
      "NO"
    ],
    [
      "Oman",
      "MU"
    ],
    [
      "Pakistan",
      "PK"
    ],
    [
      "Palau",
      "PS"
    ],
    [
      "Panama",
      "PM"
    ],
    [
      "Papua New Guinea",
      "PP"
    ],
    [
      "Paracel Is",
      "PF"
    ],
    [
      "Paraguay",
      "PA"
    ],
    [
      "Peru",
      "PE"
    ],
    [
      "Philippines",
      "RP"
    ],
    [
      "Pitcairn Is",
      "PC"
    ],
    [
      "Poland",
      "PL"
    ],
    [
      "Portugal",
      "PO"
    ],
    [
      "Puerto Rico",
      "RQ"
    ],
    [
      "Qatar",
      "QA"
    ],
    [
      "Rep of the Congo",
      "CF"
    ],
    [
      "Reunion",
      "RE"
    ],
    [
      "Romania",
      "RO"
    ],
    [
      "Russia",
      "RS"
    ],
    [
      "Rwanda",
      "RW"
    ],
    [
      "S Georgia & S Sandwich Is",
      "SX"
    ],
    [
      "Saint Lucia",
      "ST"
    ],
    [
      "Samoa",
      "WS"
    ],
    [
      "San Marino",
      "SM"
    ],
    [
      "Sao Tome & Principe",
      "TP"
    ],
    [
      "Saudi Arabia",
      "SA"
    ],
    [
      "Senegal",
      "SG"
    ],
    [
      "Serbia",
      "RI"
    ],
    [
      "Seychelles",
      "SE"
    ],
    [
      "Sierra Leone",
      "SL"
    ],
    [
      "Singapore",
      "SN"
    ],
    [
      "Sint Maarten",
      "NN"
    ],
    [
      "Slovakia",
      "LO"
    ],
    [
      "Slovenia",
      "SI"
    ],
    [
      "Solomon Is",
      "BP"
    ],
    [
      "Somalia",
      "SO"
    ],
    [
      "South Africa",
      "SF"
    ],
    [
      "South Sudan",
      "OD"
    ],
    [
      "Spain",
      "SP"
    ],
    [
      "Spratly Is",
      "PG"
    ],
    [
      "Sri Lanka",
      "CE"
    ],
    [
      "St Barthelemy",
      "TB"
    ],
    [
      "St Helena",
      "SH"
    ],
    [
      "St Kitts & Nevis",
      "SC"
    ],
    [
      "St Martin",
      "RN"
    ],
    [
      "St Pierre & Miquelon",
      "SB"
    ],
    [
      "St Vincent & the Grenadines",
      "VC"
    ],
    [
      "Suriname",
      "NS"
    ],
    [
      "Svalbard",
      "SV"
    ],
    [
      "Swaziland",
      "WZ"
    ],
    [
      "Sweden",
      "SW"
    ],
    [
      "Switzerland",
      "SZ"
    ],
    [
      "Syria",
      "SY"
    ],
    [
      "Taiwan",
      "TW"
    ],
    [
      "Tajikistan",
      "TI"
    ],
    [
      "Tanzania",
      "TZ"
    ],
    [
      "Thailand",
      "TH"
    ],
    [
      "Timor-Leste",
      "TT"
    ],
    [
      "Togo",
      "TO"
    ],
    [
      "Tokelau",
      "TL"
    ],
    [
      "Tonga",
      "TN"
    ],
    [
      "Trinidad & Tobago",
      "TD"
    ],
    [
      "Tunisia",
      "TS"
    ],
    [
      "Turkey",
      "TU"
    ],
    [
      "Turkmenistan",
      "TX"
    ],
    [
      "Turks & Caicos Is",
      "TK"
    ],
    [
      "Tuvalu",
      "TV"
    ],
    [
      "US Minor Pacific Is. Refuges",
      "UM"
    ],
    [
      "US Virgin Is",
      "VQ"
    ],
    [
      "Uganda",
      "UG"
    ],
    [
      "Ukraine",
      "UP"
    ],
    [
      "United Arab Emirates",
      "AE"
    ],
    [
      "United Kingdom",
      "UK"
    ],
    [
      "United States",
      "US"
    ],
    [
      "Uruguay",
      "UY"
    ],
    [
      "Uzbekistan",
      "UZ"
    ],
    [
      "Vanuatu",
      "NH"
    ],
    [
      "Vatican City",
      "VT"
    ],
    [
      "Venezuela",
      "VE"
    ],
    [
      "Vietnam",
      "VM"
    ],
    [
      "Wake I",
      "WQ"
    ],
    [
      "Wallis & Futuna",
      "WF"
    ],
    [
      "West Bank",
      "WE"
    ],
    [
      "Western Sahara",
      "WI"
    ],
    [
      "Yemen",
      "YM"
    ],
    [
      "Zambia",
      "ZA"
    ],
    [
      "Zimbabwe",
      "ZI"
    ],
    // [
    //     "Disputed Territories",
    //     "UU"
    // ]
  ];
// export {countries, countriesStrata, strataZones} ;
export {countries, strataZones, strataCountries} ;
