<template>
  <md-dialog
    v-model:md-active="isDialogVisible"
    :md-click-outside-to-close="false"
    @md-opened="dialogOpened"
  >
    <md-dialog-title>{{ isUpdate ? 'Update' : 'New' }} Organisation</md-dialog-title>
    <form id="new-org" @submit.prevent="saveOrganisation">
      <md-dialog-content>
        <md-field :class="getValidationClass('name')">
          <label for="name">Name</label>
          <md-input v-model="organisation.name" ref="name" />
          <span
            class="md-error"
            v-if="v$.organisation.name.required.$invalid"
          >The organisation name is required</span>
        </md-field>
    
        <!--
        <md-field :class="getValidationClass('accessType')">
          <label for="accessType">Organisation license</label>
          <md-select v-model="organisation.accessType" name="accessType">
            <md-option v-for="access in accessTypes" :key="access" :value="access" :selected="access === accessType">{{access|capitalize}}</md-option>
          </md-select>
          <span class="md-error" v-if="v$.organisation.accessType.required.$invalid">License type is required</span>
        </md-field>
        -->
        
        <md-field :class="getValidationClass('sector')">
          <label for="sector">Sector</label>
          <md-select v-model="organisation.sector" name="sector">
             <md-option v-for="[sectorKey, orgSector] in Object.entries(orgSectors)" :key="sectorKey" :value="sectorKey" :selected="sectorKey === sector">{{orgSector}}</md-option>
          </md-select>
          <span class="md-error" v-if="v$.organisation.sector.required.$invalid">Sector is required</span>
        </md-field>

        <md-field :class="getValidationClass('type')">
          <label for="type">Type</label>
          <md-select v-model="organisation.type" name="type">
              <md-option v-for="[typeKey, orgType] of Object.entries(orgTypes)" :key="typeKey" :value="typeKey" :selected="typeKey === type">{{orgType}}</md-option>
          </md-select>
          <span class="md-error" v-if="v$.organisation.type.required.$invalid">Type is required</span>
        </md-field>

        <md-field :class="getValidationClass('maxUsers')">
          <label>Maximum allowed users</label>
          <md-input type="number" min="0" v-model="organisation.maxUsers" />
          <span
            class="md-error"
            v-if="v$.organisation.maxUsers.numeric.$invalid || v$.organisation.maxUsers.between.$invalid || v$.organisation.maxUsers.required.$invalid"
          >Maximum users must be a valid number</span>
        </md-field>

        <md-field :class="getValidationClass('maxExplorerUsers')">
          <label>Maximum allowed explorer users</label>
          <md-input type="number" min="0" v-model="organisation.maxExplorerUsers" />
          <span
            class="md-error"
            v-if="v$.organisation.maxExplorerUsers.numeric.$invalid || v$.organisation.maxExplorerUsers.between.$invalid || v$.organisation.maxExplorerUsers.required.$invalid"
          >Maximum explorer users must be a valid number</span>
          <span
            class="md-error"
            v-if="v$.organisation.maxExplorerUsers.smallerThanMaxUsers.$invalid"
          >
          Explorer Users must be less than or equal to maximum users
          </span>
          <span
            class="md-error"
            v-if="v$.organisation.maxExplorerUsers.smallerThanCount.$invalid"
          >
            Organisation has {{organisation.maxExplorerUsersCount}} explorer users. Please enter a more than this.
          </span>

          
        </md-field>
       
        <md-field :class="getValidationClass('id')">
          <label>Organisation Identifier</label>
          <md-input v-model="organisation.id" :readonly="isUpdate"  @blur="v$.organisation.id.$touch"/>
           <span class="md-error" v-if="v$.organisation.id.noSpaces.$invalid">Organisation identifier cannot contain spaces</span>
        </md-field>

        <md-switch v-model="organisation.enableTermsAndConditions" :class="getValidationClass('enableTermsAndConditions')">Terms and conditions enabled</md-switch>

        <md-list v-if="organisation.enableTermsAndConditions" class="md-double-line">
            <md-list-item v-for="version in organisation.termsandconditions_versions" :key="version.id">
                  <md-radio v-model="organisation.termsandconditions_current" 
                    :value="version.id">
                        {{version.id}} 
                  </md-radio>
                <md-field>
                        <md-input v-model="version.reference" />
                </md-field>
            </md-list-item>
         </md-list>

        <md-field v-if="organisation.enableTermsAndConditions">
          <label>Add new terms and conditions link</label>
          <md-input v-model="organisation.newTermsRef" />
        </md-field>

        <md-field>
          <label>Name of principal customer contact</label>
          <md-input v-model="organisation.contactName" />
        </md-field>

        <md-field>
          <label>Email of principal customer contact</label>
          <md-input v-model="organisation.contactEmail" />
        </md-field>

        <md-field :class="getValidationClass('accessType')">
        <div>
            <legend>What is the organisation license?</legend>
            <md-radio
                name="accessType"
                v-for="access in accessTypes"
                v-model="organisation.accessType"
                :checked="access === accessType"
                :key="access"
                :value="access">
                {{capitaliseFilter(access)}}
            </md-radio>
        </div>
        <span class="md-error" v-if="v$.organisation.accessType.required.$invalid">License type is required</span>
        </md-field>

        <md-field :class="getValidationClass('defaultGeoTIFFDownloadQuota')">
          <label>GeoTIFF download quota</label>
          <md-input type="number" min="0" v-model="organisation.defaultGeoTIFFDownloadQuota"/>
          <span
            class="md-error"
            v-if="v$.organisation.defaultGeoTIFFDownloadQuota.numeric.$invalid || v$.organisation.defaultGeoTIFFDownloadQuota.between.$invalid"
          >GeoTIFF download quota must be a valid number</span>
        </md-field>

        <md-field :class="getValidationClass('defaultVectorDownloadQuota')">
          <label>Vector/Table download quota</label>
          <md-input type="number" min="0" v-model="organisation.defaultVectorDownloadQuota"/>
          <span
            class="md-error"
            v-if="v$.organisation.defaultVectorDownloadQuota.numeric.$invalid || v$.organisation.defaultVectorDownloadQuota.between.$invalid"
          >Vector/Table download quota must be a valid number</span>
        </md-field>

        <md-checkbox :class="getValidationClass('stripeIntegrationActive')" v-model="organisation.stripeIntegrationActive">Stripe integration active?</md-checkbox>
        
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="closeDialog">Close</md-button>
        <md-button type="submit" class="md-primary">Save</md-button>
      </md-dialog-actions>
    </form>
  </md-dialog>

</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, between } from '@vuelidate/validators'
import { ConfigService } from '@/services/config.service';
import { EBX_ACCESS, DEFAULT_NON_COMMERCIAL_LICENCE, DEFAULT_COMMERCIAL_LICENCE } from "@/config/constants.js";
import { ORG_SECTORS, ORG_TYPES, GEOTIFF_DOWNLOAD_QUOTA, VECTOR_DOWNLOAD_QUOTA } from "@/constants/appConstants.js";

function getDefaultOrganisation() {
  
   let defaultorg = {
    name: "",
    id: "",
    sector: "",
    type: "",
    contactName: "",
    contactEmail: "",
    enableTermsAndConditions:true,
    newTermsRef: "",
    maxUsers: '',
    maxExplorerUsers: '',
    termsandconditions_versions:[ 
        {
            "id":"1.0",
            "reference": DEFAULT_NON_COMMERCIAL_LICENCE
        },
        {
            "id":"2.0",
            "reference": DEFAULT_COMMERCIAL_LICENCE
        }
    ],
    termsandconditions_current: "1.0", 
    defaultGeoTIFFDownloadQuota: GEOTIFF_DOWNLOAD_QUOTA,
    defaultVectorDownloadQuota: VECTOR_DOWNLOAD_QUOTA,
    stripeIntegrationActive: false,

  };
   return defaultorg ;
}

export default {
  name: "OrganisationListAddEditOrganisation",
  setup: () => ({ v$: useVuelidate() }),
  data: () => ({
    isUpdate: false,
    password: "",
    organisation: getDefaultOrganisation(),
    isDialogVisible: false,
    orgId: null,
    orgConfig: null,
    type: '',
    sector: '',
    accessType: '',
    accessTypes: {},
    stripeIntegrationActive:false,
  }),
  emits: [
    'organisation-save'
  ],
  validations: {
    organisation: {
      name: {
        required
      },
      sector: {
        required
      },
      accessType: {
        required
      },
      type: {
        required
      },
      enableTermsAndConditions: {
        required,
        default: false  
      },
      maxUsers: {
        numeric,
        required,
        between: between(1, Infinity)
      }, 
      maxExplorerUsers: {
        numeric,
        required,
        between: between(0, Infinity),
        smallerThanMaxUsers(value) {
          const intValue = parseInt(value)
          if (isNaN(intValue)) {
            return value === '';
          }
          if (isNaN(parseInt(this.organisation.maxUsers))) {
            return false;
          }
          return intValue <= parseInt(this.organisation.maxUsers);
        },
        smallerThanCount(value) {
          const intValue = parseInt(value)
          const maxCount = parseInt(this.organisation.maxExplorerUsersCount)
          if (isNaN(maxCount) || maxCount <= 0) {
            return true;
          }
          if (isNaN(intValue)) {
            return false;
          }
          return intValue >= maxCount;
        }
      },
      defaultGeoTIFFDownloadQuota: {
        numeric,
        between: between(0, Infinity)
      }, 
      defaultVectorDownloadQuota: {
        numeric,
        between: between(0, Infinity)
      }, 
      stripeIntegrationActive :{ 
        default: false
      }, 
      id: {
        noSpaces: value => !/\s/.test(value)
      }
    }
  },
  methods: {
    closeDialog() {
        this.isDialogVisible = false;
    },
    async showDialog(organisation) {
      if (organisation) {
        this.isUpdate = organisation.id != null;
        this.organisation = Object.assign({}, organisation);
        this.orgId = organisation.id;
        this.orgConfig = await ConfigService.getOrgConfig(this.orgId)
      } else {
        this.organisation = getDefaultOrganisation();
        this.orgConfig = await ConfigService.getOrgConfig(this.organisation.id)
      }
      this.isDialogVisible = true;
    },
    dialogOpened() {    
      this.v$.$reset();

      setTimeout(() => {
        this.$refs.name.$el.focus();
      }, 200);
    },
    getValidationClass(fieldName) {
      const field = this.v$.organisation[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    saveOrganisation() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.$emit("organisation-save", this.organisation);

      this.isDialogVisible = false;
    },
    capitaliseFilter(value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
  created: function() {        
        this.accessTypes = EBX_ACCESS;
        this.orgSectors = ORG_SECTORS;
        this.orgTypes = ORG_TYPES;
  },
};
</script>

<style scoped lang="scss">
.md-dialog {
    form {
        overflow: auto;
    }
}

.md-dialog-title {
    margin-bottom: 0;
}

.md-switch {
    margin-top: 0;
}
</style>
