import { AbstractCollection  } from "./AbstractCollection"
import {ProvideTimePeriod} from '../providesTimePeriod'

export class DatasetCollection extends AbstractCollection {

    getDateRanges() {
        return this.abilities.map(ability => {
            const timePeriods = ability.workflowState.gatherAbilities(ProvideTimePeriod)
            return timePeriods.last()
        })
    }

    async getGlobalDatasetData(currentWorkflowStateId) {
        return await Promise.all(
            this.abilities
            .filter(ability => typeof ability.getGlobalDatasetData === 'function')
            .map(async ability => await ability.getGlobalDatasetData(currentWorkflowStateId))
        )
    }
}