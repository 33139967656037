<template>
    <div>
        <md-dialog class="blockly-modals multi-channel-modal" v-model:md-active="computedModalShown">
            <div class="multi-channel">
            <form>
                <md-field>
                    <label for="contrast_options">Contrast Options</label>
                    <md-select name="contrast_options" v-model="formData.contrastValue">
                        <md-option v-for="option in contrast_options" :value="option[1]" :key="option[1]">
                            {{ option[0] }}
                        </md-option>
                    </md-select>
                </md-field>
                <div class="field-group vertical-fields-layout" v-if="formData.contrastValue === 'custom'">
                    <div class="horizontal-layout-item">
                        <p class="md-title horizontal-fields-label">R</p>
                        <md-field :class="getValidationClass('minR')" >
                            <label for="r_min">Min</label>
                            <md-input name="r_min" v-model="formData.minR" type="number" :placeholder="`${minR}`"></md-input>
                            <span class="md-error" v-if="v$.formData.minR.$invalid">Min should be less than max</span>
                        </md-field>
                        <md-field :class="getValidationClass('maxR')">
                            <label for="r_max">Max</label>
                            <md-input name="r_max" v-model="formData.maxR" type="number" :placeholder="`${maxR}`"></md-input>
                        </md-field>
                    </div>
                    <div class="horizontal-layout-item">
                        <p class="md-title horizontal-fields-label">G</p>
                        <md-field :class="getValidationClass('minG')">
                            <label for="g_min">Min</label>
                            <md-input name="g_min" v-model="formData.minG" type="number" :placeholder="`${minG}`"></md-input>
                            <span class="md-error" v-if="v$.formData.minG.$invalid">Min should be less than max</span>
                        </md-field>
                        <md-field :class="getValidationClass('maxG')">
                            <label for="g_max">Max</label>
                            <md-input name="g_max" v-model="formData.maxG" type="number" :placeholder="`${maxG}`"></md-input>
                        </md-field>
                    </div>
                    <div class="horizontal-layout-item">
                        <p class="md-title horizontal-fields-label">B</p>
                        <md-field :class="getValidationClass('minB')">
                            <label for="r_min">Min</label>
                            <md-input name="r-min" v-model="formData.minB" type="number" :placeholder="`${minB}`"></md-input>
                            <span class="md-error" v-if="v$.formData.minB.$invalid">Min should be less than max</span>
                        </md-field>
                        <md-field :class="getValidationClass('maxB')">
                            <label for="r_max">Max</label>
                            <md-input name="r_max" v-model="formData.maxB" type="number" :placeholder="`${maxB}`"></md-input>
                        </md-field>
                    </div>
                </div>
                    <div class="multi-channel--description">
                        <h3 class="ebx-header-4">Description</h3>
                        <md-field :class="invalidClass">
                            <label hidden>Description</label>
                            <md-textarea v-model="formData.layerDescription" placeholder="Add a description" md-autogrow :md-counter="maxDescriptionLength"></md-textarea>
                            <span class="md-error">{{descriptionErrorMessage}}</span>
                            <span class="md-helper-text">Descriptions show alongside the map layer.</span>
                        </md-field>
                    </div> 
            </form>

            <md-dialog-actions>
                <md-button class="md-primary" @click="computedModalShown = false">Close</md-button>
                <md-button class="md-primary" @click="saveModal">Save</md-button>
            </md-dialog-actions>
            </div>
        </md-dialog>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {VIS_CHANNEL_OPTIONS} from '@/constants/nextGenConstants';
import { globalEventBus } from '@/eventbus.js'
import blocklyModalsMixin from './blocklyModalsMixin.js'

export default {
    name: 'MultiChannelMapLayer',
    setup: () => ({ v$: useVuelidate() }),
    mixins: [blocklyModalsMixin],
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        minR: {
            type: Number,
            default: null
        },
        maxR: {
            type: Number,
            default: null
        },
        minG: {
            type: Number,
            default: null
        },
        maxG: {
            type: Number,
            default: null
        },
        minB: {
            type: Number,
            default: null
        },
        maxB: {
            type: Number,
            default: null
        },
        contrastValue: {
            type: String,
            default: ''
        },
        layerDescription: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            contrast_options: VIS_CHANNEL_OPTIONS,
            maxDescriptionLength: 500, 
            descriptionErrorMessage: '',
            invalidClass: '',
            invalidDescription: false,
            formData: {
                minR: this.minR,
                maxR: this.maxR,
                minG: this.minG,
                maxG: this.maxG,
                minB: this.minB,
                maxB: this.maxB,
                contrastValue: this.contrastValue,
                layerDescription: this.layerDescription,
            }
        }
    },
    computed: {
        layerDescriptionFormData() {
            return this.formData.layerDescription
        }
    },
    validations () {
        return {
            formData: {
                contrastValue: {
                    required
                },
                minR: {
                    lessThanMax: function (value) {
                        if (this.formData.contrastValue !== 'custom') {
                            return true;
                        }
                        return Number(value) < Number(this.formData.maxR);
                    },
                    required
                },
                maxR: {
                    required
                },
                minG: {
                    lessThanMax: function (value) {
                        if (this.formData.contrastValue !== 'custom') {
                            return true;
                        }
                        return Number(value) < Number(this.formData.maxG);
                    },
                    required
                },
                maxG: {
                    required
                },
                minB: {
                    lessThanMax: function (value) {
                        if (this.formData.contrastValue !== 'custom') {
                            return true;
                        }
                        return Number(value) < Number(this.formData.maxB);
                    },
                    required
                },
                maxB: {
                    required
                },
            }
        }
    },
    methods: {
        getValidationClass(fieldName) {
            const field = this.v$.formData[fieldName];

            if (field) {
                return {
                    "md-invalid": field.$invalid
                };
            }
        },
        validateDescription(layerDescription) {
            if (layerDescription.length > this.maxDescriptionLength) {
                this.invalidDescription = true
                this.invalidClass = 'md-invalid'
                this.descriptionErrorMessage = `Description must be less than ${this.maxDescriptionLength} characters'`
                return false
            }
            // if (nextGenConstants.ESCAPE_SEQUENCE_REGEX.test(description)) {
            //     this.invalidDescription = true
            //     this.invalidClass = 'md-invalid'
            //     this.descriptionErrorMessage = 'Description cannot contain escape sequences'
            //     return false
            // }
            this.invalidDescription = false
            this.invalidClass = ''
            return true
        },
        getFieldValue() {
            return {
                minR: this.formData.minR,
                maxR: this.formData.maxR,
                minG: this.formData.minG,
                maxG: this.formData.maxG,
                minB: this.formData.minB,
                maxB: this.formData.maxB,
                contrastValue: this.formData.contrastValue,
                layerDescription: this.formData.layerDescription,
            };
        },
        saveModal() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        }
    },
    watch: {
        showModal(val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if(val) {
                this.formData = {
                    minR: this.minR,
                    maxR: this.maxR,
                    minG: this.minG,
                    maxG: this.maxG,
                    minB: this.minB,
                    maxB: this.maxB,
                    contrastValue: this.contrastValue,
                    layerDescription: this.layerDescription,
                }
            }
        }, 
        layerDescriptionFormData: {
            handler: function (val) {
                this.validateDescription(val)
                return val
            },
            deep: true
        },
    }
}
</script>