<template>
    <div>
        <component 
            :is="variableComponent"
            :varType="variable.type"
            :variable="variable"
            :hasValue="hasValue"
            :isEditing="isEditingVariable"
            :is-new="isNew"
            :selected-area="selectedArea"
            :show-add-map-area-option="showAddMapAreaOption"
            :are-warnings="areWarnings"
            :is-drawn="isDrawn"
            :disabled="isDisabled"
            @add-map-area="handleAddMapArea"
            @cancel-editing="handleEditCancel"
            @choose-from-assets="handleChooseArea"
            @changed-name="handleVariableNameChanged"
            @edit-variable="handleEditVariable"
            @error-dialog-fired="handleErrorDialogFired"
            @remove-area="handleRemoveArea"
            @save-variable="handleVariableSaved"
            @select-default-area="handleSelectDefaultArea"
            @show-add-map-area="showAddMapArea"
            @draw-area="handleEnableDrawing(false)"
            @edit-drawn-area="handleEnableDrawing(true)"
            @upload-area="handleUploadArea"
            @export-polygons="exportPolygons"
            />
        <GoogleMapChooseAreaDialog 
            v-model="showChooseAreaDialog" 
            :selected-collection-id="aoiCollectionId" 
            :loadIntoCollection="false"
            :splitByAttribute="false"
            :modalTitle="modalTitle"
            default-area-name="__ASSET_NAME__"
            @add-area="addArea"
            @area-id-added="handleAreaAdded"
            @error-dialog-fired="handleErrorDialogFired"
        />
        <MapAreaModal 
            v-model="showMapAreaDialog"
            @area-selected="handleAreaAdded"
        />

    </div>
</template>

<script>
import AreaVariableCreator from './AreaVariableCreator.vue'
import AreaVariableExplorer from './AreaVariableExplorer.vue'
import GoogleMapChooseAreaDialog from '@/components/ResultMap/GoogleMapChooseAreaDialog.vue'
import MapAreaModal from './MapAreaModal.vue';
import { AreaService } from "@/services/area.service";
import { MAP_STUDY_AREA_COLLECTION_ID, VARIABLE_NEW_PREFIX  } from '@/constants/nextGenConstants';
import { globalEventBus } from '@/eventbus.js'

export default {
    name: 'AreaVariable',
    components: {
        AreaVariableCreator,
        AreaVariableExplorer,
        GoogleMapChooseAreaDialog,
        MapAreaModal
    },
    props: {
        variable: {
            type: Object,
            required: false,
            default: () => {
                return {
                    type: 'area',
                    description: null,
                    id: VARIABLE_NEW_PREFIX,
                    title: undefined,
                    value: null,
                }
            },
        },
        isNew: {
            type: Boolean,
            default: false,
            required: false
        },
        isExplorer: {
            type: Boolean,
            required: false
        }, 
        areWarnings: { 
            type: Boolean, 
            required: false 
        }
    },
    emits: [
        'cancel-editing',
        'edit-variable',
        'save-variable',
        'error-dialog-fired'
    ],
    data() {
        return {
            selectedArea: null,
            showChooseAreaDialog: false,
            showMapAreaDialog: false,
            aoiCollectionId: MAP_STUDY_AREA_COLLECTION_ID,
            isEditingVariable: false,
            showAddMapAreaOption: false,
            currentName: this.variable.title,
            isDrawn: false,
            isInDrawingMode: false
        }
    },
    created() {
        if (this.isNew) {
            this.isEditingVariable = true;
        }
    },
    computed: {
        hasValue() {
            if (this.selectedArea) {
                return true;
            } else {
                return false;
            }
        },
        variableId() {
            return this.variable.id;
        },
        variableComponent() {
            if (this.isNew) {
                return 'AreaVariableCreator'
            } else {
                return this.variable.vue_component
            }
        },
        modalTitle() {
            if (this.isExplorer) { 
                return 'Select area'
            } else { 
                return 'Add example area'
            }
        },
        lastAreaDrawnToMap() {
            return this.$store.state.maplayers.drawingMode.lastCreatedArea
        },
        isDisabled() {
            return this.$store.state.maplayers.drawingMode.mode !== null
        }
    },
    watch: {
        isEditingVariable: {
            handler (editing) {
                if (editing) {
                    if (this.variable.value && !this.isExplorer) {
                        if(AreaService.isAreaOrCollection(this.variable.value)==='area') {
                            this.selectedArea = AreaService.getUserAreaById(this.variable.value)
                        }
                        if(AreaService.isAreaOrCollection(this.variable.value)==='collection') {
                            this.selectedArea = AreaService.getCollectionById(this.variable.value)
                        }
                    }
                    if(this.isExplorer && this.variable.value === null) {
                        this.selectedArea = null
                    }
                }
            },
            immediate: true
        },
        lastAreaDrawnToMap(area) {
            const wasInDrawingMode = this.isInDrawingMode
            this.isInDrawingMode = false
            if(area === null) {
                return
            }
            if(wasInDrawingMode) {
                this.isDrawn = true
                this.handleAreaAdded(area.id)
            }
        }
    },
    methods: {
        handleVariableNameChanged(name) {
            this.currentName = name
        },
        addArea(areaName) {
            globalEventBus.$emit('add-area-to-map', areaName);
        },
        handleAddMapArea() {
            this.showMapAreaDialog = true
        },
        handleAreaAdded(id) {
            this.setSelectedArea(id)
            // if explorer, set the value of the variable to the selected area
            if (this.isExplorer) {
                this.$emit('save-variable', {id: this.variable.id, value: this.selectedArea.id}, 'area');
            }
        },
        setSelectedArea(id) {
            if(AreaService.isAreaOrCollection(id)==='area') {
                this.selectedArea = AreaService.getUserAreaById(id)
            }
            // set area if its a collection
            if(AreaService.isAreaOrCollection(id)==='collection') {
                this.selectedArea = AreaService.getCollectionById(id)
            }
            // enable the area if visiblity is hidden
            if( this.selectedArea) {
                AreaService.setVisiblityForId(this.selectedArea.id, true)
                globalEventBus.$emit('zoom-to-area', this.selectedArea)
            }
        },
        handleEditCancel() {
            this.$emit('cancel-editing');
            if(this.isNew) {
                this.handleRemoveArea(true)
            }
            this.isEditingVariable = false;
        },
        handleEditVariable() {
            this.$emit('edit-variable');
            this.isEditingVariable = true;
        },
        handleRemoveArea(removeFromAreas = false) {
            if(removeFromAreas === true && this.selectedArea) {
                if(this.variable.default_value === this.selectedArea.id) {
                    AreaService.setVisiblityForId(this.selectedArea.id, false)
                } else {
                    AreaService.removeArea(this.selectedArea)
                }
            } else if (removeFromAreas === false && this.selectedArea) {
                AreaService.setVisiblityForId(this.selectedArea.id, false)
            }
            this.selectedArea = null;
            if (this.isExplorer) {
                this.$emit('save-variable', {id: this.variable.id, value: null }, 'area');
            }
            this.isDrawn = false
        },
        handleVariableSaved(variable) {
            if(this.isNew === false) {
                variable.id = this.variable.id
            }
            this.$emit('save-variable', variable, 'area');
        },
        handleChooseArea() {
            const collectionId = this.aoiCollectionId
            AreaService.addStudyAreaIfNotExists()
            AreaService.setSelectedCollection(collectionId)
            this.showChooseAreaDialog = true
        },
        handleErrorDialogFired(errorText) {
            this.$emit('error-dialog-fired', errorText);
        },
        handleSelectDefaultArea() {
            const newValue = this.variable.default_value
            this.handleVariableSaved({value: newValue})
            this.setSelectedArea(newValue)
        },
        handleEnableDrawing(isEditing) {
            if(isEditing) {
                this.$store.commit('maplayers/setDrawingModeMode', 'select')
            } else {
                globalEventBus.$emit('add-area-to-map');
                this.$store.commit('maplayers/setDrawingModeMode', 'rectangle')
            }
            this.$store.commit('maplayers/setDrawingModeEnabled', true)
            this.isInDrawingMode = true
        },
        showAddMapArea() {
            const areas = AreaService.getStudyAreas()
            const collections = AreaService.getCollections()
            if (areas[0][0] !== "No area selected" || collections.length !== 0) { 
                this.showAddMapAreaOption = true
            }
        },
        handleUploadArea() {
            // callback to add area on successfully uploaded shape locally
            const onAddedToMap = (areas) => {
                if(Array.isArray(areas) && areas.length > 0) {
                    this.handleAreaAdded(areas[0].id)
                }
            }

            globalEventBus.$emit(
                'upload-polygon-to-collection', 
                {'id':MAP_STUDY_AREA_COLLECTION_ID, 
                'addToMap':true /* add to map */, 
                'hideSplit':true /* hide splitting features */, 
                'callback':onAddedToMap
                }
            )
        },
        exportPolygons(area) {
           this.$store.commit('maplayers/exportPolygonsForAreaId', area.id)
        }
    }
}
</script>