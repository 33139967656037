<template>
    <div v-if="mode=='show' || isActive " v-show="mode=='if' || isActive" class="ebx-tab">
        <slot v-bind:selectedValue="tabData.selectedValue"></slot>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
export default{
    name: 'EbxTab',
    inject: ['tabData'],
    props: {
        title: {
            type: String,
            required: true
        },
        id: {
            type: [String, Number],
            default: null
        }
    },
    data() {
        return {
            uid: uuidv4()
        }
    },
    created() {
        this.tabData.tabElements.push(this)
    },
    computed: {
        mode () {
            return this.tabData.tabDisplayMode
        },
        isActive() {
            return this.tabData.selectedValue === this.id || this.tabData.selectedValue === this.uid
        }
    },

}
</script>