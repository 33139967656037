import {AbstractVariable} from './abstract';
/**
 * Date Variable. Used to store a date value.
 */
export class DateVariable extends AbstractVariable {
    static TYPE = 'date';
    static NAME = 'Single date';

    constructor(id, title, description= null, value = null) {
        super(id, title, description, value);
        this.setSetForExplorer(true)
    }

    /**
     * Set a set of ranges allow to be selected for the date variable from Blockly.
     * This is called within Capture State Visitor
     * @param {*} ranges 
     * @param {*} stateId 
     * @returns 
     */
    setDateRanges(ranges, stateId) {
        let rangeObj = {}
        if(this.extraState.dateRanges !== undefined) {
            rangeObj = this.extraState.dateRanges
        }
        if (rangeObj[stateId] === undefined) {
            rangeObj[stateId] = []
        }
        
        rangeObj[stateId].push(ranges)

        this.setExtraState('dateRanges',rangeObj)
        return this
    }

    /**
     * Check if a date is valid for the variable based on the date ranges set
     * @param {*} date 
     * @returns 
     */
    isDateSelectable(date) {
        const dateRanges = this.getExtraState('dateRanges')
        if(dateRanges === undefined) {
            return true
        }
        const stateIDs = Object.keys(dateRanges)   

        /**
         * Range format
         * {
         *  // each topblock is split by state id
         *  "stateId": [
         *      // range 1 e.g. dataset extent
         *      [ ['startDate','endDate'], ['startDate','endDate'] ],
         *      // filter date ranges e.g. only months of Jan, Feb over year
         *      [ ['startDate','endDate'], ['startDate','endDate'] ],
         * }
         * 
         * All ranges must be valid per state for the date to be selectable. 
         * A range is valid if one set of dates within the range covers the input date.
         */
        
        // loop round all the states, the dates must be valid for all states
        const dateValue = new Date(date)
        if(dateValue.toString() === 'Invalid Date') {
            return false
        }
        const allPassedTests = stateIDs.reduce((acc, stateId) => {
            if(acc === false) {
                return false
            }
            const timePeriods = dateRanges[stateId]
            for (let i = 0; i < timePeriods.length; i++) {
                const ranges = timePeriods[i]
                const foundRange = ranges.find(range => {
                    const start = new Date(range[0])
                    const end = new Date(range[1])
                    return dateValue >= start && dateValue <= end
                })
                if(foundRange !== undefined) {
                    return true
                }
            }
            return false

        },true)

        return allPassedTests
    }

    /**
     * Used to get the display type of the variable that could be used to mutate the UI
     * use `variable.display_type` within the VueJS component
     * @returns {String}
     */
    getDisplayType() {
        return 'date'
    }

    /**
     * The name of the vue component to show when the user is a creator.
     * It is intented that `vue_component` will be used to dynamically load the component based on the user type
     * ```
     * <component :is="variable.vue_component" :variable="variable"></component>
     * ```
     * @returns {String}
     */
    getVueJSCreatorComponent() {
        return 'DateVariableCreator'
    }

    /**
     * The name of the vue component to show when the user is a explorer
     * It is intented that `vue_component` will be used to dynamically load the component based on the user type
     * ```
     * <component :is="variable.vue_component" :variable="variable"></component>
     * ```
     * @returns {String}
     */
    getVueJSExplorerComponent() {
        return 'DateVariableExplorer'
    }

    /**
     * Get the display text used in Blockly Fields
     * @returns {String}
     */
    getDisplayText() {
        return this.getTitle();
    }

    /**
     * Validate the variable to ensure it meets the requirements. Must appendError if there is an issue
     * Values stored in the class must be in “YYYY-MM-DD” format or null
     * @returns
     */
    validate() {
        super.validate()
        if (this.getValue() === null) {
            this.appendError('Choose a date for your variable','no_date');
        } else {
            const date = this.getValue()
            const dateValue = new Date(date)
            if (!date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                this.appendError('Date format is incorrect. Please use the format “YYYY-MM-DD”', 'incorrect_date_format');
            } else if (dateValue.toString() === 'Invalid Date') {
                this.appendError('Date chosen is invalid. Please choose a valid date', 'invalid_date_value');
            }
        }
        return this
    }
}