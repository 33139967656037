/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

export const AUTH0_CLIENT_ID = 'pql9aehqiwDJnyH1KTNMP6LhlLfBkM8L';
// export const AUTH0_CLIENT_ID = 'Ugx76IrG4hgtSHki6gHLhpWWoEqKUjyS';
// export const AUTH0_CLIENT_ID = '279792942696-s0dduuegs3n5qecan52ng66336kfc0uq.apps.googleusercontent.com';
export const AUTH0_DOMAIN = 'earthblox.eu.auth0.com';
export const AUTH0_API_AUDIENCE = AUTH0_CLIENT_ID;
export const AUTH0_USER_INFO_API = `https://${AUTH0_DOMAIN}/userinfo`;
// export const AUTH0_REDIRECT_URI = `https://us-central1-ee-stem.cloudfunctions.net/oauth/signup/callback`;
export const AUTH0_REDIRECT_URI = `https://us-central1-ebx-core.cloudfunctions.net/oauth/signup/callback`;