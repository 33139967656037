import {toRaw} from 'vue'

export default {
    inject: ['onModalSave', 'onModalClose', 'eventCallbacks', 'toWatchData'],
    computed: {
        computedModalShown: {
            get() {
                return this.showModal;
            },
            set(val) {
                if(val === false) {
                    this.onModalClose(false)
                }
            }
        }
    },
    methods: {
        blockyEventCallback(event,...args) {
            if(event === 'modal-save') {
                this.onModalSave(...args)
            }
            if(event === 'modal-close') {
                this.onModalClose(...args)
            }
            if(this.eventCallbacks[event] !== undefined) {
                this.eventCallbacks[event].forEach(callback => {
                    callback(...args)
                })
            }
        }
    },
    watch: {
        toWatchData: {
            handler(val) {
                Object.keys(val).forEach(key => {
                    // for each key in the object, update the corresponding data variable
                    this[key] = toRaw(val[key])
                })
            },
            deep: true,
            immediate: true
        }
    },
}