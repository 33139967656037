<template>
    <div>
        <div>
            <h3 class="md-title md-title-2">Define values</h3>

            <div class="md-layout" v-if="valueRows.length > 0">
                <div class="md-layout-item">
                    <div>
                        <template v-for="row in valueRows" :key="row.id">
                            <TableCreatorStatisticRow
                                v-if="row.displayType === 'statistic'"
                                :row="row"
                                :datasets="datasets"
                                @update-row="(row) => updateRow(row)"
                                @delete-row="(id) => deleteRow(id)"
                                @show-transform-dialog="handleTransformDialog"
                            />
                            <TableCreatorAreaStatisticRow
                                v-if="row.displayType === 'area-calculation'"
                                :row="row"
                                :datasets="datasets"
                                @update-row="(row) => updateRow(row)"
                                @delete-row="(id) => deleteRow(id)"
                                @show-transform-dialog="handleTransformDialog"
                            />
                            <TableCreatorFeatureAttributeRow
                                v-if="row.displayType === 'feature-attribute'"
                                :row="row"
                                :datasets="datasets"
                                @update-row="(row) => updateRow(row)"
                                @delete-row="(id) => deleteRow(id)"
                                @show-transform-dialog="handleTransformDialog"
                            />
                        </template>
                    </div>
                </div>
            </div>    
            
            <div class="md-layout">
                <div class="md-layout-item my-4">
                    <md-menu md-direction="bottom-start" md-align-trigger :md-offset-x="117" :md-offset-y="-36">
                        <md-button ref="valueButton" class="md-secondary md-secondary" :class="{transparent: valueRows.length > 0, 'p-0': valueRows.length > 0}" :disabled="!hasStatisticalDatasets && !hasStudyAreaDataset" md-menu-trigger>
                            <md-icon>add</md-icon>
                            <span> Add value </span>
                        </md-button>
                        <md-menu-content>
                            <md-menu-item :disabled="!hasStatisticalDatasets" @click="addRow('statistic')">Statistic from dataset</md-menu-item>
                            <md-menu-item :disabled="!hasStudyAreaDataset" @click="addRow('area-calculation','STUDY_AREA')">Coverage calculation</md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </div>
            </div>

        </div>
        <div>
            <h3 class="md-title md-title-2">Group table</h3>

            <div class="md-layout" v-if="groupingRows.length > 0">
                <div class="md-layout-item">
                    <div>
                        <template v-for="row in groupingRows" :key="row.id">
                            <TableCreatorGroupImageRow
                                v-if="row.displayType === 'group-image-collection'"
                                :row="row"
                                :datasets="datasets"
                                @update-row="(row) => updateRow(row)"
                                @delete-row="(id) => deleteRow(id)"
                                @show-transform-dialog="handleTransformDialog"
                            />
                            <TableCreatorGroupFeatureRow
                                v-if="row.displayType === 'group-feature-collection'"
                                :row="row"
                                :datasets="datasets"
                                @update-row="(row) => updateRow(row)"
                                @delete-row="(id) => deleteRow(id)"
                                @show-transform-dialog="handleTransformDialog"
                            />   
                        </template>
                    </div>
                </div>
            </div>
            
            <div class="md-layout">
                <div class="md-layout-item my-4">
                    <TableNewGroupButton :datasets="datasets" :button-class="{transparent: groupingRows.length > 0, 'p-0': groupingRows.length > 0}" @click="addRow" />
                </div>
            </div>    

        </div>

        <div class="table-creator--description">
            <h3 class="ebx-header-4">Description</h3>
            <md-field :class="invalidClass">
                <label hidden>Description</label>
                <md-textarea v-model="description" placeholder="Add a description" md-autogrow :md-counter="maxDescriptionLength"></md-textarea>
                <span class="md-error">{{descriptionErrorMessage}}</span>
                <span class="md-helper-text">Descriptions show alongside the table in the dashboard.</span>
            </md-field>
        </div>

        <TransformDialog
        v-model="showTransformDialog" 
        @save="handleSaveTransform">
            <Transformation 
                :selected-row="selectedRow" 
                :transform="selectedTransform" 
                :column="transformColumn" 
                :transformed-columns="transformedColumnsArray" 
                :preference="selectedPreference" 
                :preference-options="tranformPreferenceOptions"
                @update-transform="updateSelectedTransform"
                @update-preference="updateSelectedPreference"
            >
                <template v-slot:text-icon>
                    <md-icon>info_outlined</md-icon>
                </template>
                <template v-slot:text>
                    <template v-if="transformColumn==='properties'">
                         This allows you to aggregate all bands into a single band
                    </template>
                    <template v-if="transformColumn==='dates'">
                        This allows you to transform all dates into a single band
                    </template>
                </template>
                <template v-slot:transformOrderText-icon>
                    <md-icon class="warning">warning</md-icon>
                </template>
                <template v-slot:transformOrderText>
                    <template v-if="transformColumn==='properties'">
                        You're also transforming all dates from this dataset into a single image.
                        The order you perform these transformations may affect the result.
                        Would you like to transform bands first, or dates first?
                    </template>
                    <template v-if="transformColumn==='dates'">
                        You're also transforming all properties from this dataset into a single image.
                        The order you perform these transformations may affect the result.
                        Would you like to transform dates first, or properties first?
                    </template>
                </template>
            </Transformation>
        </TransformDialog>
    </div>
</template>

<script>
import {TablePreviewCreator} from './NewTablePreviewCreator.js';
import tableWorkspaceMixin from '../mixins/tableWorkspaceMixin'
import TableCreatorStatisticRow from './Rows/TableCreatorStatisticRow.vue'
import TableCreatorGroupImageRow from './Rows/TableCreatorGroupImageRow.vue'
import TableCreatorGroupFeatureRow from './Rows/TableCreatorGroupFeatureRow.vue'
import TableCreatorAreaStatisticRow from './Rows/TableCreatorAreaStatisticRow.vue'
import TableCreatorFeatureAttributeRow from './Rows/TableCreatorFeatureAttributeRow.vue';
import TransformDialog from './TransformDialog.vue'
import Transformation from './Transformation.vue'
import TableNewGroupButton from './TableNewGroupButton.vue';
import {range, uniq} from 'lodash'
import {MAP_STUDY_AREA_COLLECTION_ID, TABLE_DATE_CONSTANTS} from '../../constants/nextGenConstants';

export default {
    components: { 
        TransformDialog,
        Transformation,
        TableCreatorStatisticRow,
        TableNewGroupButton,
        TableCreatorGroupImageRow,
        TableCreatorGroupFeatureRow,
        TableCreatorAreaStatisticRow,
        TableCreatorFeatureAttributeRow
    },
    mixins: [
        tableWorkspaceMixin
    ],
    name: 'TableWorkspace',
    props: {
        datasets: {
            type: Array,
            default: () => []
        },
        createdRows: {
            type: Array,
            default: () => []
        },
        tableDescription: {
            type: String,
            default: ''
        }
    },
    emits: [
        'update-rows',
        'update-preview',
        'update-description'
    ],
    data() {
        return {
            tableCreatorRows: this.createdRows,
            description: this.tableDescription,
            descriptionErrorMessage: '',
            maxDescriptionLength: 3000,
            invalidDescription: false,
            invalidClass: '',
            tablePreview: null,
            tranformPreferenceOptions: [
                {id: 'properties', name: 'Properties First'},
                {id: 'dates', name: 'Dates First'}
            ]
        }
    },
    mounted() {
        if(this.tableCreatorRows.length > 0) {
            this.computeTable(this.tableCreatorRows);
        }
    },
    computed: {
        hasDatasets() {
            return this.datasets.length > 0
        },
        hasStatisticalDatasets() {
            return this.datasets.filter(dataset => {
                if (dataset.type !== 'image_collection') {
                    return false
                }
                return dataset.bands.filter(b => b['ebx:datatype'] === 'numeric').length > 0
            }).length > 0
        },
        hasStudyAreaDataset() {
            return this.studyAreaDataset !== null
        },
        studyAreaDataset() {
            const datasets = this.datasets
                .filter(dataset => dataset.type === 'feature_collection' && dataset.id == MAP_STUDY_AREA_COLLECTION_ID)
            return datasets.length > 0 ? datasets[0] : null
        },
        valueRows() {
            return this.tableCreatorRows.filter(row => ['statistic','area-calculation','feature-attribute'].indexOf(row.displayType) >= 0)
        },
        groupingRows() {
            return this.tableCreatorRows.filter(row => ['group-image-collection','group-feature-collection'].indexOf(row.displayType) >= 0)
        },
        groupingFeatureCollectionsRows() {
            return this.tableCreatorRows
                .filter(row => ['group-feature-collection'].indexOf(row.displayType) >= 0)
                .filter(row => row.dataset != MAP_STUDY_AREA_COLLECTION_ID)
        },
        hasGroupedFeatureCollectionsRows() {
            return this.groupingFeatureCollectionsRows.length > 0
        },
        hasOnlyGroupedFeatureCollectionsRows() {
            const imageRows =this.tableCreatorRows.filter(row => ['group-image-collection'].indexOf(row.displayType) >= 0)
            return this.hasGroupedFeatureCollectionsRows && imageRows.length === 0
        },
        groupingFeatureCollectionsRowsWithAttributes() {
            return this.groupingFeatureCollectionsRows.filter(row => {
                const dataset = this.getDataset(row.dataset)
                if(!dataset) {
                    return false
                }
                if(Array.isArray(dataset.attributes) && dataset.attributes[0] && dataset.attributes[0].properties) {
                    return Object.keys(dataset.attributes[0].properties).length > 0
                }
                return false
            })
        },
    },
    methods: {
        getDatasetYears(datasetId) {
            const foundDataSet = this.datasets.find(d => d.id === datasetId)
            return range(parseInt(foundDataSet.multi_image_temporal.timePeriod.start_date.format('YYYY')), parseInt(foundDataSet.multi_image_temporal.timePeriod.end_date.format('YYYY')) + 1 )
        },
        datasetDates(datasetId) {

            const selectedDataset = this.datasets.find(d => d.id === datasetId)

            if(!selectedDataset) {
                return [];
            }

            if(!selectedDataset.multi_image_temporal?.temporal || Array.isArray(selectedDataset.multi_image_temporal?.temporal) === false) {
                return [];
            }

            return selectedDataset.multi_image_temporal.temporal.map((date, index) => {
                return {
                    name: date.name,
                    id: index,
                    value: date.value
                }
            })
        },
        addRow(displayType, dataset = null) {
            const maxID = this.tableCreatorRows.length > 0 ? Math.max(...this.tableCreatorRows.map(r => r.id)) + 1 : 0
            const newRowData = {
                id: maxID,
                dataset: dataset,
                properties: [],
                dates: ['all'],
                role: null,
                propertiesTransform: null,
                datesTransform: null,
                transformPreference: null,
                displayType: displayType,
            }

            if(displayType === 'feature-attribute' && this.groupingFeatureCollectionsRows.length > 0) {
                const datasetDetails = this.getDataset(this.groupingFeatureCollectionsRows[0].dataset)
                newRowData.dataset = this.groupingFeatureCollectionsRows[0].dataset
                newRowData.role = 'feature-attribute'
                newRowData.aggregation = 'feature-attribute'
                newRowData.roleType = 'feature-attribute'
                newRowData.title = datasetDetails['ebx:table_title'] || datasetDetails.title
                newRowData.properties = []
            }

            if(dataset) {
                const datasetDetails = this.getDataset(dataset)
                newRowData.title = datasetDetails['ebx:table_title'] || datasetDetails.title
                newRowData.type = datasetDetails.type
                if(displayType === 'group-feature-collection' && datasetDetails !== null) {
                    newRowData.role = 'rows'
                    newRowData.roleType = 'role'
                    if(datasetDetails.attributes && datasetDetails.attributes.length > 0) {
                        newRowData.properties = [datasetDetails.attributes[0]]
                    }
                }
                if(displayType === 'area-calculation' && datasetDetails !== null) {
                    newRowData.role = 'area'
                    newRowData.aggregation = 'area'
                    newRowData.roleType = 'statistic'
                    newRowData.title = datasetDetails['ebx:table_title'] || datasetDetails.title
                    newRowData.properties = [
                        {
                            name: 'area'
                        }
                    ]
                }
            }
            
            
            this.tableCreatorRows.push(newRowData);
        },
        handleSaveTransform() {
            const selectedRowKey = this.transformColumn === 'properties' ? 'propertiesTransform' : 'datesTransform'
            this.tableCreatorRows[this.selectedRowId][selectedRowKey] = this.transformChangedData.transform
            this.tableCreatorRows[this.selectedRowId].transformPreference = this.transformChangedData.preference
        },
        computeCreatorRow(row) {
            var dates = [];

            if(row.dates.length) {
                dates = this.datasetDates(row.dataset)
                let rowDates = row.dates.slice(0)
                if (rowDates.length === 2 && rowDates.includes('first') && rowDates.includes('last')) {
                    rowDates = ['first-last']
                }
                
                if (rowDates.length === 1 && ['first-last',...TABLE_DATE_CONSTANTS.map(c => c.value)].indexOf(rowDates[0]) >= 0) {
                    if(rowDates[0] === 'first') {
                        dates = dates.slice(0, 1)
                    } else if(rowDates[0] === 'last') {
                        dates = dates.slice(-1)
                    } else if(rowDates[0] === 'all-except-last') {
                        dates = dates.slice(0, -1)
                    } else if (rowDates[0] === 'all-except-first') {
                        dates = dates.slice(1)
                    }else if (rowDates[0] === 'first-last') {
                        dates = [...dates.slice(0, 1),...dates.slice(-1)]
                    }
                } else {
                    dates = dates.filter(d => rowDates.indexOf(d.value) >= 0)
                }
                dates = dates.map(d => d.name)
            }

            row.properties.forEach((property) => {
                if(row.roleType == "statistic") {      
                    const propertyName = (property['ebx:name'] || property.name || 'unknown').toUpperCase()
                    this.tablePreview = this.tablePreview.addStatistic(propertyName, row.aggregation, dates)
                } else {
                    let classes = [];
                    // get class keys
                    // classes is a key: value set, where key is the class value
                    if(property['gee:classes']) {
                        classes = Object
                            .keys(property['gee:classes'])
                            .map(key => {
                                var classVal = property['gee:classes'][key];
                                return classVal['ebx:short_description'] || classVal.description;
                            });
                    }

                    const propertyName = (property['ebx:name'] || property.name || 'unknown');

                    let propertyListString = uniq([row.title, propertyName]).join(' ');
                    this.tablePreview = this.tablePreview.addGroup(propertyListString, classes, dates);
                }
            })
        },
        /**
         * For the creator tool, generates a table for the user to preview
         */
        computeTable(creatorRows) {
            // filter out rows that have no role
            if(!creatorRows.length) {
                this.$emit('update-preview', [], 0);
                return;
            }

            this.tablePreview = new TablePreviewCreator();
            
            // compute each row
            creatorRows.forEach(row => this.computeCreatorRow(row))

            // get table
            let preview = this.tablePreview.getTable();
            let rowCount = this.tablePreview.getMaxRows();

            this.$emit('update-preview', preview, rowCount);
        },
        validateDescription(description) {
            if (description.length > this.maxDescriptionLength) {
                this.invalidDescription = true
                this.invalidClass = 'md-invalid'
                this.descriptionErrorMessage = `Description must be less than ${this.maxDescriptionLength} characters'`
                return false
            }
            this.invalidDescription = false
            this.invalidClass = ''
            return true
        },
    },
    watch: {
        tableCreatorRows: {
            handler: function (val) {       
                const creatorRows = val.filter(r => r.role === null);
                if(creatorRows.length > 0) {
                    return
                }

                this.computeTable(val);
                this.$emit('update-rows', val);
            },
            deep: true
        },
        description: {
            handler: function (val) {
                this.validateDescription(val)
                this.$emit('update-description', val, !this.invalidDescription);
                return val
            },
            deep: true
        },
        showTransformDialog() {
            this.resetTransformChangedData()
        }
    }
}
</script>