<template>
    <div>
        <md-dialog class="ebx-dialog modal-s" v-model:md-active="computedModalShown">
            <span class="md-dialog-title md-title">
                <div class="top-actions--container">
                    <div class="title--container">
                        <span>Visualisation options</span>
                    </div>
                    <div class="close-button">
                        <md-button class="md-primary" @click="computedModalShown = false"><md-icon>close</md-icon></md-button>
                    </div>
                </div>
            </span>
            

            <md-dialog-content>
                <form>
                    <md-field>
                        <label for="attribute">Attribute</label>
                        <md-select name="attribute" v-model="formData.attribute"  @md-selected="handleChangedAttribute">
                            <md-option v-for="option in attributes" :value="option[1]" :key="option[1]">
                                {{ option[0] }}
                            </md-option>
                        </md-select>
                    </md-field>
                    <md-field>
                        <label for="type">Attribute type</label>
                        <md-select name="type" v-model="formData.type">
                            <md-option v-for="option in types" :value="option[1]" :key="option[1]">
                                {{ option[0] }}
                            </md-option>
                        </md-select>
                    </md-field>
                    <md-field>
                        <label for="theme">Colour theme</label>
                        <md-select name="theme" v-model="formData.theme">
                            <md-option class="palette-element--container" v-for="option in palette_options" :value="option[1]" :key="option[1]">
                                {{option[0]}}
                                <img 
                                    v-if="option[1] !== 'random'"
                                    class="palette-element--ramp" 
                                    :src="getPaletteImage(option[1].split('.').at(-1))"
                                    :alt="`The palette visualisation image for ${option[1].split('.').at(-1)}`"/>
                            </md-option>
                        </md-select>
                    </md-field>
                    <template v-if="isNumeric">
                        <md-field>
                            <label for="classes">Number of classes</label>
                            <md-input v-model="formData.classes"></md-input>
                        </md-field>
                        <div class="md-radio-group">
                            <label for="distribution">Distribute across classes using:</label>
                            <div>
                                <md-radio v-model="formData.distribution" value="interval">Equal Intervals</md-radio>
                            </div>
                            <div>
                                <md-radio v-model="formData.distribution" value="population">Equal Population</md-radio>
                            </div>
                        </div>
                    </template>

                    <div class="multi-vector--description">
                        <h3 class="ebx-header-4 mt-12">Description</h3>
                        <md-field :class="invalidClass">
                            <label hidden>Description</label>
                            <md-textarea v-model="formData.layerDescription" placeholder="Add a description" md-autogrow :md-counter="maxDescriptionLength"></md-textarea>
                            <span class="md-error">{{descriptionErrorMessage}}</span>
                            <span class="md-helper-text">Descriptions show alongside the map layer.</span>
                        </md-field>
                    </div> 

                </form>
                

            </md-dialog-content>
        
            <md-dialog-actions>
                <button class="ebx-button ebx-button--secondary" @click="computedModalShown = false">Close</button>
                <button class="ebx-button ebx-button--primary" @click="saveModal">Save</button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import { globalEventBus } from '@/eventbus.js'
import { PaletteService } from '@/services/palette.service';
import blocklyModalsMixin from './blocklyModalsMixin.js'

export default {
    name: 'MultiVectorMapLayer',
    mixins: [blocklyModalsMixin],
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        attribute: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: null
        },
        theme: {
            type: String,
            default: null
        },
        classes: {
            type: Number,
            default: null
        },
        distribution: {
            type: String,
            default: null
        }, 
        layerDescription: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            attributes: [],
            attributeData: [],
            formData: {
                attribute: null,
                type: null,
                theme: null,
                classes: 0,
                distribution: null,
                layerDescription: ''
            },
            palette_options: [['Randomly generated','random']].concat(PaletteService.getDropdownOptions()), 
            maxDescriptionLength: 500, 
            descriptionErrorMessage: '',
            invalidClass: '',
            invalidDescription: false,
        }
    },
    computed: {
        types() {
            const types =  [['Distinct (thematic) values','thematic']]
            if(this.selectedAttributeIsNumeric){
                types.push(['Continuous (numeric) values','numeric'])
            }
            return types;
        },
        isNumeric() {
            return this.formData.type === 'numeric'
        },
        attributeDetail() {
            return this.attributeData.find(a => (a['ebx:name'] || a['name']) === this.formData.attribute)
        },
        computedDataType() {
            if(this.attributeDetail && this.attributeDetail['ebx:datatype']) {
                return ['thematic','string'].indexOf(this.attributeDetail['ebx:datatype']) >= 0 ? 'thematic' : 'numeric'
            }
            return 'thematic'
        },
        selectedAttributeIsNumeric() {
            return this.attributeDetail && this.attributeDetail['ebx:datatype'] === 'numeric'
        },
        default_colour_ramp() {
            return this.isNumeric ? 'colorbrewer.Greens' : 'random'
        }
    },
    methods: {
        getPaletteImage(palette) {
            return PaletteService.getExampleImages()[palette]
        },
        getFieldValue() {
            return {
                attribute: this.formData.attribute,
                type: this.formData.type,
                theme: this.formData.theme,
                classes: typeof this.formData.classes === 'string' ? parseInt(this.formData.classes): this.formData.classes,
                distribution: this.formData.distribution, 
                layerDescription: this.formData.layerDescription
            };
        },
        copyPropsToData() {
            console.warn("THEME", this.theme)
            this.formData = {
                attribute: this.attribute,
                type: this.type || this.computedDataType,
                theme: this.theme || this.default_colour_ramp,
                classes: this.classes || 5,
                distribution: this.distribution || 'interval',
                layerDescription: this.layerDescription
            }
        },
        saveModal() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        },
        handleChangedAttribute() {
            if(this.attributeDetail) {
                this.formData.type = this.computedDataType
                this.formData.theme = this.default_colour_ramp
            }
        }, 
        validateDescription(layerDescription) {
            if (layerDescription.length > this.maxDescriptionLength) {
                this.invalidDescription = true
                this.invalidClass = 'md-invalid'
                this.descriptionErrorMessage = `Description must be less than ${this.maxDescriptionLength} characters'`
                return false
            }
            this.invalidDescription = false
            this.invalidClass = ''
            return true
        },
    },
    watch: {
        showModal(val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if (val) {
                this.copyPropsToData()
            }
        },
        layerDescription: {
            handler: function (val) {
                this.validateDescription(val)
                return val
            },
            deep: true
        },
    }
}
</script>

<style lang="scss" scoped>
    .multi-vector { 
        &--description {
            .md-field {
                padding-top: 0;
                min-height: auto;
        }
        }
    }

</style>