<template>
    <md-dialog :md-active="showDialog" @md-closed="showDialog = false" class="ebx-dialog dialog choose-area-dialog modal-ms">
        <md-dialog-title>
            <div class="top-actions--container">
                <p class="ebx-header-2 top-actions--title">
                    {{ modalTitle }}
                </p>
                <div class="close-button">
                    <md-button class="md-primary" @click="showDialog = false"><md-icon>close</md-icon></md-button>
                </div>
            </div>
        </md-dialog-title>
        <md-dialog-content class="dialog-content">
            <md-tabs @md-changed="openTab = $event" class="dialog-tabs">
                <md-tab id="tab-assets" md-label="Assets"></md-tab>
                <md-tab id="tab-country" md-label="Country"></md-tab>
            </md-tabs>

            <template v-if="openTab === 'tab-assets'">
                <md-field>
                    <label for="asset">Area</label>
                    <md-select v-model="asset" name="asset" id="asset" placeholder="Select from Assets">
                        <md-option v-for="asset in filteredFeatureCollectionMetadata" :value="asset.id" :key="asset.id">{{asset.title}}</md-option>
                    </md-select>
                </md-field>
                <md-checkbox v-if="loadIntoCollection" class="md-primary inset-checkbox" v-model="showIntersectingFeatures" :disabled="forceAssetCropping">
                    Only load features that intersect with my Area of Interest
                </md-checkbox>
                <md-checkbox class="md-primary inset-checkbox" v-if="showCategoriseByAttribute && splitByAttribute" v-model="showSelectableProperties">
                    Categorise by attribute
                </md-checkbox>
                <md-field class="inset-checkbox--inset-dropdown" v-if="showSelectableProperties">
                    <md-select v-model="classProperty" name="classProperty" id="asset" :placeholder=placeholderText>
                        <md-option v-for="property in selectableProperties" :value="property" :key="property">{{property}}</md-option>
                    </md-select>
                </md-field>
                <div class="choose-area-attribute-error" v-if="unselectedAttributeError">
                    <span class="ebx-secondary text-negative">{{AttributeErrorMessage}}</span>
                </div>
            </template>

            <template v-if="openTab === 'tab-country'">
                <md-autocomplete md-input-placeholder="Search countries and territories" name="area" id="area" v-model="area" :md-options="countryNames">
                    <label for="area">Country</label>
                </md-autocomplete>
            </template>
        </md-dialog-content>
        <md-dialog-actions>
            <button class="ebx-button ebx-button--secondary" :disabled="showProgressBar" @click="showDialog = false">
                Cancel
            </button>
            <button 
                v-if="showProgressBar === false" 
                class="ebx-button" 
                @click="chooseSelection" 
                :class="actionButtonDisabled ? 'ebx-button--primary__disabled' : 'ebx-button--primary'">
                Add area
            </button>
            <button v-else-if="showProgressBar" class="ebx-button ebx-button--spinner ebx-button--spinner__disabled">
                <md-progress-spinner class="md-accent" md-mode="indeterminate" :md-diameter="20" :md-stroke="2"></md-progress-spinner>
                Loading
            </button>
        </md-dialog-actions>
    </md-dialog>
</template>

<script>
import { AreaService }  from '@/services/area.service';  
import { functions } from "@/firebaseFunctions.js";
import {ASSET_NO_SPLIT_VALUE} from "../../constants/nextGenConstants";
import authMixin from "@/components/mixins/authMixin";
import valueMixin from "@/components/mixins/valueMixin";
import userDatasetsMixin from "@/components/mixins/userDatasetsMixin";
import {uniq} from 'lodash'

export default {
    mixins: [authMixin, userDatasetsMixin, valueMixin],
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        loadIntoCollection: {
            type: Boolean,
            required: true
        },
        splitByAttribute: {
            type: Boolean,
            required: false,
            default: true
        },
        modalTitle: {
            type: String,
            default: 'Choose area'
        },
        defaultAreaName: {
            type: String,
            default: null
        },
        teamOnlyAssets: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'update:modelValue',
        'error-dialog-fired',
        'area-id-added',
    ],
    data() {
        return {
            ASSET_NO_SPLIT_VALUE: ASSET_NO_SPLIT_VALUE,
            area: null,
            asset: null,
            showProgressBar: false,
            errorDialogMessage: '',
            openTab: 'tab-assets',
            assetsUpdateSubscription: null,
            classProperty: ASSET_NO_SPLIT_VALUE,
            selectableProperties: [],
            metaData: null,
            showCategoriseByAttribute: false,
            showIntersectingFeatures: false,
            showSelectableProperties: false,
            forceAssetCropping: false,
            intersectOnlyAssets: [
                'snbs/world_database_protected_areas', 
                'snbs/world_database_protected_areas_simplified_mk2',
                'snbs/KeyBiodiversityAreas',
                'snbs/KeyBiodiversityAreasSimplified'
            ], 
            unselectedAttributeError: false, 
            AttributeErrorMessage: ''
        }
    },
    computed: {
        actionButtonDisabled() {
            if(this.showProgressBar) {
                return true
            }
            if(this.openTab === 'tab-assets') {
                return this.asset === null;
            }
            if(this.openTab === 'tab-country') {
                return this.area === null;
            }
            return true
        },
        filteredFeatureCollectionMetadata() {
            if(this.teamOnlyAssets) {
                return this.featureCollectionMetadata.filter(a => {
                    return a.location === 'org'
                })
            }
            return this.featureCollectionMetadata
        },
        filtedCountries() {
            const keys = []
            return this.countries.filter(c => {
                const idx = keys.indexOf(c[1])
                keys.push(c[1])
                return idx < 0
            })
        },
        countryCodes() {
            return this.countries.map(c => c[1])
        },
        countryNames() {
            return this.countries.map(c => c[0])
        },
        countries() {
            return AreaService.getCountries()
        },
        selectedAssetName() {
            if(this.asset) {
                const asset = this.filteredFeatureCollectionMetadata.find(a => a.id === this.asset)
                if(asset) {
                    return asset.title
                }
            }
            return null;
        },
        selectedCountryName() {
            const country = this.countries.filter(c => c[0] === this.area)
            if(country.length > 0) {
                return country[0][0]
            }
            return 'Unknown';
        },
        showDialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        assetBaseName() {
            if(this.asset) {
                const assetParts = this.asset.split('/')
                return assetParts[assetParts.length - 1]
            }
            return this.asset
        },
        placeholderText() { 
            if (this.selectableProperties.length === 1) {
                return this.selectableProperties[0]
            } else {
                return 'Select attribute'
            }
        }
    },
    created() {
        this.userDatasetsLoad(this.user.orgId, this.user.uid)
    },
    watch: {
        asset(newValue) {
            this.classProperty = this.ASSET_NO_SPLIT_VALUE
            this.showCategoriseByAttribute = false
            const entryParts = newValue.split('/');
            const entryId = entryParts.slice(3, entryParts.length).join('/');
            if (this.intersectOnlyAssets.includes(entryId)) {
                // force asset to be cropped by overlap
                this.showIntersectingFeatures = true
                this.forceAssetCropping = true
            }
            this.chooseSelection()
            return newValue
        },
        classProperty(classProperty) {
            if (classProperty !== '_none_') {
                this.unselectedAttributeError = false
            }
        },
        showSelectableProperties(newValue) {
            if(newValue === false) {
                this.classProperty = this.ASSET_NO_SPLIT_VALUE;
            }
        }
    },
    methods: {
        async chooseSelection() {
            if(this.openTab==='tab-assets') {
                let collectionShapes = this.getCollectionShapes("STUDY_AREA")
                if (collectionShapes.length === 0 && this.forceAssetCropping === true) {
                    // throw error to tell user they need to add an AOI first
                    this.errorDialogMessage = "Before loading a Collection, please select an Area of Interest."
                    this.$emit('error-dialog-fired', this.errorDialogMessage)
                    this.showDialog = false
                    return
                }
                if(this.showCategoriseByAttribute) {
                    if (this.classProperty === '_none_' && this.showSelectableProperties) {
                        this.unselectedAttributeError = true 
                        this.AttributeErrorMessage = 'Please select an attribute'
                        this.showDialog = true 
                        return
                    }
                    return this.chooseAssetSelection(this.metaData)
                }
                try {
                    this.showProgressBar = true
                    const firstFeatureProperties = await this.userDatasetsProperties(this.asset)
                    this.metaData = {}
                    if(Array.isArray(firstFeatureProperties) && firstFeatureProperties.length > 0) {
                        const properties = firstFeatureProperties.map(a => a.name)
                        this.selectableProperties = properties
                        this.showCategoriseByAttribute = true

                        // if a shell asset, pre-select the property
                        const entryParts = this.asset.split('/');
                        const entryId = entryParts.slice(3, entryParts.length).join('/');
                        if (this.intersectOnlyAssets.includes(entryId)) {
                            this.showSelectableProperties = true
                            if(this.selectableProperties.includes('NAME')) { 
                                this.classProperty = 'NAME'
                            } else if (this.selectableProperties.includes('INTNAME')) {
                                this.classProperty = 'INTNAME'
                            } else {
                                this.classProperty = this.selectableProperties[0]
                            }
                        }
                    }else {
                        this.chooseAssetSelection(this.metaData)
                    }
                }catch {
                    this.errorDialogMessage = "There was a problem loading your assets to the map. Please try again."
                    this.$emit('error-dialog-fired', this.errorDialogMessage)
                    this.showDialog = false
                } finally {
                    this.showProgressBar = false
                }
            }
            if(this.openTab==='tab-country') {
                this.chooseCountrySelection()
            }
        },
        async chooseAssetSelection(meta) {
            meta = Object.assign({}, meta || {})
            meta.callback = (shape) => {
                this.$emit('area-id-added', shape.areaID)
            }
            meta.areaName = this.defaultAreaName
            try {
                this.showProgressBar = true
                let collectionShapes = []
                if (this.showIntersectingFeatures) {
                    collectionShapes = this.getCollectionShapes("STUDY_AREA")
                }
                let data = {
                    "org_id": this.user.orgId,
                    "user_id": this.user.uid,
                    "asset_id": this.asset,
                    "collection_shapes": collectionShapes,
                    "class_property": this.ASSET_NO_SPLIT_VALUE
                }
                if(this.classProperty !== this.ASSET_NO_SPLIT_VALUE) {
                    data.class_property = this.classProperty
                }else {
                    meta.classProperty = this.ASSET_NO_SPLIT_VALUE
                    meta.filterByAreas = uniq(collectionShapes.map(c => c.areaID))
                }

                    // gets mapURL and bbox
                await functions.getTable(data).then((response) => {
                    // catch the error if we return no areas to load on map
                    if (response.data && response.data.error) {
                        this.errorDialogMessage = response.data.error
                        this.$emit('error-dialog-fired', this.errorDialogMessage)
                        this.showDialog = false
                    } else {
                        let areas = response.data.areas
                        let mapURL = response.data.mapURL
                        let bbox = response.data.bbox
                        if(meta.areaName === '__ASSET_NAME__') {
                            meta.areaName = this.selectedAssetName
                        }
                        if (response.data.type === 'featureView') {
                            const meta = []
                            areas.forEach(a => {
                                meta.push({
                                    areaName:a[0],
                                    areaColour:a[1],
                                    areaProperty:data.class_property,
                                    className:a[2],
                                    classProperty: 'system:index',
                                })
                            })
                            AreaService.addFeatureViewShape(null,bbox, mapURL, this.asset,meta,this.classProperty)
                            this.showDialog = false
                        } else {
                            if(areas !== undefined) {
                                areas.forEach(a => {
                                    if(this.showCategoriseByAttribute) {
                                        meta.areaName = a.class
                                        meta.className = a.index
                                        meta.properties = a.properties
                                        meta.classProperty = this.classProperty
                                    }
                                    AreaService.addUserUploadedShape(null, bbox, a.mapURL, this.asset, Object.assign({},meta))
                                })
                            }
                            if(mapURL !== undefined) {
                                AreaService.addUserUploadedShape(null, bbox, mapURL, this.asset, Object.assign({},meta))
                            }
                            let clippedAsset = this.showIntersectingFeatures ? true : false 
                            let selectedCollection = AreaService.getSelectedCollection() || AreaService.getCollectionById('STUDY_AREA');
                            let clippedBy = "STUDY_AREA"
                            AreaService.updateCollection(selectedCollection, "clippedAsset", clippedAsset)
                            AreaService.updateCollection(selectedCollection, "clippedBy", clippedBy)
                            this.showDialog = false
                        }
                    }
                }).catch(e => {
                    console.error("error", e)
                    this.errorDialogMessage = "There was a problem loading your assets to the map. Please try again."
                    this.$emit('error-dialog-fired', this.errorDialogMessage)
                    this.showDialog = false
                }).finally(() => {
                    this.loadingAsset = false;
                });

            } catch {
                this.errorDialogMessage = "Error Loading asset."
                this.$emit('error-dialog-fired', this.errorDialogMessage)
                this.showDialog = false
            } finally {
                this.showProgressBar = false
            }
        },
        getCollectionShapes(collectionId) {
            return AreaService.getShapesForCollection(collectionId)
        },
        getCountryCode(country) {
            let countryIdx = this.countryNames.findIndex(c => c === country)
            return this.countryCodes[countryIdx]
        },
        async chooseCountrySelection() {
            const callback = (shape) => {
                this.$emit('area-id-added', shape.areaID)
            }
            try {
                this.showProgressBar = true
                let country_code = this.getCountryCode(this.area)
                const response = await functions.getCountryTable({
                    org_id: this.user.orgId,
                    user_id: this.user.uid,
                    country_co: country_code
                })
                
                this.$emit('add-area');
                this.selectedArea = AreaService.getSelectedArea()
                this.selectedArea.name = this.selectedCountryName
                AreaService.updateArea(this.selectedArea)

                let mapURL = response.data.mapURL
                let bbox = response.data.bbox
                AreaService.addUserUploadedShape(this.selectedArea.id, bbox, mapURL, country_code, {
                    properties: response.data.properties,
                    callback: callback
                }, 'CountryFeature')
                this.showDialog = false
            } catch {
                this.errorDialogMessage = "Error Loading Country"
                this.$emit('error-dialog-fired', this.errorDialogMessage)
                this.showDialog = false
            } finally {
                this.showProgressBar = false
            }
            
        }, 
    }
}    
</script>