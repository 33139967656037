<template>
    <div>
        <md-dialog v-model:md-active="computedModalShown" class="blockly-modals ebx-dialog table-creator-modal">
            <md-dialog-title>
                <div class="top-actions--container">
                    <div class="title--container">
                        <span>Add Table</span>
                    </div>
                    <div class="close-button">
                        <md-button class="md-primary" @click="computedModalShown = false"><md-icon>close</md-icon></md-button>
                    </div>
                </div>
            </md-dialog-title>
            <md-dialog-content>
                <TableCreator 
                    :datasets=datasets
                    :createdRows=formData.createdRows
                    :tableDescription=formData.tableDescription
                    @update-rows="(rows) => updateCreatedRows(rows)"
                    @update-description="(description, isValid) => updateDescription(description, isValid)"
                    />

            </md-dialog-content>
            <md-dialog-actions>
                <md-checkbox v-model="formData.includeChart" class="md-primary" :disabled="isChartDisabled">Include Chart</md-checkbox>
                <md-button class="md-primary" @click="computedModalShown = false">Cancel</md-button>
                <md-button class="md-primary md-raised" :disabled="!canSaveTable" @click="saveModal">Save</md-button>
            </md-dialog-actions>
        </md-dialog>
        <md-dialog-alert
            v-model:md-active="showAlert"
            :md-content="alertMessage"
            md-confirm-text="Back" />
    </div>
</template>

<script>
import TableCreator from "@/components/TableCreator.vue";
import {uniq, cloneDeep} from 'lodash'
import { globalEventBus } from '@/eventbus.js'
import blocklyModalsMixin from './blocklyModalsMixin.js'

export default {
    name: 'CreateTable',
    mixins: [blocklyModalsMixin],
    components: {
        TableCreator
    },
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        createdRows: {
            type: Array,
            default: () => [
                {
                    id: 0,
                    dataset: null,
                    properties: [],
                    dates: [],
                    role: null,
                    propertiesTransform: null,
                    datesTransform: null,
                    transformPreference: null
                }]
        },
        includeChart: {
            type: Boolean,
            default: false
        },
        tableDescription: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showAlert: false,
            alertMessage: '',
            isDescriptionValid: true,
            datasets: [],
            formData: {
                createdRows: this.createdRows,
                includeChart: this.includeChart,
                tableDescription: this.tableDescription
            }
        }
    },
    methods: {
        getFieldValue() {
            return {
                createdRows: this.formData.createdRows,
                includeChart: this.formData.includeChart,
                tableDescription: this.formData.tableDescription
            }
        },
        saveModal() {
            if (this.isDatatypeInvalid() == false) {
                this.blockyEventCallback('modal-save', this.getFieldValue());
            }
        },
        updateCreatedRows(rows) {
            this.formData.createdRows = rows;
        }, 
        updateDescription(description, isValid) {
            this.formData.tableDescription = description
            this.isDescriptionValid = isValid
        },
        isDatatypeInvalid() {
            var props = this.formData.createdRows.map(element => uniq(element.properties.map(property => property["ebx:datatype"])))
            var invalidRows = props.filter(row => row.length > 1)

            if (invalidRows.length > 0) {
                 this.alertMessage = "You have selected both thematic and numeric bands, please restrict your selection to one or the other."
                this.showAlert = true 
                return true 
           } 
           return false
        }
    },
    watch: {
        showModal: function (val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if(val) {
                this.formData = {
                    createdRows: cloneDeep(this.createdRows),
                    includeChart: this.includeChart,
                    tableDescription: this.tableDescription
                }
            }
        },
        isChartDisabled: function (val) {
            if (val) {
                this.formData.includeChart = false
            }
        }
    },
    computed: {
        canSaveTable() {
            return this.formData.createdRows.length > 0 && this.isDescriptionValid
        },
        isChartDisabled() {
            // TODO: add config for enabling new charts
            const config_enable_new_charts = false;
            if (config_enable_new_charts) {
                return false;
            }

            // get all unique roles
            var role = this.formData.createdRows.map(element => element.role)
            var uniqueRoles = uniq(role)

            var roleTypes = this.formData.createdRows.map(element => element.roleType)
            var uniqueRoleTypes = uniq(roleTypes)

            // allow for only statistics to enable chart
            if(uniqueRoleTypes.length === 1 && uniqueRoleTypes[0] === 'statistic') {
                return false;
            }

            //Check for area and grouping
            if(uniqueRoles.length === 2 && uniqueRoles.indexOf('area') >= 0 && uniqueRoles.indexOf('rows') >= 0) {
                return false
            }

            if (uniqueRoles.length !== this.formData.createdRows.length) {
                return true;
            }


            // groups can only have 1 property and 1 date
            // statistics can only have 1 property
            for (var i = 0; i < this.formData.createdRows.length; i++) {
                var row = this.formData.createdRows[i]
                if (row.roleType == "statistic") {
                    if (row.properties.length !== 1) {
                        return true
                    }
                } else if (row.roleType == "role") {
                    if (row.properties.length !== 1 || row.dates.length > 1) {
                        return true
                    }
                }
            }

            return false;
        }
    }
}
</script>