import * as Blockly from 'blockly/core';
import {FieldPlayButton} from '@/fields/FieldPlayButton';
import {FieldStopButton} from '@/fields/FieldStopButton';

export const togglePlayButton = function() {
    let parentBlock = this.getSurroundParent()
    if (parentBlock && parentBlock.type.includes('workflow')) {         
        this.removeInputIfExists('run_button_input')
    } else {
        const currentInput = this.getInput('run_button_input')
        if(this.inputExists('run_button_input')) {
            if (currentInput.isVisible()){
                if(this.getState('isLoading', false)) {
                    this.addStopButtonInput(this.inputExists('run_button_input'))
                } else {
                    this.addRunButtonInput(this.inputExists('run_button_input'))
                }
            }
        } else {
            this.addRunButtonInput(this.inputExists('run_button_input'))
        }
    }
}

export const addRunButtonInput = function(buttonCreated) {
    if(buttonCreated) {
        
        this.setFieldValue('Run', 'run_button_label')

        if(this.fieldExists('run_button_field')) {
            const field = this.getField('run_button_field')
            if(field instanceof FieldStopButton) {
                const input = this.getInput('run_button_input')
                input.removeField('run_button_field')
                input.appendField(new FieldPlayButton(), "run_button_field")
            }
        }

    } else {
        this.appendDummyInput('run_button_input')
            .appendField(new Blockly.FieldLabelSerializable('Run'),'run_button_label')
            .appendField(new FieldPlayButton(), "run_button_field")
            .setAlign(Blockly.ALIGN_RIGHT)
    }

}
export const addStopButtonInput = function(buttonCreated) {
    if(buttonCreated) {
        this.setFieldValue('Stop', 'run_button_label')

        if(this.fieldExists('run_button_field')) {
            const field = this.getField('run_button_field')
            if(field instanceof FieldPlayButton) {
                const input = this.getInput('run_button_input')
                input.removeField('run_button_field')
                input.appendField(new FieldStopButton(), "run_button_field")
            }
        }

    } else {
        this.appendDummyInput('run_button_input')
            .appendField(new Blockly.FieldLabelSerializable('Stop'), 'run_button_label')
            .appendField(new FieldStopButton(), "run_button_field")
            .setAlign(Blockly.ALIGN_RIGHT)
    }
}