import {AbstractVariable} from './abstract';
import {range} from 'lodash'
/**
 * Date Variable. Used to store a date value.
 */
export class DateRangeVariable extends AbstractVariable {
    static TYPE = 'date range';
    static NAME = 'Date range';

    constructor(id, title, description= null, value = null) {
        super(id, title, description, value);
        this.setSetForExplorer(true)
        this.cadence_ = null
    }

    /**
     * Set a set of ranges allow to be selected for the date variable from Blockly.
     * This is called within Capture State Visitor
     * @param {*} ranges 
     * @param {*} stateId 
     * @returns 
     */
    setDateRanges(ranges, stateId) {
        let rangeObj = {}
        if(this.extraState.dateRanges !== undefined) {
            rangeObj = this.extraState.dateRanges
        }
        if (rangeObj[stateId] === undefined) {
            rangeObj[stateId] = []
        }
        
        rangeObj[stateId].push(ranges)

        this.setExtraState('dateRanges',rangeObj)
        return this
    }

    /**
     * Check if a date is valid for the variable based on the date ranges set
     * @param {*} date 
     * @returns 
     */
    isDateSelectable(date) {
        const dateRanges = this.getExtraState('dateRanges')
        if(dateRanges === undefined) {
            return true
        }
        const stateIDs = Object.keys(dateRanges)   

        /**
         * Range format
         * {
         *  // each topblock is split by state id
         *  "stateId": [
         *      // range 1 e.g. dataset extent
         *      [ ['startDate','endDate'], ['startDate','endDate'] ],
         *      // filter date ranges e.g. only months of Jan, Feb over year
         *      [ ['startDate','endDate'], ['startDate','endDate'] ],
         * }
         * 
         * All ranges must be valid per state for the date to be selectable. 
         * A range is valid if one set of dates within the range covers the input date.
         */
        
        // loop round all the states, the dates must be valid for all states
        const dateValue = new Date(date)
        if(dateValue.toString() === 'Invalid Date') {
            return false
        }
        const allPassedTests = stateIDs.reduce((acc, stateId) => {
            if(acc === false) {
                return false
            }
            const timePeriods = dateRanges[stateId]
            for (let i = 0; i < timePeriods.length; i++) {
                const ranges = timePeriods[i]
                const foundRange = ranges.find(range => {
                    const start = new Date(range[0])
                    const end = new Date(range[1])
                    return dateValue >= start && dateValue <= end
                })
                if(foundRange !== undefined) {
                    return true
                }
            }
            return false

        },true)

        return allPassedTests
    }


    get cadence() {
        return this.cadence_
    }
    set cadence(cadence) {
        this.cadence_ = cadence
    }
    get extent_dates() {
        return this.getDateRangeExtent()
    }
    
    get available_years() {
        const startEndDates =  this.getDateRangeExtent()
        const startDate = new Date(startEndDates.start)
        const endDate = new Date(startEndDates.end)
        // end date is inclusive so plus 1
        return range(startDate.getFullYear(), endDate.getFullYear() + 1)
    }

    getDateRangeExtent() {
        const dateRanges = this.getExtraState('dateRanges')
        if(typeof dateRanges === 'object' && dateRanges !== null) {
            const stateIDs = Object.keys(dateRanges)
            return stateIDs.reduce((acc, stateId) => {
                const timePeriods = dateRanges[stateId]
                timePeriods.forEach(ranges => {
                    // always get the last range
                    const range = ranges[ranges.length - 1]
                    // if first item in range is smaller thant acc.start then set the date to the start
                    if(acc.start === null || new Date(range[0]) < new Date(acc.start)) {
                        acc.start = range[0]
                    }
                    // if first item in range is greater than acc.end then set the date to the end
                    if(acc.end === null || new Date(range[1]) > new Date(acc.end)) {
                        acc.end = range[1]
                    }
                })
                return acc
            },{
                start: null,
                end: null
            })
        }
        return {
            start: '1950-01-01',
            end: '2100-12-31'
        }
    }

    /**
     * Override meta data to include the cadence of the date variable
     * @returns 
     */
    getMetaJSON() {
        if(this.cadence === 'year') {
            return  Object.assign({}, super.getMetaJSON(), {
                cadence: this.cadence
            })
        }
        return super.getMetaJSON()
    }

    /**
     * Sets cadence of the date range variable from a saved workflow
     * @param {*} json 
     * @returns 
     */
    fromJSONSave(json) {
        super.fromJSONSave(json)
        if(json.meta.cadence !== undefined) {
            this.cadence = json.meta.cadence
        }
        return this
    }

    reactiveProps() {
        return [...super.reactiveProps(), 'cadence', 'available_years','extent_dates']
    }

    /**
     * Used to get the display type of the variable that could be used to mutate the UI
     * use `variable.display_type` within the VueJS component
     * @returns {String}
     */
    getDisplayType() {
        return 'dateRange'
    }

    /**
     * The name of the vue component to show when the user is a creator.
     * It is intented that `vue_component` will be used to dynamically load the component based on the user type
     * ```
     * <component :is="variable.vue_component" :variable="variable"></component>
     * ```
     * @returns {String}
     */
    getVueJSCreatorComponent() {
        return 'DateRangeVariableCreator'
    }

    /**
     * The name of the vue component to show when the user is a explorer
     * It is intented that `vue_component` will be used to dynamically load the component based on the user type
     * ```
     * <component :is="variable.vue_component" :variable="variable"></component>
     * ```
     * @returns {String}
     */
    getVueJSExplorerComponent() {
        return 'DateRangeVariableExplorer'
    }

    /**
     * Get the display text used in Blockly Fields
     * @returns {String}
     */
    getDisplayText() {
        return this.getTitle();
    }

    /**
     * Validate the variable to ensure it meets the requirements. Must appendError if there is an issue
     * Values stored in the class must be in “YYYY-MM-DD” format or null
     * @returns
     */
    validate() {
        super.validate()
        if (this.getValue() === null) {
            this.appendError('Choose a date for your variable','no_date');
        } else {
            const date = this.getValue()
            if (Array.isArray(date)) {
                const date1 = date[0]
                const date2 = date[1]
                const startDate = new Date(date[0])
                const endDate = new Date(date[1])

                if (!date1.match(/^\d{4}-\d{2}-\d{2}$/)) {
                    this.appendError('Start date format is incorrect. Please use the format “YYYY-MM-DD”', 'incorrect_date_format');
                } else if (startDate.toString() === 'Invalid Date') {
                    this.appendError('Start date chosen is invalid. Please choose a valid date', 'invalid_date_value');
                }

                if (!date2.match(/^\d{4}-\d{2}-\d{2}$/)) {
                    this.appendError('End date format is incorrect. Please use the format “YYYY-MM-DD”', 'incorrect_date_format');
                } else if (endDate.toString() === 'Invalid Date') {
                    this.appendError('End date chosen is invalid. Please choose a valid date', 'invalid_date_value');
                }

                if(startDate > endDate) {
                    this.appendError('Start date is greater than end date. Please choose a valid date range', 'start_greater_than_end');
                }

            } else {
                const dateValue = new Date(date)
                if (!date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                    this.appendError('Date format is incorrect. Please use the format “YYYY-MM-DD”', 'incorrect_date_format');
                } else if (dateValue.toString() === 'Invalid Date') {
                    this.appendError('Date chosen is invalid. Please choose a valid date', 'invalid_date_value');
                }

            }


        }
        return this
    }
}