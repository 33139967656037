/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import { setDropdownPreviousValue } from '@/blocks/helper_functions'
import {NO_DATA_DROPDOWN_VALUE} from '@/constants/nextGenConstants';
import {AbstractBlock, AbstractFieldHelpers} from '@/blocks/_abstractBlock';
import { ContentService } from '@/services/content.service';

const FIELD = Object.freeze({
    BAND: 'band',
    VALUE: 'value'
})

var modifierMaskJson = {
    "type": "modifier_unmask",
    "message0": `%1 %2 %3 %4 %5 %6%7 %8`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "unmask_title",
            "text": "%{BKY_MODIFIER_UNMASK_BLOCK_TITLE}",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy",
        },
        {
            "type": "field_label_serializable",
            "name": "band_label",
            "text": "%{BKY_MODIFIER_UNMASK_BAND_LABEL}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.BAND,
            "options": [["Select an option", NO_DATA_DROPDOWN_VALUE]], //These options will come from the define block
        },
        {
            "type": "input_dummy",
        },
        {
            "type": "field_label_serializable",
            "name": "value_label",
            "text": "%{BKY_MODIFIER_UNMASK_VALUE_LABEL}"
        },
        {
            "type": "field_number",
            "name": FIELD.VALUE,
            "value": "0",
            "precision": 1
        },
        {
            "type": "input_dummy",
        },

        
    ],
    
    "previousStatement": null,
    "nextStatement": null,
    "style": "secondaryFilter",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['modifier_unmask'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    onInit: function() {
        this.jsonInit(modifierMaskJson);
        this.about_block_url = ContentService.getAboutBlockUrl('modifier_unmask')
    },
    accept: async function (visitor) {
        console.log('accept modifier_unmask visitor')
        await visitor.visitModifierUnMaskBlock(this);
    },
    updateShape_: function() {
    
        if(this.hasStateChanged('bands')) {
            let bandsOptions = this.getState('bands')
            this.updateDropdownField(this, FIELD.BAND, bandsOptions)
        }
    },
    updateDropdownField(block, fieldName, fieldOptions) {
        block.getField(fieldName).updateOptions(fieldOptions)
        setDropdownPreviousValue(block, fieldName, block.getFieldValue(fieldName))
    },
    ebxValidate: function(errors) {
        this.setWarningText(null, 'not_raster')
        const isRaster = this.getState('isRaster')
        if(!isRaster) {
            this.setWarningText(errors['not_raster'] || 'This block is only compatible with raster datasets.', 'not_raster')
            return
        }
    },
};
