import {popupFactory} from "@/components/ResultMap/CustomPopups/popupFactory.js";

/**
 * Defines the abstract class for functionality for a popup in google maps.
 * @abstract
 * 
 * Popup constructor only requires a vue component.
 * Popups require access to the google and map namespaces via a register function.
 * Unregister function is required to complete any cleanup.
 */
export class AbstractPopup {

    /**
     * The component to use for the popup.
     */
    _component;

    /**
     * The google maps namespace.
     */
    _google;

    /**
     * The map object.
     */
    _map;

    /**
     * The popup instance.
     */
    _popup = null;

    constructor(component) {
        this._component = component;
    }

    /**
     * Registers the google and map namespaces.
     * @param {*} google 
     * @param {*} map 
     */
    register(google, map) {
        this._google = google;
        this._map = map;
    }

    /**
     * Unregisters the google and map namespaces.
     */
    unregister() {
        this._google = null;
        this._map = null;
        this._component = null;
    }

    /**
     * Renders the popup on the map at a given position.
     * @param {*} position - either a google maps lat long or an array of lat and long.
     * @param {*} popupProps - the props to supply to the popup component, optional.
     */
    render(position, popupProps) {
        if (Array.isArray(position)) {
            position = new this._google.maps.LatLng(position[0], position[1]);
        }

        if ([undefined, null].includes(popupProps)) {
            popupProps = {};
        }

        this._popup = popupFactory(this._google, position, this._component, popupProps);
        this._popup.setMap(this._map);
    }

    /**
     * Hides the popup.
     */
    hide() {
        if (this._popup === null) {
            return;
        }

        this._popup.setMap(null);
        this._popup = null;
    }

    /**
     * Allows you to set the popup properties.
     */
    setPopupProps(props) {
        if ([undefined, null].includes(props)) {
            throw new Error("Props cannot be null or undefined");
        }

        if (this._popup === null) {
            throw new Error("Popup must be rendered before setting props");
        }

        this._popup.setProps(props);
    }

    /**
     * Handles map events, can be implemented by subclasses.
     * Called externally from the popup mixin such that the popup can respond to events that may not be called directly from the map.
     * See GoogleMapDrawing for an example of this.
     * Events are stored such that:
     * {
     *  type: string,
     *  event: any
     * }
     */
    handleMapEvent(event) {
        // to be implemented by subclasses
        console.warn("ignoring event", event.type);
    }
}