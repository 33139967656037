/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import  { NO_DATA_DROPDOWN_VALUE, } from '@/constants/nextGenConstants';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock';
import { ContentService } from '@/services/content.service';

const FIELD = Object.freeze({
    CATEGORY: "ind_category",
    INDEX: "ind_options"
})

const INPUT = Object.freeze({
    INDEX: "ind_input"
})

var indicesJson = {
    "type": "analysis_indices",
    "message0": `%1 %2 %3 %4 %5`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "indices_title",
            "text": "%{BKY_ANALYSIS_INDICES_BLOCK_TITLE} ", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "indices_label",
            "text": "%{BKY_ANALYSIS_INDICES_CATEGORY_LABEL}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.CATEGORY,
            "options": [
                ["Select an option", NO_DATA_DROPDOWN_VALUE],
            ]
        },
        {
            "type": "input_dummy"
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "calculate",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['analysis_indices'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,

    FIELD: FIELD,
    INPUT: INPUT,

    onInit: function() {
        this.jsonInit(indicesJson);

        let categoriesField = this.getField(this.FIELD.CATEGORY);
        categoriesField.setValidator(this.indexCategoryValidator);

        this.about_block_url = ContentService.getAboutBlockUrl('analysis_indices')
    },

    accept: async function (visitor) {
        await visitor.visitIndicesBlock(this);
    },

    indexCategoryValidator: function(newValue) {
        const block = this.getSourceBlock();

        block.setState(block.FIELD.CATEGORY, newValue);
        console.info("Updated category!", newValue);
        // block.updateShapeOnChange()
        
        return newValue;
    },

    indexValueValidator: function(newValue) {
        const block = this.getSourceBlock();

        block.setState(block.FIELD.INDEX, newValue);

        return newValue;
    },

    onSaveExtraState: function(extraState) {
        // only need to save options as value is already saved by the validator
        const categoryValue = this.getFieldValue(this.FIELD.CATEGORY);
        const categoryOptions = this.getField(this.FIELD.CATEGORY)
                                    .getOptions()
                                    .filter(o => o[1] === categoryValue);

        if (this.fieldExists(this.FIELD.INDEX)) {
            const indexValue = this.getFieldValue(this.FIELD.INDEX);
            const indexOptions = this.getField(this.FIELD.INDEX)
                                        .getOptions()
                                        .filter(o => o[1] === indexValue);

            extraState[this.FIELD.INDEX + "_options"] = indexOptions;
        }

        extraState[this.FIELD.CATEGORY + "_options"] = categoryOptions;
        
        return extraState;
    },

    updateShape_: function() {
        this.updateCategoriesField_();
        this.updateIndexField_();
    },

    updateCategoriesField_: function() {
        if (!this.hasStateChanged(this.FIELD.CATEGORY+"_options")) {
            return;
        }

        console.info("Updating categories field with options")
        const categoriesField = this.getField(this.FIELD.CATEGORY);
        const categoryOptions = this.getState(this.FIELD.CATEGORY + "_options");
        categoriesField.updateOptions(categoryOptions);
    },

    updateIndexField_: function() {
        if (!this.hasStateChanged(this.FIELD.CATEGORY)) {
            return;
        }

        const indexCategory = this.getState(this.FIELD.CATEGORY, NO_DATA_DROPDOWN_VALUE)

        if (indexCategory === NO_DATA_DROPDOWN_VALUE) {
            this.removeInputIfExists(this.INPUT.INDEX)
            return;
        }

        const indexCategoryContent = this.getState('category_content')

        if(!indexCategoryContent) {
            return;
        }

        const indicesDropdown = indexCategoryContent[indexCategory];
        console.log("indicesDropdown", indicesDropdown)

        // check if field already exists and update options
        if (this.fieldExists(this.FIELD.INDEX)) {
            this.updateOptionsIfSet(this.FIELD.INDEX, indicesDropdown)
            return;
        }

        if (!this.inputExists(this.INPUT.INDEX)) {
            this.appendDummyInput(this.INPUT.INDEX)
        }

        let indexField = this.createDropdownWithValue(indicesDropdown, indicesDropdown[0][1]);
        indexField.setValidator(this.indexValueValidator);

        let indexLabel = new Blockly.FieldLabelSerializable(Blockly.Msg.ANALYSIS_INDICES_INDEX_LABEL);

        this.getInput(this.INPUT.INDEX).appendField(indexLabel).appendField(indexField, this.FIELD.INDEX)
        this.triggerValidator(this.FIELD.INDEX)
    },
    
    ebxValidate: function(errors) {
        this.setWarningText(null, 'not_raster')
        const isRaster = this.getState('isRaster')
        if(!isRaster) {
            this.setWarningText(errors['not_raster'] || 'This block is only compatible with raster datasets.', 'not_raster')
            return
        }
        
        const cat = this.getFieldValue(this.FIELD.CATEGORY)
        if (!cat || cat === NO_DATA_DROPDOWN_VALUE) {
            if (errors) {
                const warning = errors['no_options_error']
                this.setWarningText(warning, "no_options_error");
                return
            }
        }
        this.setWarningText(null, "no_options_error");
    },
    
}