<template>
    <EbxInlineInput 
        v-model="value" 
        :disabled="disabled"
        :id="generatedId"
        :input-style-classes="['ebx-primary-1']"
        :input-edit-style-classes="['color-box']"
        :input-validation="inlineValidation('color','Color')"
        :update-value="false"
        :disable-blur="true"
        :input-placeholder="inputPlaceholder"
        input-type="color"
        edit-actions-position="right"
        @save="handleIndividualSave"
    >
        <template v-slot:view-value="slotProp">
            <div class="color-box" :style="{ backgroundColor: value }">&nbsp;</div>
            <span>{{ slotProp.value.toUpperCase() }}</span>
        </template>
        <template v-slot:after-input="slotProp">
            <span @click="handleClickingHexColour(slotProp)">{{ slotProp.newValue.toUpperCase() }}</span>
        </template>
    </EbxInlineInput>   
</template>

<script>
import EbxInlineInput from "./EbxInlineInput.vue";
import valueMixin from '@/components/mixins/valueMixin';

export default {
    components: {
        EbxInlineInput
    },
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: null
        },
        inputPlaceholder: {
            type: String,
            default: 'Click to pick color'
        },
    },
    emits: [
        'update:modelValue',
    ],
    mixins: [valueMixin],
    computed: {
        generatedId() {
            return this.id ? this.id : 'band_' + Math.random().toString(36).substr(2, 9);
        }
    },
    methods: {
        handleIndividualSave(value) {
            this.$emit('update:valueMixin',value)
        },
        handleClickingHexColour(slotProp) {
            slotProp.enterEditMode();
            slotProp.focusInput()
        },
        inlineValidation(property, niceName = null) {
            return (newValue) => {
                return {
                    error: `${niceName || property} is required`,
                    active: newValue === null || newValue.length === 0
                }
            }
        },
    }
}
</script>