/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["MODIFIER_TIME_PERIOD_BLOCK_TITLE"] = "Select time period";
Blockly.Msg["MODIFIER_TIME_PERIOD_FROM_LABEL"] = "From: ";
Blockly.Msg["MODIFIER_TIME_PERIOD_TO_LABEL"] = " To: ";

export default Blockly.Msg;