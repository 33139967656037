
import * as Blockly from 'blockly/core';
import {FieldModal} from './FieldModal';

const dom = Blockly.utils.dom;
const Size = Blockly.utils.Size

const DEFAULT_TEXT = 'Search datasets';
const DEFAULT_HEIGHT = 50;

const SVG = `<svg width="200" height="36" viewBox="0 0 151 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect rx="4" width="200" height="42" fill="white"/>
<path transform="scale(1.6)" d="M18.0057 13.805H17.3734L17.1492 13.5889C17.9337 12.6764 18.4059 11.4917 18.4059 10.203C18.4059 7.32933 16.0766 5 13.203 5C10.3293 5 8 7.32933 8 10.203C8 13.0766 10.3293 15.4059 13.203 15.4059C14.4917 15.4059 15.6764 14.9337 16.5889 14.1492L16.805 14.3734V15.0057L20.8073 19L22 17.8073L18.0057 13.805ZM13.203 13.805C11.2098 13.805 9.60091 12.1961 9.60091 10.203C9.60091 8.20983 11.2098 6.60091 13.203 6.60091C15.1961 6.60091 16.805 8.20983 16.805 10.203C16.805 12.1961 15.1961 13.805 13.203 13.805Z" fill="#505050"/>
<text x="42" y="26" fill="#505050" font-size="16">Search datasets</text>
</svg>
`

/**
 * An implementation of the FieldImage class which specifically doesn't use the
 * value_ property to allow inherited classes to use setValue without breaking the 
 * image functionality. This is useful whenever you want to create a button, but
 * set a hidden field value.
 * 
 * Overrides the doClassValidation_ and doValueUpdate_ methods to prevent the value_ property from being set.
 * Implementations should override these methods.
 */
class FieldDatasetChoose extends FieldModal {
    
    /**
     * Create the block UI for this image.
     * @override
     */
    initView() {

        const elm = document.createElementNS('http://www.w3.org/2000/svg', 'dataset')
        elm.innerHTML = SVG

        this.textElement_ = Blockly.utils.dom.createSvgElement(Blockly.utils.Svg.G, {
            'class': 'datasetSearchField'
        },this.fieldGroup_);
        this.textElement_.innerHTML = elm.firstChild.innerHTML
        this.ebxRect = this.textElement_.firstElementChild;
        this.ebxText = Array.prototype.slice.call(this.textElement_.children)[2];
        this.staticWidth = 200
        this.size_ = new Size( this.staticWidth, DEFAULT_HEIGHT);
        if(!this.searchText) {
            this.searchText = DEFAULT_TEXT
        }
    
        if (this.clickHandler_) {
            this.textElement_.style.cursor = 'pointer';
        }

    } 

    setText(text) {
        this.searchText = text
        this.updateTextElementText_()
        return this
    }

    render_() {
        this.updateTextElementText_()
        super.render_()
    }

    updateTextElementText_() {
        if(this.textElement_){
            if(this.searchText === DEFAULT_TEXT) {
                this.textElement_.classList.remove('datasetSelected')
                this.size_.height = DEFAULT_HEIGHT
            } else {
                this.textElement_.classList.add('datasetSelected')
                this.size_.height = DEFAULT_HEIGHT - 20
            }
        }
        if(this.ebxText) {
            this.ebxText.textContent = this.searchText
            this.updateSize_(0)
        }
    }

    updateSize_(opt_margin) {
        const constants = this.getConstants();
        const xOffset = opt_margin !== undefined ?
            opt_margin :
            (this.borderRect_ ? this.getConstants().FIELD_BORDER_RECT_X_PADDING :
                                0);
        let totalWidth = xOffset * 2;
    
        let contentWidth = 0;
        if (this.textElement_) {
            const textWidth = dom.getFastTextWidth(this.ebxText, constants.FIELD_TEXT_FONTSIZE, constants.FIELD_TEXT_FONTWEIGHT, constants.FIELD_TEXT_FONTFAMILY) + 72;
            if(textWidth > this.staticWidth) {
                contentWidth = textWidth
            } else {
                contentWidth = this.staticWidth
            }
            totalWidth += contentWidth;
        }

        this.size_.width = totalWidth;
        this.ebxRect.setAttribute('width', this.size_.width)
        
    }
}

export {FieldDatasetChoose};