import { v4 as uuidv4 } from "uuid";
import { MAP_LAYER_TYPE } from '../../../constants/nextGenConstants.js'

const state = {
    mapLayers: [],
    basemapLayers: [],
    assetLayers: [],
    selectedLayer: {},
    legends: [],
    drawingMode: {
        enabled: false,
        lastCreatedArea: null,
        mode: null
    },
    exportAreaId: null,
};

const getters = {
    getMapLayers: state => {
        return state.mapLayers;
    },
    getMapLayerGroup: (state) => (layerId) => {
        return state.mapLayers.find(layer => layer.id === layerId)
    },
    getSelectedLayer: state => {
        return state.selectedLayer
    },
    getLegendForMapId: state => mapId => {
        const idx = state.legends.findIndex(l => l.id === mapId)
        if(idx >=0) {
            return state.legends[idx]
        }
        return null
    },
    getOrderedMapLayers: state => {
        return [].concat(state.basemapLayers, state.mapLayers, state.assetLayers)
    }
};

const mutations = {
    setMapLayers(state, mapLayers) {
        state.mapLayers = mapLayers.map(layer => {
            layer.type = MAP_LAYER_TYPE.RESULT
            return layer
        });
    },
    setSelectedLayer(state, selectedLayer) {
        state.selectedLayer = selectedLayer
    },
    setMapLayer(state, mapLayer) {
        mapLayer.type = MAP_LAYER_TYPE.RESULT
        state.mapLayers.push(mapLayer)
    },
    setBasemapLayer(state, layer) {
        // There is always just one basemap layer
        layer.type = MAP_LAYER_TYPE.BASEMAP
        state.basemapLayers = [layer]
    },
    appendAssetLayer(state, layer) {
        layer.type = MAP_LAYER_TYPE.ASSET
        state.assetLayers.push(layer)
    },
    updateLayer(state, updateObj) { 
        const layerIdx = state.mapLayers.findIndex(layer => layer.id === updateObj.id)
        state.mapLayers[layerIdx] = Object.assign(state.mapLayers[layerIdx], updateObj)
    },
    updateSelectedLayer(state, updateObj) {
        state.selectedLayer[updateObj.id] = updateObj.layerIdx
    },
    reverseLayers(state) {
        return state.mapLayers.slice().reverse();
    },
    removeLayer(state, layerId) {
        state.mapLayers = state.mapLayers.filter(layer => layer.id !== layerId)
    },
    removeAssetLayer(state, layerId) {
        state.assetLayers = state.assetLayers.filter(addedLayers => addedLayers.id !== layerId)
    },
    setLegends(state, legends) {
        state.legends = legends
    },
    removeLegendById(state, id) {
        state.legends = state.legends.filter(legend => legend.id !== id)
    },
    clearBasemapLayers(state) {
        state.basemapLayers = []
    },
    clearAllMapLayers(state) {
        state.mapLayers = []
        state.assetLayers = []
    },
    clearWorkflowMapLayers(state) {
        state.mapLayers = []
    },
    clearAssetMapLayers(state) {
        state.assetLayers = []
    },
    clearLegends(state) {
        state.legends = []
    },
    setDrawingModeEnabled(state, drawingModeEnabled) {
        state.drawingMode.enabled = drawingModeEnabled === true
    },
    setDrawingModeMode(state, mode) {
        state.drawingMode.mode = mode
    },
    setLastCreatedArea(state, lastCreatedArea) {
        state.drawingMode.lastCreatedArea = lastCreatedArea
    },
    exportPolygonsForAreaId(state, exportAreaId) {
        state.exportAreaId = exportAreaId
    }
};

const actions = {
    constructMapLayers(context, runResult) {
        if (runResult) {
            context.commit('setSelectedLayer', {})
            const layers = runResult.layers
            const mapLayers = layers.map((layer) => {
                const mapUUID = uuidv4()
                let groups = layer.groups || []
                if (groups.length) {
                    groups.map((groupLayer, index) => {
                        groupLayer.displayLayer = index === 0 
                        return layer
                    })
                }
                let mapLayer = {
                    id: mapUUID,
                    blockName: runResult.name,
                    country: runResult.country,
                    layers: groups,
                    bbox: layer.bbox,
                    isGroup: true,
                    opacity: layer.opacity,
                    name: layer.name, 
                    description: layer.description,
                    originalId: layer.id,
                    originalType: layer.type,
                };
                context.commit('updateSelectedLayer', {
                    id: mapLayer.id,
                    layerIdx: 0
                });
                if (groups.length > 1) {
                    // timeseries layer
                    mapLayer.isGroup = true
                } else {
                    mapLayer.isGroup = false
                }

                let legend = null
                if(layer.legend && layer.legend.type) {
                    legend = {
                        id: mapUUID,
                        layerName: layer.name,
                        type: layer.legend.type,
                        values: layer.legend.values
                    }
                }
                
                return [mapLayer, legend]
            })
            context.commit('setMapLayers', mapLayers.map(layer =>  layer[0]).filter(mapLayer => mapLayer !== null))
            context.commit('setLegends', mapLayers.map(layer =>  layer[1]).filter(legend => legend !== null))
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};