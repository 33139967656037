<template>
    <div class="ebx-templates">
        <div class=workflow-search v-if="!loading && !searchHidden">
            <EbxSearchBar 
            v-model="form" 
            :filter-options="filterOptions" 
            class="filter-wide"/>
        </div>
        <div class="tag-chip-container" ref="chips" v-if="!loading && !tagsHidden && !isFilteringTeamTemplates">
            <md-chip v-for="(tag, index) in templateTags" :key="index"  :class="{ 'md-chip': true, 'md-clicked': tag.clicked }"  md-clickable @click="selectedChip(index)">{{ tag.name }}</md-chip>
        </div>
        <div class="ebx-templates--content" v-if="filteredTemplates.length > 0">
            <div class="md-layout md-gutter">
                <div 
                    v-for="template in filteredTemplates" 
                    :key="template.id"
                    class="ebx-template-card md-layout-item md-size-25 md-small-size-33 md-xsmall-size-100">
                    
                    <EbxSimpleCard 
                        :imgSrc="template.thumbnail" 
                        :text="template.name"
                        :show-left-action="true"
                        :show-right-action="true"
                        placeholder-type="globe"
                        @action-left-click="() => templateOpen(template.id)"
                        @action-right-click="() => templateDetails(template.id)"
                    />
                </div>
            </div>
        </div>
        <div class="workflow-tab--empty" v-else-if="!loading && !isSubsetTemplates">
            <div class="ebx-icon">
                <img v-if="form.filterBy=='team_published'" :src="assets.icons.emptyStateFolder" alt="No templates">
                <img v-else :src="assets.icons.emptyStateAssets" alt="No templates">
            </div>
            <p v-if="form.filterBy=='team_published'" class="ebx-primary">Your team doesn’t have any templates yet</p>
            <p v-else class="ebx-primary">We couldn't find any templates matching this search</p>
            
        </div>

    </div>
</template>

<script>
import EbxSimpleCard from '@/components/EbxComponents/EbxSimpleCard.vue'
import EbxSearchBar from '../../components/EbxComponents/EbxSearchBar.vue';
import authMixin from "@/components/mixins/authMixin";
import packageMixin from "@/components/mixins/packageMixin";
import { matchSorter } from 'match-sorter';
import {PROJECT_MODE} from '@/components/mixins/projectMixin';
import { isNotNullOrUndefined } from '@/helpers/generalHelperFunctions.js'
import valueMixin from '@/components/mixins/valueMixin';
import assetsMixin from '@/components/mixins/assetsMixin.js'

export default {
    name: 'TemplateWorkflows',
    mixins: [authMixin, packageMixin, valueMixin, assetsMixin],
    components: {
        EbxSimpleCard,
        EbxSearchBar
    },
    props: {
        modelValue: {
            type: Boolean,
            required: false,
            default: false
        },
        searchHidden: {
            type: Boolean,
            required: false,
            default: false
        },
        tagsHidden: { 
            type:Boolean,
            required: false, 
            default: false
        },
        packageWorkflowIds: {
            type: Array,
            required: false,
            default: () => []
        },
        packageTitle: {
            type: String,
            required: false,
            default: () => ''
        },
        isSubsetTemplates: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            templateTags: [],
            form: {
                searchInput: null,
                filterBy: 'all'
            }, 
            projectOrigin: 'Projects', 
            clicked: false,
            selectedTags: [],
        }
    },
    computed: {
        isFilteringTeamTemplates() {
            return this.form.filterBy === PROJECT_MODE.ORG_TEMPLATE;
        },
        filteredTemplates() {
            let tagsArray = this.selectedTags.map(obj => obj.name);
            let templateList = []
            const filteredTemplates = this.templates.filter(template => {
                if(this.form.filterBy === 'all') return true;
                return template.mode === this.form.filterBy;
            });

            if (this.form.searchInput !== "" && this.form.searchInput !== null) { 
                templateList =  matchSorter(filteredTemplates, this.form.searchInput, { keys: ['name'], threshold: matchSorter.rankings.CONTAINS })
            } else if (this.packageWorkflowIds && this.packageWorkflowIds.length > 0 ) {
                templateList = this.filteredPackageWorkflows(this.packageWorkflowIds);
            } else if (this.isSubsetTemplates) {
                templateList = []
            } else {
                templateList = filteredTemplates
            }
            if (tagsArray.length > 0 ) {
                templateList = templateList.filter(template => template.tags && tagsArray.every(tag => template.tags.includes(tag)));
            }
            templateList.sort((a, b) => a.name.localeCompare(b.name)); 
            return templateList

        },
        filterOptions() {
            if(this.userVariablesEnabled === false) {
                return null
            }
            return [
                {
                    value: 'all',
                    label: 'All templates'
                },
                {
                    value: PROJECT_MODE.ORG_TEMPLATE,
                    label: 'Team templates'
                },
                {
                    value: PROJECT_MODE.GLOBAL_TEMPLATE,
                    label: 'Earth Blox templates'
                }
            ]
        },
        loading: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        templates() {
            return [].concat(this.teamProjects || [], this.globalProjects || []);
        }
    },
    async mounted() {
        await Promise.all([this.getTemplates(),this.getTemplateTags()])
    },
    methods: {
        filteredPackageWorkflows(ids) {
            return this.templates.filter(template => ids.includes(template.id));
        },
        templateDetails(id) {
            const workflow = this.templates.find(template => template.id === id);
            this.$store.commit('router/setPageState', {
                    workflow: workflow,
                    packageTitle: this.packageTitle,
                    origin: this.projectOrigin
            })
            this.$router.push( {
                name:'Template',
                params: {
                    workflowId: workflow.id,
                } 
            })
        },
        templateOpen(id) {
            const workflow = this.templates.find(template => template.id === id);
            const workflowPayload = {
                workflow: workflow, 
                projectMode: workflow.mode,
                projectToBeLoaded: true
            }
            this.$store.commit('project/setWorkflowAndMode', workflowPayload)
            this.$router.push( {
                name:'Workflow',
                params: {
                    workflowId: workflow.id
                }
            })
        },
        async getTemplates() {
            this.loading = true;
            if(this.userVariablesEnabled){
                // call vuex action
                await Promise.all([this.getGlobalProjects(),this.getTeamProjects(this.user.orgId)]);
            }else {
                await this.getGlobalProjects();
            }
            this.loading = false;
        }, 
        async getTemplateTags() { 
            await this.getGlobalProjectsTags(); 
            const tags = this.globalProjectsTags;

            tags.forEach(tag => this.templateTags.push({"name": tag, "clicked": false}))
        
        }, 
        selectedChip(index) { 
            this.$refs.chips.focus();
            const tag = this.templateTags[index];
            tag.clicked = !tag.clicked;

            if (tag.clicked) {
                this.selectedTags.push(tag);
            } else {
               this.selectedTags = this.selectedTags.filter(tag => tag.clicked);
            }
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (isNotNullOrUndefined(to)) {
                    const pageState = this.$store.state.router.pageState
                    if (pageState && pageState.origin === "Projects") {
                        this.projectOrigin = 'Projects'
                    } else {
                        this.projectOrigin = ''
                    }
                } 
            }
        }
    }
}
</script>