
import {FieldNoTrimDropdown} from './FieldNoTrimDropdown';
import { VariablesService } from '../services/variable.service';
import { sharedVariableMethodsMixin } from './sharedVariableMethodsMixin';

/**
 * Class for an editable multi section dropdown field.
 * @extends {FieldNoTrimDropdown}
 * @alias Blockly.FieldNoTrimDropdown
 */
class FieldVariableDropdown extends FieldNoTrimDropdown {

    constructor(menuGenerator, validator, config) {
        super(menuGenerator, validator, config)
    }

    /**
     * Updates the dropdown options
     * @param {*} options 
     * @param {*} newValue 
     */
    updateOptions(options, newValue = null) {
        super.updateOptions(options, newValue)
    }

    setValue(newValue) {
        let nonDeletedItems = null
        const generatedOptions = this.generatedOptions_ || this.menuGenerator_;
        if(VariablesService.getDeletedVariableById(this.getValue()) !== undefined && Array.isArray(generatedOptions)) {
            nonDeletedItems = generatedOptions.filter(o => o[1] !== this.getValue())
        }
       
        const result = super.setValue(newValue);
        if(nonDeletedItems) {
            this.updateOptions(nonDeletedItems, newValue)
        }
        return result;
    }
    
    /**
     * If a variable is selected, return the display text of the variable, otherwise return the value.
     * @param {*} newValue 
     * @returns 
     */
    getEditorText_(newValue) {
        if(this.isVariable()) {
            return this.getVariable().getDisplayText()
        }
        return super.getText_(newValue);
    }
    
    getText_() {
        const newValue = this.getValue();
        return this.getEditorText_(newValue);
    }
}

Object.assign(FieldVariableDropdown.prototype, sharedVariableMethodsMixin);

export {FieldVariableDropdown};