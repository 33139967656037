import {AbstractAbility} from "./abstractAbility"
import {AbstractCollection} from "./collections/AbstractCollection"

class ProvidesDatasetType extends AbstractAbility {
    constructor(state) {
        super(state)
        this.stateKey = 'datasetType'
    }
    /**
     * Get the type of the dataset that is being used
     * @returns {string} 'raster' or 'vector'
     */
    getType() {
        return this.getState().type || 'raster'
    }

    static newCollection(abilites) {
        return new AbstractCollection(abilites)
    }
}

export {ProvidesDatasetType}