/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
/**
 * Mixin to share methods and data between variable versions of blockly fields.
 */
import { VariablesService } from '../services/variable.service';

export const sharedVariableMethodsMixin = {
    /**
     * Checks if the value is a variable
     * @returns {Boolean}
     */
    isVariable(includeDeleted = true) {
        if(includeDeleted && VariablesService.getDeletedVariableById(this.getValue()) !== undefined) {
            return true
        }
        return VariablesService.getVariableById(this.getValue()) !== undefined
    },

    /**
     * gets the variable from the variable service
     * @returns {Varible}
     */
    getVariable(includeDeleted = true) {
        if(this.isVariable(includeDeleted)) {
            if (includeDeleted && VariablesService.getDeletedVariableById(this.getValue()) !== undefined) {
                return VariablesService.getDeletedVariableById(this.getValue());
            }
            return VariablesService.getVariableById(this.getValue());
        }
        return null
    },

    /**
     * gets the variable value or current value of the field if variable is not selected
     * @returns 
     */
    getVariableValue(fallbackToValue = true) {
        const fallback = fallbackToValue ? this.getValue() : null;
        return this.isVariable() ? this.getVariable().getValue() : fallback
    }
}