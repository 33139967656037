<template>
    <dialog id="dialogTag" ref="dialogTag" v-show="showDialog" class="ebx-dialog" :class="dialogClasses">
        <div class="ebx-dialog--content">
            <h2 class="ebx-header-2 ebx-dialog--title" v-if="dialogContent.title">{{dialogContent.title}}</h2>
            <p v-if="dialogContent.htmlBody" v-html="dialogContent.htmlBody" class="ebx-primary ebx-dialog--body"></p>
            <p v-else class="ebx-primary ebx-dialog--body">{{dialogContent.body}}</p>
        </div>
        <div v-if="action" class="ebx-dialog--actions">
            <button v-show="cancellable" class="ebx-button ebx-button--secondary ebx-dialog--actions__cancel" @click="handleCancel">Cancel</button>
            <form v-if="!linkDialog.isLink && linkDialog.linkTarget" :action="linkDialog.linkTarget">
                <input type="hidden" name="prefilled_email" :value="linkDialog.linkEmail"/>
                <input value="ADD PAYMENT DETAILS" type="submit" class="ebx-button ebx-button--primary" />
            </form>
            <a v-else-if="linkDialog.isLink" class="ebx-button ebx-button--primary" :href="linkDialog.linkTarget">{{linkDialog.linkText}}</a>
        </div>
    </dialog>
</template>

<script>
export default {
    name: 'EbxDialog',
    props: {
        action: {
            type: Boolean,
            required: false,
            default: true
        },
        dialogClasses: {
            type: Array,
            required: false,
            default: () => ([])
        },
        showEbxDialog: {
            type: Boolean,
            required: true,
            default: false
        },
        cancellable: {
            type: Boolean,
            required: false,
            default: true
        },
        linkDialog: {
            type: Object,
            required: false,
            default () {
                return {
                    isLink: false,
                    linkText: 'Click here to confirm'
                }
            }
        },
        dialogContent: {
            type: Object,
            required: false,
            default () {
                return {
                    title: '',
                    body: '',
                    htmlBody: null
                }
            }
        },
    },
    data() {
        return {
            dialogTag: null
        }
    },
    computed: {
        showDialog() {
            if (!this.dialogTag) {
                return false
            }
            this.showEbxDialog ? this.dialogTag.showModal() : this.dialogTag.close()
            return this.showEbxDialog
        }
    },
    methods: {
        handleCancel() {
            this.$refs.dialogTag.close()
        }
    },
    mounted() {
        this.dialogTag = this.$refs.dialogTag
    }
}
</script>