<template>
    <div class="layersContainer">

        <template v-if="isResultLayer">
            <GoogleMapLayer
                v-for="(layer, index) in mapLayer.layers"
                :key="`${layer.mapid}-${mapLayer.mapLayerID}-${indexKey}`"
                :google="google"
                :map="map"
                :map-id="layer.mapid"
                :display-layer="selectedLayer === index"
                :layerID="mapLayer.id"
                :opacity="mapLayer.opacity"
                :isMapMoving="isMapMoving"
                :layerIndex="index"
                :type="mapLayer.type"
                @tiles-loading="handleLoadingTiles(index, $event)"
            />
        </template>
        <template v-else>
            <GoogleMapLayer
                :key="`${mapLayer.id}-${indexKey}`"
                :google="google"
                :map="map"
                :map-id="mapLayer.id"
                :display-layer="true"
                :layerID="mapLayer.id"
                :opacity="100"
                :isMapMoving="isMapMoving"
                :layerIndex="0"
                :type="mapLayer.type"
                :created-layer="mapLayer"
            />
        </template>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import GoogleMapLayer from "@/components/ResultMap/GoogleMapLayer.vue";
import { MAP_LAYER_TYPE } from "../../constants/nextGenConstants";

export default {
    name: "GoogleMapLayerContainer",
    components: {
        GoogleMapLayer
    },
    props: {
        google: {
            type: Object,
            required: true
        },
        map: {
            type: Object,
            required: true
        },
        mapLayer: {
            type: Object,
            required: true
        },
        indexKey: {
            type: String,
            required: true
        }
    },
    emits: [
        'tiles-loading'
    ],
    data() {
        return {
            isMapMoving: false,
            loadingLayers: [],
        }
    },
    computed: {
        selectedLayers() {
            return this.$store.getters['maplayers/getSelectedLayer']
        },
        selectedLayer() {
            return this.selectedLayers[this.mapLayer.id]
        },
        isResultLayer() {
            return this.mapLayer.type === MAP_LAYER_TYPE.RESULT
        }
    },
    mounted() {
        // adds event listeners for when the map is moving
        this.zoom = this.map.getZoom();
        this.map.addListener('zoom_changed', () => {
            let currentZoom = this.map.getZoom()
            if(this.zoom !== currentZoom) {
                this.isMapMoving = true;
                this.zoom = currentZoom
            }
        })
        this.map.addListener('drag', () => {
            this.isMapMoving = true;
        })
        this.map.addListener('idle', () => {
            this.isMapMoving = false;
        })
    },
    watch: {
        mapLayer(mapLayer) {
            this.loadingLayers = mapLayer.layers.map(() => false)
        },
        loadingLayers: {
            handler: function(loadingLayers) {
                if (loadingLayers.filter(loading => loading).length > 0) {
                    this.$emit('tiles-loading', true)
                } else {
                    this.$emit('tiles-loading', false)
                }
            },
            deep: true 
        },
    },
    methods: {
        handleLoadingTiles(index, loading) {
            this.loadingLayers[index] = loading
        }
    }
}
</script>

<style scoped>
.layersContainer {
    display: none;
}
</style>