/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["OUTPUT_SAVE_DATASET_LABEL"] = "Save data for re-use  ";
Blockly.Msg["OUTPUT_SAVE_DATASET_NAME_LABEL"] = "Name";


export default Blockly.Msg;