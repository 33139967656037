<template>
    <div ref="addArea" class="add-area">
        <md-menu class="add-area--container" :md-offset-x="170" :md-offset-y="-36">
            <div class="md-menu-trigger add-area--action" md-menu-trigger>
                <md-icon class="add-area--icon">add</md-icon>
                <span class="ebx-action-text">Add area</span>
            </div>
            <md-menu-content class="add-area-menu">
                <md-menu-item class="add-area-menu--option" @click="$emit('add-new-area', null, collectionId, true)">
                    <span class="material-icons-outlined ebx-icon">edit</span>
                    <span>Draw area</span>
                </md-menu-item>
                <md-menu-item class="add-area-menu--option" @click="$emit('upload-polygon-to-collection', collectionId, true)">
                    <span class="material-icons-outlined ebx-icon">file_upload</span>
                    <span>Upload new feature</span>
                </md-menu-item>
                <md-menu-item class="add-area-menu--option" @click="$emit('choose-area', collectionId)">
                    <span class="material-icons-outlined ebx-icon">folder</span>
                    <span>Choose from Assets</span>
                </md-menu-item>
            </md-menu-content>
        </md-menu>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/

export default {
    props: {
        collectionId: {
            type: String,
            required: true
        }
    },
    emits: [
        'add-new-area',
        'upload-polygon-to-collection',
        'choose-area'
    ]
};
</script>