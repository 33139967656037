/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */


// ----------------------------------SATELLITE DATA-----------------------------------------------

/*
* Object structure
* - satellite
* 	- from: start of satellite operation date
* 	- to: end date of satellite mission
    - scale
    - collectionID
* 	- bands: band designations
*			  - r
* 			- g
*		    - b
*			  - nir...
*  	- functions:
*/

import { workspace, getChildrenInside } from 'blockly/core';
import { DatasetService } from '@/services/dataset.service';

let dataset = {
    l8: { from: '2013-04-11', to: getToday(0, 5), name: 'Landsat 8', scale: 30, collectionID: 'LANDSAT/LC08/C01/T1_TOA', rawCollectionID: 'LANDSAT/LC08/C01/T1', SRcollectionID: 'LANDSAT/LC08/C01/T1_SR', bands: { aerosols: 'B1', b: 'B2', g: 'B3', r: 'B4', nir: 'B5', swir_1: 'B6', swir_2: 'B7', pan: 'B8', cirrus: 'B9', thermal_ir_1: 'B10', thermal_ir_2: 'B11', bitmask: 'BQA' } },
    l7: { from: '1999-01-01', to: getToday(0, 28), name: 'Landsat 7', scale: 30, collectionID: 'LANDSAT/LE07/C01/T1_TOA', rawCollectionID: 'LANDSAT/LE07/C01/T1', SRcollectionID: 'LANDSAT/LE07/C01/T1_SR', bands: { b: 'B1', g: 'B2', r: 'B3', nir: 'B4', swir_1: 'B5', thermal_ir_1: 'B6_VCID_1', thermal_ir_2: 'B6_VCID_2', swir_2: 'B7', pan: 'B8', bitmask: 'BQA' } },
    l5: { from: '1984-01-01', to: '2012-05-05', name: 'Landsat 5', scale: 30, collectionID: 'LANDSAT/LT05/C01/T1_TOA', rawCollectionID: 'LANDSAT/LT05/C01/T1', SRcollectionID: 'LANDSAT/LT05/C01/T1_SR', bands: { b: 'B1', g: 'B2', r: 'B3', nir: 'B4', swir_1: 'B5', thermal_ir_1: 'B6', swir_2: 'B7', bitmask: 'BQA' } },
    l4: { from: '1982-09-01', to: '1993-12-14', name: 'Landsat 4', scale: 30, collectionID: 'LANDSAT/LT04/C01/T1_TOA', rawCollectionID: 'LANDSAT/LT04/C01/T1', SRcollectionID: 'LANDSAT/LT04/C01/T1_SR', bands: { b: 'B1', g: 'B2', r: 'B3', nir: 'B4', swir_1: 'B5', thermal_ir_1: 'B6', swir_2: 'B7', bitmask: 'BQA' } },
    s2: { from: '2015-06-23', to: getToday(0, 2), name: 'Sentinel-2', scale: 10, collectionID: 'COPERNICUS/S2', SRcollectionID: 'COPERNICUS/S2_SR', bands: { aerosols: 'B1', b: 'B2', g: 'B3', r: 'B4', red_edge_1: 'B5', red_edge_2: 'B6', red_edge_3: 'B7', nir: 'B8', red_edge_4: 'B8A', vapor: 'B9', cirrus: 'B10', swir_1: 'B11', swir_2: 'B12', empty_1: 'QA10', empty_2: 'QA20', cloud_mask: 'QA60' } },

    l8_SR: { from: '2013-03-18', to: getToday(1, 2), name: 'Landsat 8 SR', scale: 30, collectionID: 'LANDSAT/LC08/C02/T1_L2', bands: { coastal_aerosols: 'SR_B1', b: 'SR_B2', g: 'SR_B3', r: 'SR_B4', nir: 'SR_B5', swir_1: 'SR_B6', swir_2: 'SR_B7', aerosol: 'SR_QA_AEROSOL', surface_reflectance: 'ST_B10', atmospheric_transmittance: 'ST_ATRAN', cloud_dist: 'ST_CDIST', downwelled_radiance: 'ST_DRAD', emissivity:'ST_EMIS', emissivity_sd: 'ST_EMSD', uncertainty:'ST_QA', thermal: 'ST_URAD', pixel_qa:'QA_PIXEL'} },
    l7_SR: { from: '1999-01-01', to: getToday(1, 1), name: 'Landsat 7 SR', scale: 30, collectionID: 'LANDSAT/LE07/C02/T1_L2', bands: { b: 'SR_B1', g: 'SR_B2', r: 'SR_B3', nir: 'SR_B4', swir_1: 'SR_B5', swir_2: 'SR_B7', opacity: 'SR_ATMOS_OPACITY', cloud_qual: 'SR_CLOUD_QA',cloud_mask:"QA_PIXEL", saturation:'QA_RADSAT'} },
    l5_SR: { from: '1984-01-01', to: '2012-05-05', name: 'Landsat 5 SR', scale: 30, collectionID: 'LANDSAT/LT05/C02/T1_L2', bands: {b: 'SR_B1', g: 'SR_B2', r: 'SR_B3', nir: 'SR_B4', swir_1: 'SR_B5', swir_2: 'SR_B7', opacity: 'SR_ATMOS_OPACITY', cloud_qual: 'SR_CLOUD_QA',cloud_mask:"QA_PIXEL", saturation:'QA_RADSAT'} },
    l4_SR: { from: '1982-08-22', to: '1993-12-14', name: 'Landsat 4 SR', scale: 30, collectionID: 'LANDSAT/LT04/C02/T1_L2', bands: {b: 'SR_B1', g: 'SR_B2', r: 'SR_B3', nir: 'SR_B4', swir_1: 'SR_B5', swir_2: 'SR_B7', opacity: 'SR_ATMOS_OPACITY', cloud_qual: 'SR_CLOUD_QA',cloud_mask:"QA_PIXEL", saturation:'QA_RADSAT'} },
    s2_SR: { from: '2017-03-28', to: getToday(0, 2), name: 'Sentinel-2 SR', scale: 10, collectionID: 'COPERNICUS/S2_SR', bands: { aerosols: 'B1', b: 'B2', g: 'B3', r: 'B4', red_edge_1: 'B5', red_edge_2: 'B6', red_edge_3: 'B7', nir: 'B8', red_edge_4: 'B8A', vapor: 'B9', swir_1: 'B11', swir_2: 'B12', empty_1: 'QA10', empty_2: 'QA20', cloud_mask: 'QA60' } },

    PALSAR: { from: '2007', to: '2017', name: 'Global PALSAR-2/PALSAR Yearly Mosaic', scale: 25, collectionID: 'JAXA/ALOS/PALSAR/YEARLY/SAR' },
    SAR: { from: '2014-10-17', to: getToday(0, 2), name: 'Sentinel-1 SAR GRD: C-band Synthetic Aperture Radar Ground Range Detected, log scaling', scale: 10, collectionID: 'COPERNICUS/S1_GRD' },
    srtm_dtm: { name: 'SRTM 30m DEM', scale: 30, collectionID: 'USGS/SRTMGL1_003', min: '-10', max: '6500' },
    gmted_dtm: { name: 'GMTED 30m DEM', scale: 30, collectionID: 'USGS/GMTED2010', min: '-100', max: '6500' },
    alos_dsm: { name: 'ALOS 30m DSM', scale: 30, collectionID: 'JAXA/ALOS/AW3D30_V1_1', min: '-10', max: '6500' },
    copernicus_cover_2015: { name: 'Copernicus Global Land Cover Layers', scale: 100, collectionID: 'COPERNICUS/Landcover/100m/Proba-V-C3/Global/2015' },
    copernicus_cover_2016: { name: 'Copernicus Global Land Cover Layers', scale: 100, collectionID: 'COPERNICUS/Landcover/100m/Proba-V-C3/Global/2016' },
    copernicus_cover_2017: { name: 'Copernicus Global Land Cover Layers', scale: 100, collectionID: 'COPERNICUS/Landcover/100m/Proba-V-C3/Global/2017' },
    copernicus_cover_2018: { name: 'Copernicus Global Land Cover Layers', scale: 100, collectionID: 'COPERNICUS/Landcover/100m/Proba-V-C3/Global/2018' },
    copernicus_cover_2019: { name: 'Copernicus Global Land Cover Layers', scale: 100, collectionID: 'COPERNICUS/Landcover/100m/Proba-V-C3/Global/2019' },
    corine_1990: { from: '1989', to: '1998', name: 'Copernicus CORINE Land Cover', scale: 100, collectionID: 'COPERNICUS/CORINE/V20/100m/1990' },
    corine_2000: { from: '1999', to: '2001', name: 'Copernicus CORINE Land Cover', scale: 100, collectionID: 'COPERNICUS/CORINE/V20/100m/2000' },
    corine_2006: { from: '2005', to: '2007', name: 'Copernicus CORINE Land Cover', scale: 100, collectionID: 'COPERNICUS/CORINE/V20/100m/2006' },
    corine_2012: { from: '2011', to: '2012', name: 'Copernicus CORINE Land Cover', scale: 100, collectionID: 'COPERNICUS/CORINE/V20/100m/2012' },
    corine_2018: { from: '2017', to: '2018', name: 'Copernicus CORINE Land Cover', scale: 100, collectionID: 'COPERNICUS/CORINE/V20/100m/2018' },
    glims: { name: 'GLIMS: Global Land Ice Measurements from Space - 2016', scale: 30, collectionID: 'GLIMS/2016' },
    croplands: { name: 'GFSAD1000: Cropland Extent 1km Crop Dominance, Global Food-Support Analysis Data', scale: 1000, collectionID: 'USGS/GFSAD1000_V1' },
    annual_burned_area: { name: 'MCD64A1.006 MODIS Burned Area Monthly Global 500m', scale: 500, collectionID: 'MODIS/006/MCD64A1', from: '2000-11-01', to: getToday(3) },
    burned_area: { name: 'MCD64A1.006 MODIS Burned Area Monthly Global 500m', scale: 500, collectionID: 'MODIS/006/MCD64A1', from: '2000-11-01', to: getToday(3) },
    snow_cover: { name: 'MOD10A1.006 Terra Snow Cover Daily Global 500m', scale: 500, collectionID: 'MODIS/006/MOD10A1', from: '2000-02-24', to: getToday() },
    terra_climate: { name: 'TerraClimate: Monthly Climate and Climatic Water Balance for Global Terrestrial Surfaces, University of Idaho', scale: 4638, collectionID: 'IDAHO_EPSCOR/TERRACLIMATE', from: '1958-01-01', to: '2019-12-01' },
    yearly_water: { name: 'JRC Yearly Water Classification History', scale: 30, collectionID: 'JRC/GSW1_2/YearlyHistory', from: '1984-03-16', to: '2019-01-01' },
    surface_water: { name: 'JRC Monthly Water History', scale: 30, collectionID: 'JRC/GSW1_2/MonthlyHistory', from: '1984-03-16', to: '2019-01-01' },
    ozone: { name: 'TOMS and OMI Merged Ozone Data', scale: 124459, collectionID: 'TOMS/MERGED', from: '1978-11-01', to: getToday(0, 4), bands: { ozone: 'ozone' } },
    chlorophyll: { name: 'Ocean Color SMI: Standard Mapped Image MODIS Aqua Data', scale: 500, collectionID: 'NASA/OCEANDATA/MODIS-Aqua/L3SMI', from: '2002-07-02', to: getToday(0, 95) },
    nrti_cloud: { name: 'Sentinel-5P NRTI CLOUD: Near Real-Time Cloud', scale: 1113, collectionID: 'COPERNICUS/S5P/NRTI/L3_CLOUD', from: '2018-07-05', to: getToday() },
    noaa_ocean: { name: 'NOAA CDR: Ocean Near-Surface Atmospheric Properties', scale: 27830, collectionID: 'NOAA/CDR/ATMOS_NEAR_SURFACE/V2', from: '1988-01-01', to: getToday(4, 5) },
    gldas: { name: 'GLDAS-2.1: Global Land Data Assimilation System', scale: 27830, collectionID: 'NASA/GLDAS/V021/NOAH/G025/T3H', from: '2000-01-01', to: getToday(0, 95) },
    firms: { name: 'FIRMS: Fire Information for Resource Management System', scale: 1000, collectionID: 'FIRMS', from: '2000-11-01', to: getToday() },
    terra_fires: { name: 'MOD14A1.006: Terra Thermal Anomalies & Fire Daily Global 1km', scale: 1000, collectionID: 'MODIS/006/MOD14A1', from: '2000-02-18', to: getToday(0, 7) },
    population_count: { name: 'GPWv411: Population Count (Gridded Population of the World Version 4.11)', scale: 100, collectionID: 'CIESIN/GPWv411/GPW_Population_Count' },
    population_density: { name: 'GPWv411: Population Density (Gridded Population of the World Version 4.11)', scale: 100, collectionID: "CIESIN/GPWv411/GPW_Population_Density" },
    biomass: { from: '2007', to: '2017', name: 'Global PALSAR-2/PALSAR Yearly Mosaic', scale: 25, collectionID: 'JAXA/ALOS/PALSAR/YEARLY/SAR' },
    carbomap: { collectionID: 'users/Carbomap/optimise__dcd_images_Final_Raster', scale: 14.138147415249383 },
    precipitation: { name: 'CHIRPS Daily: Climate Hazards Group InfraRed Precipitation with Station Data (version 2.0 final)', scale: 5550, collectionID: 'UCSB-CHG/CHIRPS/DAILY', from: '1981-01-01', to: '2020-05-01', bands: { precipitation: 'precipitation' } },//adjust to date to function
    ERA5_precip: { name: 'ERA5 Daily Precipitation aggregate', scale: 27750, collectionID: 'ECMWF/ERA5/DAILY', from: '1979-01-02', to: '2020-05-01', bands: { precipitation: 'total_precipitation' } },//adjust to date to function
    PERSIANN_precip: { name: 'PERSIANN-CDR: Precipitation Estimation from Remotely Sensed Information Using Artificial Neural Networks-Climate Data Record', scale: 27750, collectionID: 'NOAA/PERSIANN-CDR', from: '1983-01-01', to: '2019-06-30', bands: { precipitation: 'precipitation' } },//adjust to date to function
    viirs_corrected: { name: 'VIIRS Stray Light Corrected Nighttime Day/Night Band Composites Version 1', scale: 463, collectionID: 'NOAA/VIIRS/DNB/MONTHLY_V1/VCMSLCFG', from: '2014-01-01', to: '2020-12-01', bands: { average_radiance: 'avg_rad', cloudfree_coverage: "cf_cvg" } },
    viirs: { name: 'VIIRS Nighttime Day/Night Band Composites Version 1', scale: 463, collectionID: 'NOAA/VIIRS/DNB/MONTHLY_V1/VCMCFG', from: '2012-04-01', to: '2020-12-01', bands: { average_radiance: 'avg_rad', cloudfree_coverage: "cf_cvg" } },
    s2_probability: {name: 'Sentinel-2 Cloud Probability', scale: 10, collectionID: 'COPERNICUS/S2_CLOUD_PROBABILITY', from: '2015-06-23', to: 'getToday(0, 2)', bands: { probabiity: 'probability'}},
    //Leeds Earthquake Blocks
    leeds_china_phase_diff: { collectionID: 'users/gy09csw/earth_blox/C_129A_04939_131313_20200116_20200128_geo_diff_pha', scale: 111.31940285087583 },
    leeds_china_unwrapped: { collectionID: 'users/gy09csw/earth_blox/C_129A_04939_131313_20200116_20200128_geo_unw', scale: 111.31940285087583 },
    leeds_iceland_phase_diff: { collectionID: 'users/gy09csw/earth_blox/I_155D_02579_100800_20201020_20201026_geo_diff_pha', scale: 111.31949079327357 },
    leeds_iceland_unwrapped: { collectionID: 'users/gy09csw/earth_blox/I_155D_02579_100800_20201020_20201026_geo_unw', scale: 111.31949079327352 },
    leeds_nevada_phase_diff: { collectionID: 'users/gy09csw/earth_blox/N_144D_05102_121313_20200329_20200516_geo_diff_pha', scale: 111.31949079327357 },
    leeds_nevada_unwrapped: { collectionID: 'users/gy09csw/earth_blox/N_144D_05102_121313_20200329_20200516_geo_unw', scale: 111.31949079327357 },
    leeds_turkey_phase_diff: { collectionID: 'users/gy09csw/earth_blox/T_116A_05167_121313_20200115_20200127_geo_diff_pha', scale: 111.31940285087583 },
    leeds_turkey_unwrapped: { collectionID: 'users/gy09csw/earth_blox/T_116A_05167_121313_20200115_20200127_geo_unw', scale: 111.31940285087583 },
    leeds_xizang_phase_diff: { collectionID: 'users/gy09csw/earth_blox/X_121D_05668_131313_20200702_20200726_geo_diff_pha', scale: 111.31940285087583 },
    leeds_xizang_unwrapped: { collectionID: 'users/gy09csw/earth_blox/X_121D_05668_131313_20200702_20200726_geo_unw', scale: 111.31940285087583 },

    //Leeds Glacier blocks
    leeds_PG_ERS: { collectionID: 'users/ZahraSadeghi/PG_ERS', scale: 106.88310421836188 },
    leeds_PG_Velocity: { collectionID: 'users/ZahraSadeghi/PG_Velocity', scale: 100 },
    leeds_JAK_Velocity: { collectionID: 'users/raws/190506_190512_coffsN_mag_yrF_gc', scale: 100 },

    // Function returns an appropriate band for a specified satellite and requested wavelength
    getBand: function (sat, band) {
        if (sat !== null)
            return dataset[sat]['bands'][band];
        else
            return null;
    },

    getCollectionID: function (sat) {
        if (sat !== null)
            return dataset[sat]['collectionID'];
        else
            return null;
    },

    getRawCollectionID: function (sat) {
        if (sat !== null)
            return dataset[sat]['rawCollectionID'];
        else
            return null;
    },

    getSRCollectionID: function (sat) {
        if (sat !== null)
            return dataset[sat]['SRcollectionID'];
        else
            return null;
    },

    // only for DEMs
    getMin: function (sat) {
        if (sat !== null)
            return dataset[sat]['min'];
        else
            return null;
    },

    getMax: function (sat) {
        if (sat !== null)
            return dataset[sat]['max'];
        else
            return null;
    },

    // for geophysical and climate datasets
    getFrom: function (sat) {
        if (sat !== null)
            return dataset[sat]['from'];
        else
            return null;
    },

    getTo: function (sat) {
        if (sat !== null)
            return dataset[sat]['to'];
        else
            return null;
    },

    setFrom: function (sat, yrs = 1, months = 0, days = 1) {
        if (sat !== null) {
            var td = dataset[sat]['to'];
            var d = new Date(td);
            var c = new Date(d.getFullYear() - yrs, d.getMonth() - months, d.getDate() - days);
            c = c.toISOString().split('T')[0];
            return c;
        } else {
            return null;
        }
    },

    getScale: function (sat) {
        if (sat in dataset)
            return dataset[sat]['scale'];
        else
            return null;
    },

    // Function takes in the from-to dates as arguments and returns all available satellites for specified dates

    defaultSatellite: function (fd, td) {
        // TO DO: call from the main dataset a function that returns availability arrays
        var today = (new Date()).toISOString().slice(0, 10).replace(/-/g, "");
        var satellite_availability = ['2013-04-11', '2019-04-04', '1999-01-01', '2019-04-19', '1984-01-01', '2012-05-05', '1982-08-22', '1993-12-14', '2015-06-23', today];
        var corresponding_satellites = [['Landsat\u00A08', 'l8'], ['Landsat\u00A07', 'l7'], ['Landsat\u00A05', 'l5'], ['Landsat\u00A04', 'l4'], ['Sentinel-2', 's2'], ['Satellite data not available for these dates', 'na']];
        var decision = Array(1);
        var i = 0;

        if (td < fd) {
            decision[0] = corresponding_satellites[corresponding_satellites.length - 1];
        }
        else {
            // Looping through satellite_availability and corresponding_satellite arrays
            for (let k = 0; k < ((satellite_availability.length) / 2); k++) {
                if ((fd >= satellite_availability[k * 2]) && (td <= satellite_availability[(k * 2) + 1])) {
                    decision[i] = corresponding_satellites[k];
                    i++;
                }
            }

            if (i == 0) {
                decision[i] = corresponding_satellites[corresponding_satellites.length - 1];
            }
        }
        return decision;
    }
}

function initialiseDataset() {
    console.info("Initialising dataset module");
    dataset.hansen = DatasetService.getBlockDatasetSpec('UMD/hansen/global_forest_change');
    dataset.hansen.bands = { tree_cover: 'treecover2000', loss: 'loss', gain: 'gain' };
    console.info("Dataset module initialised");
}


// Look up table for retrieving band arrays for dropdowns
const getBandArray = {
    "l8": function () {
        return [['B1 (Aerosol)', "B1"], ['B2 (Blue)', "B2"], ['B3 (Green)', "B3"], ['B4 (Red)', "B4"], ['B5 (NIR)', "B5"], ['B6 (SWIR 1)', "B6"], ['B7 (SWIR 2)', "B7"], ['B8 (Pan)', 'B8'],['B9 (Cirrus)', 'B9'],['B10 (TIRS 1)', 'B10'],['B11 (TIRS 2)', 'B11'] ,['QA (Bitmask)','BQA']];
      },
    "l7": function () {
        return [['B1 (Blue)', "B1"], ['B2 (Green)', "B2"], ['B3 (Red)', "B3"], ['B4 (NIR)', "B4"], ['B5 (SWIR 1)', "B5"], ['B6 (TIRS 1)', "B6_VCID_1"], ['B6 (TIRS 2)', "B6_VCID_2"], ['B7 (SWIR 2)', "B7"], ['B8 (Pan)', "B8"]];
    },
    "l5": function () {
        return [['B1 (Blue)', "B1"], ['B2 (Green)', "B2"], ['B3 (Red)', "B3"], ['B4 (NIR)', "B4"], ['B5 (SWIR 1)', "B5"], ['B6 (TIRS 1)', "B6"], ['B7 (SWIR 2)', "B7"]];
    },
    "l4": function () {
        return [['B1 (Blue)', "B1"], ['B2 (Green)', "B2"], ['B3 (Red)', "B3"], ['B4 (NIR)', "B4"], ['B5 (SWIR 1)', "B5"], ['B6 (TIRS 1)', "B6"], ['B7 (SWIR 2)', "B7"]];
    },
    "s2": function () {
        return [['B1 (Aerosols)', "B1"], ['B2 (Blue)', "B2"], ['B3 (Green)', "B3"], ['B4 (Red)', "B4"], ['B5 (Red Edge 1)', "B5"], ['B6 (Red Edge 2)', "B6"], ['B7 (Red Edge 3)', "B7"], ["B8 (NIR)", "B8"], ["B8A (Red Edge 4)", "B8A"], ["B9 (Water vapor)", "B9"], ['B10 (Cirrus)', "B10"], ['B11 (SWIR 1)', "B11"], ['B12 (SWIR 2)', "B12"]];
    },
    "l8_SR": function () {
        return [['B1 (Aerosol)', "SR_B1"], ['B2 (Blue)', "SR_B2"], ['B3 (Green)', "SR_B3"], ['B4 (Red)', "SR_B4"], ['B5 (NIR)', "SR_B5"], ['B6 (SWIR 1)', "SR_B6"], ['B7 (SWIR 2)', "SR_B7"], ['Aerosol (Attributes)', 'SR_QA_AEROSOL']];
    },
    "l7_SR": function () {
        return [['B1 (Blue)', "SR_B1"], ['B2 (Green)', "SR_B2"], ['B3 (Red)', "SR_B3"], ['B4 (NIR)', "SR_B4"], ['B5 (SWIR 1)', "SR_B5"],['B7 (SWIR 2)', "SR_B7"]];
    },
    "l5_SR": function () {
        return [['B1 (Blue)', "SR_B1"], ['B2 (Green)', "SR_B2"], ['B3 (Red)', "SR_B3"], ['B4 (NIR)', "SR_B4"], ['B5 (SWIR 1)', "SR_B5"],['B7 (SWIR 2)', "SR_B7"]];
    },
    "l4_SR": function () {
        return [['B1 (Blue)', "SR_B1"], ['B2 (Green)', "SR_B2"], ['B3 (Red)', "SR_B3"], ['B4 (NIR)', "SR_B4"], ['B5 (SWIR 1)', "SR_B5"],['B7 (SWIR 2)', "SR_B7"]];
    },
    "s2_SR": function () {
        return [['B1 (Aerosols)', "B1"], ['B2 (Blue)', "B2"], ['B3 (Green)', "B3"], ['B4 (Red)', "B4"], ['B5 (Red Edge 1)', "B5"], ['B6 (Red Edge 2)', "B6"], ['B7 (Red Edge 3)', "B7"], ["B8 (NIR)", "B8"], ["B8A (Red Edge 4)", "B8A"], ["B9 (Water vapor)", "B9"], ['B11 (SWIR 1)', "B11"], ['B12 (SWIR 2)', "B12"]];
    },
    "PALSAR": function () {
        return [["HH", "HH"], ["HV", "HV"]];
    },
    "SAR": function () {
        return [['VH', 'VH'], ["VV", "VV"], ['HV', 'HV'], ["HH", "HH"]];
    },
    "ozone": function () {
        return [['Ozone', 'ozone']];
    },

    'gldas': function () {
        return [
            ['Albedo', 'Albedo_inst'],
            ['Average surface skin T', 'AvgSurfT_inst'],
            ['Pressure', 'Psurf_f_inst'],
            ['Soil Moisture 10 cm', 'SoilMoi0_10cm_inst'],
            ['Soil Temperature 10 cm', 'SoilTMP0_10cm_inst'],
            ['Air temperature', 'Tair_f_inst'],
            ['Wind speed', 'Wind_f_inst']]
    },

    'chlorophyll': function () {
        return [
            ['Chlorophyll a concentration', 'chlor_a'],
            ['Particulate organic carbon', 'poc'],
            ['Sea surface temperature', 'sst']]
    },

    'nrti_cloud': function () {
        return [
            ['Retrieved effective radiometric cloud fraction', 'cloud_fraction'],
            ['Retrieved atmospheric pressure at the level of top cloud', 'cloud_top_pressure'],
            ['Retrieved altitude of the cloud top', 'cloud_top_height'],
            ['Cloud base height', 'cloud_base_height']]
    },

    'noaa_ocean': function () {
        return [
            ['Air\u00A0temperature at\u00A010m', 'air_temperature'],
            ['Specific\u00A0humidity at\u00A010m', 'specific_humidity'],
            ['Wind speed\u00A0at\u00A010m', 'wind_speed']]
    },

    'snow_cover': function () {
        return [
            ['Snow\u00A0cover', 'NDSI_Snow_Cover']]
    },

    'precipitation': function () {
        return [
            ['Precipitation', 'precipitation']]
    },

    'ERA5_precip': function () {
        return [
            ['Precipitation', 'total_precipitation']]
    },

    'PERSIANN_precip': function () {
        return [
            ['Precipitation', 'precipitation']]
    },

    'yearly_water': function () {
        return [
            ['Water class', 'waterClass']]
    },

    'surface_water': function () {
        return [
            ['Water', 'water']]
    }

}

function getBlock(name) {
    return workspace.getBlockById(name);
}

/**
 * @param {int} months  Number of months that it takes for GEE to upload the dataset.
 * @param {int} days    Number of days that it takes for GEE to upload the dataset.
 */
function getToday(months = 0, days = 0) {
    var d = new Date();
    var c = new Date(d.getFullYear(), d.getMonth() - months, d.getDate() - days);
    c = c.toISOString().split('T')[0];
    return c;
}

// -------------------------- Helper functions for accessing date, area and satellite variables from other blocks ------------------------------------------
var sat_area_date = ["input_optical_data_only", "input_optical_data", "input_radar_data", "input_forest_data", "input_dem_data", "input_land_cover", "input_anthropogenic", "input_ice_data", "input_atmospheric_data", "input_fire_data", "input_drought_data", "input_river_data", "input_water_data", "input_climate_data", "input_radar_data_only"];
var date_blocks = ["input_optical_data", "input_radar_data", "input_forest_data", "input_atmospheric_data", "input_date_selection", "input_date_area_selection", "input_climate_data", "input_fire_data", "input_river_data", "input_water_data", "input_drought_data"];
var area_blocks = ["input_forest_data", "input_atmospheric_data", "input_climate_data", "input_land_cover", "input_anthropogenic", "input_ice_data", "input_optical_data", "input_radar_data", "input_date_area_selection", "input_fire_data", "input_drought_data", "input_river_data", "input_water_data"];
var analyse_blocks = ['analyse_unsupervised_classification', 'analyse_supervised_classification', "analyse_change_detection", "analyse_ndvi", "analyse_ndwi", "analyse_ndsi", "analyse_nbi", 'analyse_spi', 'analyse_band_math', 'analyse_single_band_math']


function getCurrentSat(block) {

    while (!sat_area_date.includes(block.type) && block.getSurroundParent() !== null) {
        block = block.getSurroundParent();
    }

    if (block !== null) {
        let currentSat = block.getFieldValue("dropdown");
        // if a Surface Reflectance dataset, need to return a dataset name ex: s2_SR
        if (block.getFieldValue("data")) {
            currentSat = block.getFieldValue("dropdown") + block.getFieldValue("data");
            return currentSat;
        } else {
            return currentSat;
        }
    } else {
        return null;
    }
}

/**
 * Getting current sat was not working correctly, so have created a new function which uses the root block
 * method build natively in Blockly
 * @param {*} block
 */
function getCurrentSatUsingRootBlock(block) {
    let rootBlock = block.getRootBlock()
    // confusing fields names but dropdown is satellite
    if (rootBlock.getFieldValue("dropdown")) {
        let currentSat = rootBlock.getFieldValue("dropdown");
        // if a Surface Reflectance dataset, need to return a dataset name ex: s2_SR
        // data shows TOA or SR
        if (rootBlock.getFieldValue("data")) {
            currentSat += rootBlock.getFieldValue("data");
            return currentSat;
        } else {
            return currentSat;
        }
    } else if (rootBlock.satelliteParameter){ 
        // special parameter on blocks that don't have dropdown/param field to allow
        // for output other vis to work for some blocks e.g. analyse_change_detection
        return rootBlock.satelliteParameter;
    } else {
        return null;
    }
}

function getAnalyseBlock(block) {
    while (!analyse_blocks.includes(block.type) && block.getParent() !== null) {
        block = block.getParent();
    }
    if (block !== null && analyse_blocks.includes(block.type)) {
        return block;
    } else {
        return null;
    }
}

function getFromDate(block, secondary = '') {  //secondary bit for blocks with multiple date selector band references, eg radar
    var fd = '';
    while (!date_blocks.includes(block.type) && block.getSurroundParent() !== null) {
        block = block.getSurroundParent();
    }

    fd = block.getFieldValue("fd" + secondary);
    if (fd == null) {
        fd = block.getFieldValue("before_fd");
    }

    return fd;
}


function getToDate(block, secondary = '') {  //secondary bit for blocks with multiple date selector band references, eg radar
    var td = '';

    while (!date_blocks.includes(block.type) && block.getSurroundParent() !== null) {
        block = block.getSurroundParent();
    }

    td = block.getFieldValue("td" + secondary);
    if (td == null) {
        td = block.getFieldValue("before_td");
    }

    return td;
}


function setFromDate(date, yrs = 0, months = 1, days = 0, plus = false) {  //need +/- options for change detection block
    if (plus === true) {
        var d1 = new Date(date);
        let c1 = new Date(d1.getFullYear() + yrs, d1.getMonth() + months, d1.getDate() + days);
        c1 = c1.toISOString().split('T')[0];
        return c1;
    } else if (plus === false) {
        var d2 = new Date(date);
        let c2 = new Date(d2.getFullYear() - yrs, d2.getMonth() - months, d2.getDate() - days);
        c2 = c2.toISOString().split('T')[0];
        return c2;
    }
}

/**
 * Validate From DateField. Function returns previous date selection if the new date is invalid.
 * @param {newValue} date string to validate in 'YYYY-MM-DD' format
 */
function validateFrom(field, newValue, extra = '') {  //extra bit for blocks with multiple date selector band references, eg radar
    var new_val = new Date(newValue);
    let fromStr

    if (field.getSourceBlock().getFieldValue('data') !== null) {
        fromStr = dataset.getFrom(String(field.getSourceBlock().getFieldValue('dropdown') + field.getSourceBlock().getFieldValue('data')));
    }
    else {
        fromStr = dataset.getFrom(field.getSourceBlock().getFieldValue('dropdown'));
    }

    if (fromStr === null) { fromStr = dataset.getFrom(getCurrentSat(field.getSourceBlock())) }
    var toDate = new Date(field.getSourceBlock().getFieldValue('td' + extra));

    if (new_val < new Date(fromStr) || new_val > toDate) {
        var prev_val = new Date(field.getSourceBlock().getFieldValue('fd' + extra));
        return prev_val.toISOString().split('T')[0];
    }
    return newValue;
}

/**
 * Validate To DateField. Function returns previous date selection if the new date is invalid.
 * @param {newValue} date string to validate in 'YYYY-MM-DD' format
 */
function validateTo(field, newValue, extra = '') { //extra bit for blocks with multiple date selector band references, eg radar
    var before = new Date(field.getSourceBlock().getFieldValue('fd' + extra));
    var after = new Date(newValue);

    var td
    if (field.getSourceBlock().getFieldValue('data') !== null) {
        td = new Date(dataset.getTo(String(field.getSourceBlock().getFieldValue('dropdown') + field.getSourceBlock().getFieldValue('data'))));
    }
    else {
        td = new Date(dataset.getTo(String(field.getSourceBlock().getFieldValue('dropdown'))));
    }
    if (after < before || after > td) {
        /* Try and restrict the dates in the calendar


        1. This needs to go to Ln 195 in field_dates.js
        Blockly.FieldDate.prototype.dropdownCreate_ = function() {
            // Create the date picker using Closure.
            Blockly.FieldDate.loadLanguage_();
            var picker = new goog.ui.DatePicker();
            picker.setAllowNone(false);
            picker.setShowWeekNum(false);
            picker.setUseNarrowWeekdayNames(true);
            picker.setUseSimpleNavigationMenu(true);
            picker.setDate(goog.date.DateTime.fromIsoString(this.getValue()));
            // -----  add min-max range -----
            date_range = this.getDateRange(); // --- > need to declare this is core/field.js
            picker.setUserSelectableDateRange(date_range);
            // -------------------------

            this.changeEventKey_ = goog.events.listen(
                picker,
                goog.ui.DatePicker.Events.CHANGE,
                this.onDateSelected_,
                null,
                this);
            this.activeMonthEventKey_ = goog.events.listen(
                picker,
                goog.ui.DatePicker.Events.CHANGE_ACTIVE_MONTH,
                this.updateEditor_,
                null,
                this);

            return picker;
        };

        2. Add dateRange_ variable to Field class and a getter method (core/field.js)
        https://github.com/google/blockly/blob/f8fc748055515e30eb6ae63106b85313824c837f/core/field.js
        // Constructor

        // getter
        Blockly.Field.prototype.getDateRange = function() {
            return this.dateRange_;
        };

        // Update when value changes?

        3. Pass DateRange to Field object when initializing the field date:
        newDateRange = new DateRange(new DateDate(2010, 1, 25), new DateDate(2020, 1, 27));
         */
        return field.getSourceBlock().getFieldValue('td' + extra);
    }
    return newValue;
}

/**
 * Validate Yearly From DateField. Function returns previous date selection if the new date is invalid.
 * @param {newValue} date string to validate in 'YYYY' format
 */
function validateYearlyFrom(field, newValue, secondary = '') {
    var toDate = field.getSourceBlock().getFieldValue('td' + secondary);
    if (newValue > toDate)
        return field.getSourceBlock().getFieldValue('fd' + secondary);
    return newValue;
}

/**
 * Validate Yearly To DateField. Function returns previous date selection if the new date is invalid.
 * @param {newValue} date string to validate in 'YYYY' format
 */
function validateYearlyTo(field, newValue) {
    var before = field.getSourceBlock().getFieldValue('fd');
    if (newValue < before)
        return field.getSourceBlock().getFieldValue('td');
    return newValue;
}

function hasValidYears(fromYear, toYear) {
    var warning = null;
    if (fromYear > toYear) {
        warning = 'The \'From\' date exceeds the \'To\' date'
    }
    return warning;
}

function hasValidDates(block, fromDate, toDate) {
    let fromStr;
    let toStr;

    if (block.getFieldValue("dropdown")) {
        if (block.getFieldValue('data') !== null) {
            fromStr = dataset.getFrom(String(block.getFieldValue('dropdown') + block.getFieldValue('data')));
            toStr = dataset.getTo(String(block.getFieldValue('dropdown') + block.getFieldValue('data')));
        }
        else {
            fromStr = dataset.getFrom(block.getFieldValue('dropdown'));
            toStr = dataset.getTo(String(block.getFieldValue('dropdown')))
        }
    }

    if (fromStr === null) { fromStr = dataset.getFrom(getCurrentSat(block)) }
    if (toStr === null) { toStr = dataset.getFrom(getCurrentSat(block)) }

    var warning = null;
    if (fromDate > toDate) {
        warning = 'The \'From\' date exceeds the \'To\' date'
    } else if (fromDate < fromStr) {
        warning = 'The \'From\' date is below the datasets minimum date - ' + fromStr
    } else if (toDate > toStr) {
        warning = 'The \'To\' date is above the datasets maximum date - ' + toStr
    }
    return warning;
}

function validateFromDate(newValue, date_val) {
    var new_val = new Date(newValue);
    var date = new Date(date_val);
    if (new_val < date) {
        return date_val
    }
    return newValue;
}

function validateToDate(newValue, date_val) {
    var new_val = new Date(newValue);
    var date = new Date(date_val);
    if (new_val > date) {
        return date_val
    }
    return newValue;
}



function getArea(block) {
    var area = '';
    var parent = block.getSurroundParent();
    if (parent !== null) {
        while (!area_blocks.includes(parent.type) && parent.getSurroundParent() !== null) {
            parent = parent.getSurroundParent();
        }
        area = parent.getFieldValue("area");
    }
    return area;
}


function getParam(block) {
    var data_blocks = ["input_forest_data", "input_climate_data", "input_radar_data", "input_radar_data_only"];

    while ((block.getSurroundParent() !== null) && (!data_blocks.includes(block.type))) {
        block = block.getSurroundParent();
    }

    if (block.getFieldValue("param") == null) {
        return getCurrentSat(block);
    } else {
        return block.getFieldValue("param");
    }
}


function getClassNames(block) {
    var data_blocks = ["analyse_supervised_classification"];
    var child = block;
    while ((child !== null) && (!data_blocks.includes(child.type))) {
        child = getChildrenInside(child)[0];
    }

    let param = null;
    try {
        param = child.getClassNames_();
    } catch {
        param = ['na'];
    }
    return param;
}

function getSupervisedPalette(block, class_names) {
    var data_blocks = ["analyse_supervised_classification"];
    var child = block;
    while ((child !== null) && (!data_blocks.includes(child.type))) {
        child = getChildrenInside(child)[0];
    }

    let palette_array = [];
    try {
        palette_array = child.getPaletteArray_(class_names);
    } catch {
        palette_array = ['na'];
    }
    return palette_array;
}

function getClassDropdowns(block) {
    var dropdown = [];
    var names1 = [];
    var names2 = [];
    var palette_array = [];
    try {
        var children = getChildrenInside(block);
        names1 = getClassNames(children[0]);
        names2 = getClassNames(children[1]);
        if ((names1[0] == 'na') || (names2[0] == 'na')) {
            dropdown = [['Classification block missing', 'na']];
        } else {
            for (var i = 0; i < names1.length; i++) {
                if (names1[i] == names2[i]) {
                    dropdown.push([names1[i], names1[i]]);
                } else {
                    dropdown = [['Class names do not match', 'na']];
                    break;
                }
            }
            if (names1.length !== names2.length) {
                dropdown = [['Number of classes is not the same', 'na']];
            }
            if ((names1[0] !== 'na') && (names2[0] !== 'na')) {
                palette_array = getSupervisedPalette(block, names1);
            }
        }
    } catch {
        dropdown = [['Classification block missing', 'na']];
    }
    var result_array = [];
    result_array.push(dropdown);
    result_array.push(names1);
    result_array.push(palette_array);
    return result_array;
}

function getParentBlockByType(block, block_to_find) {
    do {
        block = block.getSurroundParent();
    } while ((block !== null) && (block.type !== block_to_find));

    if (block !== null) {
        return block;
    } else {
        return null;
    }
}

function isChartPresent(block) {
    let chartPresent = false;
    while (block.getChildren().length > 0) {
        if (block.getChildren().length === 1) {
            block = block.getChildren()[0];
            if (block.type == 'output_charts' || block.type == 'output_table') {
                chartPresent = true;
            }
        } else {
            chartPresent = block.getChildren().some(child => isChartPresent(child) === true);
            break;
        }
    }

    return chartPresent;
}

export { dataset, initialiseDataset, getParam, getCurrentSat, getAnalyseBlock, getFromDate, getToDate, getBandArray, getBlock, validateFrom, validateTo, validateYearlyFrom, validateYearlyTo, validateFromDate, validateToDate, getArea, getClassDropdowns, getParentBlockByType, setFromDate, isChartPresent, hasValidYears, hasValidDates, getCurrentSatUsingRootBlock }
