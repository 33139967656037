<template>
    <md-field class="md-multi-select">
        <md-input v-if="hasDisplayText && dropdownOpen === false && forceOpen === false" class="md-multi-select--input" :model-value="displayText" readonly @click="handleOpenSelect" />
        <md-select v-else v-model="selection" ref="dropdown" :placeholder="placeholder" multiple @md-opened="dropdownOpen = true" @md-closed="dropdownOpen = false">
            <slot name="before" v-bind:selectActions="selectActions"></slot>
            <md-option
                v-if="enableSelectAll"
                :value="'all'"
                >
                {{ allName }}
            </md-option>
            <md-option
                v-for="option in options"
                :key="option.id"
                :value="option.id"
                >
                {{ option.name }}
            </md-option>
            <slot name="after" v-bind:selectActions="selectActions"></slot>
        </md-select>
        <md-icon class="md-icon-image" v-if="hasDisplayText && dropdownOpen === false && forceOpen === false">
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10l5 5 5-5z" />
            <path d="M0 0h24v24H0z" fill="none" />
            </svg>
        </md-icon>
    </md-field>
</template>

<script>

import {difference} from 'lodash'
import valueMixin from '@/components/mixins/valueMixin'

export default {
    name: 'TableMultiSelect',
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        options: {
            type: Array,
            default: () => []
        },
        allName: {
            type: String,
            default: 'All'
        },
        displayText: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: 'Select'
        },
        enableSelectAll: {
            type: Boolean,
            default: true
        },
        mode: {
            type: String,
            default: 'multi'
        }
    },
    mixins: [valueMixin],
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            selection: this.modelValue,
            dropdownOpen: false,
            forceOpen: false,
            ignoreWatcher: false
        }
    },
    computed: {
        selectActions() {
            return {
                closeSelect: this.closeSelect
            }
        },
        hasDisplayText() {
            return this.displayText !== null && this.displayText.length > 0
        },
        all() {
            return this.options.map(option => option.id);
        }
    },
    watch: {
        selection: {
            //immediate: true,
            handler(newSelection, oldSelection) {
                if(this.mode === 'single') {
                    if(this.ignoreWatcher) {
                        this.ignoreWatcher = false
                        return
                    }
                    this.ignoreWatcher = true
                    if(newSelection.length === 0) {
                        this.selection = []
                        this.$emit('update:modelValue', []);
                    } else if (oldSelection.length === 0) {
                        this.ignoreWatcher = false
                        this.selection = newSelection
                        this.$emit('update:modelValue', newSelection);
                    } else {
                        const differenceOptions = difference(newSelection, oldSelection)
                        this.selection = differenceOptions
                        this.$emit('update:modelValue', differenceOptions);
                    }
                    return
                }

                // check if selection has changed to include or not include all, if so, then select all options in dropdown or unselect all options in dropdown

                if(newSelection && oldSelection){
                    if(newSelection.includes('all') && !oldSelection.includes('all')) {
                        this.selection = ['all', ...this.all];
                    } else if (!newSelection.includes('all') && oldSelection.includes('all')) {
                        this.selection = [];
                    }
                }

                const selectionExcludingAll = this.selection.filter(item => item !== 'all');
                this.$emit('update:modelValue', selectionExcludingAll);
            }
        }
    },
    methods: {
        updateSelection(selection) {
            this.selection = selection
        },
        closeSelect() {
            setTimeout(() => {
                this.$refs.dropdown.showSelect = false
            })
        },
        handleOpenSelect() {
            this.forceOpen = true
            // needs to rerender to show dropdown then force an open
            setTimeout(() => {
                this.$refs.dropdown.openSelect()
                setTimeout(() => {
                    this.forceOpen = false
                })
            }) 
            
        }
    }
}
</script>