/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["MODIFIER_MASK_BLOCK_TITLE"] = "Mask out part of image";
Blockly.Msg["MODIFIER_MASK_DEFINITION_LABEL"] = "Where ";
Blockly.Msg["MODIFIER_MASK_BAND_LABEL"] = "              ";

export default Blockly.Msg;
