/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import ReassignClassesModel from '@/modals/ReassignClasses.vue';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock';
import { ContentService } from '@/services/content.service';
import assets from '@/assets.js';

const FIELD = {
    MODAL: 'classes',
}

const INPUT = {
    MODAL: 'MODAL',
}

var reassignJson ={
    "type": "modifier_reassign_classes",
    "lastDummyAlign0": "RIGHT",
    "message0": "%1 %2 %3",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "calculator_title",
            "text": "%{BKY_MODIFIER_REASSIGN_CLASSES_BLOCK_TITLE}",
            "class": "boldTitleField"
        },
        {
            "type": "field_modal",
            "name": FIELD.MODAL,
            "modal_component": ReassignClassesModel,
            "modal_data": {
                band: '',
                newBandName: '',
                newClasses: [],
            },
            "src": assets.blockly.settingsWhite24dp,
            "width": 24,
            "height": 24,
            "opt_alt": "ALT STRING"
        },
        {
            "type": "input_dummy",
            "name": INPUT.MODAL
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "classify",
    "tooltip": "",
    "helpUrl": ""
}


Blockly.Blocks['modifier_reassign_classes'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    onInit: function() {
        this.jsonInit(reassignJson)
        this.about_block_url = ContentService.getAboutBlockUrl('modifier_reassign_classes')
    },
    accept: async function (visitor) {
        await visitor.visitModifierReAssignClassesBlock(this);
    },
    updateShape_() {
        if(this.hasStateChanged('bands')) {
            this.getField(FIELD.MODAL).setModalData('bands', this.getState('bands'))
        }
    },
    ebxValidate: function(tooltips) {
        this.setWarningText(null, 'not_raster')
        const isRaster = this.getState('isRaster')
        if(!isRaster) {
            this.setWarningText(tooltips['not_raster'] || 'This block is only compatible with raster datasets.', 'not_raster')
            return
        }

        const modalField = this.getFieldValue(FIELD.MODAL)

        this.setWarningText(null, 'no_band_selected')
        this.setWarningText(null, 'no_new_band_name')
        this.setWarningText(null, 'invalid_selected_band')
        this.setWarningText(null, 'new_classes_required')
        this.setWarningText(null, 'new_classes_value_invalid')
    
        if(!modalField.band) {
            this.setWarningText(tooltips['no_band_selected'] || 'No band has been selected', 'no_band_selected')
        } else {
            const validBands = this.getState('bands').map((band) => band['ebx:name'])
            if(validBands.includes(modalField.band) === false) {
                this.setWarningText(tooltips['invalid_selected_band'] || 'Selected band is no longer valid', 'invalid_selected_band')
            }
        }
        if(!modalField.newBandName || modalField.newBandName.length === 0) {
            this.setWarningText(tooltips['no_new_band_name'] || 'Enter a new band name', 'no_new_band_name')
        }

        if(Array.isArray(modalField.newClasses) ===false) {
            this.setWarningText(tooltips['new_classes_required'] || 'You must add at least 1 new class', 'new_classes_required')
        } else if (modalField.newClasses.length === 0) {
            this.setWarningText(tooltips['new_classes_required'] || 'You must add at least 1 new class', 'new_classes_required')
        } else {
            const uniqueValues = new Set(modalField.newClasses)
            if(uniqueValues.size !== modalField.newClasses.length) {
                this.setWarningText(tooltips['new_classes_value_invalid'] || 'New class values must be unique', 'new_classes_value_invalid')
            }
        }
    }
    
}; 