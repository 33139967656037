import { register } from 'register-service-worker';
import { globalEventBus } from './eventbus';

if (import.meta.env.PROD) {

  register(`/service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered(reg) {
      console.log('Service worker has been registered.');
      //Check for updated service worker on load
      reg.update();
      window.localStorage.removeItem('swUpdated')
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
      window.localStorage.setItem('swUpdated', 'updated')
      globalEventBus.$emit('swUpdated','updated')
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });
}
