export const GlobalPlugin = () => {
    return {
        install(app) {
            app.config.globalProperties.$confirmModal = function (message, options = {}) {
                return this.$store.dispatch('global/setConfirmationDetails', {
                    message,
                    shown: true,
                    ...options
                });
            }
            app.config.globalProperties.$APIConfiguration = function (message, options = {}) {
                return this.$store.dispatch('global/setAPIConfigurationDetails', {
                    message,
                    shownModal: true,
                    ...options
                });
            }
        }
    }
}