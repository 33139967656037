/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["WORKFLOW_EMPTY_CONTAINER_TITLE"] = "Workflow Container";
Blockly.Msg["WORKFLOW_EMPTY_CONTAINER_LABEL"] = "Workflow Container";


export default Blockly.Msg;