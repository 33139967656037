<template>
    <div ref="loadingIndicator" v-show="tilesLoading" class="loading-indicator">
        <md-progress-spinner md-mode="indeterminate" class="md-accent" :md-diameter="30">
        </md-progress-spinner>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
export default {
    props: {
        google: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
        tilesLoading: {
            type: Boolean,
            required: true
        }
    },
    mounted() {
        const loadingIndicator = this.$refs.loadingIndicator;
        this.map.controls[this.google.maps.ControlPosition.LEFT_BOTTOM].push(
            loadingIndicator
        );
    }
};
</script>

<style scoped>
.loading-indicator {
    margin-left: 10px;
    margin-bottom: 50px; 
}
</style>