/**
 * Flow Manager, used to run a series of functions in order
 * One after the other once the workspace has finished changing
 * This is to somewhat encapsulate the workflow changes and make it easier to update 
 * blocks in a workflow in an ordered manner.
 * 
 * Example:
 * User places a attribute select block, into a workflow. The options to select will not be available
 * until the workspace has finished changing and updated the dropdown from the metadata within the dataset.
 * We need to wait until the workspace has finished changing before we can update the block.
 * This continues for each step in this flow.
 * 
 * Once the flow has finished it will unbind itself from the workspace change listener.
 * 
 * 1. Flow manager calls first function in the flow
 * 2. first function Triggers updates in the workspace. upon all stacked events finished up the workspace calls event_finished
 * 3. flow captures event_finished and runs the next function in the flow
 * 4. repeat until flow is empty
 * 5. unbind from workspace change listener
 */
class FlowManager {
    /**
     * 
     * @param {AbstractVisitor} visitor 
     * @param {BlocklyWorkspace} workspace 
     */
    constructor(visitor, workspace) {
        this._flow = []
        this.visitor = visitor
        this.listener = this._onChangeFinished.bind(this)
        this.workspace = workspace
        this.boundListener = false
    }
    /**
     * add a function to the flow
     * @param {function} flow 
     * @returns  {FlowManager}
     */
    addStep(flow) {
        if (typeof flow !== 'function') {
            throw new Error('FlowManager.addStep expects a function')
        }
        this._flow.push(flow)
        return this
    }
    /**
     * Binds the listener to the workspace change event. This is usally called by run
     * @returns {FlowManager}
     */
    bindListener() {
        if(!this.boundListener) {
            this.workspace.addChangeListener(this.listener)
            this.boundListener = true
        }
        return this;
    }
    /**
     * Runs the flow, if the flow is empty it will unbind the listener from the workspace
     * @returns {FlowManager}
     */
    run() {
        this.bindListener()
        if(this._flow.length > 0) {
            const flow = this._flow.shift()
            flow()
        }
        if(this._flow.length === 0 && this.boundListener){
            this.workspace.removeChangeListener(this.listener)
            this.boundListener = false
        }
        return this
    }
    /**
     * This change listener
     * @param {*} event 
     */
    _onChangeFinished(event) {
        if(event.type === 'event_finished') {
            this.run()
        }
    }

    /**
     * 
     * @param {AbstractVisitor} visitor 
     * @param {BlocklyWorkspace} workspace 
     * @param {Block} block 
     * @returns  {FlowManager}
     */
    static applyRecommendedFields(visitor, workspace, block) {
        const flowManager = new FlowManager(visitor, workspace)
        if(block.recommendedFields) {
            block.recommendedFields.forEach(field =>{
                flowManager.addStep(() => {
                    if (block.getField(field.name)) {
                        if(block.getFieldValue(field.name) !== field.value) {
                            block.setFieldValue(field.value, field.name);
                        } else {
                            // if the value is already set, we can skip this step and trigger the next
                            flowManager.run();
                        }
                    }else {
                        console.warn('Field not found', field.name, block.type)
                        // Field not found just move to the next one.
                        flowManager.run();
                    }
                });
            })
            // must remove recommended blocks ones actioned
            flowManager.addStep(() => {
                block.recommendedFields = null
                flowManager.run()
            });
        }
        return flowManager
    }
}

export {FlowManager}