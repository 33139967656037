import { AbstractCollection  } from "./AbstractCollection"

export class BandCollection extends AbstractCollection {

    allBands() {
        return this.abilities.flatMap(a => a.getBands())
    }
    availableBands(ignoreBlockId = false, stopAtSavedBlockId = false) {
        const bandAbilities = this.abilities
        let filteredBands = [];
        let filtedBandAbilities = []
        //collate filters
       
        bandAbilities.forEach(ability => {

            if(ability.getRemovePreviousBands()) {
                filteredBands = []
                filtedBandAbilities = []
            }

            filtedBandAbilities.push(ability)

            const abilityFilters = ability.getState().filtered_bands
            if(abilityFilters !== undefined) {
                filteredBands = filteredBands.concat(abilityFilters.slice(0))
            }
        })
        //Remove Previous Bands
        

        const allBands = filtedBandAbilities
            .map(ability => {
                return {
                    blockId: ability.associatedBlockId,
                    bands: ability.getBands(),
                    prepend: ability.prependBand(),
                    filters: filteredBands.filter(f => {
                        let fIdx = ability.workflowState.registry.traversedBlocks.indexOf(f.blockId)
                        let blockIdx = ability.workflowState.registry.traversedBlocks.indexOf(ability.associatedBlockId)
                        let savedWorkflowIdx = ability.workflowState.registry.traversedBlocks.indexOf(stopAtSavedBlockId)
                        // If both ID's are not in current workflow. This indicates that the required workflow is for addTable or calculator
                        // We need to use the previous traversalBlocks to determin the order of when blocks are loaded
                        // Because addTable and Calculator can sometimes contain datasets that have been added after
                        if(fIdx === -1 && blockIdx === -1) {
                            fIdx = ability.workflowState.registry.previousTraversedBlocks.indexOf(f.blockId)
                            blockIdx = ability.workflowState.registry.previousTraversedBlocks.indexOf(ability.associatedBlockId)
                            savedWorkflowIdx = ability.workflowState.registry.previousTraversedBlocks.indexOf(stopAtSavedBlockId)
                        }
                        return f.blockId !== ignoreBlockId && blockIdx < fIdx && (stopAtSavedBlockId === false || savedWorkflowIdx >= fIdx || savedWorkflowIdx === -1)
                    })
                }
            })
            .reduce((allBands, {bands, filters, prepend}) => {
                // order the bands. if prepend is true then add the bands to the beginning of the list
                var bandOrdering = (bands) => prepend ? bands.concat(allBands) : allBands.concat(bands)
                if(filters.length === 0) {
                    return bandOrdering(bands)
                }
                // always compare the last filter to reduce the bands. as the filters above are filtered by block position. 
                // this will compare each filter against the last and remove any bands that are not in the last filter
                // this is needed as bands could be added by blocks.
                const lastFilter = filters[filters.length -1]
                const filteredBands = bands.filter(b => {
                    return lastFilter.bands.indexOf(b['ebx:name']) >= 0
                })
                return bandOrdering(filteredBands)
            }, [])
        return allBands
    }

    toDropDownOptions(ignoreNamespace = false, valueKey = 'ebx:name', keyKey = 'ebx:name') {
        console.debug(ignoreNamespace)
        return this.availableBands().map(band => {
            return [band[valueKey], band[keyKey]]
        })
        
    }

}