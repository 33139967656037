import * as Blockly from 'blockly/core';
import {NO_DATA_DROPDOWN_VALUE} from '@/constants/nextGenConstants';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock';
import { ContentService } from '@/services/content.service';

const datasetOptionsDropdownGenerator = () => {
    return [
        ["Select an option", NO_DATA_DROPDOWN_VALUE]
    ]
}

// TODO: consider abstracting FIELD and INPUT names to abstractBlock, on init define
// them to allow for visitors to access the names too
const FIELD = Object.freeze({
    DATASET: 'selected_dataset'
})

var inputDatasetJson = {
    "type": "input_dataset",
    "message0": "%1 %2 %3",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "dataset_label",
            "text": "%{BKY_INPUT_DATASET_LABEL}",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.DATASET,
            "options": datasetOptionsDropdownGenerator
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "dataset",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['input_dataset'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,

    onInit: function() {
        this.jsonInit(inputDatasetJson);
        this.getField(FIELD.DATASET).setValidator(this.validateDatasetName)

        this.about_block_url = ContentService.getAboutBlockUrl('input_dataset')
    },

    onSaveExtraState: function() {
        let fieldOptions = this.getField(FIELD.DATASET).getOptions();
        let selectedOption = this.getFieldValue(FIELD.DATASET);

        let state = {}
        state[FIELD.DATASET] = fieldOptions.filter(option => option[1] === selectedOption)
        return state;
    },

    validateDatasetName(newValue) {
        return newValue
    },

    accept: async function (visitor) {
        await visitor.visitInputDatasetBlock(this);
    },

    ebxValidate: function(tooltips) {
        if(this.getFieldValue(FIELD.DATASET) === NO_DATA_DROPDOWN_VALUE) {
            return this.setWarningText(tooltips['no_selected_saved_dataset'] || 'Please select a dataset', 'no_selected_saved_dataset')
        }
        return this.setWarningText(null, 'no_selected_saved_dataset')
    },

    /**
     * Update the dataset options dropdown with the users selected dataset
     */
    updateShape_: function() {
        if (this.hasStateChanged(FIELD.DATASET)){
            const options = this.getState(FIELD.DATASET)
            this.getField(FIELD.DATASET).updateOptions(options)
        }
    }
}