import datasetImagePlaceholder from "@/assets/ebx-icons/dataset-image-placeholder.svg?url"
import datasetVectorPlaceholder from "@/assets/ebx-icons/dataset-vector-placeholder.svg?url"
import emptyStateAssets from "@/assets/ebx-icons/empty-state-assets.svg?url"
import emptyStateFolder from "@/assets/ebx-icons/empty-state-folder.svg?url"
import emptyStateFile from "@/assets/ebx-icons/empty-state-file.svg?url"
import emptyStateVariables from "@/assets/ebx-icons/empty-state-variables.svg?url"
import emptyStateVariablesGrey from "@/assets/ebx-icons/empty-state-variables-grey.svg?url"
import emptyStateDashboard from "@/assets/ebx-icons/empty-state-dashboard.svg?url"
import emptyStateWorkflow from "@/assets/ebx-icons/empty-state-workflow.svg?url"
import globe from "@/assets/ebx-icons/globe.svg?url"
import carat from "@/assets/ebx-icons/carat.svg?url"
import caratDisabled from "@/assets/ebx-icons/carat_disabled.svg?url"
import cursor from "@/assets/ebx-icons/cursor.svg?url"
import rectangle from "@/assets/ebx-icons/rectangle.svg?url"
import irregularShape from "@/assets/ebx-icons/irregular-shape.svg?url"
import deleteOutline from "@/assets/ebx-icons/delete_outline.svg?url"
import addArea from "@/assets/ebx-icons/add-area.svg?url"
import play from "@/assets/ebx-icons/play.svg?url"
import playDisabled from "@/assets/ebx-icons/play-disabled.svg?url"
import stop from "@/assets/ebx-icons/stop.svg?url"
import folderZip from "@/assets/ebx-icons/folder_zip.svg?url"
import loading from "@/assets/loading.gif?url"
import playIcon from "@/assets/play_icon.svg?url"
import stopIcon from "@/assets/stop_icon.svg?url"
import intersect from "@/assets/gifs/intersect.gif?url"
import difference from "@/assets/gifs/difference.gif?url"
import dissolve from "@/assets/gifs/dissolve.gif?url"
import datasetId from "@/assets/datasetID.gif?url"
import settingsWhite24dp from "@/assets/settings_white_24dp.svg?url"
import travelExplore from "@/assets/travel_explore.svg?url"
import edit24dp from "@/assets/edit_24dp.svg?url"
import infoWhite24dp from "@/assets/info_white_24dp.svg?url"

import Blues from '@/assets/ee-palettes/Blues.png';
import BrBG from '@/assets/ee-palettes/BrBG.png';
import BuGn from '@/assets/ee-palettes/BuGn.png';
import Delta from '@/assets/ee-palettes/Delta.png';
import Greens from '@/assets/ee-palettes/Greens.png';
import Greys from '@/assets/ee-palettes/Greys.png';
import GreysReverse from '@/assets/ee-palettes/GreysReverse.png';
import Ice from '@/assets/ee-palettes/Ice.png';
import OrRd from '@/assets/ee-palettes/OrRd.png';
import PiYG from '@/assets/ee-palettes/PiYG.png';
import RdBu from '@/assets/ee-palettes/RdBu.png';
import RdPu from '@/assets/ee-palettes/RdPu.png';
import RdYlBu from '@/assets/ee-palettes/RdYlBu.png';
import RdYlGn from '@/assets/ee-palettes/RdYlGn.png';
import Reds from '@/assets/ee-palettes/Reds.png';
import Spectral from '@/assets/ee-palettes/Spectral.png';
import Speed from '@/assets/ee-palettes/Speed.png';
import YlGnBu from '@/assets/ee-palettes/YlGnBu.png';
import batlow from '@/assets/ee-palettes/batlow.png';
import cividis from '@/assets/ee-palettes/cividis.png';
import coolwarm from '@/assets/ee-palettes/coolwarm.png';
import heatmap from '@/assets/ee-palettes/heatmap.png';
import jet from '@/assets/ee-palettes/jet.png';
import lajolla from '@/assets/ee-palettes/lajolla.png';
import linear_gow_65_90_c35 from '@/assets/ee-palettes/linear_gow_65_90_c35.png';
import linear_kry_5_95_c72 from '@/assets/ee-palettes/linear_kry_5_95_c72.png';
import magma from '@/assets/ee-palettes/magma.png';
import roma from '@/assets/ee-palettes/roma.png';
import viridis from '@/assets/ee-palettes/viridis.png';
import warmcool from '@/assets/ee-palettes/warmcool.png';

export default {
    icons: {
        datasetImagePlaceholder,
        datasetVectorPlaceholder,
        emptyStateAssets,
        emptyStateFolder,
        emptyStateVariables,
        emptyStateVariablesGrey,
        emptyStateDashboard,
        emptyStateFile,
        emptyStateWorkflow,
        globe,
        carat,
        caratDisabled,
        cursor,
        rectangle,
        irregularShape,
        deleteOutline,
        addArea,
        play,
        playDisabled,
        stop,
        folderZip
    },
    gifs: {
        intersect,
        difference,
        dissolve
    },
    blockly: {
        settingsWhite24dp,
        travelExplore,
        edit24dp,
        infoWhite24dp
    },
    palette: {
        Blues,
        BrBG,
        BuGn,
        Delta,
        Greens,
        Greys,
        GreysReverse,
        Ice,
        OrRd,
        PiYG,
        RdBu,
        RdPu,
        RdYlBu,
        RdYlGn,
        Reds,
        Spectral,
        Speed,
        YlGnBu,
        batlow,
        cividis,
        coolwarm,
        heatmap,
        jet,
        lajolla,
        linear_gow_65_90_c35,
        linear_kry_5_95_c72,
        magma,
        roma,
        viridis,
        warmcool
    },
    loading,
    playIcon,
    stopIcon,
    datasetId
}