/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["WORKFLOW_CLASSIFY_IMAGE_TITLE"] = "Create supervised classification"
Blockly.Msg["WORKFLOW_CLASSIFY_IMAGE_ALG_LABEL"] = "Select algorithm"
Blockly.Msg["WORKFLOW_CLASSIFY_IMAGE_INPUT_LABEL"] = "Input data";
Blockly.Msg["WORKFLOW_CLASSIFY_IMAGE_TRAINING_LABEL"] = "Training data";
Blockly.Msg["WORKFLOW_CLASSIFY_IMAGE_OUTPUT_LABEL"] = "output";

export default Blockly.Msg;