import * as Blockly from 'blockly/core';
import { AreaService } from '@/services/area.service';
import { ContentService } from '@/services/content.service';
import { AbstractBlock, AbstractFieldHelpers, AbstractVariableHelpers } from '@/blocks/_abstractBlock';
import {NO_DATA_DROPDOWN_VALUE} from '@/constants/nextGenConstants'
import { RegistryService } from "@/services/registry.service"
const FIELD = Object.freeze({
    STUDY_AREA: 'study_area_options'
})

const INPUT = Object.freeze({
    STUDY_AREA: 'study_area_input'
})

var studyareaJson = {
    "type": "study_area",
    "message0":"%1 %2 %3 %4",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "study_area_title",
            "text": "%{BKY_STUDY_AREA_BLOCK_TITLE}", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy",
            "name": INPUT.STUDY_AREA
        },
        {
            "type": "field_label_serializable",
            "name": "study_area_label",
            "text": "%{BKY_STUDY_AREA_BLOCK_LABEL}"
        },
        {
            "type": "field_variable_dropdown",
            "name": FIELD.STUDY_AREA,
            "options": [["Select an option", NO_DATA_DROPDOWN_VALUE]]
        }
    ],
    
    "previousStatement": null,
    "nextStatement": null,
    "style": "primaryFilter",
    "tooltip": "",
    "helpUrl": ""
};

// const eventUtils = Blockly.Events

Blockly.Blocks['study_area'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    ...AbstractVariableHelpers,

    FIELD: FIELD,
    INPUT: INPUT,

    onInit: function() {

        const studyAreaJsonClone = Object.assign({}, studyareaJson)
        if(!this.isInFlyout) {
            // Attempt to load the study area options from the observable state of the global datasets.
            const globalDatasetService = RegistryService.getGlobalDatasetService()
            let globalVectors = []
            if(globalDatasetService && globalDatasetService.hasObservable()) {

                globalVectors = globalDatasetService
                    .getWrappedObservable()
                    .onlyProjectOrigins()
                    .onlyVectors(['feature_collection','area'])
                    .asOptions()

                globalVectors = [...globalVectors,...globalDatasetService
                    .getWrappedObservable()
                    .onlyWorkspaceVariables()
                    .onlyWorkspaceVariablesOfType(['area'])
                    .asOptions()
                ]
            }
            // If we have vectors update the options, otherwise lavel as no value dropdown
            if(globalVectors.length > 0) {
                studyAreaJsonClone.args0[3].options = globalVectors
            }
        }

        this.jsonInit(studyAreaJsonClone)
        this.about_block_url = ContentService.getAboutBlockUrl('study_area')
    },

    updateAreas: function() {
        const vectors = this.getState('vectors')
        if (vectors && vectors.length > 0) {
            this.getField(FIELD.STUDY_AREA)?.updateOptions(vectors);
        } else {
            this.getField(FIELD.STUDY_AREA)?.updateOptions([["Select an option", NO_DATA_DROPDOWN_VALUE]]);
        }
    },

    updateShape_: function() {
        if(this.hasStateChanged('vectors')) {
            this.updateAreas();
        }
        if(this.hasStateChanged("type")) {
            const type = this.getState("type")
            if (type === 'vector') {
                this.updateLabel("study_area_label","Features intersecting with")
            } else { 
                this.updateLabel("study_area_label","Area of Interest:")
            }
        }
    },

    ebxValidate: function(errors) {
        const studyAreaField = this.getField(FIELD.STUDY_AREA)
        if (studyAreaField && studyAreaField.getValue() !== NO_DATA_DROPDOWN_VALUE) {
            let areaId = studyAreaField.getVariableValue() // falls back to field value if no variable is selected
            let areaHasShapes = AreaService.areaHasShapes(areaId)
            let areaName = AreaService.getAreaName(areaId)
            const isVectorArea = this.isVectorArea(areaId)

            if(studyAreaField.isVariable() === false) {
                // Check for no shapes in area
                if (!areaHasShapes && !isVectorArea) {
                    const noShapeText = this.getErrorMessage(errors, 'no_shapes_warning', 'There are no polygons in [area]. Draw or upload a polygons, or choose a different Area of Interest.')
                    this.setWarningText(noShapeText.replace('[area]', areaName), "no_shapes_warning");
                    return
                }
            }
            
        }
        this.setWarningText(null, "no_study_area_variable_warning");
        this.setWarningText(null, "no_shapes_warning");
    },

    getErrorMessage(errors, id, fallback) {
        return (errors && errors[id]) ? errors[id] : fallback
    },

    accept: async function (visitor) {
        await visitor.visitStudyAreaBlock(this);
    },

    // check if areaId is in vectorAreas
    isVectorArea: function(areaId) {
        let isVectorArea = false
        let vectorAreas = this.getState('vectors')
        if (vectorAreas && vectorAreas.length > 0) {
            vectorAreas.forEach(vectorArea => {
                if (areaId === vectorArea[1]) {
                    isVectorArea = true
                }
            })
        }
        return isVectorArea
    }

}; 