<template>
    <div class="table-creator--row-body table-creator--row-statistic">
        <div class="md-table-cell--width-x2" colspan="2">
            <label>
                Attribute
            </label>
            <TableMultiSelect 
                    :selected="selectedProperties" :options="datasetOptions"
                    :allName="'All Attributes'" 
                    :display-text="selectedBandsDisplayText" 
                    v-model="selectedProperties"
                    label="Attributes" ref="propertySelector">
                    <!-- <template v-slot:after="data">
                        <button v-if="selectedMultipleProperties"
                            class="md-list-item-button md-list-item-container md-button-clean md-list-item-button--transformation"
                            @click.prevent="handleLoadTransformDialog('properties', data)">
                            <div class="md-list-item-content md-ripple">
                                <template v-if="hasPropertiesTransform">
                                    Edit Transform
                                </template>
                                <template v-else>
                                    Transform +
                                </template>
                            </div>
                        </button>
                    </template> -->
                </TableMultiSelect>
        </div>
        <div class="md-table-cell--width-x2" colspan="2">
            &nbsp;
        </div>
        <div class="md-table-cell--delete">
            <md-button class="md-icon-button" @click="$emit('delete-row', row.id)">
                <md-icon>delete</md-icon>
            </md-button>
        </div>
    </div>
</template>

<script>
import tableCreatorRowMixin from '../../mixins/tableCreatorRowMixin'
import TableMultiSelect from '../TableMultiSelect.vue'

export default {
    name: 'TableCreatorFeatureAttributeRow',
    mixins: [tableCreatorRowMixin],
    components: {
        TableMultiSelect
    },
    data() {
        return {
            selected: {
                dataset: this.row.dataset || null,
                dates: [],
                properties: this.row.properties,
                role: this.row.aggregation || this.row.role || null,
                propertiesTransform: null,
                datesTransform: null,
                transformPreference: null,
                displayType: this.row.displayType
            },
        }
    },
    props: {
        row: {
            type: Object,
            default: () => { }
        },
        datasets: {
            type: Array,
            default: () => []
        }
    },
    emits: [
        'update-row',
        'delete-row'
    ],
    computed: {
        //override mixin
        datasetOptions() {
            const dataset = this.getDataset(this.row.dataset);
            return dataset.attributes[0].properties.map(property => {
                return {id: property, name: property}
            })
        },
        selectedProperties: {
            get() {
                return this.selected.properties.map(p => p.name)
            },
            set(values) {
                this.selected.properties = values.map(v => {
                    return { name: v}
                })
            }
        }
    },
    watch: {
        /**
         * detect changes to transforms and update selected data with changes
         */
        row: {
            handler(newRow) {
                this.handleRowUpdated(newRow)
            },
            deep: true
        },
        selected: {
            handler() {
                const properties = this.selected.properties
                const dataset = this.getDataset(this.row.dataset);
                var selected = this.computeSelectedRow(dataset, 'rows', 'statistic')
                selected.properties = properties
                selected.aggregation = 'feature-attribute'
                selected.roleType = 'feature-attribute'
                this.$emit('update-row', selected);
            },
            deep: true
        }
    }
}
</script>