/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["ANALYSIS_CONVERT_IMAGES_TITLE"] = "Convert images to features     ";
Blockly.Msg["ANALYSIS_CONVERT_IMAGES_BAND_LABEL"] = "Band";
Blockly.Msg["ANALYSIS_CONVERT_IMAGES_NAME_LABEL"] = "Name Attribute";

export default Blockly.Msg;