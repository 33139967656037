
import { RxEvents } from './providers/events';
// We cannot dependency inject the VariableService into the AbstractVariable class as RxJS will break the VueJS reactivity
// So we need to import the service directly as its a singleton
import { VariablesService } from '../services/variable.service.js';
/**
 * AbstractVariable aims to be a base class for all variables in the application. It provides a common interface for all variables
 * All variables should extend this class.
 * Usage:
 * ```
 * import {AbstractVariable} from './abstract';
 * export class AreaVariable extends AbstractVariable {
 *  static TYPE = 'area';
 *
 *    constructor(id, title, description = null, value = null) {
 *        super(id, title, description, value);
*    }
 * }
 * ```
 */
export class AbstractVariable extends RxEvents {
    // Type is the unique identifier for the variable. It is used to determine the type of the variable
    static TYPE = 'abstract';
    static NAME = 'Override Me'
    /**
     * Constructor for the should be called by the child class using super()
     * @param {String} id 
     * @param {String} title 
     * @param {String} description 
     * @param {*} value 
     */
    constructor(id, title, description= null, value = null) {
        super()
        // Setup some UI specific properties
        this.type = this.constructor.TYPE;
        this.scope = 'global';
        this.display_type_ = null;
        this.display_order_ = 0;
        this.default_value_ = null
        this.set_for_explorer_ = false
        this.warningMessages_ = []
        this.errorMessages_ = []
        this.deleted_ = false

        // Setup the variable
        this.variable = {
            id: id,
            title: title,
            value: value,
            description: description,
        };

        // Extra State to be provided by visitors
        this.clearExtraState()

        this.events = {
            // RxJS Subjects and Vue Observables do not play nice togther. create a custom event emitter that mimics the behavior of RxJS Subjects
            update: this.createEventSubject_('update'),
            updatedValidateMessages: this.createEventSubject_('updatedValidateMessages'),
            updatedExtraState: this.createEventSubject_('updatedExtraState'),
        }
    }

    /**
     * Getters and Setters for the variable properties
     * Used within the Observable and Vuejs to allow for reactive updates
     * Update will trigger an RxJS event to notify subscribers of the change e.g. Blockly
     */

    get title() {
        return this.variable.title;
    }
    get blockIds() {
        return this.extraState.block_ids;
    }
    get id() { 
        return this.variable.id;
    }
    set title(title) {
        this.setTitle(title)
    }
    get value() {
        return this.getComputedValue();
    }
    set value(value) {
        this.setValue(value)
    }
    get description() {
        return this.variable.description;
    }
    set description(value) {
        this.setDescription(value)
    }
    get set_for_explorer() {
        return this.set_for_explorer_;
    }
    set set_for_explorer(value) {
        this.setSetForExplorer(value)
    }
    get display_order() {
        return this.display_order_;
    }
    set display_order(value) {
        this.setDisplayOrder(value)
    }
    get display_type() {
        return this.display_type_;
    }
    get has_default_value() {
        return this.default_value_ !== null
    }
    get default_value() {
        return this.getDefaultValue()
    }

    get extra_state() {
        return this.extraState
    }

    get is_valid() {
        return this.isValid()
    }
    get all_error_messages() {
        return [
            ...this.errorMessages_.map(error => error.message),
            ...this.warningMessages_.map(warning => warning.message)
        ]
    }
    get error_messages() {
        return this.errorMessages_.map(error => error.message)
    }
    get warning_messages() {
        return this.warningMessages_.map(error => error.message)
    }

    get vue_component() {
        if(VariablesService.getIsExplorerMode()) {
            return this.getVueJSExplorerComponent()
        }
        return this.getVueJSCreatorComponent()
    }

    /**
     * Returns the reactive properties for the variable, this is used to create the reactive object in VueJS
     * This should be overridden by the child class to add any extra properties
     * e.g. return [...super.reactiveProps(), 'extra_property']
     * 
     * @returns Array<String>
     */
    reactiveProps() {
        return [
            'title', 'value', 'description', 'set_for_explorer', 
            'display_order', 'display_type', 'has_default_value', 'extra_state', 
            'is_valid', 'all_error_messages', 'error_messages', 'warning_messages', 'vue_component',
            'type','scope', 'blockIds', 'id', 'default_value'
        ]
    }


    /**
     * Creates a reactive object that can be used in VueJS
     * Allows sub componetns to be updated upon changes to the variable
     * @returns 
     */
    createReativeObject() {
        const observable = {
        }
        const props = this.reactiveProps()
        var self = this
        props.forEach(prop => {
            Object.defineProperty(observable, prop, {
                get() {
                    return self[prop]
                },
                set(value) {
                    self[prop] = value
                }
            })
        })
    
        return observable;
    }

    /**
     * Clears the extra state for the variable
     * @returns this
     */
    clearExtraState() {
        this.extraState = {}
        return this
    }

    /**
     * Sets extra state on the variable so we can use it to process extra behavior
     * returns this
     */
    setExtraState(key, value, silent = false) {
        this.extraState[key] = value
        if(!silent){
            this.events.updatedExtraState.next(this)
        }
        return this
    }

    /**
     * Gets extra state from the variable
     * @param {*} key 
     * @returns 
     */
    getExtraState(key) {
        return this.extraState[key]
    }

    /**
     * removes extra state from the variable
     * @param {*} key 
     * @returns 
     */
    removeExtraState(key) {
        delete this.extraState[key]
        return this
    }

    /**
     * Set the title of the variable if its changed. then trigger and update event
     * @param {*} title 
     * @param {*} silent 
     * @returns 
     */
    setTitle(title, silent = false) {
        const oldVariable = this.variable.title
        this.variable.title = title;
        if(!silent && oldVariable !== title){
            this.events.update.next(this)
        }
        return this
    }
    /**
     * Set the value of the variable if its changed. then trigger and update event
     * @param {*} value 
     * @param {*} silent 
     * @param {*} validate
     * @returns 
     */
    setValue(value, silent = false, validate = true) {
        const oldVariable = this.variable.value
        this.variable.value = value;
        if(!silent && oldVariable !== value){
            this.events.update.next(this)
            if(validate) {
                this.validate()
            }
        }
        return this
    }
    /**
     * Set the value of the description if its changed. then trigger and update event
     * @param {*} value 
     * @param {*} silent 
     * @returns 
     */
    setDescription(description, silent = false) {
        const oldVariable = this.variable.description
        this.variable.description = description;
        if(!silent && oldVariable !== description){
            this.events.update.next(this)
        }
        return this
    }
    setSetForExplorer(value, silent = false) {
        const oldVariable = this.set_for_explorer_
        this.set_for_explorer_ = value;
        if(!silent && oldVariable !== value){
            this.events.update.next(this)
        }
        return this
    }
    /**
     * Set the display_order of the variable if its changed. then trigger and update event
     * @param {*} value 
     * @param {*} silent 
     * @returns 
     */
    setDisplayOrder(value, silent = false) {
        const oldVariable = this.display_order_
        this.display_order_ = value;
        if(!silent && oldVariable !== value){
            this.events.update.next(this)
        }
        return this
    }

    /**
     * Sets the deleted flag on the variable. This is used to remove the variable from the provider
     * @param {*} deleted 
     * @returns 
     */
    setDeleted(deleted=true) {
        this.deleted_ = deleted
        return this
    }

    /**
     * Set the default value of the variable. This is used to set a default value for the explorer user
     * @param {*} value 
     * @returns 
     */
    setDefaultValue(value) {
        this.default_value_ = value
        return this
    }

    /**
     * Gets back if deleted flag is set
     * @returns {Boolean}
     */
    getIsDeleted() {
        return this.deleted_
    }

    /**
     * Getter method to get the title of the variable
     * @returns {String}
     */
    getTitle() {
        return this.variable.title;
    }
    /**
     * Getter method to get the id of the variable
     * @returns {String}
     */
    getId() {
        return this.variable.id;
    }
    /**
     * Getter method to get the value of the variable
     * @returns {String}
     */
    getValue() {
        return this.variable.value;
    }
    /**
     * Getter method to get the scope of the variable
     * @returns {String}
     */
    getScope() {
        return this.scope;
    }
    /**
     * Getter method to get the type of the variable
     * @returns {String}
     */
    getType() {
        return this.type;
    }
    /**
     * Getter method to get the display type of the variable
     * @returns {String}
     */
    getDisplayType() {
        return this.display_type_
    }
    /**
     * Getter method to get the description of the variable
     * @returns {String}
     */
    getDescription() {
        return this.variable.description
    }
    /**
     * Getter method to get the display order of the variable
     * @returns {String}
     */
    getDisplayOrder() {
        return this.display_order_
    }
    /**
     * Getter method to get the default value of the variable
     * @returns {*}
     */
    getDefaultValue() {
        return this.default_value_
    }
    /**
     * Getter method to get the if we set the default value for the of the variable if explorer user
     * @returns {String}
     */
    getSetForExplorer() {
        return this.set_for_explorer_
    }
    /**
     * Get the display text used in Blockly Fields
     * @returns {String}
     */
    getDisplayText() {
        return this.getBlocklyText()
    }
    /**
     * Get the display text used in Blockly Dropdown options
     * @returns 
     */
    getBlocklyText() {
        return `Variable: ${this.variable.title}`
    }

    /**
     * Get the vuejs component for the creator can be loaded in vue by:
     * ```
     * <component :is="variable.vue_component" :variable="variable"></component>
     * ```
     * @returns 
     */
    getVueJSCreatorComponent() {
        return 'template'
    }
    /**
     * Get the vuejs component for the explorer can be loaded in vue by:
     * ```
     * <component :is="variable.vue_component" :variable="variable"></component>
     * ```
     * @returns 
     */
    getVueJSExplorerComponent() {
        return 'template'
    }

    /**
     * get the computed value to show in the variables tab
     * depending on the value and if we set the default value for the of the variable if explorer user
     * @returns 
     */
    getComputedValue() {
        if (this.getValue() === null && this.getSetForExplorer() === true && VariablesService.getIsExplorerMode()) {
            return this.getDefaultValue()
        }
        return this.getValue()
    }

    /**
     * Sets the scope of the variable. should not be set manually as this is set by the provider
     * @param {*} scope 
     */
    setScope(scope) {
        this.scope = scope;
        return this
    }

    /**
     * Return a json object that is use to save meta data about the variable which can be reload later
     * This should be overrideen to add any more detail on the variable we need to save in the json object
     * @returns 
     */
    getMetaJSON() {
        return {
            display_type: this.getDisplayType(),
            display_order: this.getDisplayOrder(),
            set_for_explorer: this.getSetForExplorer(),
        }
    }

    /**
     * Converts the variable to a json object that can be saved to a firestore document
     * This is not meant to be overriden. for extra data use getMetaJSON
     * @returns 
     */
    toJSONForSave() {
        return {
            meta: this.getMetaJSON(),
            description: this.getDescription(),
            id: this.getId(),
            type: this.getType(),
            title: this.getTitle(),
            value: this.getValue(),
        }
    }

    /**
     * used to rehydrate the variable from a json object that was saved in a firestore document
     * @param {*} json 
     * @returns 
     */
    fromJSONSave(json) {
        this.variable = {
            id: json.id,
            title: json.title,
            value: json.value === undefined ? null : json.value,
            description: json.description,
        }
        this.display_type_ = json.meta.display_type
        this.display_order_ = json.meta.display_order
        this.set_for_explorer_ = json.meta.set_for_explorer === undefined ? false : (json.meta.set_for_explorer === true)
        this.default_value_ = this.variable.value // from setup this is the value

        // Default value is for explorers only. The value set by the creator will be copied here to set a default
        // if set for explorer is true and the value is null the default value will be used
        if(VariablesService.getIsExplorerMode() && this.set_for_explorer_ === false) {
            // we want a null initial value for the explorer. to force the explorer to choose/enter a new value.
            this.variable.value = null
        }
        return this
    }

    isValid() {
        return this.errorMessages_.length === 0 && this.warningMessages_.length === 0
    }

    validate() {
        this.clearErrors()
        return this
    }

    /**
     * Clear all warnings on the variable
     * @returns 
     */
    clearWarnings() {
        if(this.warningMessages_.length > 0) {
            this.warningMessages_ = []
            this.events.updatedValidateMessages.next(this)
        }
        return this
    }
    /**
     * Clear all warnings on the variable
     * @returns 
     */
    clearErrors() {
        if(this.errorMessages_.length > 0) {
            this.errorMessages_ = []
            this.events.updatedValidateMessages.next(this)
        }
        return this
    }
    /**
     * adds a warning message to the variable. Warnings come from Blockly
     * @param {*} message 
     * @param {*} key 
     * @returns 
     */
    appendWarning(message, key, silent = false) {
        this.warningMessages_.push({message, key})
        if(silent === false) {
            this.events.updatedValidateMessages.next(this)
        }
        return this
    }

    /**
     * remove a warning message from the variable. Warnings come from Blockly
     * @param {*} key
     * @returns 
     */
    removeWarning(key, silent = false) {
        this.warningMessages_ = this.warningMessages_.filter(warning => warning.key !== key)
        if(silent === false) {
            this.events.updatedValidateMessages.next(this)
        }
        return this
    }

    /**
     * adds a error message to the variable
     * @param {*} message 
     * @param {*} key 
     * @returns 
     */
    appendError(message, key, silent = false) {
        this.errorMessages_.push({message, key})
        if(silent === false) {
            this.events.updatedValidateMessages.next(this)
        }
        return this
    }

    /**
     * Get all the error on the variable
     * @returns {Array<String>}
     */
    getErrors(includeErrors = true, includeWarnings = true) {
        return [
            ...(includeErrors ? this.errorMessages_ : []), 
            ...(includeWarnings ? this.warningMessages_ : []), 
        ]
    }
        /**
     * Append the block id for a block the date variable is used in.
     * This is called within Capture State Visitor
     * @param {*} blockId 
     * @param {*} stateId 
     * @returns 
     */

    appendBlockId(blockId, stateId) {
        let blockIdsObj = {}

        if(this.extraState.block_ids !== undefined) {
            blockIdsObj = this.extraState.block_ids
        }
        if (blockIdsObj[stateId] === undefined) {
            blockIdsObj[stateId] = []
        }

        blockIdsObj[stateId].push(blockId)
        
        this.setExtraState('block_ids',blockIdsObj)

        return this
    }
    /**
     * Set dataset data as empty to be overwritten by the area variable
     */
    getGlobalDatasetData() {
        return {}
    }
}