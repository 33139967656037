import {BlockDuplicateFinishedEvent as DuplicateFinishedEvent} from '@/events/duplicate_finished'

/**
 * Monkey Patch the Clipboard events fire a duplicate event to trigger visitors
 **/

// holds copied data for use later in paste function. Mimicks Blockly
let copyDataShadow = null

export const rebindWorkspacePaste = (Blockly, workspace) => {
    // https://github.com/google/blockly/blob/blockly-v9.3.3/core/clipboard.ts#L51
    const oldPasteFunc = workspace.paste.bind(workspace)
    workspace.paste = function(arg1) {
        Blockly.ebxPaste_ = true
        const duplicatedBlock = oldPasteFunc(arg1);
        Blockly.ebxPaste_ = undefined
        if(copyDataShadow && duplicatedBlock) {
            Blockly.Events.fire(new DuplicateFinishedEvent(duplicatedBlock, 'paste', duplicatedBlock ? duplicatedBlock.name : null, copyDataShadow));
        }
        return duplicatedBlock
    }.bind(workspace)

}

export const init = (Blockly) => {

    const blocklyClipboardFunctions = {
        copy: Blockly.clipboard.TEST_ONLY.copyInternal,
        duplicate: Blockly.clipboard.TEST_ONLY.duplicateInternal,
    }

    // https://github.com/google/blockly/blob/blockly-v9.3.3/core/clipboard.ts#L23
    Blockly.clipboard.TEST_ONLY.copyInternal = function(toCopy) {
        copyDataShadow = toCopy
        return blocklyClipboardFunctions.copy(toCopy);
    }

    // Note: uses paste under the hood but the paste function is different to that from above.
    // https://github.com/google/blockly/blob/blockly-v9.3.3/core/clipboard.ts#L65
    Blockly.clipboard.TEST_ONLY.duplicateInternal = function(toDuplicate) {
        Blockly.ebxDuplicate_ = true
        const duplicatedBlock = blocklyClipboardFunctions.duplicate(toDuplicate);
        Blockly.ebxDuplicate_ = undefined
        if(copyDataShadow && duplicatedBlock) {
            Blockly.Events.fire(new DuplicateFinishedEvent(duplicatedBlock, 'duplicate', duplicatedBlock ? duplicatedBlock.name : null, copyDataShadow));
        }
        return duplicatedBlock
    }
}