/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import { 
    setDropdownPreviousValue,
    addDropdownInput,
    addFieldTextInput,
} from '@/blocks/helper_functions'
import {FieldNoTrimDropdown} from '@/fields/FieldNoTrimDropdown';
import { 
    NO_DATA_DROPDOWN_VALUE, VALID_BANDNAME_REGEX, INVALID_BANDNAME_ERROR, VALID_PROPNAME_REGEX, NON_BLOCKING_BLOCKLY_WARNING_PREFIX
} from '@/constants/nextGenConstants';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock';
import { ContentService } from '@/services/content.service';

const DEFAULT_BAND_SELECT_OPTIONS = [["Select an option", NO_DATA_DROPDOWN_VALUE]];
const DEFAULT_DATASET_SELECT_OPTIONS = [
    ["All areas of interest", NO_DATA_DROPDOWN_VALUE],
    ["1km Grid", "1km_grid"]
];
const DEFAULT_USER_BAND_NAME = "My Band";
const DEFAULT_USER_ATTRIBUTE_NAME = "my_attribute";
const ENABLE_LOGGING = true;
const BLOCK_NAME = 'analysis_zonal_statistics';
const FIELD = Object.freeze({
    BANDS: "zonal_stats_bands",
    METHOD: "zonal_stats_method",
    BAND_NAME: "zonal_stats_band_name",
    ATTRIBUTE_NAME: "zonal_stats_attribute_name",
    DATASET: "zonal_stats_dataset",
    ZONE_BANDS: "zonal_stats_zone_bands",
    DATES: "zonal_stats_zone_dates"
})

const methods = [
    ['Median', 'median'],
    ['Mean', 'mean'],
    ['Sum', 'sum'],
    ['Min', 'min'],
    ['Max', 'max'],
    ['Mode', 'mode'],
    ['Count', 'count'],
    ['Standard Deviation', 'stdDev'],
    ['Variance', 'variance'],
]

var zonalStatisticsJson = {
    "type": BLOCK_NAME,
    "message0": "%1 %2 %3 %4 %5 %6 %7 %8 %9 %10 %11 %12 %13 %14",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "zonal_stats_title",
            "text": "%{BKY_ANALYSIS_ZONAL_STATISTICS_BLOCK_TITLE}", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy",
            "name":"title"
        },
        {
            "type": "field_label_serializable",
            "name": "zonal_stats_band_label",
            "text": "%{BKY_ANALYSIS_ZONAL_STATISTICS_BLOCK_BAND}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.BANDS,
            "options": DEFAULT_BAND_SELECT_OPTIONS
        },
        {
            "type": "input_dummy",
            "name":"bands"
        },
        {
            "type": "field_label_serializable",
            "name": "zonal_stats_band_method_label",
            "text": "%{BKY_ANALYSIS_ZONAL_STATISTICS_BLOCK_METHOD}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.METHOD,
            "options": methods
        },
        {
            "type": "input_dummy",
            "name":"methods"
        },
        {
            "type": "field_label_serializable",
            "name": "dataset_label",
            "text": "%{BKY_ANALYSIS_ZONAL_STATISTICS_BLOCK_ZONE_DATASET_LABEL}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.DATASET,
            "options": DEFAULT_DATASET_SELECT_OPTIONS, //These options will come from the define block
        },
        {
            "type": "input_dummy",
            "name": "post_dataset"
        },
        {
            "type": "field_label_serializable",
            "name": "zonal_stats_band_name_label",
            "text": "%{BKY_ANALYSIS_ZONAL_STATISTICS_BLOCK_BAND_NAME}"
        },
        {
            "type": "field_input",
            "name": FIELD.BAND_NAME,
            "text": DEFAULT_USER_BAND_NAME,
        },
        {
            "type": "input_dummy",
            "name": "post_name"
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "calculate",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks[BLOCK_NAME] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,

    FIELD: FIELD,
    ZONE_BANDS_DROPDOWN: "zonal_stats_zone_bands_dropdown",
    ZONE_BANDS_INPUT_NAME: "zonal_stats_zone_bands_input", // note not the same as the field name,
    ZONE_DATES_INPUT_NAME: "zonal_stats_zone_dates_input",
    onInit: function() {
        this.jsonInit(zonalStatisticsJson);
        this.about_block_url = ContentService.getAboutBlockUrl('analysis_zonal_statistics')
        this.blockLog("Zonal Block INITIALISING.");
        this.getField(this.FIELD.BAND_NAME)?.setValidator(this.bandNameValidator);
        this.getField(this.FIELD.BANDS)?.setValidator(this.bandValidator);
        this.getField(this.FIELD.METHOD)?.setValidator(this.methodValidator);
        this.getField(this.FIELD.DATASET)?.setValidator(this.datasetValidator);

        this.blockLog("Zonal Block INIT complete.");
    },

    accept: async function (visitor) {
        let message = "Accepting the populate state visitor";
        console.log(message);
        this.blockLog(message)
        await visitor.visitAnalysisZonalStatisticsBlock(this);
    },

    blockLog: function(message){
        /*
        disable by setting ENABLE_LOGGING to false for production
        a logger that takes a message and adds some useful block specific information like block id and some of the states we use for the EBX app
        */
        let prefix = "ANALYSIS:"; // useful for making something stand out in the console
        if (ENABLE_LOGGING){
            console.log(`BLOCK:${this.id} ${prefix}: ${message}`);
            console.log(`\t${prefix} isLoadingState: ${this.isLoadingState()}`);
            console.log(`\t${prefix} userChangedBandName: ${this.getState('userChangedBandName')}`);
            console.log(`\t${prefix} autoChangeBandName: ${this.hasState('autoChangeBandName')}`);
        }
    },

    onLoadExtraState(state) {
        this.blockLog("In onLoadExtraState");
        if(Object.keys(state).length > 0 && state.userChangedBandName === undefined) {
            if(this.isLoadingWorkflow()) {
                this.blockLog("Setting userChangedBandName to true and autoChangeBandName to false")
                this.setState('userChangedBandName', true)
                this.setNonPersistentState('autoChangeBandName', false)
            } else {
                this.setNonPersistentState('autoChangeBandName', true)
            }
        }

        if (state[this.ZONE_BANDS_DROPDOWN]) {
            // create the dropdown, set the options and set the value
            const options = state[this.ZONE_BANDS_DROPDOWN]

            if (options.length === 0) {
                this.blockLog("No options for zone bands, not adding")
                return state
            }

            if (options.length === 1 && options[0][1] === NO_DATA_DROPDOWN_VALUE) {
                this.blockLog("Only one option for zone bands, not adding")
                return state
            }

            addDropdownInput(this, this.ZONE_BANDS_INPUT_NAME, Blockly.Msg.ANALYSIS_ZONAL_STATISTICS_BLOCK_ZONE_BAND_LABEL, FIELD.ZONE_BANDS, options, null, this.zoneBandValidator, FieldNoTrimDropdown);
            this.moveInputAfter(this.ZONE_BANDS_INPUT_NAME, 'post_dataset');
        }

        if(state['isVector']) {
            this.updateVectorChanges_()
        }

        this.blockLog("Completed onLoadExtraState");
        return state
    },

    bandValidator: function(newValue){
        let block = this.getSourceBlock();
        block.blockLog(`New value for ${block.FIELD.BANDS} ${newValue}`);
        let generatedValue = `${block.getFieldValue(block.FIELD.METHOD)}_${newValue}`;
        block.setOutputNameValue(generatedValue);
        return newValue;
    },
    generateAttributeName_: function(dataset, dates) {
        if(['range','single_composite'].indexOf(this.getState('cadence')) >= 0) {
            return `${dataset.replaceAll(' ','_')}`
        }
        return `${dataset.replaceAll(' ','_')}_${dates.replaceAll(' ','_')}`
    },


    methodValidator: function(newValue){
        let block = this.getSourceBlock();
        block.blockLog(`New value for ${block.FIELD.METHOD} ${newValue}`)
        if(block.getState('isVector') === false) {
            let generatedValue = `${newValue}_${block.getFieldValue(block.FIELD.BANDS)}`;
            block.setOutputNameValue(generatedValue);
            
            return newValue;
        }
        
        return newValue;    
    },
    dateValidator: function(newValue){
        let block = this.getSourceBlock();
        block.blockLog(`New value for ${block.FIELD.DATES} ${newValue}`)
        if(block.getState('isVector')) {
            if(newValue !== NO_DATA_DROPDOWN_VALUE && block.getFieldValue(FIELD.DATASET) !== null && block.getFieldValue(FIELD.DATASET) !== NO_DATA_DROPDOWN_VALUE){
                let generatedValue = block.generateAttributeName_(block.getField(block.FIELD.DATASET).getText(), this.getTextFromOptionsUsingValue(newValue));
                block.setOutputNameValue(generatedValue);
            }
            return newValue;
        }
        let generatedValue = `${newValue}_${block.getFieldValue(block.FIELD.BANDS)}`;
        block.setOutputNameValue(generatedValue);
        return newValue;    
    },

    zoneBandValidator: function(newValue) {
        console.log('Zone Bands validator, newValue ====' , newValue);
        let block = this.getSourceBlock();
        block.blockLog("In Zone Band validator");

        return newValue;
    },

    bandNameValidator: function(newValue) {
        let block = this.getSourceBlock();
        block.blockLog(`New value for ${block.FIELD.BAND_NAME} ${newValue}`);
        if(
            block.isLoadingState() === false 
            && block.hasState('autoChangeBandName') === false 
            && block.getFieldValue(block.FIELD.BAND_NAME) !== newValue
            && newValue !== DEFAULT_USER_BAND_NAME
            ) {
            block.blockLog("Setting userChangedBandName to true");
            block.setState('userChangedBandName', true);
        }
        block.blockLog("Removing autoChangeBandName");
        block.removeState('autoChangeBandName');
        return newValue;
    },

    attributeNameValidator: function(newValue) {
        let block = this.getSourceBlock();
        block.blockLog(`New value for ${block.FIELD.ATTRIBUTE_NAME} ${newValue}`);
        if(
            block.isLoadingState() === false 
            && block.hasState('autoChangeBandName') === false 
            && block.getFieldValue(block.FIELD.ATTRIBUTE_NAME) !== newValue
            //&& newValue !== block.generateAttributeName_()
            ) {
            block.blockLog("Setting userChangedBandName to true");
            block.setState('userChangedBandName', true);
        }
        block.blockLog("Removing autoChangeBandName");
        block.removeState('autoChangeBandName');
        return newValue;
    },

    datasetValidator: function(newValue) {
        let block = this.getSourceBlock();

        if(block.getState('isVector')) {
            if(newValue !== NO_DATA_DROPDOWN_VALUE && block.getField(FIELD.DATES) !== null && block.getFieldValue(FIELD.DATES) !== NO_DATA_DROPDOWN_VALUE) {
                let generatedValue = block.generateAttributeName_(this.getTextFromOptionsUsingValue(newValue), block.getField(block.FIELD.DATES).getText());
                block.setOutputNameValue(generatedValue);
            }
            return newValue;
        }

        const shouldBandsDropdownBeVisible = newValue !== NO_DATA_DROPDOWN_VALUE && newValue !== '1km_grid';
        
        if(!shouldBandsDropdownBeVisible && !block.isLoadingWorkflow()) {
            block.removeInputIfExists(block.ZONE_BANDS_INPUT_NAME);
        }
        return newValue
    },

    updateShape_: function() {
        this.blockLog("In updateShape_ for Zonal Statistics");

        this.updateVectorChanges_()

        if (this.hasStateChanged(FIELD.DATASET) || this.hasStateChanged('vectors')) {
            const availableDatasets = this.getState(FIELD.DATASET);
            const datasetOptions = this.getState('isVector') ? availableDatasets : [...DEFAULT_DATASET_SELECT_OPTIONS, ...availableDatasets];
            this.getField(FIELD.DATASET).updateOptions(datasetOptions);
            setDropdownPreviousValue(this, FIELD.DATASET, this.getFieldValue(FIELD.DATASET));
        }

        let bandsOptions = this.getState(this.ZONE_BANDS_DROPDOWN);
        
        //If there are bands:
        const shouldBandsDropdownBeVisible = Array.isArray(bandsOptions) && bandsOptions.length > 0 && bandsOptions[0][0] !== "No thematic bands available"

        if(this.hasStateChanged(this.ZONE_BANDS_DROPDOWN)) {
            if(shouldBandsDropdownBeVisible) {
                this.blockLog(`Bands Dropdown has been updated by the visitor to ${bandsOptions}`);
                if (this.getField(FIELD.ZONE_BANDS)) {
                    this.blockLog("Updating zonal band options");
                    this.getField(FIELD.ZONE_BANDS).updateOptions(bandsOptions);
                } else {
                    // add new input with bands dropdown
                    this.blockLog("Adding new bands input");
                    addDropdownInput(this, this.ZONE_BANDS_INPUT_NAME, Blockly.Msg.ANALYSIS_ZONAL_STATISTICS_BLOCK_ZONE_BAND_LABEL, FIELD.ZONE_BANDS, bandsOptions, null, this.zoneBandValidator, FieldNoTrimDropdown);
                    this.getField(FIELD.ZONE_BANDS).setValue(bandsOptions[0][1]);
                    this.moveInputAfter(this.ZONE_BANDS_INPUT_NAME, 'post_dataset');
                }
            } else {
                this.removeInputIfExists(this.ZONE_BANDS_INPUT_NAME);
            }
            
        }

        

        this.updateBandsField_();
        this.updateBandNameField_();
        this.updateMethodField_();
    },

    updateVectorChanges_: function() {
        if(this.hasStateChanged('isVector')) {
            if (this.getState('isVector')) {
                // Move Dataset Field Up
                this.moveInputAfter('post_dataset', 'title');
                this.getField('dataset_label').setValue(Blockly.Msg.ANALYSIS_ZONAL_STATISTICS_BLOCK_ZONE_RASTER_DATASET_LABEL);
                // Remove Bands use Zonal Bands Instead
                this.removeInputIfExists('bands');
                // Set New Attribute Name, remove New Band Name
                if(this.fieldExists(FIELD.BAND_NAME)) {
                    this.removeInputIfExists('post_name')
                    addFieldTextInput(this,'post_name', Blockly.Msg.ANALYSIS_ZONAL_STATISTICS_BLOCK_ATTRIBUTE_NAME, DEFAULT_USER_ATTRIBUTE_NAME, FIELD.ATTRIBUTE_NAME, null, this.attributeNameValidator);
                    this.setState('userChangedBandName', false)
                }
            }else {
                // Move Dataset Field Down
                this.moveInputAfter('post_dataset', 'methods');
                this.getField('dataset_label').setValue(Blockly.Msg.ANALYSIS_ZONAL_STATISTICS_BLOCK_VECTOR_DATASET_LABEL);
                // Add Bands Field for Dataet
                addDropdownInput(this,'bands', Blockly.Msg.ANALYSIS_ZONAL_STATISTICS_BLOCK_BAND, FIELD.BANDS, this.getState(this.FIELD.BANDS), null, null, FieldNoTrimDropdown);
                this.moveInputAfter('bands', 'title');
                // Remove Dates Field
                this.removeInputIfExists(this.ZONE_DATES_INPUT_NAME);
                // Set New Band Name, remove New Attribute Name
                if(this.fieldExists(FIELD.ATTRIBUTE_NAME)) {
                    this.removeInputIfExists('post_name')
                    addFieldTextInput(this,'post_name', Blockly.Msg.ANALYSIS_ZONAL_STATISTICS_BLOCK_BAND_NAME, DEFAULT_USER_BAND_NAME, FIELD.BAND_NAME, null, this.bandNameValidator);
                    this.setState('userChangedBandName', false)
                }
            }
        }
        if(this.getState('isVector') && this.hasStateChanged('dates')) {
            if(this.fieldExists(FIELD.DATES)) {
                this.getField(FIELD.DATES).updateOptions(this.getState('dates'));
            }else{
                addDropdownInput(this, this.ZONE_DATES_INPUT_NAME, Blockly.Msg.ANALYSIS_ZONAL_STATISTICS_BLOCK_DATE, FIELD.DATES, this.getState('dates'), null, this.dateValidator, FieldNoTrimDropdown);
                this.moveInputAfter(this.ZONE_DATES_INPUT_NAME, 'methods');
            }
            if(this.fieldExists(FIELD.ATTRIBUTE_NAME) && this.getFieldValue(FIELD.ATTRIBUTE_NAME)==='') {
                if(this.getFieldValue(FIELD.DATASET) !== NO_DATA_DROPDOWN_VALUE && this.getFieldValue(FIELD.DATES) !== NO_DATA_DROPDOWN_VALUE) {
                    this.setFieldValue(this.generateAttributeName_(this.getField(FIELD.DATASET).getText(), this.getField(FIELD.DATES).getText()), FIELD.ATTRIBUTE_NAME);
                    this.setState('userChangedBandName', false)
                }
            }
        }
    },

    updateMethodField_ : function(){
        //noop
        if (!this.hasStateChanged(this.FIELD.METHOD)) {
            return;
        }
    },

    updateBandNameField_ : function(){
        //noop
        if (!this.hasStateChanged(this.FIELD.BAND_NAME)) {
            return;
        }

    },

    setOutputNameValue(generatedValue, forceChange = false) {
        const fieldName = this.getState('isVector') ? FIELD.ATTRIBUTE_NAME : FIELD.BAND_NAME;
        this.blockLog(`Inside setOutputNameValue`);
        if(this.hasState('autoChangeBandName') && forceChange === false) {
            this.blockLog("setOutputNameValue is a NOOP");
            return;
        }
        
        if(this.getState('userChangedBandName') == false || this.getState('userChangedBandName') == null || forceChange === true) {
            this.setNonPersistentState('autoChangeBandName', true);
            this.blockLog(`Setting ${fieldName} field value to ${generatedValue}`);
            this.setFieldValue(generatedValue, fieldName);
            this.removeState('autoChangeBandName');
        } else {
            this.blockLog(`Not autofilling ${fieldName}, user has already typed`);
        }
    },

    updateBandsField_: function() {
        if (!this.hasStateChanged(this.FIELD.BANDS)) {
            return;
        }
        if(this.fieldExists(this.FIELD.BANDS)) {
            this.blockLog("Updating main band options");
            const bandsField = this.getField(this.FIELD.BANDS);
            const bandsOptions = this.getState(this.FIELD.BANDS);
            bandsField.updateOptions(bandsOptions);
        }
    },
    
    ebxValidate: function(toolTips) {
        this.blockLog("Validating zonal block inputs")
        let band = this.getFieldValue(FIELD.BANDS);
        let output_name = this.getFieldValue(this.getState('isVector') ? FIELD.ATTRIBUTE_NAME : FIELD.BAND_NAME);
        let outputRegex = this.getState('isVector') ?  VALID_PROPNAME_REGEX : VALID_BANDNAME_REGEX;
        let outputTooltip = this.getState('isVector') ? 'no_attribute_name' : 'no_band_name';
        let dataset = this.getFieldValue(FIELD.DATASET);
        let method = this.getFieldValue(FIELD.METHOD);

        this.setWarningText(null);

        if(this.getState('isVector')) {
            if(dataset === NO_DATA_DROPDOWN_VALUE || dataset === '' || !dataset) {
                const warning = toolTips['no_dataset'] || Blockly.Msg.ANALYSIS_ZONAL_STATISTICS_BLOCK_DATASET_ERROR;
                this.setWarningText(warning, "no_dataset");
                return
            }
            let dates = this.getFieldValue(FIELD.DATES);
            if(dates === NO_DATA_DROPDOWN_VALUE || dates === '' || !dates) {
                const warning = toolTips['no_date'] || Blockly.Msg.ANALYSIS_ZONAL_STATISTICS_BLOCK_DATE_ERROR;
                this.setWarningText(warning, "no_date");
                return
            }

            if(this.getState('cadence') === 'range') {
                const warning = toolTips['range_cadence'] || Blockly.Msg.ANALYSIS_ZONAL_STATISTICS_BLOCK_CADENCE_ERROR;
                this.setWarningText(warning, NON_BLOCKING_BLOCKLY_WARNING_PREFIX+"range_cadence");
                return
            }
        } else {
            if (!band || band === NO_DATA_DROPDOWN_VALUE) {
                if (toolTips) {
                    const warning = toolTips['band_not_selected'];
                    this.setWarningText(warning, "band_not_selected");
                    return
                }
            } 
        }
        if (!method || method == "") {
            if (toolTips) {
                console.log(JSON.stringify(toolTips));
                const warning = toolTips['no_method'];
                this.setWarningText(warning, "no_method");
                return
            }
        }
        if (!output_name || output_name == "") {
            if (toolTips) {
                console.log(JSON.stringify(toolTips));
                const warning = toolTips[outputTooltip] || Blockly.Msg.ANALYSIS_ZONAL_STATISTICS_BLOCK_OUTPUT_ERROR;
                this.setWarningText(warning, outputTooltip);
                return
            }
        }

        const test = outputRegex.test(output_name);
        if(!test) {
            return this.setWarningText(INVALID_BANDNAME_ERROR)
        }

        
    },
    
}