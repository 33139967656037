<template>
    <div class="ebx-templates">
        <div class="dataset-page--back-action ebx-action-text ebx-action-text--primary ebx-action-text__icon" @click="exitView">
            <span class="material-icons-outlined ebx-icon">chevron_left</span>
            <p>Back to Projects</p>
        </div>
        <h1 class="ebx-header-2">{{projectsTitle}}</h1>
        <EbxTemplateWorkflows 
        v-show="!loadingTemplates" 
        v-model="loadingTemplates" 
        :title="projectsTitle"  />
        <md-progress-spinner v-if="loadingTemplates"
            md-mode="indeterminate" 
            class="static-loading-spinner md-accent assets-tab--loading"
            :md-diameter="80"
        ></md-progress-spinner>
    </div>
    
</template>

<script>

import EbxTemplateWorkflows from '@/components/EbxComponents/EbxTemplateWorkflows.vue'

export default {
    name: 'TemplateWorkflows',
    components: {
        EbxTemplateWorkflows
    },
    data() {
        return {
            projectsTitle: "Choose a template",
            loadingTemplates: true,
        }
    },
    created() {
        this.loadingTemplates = true;
    },
    methods: {
        exitView() {
            this.$router.push( '/projects')
        }
    }
}
</script>