<template>
    <div class="table-creator--row-body table-creator--row-statistic">
        <div>
            <label>
                Dataset
            </label>
            <md-field>
                <md-select v-model="selected.dataset" placeholder="Select">
                    <md-option v-for="option in datasetOptions" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </md-option>
                </md-select>
            </md-field>
        </div>
        <div>
            <template v-if="showBandOption">
                <label>
                    Image band
                </label>
                <TableMultiSelect :selected="selected.properties" :options="datasetProperties"
                    :allName="'All bands'" 
                    :display-text="selectedBandsDisplayText" 
                    v-model="selected.properties"
                    :enableSelectAll="false" mode="single"
                    label="Image band" ref="propertySelector"
                >
                    <!-- <template v-slot:after="data">
                        <button v-if="selectedMultipleProperties"
                            class="md-list-item-button md-list-item-container md-button-clean md-list-item-button--transformation"
                            @click.prevent="handleLoadTransformDialog('properties', data)">
                            <div class="md-list-item-content md-ripple">
                                <template v-if="hasPropertiesTransform">
                                    Edit Transform
                                </template>
                                <template v-else>
                                    Transform +
                                </template>
                            </div>
                        </button>
                    </template> -->
                </TableMultiSelect>
            </template>
        </div>
        <div>
            <template v-if="showDateOption">
                <label>
                    Dates
                </label>
                <TableDateSelect 
                    :selected="selected.dates" 
                    :options="datasetDates"
                    v-model="selected.dates"
                /> 
            </template>
        </div>

        <div>
            &nbsp;
        </div>
        <div class="md-table-cell--delete">
            <md-button class="md-icon-button" @click="$emit('delete-row', row.id)">
                <md-icon>delete</md-icon>
            </md-button>
        </div>
    </div>

</template>

<script>
import TableMultiSelect from '../TableMultiSelect.vue';
import tableCreatorRowMixin from '../../mixins/tableCreatorRowMixin'
import TableDateSelect from '../TableDateSelect.vue';

const StatsList = [
    {
        name: "N",
        id: "n"
    },
    {
        name: "Mean",
        id: "mean"
    },
    {
        name: "Median",
        id: "median"
    },
    {
        name: "Min",
        id: "min"
    },
    {
        name: "Max",
        id: "max"
    },
    {
        name: "Sum",
        id: "sum"
    }
]

export default {
    components: {
        TableMultiSelect,
        TableDateSelect
    },
    name: 'TableCreatorGroupImageRow',
    mixins: [tableCreatorRowMixin],
    data() {
        const filteredProperties = this.filterDatasetProperties('thematic', 'bands', this.row.dataset)
        return {
            selected: {
                dataset: this.row.dataset || null,
                dates: this.row.dates,
                properties: this.row.properties.length ? this.getPreselectedProperties(this.row.properties, filteredProperties) : [],
                role: this.row.aggregation || this.row.role || null,
                propertiesTransform: this.row.propertiesTransform || null,
                datesTransform: this.row.datesTransform || null,
                transformPreference: this.row.transformPreference || null,
                displayType: this.row.displayType
            },
        }
    },
    props: {
        row: {
            type: Object,
            default: () => { }
        },
        datasets: {
            type: Array,
            default: () => []
        }
    },
    emits: [
        'update-row',
        'delete-row',
    ],
    computed: {
        /**
         * Generates the available roles/statistics for the selected dataset
         */
        datasetRoles() {
            return StatsList;
        },
        showDateOption() {
            return this.showBandOption && this.selected.properties.length > 0
        },
        showBandOption() {
            return this.selected.dataset !== null
        },
        //override mixin
        datasetProperties() {
            return this.filterDatasetProperties('thematic', 'bands', this.selected.dataset)
        },
        //override mixin
        datasetOptions() {
            return this.filterDatasetOptions('thematic', 'bands')
        },
    },
    watch: {
        /**
         * detect changes to transforms and update selected data with changes
         */
        row: {
            handler(newRow) {
                this.handleRowUpdated(newRow)
            },
            deep: true
        },
        selected: {
            handler() {
                if (!this.selected.dataset) {
                    this.$emit('update-row', this.selected)
                    return;
                }

                if (this.selected.dates.length === 0) {
                    return;
                }

                const dataset = this.getDataset(this.selected.dataset);
                var selected = this.computeSelectedRow(dataset, 'rows', 'role')
                this.$emit('update-row', selected);
            },
            deep: true
        }
    }
}
</script>