<template>
    <div class="workflow">
        <ProjectAccessDenied v-if="projectAccessDenied" />
        <TermsDialog v-if="showTermsDialog" v-bind:tncsVersion="tncsVersion" v-bind:tncsDoc="tncsDoc"
            v-bind:disableAccept="disableTncsAccept" tncsTitle="EARTH BLOX TERMS AND CONDITIONS"
            tncsRejectButtonTxt="Preview"
            tncsRejectAlertContent="You can explore Earth Blox in preview mode but access will be restricted and you will not be able to run worfklows. Please refresh to accept Terms and Conditions"
            tncsRejectAlertConfirm="Okay, got it" 
            @disable-accept="disableTncsAccept = $event"
        />

        <div v-if="isLoggedIn" class="workflow-row" ref="workflowRow" v-bind:style="{ flexDirection: colsFlexDirection}">
            <div 
            v-bind:style="leftColumnStyles"
            :class="['workflow-column', 'left-column', { 'workflow-column--closed': !workpaneOpen }]" 
            ref="blockCol" 
            id="blockCol">
                <md-card v-show="workpaneOpen">
                    <md-tabs :md-active-tab="openLeftTab" ref="overviewTabs" class="workpane-tabs" :class="isExplorer? 'workpane-tabs__single' : ''" md-alignment="left" @md-changed="openLeftTab=$event">
                        <md-tab id="tab-overview" md-label="Overview">
                        </md-tab>
                        <md-tab v-if="isCreator" id="tab-workspace" md-label="Workspace">
                        </md-tab>
                        <md-tab v-if="showVariablesTab" id="tab-variables" md-label="Variables">
                        </md-tab>
                       
                    </md-tabs>

                    <div class="workflow-buttons">
                        <button @click="handleWorkpaneChange" class="ebx-icon-button ebx-icon-button__no-shadow">
                            <span class="material-icons-outlined ebx-icon">keyboard_double_arrow_left</span>
                        </button>
                    </div>

                    <div class="tabs-content--container" ref="tabContent">
                        <div class="tabs-content--content">
                            <div class="overview-tab"  v-show="openLeftTab === 'tab-overview'" id="tab-overview" md-label="Overview" ref="overviewTab">
                                <div class="overview-content" v-if="isCreator || overviewDescription.length > 0" ref="overviewDescription"> 
                                    <EbxTextarea
                                        v-model="overviewDescription"
                                        :awaitingSave="savingDescription"
                                        :asyncSave="projectId !== null"
                                        :bypassInput="isExplorer"
                                        @save-description="handleProjectDescriptionSave" />
                                </div>
                                <div v-else class="overview-content--empty" ref="overviewDescription">
                                    <div class="default-state--empty">
                                        <div class="ebx-icon">
                                            <img :src="assets.icons.emptyStateFile" alt="No Description">
                                        </div>
                                        <p class="ebx-primary">
                                            This project doesn’t have a<br>description yet
                                        </p>
                                    </div>
                                </div>

                                <div v-if="isExplorer" class="overview-variable-content" ref="overviewVariables"> 
                                    <div class="workflow-divider">
                                        <div id="resizeNSButton" @mousedown="resizeWorkflowOverviewRows" v-on:dblclick="resetWorkpaneRows"></div>
                                    </div>
                                    <h2 class="ebx-primary-bold">Variables</h2>
                                    <Variables 
                                        v-if="variablesEnabled"
                                        add-area="handleAddArea"
                                        :is-explorer="isExplorer"
                                        :run-in-progress="runInProgress"
                                        @error-dialog-fired="handleErrorDialogFired"
                                    />
                                    <div v-else class="default-state--empty">
                                        <div class="ebx-icon">
                                            <img :src="assets.icons.emptyStateVariablesGrey" alt="No Variables">
                                        </div>
                                        <p class="ebx-primary">
                                            This project doesn’t have any<br>variables yet
                                        </p>
                                    </div>
                                </div>


                                
                            </div>
                            <div v-if="showVariablesTab" class="variables-tab" v-show="openLeftTab === 'tab-variables'" id="tab-variables" md-label="Variables">
                                <Variables
                                    @error-dialog-fired="handleErrorDialogFired"
                                />
                            </div>
                            <div class="blockly-tab">
                                <div class="block-workspace--empty" v-show="openLeftTab === 'tab-workspace' && workspaceEmpty" :style="{ 'margin-left': `${toolboxWidth}px` }">
                                    <div class="ebx-icon">
                                        <img :src="assets.icons.emptyStateWorkflow" alt="Empty workspace">
                                    </div>
                                    <p class="ebx-primary">Drag and drop blocks from the toolbox to start building a workflow</p>
                                </div>
                                <BlocklyComponent 
                                    v-show="openLeftTab === 'tab-workspace'" 
                                    ref="blocklyComponent" 
                                    class="blockly-container" 
                                    v-model="blockData"
                                    v-on:newWorkspace="handleBlocklyLoaded" v-bind:loadWorkflow="workflowId"
                                    v-bind:workflowRegistry="workflowRegistry" v-bind:runData="runData"
                                    v-bind:globalDatasetService="globalDatasetService"
                                    v-on:run-workflow-subset="validateWorkflows([$event])" v-on:stop-workflow="stopWorkflowRun"
                                    v-on:toolbox-width-change="recalculateEmptyStatePosition" v-on:is-empty="workspaceEmpty = $event"
                                    v-on:change="handleOnBlockChange"
                                    v-on:load-json="handleLoadingJSON"
                                ></BlocklyComponent>
                            </div>
                        </div>
                        <div class="blockly-buttons">
                            <div class="ebx-run-button mr-s">
                                <button v-if="runInProgress" name="Stop" id="ebx-run-button" type="button" class="ebx-run-button--error" @click="stopWorkflowRun()">
                                    <span class="ebx-action">Stop</span>
                                    <img class="button-icon" :src="assets.icons.stop" alt="Toggle expanded section">
                                </button>
                                <button v-else id="ebx-run-button" type="button" :disabled="runButtonDisabled" name="Run" class="ebx-run-button--pine" @click="validateWorkflows()">
                                    <span class="ebx-action">Run</span>
                                    <img v-if="runButtonDisabled" class="button-icon" :src="assets.icons.playDisabled" alt="Toggle expanded section">
                                    <img v-else class="button-icon" :src="assets.icons.play" alt="Toggle expanded section">
                                </button>
                                <!-- Workflow settings menu and trigger -->
                                <md-menu :md-offset-x="0" :md-offset-y="-70">
                                    <button class="ebx-run-button--options" md-menu-trigger>
                                        <div class="carat">
                                            <img :src="assets.icons.carat" alt="Toggle expanded section">
                                        </div>
                                    </button>
                                    <md-menu-content class="ebx-options-menu">
                                        <md-menu-item @click="handleProjectSettings" class="ebx-options-menu--option">Workflow settings</md-menu-item>
                                    </md-menu-content>
                                </md-menu>
                            </div>
                        </div>
                    </div>

                </md-card>
                <div v-show="!workpaneOpen" class="workflow--minimal-controls">
                    <div class="workflow--minimal-controls-container">
                        <button @click="handleWorkpaneChange" class="ebx-icon-button ebx-icon-button__no-shadow">
                            <span class="material-icons-outlined ebx-icon">keyboard_double_arrow_right</span>
                        </button>
                        <div class="blockly-buttons">
                            <md-button v-if="runInProgress" class="md-icon-button md-raised md-accent"
                                name="Stop" id="ebx-stop-button-2"  @click="stopWorkflowRun()">
                                
                                <md-icon>stop</md-icon>
                                <md-tooltip md-direction="right">Stop Current Workflow Run</md-tooltip>
                            </md-button>
                            <md-button v-else :disabled="runButtonDisabled" id="ebx-run-button-2" name="Run" @click="validateWorkflows()"
                                class="md-icon-button md-raised md-accent" >

                                <md-icon>play_arrow</md-icon>
                                <md-tooltip md-direction="right">Run Workflow</md-tooltip>
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="workpaneOpen" class="workflow-divider" ref="divider">
                <div id="resizeButton" @mousedown="resizeWorkflowColumns" v-on:dblclick="resetWorkpane"
                    v-if="screenWidth > 800"></div>
            </div>

            <div :class="['workflow-column', 'right-column']" ref="mapCol" id="mapCol" v-bind:style="rightColumnStyles">
                <md-card>

                    <md-tabs :md-active-tab="openTab" class="map-tabs" md-alignment="right" @md-changed="openTab=$event">
                        <template v-slot:md-tab="{ tab }">
                            {{ tab.label }} <strong class="badge" v-if="tab.data.badge">({{ tab.data.badge }})</strong>
                        </template>
                        <md-tab id="tab-map" md-label="Map">
                            <TheResultMap 
                                :orgCustomisations="orgCustomisations || {}" 
                                :isDashboardMap="false"
                                :show-loading-overlay="runInProgress"
                                :is-explorer="isExplorer"
                                ref="TheResultMap" 
                                v-on:loaded="handleMapLoaded"
                                />
                        </md-tab>
                        <md-tab v-if="dashboardTabEnabled" id="tab-dash" md-label="Dashboard">
                            <Dashboard :runResult="runResult" @update-run-result-outputs="updateRunResultOutputs" ref="Dashboard" />
                        </md-tab>
                    </md-tabs>
                </md-card>
            </div>
        </div>

        <ConfirmationModal ref="confirmClear" title="Clear Project" ok-button-text="Clear Map"
            ok-button-class="md-raised md-danger">
            <p>Your current Project is unsaved. If you load a new Project, your work will be overwritten.</p>
        </ConfirmationModal>
        <ConfirmationModal ref="confirmMapClear" title="Overwrite Map" cancel-button-text="Overwrite"
            ok-button-text="Load without areas">
            <p>This project contains predefined map areas. Do you want to overwrite your current map areas, or load the
                project without map areas?</p>
        </ConfirmationModal>
        <ConfirmationModal ref="confirmRunWithExport" :title="exportBlockModalText.title" class="modal-m"
            :cancel-button-text="exportBlockModalText.cancelButton" cancel-button-class="btn-secondary"
            ok-button-class="md-primary--confirmation-button" :ok-button-text="exportBlockModalText.confirmButton" :close-on-esc="false"
            :close-on-click-outside="false" :close-button-as-action="false" :if-warning="false">
            <p>
                Running this workflow will export <template v-if="exportBlockCount>1"> {{exportBlockCount}} datasets</template><template v-else>a dataset</template>. To avoid this, remove or disable the 
                Export dataset output block<template v-if="exportBlockCount>1">s</template> in your workflow. 
            </p>
            <!-- <p class="muted smaller mt-4">Estimated time: {{ exportEstimatedTimeString }}</p> -->
        </ConfirmationModal>

        <md-snackbar :md-position="'center'" :md-duration="10000" v-model:md-active="snackbar.visible" md-persistent
            @md-closed="handleSnackBarClosing">
            <span>{{ snackbar.message }}</span>
            <md-button v-if="snackbar.showUndo" class="md-raised md-accent" @click="handleUndoAction">
                {{ undoButtonText }}
            </md-button>
        </md-snackbar>

        <md-dialog-alert class="ebx-error-dialog" v-model:md-active="errorDialog.visible" :md-content="errorDialog.message"
            md-confirm-text="OKAY" />

        <md-dialog v-model:md-active="showDatasetIdInfo">

            <md-dialog-content>
                <ol>
                    <li>Go to the <a href="https://developers.google.com/earth-engine/datasets/catalog"
                            target="_blank">Earth Engine Data Catalog <md-icon>open_in_new</md-icon></a></li>
                    <br>
                    <li>Find the data you want to download, such as the <strong>Canada AAFC Annual Crop Inventory</strong>,
                        and click to open its dedicated webpage.</li>
                    <br>
                    <li>
                        <p>To select the asset identifier, or ID, look for the <strong>Earth Engine Snippet</strong>, and
                            copy the text between inverted commas, inside the parenthesis: ("this text here")</p>
                        <p>
                            <img :src="assets.datasetId"
                                alt="Selecting the identifier text found between inverted commas, inside the parenthesis">
                        </p>
                    </li>
                </ol>
            </md-dialog-content>

            <md-dialog-actions>
                <md-button class="md-primary" @click="showDatasetIdInfo = false">Close</md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-dialog v-model:md-active="showCommercialLicenseInfo">

            <md-dialog-content>
                <p>The dataset you've chosen could have restrictions on commercial use. Please refer to the license in the
                    Earth Engine catalog before proceeding.</p>
            </md-dialog-content>

            <md-dialog-actions>
                <md-button class="md-primary" @click="showCommercialLicenseInfo = false">Close</md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-dialog v-model:md-active="showIndicatorInfo">

            <md-dialog-content>
                <h3>{{ indicatorContent.name }}</h3>
                <p>{{ indicatorContent.description }}</p>
                <p><strong>Data sources:</strong></p>
                <ul>
                    <li v-for="url in indicatorContent.dataSourceUrls" :key="url"><a :href="url" target="_blank">{{ url }}</a>
                    </li>
                </ul>
                <p>Last updated: {{ indicatorContent.dateUpdated }}</p>
            </md-dialog-content>

            <md-dialog-actions>
                <md-button class="md-primary" @click="showIndicatorInfo = false">Close</md-button>
            </md-dialog-actions>
        </md-dialog>

        <LoadJson ref="LoadJson" @load-json-workflow="loadJsonWorkflow" />

        <TrialWarning v-if="isStripeActive" />
        <LoadingOverlay v-if="projectToBeLoaded && showLoadingOverlay" />
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/

import '../blocks'
import BlocklyComponent from "../components/BlocklyComponent.vue";
import * as Blockly from "blockly/core";
import { organisationsCollection } from "@/firebase.js";
import { AuthService } from "@/services/auth.service";
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import ProjectAccessDenied from "@/components/ProjectAccessDenied.vue";
import TheResultMap from "@/components/ResultMap/TheResultMap.vue";
import Dashboard from "@/components/Dashboard.vue";
import TermsDialog from "@/components/TermsDialog.vue";
import { GA_EVENT_PROPS } from "@/constants/gaConstants.js";
import { ManageDialogsService } from '@/services/manageDialogs.service';
import * as configConstants from "@/config/constants";
import { OrgConfig } from "@/config/main";
import { v4 as uuidv4 } from 'uuid';
import {NON_BLOCKING_BLOCKLY_WARNING_PREFIX,WORKFLOW_SAVED_TYPES,EMPTY_VARIABLES} from '../constants/nextGenConstants'
import {APP_VERSION_STRING} from '../constants/appConstants'
import { WorkflowRegistry } from '../workflow/registry'
import { saveWorkflowMixin, loadWorkflowMixin, sharedWorkflowMixin, PROJECT_STATE } from '../components/mixins/projectMixin';
import ConfirmationModal from '../components/ConfirmationModal.vue';
import authMixin from '../components/mixins/authMixin'
import stripeMixin from '../components/mixins/stripeMixin'
import LoadJson from '../components/ProjectSaveLoad/LoadJson.vue';
import {ProvidesImageExport} from '../workflow/abilities/providesImageExport';
import { validateWorkspace } from "@/blocks/workspace_validation";
import { AreaService } from '../services/area.service';
import TrialWarning from '@/components/TrialWarning.vue';
import { DatasetService } from '../services/dataset.service';
import { RegistryService } from '../services/registry.service'
import { GlobalDatasetsService} from '../services/globalDatasets.service'
import userDatasetsMixin from '@/components/mixins/userDatasetsMixin';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import EbxTextarea from '../components/EbxComponents/EbxTextarea.vue';
import { VariablesService } from '@/services/variable.service';
import {ProjectVariablesProvider} from '@/variables/providers/projectVariablesProvider';
import Variables from '@/components/Variables/Variables.vue';
import { AreaVariable } from '@/variables/area';
import { DateVariable } from '@/variables/date';
import { DateRangeVariable } from '@/variables/dateRange';
import { globalEventBus } from '@/eventbus';
import { isNotNullOrUndefined } from '@/helpers/generalHelperFunctions.js';
import assetsMixin from "@/components/mixins/assetsMixin.js";
import { BlockWorkflowFinishedEvent } from '@/events/workflow_finished';
const eventUtils = Blockly.Events;

export default {
    name: "Workflow",
    mixins: [
        authMixin,
        stripeMixin,
        userDatasetsMixin,
        sharedWorkflowMixin,
        saveWorkflowMixin,
        loadWorkflowMixin,
        assetsMixin
    ],
    props: {
        workflowId: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        openTab: 'tab-map',
        openLeftTab:'tab-workspace',
        blocklyLoaded: false,
        mapLoaded: false,
        blocklyLoadedCallbacks: [],
        blockData: [],
        runResult: {},
        runData: {
            runId: null,
            isLoading: false,
            blocks: [],
            exports: []
        },
        runExports: {},
        runInProgress: false,
        downloadPrompt: {
            isActive: false,
            model: "",
        },
        errorDialog: {
            visible: false,
            message: "",
        },
        snackbar: {
            visible: false,
            message: "",
            undoButtonText: 'Undo',
            showUndo: false,
            undoAction: null
        },
        screenWidth: window.innerWidth,
        colsFlexDirection: "row",
        tncsStatus: '',
        showTermsDialog: false,
        showIntroVideo: false,
        tncsVersion: '',
        tncsDoc: '',
        disableTncsAccept: true,
        userResponseData: {},
        disableRun: false,
        newRequests: 0,
        showDatasetIdInfo: false,
        showCommercialLicenseInfo: false,
        showIndicatorInfo: false,
        indicatorContent: {},
        workflowRegistry: new WorkflowRegistry(),
        globalDatasetService: null,
        codexDoc: null,
        toolboxWidth: 180,
        workspaceEmpty: true,
        termsAgreed: false,
        showLoadingOverlay: false,
        snapshotListeners: {
            video: null,
            terms: null,
        },
        workpaneOpen: true,
        minWorkpaneWidthCreator: '312px', // Default width for the workpane when the user is a creator or there are no variables
        minWorkpaneWidthExplorer: '544px', // if the user is an explorer and has varibles enabled
        minWorkpaneWidthAltered: null, // the size if the user has altered the workpane
        leftColumnWidth: '50%',
        rightColumnWidth: '50%',
        collapsedLeftColumnWidth: '56px',
        expandedRightColumnWidth: 'calc(100% - 56px)',
        transitionsEnabled: false,
        transitionTime: 300,
        savingDescription: false,
        variablesService: VariablesService.getAsReactiveObject(),
        projectAccessDenied: false,
        appVersion: "no version specified",
    }),
    components: {
        BlocklyComponent,
        ProjectAccessDenied,
        TheResultMap,
        Dashboard,
        TermsDialog,
        ConfirmationModal,
        LoadJson,
        TrialWarning,
        LoadingOverlay,
        EbxTextarea,
        Variables
    },
    watch: {
        isExplorer(isExplorer) {
            this.setOpenLeftTab()
            this.resetWorkpane()
            this.resetWorkpaneRows()
            if(isExplorer) {
                // hide all areas if user is now a explorer
                AreaService.getCustomAreas().forEach(area => AreaService.setVisiblityForId(area.id, false))
            } else {
                // Loop round all variables. removing any explorer created areas and showing any creator created areas
                VariablesService.getVariables().forEach(variable => {
                    if(variable instanceof AreaVariable) {
                        if(AreaService.isAreaOrCollection(variable.value)==='area') {
                            if(variable.value !== variable.default_value) {
                                AreaService.removeArea(variable.value)
                            }
                        }
                        if(AreaService.isAreaOrCollection(variable.value)==='collection') {
                            if(variable.value !== variable.default_value) {
                                AreaService.removeCollection(variable.value)
                            }
                        }
                    }
                })
                // re-enabled all areas
                const customAreas =  AreaService.getCustomAreas()
                if(customAreas.length > 0){
                    customAreas.forEach(area => AreaService.setVisiblityForId(area.id, true))
                    globalEventBus.$emit('zoom-to-area',customAreas[0])
                }
            }
            // Trigger user type change
            VariablesService.setUserType(isExplorer ? 'explorer' : 'creator')
        },
        blockData: {
            deep: true,
            handler: "updateRunInProgress",
        },
        screenWidth(newWidth) {
            let leftColumn = this.$refs.blockCol;
            let rightColumn = this.$refs.mapCol;
            if (newWidth <= 800) {
                if (this.workpaneOpen) {
                    this.colsFlexDirection = "column"
                    leftColumn.style.width = "100%";
                    rightColumn.style.width = "100%";
                } else {
                    this.colsFlexDirection = "row"
                    leftColumn.style.width = this.collapsedLeftColumnWidth;
                    rightColumn.style.width = this.expandedRightColumnWidth;
                }
                Blockly.svgResize(Blockly.getMainWorkspace());
            } else {
                this.colsFlexDirection = "row"
                if (this.workpaneOpen) {
                    leftColumn.style.width = this.leftColumnWidth;
                    rightColumn.style.width = this.rightColumnWidth;
                }
            }
        },
        user() {
            if (this.showTermsDialog === true && this.userTermsAgreed) {
                this.confirmTermsAreAgreed()

            }
        },
        blocklyLoadedCallbacks: {
            handler: function(callbacks) {
                if(this.blocklyLoaded && this.mapLoaded) {
                    AreaService.setMapReady(true)
                }
                if(callbacks.length > 0) {
                    this.handleBlocklyLoaded(Blockly.getMainWorkspace())
                }
            },
            deep: true 
        },
        $route: {
            immediate: true,
            async handler(to) {
                if (isNotNullOrUndefined(to) === false) {
                    return;
                }

                const resolveLoadWorkflow = async () => {
                    await this.onLoadWorkflow(this.projectWorkflow, this.projectMode, this.projectVariablesProvider)
                    Blockly.getMainWorkspace().zoomToFit()
                    this.showLoadingOverlay = false
                    this.$store.commit('project/setProjectToBeLoaded', false)
                }

                if (to.name === 'Workflow') {
                    if (to.params.workflowId) {
                        this.showLoadingOverlay = true
                        this.setOpenLeftTab()
                        //Check already loaded in state
                        if(this.projectToBeLoaded){
                            if(this.projectId === to.params.workflowId) {
                                VariablesService.clearVariables()
                                this.blocklyLoadedCallbacks.push(resolveLoadWorkflow)
                            } else {
                                // todo load into state then load workflow
                            }
                        } else {
                            this.$router.push({ name: 'Workflow' })
                        }
                    } else {
                        if(this.projectToBeLoaded && this.projectIsLoaded) {
                            if (this.$refs.confirmClear) {
                                const confirmed = await this.$refs.confirmClear.confirm()
                                if(confirmed) {
                                    this.clearWorkflow()
                                    AreaService.clearMap();
                                    VariablesService.clearVariables()
                                }
                            } else {
                                this.clearWorkflow()
                                AreaService.clearMap();
                                VariablesService.clearVariables()
                            }
                        }
                    }
                }
            }
        },
        openLeftTab() {
            // get the blockly workspace
            if(Blockly.getMainWorkspace()) {
                // hide the chaff: Cannot believe blockly have this method but all hail hide the chaff
                // https://developers.google.com/blockly/reference/js/blockly.workspacesvg_class.hidechaff_1_method.md
                Blockly.getMainWorkspace().hideChaff(true)
            }
        },
    },
    async beforeRouteLeave(to, from, next) {
        if(this.runInProgress === true) {
            const message = 'This project has workflows running that will be stopped. Are you sure you want to leave the page?'
            const confirmedLeave = await this.$confirmModal(message ,{
                title: 'Workflows running',
                okButtonText: 'Leave',
            })
            if (confirmedLeave === false) {
                return
            }
            this.stopWorkflowRun()
        }
        
        if (this.hasUnsavedChanges() === true) {
            const message = 'This project has unsaved changes that will not be saved. Are you sure you want to leave the page?'
            const confirmedLeave = await this.$confirmModal(message ,{
                title: 'Unsaved Changes',
                okButtonText: 'Leave',
            })
            if (confirmedLeave === false) {
                return
            }
        }
        this.clearWorkflow(true)
        const hasPinnedAreas = AreaService.getHasPinnedAreas()
        if (!hasPinnedAreas) {
            AreaService.clearMap()
        }
        VariablesService.clearVariables()
        this.userForcedExplorer = null

        this.openLeftTab = 'tab-workspace'
        this.resetWorkpane()
        this.resetWorkpaneRows()
        next()
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            // reset any open drawing tools
            vm.$store.commit('maplayers/setDrawingModeEnabled', false)
            vm.$store.commit('maplayers/setDrawingModeMode', null)
            
            if(vm.isExplorer && vm.projectIsLoaded === false) {
                return next('/projects/')
            }else {
                setTimeout(() => {
                    vm.resetWorkpane()
                    vm.resetWorkpaneRows()
                })
            }
        })
    },
    created() {
        this.appVersion = APP_VERSION_STRING
        if (this.screenWidth < 800) {
            this.colsFlexDirection = "column";
        }
        window.addEventListener('resize', this.onResize)
        this.setOpenLeftTab()

        // Create the Variables service and add it to the workspace
        this.projectVariablesProvider = new ProjectVariablesProvider()
        VariablesService
            .registerVariableType(AreaVariable, AreaVariable.NAME)
            .registerVariableType(DateVariable, DateVariable.NAME)
            .registerVariableType(DateRangeVariable, DateRangeVariable.NAME)
            .addProvider(this.projectVariablesProvider)
            .setUserType(this.isExplorer ? 'explorer' : 'creator')

        // Create the global dataset service and add the 2 providers workflow and map layers
        this.globalDatasetService = new GlobalDatasetsService()
        this.globalDatasetService
            .addProvider(this.workflowRegistry)
            .addProvider(AreaService)
            .addProvider(VariablesService)
        
        
        RegistryService.setGlobalDatasetService(this.globalDatasetService) 

        AuthService.loggedUser$.subscribe((user) => {
            if(!user || !user.uid) {
                this.cleanupListeners()
            }
        })

        window.onbeforeunload = () => {
            let dirty = this.hasUnsavedChanges() === true || this.runInProgress === true;
            return dirty ? "" : null;
        }

        this.$store.dispatch('stripe/checkStripeIntegrationStatus', this.user.orgId)
        this.$store.dispatch('stripe/queryUserSubscriptions', this.user.uid)

        if (!this.orgRef) {
            this.errorDialog.message = "You'll need to assign yourself to an organisation before you can use the workspace!";
            this.errorDialog.visible = true;
            return;
        }

        this.confirmTermsAreAgreed()

        this.datasetIdDialogSubscription = ManageDialogsService.infoDatasetId$.subscribe(() => {
            this.showDatasetIdInfo = true;
        });

        this.commercialLicenseDialogSubscription = ManageDialogsService.commercialLicenseCaveat$.subscribe(() => {
            this.showCommercialLicenseInfo = true;
        });

        this.indicatorInfoSubscription = ManageDialogsService.indicatorInfo$.subscribe((content) => {
            this.showIndicatorInfo = true;
            this.indicatorContent = content;
        });

        this.resetServiceWorkerImageCaching()

        // @todo this only works if the workflow page has loaded. If loding the app from another page
        // we need to workout how to pass this data when the block is created/mounted. maybe using the router params or vuex
        globalEventBus.$on('load-dataset', (dataset, recommendedBlocks) => {
            var newBlock = Blockly.getMainWorkspace().newBlock('workflow_insert_dataset');
            newBlock.setFieldValue(dataset, 'dataset_options')
            newBlock.initSvg();
            newBlock.render();
            newBlock.insertChildBlocks(recommendedBlocks)
        })
    },
    beforeUnmount() {
        this.datasetIdDialogSubscription.unsubscribe();
        this.commercialLicenseDialogSubscription.unsubscribe();
        this.cleanupListeners()
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        setOpenLeftTab() {
            if (this.isCreator) {
                this.openLeftTab = 'tab-workspace'
                this.leftColumnWidth = '50%'
                this.rightColumnWidth = '50%'
            } else {
                this.openLeftTab = 'tab-overview'
                this.leftColumnWidth = this.minWorkpaneWidth
                this.rightColumnWidth = `calc(100% - ${this.minWorkpaneWidth})`
            }
        },
        async handleProjectDescriptionSave(newValue) {
            if (this.projectId === null) {
                // set the new description value locally but don't save it to the database
                this.projectDescription = newValue
            } else {
                this.savingDescription = true
                await this.saveProjectSave({projectDescription: newValue}, PROJECT_STATE.DETAIL_UPDATE, true)
                this.savingDescription = false
            }
        },
        hasUnsavedChanges() {
            if(this.isExplorer){
                return false
            } else if (this.projectIsLoaded) {
                return this.projectIsChanged
            } else {
                if(this.blockData.length > 0) {
                    return true
                }
                // if user has entered a project description but not saved it, we should return true
                if (this.projectDescription && !this.projectId) {
                    return true
                }
                // bit of a rush here and it has been pointed out the below condition is confusing because of the two variables that have confusing names
                // we want the unsaved changes modal functionality to remain the same, unless the user has pinned areas on the map, in which case, we can assume that the user has done the saving they need to do
                // and therefore, if the map has items on it and they are not pinned areas, we should return true
                if (AreaService.hasItemsOnMap() && !AreaService.getHasPinnedAreas()) {
                    return true
                }
            }
            return false
        },
        cleanupListeners() {
            if(this.snapshotListeners.video) {
                this.snapshotListeners.video()
                this.snapshotListeners.video = null
            }
            if(this.snapshotListeners.terms) {
                this.snapshotListeners.terms()
                this.snapshotListeners.terms = null
            }
            this.stopWorkflowRun()
        },
        async handleLoadingJSON() {
            await DatasetService.loadDatasetList(this.user.orgId)
            this.$refs.LoadJson.showDialog()
        },
        handleBlocklyLoaded(workspace) {
            this.blocklyLoaded = true;
            // resize because we init the zoomToFit control and that changes the size of the workspace
            setTimeout(() => {
                Blockly.svgResize(workspace);
                if (this.mapLoaded) {
                    this.runBlocklyCallbacks()
                }
            })
        },
        handleMapLoaded() {
            this.mapLoaded = true;
            if (this.blocklyLoaded) {
                this.runBlocklyCallbacks()
            }
        },
        runBlocklyCallbacks() {
            if (this.mapLoaded && this.blocklyLoaded) {
                const callbacks = this.blocklyLoadedCallbacks
                this.blocklyLoadedCallbacks=[]
                if (callbacks.length > 0) {
                    callbacks.forEach((fn) => {
                        fn(Blockly.getMainWorkspace());
                    });
                }
            } else {
                console.warn('map or blockly not loaded yet')
            }
        },
        confirmTermsAreAgreed() {
            const self = this
            const handler = async (resolve) => {
                this.snapshotListeners.terms = this.orgRef.onSnapshot((orgDoc) => {
                    if (orgDoc.exists) {
                        // check if terms and conditions are switched on for the org
                        let showOrgTerms = orgDoc.data().enableTermsAndConditions;
                        if (showOrgTerms) {
                            // get terms version and current link to tnc html doc
                            const location = self.user.orgId + "/termsandconditions/current";
                            organisationsCollection.doc(location).get().then(current => {
                                if (current.exists) {
                                    self.tncsVersion = current.data().ref.id;
                                    current.data().ref.get().then((versiondoc) => {
                                        if (versiondoc.exists) {
                                            self.tncsDoc = versiondoc.data().reference;
                                        } else {
                                            self.tncsDoc = "";
                                            self.disableTncsAccept = true;
                                        }
                                    })
                                    // show T&C pop up if user hasn't agreed, or new version
                                    if (self.user.termsAgreed !== undefined) {
                                        self.showTermsDialog = !self.user.termsAgreed;
                                        self.user.termsAgreed ? self.tncsStatus = 'agree' : self.tncsStatus = 'disagree';
                                    }
                                    if (self.user.termsVersion !== self.tncsVersion) {
                                        self.showTermsDialog = true;
                                    }
                                }// if current.exists
                                resolve()
                            });
                        }
                    } else {
                        resolve()
                    }
                }, error => {
                    console.error('terms agreed', error);
                });
            }
            return new Promise(handler)
        },
        handleOnBlockChange() {
            if (this.projectIsLoaded == false) {
                this.projectChanged = true
                return;
            }
            if(this.hasProjectChanged()){
                this.changeProjectState(PROJECT_STATE.CHANGED)
                return
            } else{
                this.changeProjectState(PROJECT_STATE.SAVED)
                return
            }
        },
        async validateWorkflows(limitTopBlocksIds) {

            this.$store.commit('maplayers/setDrawingModeEnabled', false)
            this.$store.commit('maplayers/setDrawingModeMode', null)
            
            let validWorkflow = true;
            
            // Function to check for warnings on blocks. If a block contains no warnings 
            // or only warnings starting with "warning__" then they are not stoppable warnings
            // and allow the workflow to run even if we display warnings
            const isStoppableWarning = function(warning) {
                if (warning) {
                    if(typeof(warning) === 'object' && typeof(warning.text) === 'object' && warning.text !== null) {
                        const stoppableWarnings = Object.keys(warning.text).filter(key => key.startsWith(NON_BLOCKING_BLOCKLY_WARNING_PREFIX) === false);
                        return stoppableWarnings.length > 0
                    }
                    return true
                }
                return false
            }
            //revaluate valdiation rules incase any have been missed on a blockly update
            await validateWorkspace(Blockly.getMainWorkspace());

            //Not to be confused with validateWorkflows, I couldn't think of a better name
            //Takes a list of topBlockIds to check for stoppable warnings
            function validateWorkflow(topBlockIds, workspace) { 
                topBlockIds.forEach((topBlockId) => {
                    let block = workspace.getBlockById(topBlockId);
                    if (!block.disabled) {
                        // get top block descendants
                        let blockDescendants = block.getDescendants();
                        if (isStoppableWarning(block.warning)) {
                            validWorkflow = false;
                        } else if (blockDescendants && blockDescendants.length > 0) {
                            blockDescendants.forEach((descendant) => {
                                if (block.isEnabled() && descendant.isEnabled() && isStoppableWarning(descendant.warning)) {
                                    validWorkflow = false
                                }
                            })
                        }
                    }                 
                })
            }

            if (limitTopBlocksIds !== undefined) {
                //validate only the workflow that is being run
                validateWorkflow(limitTopBlocksIds, Blockly.getMainWorkspace())
            } else {
                validateWorkflow(this.blockData.map(blockData => blockData.id), Blockly.getMainWorkspace())
            }

            if (!validWorkflow) {
                this.errorDialog.message = 'You\'re running a workflow that contains a block with an active warning. Please check your work and try again.';
                this.errorDialog.visible = true;
            }

            console.log("is valid workflow: " + validWorkflow);

            if (validWorkflow) {
                this.runWorkflow(limitTopBlocksIds);
            }
        },
        resetServiceWorkerImageCaching() {
            if (navigator.serviceWorker && navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({
                    type: 'clearQueue',
                });
            }
        },
        setBlocksLoading(loadingBlocks, loading) {
            this.blockData = this.blockData.map(blockData => {
                const isRelevantBlock = loadingBlocks === null || loadingBlocks === undefined || loadingBlocks.indexOf(blockData.id) >= 0
                if(isRelevantBlock) {
                    blockData.isLoading = loading
                }
                return blockData
            })
        },
        async runWorkflow(limitTopBlocksIds) {
            this.$store.commit("runresult/setRunCompleted", false);
            console.log('Running workflow');
            this.resetServiceWorkerImageCaching()
            const runId = uuidv4().replaceAll('-', '');
            this.$store.commit('runresult/setLastRunId', runId);
            const runData = {
                runId,
                isLoading: true,
                unsubscribe: null,
                runResult: null,
                blocks: this.workflowRegistry.getRunableBlocks(limitTopBlocksIds).map(k => {
                    const workflowState = this.workflowRegistry.getWorkflowForId(k)
                    const block = Blockly.getMainWorkspace().getBlockById(workflowState.id)
                    return {
                        id: block.id,
                        type: block.type
                    }
                }),
                exports: this.workflowRegistry.gatherAbilities(ProvidesImageExport).map(e => e.toRunDoc()),
                variables: VariablesService.toJSONForRunDoc()
            }

            try {
                if (runData.blocks.length === 0) {
                    this.errorDialog.message = 'Please create a workflow';
                    this.errorDialog.visible = true;
                    return
                }
                if (runData.exports.length > 0) {
                    this.runExports = runData.exports;
                    const confirmedExport = await this.$refs.confirmRunWithExport.confirm()
                    if (confirmedExport === false) {
                        runData.exports.forEach(e => {
                            let block = Blockly.getMainWorkspace().getBlockById(e.id);
                            block.setEnabled(false);
                        })
                    }
                }
                this.runData = runData
                this.runInProgress = true

                await AuthService.checkUserAuth();
                this.errorDialog.visible = false;
                this.runResult = {}
                // this.$refs.TheResultMap.clearMap();
                this.setBlocksLoading(limitTopBlocksIds, true)

                //load any assets not loaded yet
                let workspaceRunData = this.workflowRegistry.serializeWorkspace(Blockly.getMainWorkspace(), limitTopBlocksIds)

                if (workspaceRunData.error) {
                    this.handleRunWorkflowError(workspaceRunData)
                    return;
                }

                if (this.codexDoc) {
                    this.updateCodexDocWithWorkspace(workspaceRunData)
                }

                let documentReference = await this.setRunDocForWorkflow(runId, JSON.stringify(workspaceRunData), runData.variables)
                runData.unsubscribe = documentReference.onSnapshot(async (doc) => {
                    const result = doc.data();
                    const collRef = await doc.ref.collection('outputs').get();

                    const workspace = Blockly.getMainWorkspace()
                    let topBlockIds = this.blockData.map(blockData => blockData.id)
                    let block = workspace.getBlockById(topBlockIds[0]);

                    if (result.status === "completed") {
                        runData.runResult = result;
                        runData.runResult.doc_id = runId;
                        runData.unsubscribe();
                        runData.isLoading = false
                        this.runInProgress = false
                        this.handleRunWorkflowSnapshotSuccess(result, collRef, runData)
                        this.runResult = result;
                        this.runResult.isLoading = false; // this ok?
                        this.$store.commit("runresult/setRunCompleted", true);
                        // construct map layers
                        console.debug('RUN RESULT', this.runResult)
                        this.$store.dispatch("maplayers/constructMapLayers", this.runResult);
                        this.setBlocksLoading(limitTopBlocksIds, false)
                        if (block && eventUtils.isEnabled()) {
                            eventUtils.fire(new BlockWorkflowFinishedEvent(block, 'field', this.name || null, this));
                        }
                        return
                    }
                    if (result.status === "error") {
                        runData.runResult = result;
                        runData.unsubscribe();
                        runData.isLoading = false
                        this.runInProgress = false
                        this.handleRunWorkflowSnapshotError(result, runData)
                        this.setBlocksLoading(limitTopBlocksIds, false)
                        if (block && eventUtils.isEnabled()) {
                            eventUtils.fire(new BlockWorkflowFinishedEvent(block, 'field', this.name || null, this));
                        }
                        return
                    }
                    console.warn('Received Unknown Snapshot State: ', result.status)
                }, error => {
                    console.error('Run workflow', error);
                })
            } catch (error) {
                this.handleRunWorkflowError(error)
                runData.isLoading = false
                this.runInProgress = false
                throw error
            }
        },
        async handleRunWorkflowSnapshotSuccess(result, collRef, runData) {
            console.info(`Found ${collRef.size} outputs.`);
            if (!collRef.empty) {
                let outputs = [];
                collRef.forEach((outputDoc) => {
                    let data = outputDoc.data();
                    let doc_id = outputDoc.id;
                    let output = JSON.parse(data.output)
                    data = {
                        ...output,
                        index: data.index,
                        doc_id,
                    }
                    outputs.push(data);
                });

                // if there are outputs thens ort them by index in ascending order
                if (outputs.length > 0) {
                    result.outputs = outputs.sort((a, b) => a.index - b.index);
                }
            }
            if (result.prompt !== undefined && result.prompt !== '') {
                this.errorDialog.message = result.prompt;
                this.errorDialog.visible = true;
            }
            // TODO doc.delete()
            try {
                var now = firebase.firestore.Timestamp.now()
                var time = now - runData.runResult.startedAt;
                time = time.toFixed(2);
                console.log("Block took " + time + "s");
            } catch (err) {
                console.error("Problem calculating block run time for analytics: " + err);
            }
        },
        handleRunWorkflowSnapshotError(result) {
            const errmsg = result.errmsg.length ? result.errmsg : `Error while running workflow. If this persists, contact support with id: ${result.runId}`

            this.errorDialog.message = errmsg

            this.errorDialog.visible = true;
            let event_props = {};
            event_props[GA_EVENT_PROPS.ERR_INFO] = errmsg;
        },
        handleRunWorkflowError(error) {
            if (error.code == "permission-denied") {
                if (this.showTermsDialog) {
                    this.errorDialog.message = `Preview Restricted: Please refresh browser and then accept Terms and Conditions to run a workflow`;
                } else {
                    this.errorDialog.message = `Restricted: Terms & Conditions may have changed: Please refresh browser and then accept terms and conditions`;
                }
            } else if (error.error) {
                this.errorDialog.message = `${error.error}`;
            } else {
                this.errorDialog.message = `Error while running workflow. Error: ${error.code}`;
            }
            this.errorDialog.visible = true;
            this.isLoading = false;
            this.runInProgress = false
        },
        async setRunDocForWorkflow(runId, json, variables) {
            console.log('RUNNING WORKFLOW', runId, json)
            // here we decide if the user is self registered or not
            // If they are self registered, the run doc should go into the runs collection in their user doc
            // If they are not, fall back to the old way of storing it in the organisation's runs collection
            var referenceDoc;
            this.userRef ? referenceDoc = this.userRef : referenceDoc = this.orgRef
            const newDoc = {
                    id: runId,
                    status: "started",
                    startedAt: firebase.firestore.FieldValue.serverTimestamp(),
                    submittedBy: {
                        uid: this.user.uid,
                        email: this.user.email,
                        displayName: this.user.displayName,
                        orgId: this.user.orgId
                    },
                    hasTasks: false,
                    json: json,
                    variables: variables,
                    appVersion: this.appVersion,
                }
            if (Object.keys(this.projectSettings).length > 0) {
                newDoc.ebxCoreConfig = this.projectSettings
            }
            await referenceDoc
                .collection("runs")
                .doc(runId)
                .set(newDoc)
            return referenceDoc.collection("runs").doc(runId)
        },
        stopWorkflowRun() {
            if (this.blockData) {
                this.setBlocksLoading(null, false)
                this.updateRunInProgress(this.blockData); // For some reason watch is not triggering after blocks are updated, so we call update manually
            }
            if (this.runData) {
                this.runData.isLoading = false
                if(this.runData.unsubscribe) {
                    this.runData.unsubscribe()
                    this.runData.unsubscribe = null
                }
            }
            this.runInProgress = false
            // console.debug("Logging a workflow_stop event, user "+ this.user.uid + ", org " + this.user.orgId);
        },
        updateRunInProgress(blockData) {
            this.runInProgress =
                blockData &&
                blockData.length > 0 &&
                blockData.some((block) => block.isLoading);
        },
        async onRunWorkflow(workflowName) {
            if (!workflowName || !workflowName.trim()) {
                this.errorDialog.message = "Could not run workflow with blank name";
                this.errorDialog.visible = true;
                return;
            }
            let props = {};
            props[GA_EVENT_PROPS.NAME] = workflowName;
            let workspace = Blockly.getMainWorkspace();
            try {
                const doc = await this.orgRef
                    .collection("workflows")
                    .doc(workflowName)
                    .get()

                if (!doc.exists) {
                    this.errorDialog.message = `Workflow '${workflowName}' does not exist!`;
                    this.errorDialog.visible = true;
                    return;
                }
                const workflowDataString = doc.data().blockDefinition;
                this.performWorkspaceInsertionUsingString(workspace, workflowDataString)
            } catch (error) {
                this.errorDialog.message = `Error while running saved workflow '${workflowName}'. Error: ${error}`;
                this.errorDialog.visible = true;
            }
        },
        shareWorkflow() {
            // uses Async API
            var copyToClipboard = text => {
                if (!navigator.clipboard) {
                    legacyCopyToClipboard(text);
                    return;
                }
                navigator.clipboard.writeText(text).then(() => {
                    // TODO tell user they have copied text
                }, (error) => {
                    console.error("Could not copy text: ", error)
                    // TODO tell user they have failed to copy text
                });
            }

            // if async api fails use none async legacy code
            var legacyCopyToClipboard = text => {
                let textArea = document.createElement("textarea");
                textArea.value = text;

                textArea.style.top = "0";
                textArea.style.left = "0";
                textArea.style.position = "fixed";

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    document.execCommand('copy');
                    // TODO tell user they have copied text
                } catch (err) {
                    console.error('Fallback: Oops, unable to copy', err);
                    // TODO tell user they have failed to copy text
                }

                document.body.removeChild(textArea);
            }

            let workspaceDom = Blockly.Xml.workspaceToDom(Blockly.getMainWorkspace());
            let xml = Blockly.Xml.domToText(workspaceDom);

            copyToClipboard(xml);
        },
        getrefforcap() {
            return this.$refs.mapCol;
        },
        downloadData() {  //for the half finished PNG capture capabilities
            //will need npm install of html2canvas
            let myself = this;
            this.$refs.TheResultMap.getCanvas().then(function (canvas) {
                var img = canvas
                    .toDataURL("image/png")
                    .replace("image/png", "image/octet-stream");
                myself.downloadFile(img);
            });
            return;
        },
        downloadFile(data) {
            // Create invisible a element
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);
            a.href = data;
            a.click(function (e) { e.preventDefault() });
            // Cleanup
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
        },
        resizeWorkflowOverviewRows(event) {
            event.preventDefault();

            // disable the drag resize when the workpane is closed
            if (!this.workpaneOpen) {
                return
            }

            const mouseMoveEvent = (event) => {
                event.preventDefault();
                const descriptionRow = this.$refs.overviewDescription;
                const variablesRow = this.$refs.overviewVariables;
                const overviewTab = this.$refs.overviewTab
                const minHeight = this.screenWidth < 800 ? 10 : 190;

                let totalHeight = overviewTab.offsetHeight;
                const offset = event.pageY - overviewTab.getBoundingClientRect().top;

                let descriptionRowHeight = offset;
                descriptionRowHeight = Math.max(descriptionRowHeight, minHeight);
                descriptionRowHeight = Math.min(descriptionRowHeight, totalHeight - minHeight);
                descriptionRow.style.height = `${descriptionRowHeight}px`;
                variablesRow.style.height = `${totalHeight - descriptionRowHeight}px`;
            }

            const removeMouseMoveEvent = () => {
                document.removeEventListener('mousemove', mouseMoveEvent);
                document.removeEventListener('mouseup', this);
                setTimeout(() => {
                    Blockly.svgResize(Blockly.getMainWorkspace());
                }, 50);
            }

            document.addEventListener('mousemove', mouseMoveEvent);
            document.addEventListener('mouseup', removeMouseMoveEvent);
        },
        resizeWorkflowColumns(event) {
            event.preventDefault();

            // disable the drag resize when the workpane is closed
            if (!this.workpaneOpen) {
                return
            }

            let mouseMoveTimer;
            const mouseMoveEvent = (event) => {
                event.preventDefault();
                const  divider = this.$refs.divider;

                let totalWidth = window.getComputedStyle(this.$refs.workflowRow).width;
                totalWidth = parseInt(totalWidth.replace('px', ''));
                let leftColumnWidth = (event.pageX - (divider.style.width / 2));
                let rightColumnWidth = (totalWidth - leftColumnWidth - (divider.style.width / 2));
                this.leftColumnWidth = leftColumnWidth / totalWidth * 100 + '%';
                this.rightColumnWidth = rightColumnWidth / totalWidth * 100 + '%';
                if(mouseMoveTimer) {
                    clearTimeout(mouseMoveTimer);
                }
                mouseMoveTimer = setTimeout(() => {
                    Blockly.svgResize(Blockly.getMainWorkspace());
                },10)
            }

            const removeMouseMoveEvent = () => {
                document.removeEventListener('mousemove', mouseMoveEvent);
                document.removeEventListener('mouseup', this);
                setTimeout(() => {
                    Blockly.svgResize(Blockly.getMainWorkspace());
                }, 50);
            }

            document.addEventListener('mousemove', mouseMoveEvent);
            document.addEventListener('mouseup', removeMouseMoveEvent);

        },
        onResize() {
            this.screenWidth = window.innerWidth;
        },
        handleWorkpaneChange() {
            // if the workpane is open, we want to close it
            // if the workpane is closed, we want to open it
            if (this.workpaneOpen) {
                this.collapseWorkpane();
            } else {
                this.transitionsEnabled = true;
                this.resetWorkpane();
                this.resetWorkpaneRows()
            }
        },
        collapseWorkpane() {
            Blockly.getMainWorkspace().hideChaff(true);
            if (this.colsFlexDirection === "column" && this.screenWidth < 800) {
                this.colsFlexDirection = "row";
            }
            this.workpaneOpen = false;
            this.transitionsEnabled = true;

            setTimeout(() => {
                this.cleanUpWorkpane()
            }, this.transitionTime);
        },
        resetWorkpaneRows() {
            const descriptionRow = this.$refs.overviewDescription;
            const variablesRow = this.$refs.overviewVariables;
            if(descriptionRow) {
                if (this.isExplorer) {
                    descriptionRow.style.height = '35%';
                } else {
                    descriptionRow.style.height = '100%';
                }
            }
            if(variablesRow){
                variablesRow.style.height = '65%';
            }
        },
        resetWorkpane() {
            let hasTransition = true
            if (this.colsFlexDirection === "row" && this.screenWidth < 800) {
                this.colsFlexDirection = "column";
                this.leftColumnWidth = '100%';
                this.rightColumnWidth = '100%';
                hasTransition = false
            }
            // reset min width
            this.minWorkpaneWidth = null;

            // add column styles
            if (hasTransition) {
                this.workpaneOpen = true;
            } else {
                this.transitionsEnabled = false;
                this.workpaneOpen = true;
            }
            setTimeout(() => {
                // wait for transition to complete
                this.cleanUpWorkpane()
            }, this.transitionTime);
        },
        cleanUpWorkpane() {
            this.removeTransition()
            setTimeout(() => {
                Blockly.svgResize(Blockly.getMainWorkspace());
            })
            this.toggleFreshdeskWidget();
        },
        removeTransition() {
            this.transitionsEnabled = false;
        },
        toggleFreshdeskWidget() {
            // get body element
            let body = document.getElementsByTagName('body')[0];
            let hiddenFreshdeskClass = 'hide-freshdesk-widget';
            if (!this.workpaneOpen) {
                // does body have the class?
                if (!body.classList.contains(hiddenFreshdeskClass)) {
                    // add a class to the body
                    body.classList.add(hiddenFreshdeskClass);
                }
            } else {
                // does body have the class?
                if (body.classList.contains(hiddenFreshdeskClass)) {
                    // remove the class from the body
                    body.classList.remove(hiddenFreshdeskClass);
                }
            }
        },
        clearWorkflow(silent) {
            this.resetServiceWorkerImageCaching()
            this.resetProject()
            this.workflowRegistry.clear();
            const workspace = Blockly.getMainWorkspace();
            if(workspace) {
                workspace.clear();
            }
            //Check if the result map has been loaded
            if(this.$refs.TheResultMap) {
                this.$refs.TheResultMap.clearLayers(true, !AreaService.getHasPinnedAreas());
            }
            this.blockData = [];
            this.workspaceEmpty = true;
            this.openTab = 'tab-map';
            this.runResult = {}
            // reset the last run id
            this.$store.commit('runresult/setLastRunId', null)

            if (silent !== true) {
                this.snackbar.message = `Workflow has been cleared.`;
                this.snackbar.showUndo = true
                this.undoButtonText = 'Undo'
                this.snackbar.undoAction = () => {
                    workspace.undo(false);
                    this.snackbar.visible = false
                    this.snackbar.showUndo = false
                }
                this.snackbar.visible = true;
            }

        },
        getSavedWorkflowType(workflowString) {
            if (workflowString.substring(0, 1) === '{') {
                return WORKFLOW_SAVED_TYPES.JSON
            }
            if (workflowString.substring(0, 4) === '<xml') {
                return WORKFLOW_SAVED_TYPES.XML
            }
            return WORKFLOW_SAVED_TYPES.UNKNOWN
        },
        handleUndoAction() {
            if (typeof this.snackbar.undoAction === 'function') {
                this.snackbar.undoAction()
                this.snackbar.undoAction = null
            }
        },
        handleSnackBarClosing() {
            this.snackbar.undoAction = null
            this.snackbar.showUndo = false
        },
        updateCodexDocWithWorkspace(workspaceRunData) {
            this.codexDoc.update({
                workflow: JSON.stringify(workspaceRunData),
                workflowTimestamp: new Date()
            }).then(() => {
                this.codexDoc = null;
            }).catch((error) => {
                this.codexDoc = null;
                console.error(error);
            });
        },
        loadCodex(codexJson) {
            const workspace = Blockly.getMainWorkspace();
            try {
                this.performWorkspaceInsertionUsingString(workspace, codexJson);
            } catch (error) {
                console.error(error);
                this.errorDialog.message = `Error while loading codex: ${error.message}`;
                this.errorDialog.visible = true;
            }
        },
        recalculateEmptyStatePosition(width) {
            if (width === 0) {
                return
            } else {
                this.toolboxWidth = width
            }
        },
        loadJsonWorkflow(json) {
            const workspace = Blockly.getMainWorkspace();

            const wrapInBlocklyWorkspace = (blockObj) => {
                return {
                    "blocks": {
                        "languageVersion": 0,
                        ...blockObj
                    }
                }
            }

            try {
                json = JSON.parse(json)
                const areas = JSON.stringify(json.mapAreas)

                delete json.mapAreas
                const wrappedJson = wrapInBlocklyWorkspace(json)

                this.performWorkspaceInsertionUsingString(workspace, JSON.stringify(wrappedJson));
                this.loadProjectAreasOnMap(areas);
            } catch (error) {
                console.error(error);
                this.errorDialog.message = `Error while loading workflow: ${error.message}. Check the console for more details.`;
                this.errorDialog.visible = true;
            }
        },
        handleErrorDialogFired(errorText) {
            this.errorDialog.message = errorText;
            this.errorDialog.visible = true;
        },
        handleProjectSettings() {
            this.onEditProjectSettings(this.projectWorkflow)
        },
        updateRunResultOutputs(outputs) {
            this.runResult.outputs = outputs
        },
    },
    computed: {
        leftColumnStyles() {
            return { 
                minWidth: this.workpaneOpen ? this.minWorkpaneWidth: this.collapsedLeftColumnWidth, 
                width: this.workpaneOpen ? this.leftColumnWidth : this.collapsedLeftColumnWidth,
                transition: this.transitionsEnabled ? `width ${this.transitionTime/1000}s linear` : 'none'
            }
        },
        rightColumnStyles() {
            return {
                width: this.workpaneOpen ? this.rightColumnWidth : this.expandedRightColumnWidth,
                transition: this.transitionsEnabled ? `width ${this.transitionTime/1000}s linear` : 'none'
            }
        },
        isCreator() {
            if(this.userIsForcingMode) {
                return this.userForcedExplorer === false
            }
            return authMixin.computed.isCreator.call(this)
        },
        isExplorer() {
            if(this.userIsForcingMode) {
                return this.userForcedExplorer
            }
            return authMixin.computed.isExplorer.call(this)
        },
        minWorkpaneWidth: {
            get() {
                if(this.minWorkpaneWidthAltered !== null) {
                    return this.minWorkpaneWidthAltered
                }
                if(this.isExplorer && this.variablesEnabled) {
                    return this.minWorkpaneWidthExplorer
                }
                return this.minWorkpaneWidthCreator
            },
            set(value) {
                this.minWorkpaneWidthAltered = value
            }
        },
        variablesEnabled() {
            if(this.isExplorer) {
                return this.variablesService.variables.length > 0
            }
            return this.userVariablesEnabled
        },
        showVariablesTab() {
            return this.variablesEnabled && this.isCreator
        },
        overviewDescription: {
            get() {
                return this.projectDescription || ''
            },
            set(newValue) {
                return newValue
            }
        },
        exportMessageString() {
            let outputMessage = '';
            if (this.runExports.length > 0) {
                const exportNames = this.runExports.map(e => e.name)
                const firstName = exportNames.shift()
                outputMessage = firstName
                if (exportNames.length === 1) {
                    outputMessage += ' and ' + exportNames[0]
                } else if (exportNames.length > 1) {
                    const lastName = exportNames.pop()
                    outputMessage += ', ' + exportNames.join(', ') + ' and ' + lastName
                }
            }
            return outputMessage
        },
        exportEstimatedTimeString() {
            return '60 minutes'
        },
        showCodePreview() {
            return OrgConfig.isFeatureShown(this.orgConfig, configConstants.FEATURES.CODE_PREVIEW);
        },
        disableCodePreview() {
            return OrgConfig.isFeatureDisabled(this.orgConfig, configConstants.FEATURES.CODE_PREVIEW);
        },
        dashboardTabEnabled() {
            return OrgConfig.isFeatureShown(this.orgConfig, configConstants.FEATURES.TAB_DASHBOARD);
        },
        workflowsLibraryEnabled() {
            return OrgConfig.isFeatureShown(this.orgConfig, configConstants.FEATURES.WORKFLOWS_LIBRARY);
        },
        saveWorkflowsEnabled() {
            return OrgConfig.isFeatureShown(this.orgConfig, configConstants.FEATURES.WORKFLOWS_SAVE);
        },
        loadWorkflowsEnabled() {
            return OrgConfig.isFeatureShown(this.orgConfig, configConstants.FEATURES.WORKFLOWS_LOAD);
        },
        exportBlockCount() {
            if (this.runExports && this.runExports.length > 0) {
              
                return this.runExports.length
            }
            return 0
        },
        exportBlockModalText() {
            return {
                title: this.exportBlockCount > 1 ? 'Export datasets?':'Export dataset?',
                cancelButton: this.exportBlockCount > 1 ? 'Disable blocks':'Disable block',
                confirmButton: this.exportBlockCount > 1 ? 'Continue with exports':'Continue with export',
            }
        }, 
        hasBlockWarnings() { 
            return this.$store.getters['blockly/warningsPresent']
        },
        hasEmptyVariables() {
            // check if any variables have no selected value
            return this.variablesService.variables.some(variable => EMPTY_VARIABLES.includes(variable.value) || variable.error_messages.length > 0)
        },
        runButtonDisabled() {
            if (this.hasBlockWarnings) {
                return true
            } else if (this.isExplorer && this.hasEmptyVariables) {
                return true
            } else {
                return false
            }
        },
    }
};
</script>
