/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/* eslint-disable no-prototype-builtins */

import { api } from "../firebaseFunctions.js";

export class ApiError extends Error {
    /**
     * Api Error class to handle error responses from api
     * @param {*} message 
     * @param {*} response 
     * @param {*} json 
     * @param {*} name 
     */
    constructor(message, response, json, name = 'ApiError') {
        super(message)
        this.name = name
        this.response = response
        this.json = json
    }
    getName() {
        return this.name
    }
    getResponse(){
        return this.response
    }
    getStatusCode() {
        return this.response.status
    }
    getJson(){
        return this.json
    }
    getFirstMessage(){
        const json = this.getJson()
        if(json.hasOwnProperty('message')){
            const msg = json.message
            if (typeof(msg) === 'string'){
                return msg
            }
            if (Array.isArray(msg)){
                return msg[0]
            }
            if (typeof(msg) === 'object'){
                const keys = Object.keys(msg)
                if (keys.length > 0){
                    return msg[keys[0]]
                }
                return null
            }
        }
        return this.message
    }
    getMessages(){
        const json = this.getJson()
        if(json.hasOwnProperty('message')){
            if(typeof(json.message) === 'string'){
                return { _default: json.message}
            }
            return json.message;
        }
        return null;
    }
}

export class ApiUnauthorizedError extends ApiError {
    constructor(message, response, json) {
        super(message, response, json, 'ApiUnauthorizedError')
    }
}
export class ApiForbiddenError extends ApiError {
    constructor(message, response, json) {
        super(message, response, json, 'ApiForbiddenError')
    }
}

export class ApiNoDataError extends ApiError {
    constructor(message, response, json) {
        super(message, response, json, 'ApiNoDataError')
    }
}

export class ApiNotFoundError extends ApiError {
    constructor(message, response, json) {
        super(message, response, json, 'ApiNotFoundError')
    }
}
export class ApiInternalError extends ApiError {
    constructor(message, response, json) {
        super(message, response, json, 'ApiInternalError')
    }
}

export class ApiUnknownError extends ApiError {
    constructor(message, response, json) {
        super(message, response, json, 'ApiUnknownError')
    }
}

class AbstractApi {
    /**
     * Abstract class for api to provide common functionality for handling response and errors
     * @param {*} namespace 
     */
    constructor(namespace){
        this.baseUrl = api.url(namespace);
        this.namespace = namespace
    }
    async _handleResponse(response) {
        const parseErrorBody = async function(response) {
            try {
                return JSON.parse(await response.text())
            } catch {
                return {}
            }
        }

        switch(response.status) {
            case 200: {
                const jsonData = await response.json()
                if (jsonData.hasOwnProperty('data')){
                    return jsonData.data
                }
                throw new ApiNoDataError('No data', response, jsonData)
            }
            case 401:
                throw new ApiUnauthorizedError('Unauthorized', response, await parseErrorBody(response))
            case 403:
                throw new ApiForbiddenError('Forbidden', response, await parseErrorBody(response))
            case 404:
                throw new ApiNotFoundError('Not found', response, await parseErrorBody(response))
            case 500:
                throw new ApiInternalError('Internal server error', response, await parseErrorBody(response))
            default:
                throw new ApiUnknownError('Unknown error', response, await parseErrorBody(response))
        }
    }
}



export class ManageDatasetApi extends AbstractApi{
    /**
     * Api for managing datasets
     * 
     * example usage:
     * 
     * import { ManageDatasetApi, ApiError } from "@/services/manageDatasetApi.service";
     * const api = new ManageDatasetApi()
        try {
            const result = await api.getDatasetBandByName('protected_a43c43a6-ad43-4cee-8ea4-9308bf770a34','dsadsad')
            console.warn('result', result)
        } catch(err){
            if (err instanceof ApiError) {
            console.warn('ApiError', err.getFirstMessage())
            } else {
            console.warn('err', err)
            }
        }
     * @param {*} namespace 
     */
    constructor(namespace = 'apiManageDataset'){
        super(namespace);
    }
    // Datasets
    async getDatasets() {
        /**
         * Get all datasets metadata
         * 
         * Response:
         * [{
            "id": "protected_dd8a905c-aad5-4aaf-91f1-6865c102ca12",
                "status": "COMPLETED",
                "user_id": "1ssa1GFg8i5tOiUyIt7DfzBXp5lY",
                "operation_id": "1ssa1GFg8i5tOiUyIt7DfzBXp5lY_30722262-1ca5-431c-82e8-f77c16af9c1b"
            }]
         */
        const response = await api.get(this.baseUrl +'/datasets')
        return this._handleResponse(response)
    }
    async getDatasetById(id) {
        /**
         * Get Dataset STAC Entry
         * 
         * Response:
         *  {
                "id": "protected_a43c43a6-ad43-4cee-8ea4-9308bf770a34",
                "title": "test CHANGE",
                "bands": [
                    {
                        "id": "b1",
                        "name": "b1",
                        "description": "b1",
                        "datatype": "numeric",
                        "display": true,
                        "scale": [
                            1
                        ],
                        "units": "digital_number",
                        "min": 0.0,
                        "max": 255.0,
                        "classes": null
                    }
                ],
                "description": "",
                "shortDescription": "Just a short Description",
                "license": "open",
                "provider": null,
                "providerUrl": null
            }
         */
        const response = await api.get(this.baseUrl +'/datasets/'+encodeURIComponent(id))
        return this._handleResponse(response)
    }
    async moveDatasetById(id, permission) {
        /**
         * Move a dataset to the organistaion catalog or back to the user catalog depending on the current location
         * Args:
         *  permission: 'editable' or 'viewable'
         * 
         * Response:
         *  {"success":true}
         */
        const response = await api.put(this.baseUrl +'/datasets/'+encodeURIComponent(id)+'/move',{permission})
        return this._handleResponse(response)
    }
    async deleteDatasetById(id) {
        /**
         * Delete a dataset STAC Entry removing images from EE
         * 
         * Response:
         * {"success":true}
         */
        const response = await api.delete(this.baseUrl +'/datasets/'+encodeURIComponent(id))
        return this._handleResponse(response)
    }
    async updateDatasetById(id, data) {
        /**
         * Update a dataset STAC Entry
         * data params
         * {
         *  title: string,
         *  license: string,
         *  provider: string,
         *  providerUrl: string,
         *  description: string,
         *  shortDescription: string,
         * }
         * 
         * Response:
         *  same as getDatasetById
         */
        const response = await api.put(this.baseUrl +'/datasets/'+encodeURIComponent(id), data)
        return this._handleResponse(response)
    }
    //Bands
    async getDatasetBandByName(datasetId, bandName) {
        /**
         * Get a dataset band metadata includes min, max and class values. 
         * 
         * Response:
         * {
            "id": "b1",
            "name": "b1",
            "description": "b1",
            "datatype": "numeric",
            "display": true,
            "scale": [
                1
            ],
            "units": "digital_number",
            "min": 0.0,
            "max": 255.0,
            "classes": null
        }
         */
        const response = await api.get(this.baseUrl +'/datasets/'+encodeURIComponent(datasetId)+'/bands/'+encodeURIComponent(bandName))
        return this._handleResponse(response)
    }
    async updateDatasetBandByName(datasetId, bandName, data, queryParams = {}) {
        /**
         * Update a dataset band metadata
         * 
         * data params
         * {
         * name: string,
         * description: string,
         * units: string,
         * display: boolean,
         * type: string (numeric, thematic)
         * min: number,
         * max: number,
         * classes: object{
         *      "1": {"color": "0000FF", "description": "water"}
         *      "10": null
         *  }
         * }
         * 
         * query params:
         * appendClasses: boolean (attempts to update existing classes if any appending or updating, if value is null it is removed)
         * calculateClassValues: boolean (attempts to overwrite the classes with default values found when importing the dataset. Must be combined with classes: {} as an empty object)
         * 
         * Response:
         * same as getDatasetBandByName
         */
        let url = this.baseUrl +'/datasets/'+encodeURIComponent(datasetId)+'/bands/'+encodeURIComponent(bandName)
        const response = await api.put(url, data, queryParams)
        return this._handleResponse(response)
    }
    //image
    async getDatasetImages(datasetId) {
        /**
         * get all images for a dataset
         * 
         * Response:
         * [
        {
            "id": "1ssa1GFg8i5tOiUyIt7DfzBXp5lY_540de099-d6ad-4deb-84dd-d0a964b7de6a",
            "state": "COMPLETED",
            "espg": 32630,
            "operation_id": "1ssa1GFg8i5tOiUyIt7DfzBXp5lY_540de099-d6ad-4deb-84dd-d0a964b7de6a",
            "user_id": "1ssa1GFg8i5tOiUyIt7DfzBXp5lY",
            "createdAt": "2023-05-15T11:06:37.052000+00:00",
            "updatedAt": "2023-05-15T11:06:37.052000+00:00",
            "startTime": "2023-05-14T00:00:00+00:00",
            "endTime": "2023-05-14T23:59:59+00:00",
            "properties": {
                "test": "Hello World",
                "api": 1234
            }
        }
    ]
         */
        const response = await api.get(this.baseUrl +'/datasets/'+encodeURIComponent(datasetId)+'/images')
        return this._handleResponse(response)
    }
    async getDatasetImageByName(datasetId, imageId) {
        /**
         * get a single image for a dataset
         * 
         * Response: 
         * {
            "id": "1ssa1GFg8i5tOiUyIt7DfzBXp5lY_540de099-d6ad-4deb-84dd-d0a964b7de6a",
            "state": "COMPLETED",
            "espg": 32630,
            "operation_id": "1ssa1GFg8i5tOiUyIt7DfzBXp5lY_540de099-d6ad-4deb-84dd-d0a964b7de6a",
            "user_id": "1ssa1GFg8i5tOiUyIt7DfzBXp5lY",
            "createdAt": "2023-05-15T11:06:37.052000+00:00",
            "updatedAt": "2023-05-15T11:06:37.052000+00:00",
            "startTime": "2023-05-14T00:00:00+00:00",
            "endTime": "2023-05-14T23:59:59+00:00",
            "properties": {
                "test": "Hello World",
                "api": 1234
            }
        }
         */
        const response = await api.get(this.baseUrl +'/datasets/'+encodeURIComponent(datasetId)+'/images/'+encodeURIComponent(imageId))
        return this._handleResponse(response)
    }
    async updateDatasetImageByName(datasetId, imageId, data) {
        /**
         * Update a dataset image metadata
         * 
         * data params
         * {
         * start_time: iso8601 Date string,
         * end_time: iso8601 Date string,
         * properties: object{
         *     "key": "value",
         *    "key2": null
         * }
         * 
         * Note: setting properties to null will remove the property
         * 
         * Response:
         * Same as getDatasetImageByName
         */
        const response = await api.put(this.baseUrl +'/datasets/'+encodeURIComponent(datasetId)+'/images/'+encodeURIComponent(imageId), data)
        return this._handleResponse(response)
    }
    async deleteDatasetImageByName(datasetId, imageId) {
        /**
         * Delete a dataset image
         * 
         * Response:
         * 
         * {"success":true}
         */
        const response = await api.delete(this.baseUrl +'/datasets/'+encodeURIComponent(datasetId)+'/images/'+encodeURIComponent(imageId))
        return this._handleResponse(response)
    }
}