<template>
  <md-dialog
    v-model:md-active="isDialogVisible"
    :md-click-outside-to-close="false"
    @md-opened="dialogOpened"
  >
    <md-dialog-title>{{ isUpdate ? 'Update' : 'New' }} Organisation Group</md-dialog-title>
    <form id="new-org" @submit.prevent="saveGroup">
      <md-dialog-content>
        <md-field :class="getValidationClass('name')">
          <label for="name">Name</label>
          <md-input v-model="group.name" ref="name" />
          <span
            class="md-error"
            v-if="v$.group.name.required.$invalid"
          >The group name is required</span>
        </md-field>
    
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="closeDialog">Close</md-button>
        <md-button type="submit" class="md-primary">Save</md-button>
      </md-dialog-actions>
    </form>
  </md-dialog>
  

</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
function getDefaultGroup() {
  
   let defaultorg = {
    name: "",
  };
   return defaultorg ;
}

export default {
  name: "OrganisationGroupsAddEditGroup",
  setup: () => ({ v$: useVuelidate() }),
  data: () => ({
    isUpdate: false,
    group: getDefaultGroup(),
    isDialogVisible: false,
    groupId: null,
  }),
  emits: [
    'group-save'
  ],
  validations: {
    group: {
      name: {
        required
      }
    }
  },
  methods: {
    closeDialog() {
        this.isDialogVisible = false;
    },
    async showDialog(group) {
      if (group) {
        this.isUpdate = group.id != null;
        this.group = Object.assign({}, group);
        this.orgId = group.id;
      } else {
        this.group = getDefaultGroup();
      }
      this.isDialogVisible = true;
    },
    dialogOpened() {    
      this.v$.$reset();

      setTimeout(() => {
        this.$refs.name.$el.focus();
      }, 200);
    },
    getValidationClass(fieldName) {
      const field = this.v$.group[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    saveGroup() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.$emit("group-save", this.group, this.isUpdate);

      this.isDialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.md-dialog {
    form {
        overflow: auto;
    }
}

.md-dialog-title {
    margin-bottom: 0;
}

.md-switch {
    margin-top: 0;
}
</style>
