<template>
    <div>
    <md-dialog v-model:md-active="computedModalShown" :md-click-outside-to-close="false" class="image-upload ebx-dialog modal-m">
    <span class="md-dialog-title md-title">
        <div class="top-actions--container">
            <div class="title--container">
                <span>Image options</span>
            </div>
            <div class="close-button">
                <md-button class="md-primary" @click="cancelDates"><md-icon>close</md-icon></md-button>
            </div>
        </div>
    </span>
    <md-dialog-content class="dialog-content">
        <md-content class="content">
            <form>
                <div class="dates-modal">
                    <h2 class="image-modal-h2">Image date</h2>
                </div>
                <md-radio v-model="formData.imageType" value="point">
                    Point in time
                    <p class="imageTypeLabel muted">Image taken on a specific day or time</p>
                </md-radio>

                <div v-if="formData.imageType=='point'" class="radio-options-container">
                    <div class="radio-options-container--column">
                        <div class="first">
                            <md-datepicker v-model="selectedDate" @md-closed="showSelectedDateErrors = true"/>
                            <p class="md-error" v-if="v$.selectedDate.$invalid && (showHiddenErrors || showSelectedDateErrors)">A valid date is required</p>
                        </div>
                    </div>
                </div>

                <md-radio v-model="formData.imageType" value="period">
                    Period of time
                    <p class="imageTypeLabel muted">Combined images representing a time period</p>
                </md-radio>
                <div v-if="formData.imageType=='period'" class="radio-options-container">
                    <div class="radio-options-container--column">
                        <div class="first">
                            <div class="date-label">
                                <label for="FromDatepicker" class="ebx-primary">From</label>
                            </div>
                            <md-datepicker id="FromDatepicker" v-model="formData.selectedFromDate" @md-closed="showSelectedFromDateErrors = true"/>
                        </div>
                        <div class="last">
                            <div class="date-label">
                                <label for="ToDatepicker" class="ebx-primary">To</label>
                            </div>
                            <md-datepicker id="ToDatepicker" v-model="formData.selectedToDate" @md-closed="showSelectedFromDateErrors = true"/>
                        </div>
                    </div>
                    <p class="md-error" v-if="(v$.formData.selectedFromDate.requiredIfPeriod.$invalid || v$.formData.selectedToDate.$invalid) && (showHiddenErrors || showSelectedFromDateErrors)">Select a date range</p>
                    <p class="md-error" v-else-if="v$.formData.selectedFromDate.dateBeforeTo.$invalid && (showHiddenErrors || showSelectedFromDateErrors)">From date must be before to date</p>
                </div>
            </form>
        </md-content>
    </md-dialog-content>
    <md-dialog-actions>
        <EbxButton @click="saveModal">Save</EbxButton>
      </md-dialog-actions>
    </md-dialog>
    </div>
</template>


<script>

/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/

import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import moment from 'moment'
import { globalEventBus } from '@/eventbus.js'
import blocklyModalsMixin from './blocklyModalsMixin.js'
import EbxButton from '@/components/EbxComponents/EbxButton.vue'

export default {
    name: "ImageDates",
    setup: () => ({ v$: useVuelidate() }),
    components:{
        EbxButton
    },
    mixins: [blocklyModalsMixin],
    props: {
        showModal: { 
            type: Boolean, 
            default: false,
        },
        selectedToDate: { 
            type: String, 
            default: null
        }, 
        selectedFromDate: { 
            type: String, 
            default: null
        },
        imageType: { 
            type: String, 
            default: null
        },
    },
    data(){
        return {
            showHiddenErrors:false,
            showSelectedDateErrors: false,
            showSelectedFromDateErrors: false,
            formData: {
                selectedToDate: this.selectedToDate,
                selectedFromDate: this.selectedFromDate,
                imageType: this.imageType
            }
        }
    },
    methods: {
        closeDialog() {
            this.computedModalShown = false;
        },
        cancelDates() {
            this.closeDialog();
        },
        getFieldValue() {
            return {
                selectedFromDate: moment(this.formData.selectedFromDate).format("YYYY-MM-DD"),
                selectedToDate: moment(this.formData.selectedToDate).format("YYYY-MM-DD"), 
                imageType:this.formData.imageType
            }
        },
        saveModal() { 
            if(this.v$.$invalid) {
                this.showHiddenErrors = true;
                return;
            }
            this.showHiddenErrors = false;
            this.blockyEventCallback('modal-save', this.getFieldValue());
        }
    },
    computed: {
        selectedDate: {
            get() {
                return this.formData.selectedToDate
            },
            set(date) {
                this.formData.selectedToDate = moment(date).set({'hour': 23, 'minute': 59, 'second': 0 }).format("YYYY-MM-DD") 
                this.formData.selectedFromDate = moment(date).set({'hour': 0, 'minute': 0, 'second': 0 }).format("YYYY-MM-DD") 
            }
        }
    },
    watch: {
        imageType(newValue, oldValue) {
            if (newValue === "period" && oldValue === "point") { 
                this.formData.selectedFromDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
            }

        },
        showModal(val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if(val) {
                this.showHiddenErrors = false;
                this.formData = {
                    selectedToDate: this.selectedToDate,
                    selectedFromDate: this.selectedFromDate,
                    imageType: this.imageType
                }
            }
        }
    },
    validations () {
        return {
            selectedDate: {
                requiredIfPoint: requiredIf(this.formData.imageType === "point")
            },
            formData: {
                imageType: {
                    required
                },
                selectedToDate: {
                    requiredIfPeriod: requiredIf(this.formData.imageType === "period"),
                },
                selectedFromDate: {
                    requiredIfPeriod: requiredIf(this.formData.imageType === "period"),
                    dateBeforeTo: function () {
                        if (this.formData.imageType === "point") {
                            return true;
                        }
                        return moment(this.formData.selectedFromDate).isBefore(moment(this.formData.selectedToDate));
                    }
                }
            }
        }
    }
}
</script>