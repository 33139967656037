<template>
    <div class="explore">
        <h1 class="ebx-header-1">Explore</h1>
        <div v-if="loadingExplore">
            <md-progress-spinner 
                md-mode="indeterminate" 
                class="static-loading-spinner md-accent assets-tab--loading"
                :md-diameter="80"
                ></md-progress-spinner>
        </div>
        <div v-else>
            <div class="explore--packages">
                <div class="md-layout md-gutter">
                    <div 
                        v-for="pkg in ebxPackages" 
                        :key="pkg.id"
                        class="explore--package-card md-layout-item md-size-33 md-small-size-50 md-xsmall-size-100">
                        
                        <EbxFolderCard :details="{
                            title: pkg.title,
                            description: pkg.description,
                            images: pkg.projectsAssigned.projectThumbnails
                            }" @goToPage="() => packageOpen(pkg.id)" />
                    </div>
                </div>
            </div>
            <h2 class="ebx-header-2">{{projectsTitle}}</h2>
            <EbxTemplateWorkflows 
            :title="projectsTitle" 
            v-model="loadingTemplates"
            />
        </div>
    </div>
</template>

<script>
import EbxTemplateWorkflows from '@/components/EbxComponents/EbxTemplateWorkflows.vue'
import EbxFolderCard from '@/components/EbxComponents/EbxFolderCard.vue'
import authMixin from "@/components/mixins/authMixin";
import packageMixin from "@/components/mixins/packageMixin";

export default {
    components: {
        EbxTemplateWorkflows,
        EbxFolderCard,
    },
    mixins: [authMixin, packageMixin],
    data() {
        return {
            projectsTitle: "Template projects",
            loadingTemplates: false,
            loadingPackages: false,
            ebxPackages: [],
        }
    },
    computed: {
        loadingExplore() {
            return this.loadingTemplates && this.loadingPackages;
        },
    },
    mounted() {
        this.getPackages();
    },
    methods: {
        async getPackages() {
            this.ebxPackages = [];
            this.loadingPackages = true;
            await this.getGlobalPackages();
            this.ebxPackages = this.globalPackages.slice().sort((a, b) => a.title.localeCompare(b.title));
            this.loadingPackages = false;
        },
        packageOpen(id) {
            const selectedPackage = this.ebxPackages.find(pkg => pkg.id === id);
            this.$router.push( {
                name:'Package',
                params: {
                    packageId: selectedPackage.id,
                }
            })
        },
    }
}
</script>
