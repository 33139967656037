<template>
    <div class="ebx-drawing-controls--reassign-popup">
        <md-list>
            <md-list-item>
                <md-menu :md-offset-x="150" :md-offset-y="-36">
                    <span class="md-list-item-text ebx-primary" md-menu-trigger>Move to area…</span>
                    <md-menu-content class="ebx-drawing-controls--reassign-popup-menu-content">
                        <md-menu-item v-for="area in areas" :key="area.id" :disabled="areaIsDisabled(area)" @click="handleReassign(area)">
                            <div type="button" class="colour-selection-button md-theme-default" :style="{backgroundColor: area.colour}">
                                 &nbsp;
                            </div>
                            <div class="md-list-item-text"> {{  area.name }}</div>
                        </md-menu-item>
                    </md-menu-content>
                </md-menu>
            </md-list-item>
      </md-list>
    </div>
</template>

<script>

export default {
    name: 'ReassignShapeMenu',
    props: {
        areas: {
            type: Array,
            default: () => []
        },
        selectedArea: {
            type: Object,
            default: null
        },
        clickHandler: {
            type: Function,
            default: () => {}
        }
    },
    methods: {
        handleReassign(area) {
            if(this.areaIsDisabled(area)=== false) {
                this.clickHandler(area);
            }
        },
        areaIsDisabled(area) {
            return area.id === this.selectedArea.id;
        }
    }, 
}

</script>