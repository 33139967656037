/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/**
 * Class for a custom field-date control to allow for inclusion of variables.
 * @extends {CustomFieldDate}
 * @alias Blockly.FieldDateVariable
 */

import * as Blockly from 'blockly/core';
import { VariablesService } from '@/services/variable.service';
import {CustomFieldDate} from './blocklyFieldDate';
import { VARIABLE_PROVIDERS } from '@/constants/nextGenConstants';
import { sharedVariableMethodsMixin } from './sharedVariableMethodsMixin';
const variableType = 'date';
const variableTypes = ['date', 'date range'];

class FieldDateVariable extends CustomFieldDate {

    constructor(value = undefined, validator = undefined, textEdit = false, handleDisplayText = false, options = []) {
        super(value, validator, textEdit);
        this.options_ = options;
        /**
         * If the handleDisplayText is a function, set the handleDisplayText_ to the function
         */
        if (handleDisplayText && typeof handleDisplayText === 'function') {
            this.handleDisplayText_ = handleDisplayText;
            this.hasHandleDisplayText_ = true;
        }
    }

    /**
     * Constructs a FieldDateVariable from a JSON arg object.
     * Override the default fromJson method to include the handleDisplayText function
     * @param {*} options 
     * @returns 
     */
    static fromJson(options) {
        return new this(options['date'], undefined, options['textEdit'], options['handleDisplayText']);
    }

    /**
     * Method that allows us to set the handleDisplayText function on the field
     * @param {*} handleDisplayText
     * @returns
     */
    setHandleDisplayText(handleDisplayText) {
        if (typeof handleDisplayText === 'function') {
            this.handleDisplayText_ = handleDisplayText;
            this.hasHandleDisplayText_ = true;
        }
        return this;
    }

    /**
     * Include the variable button in the date picker
     */
    updateEditor_() {
        super.updateEditor_();
        // Append variable buttons to the date picker
        this.appendVariableToOptions_();
    }

    /**
     * Override the default doClassValidation_ method to include the variable validation
     * If the newValue is not a variable, do the default validation
     * @param {*} newValue 
     * @returns 
     */
    doClassValidation_(newValue = undefined) {
        const vars = VariablesService.getVariablesByTypes(variableTypes).map(v => v.getId());
        if (newValue && vars.indexOf(newValue) >= 0) {
            return newValue;
        }
        return super.doClassValidation_(newValue);
    }

    /**
     * Looks up the variable display text from the variable ID, and returns the display text if it exists.
     * If the variable ID does not exist, it returns the value.
     * @param {*} newValue 
     * @returns {String}
     */
    getEditorText_(newValue) {
        if(this.isVariable()) {
            return this.getVariable().getDisplayText()
        }
        return newValue;
    }
    /**
     * Method to get the variable title
     * @returns 
     */
    getText_() {
        const newValue = this.getValue();
        if(this.hasHandleDisplayText_) {
            return this.handleDisplayText_.call(this,newValue)
        }
        return this.getEditorText_(newValue);
    }
    /**
     * Updates the datepicker variable options
     * @param {*} options 
     * @param {*} newValue 
     */
    updateOptions(options) {
        this.options_ = options;
        this.forceRerender();
    }

    /**
     * Loop through the date variables avaialable in the VariablesService 
     * Create the button element for each variable
     * Append the button to the parent node of the date picker
     */
    appendVariableToOptions_() {
        const variables = this.options_;
        if (!this.picker_ || !variables) {
            return;
        }

        // Remove the variable buttons from the date picker
        this.removeVariableButtons_();

        if (variables.length === 0) {
            return;
        }

        variables.forEach(variable => {
            const variableTextValue = variable[0];
            const variableValue = variable[1];
            this.applyVariableButton_(variableTextValue, variableValue);
        });

        // This is phase 2
        // this.applyNewVariableButton();
    }

    /**
     * Add a new html button to the date picker to allow the user to select a variable
     */
    applyVariableButton_(variableText, variableValue) {
        const parentNode = this.picker_.element_.parentElement;        
        parentNode.parentNode.classList.add('ebx-date-picker');// add class to the parent of the parent node
        const variableButton = this.constructVariableButton_(variableText);
        variableButton.addEventListener('click', (e) => {
            e.preventDefault();
            // set the field value to the variable value
            this.setEditorValue_(variableValue);
            //this.setValue(variableValue);
            Blockly.WidgetDiv.hide();
            Blockly.DropDownDiv.hideIfOwner(this);
        });
        parentNode.appendChild(variableButton);
    }

    /**
     * Add a new html button to the date picker to allow the user to select a variable
     */
    applyNewVariableButton_() {
        const parentNode = this.picker_.element_.parentElement;        
        parentNode.parentNode.classList.add('ebx-date-picker');// add class to the parent of the parent node
        const variableButton = this.constructVariableButton_();

        variableButton.addEventListener('click', (e) => {
            e.preventDefault();
            console.log('This will open up a way to select a variable')
            let vars = VariablesService.getVariablesByType(variableType);
            if (vars.length === 0) {
                VariablesService.addVariable(variableType, VARIABLE_PROVIDERS.PROJECT.id, 'Start/End Date', 'description text', '2021-01-01')
            }
            vars = VariablesService.getVariablesByType(variableType);
            this.setEditorValue_(vars[0].id);
            Blockly.WidgetDiv.hide();
            Blockly.DropDownDiv.hideIfOwner(this);
        });
        parentNode.appendChild(variableButton);
    }

    /**
     * Method to construct the ebx-date-variable button
     */
    constructVariableButton_(text = 'Add new variable...') {
        const variableButton = document.createElement('button');// add a class to the button
        variableButton.classList.add('ebx-date-variable');
        variableButton.textContent = text;
        return variableButton;
    }
    /**
     * Method to remove all the variable buttons from the date picker
     * This is useful so we don't have duplicate buttons
     */
    removeVariableButtons_() {
        const parentNode = this.picker_.element_.parentElement;
        const variableButtons = parentNode.querySelectorAll('.ebx-date-variable');
        if (!variableButtons) {
            return;
        }
        variableButtons.forEach(button => {
            button.remove();
        });
    }
}

Object.assign(FieldDateVariable.prototype, sharedVariableMethodsMixin);

export {FieldDateVariable}