/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import * as Blockly from 'blockly/core';
import {NO_DATA_DROPDOWN_VALUE} from '@/constants/nextGenConstants';
import {AbstractBlock, AbstractFieldHelpers} from '@/blocks/_abstractBlock';
import { ContentService } from '@/services/content.service';

const defaultSelectOptions = [["Select an option", NO_DATA_DROPDOWN_VALUE]]
const modifierRemoveAttributesJson = {
    "type": "modifier_remove_attributes",
    "message0": ` %1 %2 %3`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "attributes_title",
            "text": "%{BKY_MODIFIER_REMOVE_ATTRIBUTES_TITLE} ",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_multi_dropdown",
            "name": "remove_attributes",
            "options": defaultSelectOptions
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "secondaryFilter",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['modifier_remove_attributes'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    onInit: function() {
        this.jsonInit(modifierRemoveAttributesJson);
        this.about_block_url = ContentService.getAboutBlockUrl('modifier_remove_attributes')
    },
    accept: async function (visitor) {
        console.log('accept define visitor')
        await visitor.visitModifierRemoveAttributesBlock(this);
    },
    updateShape_: function() {
        if(this.hasStateChanged('attributes')) {
            let attributeOptions = defaultSelectOptions
            if (this.getState('attributes')) {
                attributeOptions = this.getState('attributes')
            }
            if (attributeOptions.length > 0) {
                this.updateOptionsIfSet('remove_attributes', [].concat(attributeOptions))
            } else {
                this.updateOptionsIfSet('remove_attributes', [].concat(defaultSelectOptions))
            }
        }
    },
    ebxValidate: function(tooltips) {
        const isVector = this.getState('isVector')
        const hasDefinition = this.getState('hasDefinition')
        this.setWarningText(null, "not_vector_dataset")
        if (!hasDefinition) {
            return
        } else if (hasDefinition && !isVector) {
            this.setWarningText(tooltips['not_vector_dataset'] || "This block can only be added to workflows with a vector dataset input.", "not_vector_dataset")
        } else {
            this.setWarningText(null, "not_vector_dataset")
        }
    }
}