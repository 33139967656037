<template>
    <div class="ebx-tabs">
        <div class="ebx-tabs--actions" v-if="hideActionsIfNotMultiple === false ||tabs.length > 1">
            <slot name="actions" v-bind:selectTab="selectTab">
                <div v-for="tab in tabs" :key="tab.id" class="ebx-tabs--action" :class="{active: tab.id === selectedValue}" @click.prevent="selectTab(tab.id)">
                    <span class="ebx-tabs--action-text">{{ tab.title }}</span>
                    <span class="ebx-tabs--action-seperator"></span>
                </div>
            </slot>
        </div>
        <div class="ebx-tabs--content-container" :class="contentClasses">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import valueMixin from '@/components/mixins/valueMixin'

export default{
    name: 'EbxTabs',
    provide() {
        const tabData = {}
        Object.defineProperty(tabData, "selectedValue", {
            enumerable: true,
            get: () => this.selectedValue,
        })
        Object.defineProperty(tabData, "tabDisplayMode", {
            enumerable: true,
            get: () => this.tabDisplayMode,
        })
        Object.defineProperty(tabData, "tabElements", {
            enumerable: true,
            get: () => this.tabElements,
        })

        return {
            tabData,
        }
    },
    mixins: [valueMixin],
    props: {
        modelValue: {
            type: [String, Number],
            default: null
        },
        tabDisplayMode: {
            type: String,
            default: 'if'
        },
        contentClass: {
            type: String,
            default: ''
        },
        noContentScroll: {
            type: Boolean,
            default: false
        },
        hideActionsIfNotMultiple: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'change',
        'update:modelValue'
    ],
    data() {
        return {
            internalSelectedValue: null,
            tabElements: []
        }
    },
    computed: {
        contentClasses() {
            const classes = this.contentClass.split(' ')
            if(this.noContentScroll !==false) {
                classes.push('no-scroll')
            }
            return classes
        },
        tabs() {
            return this.tabElements
            .map((tab, i) => {
                return {
                    id: tab.id !== undefined ? tab.id : tab._uid,
                    index: i,
                    title: tab.title || null,
                    tab: tab
                }
            })
        },
        selectedValue() {
            if (this.internalSelectedValue !== null){
                return this.internalSelectedValue
            }
            return this.value !== null ? this.value : this.tabs[0].id
        }
    },
    methods: {
        selectTab(tabId) {
            this.internalSelectedValue = tabId
            this.$emit('change', tabId)
            this.$emit('update:modelValue', tabId)
        }
    },
    watch: {
        value() {
            this.internalSelectedValue = null;
        }
    }
}
</script>