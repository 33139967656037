<template>
  <md-dialog
    class="modal-m"
    v-model:md-active="isDialogVisible"
    :md-click-outside-to-close="true"
    @md-opened="dialogOpened"
  >
    <md-dialog-title>{{ formUser.uid ? 'Update' : 'New' }} User</md-dialog-title>
    <md-dialog-content>
    <form id="user" @submit.prevent="saveUser" v-if="!isSuperAdmin || organisations">
      
        <md-field v-if="(isSuperAdmin) || isCustomerSupport || !formUser.isCurrentUser" :class="getValidationClass('email')">
          <label for="email">Email</label>
          <md-input id="ebx-new-user-email" v-model="formUser.email" ref="email" />
          <span class="md-error" v-if="v$.formUser.email.required.$invalid">The email is required</span>
          <span class="md-error" v-else-if="v$.formUser.email.email.$invalid">Invalid email</span>
        </md-field>

        <md-field v-if="(isSuperAdmin) || isCustomerSupport || !formUser.isCurrentUser" :class="getValidationClass('password')">
          <label>Password</label>
          <md-input id="ebx-new-user-password" v-model="formUser.password" />
          <span class="md-error" v-if="owaspViolations.length > 0">{{ owaspViolations[0] }}</span>
        </md-field>

        <md-field v-if="(isSuperAdmin) || (isCustomerSupport && formUser.isCurrentUser)" :class="getValidationClass('orgId')">
          <label id="ebx-new-user-organisation">Organisation</label>
          <md-select v-model="formUser.orgId" name="orgId">
            <md-option
              v-for="organisation in organisations"
              :key="organisation.id"
              :value="organisation.id"
            >{{ organisation.name }}</md-option>
          </md-select>
          <span class="md-error" v-if="v$.formUser.orgId.required.$invalid">Organisation is required</span>
        </md-field>

        <md-field>
          <label>Display Name</label>
          <md-input id="ebx-new-user-display-name" v-model="formUser.displayName" ref="displayName" />
        </md-field>

        <md-field :class="getValidationClass('photoURL')">
          <label>Photo URL</label>
          <md-input v-model="formUser.photoURL" />
          <span class="md-error" v-if="v$.formUser.photoURL.url.$invalid">Photo URL must be a valid URL</span>
        </md-field>

        <md-field :class="getValidationClass('emailVerified')" v-if="isSuperAdmin">
          <label for="verified">Email verified?</label>
          <div id="emailVerified">
            <md-switch name="verified" v-model="formUser.emailVerified" />
          </div>
          <span class="md-error" v-if="v$.formUser.emailVerified.$invalid">emailVerified must be boolean</span>
        </md-field>

        <md-field :class="getValidationClass('activeSubscription')" v-if="isSuperAdmin">
          <label for="subscription">Subscription Claim?</label>
          <div id="activeSubscription">
            <md-switch name="subscription" v-model="formUser.activeSubscription" />
          </div>
          <span class="md-error" v-if="v$.formUser.activeSubscription.$invalid">Subscription Claim must be boolean</span>
        </md-field>

        <div class="block" v-if="isSuperAdmin && userType == 'creator'">
            <div class="title">Is the user allowed access to workspace variables?</div>
            <div class="input">
                <md-switch v-model="featureVariables">Yes</md-switch>
            </div>
        </div>

        <div class="block mt-12" v-if="isSuperAdmin || isCustomerSupport || isAdmin || !formUser.isCurrentUser">
            <label for="userType" class="muted">User Type</label>
            <div class="input">
              <md-radio v-model="userType" name="userType" id="userTypeCreator" value="creator">Creator</md-radio>
              <md-radio v-model="userType" name="userType" id="userTypeExplorer" value="explorer">Explorer</md-radio>
            </div>
        </div>
        
        <md-field v-if="isSuperAdmin || isCustomerSupport || isAdmin || !formUser.isCurrentUser">
          <label for="roles">Roles</label>
          <md-select v-model="userRoles" name="roles" id="roles" multiple>
            <md-option v-if="isAdmin || isCustomerSupport" value="admin">Organisation Admin</md-option>
            <md-option v-for="group in orgGroups" :key="group.id+'user'" :value="'group:user:'+group.id">{{ group.name }} User</md-option>
            <md-option v-for="group in orgGroups" :key="group.id+'admin'" :value="'group:admin:'+group.id">{{ group.name }} Admin</md-option>
            <md-option v-if="isSuperAdmin" value="superadmin">Super Admin</md-option>
            <md-option v-if="isSuperAdmin" value="customer_support">Customer Support</md-option>
            <md-option value="api_org_admin">API Org Admin</md-option>
          </md-select>
        </md-field>
      

      <md-dialog-actions>
        <md-button class="md-raised" id="delete-button" @click="deleteUser" v-if="isEdit">Delete User</md-button>
        <md-button class="md-primary" @click="closeDialog">Close</md-button>
        <md-button type="submit" class="md-primary">Save</md-button>
      </md-dialog-actions>
    </form>
  </md-dialog-content>
  </md-dialog>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { useVuelidate } from '@vuelidate/core'
import {
  required,
  email,
  url,
  requiredUnless
} from '@vuelidate/validators'
import authMixin from '@/components/mixins/authMixin.js';
import { organisationsCollection } from "@/firebase.js";
import { AuthService } from "../services/auth.service";
import { OWASP_CONFIG } from "@/constants/appConstants.js";
import * as owasp from 'owasp-password-strength-test';
owasp.config(OWASP_CONFIG);


function getDefaultUser() {
  return {
    email: "",
    password: "",
    displayName: "",
    photoURL: "",
    orgId: null,
    roles: ['creator'],
    termsConsent: null,
    termsVersion: "",
    emailVerified: true,
    activeSubscription: false,
    isCurrentUser: false,
    features: {}
  };
}

export default {
  name: "UserListAddEditUser",
  mixins: [authMixin],
  setup: () => ({ v$: useVuelidate() }),
  props: {
    isEdit: {
      type: Boolean,
      required: true
    },
    groups: {
      type: Array,
      required: true
    }
  },
  emits: [
    "user-save",
    "user-delete"
  ],
  data: () => ({
    organisations: null,
    authenticatedUser: null,
    formUser: getDefaultUser(),
    isDialogVisible: false,
    owaspViolations: [],
  }),
  validations () {
    return {
      formUser: {
        email: {
          required: requiredUnless(this.formUser.isLtiUser === true),
          email
        },
        password: {
          validatePassword: function(value){
            if (!value && this.formUser && this.formUser.uid) {
              // this only occurs when user is being modified - we allow empty pw because in that case the pw is left unchanged
              return true;
            }
            let result = owasp.test(value);
            this.owaspViolations = result.errors; // store the errors messages returned by owasp to display
            return result.strong; // returning this will fail the validation if result.strong is false
          }
        },
        photoURL: {
          url
        },
        orgId: {
          required
        },
        emailVerified: {
        // dont make these compulsory - this field is hidden for non superusers
        },
        activeSubscription: {
        // dont make these compulsory - this field is hidden for non superusers
        },
        featureVariables: {
        // dont make these compulsory - this field is hidden for non superusers
        }
      }
    }
  },
  methods: {
    closeDialog() {
        this.isDialogVisible = false;
    },
    showDialog(user) {
      if (user) {
        this.formUser = Object.assign({}, user, {
          isCurrentUser: user.uid === this.authenticatedUser.uid
        });
      } else {
        this.formUser = getDefaultUser();
        if (!this.isSuperAdmin && this.authenticatedUser) {
          this.formUser.orgId = this.authenticatedUser.orgId;
        }
      }
      this.isDialogVisible = true;
    },
    dialogOpened() {
      this.v$.$reset();

      setTimeout(() => {
        if (this.formUser.isCurrentUser) {
          this.$refs.displayName.$el.focus();
        } else {
          this.$refs.email.$el.focus();
        }        
      }, 200);
    },
    getValidationClass(fieldName) {
      const field = this.v$.formUser[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    saveUser() {
        this.v$.$touch();

        if (this.v$.$invalid) {
            return;
        }

        this.$emit("user-save", this.formUser);

        this.isDialogVisible = false;
    },
    deleteUser(){
      if(confirm("Are you sure you want to delete this user, this is an irreversible action?")){
        this.$emit("user-delete", this.formUser)
        this.isDialogVisible = false;
      }    
    }
  },
  computed: {
    isUpdate() {
      return this.formUser.uid != null;
    },
    orgGroups() {
      return this.groups.filter(group => group.orgId === this.formUser.orgId);
    },
    featureVariables: {
      get() {
        return this.formUser.features?.variables || false;
      },
      set(value) {
        if(this.formUser.features === undefined) {
          this.formUser.features = {};
        }
        this.formUser.features.variables = value;
      }
    },
    userType: {
      get() {
        if (this.formUser.roles.includes("explorer")) {
          return "explorer";
        } else {
          return "creator";
        }
      },
      set(value) {
        if (this.formUser.roles.includes(value)) {
          return;
        }
        const newRoles = this.formUser.roles.filter(role => ['creator','explorer'].includes(role) === false);
        newRoles.push(value);
        this.formUser.roles = newRoles;
      }
    },
    userRoles: {
      get() {
        const userRoles = this.formUser.roles.filter(role => ['creator','explorer'].includes(role) === false);
        return userRoles
      },
      set(value) {
        const newValue = value.slice(0);
        newValue.push(this.userType)
        if (JSON.stringify(newValue) !== JSON.stringify(this.formUser.roles)) {
          this.formUser.roles = newValue;
        }
      }
    }
  },
  created: function() {
    this.subscription = AuthService.loggedUser$.subscribe(user => {
      this.authenticatedUser = user;

      if ((this.isSuperAdmin || this.isCustomerSupport) && !this.organisations) {
        organisationsCollection.get().then(querySnapshot => {
          let organisations = [];
          querySnapshot.forEach(doc => {
            let docData = doc.data();
            organisations.push({
              id: doc.id,
              name: docData.name
            });
          });
          this.organisations = organisations;
        });
      }
    });
  },
  beforeUnmount() {
    this.subscription.unsubscribe();
  }
};
</script>

<style scoped lang="scss">
.md-dialog-title {
  margin-bottom: 0;
}

#delete-button {
  background-color: #ff5252;
  color: #ffffff;
  margin-right: auto;
}

#emailVerified, #activeSubscription {
  margin-top: 1em;
}
</style>
