<template>
    <div class="ebx-pixel-inspector">
        <div 
            class="ebx-pixel-inspector--values-wrapper"
            v-if="!isLoading && !isEmpty">
            <template v-for="(value, index) in pixelValues" :key="index">
                <EbxColorValue
                    v-if="!value.multiBand"
                    :color="value.color"
                    :label="value.label"
                    :colorDescription="value.bands[0].class !== null ? value.bands[0].class : value.bands[0].value"
                ></EbxColorValue>
                <template v-else>
                    <div 
                        class="ebx-pixel-inspector--multi-band-wrapper"
                        >
                        <EbxValue
                            :label="value.label"
                            :modelValue="`R (${value.bands[0].name}): ${value.bands[0].value}`"
                            theme="wide"
                            />
                        <EbxValue
                            :modelValue="`G (${value.bands[1].name}): ${value.bands[1].value}`"
                            theme="wide"
                            />
                        <EbxValue
                            :modelValue="`B (${value.bands[2].name}): ${value.bands[2].value}`"
                            theme="wide"
                            />
                    </div>
                </template>
            </template>
        </div>
        <div 
            class="ebx-pixel-inspector--loading-indicator"
            v-show="isLoading"
            >
            <md-progress-spinner class="md-accent" md-mode="indeterminate"
                :md-diameter="48" :md-stroke="2"
            ></md-progress-spinner>
        </div>
        <div class="ebx-pixel-inspector--point-info-wrapper">
            <div class="ebx-pixel-inspector--point-info-row">
                <EbxValue label="Latitude" :modelValue="displayLatitude"></EbxValue>
                <EbxValue label="Longitude" :modelValue="displayLongitude"></EbxValue>
            </div>
            <div class="ebx-pixel-inspector--point-info-row">
                <EbxValue label="Zoom" :modelValue="displayZoom"></EbxValue>
                <EbxValue label="Scale" :modelValue="displayScale"></EbxValue>
            </div>
        </div>
    </div>
</template>

<script>

import EbxColorValue from '../../EbxComponents/EbxColorValue.vue';
import EbxValue from '../../EbxComponents/EbxValue.vue';
export default {
    name: 'PixelInspector',
    components: {
        EbxColorValue,
        EbxValue
    },
    props: {
        // latitude, longitude, zoom level and scale, all of which are numbers
        latitude: {
            type: Number,
            required: true
        },
        longitude: {
            type: Number,
            required: true
        },
        zoom: {
            type: Number,
            required: true
        },
        scale: {
            type: Number,
            required: true
        }
    },
    mounted() {
        this.pixel = {
            runId: this.$store.state.runresult.lastRunId,
            latitude: this.latitude,
            longitude: this.longitude,
            zoom: this.zoom,
            scale: this.scale
        }

        // this.$store.commit('pixelInspector/addDummyPixel_');
    },
    computed: {
        isEmpty () {
            if (this.isLoading) {
                return false;
            }

            if (this.pixelValues.length === 0) {
                return true;
            }

            return false;
        },
        displayLatitude() {
            return this.latitude.toFixed(4);
        },
        displayLongitude() {
            return this.longitude.toFixed(4);
        },
        displayZoom() {
            return this.zoom.toFixed(0);
        },
        displayScale() {
            if (this.scale < 1) {
                return `${this.scale.toFixed(2)}m/px`;
            }

            if (this.scale < 100) {
                return `${this.scale.toFixed(0)}m/px`;
            }

            if (this.scale < 1000) {
                return `${(this.scale / 1000).toFixed(2)}km/px`;
            }

            return `${(this.scale / 1000).toFixed(0)}km/px`;
        },
        pixel: {
            get() {
                return this.$store.getters['pixelInspector/getLastPixel'];
            },
            set(runId, latitude, longitude, scale) {
                this.$store.dispatch('pixelInspector/fetchPixel', runId, latitude, longitude, scale);
            }
        },
        pixelValues() {
            return this.$store.getters['pixelInspector/getLastPixelAsValues']
        },
        isLoading() {
            return this.$store.getters['pixelInspector/isLoading'];
        }
    }
}

</script>