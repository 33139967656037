/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import Blockly from "blockly";
import { pine300 } from "./styles/variables/colours.module.scss";

class ColoursConstantsProvider extends Blockly.zelos.ConstantProvider {
    constructor() {
        // Set up all of the constants from the base provider.
        super();
    
        // Override a few properties.

        /**
         * The outline colour for selected block.
         * @type {string}
         * @override
         */
        this.SELECTED_GLOW_COLOUR = pine300;

        /**
         * @type {boolean}
         * @override
         */
        this.FIELD_DROPDOWN_SVG_ARROW = true;
        
        this.FIELD_DROPDOWN_NO_BORDER_RECT_SHADOW = true;

        /**
         * The outline colour for draggable block.
         * @type {string}
         * @override
         */
        this.REPLACEMENT_GLOW_COLOUR = '#00FFEB';

    }
    /**
     * @override
     */
    init() {
        super.init();

    }
    
}

class ColoursRenderer extends Blockly.zelos.Renderer {
    constructor(name) {
        super(name);
    }

    /**
     * @override
     */
    makeConstants_() {
        return new ColoursConstantsProvider();
    }
}

Blockly.blockRendering.register('colours_renderer', ColoursRenderer);
