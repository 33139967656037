/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly/core';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock';
import { ContentService } from '@/services/content.service';

const bufferMethods = [
    ['Direct distance', 'distance'],
    ['Travel time', 'time']
]

const methodToLabelMsg = {
    'distance': 'ANALYSIS_BUFFER_DISTANCE_LABEL',
    'time': 'ANALYSIS_BUFFER_TIME_LABEL'
}

const methodToUnitMsg = {
    'distance': 'ANALYSIS_BUFFER_DISTANCE_UNIT_LABEL',
    'time': 'ANALYSIS_BUFFER_TIME_UNIT_LABEL'
}

const INPUT = Object.freeze({
    TYPE: 'buffer_type',
    VALUE: 'buffer_value',
})

const FIELD = Object.freeze({
    TYPE: 'buffer_type',
    VALUE: 'buffer_value',
    BUFFER_LABEL: 'buffer_label',
    UNIT_LABEL: 'buffer_unit'
})


var bufferJson = {
    "type": "analysis_buffer",
    "message0": `%1 %2 %3 %4 %5 %6 %7`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "indices_title",
            "text": "%{BKY_ANALYSIS_BUFFER_BLOCK_TITLE} ", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy",
            "name": INPUT.TYPE
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.TYPE,
            "options": bufferMethods
        },
        {
            "type": "input_dummy",
            "name": INPUT.VALUE
        },
        {
            "type": "field_label_serializable",
            "name": FIELD.BUFFER_LABEL,
            "text": "%{BKY_ANALYSIS_BUFFER_DISTANCE_LABEL}"
        },
        {
            "type": "field_number",
            "name": FIELD.VALUE,
            "value": 1,
            "precision": .01
        },
        {
            "type": "field_label_serializable",
            "name": FIELD.UNIT_LABEL,
            "text": "%{BKY_ANALYSIS_BUFFER_DISTANCE_UNIT_LABEL}"
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "geoprocessing",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['analysis_buffer'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    FIELD,
    INPUT,

    onInit: function() {
        this.jsonInit(bufferJson);

        this.getField(FIELD.TYPE).setValidator(this.typeValidator.bind(this));
        this.about_block_url = ContentService.getAboutBlockUrl('analysis_buffer')
    },

    accept: async function (visitor) {
        await visitor.visitBufferBlock(this);
    },

    typeValidator: function(newValue) {
        console.log("typeValidator", newValue)

        const labelMsg = Blockly.Msg[methodToLabelMsg[newValue]]
        const unitMsg = Blockly.Msg[methodToUnitMsg[newValue]]
        
        this.setFieldValue(labelMsg, FIELD.BUFFER_LABEL)
        this.setFieldValue(unitMsg, FIELD.UNIT_LABEL)
    },


    updateShape_: function() {
    },

    ebxValidate: function(tooltips) {
        const isVector = this.getState('isVector')
        const hasDefinition = this.getState('hasDefinition')
        this.setWarningText(null, "not_vector_dataset")
        if (!hasDefinition) {
            return
        } else if (hasDefinition && !isVector) {
            this.setWarningText(tooltips['not_vector_dataset'] || "Not a vector dataset", "not_vector_dataset")
        } else {
            this.setWarningText(null, "not_vector_dataset")
        }
    }
}