import * as gjv from 'geojson-validation';
import * as tgj from '@mapbox/togeojson';
import jszip from 'jszip';
import shp from 'shpjs';
import reproject from 'reproject';
import epsg from 'epsg';
import { lineToPolygon, simplify, bbox, flatten } from "@turf/turf";
// const moment = require("moment");

/**
 * function which takes a geojson object, and returns an array of polygon coordinate arrays
 * [[[x,y],[x,y],...],[[x,y],[x,y],...],...]
 * 
 * @param {GeoJSON} geoJson - input geojson
 * @param {function} callbackFunc - optional callback function to process each polygon
 * @returns {Promise} polygons
 */
function getShapes(geoJson, callbackFunc) {
    let shapes = []
    let features = geoJson.features;

    // filter features to only those with geometry and polygons
    features = features.filter(feature => {
        if(!feature.geometry) {
            console.info("Ignoring feature without geometry");
            return false;
        }

        let geometry = feature.geometry;

        if (!geometry.type) {
            console.info("Ignoring feature without geometry type");
            return false;
        }
        //check if type is also not point
        if (geometry.type !== "Polygon" && geometry.type !== "Point") {
            console.info("Ignoring feature with geometry type: ", geometry.type);
            return false;
        }
        return true;
    });

    //updated this to handle coords of both point and polygons
    features.forEach(feature => {
        let geometry = feature.geometry;
        if (geometry.type == "Polygon") {
            let coords = geometry.coordinates;
                coords.forEach(coordSet => {
                if(typeof callbackFunc === 'function') {
                    callbackFunc(coordSet, feature)
                }
                shapes.push(coordSet);
            })
        } else if (geometry.type === "Point") {
            let coords = geometry.coordinates;
            if(typeof callbackFunc === 'function') {
                callbackFunc(coords, feature)
            }
            shapes.push(coords)
        }
    })
    return shapes;
}

/**
 * For a given geoJson object, parse and return array of bbox
 * @param {GeoJSON} geoJson 
 * @returns {Array} [minX minY maxX maxY]
 */
function getBbox(geoJson) {
    return bbox(geoJson);
}

/**
 * For any shape file, read files, convert to geojson and return
 * @param {*} files 
 * @returns {Promise} resolves to geoJson
 */
function getGeoJson(files) {
    if(files.length === 1) {
        let file = files[0];
        return readFile(file)
    } else {
        // error for no files or numerous files
        throw Error("Please only upload one file at a time");
    }
}

/**
 * Loops over all features in a feature collection, and returns
 * a geoJson object with all the features in polygon format, if possible to convert
 * @param {GeoJSON} geoJson 
 * @returns {GeoJSON} polygonised geoJson
 */
function polygoniseGeoJson(geoJson) {
    geoJson.features = geoJson.features.map(feature => {
        if (!feature.geometry) {
            console.info("Ignoring feature without geometry", feature)
            return feature;
        }

        let geometry = feature.geometry;

        if (!geometry.type) {
            console.info("Ignoring feature without geometry type", feature)
            return feature;
        }

        // only parse non-polygons
        switch(geometry.type){
            case "LineString": {
                // convert to polygon
                return lineToPolygon(feature);
            }
            case "MultiLineString": {
                // convert to polygon
                return lineToPolygon(feature);
            }
            default: {
                return feature;
            }
        }
    });

    return geoJson;
}


/**
 * For a given geojson object, return an array of errors, if the array  is empty
 * the geojson is valid
 * @param {*} geoJson 
 * @param {Function} callback parameters are isValid and errors
 * @example 
 * validateGeoJson(geoJson, (isValid, errors) => {
 * ...})
 */
function validateGeoJson(geoJson, callback) {
    let errorTrace = gjv.valid(geoJson, true);

    // returns the result of the callback
    if(errorTrace.length === 0) {
        return callback(true, []);
    } else {
        return callback(false, errorTrace);
    }
}


/**
 * Reads a given file and returns a geojson object for that file
 * @param {File} file
 * @returns {GeoJSON} geojson object
 */
function readFile(file) {
    let extension = getFileExtension(file.name);

    // handlers for each file type, must return a promise
    switch(extension){
        case "geojson":
            return readJson(file);
        case "kml":
            return readKML(file);
        case "kmz":
            return readKMZ(file);
        case "zip":
            return readShpZip(file);
        // case "csv":
        //     return readCsv(file);
        default:
            throw Error("Please upload a valid file format");
    }
}

/**
 * Gets file extension from file name
 * @param {String} filename 
 * @returns {String} extension
 */
function getFileExtension(filename) {
    return filename.split('.').pop();
}

/**
 * Given a json file, reads it and returns a geojson 
 * @param {File} file 
 */
async function readJson(file) {
    let json_result = await new Promise((resolve) => {
        let fr = new FileReader();
        fr.onload = () => { resolve(fr.result) }
        fr.readAsText(file);
    })

    try {
        let geoJson = JSON.parse(json_result);
        return parseGeoJson(geoJson);
    } catch(e) {
        console.error(e);
        throw Error("This file does not contain any features.");
    }
}

/**
 * Given a csv file, reads it and returns a geojson
 */

/**
 * For a given KML file, convert to geojson, parse and return array of polygons
 * @param {File} file KML file
 * @returns {Array} polygons
 */
async function readKML(file) {
    let kml_result = await new Promise((resolve) => {
        let fr = new FileReader();
        fr.onload = () => { resolve(fr.result) }
        fr.readAsText(file);    
    })
    
    try {
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(kml_result, "text/xml");
        if(isXmlParseError(xmlDoc)){
            throw new Error('Error parsing XML');
        }                        
        let geoJson = tgj.kml(xmlDoc);  
        geoJson = parseGeoJson(geoJson);
                     
        return geoJson;
    } catch (e) {
        console.error(e);
        throw new Error("There are errors in your KML file, please ensure it is the correct XML format")
    }
}

/**
 * For a given kmz file, read all files, convert to geojson and return an array of polygons
 * @param {File} file KMZ file
 * @returns {Array} polygons
 */
async function readKMZ(file) {
    let getDom = xml => (new DOMParser()).parseFromString(xml, "text/xml") // could reuse readKML code if needed

    let kmlDom = await new Promise((resolve, reject) => {
        var zip = new jszip();
        return zip.loadAsync(file)
            .then(zip => {
                let kmlDom = null;
                zip.forEach((relPath, file) => {
                    if(getFileExtension(relPath) === "kml" && kmlDom === null){
                        kmlDom = file.async("string").then(getDom);
                    }
                })
                kmlDom ? resolve(kmlDom) : reject("No KML files found")
            });
    });

    try {
        let geoJson = tgj.kml(kmlDom);
        return parseGeoJson(geoJson);
    } catch(e) {
        console.error(e);
        throw new Error("There are errors in your KMZ file, please ensure it is the correct format")
    }
}


/**
 * For a given shp file, reads it, converts to geojson and returns an array of polygons
 * @param {File} file 
 * @returns {Array} polygons
 */
async function readShpZip(file) {
    // workaround for arrayBuffer for safari
    (function () {
        File.prototype.arrayBuffer = File.prototype.arrayBuffer || myArrayBuffer;
        Blob.prototype.arrayBuffer = Blob.prototype.arrayBuffer || myArrayBuffer;

        function myArrayBuffer() {
            // this: File or Blob
            return new Promise((resolve) => {
                let fr = new FileReader();
                fr.onload = () => {
                    resolve(fr.result);
                };
                fr.readAsArrayBuffer(this);
            });
        }
    })();

    try {
        let fileBuffer = await file.arrayBuffer();
        let geoJson = await shp(fileBuffer)
        if(Array.isArray(geoJson)){
            let polygons = [];
            geoJson.forEach(geoJsonObject => {
                polygons.concat(parseGeoJson(geoJsonObject));
            })
            return polygons;
        } else {
            return parseGeoJson(geoJson);
        }
    } catch(e) {
        console.error(e);
        throw new Error("There are errors in your SHP file, please ensure it is the correct format")
    }

}

/**
 * For a given geojson json string, parse, polygonise and simplify
 * @param {GeoJSON} geojson - geojson object
 * @returns {GeoJSON} simplified geojson object
 */
function parseGeoJson(geojson){
    return validateGeoJson(geojson, (isValid, errors) => {
        if(!isValid){
            console.error(errors);
            throw new Error("There are errors in your GeoJSON file, please ensure it is the correct format")
        }

        // let crs = result.crs // coordinate reference system if needed
        let convertedGeoJson = convertGeoJsonCRS(geojson);
        
        let reversedGeoJson = reproject.reverse(convertedGeoJson); // reversing the x to y and y to x for correct lat long order

        // convert line strings and multi line strings to polygons
        let polygonisedJson = polygoniseGeoJson(reversedGeoJson);

        // flatten geojson, gets rid of multi polygons
        let flattenedJson = flatten(polygonisedJson);

        return flattenedJson
    });
}

/**
 * Takes a given geojson object and converts to wgs84, EPSG:4326
 * @param {GeoJSON} geoJson object in unknown projection 
 * @returns {GeoJSON} converted geojson to wgs84
 */
function convertGeoJsonCRS(geoJson) {
    try {
        let converted = reproject.toWgs84(geoJson, undefined, epsg) // detects defined crs in geojson and reprojects to wgs84
        return converted;
    } catch {
        // if no coordinate reference system found in geoJson, assume already in wgs84
        console.error("No CRS found in GeoJSON, assuming already in WGS84");
        return(geoJson)
    } 
}

/**
 * Checks if the xml document has errors
 * does not support IE
 * @param {XMLDocument} xmlDoc - xml document
 * @returns {Boolean} true if error, false if no error
 */
function isXmlParseError(parsedFile) {
    var parser = new DOMParser()
    var erroneousParse = parser.parseFromString('<', 'application/xml')
    var parserErrorNS = erroneousParse.getElementsByTagName("parsererror")[0].namespaceURI;

    return parsedFile.getElementsByTagNameNS(parserErrorNS, "parsererror").length > 0;
}

/**
 * For a given geojson object and file size limit, attempt 3 times to simplify the geojson, returns geojson and tolerance in M, tolerance is null if no simplification was needed.
 * If the file size is still too large, throw an error.
 * @param {GeoJSON} geoJson - geojson object
 * @param {Number} fileSizeLimit - file size limit in bytes
 * @returns {Object} - object with two keys, geojson and tolerance
 * @throws {Error} if file size is still too large after 3 attempts
 */
function simplifyGeoJson(geoJson, fileSizeLimit) {
    // all geojsons are converted to wgs84, so tolerance is in degrees
    const tolerances = [0.0001, 0.001, 0.01]
    const tolerancesinM = [10, 100, 1000] // tolerances in M at equator to report to user

    let simplifiedGeoJson = geoJson;
    let attempts = 0;
    while (attempts < 3) {
        // size of the geojson in bytes
        const geoJsonSize = new TextEncoder().encode(JSON.stringify(simplifiedGeoJson)).length;
        if (geoJsonSize > fileSizeLimit) {
            console.info(`File size in MB ${geoJsonSize / 1000000} is too large, attempting to simplify with tolerance ${tolerancesinM[attempts]}`)
            simplifiedGeoJson = simplify(simplifiedGeoJson, {
                "tolerance": tolerances[attempts],
                "highQuality": false,
                "mutate": false
            });
            attempts++;
        } else {
            break;
        }
    }

    const finalSize = new TextEncoder().encode(JSON.stringify(simplifiedGeoJson)).length;

    if (finalSize > fileSizeLimit) {
        throw new Error(`File size in MB ${finalSize / 1000000} is still too large after simplification, please simplify your file further`);
    }

    console.info(`File size in MB ${finalSize / 1000000} is within limit, no simplification needed`)
    if (attempts === 0) {
        return {
            geojson: simplifiedGeoJson,
            tolerance: null
        }
    }
    console.info(`simplified with tolerance ${tolerancesinM[attempts-1]}m`)
    return {
        geojson: simplifiedGeoJson,
        tolerance: tolerancesinM[attempts-1]
    }
}

function csvToGeoJson(data, latColumn, lonColumn, projection) {
    let features = data.map(row => {
        let lat = parseFloat(row[latColumn]);
        let lon = parseFloat(row[lonColumn]);
        let geometry = {
            type: "Point",
            coordinates: [lon, lat]
        }
        return {
            type: "Feature",
            geometry: geometry,
            properties: row
        }
    });

    // extract the epsg number from the projection string
    let epsg = projection.split(":").pop();

    if ([null, undefined, ""].includes(epsg)) {
        epsg = 4326;
    }

    // https://geojson.org/geojson-spec#coordinate-reference-system-objects
    const crs = {
        "type": "name",
        "properties": {
            "name": `urn:ogc:def:crs:EPSG::${epsg}`
        }
    }

    var geojson = {
        type: "FeatureCollection",
        features,
        crs
    }

    return parseGeoJson(geojson);
}

export {
    getGeoJson,
    getShapes,
    validateGeoJson,
    getBbox,
    simplifyGeoJson,
    csvToGeoJson
}