import axios from "axios";
import { v4 as uuidv4 } from "uuid";

// this is not used anymore
const API_GATEWAY_ENDPOINT = '';//process.env.VUE_APP_API_GATEWAY_ENDPOINT;

const defaultConfig = {
  zone: "hoa", 
  country: "HOA", 
  period: 90,
  workflow: null, 
  predictive: false, 
  scenario: "rcp45",
  year: "2030"
}

const state = {
  blocks: [],
  config: defaultConfig,
  menuVisible: true,
  dashMaximized: false,
  loading: false,
  error: false,
};

const getters = {
  getBlocks: state => {
    return state.blocks;
  },
  getConfig: state => {
    return state.config;
  },
  isMenuVisible: state => {
    return state.menuVisible;
  },
  isDashMaximized: state => {
    return state.dashMaximized;
  },
  isLoading: state => {
    return state.loading;
  },
  isError: state => {
    return state.error;
  }
};

const mutations = {
  setBlocks(state, blocks) {
    state.blocks = blocks;
  },
  setConfig(state, config) {
    state.config = config;
  },
  setZone(state, zone) {
    state.config.zone = zone;
  },
  setCountry(state, country) {
    state.config.country = country;
  },
  setPeriod(state, period) {
    state.config.period = parseInt(period);
  },
  setWorkflow(state, workflow) {
    state.config.workflow = workflow;
  },
  setPredictive(state, predictive) {
    state.config.predictive = predictive;
  },
  setMenuVisible(state, visible) {
    state.menuVisible = visible;
  },
  setDashMaximized(state, maximized) {
    state.dashMaximized = maximized;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  }
};

const actions = {
  async loadBlocks(context) {
    context.commit('setLoading', true);
    context.commit('setBlocks', [{ runResult: {}, isLoading: true }]);

    let reqConfig = {}

    if(context.state.config.predictive) {
      reqConfig = {
        zone: context.state.config.zone,
        country: context.state.config.country,
        workflow: context.state.config.workflow,
        predictive: context.state.config.predictive,
        scenario: context.state.config.scenario,
        year: context.state.config.year
      }
    } else {
      reqConfig = {
        zone: context.state.config.zone,
        country: context.state.config.country,
        workflow: context.state.config.workflow,
        predictive: context.state.config.predictive,
        period: context.state.config.period
      }
    }
    
    try {
      let response = await axios
        .post(API_GATEWAY_ENDPOINT, reqConfig, {
          headers: { 'content-type': 'application/json' }
        });
      // sort outputs according to display order
      let outputs = response.data.outputs;
      if(outputs && outputs.length > 0){
          response.data.outputs = outputs.sort((a, b) => b.displayOrder - a.displayOrder);
      }
      context.commit('setBlocks', [{ runResult: response.data, isLoading: false, id: uuidv4() }]);
      context.commit('setLoading', false);
      context.commit('setError', false);
    } catch (error) {
      console.error(error);
      context.commit('setLoading', false);
      context.commit('setError', true);
    }
  },
  async runVisualization(context) {
    await context.dispatch('loadBlocks');
    context.commit('setMenuVisible', false);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};