const state = {
    pageState: null
}

const getters = {}

const mutations = {
    setPageState(state, data) {
        state.pageState = data
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};