/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["WORKFLOW_COMPARE_TITLE"] = "Calculate parcel statistics  ";
Blockly.Msg["WORKFLOW_COMPARE_DATA_LABEL"] = "For the parcels in";
Blockly.Msg["WORKFLOW_COMPARE_DATA_COMPARE_LABEL"] = "Using";
Blockly.Msg["WORKFLOW_COMPARE_OUTPUT_LABEL"] = "Output";


export default Blockly.Msg;