<template>
    <div>
        <EbxAlert v-if="isGlobalTemplate && assets.length > 0" theme="warning" :class="className">
            Please make sure that any example areas used in variables have been uploaded to the map, and do not come from uploaded assets.
        </EbxAlert> 
        <EbxAlert v-else-if="hasAssets" theme="info" :class="className">
            {{ textForModal }} will move {{ hasMultipleAssets ? 'assets' : 'the asset' }}  {{ assetsText }} from <strong class="ebx-bold">My Assets</strong> to <strong class="ebx-bold">Team Assets</strong>
        </EbxAlert>
    </div>
</template>

<script>
import { PROJECT_MODE } from '@/components/mixins/projectMixin';
export default {
    props: {
        assets: {
            type: Array,
            required: false,
            default: () => {
                return []
            }
        },
        className: {
            type: String,
            required: false,
            default: ''
        },
        mode: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        textForModal() {
            if(this.isPublishTemplate) {
                return 'Publishing as a template'
            }
            if(this.mode === 'updating') {
                return 'Updating the template'
            }
            return 'Moving as template to the team'
        },
        assetsText() {
            return this.assets.map(asset => {
                return '"'+asset.title+'"'
            }).join(', ')
        },
        hasMultipleAssets() {
            return this.assets.length > 1
        },
        hasAssets() {
            return this.assets.length > 0
        },
        isGlobalTemplate() {
            return this.mode === PROJECT_MODE.GLOBAL_TEMPLATE
        },
        isPublishTemplate() {
            return this.mode === PROJECT_MODE.ORG_TEMPLATE
        }
    }
}</script>