<template>
    <div>
        <TableWorkspace 
            :datasets="datasets" 
            :defined-variables="definedVariables"
            @choose-variable="handleChooseVariable" 
            @change-variable="handleChangeVariable"
            @variables-changed="handleVariablesChanged"
            @delete-calculator-row="handleDeleteRow"
        />
    </div>
</template>

<script>
import TableWorkspace from './VariableTable/TableWorkspace.vue';
import tableCreatorRowMixin from '../mixins/tableCreatorRowMixin'
export default {
    mixins: [
        tableCreatorRowMixin
    ],
    components: {
        TableWorkspace
    },
    props: {
        datasets: {
            type: Array,
            default: () => []
        },
        definedVariables: {
            type: Array,
            default: () => []
        }
    },
    emits: [
        'choose-variable',
        'change-variable',
        'delete-calculator-row',
        'variables-changed'
    ],
    data() {
        return {
            selected: {}
        }
    },
    methods: {
        handleChooseVariable(rowId, variableName) {
            this.$emit('choose-variable',rowId, variableName)
        },
        handleChangeVariable(rowId, newVar, prevVar) {
            this.$emit('change-variable', rowId, newVar, prevVar)
        },
        handleDeleteRow() {
            this.$emit('delete-calculator-row')
        },
        handleVariablesChanged(vars) {
            const expandedVars = vars.map(row => {
                if(row.dataset === null) {
                    return row
                }
                this.selected = row
                const dataset = this.getDataset(row.dataset)
                return {
                    ...row,
                    defaultUnit: dataset.multi_image_temporal.defaultUnit,
                    properties: this.getSelectedProperties(row.properties).map(p => {
                        return {
                            'ebx:name': p['ebx:name'],
                            name: p.name
                        }
                    }),
                    dates: row.dates
                }
            })
            this.$emit('variables-changed', expandedVars)
        }
    }
}
</script>