import { AbstractCollection  } from "./AbstractCollection"
import { uniq } from 'lodash'

export class AttributeCollection extends AbstractCollection {

    allAttributes(asObject = false) {
        const attributeAbilities = this.abilities
        let attributeList = []
        attributeAbilities.forEach(ability => {
            if (ability.getState().replaces === true) {
                attributeList = []
            }
            // if attributes exist, add them to the list
            if (ability.getState().attributes && ability.getState().attributes.length > 0) {
                if(asObject) {
                    attributeList = attributeList.concat(ability.getState().attributes)
                }else {
                    const attributesOrig = ability.getState().attributes.map(a => [a['ebx:name'] || a['name'], a['name']])
                    attributesOrig.forEach(attribute => attributeList.push(attribute))
                }
            }
        
            // if filtered attributes exist, remove them from the list
            if (ability.getState().filtered_attributes && ability.getState().filtered_attributes.length > 0) {
                const filteredAttributes = ability.getState().filtered_attributes
                filteredAttributes.forEach(filteredAttribute => {
                    if(asObject) {
                        attributeList = attributeList.filter(attribute => (attribute['name']) !== filteredAttribute)
                    }else {
                        attributeList = attributeList.filter(attribute => attribute[1] !== filteredAttribute)
                    }
                })
            }
            
        })

        if(asObject) {
            return attributeList
        }
        // return a list and remove duplicates
        return uniq(attributeList)
    }

    getAttributeByName(name) {
        return this.allAttributes().find(a => (a['name']) === name)
    }

    filteredAttributes() {
        return this.abilities.flatMap(a => a.getFilteredAttributes())
    }

    numericAttributes() {
        const attributeAbilities = this.abilities
        let attributeList = []
        attributeAbilities.forEach(ability => { 
            // if attributes exist and are numeric, add them to the list
            if (ability.getState().attributes && ability.getState().attributes.length > 0) {
                const attributesOrig = ability.getState().attributes.filter(a => {
                    if(a['ebx:datatype'] === 'numeric') {
                        return true
                    }
                    if(['INT', 'DOUBLE', 'INTEGER'].indexOf(a['type']) >= 0) {
                        return true
                    }
                    return false
                }).
                map(a => [a['ebx:name'] || a['name'], a['name']])
                attributesOrig.forEach(attribute => attributeList.push(attribute))
            }
        })
        // return a list and remove duplicates
        return uniq(attributeList)
    }

}