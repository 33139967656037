<template>
    <div>
        <div class="dataset-attributes-tab--empty" v-if="allProperties.length === 0">
            <div class="ebx-icon">
                <img :src="assets.icons.emptyStateAssets" alt="No assets">
            </div>
            <p class="ebx-primary">
                No attributes have been added.
                <template v-if="canEdit">
                    <br />
                    <EbxButton theme="tertiary" icon="add" @click="handleNewValue">
                        Add attribute
                    </EbxButton>
                </template>
            </p>
        </div>
        <div v-else>
            <md-table class="md-table-ebx">
                <md-table-row>
                    <md-table-head>Name</md-table-head>
                    <md-table-head>Type</md-table-head>
                    <md-table-head>Value</md-table-head>
                    <th v-if="existingCount > 0" class="md-table-head md-table-action">&nbsp;</th>
                </md-table-row>
                <md-table-row v-for="property in allProperties" :key="property.id" :class="rowClass(property)">
                    <template v-if="editingProperty(property.id)">
                        <md-table-cell>
                            <md-field>
                                <md-input v-model="editProperties[property.id].name" placeholder="Name"
                                    @blur="validateEditedRows" />
                            </md-field>
                            <div v-for="error in getErrorsForKey('name', editProperties[property.id].errors)" :key="error"
                                class="ebx-error">{{ error }}</div>
                        </md-table-cell>
                        <md-table-cell>
                            <md-field>
                                <md-select v-model="editProperties[property.id].type" placeholder="Type" @update:modelValue="validateEditedRows">
                                    <md-option v-for="option in typeOptions" :key="option.value"
                                        :value="option.value">{{ option.label }}</md-option>
                                </md-select>
                            </md-field>
                            <div v-for="error in getErrorsForKey('type', editProperties[property.id].errors)" :key="error"
                                class="ebx-error">{{ error }}</div>
                        </md-table-cell>
                        <md-table-cell>
                            <md-field>
                                <md-input v-model="editProperties[property.id].value" placeholder="Value"
                                    @blur="validateEditedRows" />
                            </md-field>
                            <div v-for="error in getErrorsForKey('value', editProperties[property.id].errors)" :key="error"
                                class="ebx-error">{{ error }}</div>
                        </md-table-cell>
                        <md-table-cell v-if="existingCount > 0" class="md-table-action">
                            &nbsp;
                        </md-table-cell>
                    </template>
                    <template v-else>
                        <md-table-cell>{{ property.name }}</md-table-cell>
                        <md-table-cell>{{ property.type === 'float' ? 'DECIMAL' : property.type.toUpperCase()
                        }}</md-table-cell>
                        <md-table-cell>
                            {{ property.value }}
                        </md-table-cell>
                        <md-table-cell class="md-table-action">
                            <template v-if="property.mode === 'delete'">
                                <md-button class="md-icon-button" @click="cancelEditing(property.id)">
                                    <md-icon>close</md-icon>
                                </md-button>
                            </template>
                            <template v-else-if="canEdit">
                                <md-menu>
                                    <md-button class="md-icon-button" md-menu-trigger>
                                        <md-icon>more_vert</md-icon>
                                    </md-button>

                                    <md-menu-content class="asset-options-menu">
                                        <md-menu-item class="asset-options-menu--option"
                                            @click="handleEdit(property, property.id)">
                                            <span class="material-icons-outlined ebx-icon">mode</span>
                                            <span>Edit</span>
                                        </md-menu-item>
                                        <md-menu-item class="asset-options-menu--option"
                                            @click="handleDelete(property, property.id)">
                                            <span class="material-icons-outlined ebx-icon">delete_outline</span>
                                            <span>Delete</span>
                                        </md-menu-item>

                                    </md-menu-content>
                                </md-menu>
                            </template>
                        </md-table-cell>
                    </template>

                </md-table-row>
            </md-table>
            <div v-if="canEdit" class="md-layout mt-8">
                <div class="md-layout-item">
                    <EbxButton v-if="editingCount === 0" icon="add" theme="tertiary" class="no-padding no-margin" @click="handleNewValue" >
                        Add attribute
                    </EbxButton>
                </div>
                <div class="md-layout-item" style="text-align:right">
                    <template v-if="hasChanges">
                        <EbxButton theme="secondary" :disabled="isSaving" @click="handleCancel">
                            Cancel
                        </EbxButton>
                        <EbxButton :loading="isSaving" class="ml-8" :disabled="isSaving || anyErrors" @click="handleSave">
                            Save
                        </EbxButton>
                    </template>
                </div>
            </div>
            <Alert v-if="hasErrored" theme="danger" class="mt-8">
                {{ responseError }}
            </Alert>
            
        </div>
        <confirmation-modal ref="modal" :hide-title="true" ok-button-text="Delete" ok-button-class="md-raised md-danger">
            <p class="ebx-primary">
                Are you sure you want to delete this attribute?
            </p>
        </confirmation-modal>
    </div>
</template>

<script>
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import Alert from '@/components/Alert.vue';
import { v4 as uuidv4 } from 'uuid';
import assetsMixin from '@/components/mixins/assetsMixin.js'

export default {
    components: {
        ConfirmationModal,
        Alert
    },
    mixins: [
        assetsMixin
    ],
    name: 'Attributes',
    props: {
        dataset: {
            type: Object,
            required: true
        },
        datasetImage: {
            type: Object,
            required: true
        },
        formState: {
            type: Object,
            required: true
        },
        canEdit: {
            type: Boolean,
            required: true
        }
    },
    emits: [
        'update-properties',
        'reset'
    ],
    data() {
        return {
            typeOptions: [
                { value: 'string', label: 'String' },
                { value: 'integer', label: 'Integer' },
                { value: 'float', label: 'Decimal' }
            ],
            newProperty: {
                name: '',
                type: 'string',
                value: ''
            },
            editProperties: {},
        }
    },
    watch: {
        isSaving(current, previous) {
            if (previous === true && current === false) {
                if (this.isIdle) {
                    this.editProperties = {}
                }
            }
        }
    },
    computed: {
        isSaving() {
            return this.formState.state === 'saving'
        },
        hasErrored() {
            return this.formState.state === 'errored'
        },
        isIdle() {
            return this.formState.state === 'idle'
        },
        responseError() {
            return  this.formState.error
        },
        hasChanges() {
            return this.editingCount > 0
        },
        existingCount() {
            return this.properties.length
        },
        editingCount() {
            return Object.keys(this.editProperties).length
        },
        allProperties() {
            const existingIds = this.properties.map(p => p.id)
            const allProperties = this.properties.slice(0)
            Object.keys(this.editProperties).forEach(k => {
                const p = this.editProperties[k]
                if (!existingIds.includes(p.id)) {
                    allProperties.push(p)
                } else {
                    const index = allProperties.findIndex(ap => ap.id === p.id)
                    allProperties[index] = p
                }
            })
            return allProperties
        },
        properties() {
            let properties = []
            if (this.datasetImage && typeof this.datasetImage.properties === 'object' && this.datasetImage.properties !== null) {
                const propKeys = Object.keys(this.datasetImage.properties)
                for (let i = 0; i < propKeys.length; i++) {
                    const propKey = propKeys[i]
                    const propValue = this.datasetImage.properties[propKey]
                    properties.push({
                        id: uuidv4(),
                        name: propKey,
                        value: propValue,
                        type: this.detectDataType(propValue),
                        mode: 'existing',
                        errors: []
                    })
                }
            }
            return properties
        },
        anyErrors() {
            const errorCount = Object.keys(this.editProperties).reduce((acc, key) => {
                const p = this.editProperties[key]
                return acc + p.errors.length
            }, 0)
            return errorCount > 0
        }
    },
    methods: {
        handleNewValue() {
            const id = uuidv4()
            this.editProperties[id] = {
                id,
                name: '',
                value: '',
                type: 'string',
                mode: 'new',
                errors: []
            }
            this.validateEditedRows()
        },
        async handleDelete(property, i) {
            if (this.canEdit === false) {
                return
            }
            const confirmed = await this.$refs.modal.confirm()
            if (confirmed) {
                this.editProperties[i] = {
                    ...property,
                    mode: 'delete',
                    errors: []
                }
                this.validateEditedRows()
            }
        },
        editingProperty(i) {
            return typeof this.editProperties[i] === 'object' && this.editProperties[i] !== null && this.editProperties[i].mode !== 'delete'
        },
        cancelEditing(i) {
            delete this.editProperties[i]
        },
        handleEdit(property, i) {
            if (this.canEdit === false) {
                return
            }
            this.editProperties[i] = {
                ...property,
                mode: 'edit',
                errors: []
            }
        },
        handleSave() {
            if (this.canEdit === false) {
                return
            }
            const exportProperties = {}
            const deleteExports = {}
            const keys = Object.keys(this.editProperties)

            if (this.validateEditedRows() === false) {
                return
            }

            keys.forEach(key => {
                const p = this.editProperties[key]
                if (p.mode === 'delete') {
                    deleteExports[p.name] = null
                } else {
                    switch (p.type) {
                        case 'integer':
                            exportProperties[p.name] = parseInt(p.value)
                            break
                        case 'float':
                            exportProperties[p.name] = parseFloat(p.value)
                            break
                        default:
                            exportProperties[p.name] = '' + p.value
                    }

                }
            })
            const properties = Object.assign({}, deleteExports, exportProperties)
            if (Object.keys(properties).length > 0) {
                this.$emit('update-properties', properties, this.datasetImage.id, this.dataset.id)
            }
        },
        handleCancel() {
            this.editProperties = {}
            this.$emit('reset')
        },
        detectDataType(value) {
            value = '' + value
            if (value.match(/^-?\d+$/)) {
                return 'integer'
            }
            if (value.match(/^-?\d+\.\d+$/)) {
                return 'float'
            }
            return 'string'
        },
        validateEditedRows() {
            const keys = Object.keys(this.editProperties)
            let errorCount = 0
            keys.forEach(key => {
                const p = Object.assign({}, this.editProperties[key])
                p.errors = []
                if (p.mode === 'delete') {
                    return
                }
                const names = this.allProperties.filter(p2 => p2.id !== p.id).map(p2 => p2.name)
                if (names.includes(p.name)) {
                    p.errors.push({ key: 'name', value: 'Name must be unique' })
                }
                if (p.name === '') {
                    p.errors.push({ key: 'name', value: 'Name is required' })
                }
                if ( /\s/.test(p.name)) {
                     p.errors.push({ key: 'name', value: 'Name must not include spaces' })
                }
                if (p.name.match(/^[0-9_a-zA-Z]+$/) === null) {
                    p.errors.push({ key: 'name', value: 'Name must be alphanumeric and may contain underscores' })
                }
                if (p.type !== 'string' && p.value === '') {
                    p.errors.push({ key: 'value', value: 'Value is required' })
                } else {
                    if (p.type === 'integer' && !p.value.match(/^-?\d+$/)) {
                        p.errors.push({ key: 'value', value: 'Value must be an integer' })
                    }
                    if (p.type === 'float' && !p.value.match(/^-?\d+\.\d+$/)) {
                        p.errors.push({ key: 'value', value: 'Value must be a decimal' })
                    }
                }
                errorCount += p.errors.length
                this.editProperties[key].errors = p.errors
            })
            return errorCount === 0
        },
        rowClass(property) {
            return {
                deleted: property.mode === 'delete',
                errored: this.editProperties[property.id] && this.editProperties[property.id].errors.length > 0,
                editing: this.editingProperty(property.id)
            }
        },
        getErrorsForKey(key, errors) {
            return errors.filter(e => e.key === key).map(e => e.value)
        }
    },
}
</script>
