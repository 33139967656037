<template>
  <input
    ref="input"
    class="md-input"
    v-model="model"
    v-bind="attributes"
    v-on="listeners"
    @focus="onFocus"
    @blur="onBlur">
</template>

<script>
  import MdComponent from '../../core/MdComponent'
  import MdUuid from '../../core/utils/MdUuid'
  import MdFieldMixin from '../MdFieldMixin'

  export default MdComponent({
    name: 'MdInput',
    mixins: [MdFieldMixin],
    inject: ['MdField'],
    props: {
      id: {
        type: String,
        default: () => 'md-input-' + MdUuid()
      },
      type: {
        type: String,
        default: 'text'
      }
    },
    computed: {
      toggleType () {
        return this.MdField.togglePassword
      },
      isPassword () {
        return this.type === 'password'
      },
      listeners () {
        const keys = this.$attrs ? Object.keys(this.$attrs) : []
        const listeners = keys.reduce((acc, key) => {
          if (key.indexOf('on') === 0) {
            acc[key] = this.$attrs[key]
          }
          return acc
        }, {})
        return listeners
      }
    },
    watch: {
      type () {
        this.setPassword(this.isPassword)
      },
      toggleType (toggle) {
        if (toggle) {
          this.setTypeText()
        } else {
          this.setTypePassword()
        }
      }
    },
    methods: {
      setPassword (state) {
        this.MdField.password = state
        this.MdField.togglePassword = false
      },
      setTypePassword () {
        this.$refs.input.type = 'password'
      },
      setTypeText () {
        this.$refs.input.type = 'text'
      }
    },
    created () {
      this.setPassword(this.isPassword)
    },
    beforeUnmount () {
      this.setPassword(false)
    }
  })
</script>
