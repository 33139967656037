
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import { ContentService } from '@/services/content.service';
import focalAnalysisModal from '@/modals/focalAnalysis.vue';
import { AbstractBlock} from './_abstractBlock';
import assets from '@/assets.js';

const analysisDropdownOptions = [
    ["Smooth my image", "smooth"],
    //["Sharpen my image","sharpen"], 
    ["Detect edges in my Image","edge_detection"], 
    ["Calculate focal statistics", "focal_stats"]
]

const statsOptions = [
    ["Count","count"], 
    ["Max","max"],
    ["Mean","mean"],
    ["Median","median"],
    ["Min","min"],
    ["Mode","mode"],        
    ["Standard Deviation","stdDev"],
    ["Sum","sum"],
    ["Variance","variance"],   
]

const smoothKernels = [
    ["square","square"],
    ["circle","circle"],
    ["euclidean","euclidean"],
    ["manhattan","manhattan"],
    ["gaussian","gaussian"],
    ["chebyshev","chebyshev"],
]


const sharpenKernels = [
    ["square","square"],
    ["circle","circle"],
]

const edgeDetectionKernels = [
    ["prewitt","prewitt"],
    ["roberts","roberts"],
    ["laplacian4","laplacian4"],
    ["laplacian8","laplacian8"],
    ["compass","compass"],
    ["kirsch",'kirsch']
]

const focalStatsKernels = [
    ["square","square"],
    ["circle","circle"],
]

const analysisOptions = {
    "showModal": false, 
   // "statsOptions": statsOptions, 
    "newBandName":[], 
}

var focalAnalysisJson ={
    "type": "analysis_focal_analysis",
    "lastDummyAlign0": "RIGHT",
    "message0": `%1 %2`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "analysis_title",
            "text": "%{BKY_ANALYSIS_FOCAL_ANALYSIS_BLOCK_TITLE}",
            "class": "boldTitleField"
        },
        {
            "type": "field_modal",
            "name": "focal_modal",
            "modal_component": focalAnalysisModal,
            "src": assets.blockly.settingsWhite24dp,
            "modal_data": analysisOptions,
            "width": 24,
            "height": 24,
            "opt_alt": "ALT STRING"
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "calculate",
    "tooltip": "",
    "helpUrl": ""
}


Blockly.Blocks['analysis_focal_analysis'] = {
    ...AbstractBlock,

    onInit: function() {
        this.jsonInit(focalAnalysisJson)

        this.getField('focal_modal').setModalData('dropdownOptions',analysisDropdownOptions)

        this.getField('focal_modal').setModalData('smoothKernelOptions',smoothKernels)
        this.getField('focal_modal').setModalData('sharpenKernelOptions',sharpenKernels)
        this.getField('focal_modal').setModalData('edgeDetectionKernelOptions',edgeDetectionKernels)
        this.getField('focal_modal').setModalData('focalStatsKernelOptions',focalStatsKernels)

        this.getField('focal_modal').setModalData('statsOptions',statsOptions)

        this.about_block_url = ContentService.getAboutBlockUrl('analysis_focal_analysis')
    },
    accept: async function (visitor) {
        console.log('accept analysis_focal_analysis visitor')
        await visitor.visitAnalysisFocalAnalysisBlock(this);
    },
    ebxValidate: function(tooltips) {

        this.setWarningText(null, 'not_raster')
        const isRaster = this.getState('isRaster')
        if(!isRaster) {
            this.setWarningText(tooltips['not_raster'] || 'This block is only compatible with raster datasets.', 'not_raster')
            return
        }

        const modalField = this.getFieldValue('focal_modal')
        if(modalField !== null) {
            // reset all warnings
            this.setWarningText(null, 'no_bands')
            this.setWarningText(null, 'no_kernel')
            this.setWarningText(null, 'no_suffix')
            if(modalField.selectedBands === undefined || modalField.selectedBands.length === 0) {
                this.setWarningText(tooltips['no_bands'] || 'Please select bands to use for analysis', 'no_bands')
            }
            if(modalField.kernel === null || modalField.kernel === '') {
                this.setWarningText(tooltips['no_kernel'] || 'Please select a kernel', 'no_kernel')
            }
            if(modalField.suffix === null || modalField.suffix === '') {
                this.setWarningText(tooltips['no_suffix'] || 'Please specify a suffix', 'no_suffix')
            }
            // if (modalField.selectedMagnitude) {
            //     if(modalField.selectedMagnitude === undefined) {
            //         return this.setWarningText(tooltips['no_magnitude'] || 'Please specify a magnitude value', 'no_magnitude')
            //     }
            // }
            // if (modalField.sigma) {
            //     if(modalField.sigma === undefined) {
            //         return this.setWarningText(tooltips['no_sigma'] || 'Please specify a sigma value', 'no_sigma')
            //     }

            // this.setWarningText(null, 'no_magnitude')
            // this.setWarningText(null, 'no_sigma')
        }
    },

    updateShape_:function() { 
        this.updateBandDropdown_()
        this.updateGsdValue_()
    },

    updateGsdValue_: function() {
        if(this.hasStateChanged('gsd')) {
            const modalField = this.getField('focal_modal')
            if(modalField) { 
                const gsd = this.getState('gsd')
                modalField.setModalData('gsd',gsd)
            }
        }
    },
    updateBandDropdown_: function() {
        if(this.hasStateChanged('bands')) {
            const modalField = this.getField('focal_modal')
             if(modalField) { 
                const bands = this.getState('bands')
                modalField.setModalData('available_bands',bands)
            }
        }
    },
    
}; 