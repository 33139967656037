/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["MODIFIER_COMPOSITE_TITLE"] = "Aggregate images in time";
Blockly.Msg["MODIFIER_COMPOSITE_METHOD_LABEL"] = "Using the";
Blockly.Msg["MODIFIER_COMPOSITE_GROUP_LABEL"] = "Combine images";
Blockly.Msg["MODIFIER_COMPOSITE_INTERVAL_LABEL"] = "Creating a new image every";

export default Blockly.Msg;