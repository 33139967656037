/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["DATERANGE_BLOCK_TITLE"] = "Limit dates";
Blockly.Msg["DATERANGE_OPTIONS_LABEL"] = "Type: ";
Blockly.Msg["DATERANGE_DATE1_LABEL"] = "From: ";
Blockly.Msg["DATERANGE_DATE2_LABEL"] = "To: ";

export default Blockly.Msg;