<template>
    <div class="package">
        <div class="package-page">
            <div class="package-page--content">
                <div class="dataset-page--back-action ebx-action-text ebx-action-text--primary ebx-action-text__icon" @click="exitView">
                    <span class="material-icons-outlined ebx-icon">chevron_left</span>
                    <p>{{backLinkText}}</p>
                </div>
                <div v-show="!loadingTemplates" class="packageProjects" >
                    <h1 class="ebx-header-1">
                        {{packageTitle}}
                    </h1>
                    <p class="ebx-primary">{{packageDescription}}</p>
                    <EbxTemplateWorkflows 
                    :isSubsetTemplates="true" 
                    v-model="loadingTemplates"
                    :searchHidden="true" 
                    :tagsHidden="true" 
                    :packageWorkflowIds="projectsAssignedIds" 
                    :packageTitle="packageTitle"/>

                    <div v-for="pkg in nestedPackages" :key="pkg.title" class="nestedPackages">
                        <div class="nestedPackage"> 
                            <h1 class="ebx-header-2">{{pkg.title}}</h1>
                            <p class="ebx-primary">{{pkg.description}}</p>
                            <EbxTemplateWorkflows 
                            :isSubsetTemplates="true" 
                            v-model="loadingTemplates" 
                            :searchHidden="true"  
                            :tagsHidden="true" 
                            :packageWorkflowIds="pkg.projectsAssignedIds" 
                            :packageTitle="pkg.title"/>
                        </div>
                    </div>
                </div>
                
                <md-progress-spinner v-if="loadingTemplates"
                    md-mode="indeterminate" 
                    class="static-loading-spinner md-accent assets-tab--loading"
                    :md-diameter="80"
                ></md-progress-spinner>
            </div>
        </div>
    </div>
</template>


<script>
import EbxTemplateWorkflows from '@/components/EbxComponents/EbxTemplateWorkflows.vue'
import packageMixin from "@/components/mixins/packageMixin";
import { isNotNullOrUndefined } from '@/helpers/generalHelperFunctions.js'

export default {
    name: 'Package',
    components: {
        EbxTemplateWorkflows
    },
    mixins: [packageMixin],
    data() {
        return {
            loadingTemplates: true,
            backLinkText:'Explore',
            packageTitle: '',
            packageDescription: '',
            projectsAssignedIds: [],
            nestedPackages : [],
        }
    },
    async mounted() {
        await this.getGlobalPackages();
        await this.getPackage(this.$route.params.packageId);
    },
    created() {
        this.loadingTemplates = true;
    },
    methods: {
        exitView() {
            if (this.backLinkText === 'Projects') {
                this.$router.push({name: 'Projects-Workflows'})
            } else if (this.backLinkText === 'Explore') {
                this.$router.push({name: 'Projects-Explore'})
            } else {
                this.$router.go(-1);
            }
        },
        async getPackage(id) {
            const pkgObj = await this.getPackageById(id);
            const pkgPkgs = pkgObj.packagesAssigned.packageIds;
            pkgPkgs.forEach(async (pkgId) => {
                const pkgInfo = await this.getPackageById(pkgId);
                this.nestedPackages.push({
                    title: pkgInfo.title,
                    description: pkgInfo.description,
                    projectsAssignedIds: pkgInfo.projectsAssigned.projectIds
                
                });
            this.nestedPackages = this.nestedPackages.slice().sort((a, b) => a.title.localeCompare(b.title));
            });
            this.packageTitle = pkgObj.title;
            this.packageDescription = pkgObj.description;
            this.projectsAssignedIds = pkgObj.projectsAssigned.projectIds;
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (isNotNullOrUndefined(to)) { 
                    const pageState = this.$store.state.router.pageState
                    if (pageState && pageState.origin === "Projects") {
                        this.backLinkText = 'Projects'
                    } else { 
                        this.backLinkText = 'Explore'
                    }
                }
            },
        },
    }
}
</script>