/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import { hasValidYears, hasValidDates } from '@/blocks/dataset';
import { validateLBlock } from './lblock_validation';
import {NO_DATA_DROPDOWN_VALUE} from "@/constants/nextGenConstants";
import { ContentService } from '@/services/content.service';


/**
 * Checks if a block is assigned an area other that NA (does not currently 
 * check if those areas have polygons) then sets warning text
 * @param {*} block 
 */

function validateArea(block) {
    let area = block.getFieldValue("study_area_options");
    if(area !== null) {
        if(area === NO_DATA_DROPDOWN_VALUE){
            let tooltips = ContentService.getWarningText('workspace_validation')
            block.setWarningText(tooltips['area'], "area_warning");
        } 
        // TODO: add this back to validation once global state areas has been added
        // else if (area.startsWith("Area")) {
        //     let polygons = AreaService.getShapesForArea(area);
        //     if(!polygons.length > 0){
        //         block.setWarningText("Area does not contain any polygons", "area_warning");
        //     } else {
        //         block.setWarningText(null, "area_warning");
        //     }
        // } 
        else {
            block.setWarningText(null, "area_warning");
        }
    }
}

/**
 * Takes block and checks for the standard block dates and checks for valid dates
 * applies warnings if invalid
 * @param {*} block 
 * @returns 
 */
function validateDates(block){
    let fd = block.getFieldValue("fd");
    let td = block.getFieldValue("td");
    let fd_yearly = block.getFieldValue("fd_yearly")
    let td_yearly = block.getFieldValue("td_yearly")
    if(fd !== null && td !==null){
        // do validation
        block.setWarningText(hasValidDates(block, fd, td), "date_warning");
    } else if (fd_yearly !== null && td_yearly !== null) {
        // do palsar validation
        block.setWarningText(hasValidYears(fd_yearly, td_yearly), "date_warning");
    }
}

/**
 * Takes a workspace
 * Gets all topBlocks and their descendants
 * Validates all blocks for areas, dates and roles
 * @param {*} workspace 
 */
const validateWorkspace = async (workspace) => {
    
    const validateBlock = async topBlock => {

        console.log("topblock type:" , topBlock.type)

        let inputCount = 0;
        let analyseCount = 0;
        let outputCount = 0;

        // insert dataset is considered an input
        if(topBlock.type.startsWith('workflow') ||
           topBlock.type.startsWith("modifier") ||
           topBlock.type.startsWith("output") ||
           topBlock.type.startsWith("study") ||
           topBlock.type.startsWith("analysis")
        ) 
        {
            // This function validates blocks and their descendants
            await validateLBlock(topBlock)
            if(topBlock.type === 'output_add_table') {
                return;
            }
        }
        
        // get all connected blocks
        let descendants = topBlock.getDescendants();
        descendants.forEach(descendant => {
            validateArea(descendant)
            validateDates(descendant)
            // checks their roles
            let blockRole = descendant.type.split('_')[0];
            
            if(blockRole === "input" || descendant.type === 'workflow_insert_dataset') {
                inputCount++;
            } else if (blockRole === "analyse") {
                analyseCount++;
            } else if (blockRole === "output") {
                outputCount++;
            }

            // resets all blocks while being looped over, this means only the parent block gets a warning
            descendant.setWarningText(null, "role_warning")
        })

        let tooltips = ContentService.getWarningText('workspace_validation')

        // sets a top level warning
        if (inputCount > 0 && outputCount === 0) {
            topBlock.setWarningText(tooltips['output'], "role_warning");
        } else if (inputCount === 0 && outputCount > 0) {
            topBlock.setWarningText(tooltips['input'], "role_warning");
        } else if (inputCount === 0 && analyseCount > 0 && outputCount === 0) {
            topBlock.setWarningText(tooltips['input_output'], "role_warning");
        } else {
            topBlock.setWarningText(null, "role_warning");
        }
    }

    // get all parents blocks (WARNING: getTopBlocks() will show an extra block whenever a block is being dragged, this block has a new ID too)
    let topBlocks = workspace.getTopBlocks()
    for (let i=0; i< topBlocks.length; i++) {
        await validateBlock(topBlocks[i])
    }
}

export {validateWorkspace}