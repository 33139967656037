/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["ANALYSIS_BUFFER_BLOCK_TITLE"] = "Add buffer to features  ";
Blockly.Msg["ANALYSIS_BUFFER_DISTANCE_LABEL"] = "Distance";
Blockly.Msg["ANALYSIS_BUFFER_TIME_LABEL"] = "Time";
Blockly.Msg["ANALYSIS_BUFFER_DISTANCE_UNIT_LABEL"] = "km";
Blockly.Msg["ANALYSIS_BUFFER_TIME_UNIT_LABEL"] = "hours";

export default Blockly.Msg;