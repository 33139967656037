import {createStore} from "vuex";

import dashboard from "@/store/modules/dashboard/dashboard.js";
import maplayers from "@/store/modules/maplayers/maplayers.js";
import pixelInspector from "@/store/modules/maplayers/pixelInspector.js";
import mapevents from "@/store/modules/maplayers/mapevents.js";

import userassets from "@/store/modules/user/userassets.js";
import userdatasets from "@/store/modules/user/userdatasets.js";

import auth from "@/store/modules/auth.js";
import runresult from "@/store/modules/runresult.js";
import googleMarketPlace from "./modules/googleMarketPlace.js";
import stripe from "@/store/modules/stripe.js";
import organisations from "@/store/modules/organisations.js";
import project from "@/store/modules/project.js";
import packages from "@/store/modules/packages.js";
import global from "@/store/modules/global.js";
import blockly from "@/store/modules/blockly.js"; 
import router from "@/store/modules/router.js";

const store = createStore({
    modules: {
        auth,
        dashboard,
        maplayers,
        runresult,
        googleMarketPlace,
        stripe,
        userassets,
        userdatasets,
        organisations,
        project,
        packages,
        global,
        blockly,
        pixelInspector,
        mapevents,
        router
    }
});

export default store;