import {
    organisationsCollection,
    googleMarketPlaceCollection} from '@/firebase.js';
import { GOOGLE_MARKETPLACE_ACTIVE_ENTITLEMENT_STATES } from "../../constants/appConstants";

const state = {
    userHasActiveEntitlement: null,
    marketPlaceCollectionSubscription: null
}

const getters = {}

const mutations = {
    setUserHasActiveEntitlement(state, userHasActiveEntitlement) {
        state.userHasActiveEntitlement = userHasActiveEntitlement;
    },
    setMarketPlaceCollectionSubscription(state, marketPlaceCollectionSubscription) {
        state.marketPlaceCollectionSubscription = marketPlaceCollectionSubscription;
    }
}

const actions = {
    queryOrgSubscriptions(context, orgId) {
        let marketPlaceAccountId = null;
        if (!orgId) {
            return;
        }
        // get org document using orgId and then get value of marketPlaceAccountId field
        organisationsCollection.doc(orgId).get().then(doc => {
            if (doc.exists) {
                marketPlaceAccountId = doc.data().marketPlaceAccountId;
            }
            if (marketPlaceAccountId) {
                // check googleMarketPlace collection for document with matching marketPlaceAccountId
                googleMarketPlaceCollection.doc(marketPlaceAccountId).get().then(doc => {
                    if (doc.exists) {
                        // get the entitlements subcollection and check for any documents with state = ACTIVE
                        const unsubscribe = doc.ref.collection("entitlements").where("state", "in", GOOGLE_MARKETPLACE_ACTIVE_ENTITLEMENT_STATES).onSnapshot(querySnapshot => {
                            if (querySnapshot.empty) {
                                // commit to state
                                context.commit('setUserHasActiveEntitlement', false);
                            } else {
                                // commit to state
                                context.commit('setUserHasActiveEntitlement', true);
                            }
                        })
                        context.commit('setMarketPlaceCollectionSubscription', unsubscribe);
                    }
                })
            }

        }).catch(error => {
            console.warn(`Error getting organisation google marketplace subscription for organisation ${orgId}: ${error}`);
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
