<template>
    <md-dialog v-model:md-active="showDialog" class="ebx-dialog dialog modal-s">
        <md-dialog-content class="dialog-content">
            <p class="ebx-primary">{{secondaryDialogText}}</p>
        </md-dialog-content>
        <md-dialog-actions>
            <button class="ebx-button ebx-button--secondary" @click="showDialog = false">
                Cancel
            </button>
            <button class="ebx-button ebx-button--primary" @click="uploadToAssets()">
                Save to assets
            </button>
        </md-dialog-actions>
    </md-dialog>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import valueMixin from '@/components/mixins/valueMixin'
export default {
    name: 'GoogleMapPolygonUploadSaveFile',
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        secondaryDialogText: {
            type: String,
            default: ''
        },
    },
    mixins: [valueMixin],
    emits: [
        'update:modelValue',
        'upload-to-assets'
    ],
    computed: {
        showDialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
    },
    methods: {
        uploadToAssets() {
            this.$emit('upload-to-assets')
            this.showDialog = false
        }
    },
}
</script>