/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import Blockly from "blockly";

Blockly.Icon.prototype.SIZE = 24;

Blockly.Comment.prototype.drawIcon_ = function(group) {
    Blockly.utils.dom.createSvgElement(
        Blockly.utils.Svg.PATH,
        {
            'class': 'blocklyIconSymbol',
            'd': "M 24.5, 14 m -19, 0 a 9.5,9.5 0 1,0 19,0 a 9.5,9.5 0 1,0 -19,0",
            'transform': 'translate(-3 -9)'
        },
    group);
    
    Blockly.utils.dom.createSvgElement(
        Blockly.utils.Svg.PATH,
        {
            'class': 'blocklyIconShape commentIcon',
            'd': 'M15,5.67A8.33,8.33,0,1,0,23.33,14h0A8.33,8.33,0,0,0,15,5.67Zm.83,14.16H14.17V18.17h1.66Zm1.73-6.46-.75.77a2.84,2.84,0,0,0-1,2.36H14.17v-.42a3.37,3.37,0,0,1,1-2.36l1-1.05a1.63,1.63,0,0,0,.5-1.17,1.67,1.67,0,0,0-3.34,0H11.67a3.33,3.33,0,1,1,6.66,0h0A2.68,2.68,0,0,1,17.56,13.37Z',
            'transform': 'translate(-3 -9)'
        },
    group);
};