<template>
    <div class="exports">
        <h1 class="ebx-header-1">
            Exports
        </h1>
        <div v-if="exportedAssets.length > 0" class="assets-card">
            <div class="assets-card--items">
                <div class="assets-card--items-group">
                <ExportList 
                    v-if="exportedAssets.length > 0"
                    :exportedAssets="exportedAssets"
                    :user="user"
                />
            </div>
            </div>
        </div>
        <div v-else-if="loading" class="">
            <md-progress-spinner 
                md-mode="indeterminate" 
                class="static-loading-spinner md-accent assets-tab--loading"
                :md-diameter="80"
                ></md-progress-spinner>
        </div>
        <div class="assets-tab--empty" v-else>
            <div class="ebx-icon">
                <img :src="assets.icons.emptyStateAssets" alt="No exports">
            </div>
            <p class="ebx-primary">Datasets exported from Projects live here.</p>
        </div>
    </div>
</template>

<script>
import ExportList from "@/components/ExportList.vue";
import authMixin from "@/components/mixins/authMixin.js";
import userAssetsMixin from "@/components/mixins/userAssetsMixin.js";
import assetsMixin from "@/components/mixins/assetsMixin.js" 
import {globalEventBus} from '@/eventbus.js';

export default {
    name: "Exports",
    mixins: [authMixin, userAssetsMixin, assetsMixin],
    components: {
        ExportList
    },
    data() {
        return {
            exportedAssets: [],
            loading: false,
            userStoragePath: ''
        }
    },
    created() {
        this.userStoragePath = 'exports/' + this.user.orgId + '/' + this.user.uid;
        this.loadExports();
        globalEventBus.$on('refresh-exports', this.loadExports)
    },
    beforeUnmount() {
        globalEventBus.$off('refresh-exports', this.loadExports)
    },
    methods: {
        async loadExports() {
            this.loading = true;
            this.exportedAssets = await this.getUserExports(this.userStoragePath);
            this.loading = false;
        }
    }
}

</script>