/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["ANALYSIS_GEOPROCESSING_BLOCK_TITLE"] = "Modify features  ";
Blockly.Msg["ANALYSIS_GEOPROCESSING_LABEL"] = "with";

export default Blockly.Msg;
 
