import { organisationsCollection } from '@/firebase.js';
import { INDIVIDUAL_ORGS } from '../../constants/nextGenConstants';
import { INDIVIDUAL_ORG_TYPE } from "@/constants/appConstants.js";
import {functions} from "@/firebaseFunctions";

const state = {
    isIndividual: false,
    orgGroups: null,
    orgGroupsLoading: false,
    orgGroupPromises: []
};

const getters = {
    getIsIndividual: state => {
        return state.isIndividual;
    },
    getOnlyOrgGroups: state => (orgId) => {
        if (state.orgGroups === null || !orgId) return []
        return state.orgGroups.filter(group => group.orgId === orgId)
    }
};

const mutations = {
    setIsIndividual(state, newValue) {
        if (newValue == true){
            state.isIndividual = true;
        } else {
            state.isIndividual = false;
        }
    },
    setOrgGroups(state, groups) {
        state.orgGroups = groups
    },
    setOrgGroupsLoading(state, loading) {
        state.orgGroupsLoading = loading
    },
    setOrgGroupsPromise(state, promise) {
        state.orgGroupPromises.push(promise)
    },
    resolveOrgGroupsPromise(state, {resolve, reject}) {
        if(resolve) {
            state.orgGroupPromises.forEach(promise => promise.resolve(resolve))
        } else if(reject){
            state.orgGroupPromises.forEach(promise => promise.reject(reject))
        }
        state.orgGroupPromises = []
    }
};

const actions = {
    checkOrganisationStatus(context, orgId) { 
        if (INDIVIDUAL_ORGS.includes(orgId)) {
            // first set isIndividual to true immediately if the org id matches preconfigured values
            console.info("Organisation is hardcoded as 'individual' Cannot share workflows.");
            context.commit('setIsIndividual', true);
        } else {
            // otherwise check if the organisation is a 'Individual' (INDIVIDUAL_ORG_TYPE) type. Set isIndividual true if so.
            let orgReference = organisationsCollection.doc(orgId);
            orgReference.get().then(orgSnapshot => {
                if (orgSnapshot.get('type') == INDIVIDUAL_ORG_TYPE){
                    console.info(`Organisation is specified as type ${INDIVIDUAL_ORG_TYPE}. Cannot share workflows`);
                    context.commit('setIsIndividual', true);
                } else {
                    console.info("Regular Organisation. User can share workflows");
                    context.commit('setIsIndividual', false);
                }
            });
        }
    },
    async getOrgGroups({ commit, state, getters }, {force}) {
        if (state.orgGroupsLoading === true) {
            return new Promise((resolve, reject) => {
                commit('setOrgGroupsPromise', {resolve, reject})
            })
        }
        if(state.orgGroups === null || force === true) {
            try {
                commit('setOrgGroupsLoading', true)
                const groups = await functions.orgUserGroups({action: 'list', all: getters['isSuperAdmin']})
                commit('setOrgGroups', groups.data.userGroups)
                commit('resolveOrgGroupsPromise', {resolve: groups.data.userGroups})
                return groups.data.userGroups
            } catch (e) {
                commit('resolveOrgGroupsPromise', {reject: e})
            } finally {
                commit('setOrgGroupsLoading', false)
            }
        }else {
            return state.orgGroups
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};