/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["MODIFIER_BANDS_TITLE"] = "Filter Bands";
Blockly.Msg["MODIFIER_REMOVE_BANDS_TITLE"] = "Remove bands";

export default Blockly.Msg;