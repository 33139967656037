/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import Excel from "exceljs";
import { USER_CREATOR_ROLE} from "@/constants/appConstants";


async function openWorkbook(file) {
  return new Promise((resolve, reject) => {
    let workbook = new Excel.Workbook();
    
    let reader = new FileReader();
    reader.onload = () => {
      let buffer = reader.result;
      
      workbook.xlsx.load(buffer).then(workbook => {
        resolve(workbook);        
      }, reject);
    };

    reader.readAsArrayBuffer(file);
  });
}

const SpreadsheetService = {
  readUsersFromSpreadsheet: async (file) => {

    let workbook = await openWorkbook(file);
    let sheet = workbook.worksheets[0];

    let users = [];

    sheet.eachRow((row, rowIndex) => {     
      if (rowIndex > 1) {
        let values = row.values;

        let email = values[1] || '';
        let displayName = values[2] || '';
        let password = values[3] || '';
        let userType = values[4] || USER_CREATOR_ROLE;

        let roles = [userType];

        if (email != null && typeof email === 'object') {
          email = email.text;
        }

        if (email) {
          users.push({email, password, displayName, roles})
        }
      }       
    });

    return users;
  },
}

export {
  SpreadsheetService
}