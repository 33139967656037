/**
 * Defines state for pixel inspector.
 * 
 * Pixels are expected to contain:
 * uuid: string
 * runId: string
 * coords: [lat, lon]
 * scale: number
 * loading: boolean
 * layers: Array of layers, this is the response object also
 * 
 * Layers are expected to contain:
 * id: string
 * label: string
 * type: string
 * description: string
 * calculatedScale: number
 * crs: string
 * groups: array of the groups and their values
 * 
 * Groups are expected to contain:
 * label: string
 * bands: array of bands and their values
 * 
 * Bands are expected to contain:
 * name/label: string
 * value: number
 * color: string
 * class: string if it exists
 */

import { v4 as uuidv4 } from 'uuid';
import { functions } from '@/firebaseFunctions';

const dummy_data = {
    "uuid": "8fb21cee1ceb48d0a0c566d4bc43d0d3",
    "runId":"8fb21cee1ceb48d0a0c566d4bc43d0d3",
    "coords": [51.22, 0.32],
    "scale": 100,
    "loading": false,
    "layers": [
        {
            "id":0,
            "label": "Layer 1",
            "type": "ImageCollection",
            "description": "dasdsadsa",
            "calculatedScale": 97.5,
            "crs":"epsg:4326",
            "groups": [
                {
                    "label":"2020-01-01",
                    "bands": [
                        {
                            "name": "landcover",
                            "value": 3,
                            "class": "Built Up",
                            "color": "#00ff00"
                        }
                    ]
                },
                {
                    "label":"2021-01-01",
                    "bands": [
                        {
                            "name": "landcover",
                            "value": 3,
                            "class": "Built Up",
                            "color": "#00ff00"
                        }
                    ]
                }
            ]
        },
        {
            "id":1,
            "label":"True Color",
            "type": "ImageCollection",
            "description": "dasdsadsa",
            "calculatedScale": 97.5,
            "crs":"epsg:4326",
            "groups": [
                {
                    "label":"2023-01-01",
                    "bands": [
                        {
                            "name": "red",
                            "value": 3
                        },
                        {
                            "name": "green",
                            "value": 2
                        },
                        {
                            "name": "blue",
                            "value": 4
                        }
                    ]
                }
            ]
        }
    ]
}

// lookup list for ordering
const vis_bands_lookup = {
    'R': 0,
    'G': 1,
    'B': 2
}

const state = {
    pixels: []
}

const getters = {
    pixels: state => state.pixels,
    getLastPixel: state => state.pixels[state.pixels.length - 1],
    /**
     * Constructs a list of the last pixels colors, label, and value.
     * Label is constructed as layerName - band (group label)
     * Where band and group label are present if > 1 exists.
     * @param {*} state 
     */
    getLastPixelAsValues: function(state, getters) {
        if (getters.isLoading) {
            return [];
        }

        const pixel = getters.getLastPixel;
        if ([null, undefined].includes(pixel)) return [];

        const layers = pixel.layers;

        if (layers.length === 0) return [];

        const layerNames = layers.map(layer => layer.label);
        const layerGroups = layers.map(layer => layer.groups);

        let values = layerGroups.map((groups, index) => {
            return groups.map(group => {
                
                const bands = group.bands.map(band => {
                    return {
                        name: band.name,
                        value: band.value.toString(),
                        color: band.color || null,
                        class: band.class || null,
                        order: band.visualized_band ? vis_bands_lookup[band.visualized_band] : 0
                    }
                }).sort((a, b) => a.order - b.order);

                const groupLabel = group.label && groups.length > 1 ? ` - ${group.label}` : '';

                return {
                    layer: layerNames[index],
                    group: group.label,
                    label: layerNames[index] + groupLabel,
                    bands,
                    multiBand: bands.length > 1,
                    color: bands.every(band => band.color === bands[0].color) ? bands[0].color : null
                }
            })
        }).flat()

        return values;
    },
    isLoading: state => state.pixels.length ? state.pixels[state.pixels.length - 1].loading : false
}

const mutations = {
    addPixel: (state, pixel) => state.pixels.push(pixel),
    clearPixels: state => state.pixels = [],
    addDummyPixel_: state => state.pixels.push(dummy_data),
    updatePixel: (state, pixel) => {
        const index = state.pixels.findIndex(p => p.uuid === pixel.uuid);
        if (index !== -1) {
            state.pixels[index] = Object.assign({},pixel);
        } else {
            throw new Error('Pixel not found');
        }
    },
    updateLoading: (state, uuid, loading) => {
        const index = state.pixels.findIndex(p => p.uuid === uuid);
        if (index !== -1) {
            state.pixels[index].loading = loading;
        } else {
            throw new Error('Pixel not found');
        }
    
    }
}

const actions = {
    fetchPixel(context, { runId, latitude, longitude, scale }) {

        let pixel = {
            uuid: uuidv4(),
            runId,
            coords: [longitude, latitude], // flipped compared to google map, as function is expecting lon, lat
            scale,
            loading: true,
            layers: []
        }
        context.commit('addPixel', pixel);

        if (runId === null) {
            context.commit('updateLoading', pixel.uuid, false);
            return;
        }

        const reqScale = Math.round(pixel.scale);

        // get all the map layers from state
        const mapLayers = context.rootGetters['maplayers/getMapLayers'];

        let layerFilters = mapLayers
            .filter(layer => layer.originalType === 'ImageCollection')
            .map(layer => {
                // stringify the originalId
                const id = layer.originalId;
                const internalMapId = layer.id;

                if (!layer.isGroup) {
                    return {
                        id
                    }
                }

                const selectedLayer = context.rootGetters['maplayers/getSelectedLayer'][internalMapId];

                if (selectedLayer === null || selectedLayer === undefined) {
                    return {
                        id
                    }
                }

                return {
                    id,
                    indices: [selectedLayer]
                }
            })

        functions.get_pixel_value({
            run_id: pixel.runId,
            coords: pixel.coords,
            // nearest integer scale
            scale: reqScale < 1 ? 1 : reqScale,
            layers: layerFilters
        })
        .then(data => data.data)
        .then(data => {
            pixel.loading = false;
            pixel.layers = data.layers;
            context.commit('updatePixel', pixel);
        }).catch(err => {
            console.error(err);
            context.commit('updateLoading', pixel.uuid, false);
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}