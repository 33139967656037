/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["SELECT_ATTRIBUTE_BLOCK_TITLE"] = "Filter by attribute";
Blockly.Msg["ATTRIBUTE_LABEL"] = "Keep images with";
Blockly.Msg["ATTRIBUTE_FILTER_LABEL"] = "With filter";
Blockly.Msg["ATTRIBUTE_NAME_LABEL"] = "variable name";
Blockly.Msg["FILTER_VALUE_LABEL"] = "Value";

export default Blockly.Msg;