/**
 * Vue mixin for popups in maps.
 * expects google and map in state.
 */
import {AbstractPopup} from "@/components/ResultMap/CustomPopups/AbstractPopup.js";

export default {
    data() {
        return {
            mapPopups_: []
        }
    },
    methods: {
        /**
         * Given either a single popup or an array of popups, registers the popup(s) with the map.
         * @param {AbstractPopup} popup
         */
        registerPopup(popup) {
            if (Array.isArray(popup)) {
                popup.forEach(p => this.registerPopup(p));
            }

            if (!(popup instanceof AbstractPopup)) {
                throw new Error("Popup must be an instance of AbstractPopup");
            }

            popup.register(this.google, this.map);
            this.mapPopups_.push(popup);
        },
        hideAllPopups() {
            this.mapPopups_.forEach(popup => popup.hide());
        },
        unregisterAllPopups() {
            this.mapPopups_.forEach(popup => {
                popup.hide();
                popup.unregister();
            });

            this.mapPopups_ = [];
        }
    },
    computed: {
        mapEvent() {
            return this.$store.getters["mapevents/lastEvent"];
        }
    },
    watch: {
        mapEvent(event) {
            this.mapPopups_.forEach(popup => popup.handleMapEvent(event));
        }       
    }
}