<template>
    <md-dialog-prompt
        v-model:md-active="showJsonDialog"
        :md-click-outside-to-close="true"
        md-title="Load Workflow from JSON"
        v-model="pastedJson"
        md-confirm-text="Load"
        @md-cancel="closeDialog"
        @md-confirm="loadWorkflow"
    />
</template>

<script>
export default {
    name: "LoadJson",
    emits: [
        'load-json-workflow'
    ],
    data: function () {
        return {
            showJsonDialog: false,
            pastedJson: ""
        }
    },
    methods: {
        closeDialog() {
            this.showJsonDialog = false;
            this.pastedJson = "";
        },
        loadWorkflow() {
            this.$emit("load-json-workflow", this.pastedJson);
            this.closeDialog();
        },
        showDialog() {
            this.showJsonDialog = true;
        }
    }
};

</script>