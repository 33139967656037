export default {
    computed: {
        globalPackages() {
            return this.$store.state.packages.globalPackages;
        },
        globalProjects() {
            return this.$store.state.packages.globalProjects;
        },
        teamProjects() {
            return this.$store.state.packages.teamProjects;
        },
        gettingStartedPackages() {
            return this.$store.state.packages.gettingStartedPackages;
        },
        globalProjectsTags() { 
            return this.$store.state.packages.globalProjectsTags; 
        }
    },
    methods: {
        getPackageById(id) {
            return this.$store.getters['packages/getPackageById'](id);
        },
        async getGlobalPackages() {
            return this.$store.dispatch('packages/getGlobalPackages');
        },
        async getGlobalProjects() {
            return this.$store.dispatch('packages/getGlobalProjects');
        },
        async getTeamProjects(orgId) {
            return this.$store.dispatch('packages/getTeamProjects', orgId);
        },
        async getGlobalProjectsTags() { 
            return this.$store.dispatch('packages/getGlobalProjectsTags')
        }
    }
}