/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/**
 * @fileoverview Extension of Blockly's ZoomToFit control to allow for offsetting.
 * @author Jake Wilkins
 * An extension of Blockly's ZoomToFit control to allow for offsetting the 
 * position of the control due to the presence of the zoom controls.
 */

import { ZoomToFitControl } from "@blockly/zoom-to-fit";
// eslint disable is used because we're using the Blockly import for type hinting
// eslint-disable-next-line no-unused-vars
import Blockly from "blockly/core";

export class EBXZoomToFitControl extends ZoomToFitControl {
    /**
     * Class for zoom to fit control.
     * @param {!Blockly.WorkspaceSvg} workspace The workspace to sit in.
     * @param {Number} leftOffset The left offset for the control.
     * @param {Number} topOffset The top offset for the control.
     * @implements {Blockly.IPositionable}
     * @constructor
     */
    constructor(workspace, leftOffset, topOffset) {
        super(workspace);

        /**
         * Left offset for translation of zoom-to-fit-control.
         * @type {number}
         * @private
         */
        this.leftOffset_ = leftOffset ? leftOffset : 0;

        /**
         * Top offset for translation of zoom-to-fit-control.
         * @type {number}
         * @private
         */
        this.topOffset_ = topOffset ? topOffset : 50;
    }

    /**
     * Positions the zoom-to-fit control with the given offset.
     * It is positioned in the opposite corner to the corner the
     * categories/toolbox starts at.
     * @param {!Blockly.MetricsManager.UiMetrics} metrics The workspace metrics.
     * @param {!Array<!Blockly.utils.Rect>} savedPositions List of rectangles that
     *     are already on the workspace.
     * @override
     */
    position(metrics, savedPositions) {
        // run the parent function
        super.position(metrics, savedPositions);

        const leftTranslation = this.left_ + this.leftOffset_;
        const topTranslation = this.top_ + this.topOffset_;

        this.svgGroup_.setAttribute('transform',
            'translate(' + leftTranslation + ',' + topTranslation + ')');
    }

}