import * as Blockly from 'blockly/core';

import { ContentService } from '@/services/content.service';
import { AbstractBlock } from '@/blocks/_abstractBlock';

var saveDatasetJson = {
    "type": "output_save_dataset",
    "message0": "%1 %2 %3 %4",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "save_dataset_label",
            "text": "%{BKY_OUTPUT_SAVE_DATASET_LABEL}", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy",
        },
        {
            "type": "field_label_serializable",
            "name": "save_dataset_name_label",
            "text": "%{BKY_OUTPUT_SAVE_DATASET_NAME_LABEL}"
        },
        {
            "type": "field_input",
            "name": "dataset_name",
            "text": "Dataset 1",
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "mapLayer",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['output_save_dataset'] = {
    ...AbstractBlock,
    onInit: function() {
        this.jsonInit(saveDatasetJson);
        this.getField('dataset_name').setValidator(this.validateDatasetName)

        this.about_block_url = ContentService.getAboutBlockUrl('output_save_dataset')
    },
    validateDatasetName(newValue) {
        return newValue
    },
    accept: async function (visitor) {
        await visitor.visitOutputSaveDatasetBlock(this);
    },
}