<template>
    <div class="area-variable">
        <!-- explorer version -->
        <VariableDetailExplorer
            :variable="variable"
            :are-warnings="areWarnings"
            >
            <md-field v-if="areWarnings" class="md-datepicker disabled-date">
                <md-icon>event</md-icon>
                <md-input class="disabled-date-input" :disabled="areWarnings" type="date" ref="input" :value="variable.value"/>
            </md-field>
            <md-datepicker v-else :model-value="variable.value" :md-disabled-dates="handleDisabledDates" @update:modelValue="onDateChange" />
        </VariableDetailExplorer>
    </div>
</template>

<script>

import VariableDetailExplorer from '@/components/Variables/VariableDetail/VariableDetailExplorer.vue'
import moment from 'moment';
import { DateVariable } from '../../../variables/date';
import { VariablesService } from '@/services/variable.service';

export default {
    name: 'DateVariableExplorer',
    components: {
        VariableDetailExplorer
    },
    props: {
        variable: {
            type: Object,
            required: true
        },
        hasValue: {
            type: Boolean,
            required: false,
            default: false
        },
        areWarnings : { 
            required: false, 
            default: false
        }
    },
    computed: {
        variableId() {
            return this.variable.id;
        }
    },
    methods: { 
        onDateChange(date) { 
            const formattedDate = moment(date).format('YYYY-MM-DD').toString()
            this.$emit('save-variable', {value: formattedDate})
        }, 
        handleDisabledDates(date) {
            const variable = VariablesService.getVariableById(this.variableId)
            if(variable instanceof DateVariable) {
                return !variable.isDateSelectable(date)
            }
            return false
        }
    }
}
</script>