<template>
    <div class="calculator--save-container">
        <div class="type-container">
            <label class="md-title">Save the result as:</label>
            <div class="type-container--radio-btns">
                <md-radio v-model="typeRadio" value="band">A new band</md-radio>
                <md-radio v-model="typeRadio" value="dataset">A new dataset</md-radio>
            </div>
        </div>
        <div class="name-container">
            <label class="md-title">Name:</label>
            <md-field :class="messageClass">
                <md-input v-model="nameInput"></md-input>
                <span class="md-error" v-if="!isNameValid">{{ errorMessage }}</span>
            </md-field>
        </div>
    </div>
</template>

<script>
import { VALID_BANDNAME_REGEX,INVALID_BANDNAME_ERROR } from "@/constants/nextGenConstants";


export default {
    props: {
        type: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },
    emits: [
        'update-type',
        'update-name',
        'update-name-valid'
    ],
    computed: {
        typeRadio: {
            get() {
                return this.type
            },
            set(value) {
                this.$emit('update-type', value)
            }
        },
        nameInput: {
            get() {
                return this.name
            },
            set(value) {
                this.$emit('update-name', value)
            }
        },
        isNameValid() {
            if(this.nameInput.length == 0) {
                return true;
            }

            const test = VALID_BANDNAME_REGEX.test(this.nameInput);

            if (!test) {
                return false;
            } 

            return true;
        },
        errorMessage() {
            if (this.isNameValid) {
                return "";
            }

            return INVALID_BANDNAME_ERROR;
        },
        messageClass() {
            return {
                "md-invalid": !this.isNameValid
            }
        }
    },
    watch: {
        isNameValid(value) {
            this.$emit('update-name-valid', value)
        } 
    }
}
</script>