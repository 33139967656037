<template>
    <div @click="handleClickingColorPicker" class="ebx-color-picker" :class="themeClasses">
        <div class="ebx-color-picker--wrapper-outer">
            <div class="ebx-color-picker--wrapper" :style="{'backgroundColor':standarisedColor}">
                <input type="color" ref="colorPicker" v-model="standarisedColor" :id="id" :disabled="disabled" @blur="$emit('blur',$event)" @focus="$emit('focus',$event)"/>
            </div>
        </div>
        <div v-if="includeHash" class="ebx-color-picker--hash">{{ standarisedColor }}</div> 
    </div>
</template>
<script>
import valueMixin from '@/components/mixins/valueMixin'
export default {
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ''
        },
        includeHash: {
            type: Boolean,
            default: true
        },
        theme: {
            type: String,
            default: 'primary'
        }
    },
    mixins: [valueMixin],
    emits: [
        'update:modelValue',
        'blur',
        'focus'
    ],
    computed: {
        standarisedColor: {
            get() {
                if (this.value === undefined || this.value === null) {
                    return '#000000'
                }
                return this.value.length > 0 ? this.value[0] === '#' ? this.value: `#${this.value}` : '#000000'
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        themeClasses() {
            return {
                [`ebx-color-picker--${this.theme}`]: true,
                'disabled': this.disabled
            }
        }
    },
    methods: {
        handleClickingColorPicker() {
            if (this.disabled) {
                return
            }
            this.$refs.colorPicker.click()
        }
    }
}
</script>