<template>
    <div class="md-card-expand ebx-card-expand">
        <slot />
    </div>
</template>

<script>
import valueMixin from '@/components/mixins/valueMixin'

export default {
    name: 'EbxCardExpand',
    inject: ['MdCard'],
    mixins: [valueMixin],
    emits: [
        'update:modelValue'
    ],
    props: {
        modelValue: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        value(newVal) {
            this.MdCard.expand = newVal
        }
    },
    created() {
        this.MdCard.expand = this.value
    }
}
</script>