/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["OUTPUT_IMAGE_TITLE"] = "Export dataset";
Blockly.Msg["OUTPUT_IMAGE_NAME_LABEL"] = "Name";
Blockly.Msg["OUTPUT_IMAGE_TITLE_RASTER"] = "Export dataset (GeoTIFF)    ";
Blockly.Msg["OUTPUT_IMAGE_VECTOR_OPTIONS"] = "File type";
Blockly.Msg["OUTPUT_IMAGE_VECTOR_FILETYPE_CSV"] = "CSV";
Blockly.Msg["OUTPUT_IMAGE_VECTOR_FILETYPE_KML"] = "KML";
Blockly.Msg["OUTPUT_IMAGE_VECTOR_FILETYPE_KMZ"] = "KMZ";
Blockly.Msg["OUTPUT_IMAGE_VECTOR_FILETYPE_SHP"] = "SHP";
Blockly.Msg["OUTPUT_IMAGE_VECTOR_FILETYPE_TF"] = "TFRecord";
Blockly.Msg["OUTPUT_IMAGE_VECTOR_FILETYPE_GEOJSON"] = "GeoJSON";

export default Blockly.Msg;