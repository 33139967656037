/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'


Blockly.Msg["STUDY_AREA_BLOCK_TITLE"] = "Select Area of Interest";
Blockly.Msg["STUDY_AREA_BLOCK_LABEL"] = "Area of Interest: ";

export default Blockly.Msg;