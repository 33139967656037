/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

 

import { Subject } from 'rxjs';

let service = {
    infoDatasetId: new Subject(),
    commercialLicenseCaveat: new Subject(),
    indicatorInfo: new Subject
};

const ManageDialogsService = {
    infoDatasetIdDialog() {
        service.infoDatasetId.next();
    },
    commercialLicenseDialog() {
        service.commercialLicenseCaveat.next();
    },
    indicatorInfoDialog(content) {
        service.indicatorInfo.next(content);
    },
    infoDatasetId$: service.infoDatasetId.asObservable(),
    commercialLicenseCaveat$: service.commercialLicenseCaveat.asObservable(),
    indicatorInfo$: service.indicatorInfo.asObservable()
};

export {
    ManageDialogsService
}
