
import * as Blockly from 'blockly/core';
import {NO_DATA_DROPDOWN_VALUE} from '@/constants/nextGenConstants';
import {AbstractBlock, AbstractFieldHelpers} from '@/blocks/_abstractBlock';
import { ContentService } from '@/services/content.service';

const defaultSelectOptions = [["Select an option", NO_DATA_DROPDOWN_VALUE]]
const modifierRemoveBandsJson = {
    "type": "modifier_remove_bands",
    "message0": ` %1 %2 %3`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "cloud_title",
            "text": "%{BKY_MODIFIER_REMOVE_BANDS_TITLE} ",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_multi_dropdown",
            "name": "bands",
            "options": defaultSelectOptions
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "secondaryFilter",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['modifier_remove_bands'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    onInit: function() {
        this.jsonInit(modifierRemoveBandsJson);
        this.about_block_url = ContentService.getAboutBlockUrl('modifier_remove_bands')
    },
    accept: async function (visitor) {
        console.log('accept define visitor')
        await visitor.visitModifierRemoveBandsBlock(this);
    },
    updateShape_: function() {
        if(this.hasStateChanged('bands')) {
            this.updateOptionsIfSet('bands', this.getState('bands'))
        }
    },
    ebxValidate: function(tooltips) {
        const isRaster = this.getState('isRaster')
        const hasDefinition = this.getState('hasDefinition')
        this.setWarningText(null, "not_raster_dataset")
        if (!hasDefinition) {
            return
        } else if (hasDefinition && !isRaster) {
            this.setWarningText(tooltips['not_raster_dataset'] || "This dataset doesn't have bands. Use this block in workflows with a raster dataset input.", "not_raster_dataset")
        } else {
            this.setWarningText(null, "not_raster_dataset")
        }
    }
}