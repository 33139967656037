

export class RxEvents {
    constructor() {
        this.events = {}
        this.subscriptions = []
    }
    addEventListener(event, callback) {
        const unsubscribe = this.events[event].subscribe(callback);
        this.subscriptions.push({ event, unsubscribe, callback });
        return this
    }
    removeEventListerner(event, callback) {
        const subscription = this.subscriptions.find(sub => sub.event === event && sub.callback === callback);
        if (subscription) {
            subscription.unsubscribe();
        }
    }
    createEventSubject_(event) {
        return {
            next: (data) => {
                const subscriptions = this.subscriptions.filter(sub => sub.event === event);
                subscriptions.forEach(sub => sub.callback(data))
            },
            subscribe: (callback) => {
                return () => {
                    this.subscriptions = this.subscriptions.filter(sub => sub.event === event && sub.callback === callback)
                }
            }
        }
    }
}