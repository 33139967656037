<template>
    <div class="dataset-list">
        <EbxSearchBar 
        v-model="searchBar" 
        :filter-options="filterOptions" 
        @keydown="resetThreshold"></EbxSearchBar>
        <div v-if="hasChips" class="chip-container">
            <md-chip v-for="chip in chips" :key="chip.id" class="chips" md-clickable @click="selectedChip">{{ chip }}</md-chip>
        </div>
        <div class="dialog-content" :class="{'dialog-content--empty': filteredModalContent.length === 0}">
            <template v-if="filteredModalContent.length === 0">
                <div class="insert-dataset-modal--empty">
                    <div class="ebx-icon">
                        <img :src="assets.icons.emptyStateAssets" alt="No assets">
                    </div>
                    <p v-if="hasSearchText" class="ebx-primary">
                        {{ noSearchResultText || noDatasetsText }}
                    </p>
                    <p v-else-if="searchFilterBy === 'vector'" class="ebx-primary">
                        {{ noVectorResultText || noDatasetsText }}
                    </p>
                    <p v-else-if="searchFilterBy === 'image'" class="ebx-primary">
                        {{ noRasterResultText || noDatasetsText }}
                    </p>
                    <p v-else class="ebx-primary">
                        {{ noDatasetsText }}
                    </p>
                </div>
            </template>
            <template v-else>
                <div class="asset-card--item asset-card--item__action" v-for="content in filteredModalContent" :key="content.id" :class="itemClass(content)" @click="handleChoosingDataset(content)">
                    <div class="asset-card--item-body">
                        <div class="asset-card--item-title">
                            <div class="asset-card--thumbnail">
                                <template v-if="hasThumbnail(content.thumbnail)">
                                    <img :src="content.thumbnail" alt="thumbnail">
                                </template>
                                <template v-else>
                                    <img v-if="content.type==='vector'" :src="assets.icons.datasetVectorPlaceholder" alt="thumbnail">
                                    <img v-else :src="assets.icons.datasetImagePlaceholder" alt="thumbnail">
                                </template>
                            </div>
                            <div class="asset-card--item-title-description">
                                <div class="dataset-title">
                                    <p class="ebx-primary-bold">{{ content.title }}</p>
                                    <div v-if="content.isPaid" class="paid-tag">
                                        <p class="ebx-secondary">Paid access</p>
                                        <md-icon class="dataset-lock-icon">lock</md-icon>
                                    </div>
                                </div>
                                <p class="ebx-secondary">{{ content.short_description }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

import EbxSearchBar from '@/components/EbxComponents/EbxSearchBar.vue'
import { matchSorter } from 'match-sorter';
import authMixin from "@/components/mixins/authMixin"; 
import assetsMixin from "@/components/mixins/assetsMixin.js" 

export default {
    name: 'DatasetList',
    mixins: [authMixin, assetsMixin],
    components: {
        EbxSearchBar,
    },
    props: {
        datasets: {
            type: Array,
            required: true
        },
        chips: {
            type: Array,
            default() {
                return []
            }
        },
        filterBy: {
            type: String,
            default: 'all'
        },
        searchTerm: {
            type: String,
            default: ''
        },
        chosenDatasetId: {
            type: String,
            default: ''
        },
        noSearchResultText: {
            type: String,
            default: null
        },
        noVectorResultText: {
            type: String,
            default: null
        },
        noRasterResultText: {
            type: String,
            default: null
        },
        noDatasetsText: {
            type: String,
            default: 'We couldn\'t find any datasets for this search'
        },
        allowedGlobalDatasetIds: {
            type: Array,
            default: null
        }
    },
    emits: [
        'dataset-chosen',
        'search-bar-changed'
    ],
    data() {
        return {
            searchBar: {
                searchInput: this.searchTerm,
                filterBy: this.filterBy
            },
            threshold: matchSorter.rankings.MATCHES
        }
    },
    computed: {
        hasChips() {
            return this.chips.length > 0
        },
        searchInput() {
            return this.searchBar.searchInput
        },
        searchFilterBy() {
            return this.searchBar.filterBy
        },
        blockHasChildren() {
            return this.block.getChildren().length > 0
        },
        hasSearchText() {
            return this.searchInput !== "" && this.searchInput !== null
        },
        filterOptions() {
            return [
                {
                    value: 'all',
                    label: 'All datasets'
                },
                {
                    value: 'vector',
                    label: 'Feature collections'
                },
                {
                    value: 'raster',
                    label: 'Image collections'
                }
            ]
        },
        filteredModalContent() {
            let modalContent = []
            //Filter for deprecated/outdated datasets. 
            this.datasets.forEach(content => {
                // If org is forced to hide a dataset, don't show it
                if (this.datasetForcedHide(content) === false && content.hideFromList !== true) {
                    modalContent.push(content)
                }
            })
            //Further filter for commercial users to remove non commercial datasets
            if (this.isCommercialUser) {
                modalContent = modalContent.filter(content => {
                    // ignore isCommercialUse if the org has forced show
                    return content.isCommercialUse !== false || this.datasetForcedShow(content)
                })
            }
            
            let filteredModalContent = modalContent
            if (this.searchFilterBy !== 'all') {
                filteredModalContent = modalContent.filter(content => content.type === this.searchFilterBy)
            }

            if (this.hasSearchText) {
                filteredModalContent = matchSorter(filteredModalContent, this.searchInput, { keys: ['title', 'keywords','type'], threshold: this.threshold })
            }
            return filteredModalContent
        }
    },
    methods: {
        datasetForcedShow(dataset) {
            return this.orgGetVisibleDatasets[dataset.correlationId] === true || this.orgGetVisibleDatasets[dataset.id] === true
        },
        datasetForcedHide(dataset) {
            return this.orgGetVisibleDatasets[dataset.correlationId] === false  || this.orgGetVisibleDatasets[dataset.id] === false
        },
        resetThreshold() {
            this.threshold = matchSorter.rankings.MATCHES
        },
        selectedChip(event) {
            this.threshold = matchSorter.rankings.EQUAL
            this.searchBar.searchInput = event.target.innerText
        },
        hasThumbnail(url) {
            // regex check for url
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/g
            return regex.test(url)
        },
        handleChoosingDataset(dataset) {
            if(this.itemIsDisabled(dataset)){
                return
            }
            this.$emit('dataset-chosen', dataset)
        },
        itemIsDisabled(content) {
            return Array.isArray(this.allowedGlobalDatasetIds) && !this.allowedGlobalDatasetIds.includes(content.id)
        },
        itemClass(content) {
            return {
                selected: content.id === this.chosenDatasetId,
                selectedPaid: content.id === this.chosenDatasetId && content.isPaid,
                paid: content.isPaid,
                disabled: this.itemIsDisabled(content)
            }
        }
    },
    watch: {
        searchBar:{
            handler(value) {
                this.$emit('search-bar-changed', value)
            },
            deep: true
        }
    }

}
</script>
