
export default {
    computed: {
        organisationGroups() {
            return this.$store.getters['organisations/getOnlyOrgGroups'](this.user?.orgId)
        },
        organisationGroupsIds() {
            return this.organisationGroups.map(group => group.id)
        }
    },
    methods: {
        loadOrgGroups(forceCacheReload = false) {
            return this.$store.dispatch('organisations/getOrgGroups', {force: forceCacheReload})
        }
    }
}