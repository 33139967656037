<script>
  import { h } from 'vue'
  export default {
    name: 'MdCardExpandTrigger',
    inject: ['MdCard'],
    render () {
      const [trigger] = this.$slots.default()
      const staticClass = ' md-card-expand-trigger'
      let listeners = {
        onClick: () => {
          this.MdCard.expand = !this.MdCard.expand
        }
      }

      if (trigger) {
        return h(trigger,{
          ...trigger.ctx.props,
          ...listeners,
          class: trigger.ctx.props.class + staticClass
        })
      }

      return h('div', {
        class: staticClass,
        ...listeners
      })
    }
  }
</script>

<style lang="scss">
  @import "../../MdAnimation/variables";

  .md-card-expand-trigger.md-icon-button {
    transition: transform .4s $md-transition-stand-timing;
    will-change: transform;
  }
</style>
