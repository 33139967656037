/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import {EarthEngineMapType} from "./EarthEngineMapType";
import { AreaService } from "@/services/area.service";

/**
 * ShapeMapType is a custom image map tiling map, used to display a shape on the map, extening the EarthEngineMapType
 * It also has access to the drawing manager and area service to allow for recolouring of tiles
 */

export class ShapeMapType extends EarthEngineMapType {

    name = "Shape"
    alt = "Shape Tiling Basemap"
    shapeID;
    drawingManager;
    overlay;
    clickable = false;
    editable = false;

    // based on https://codepen.io/sosuke/pen/Pjoqqp
    // which we can consider implementing ourselves if we see a need for generating filters on the fly
    // these are filters to convert a perfectly black "#000" pixel to the required colour
    colourToFilterMap = {
        "#84da59": "filter: invert(74%) sepia(99%) saturate(290%) hue-rotate(45deg) brightness(91%) contrast(89%);",
        "#00965f": "filter: invert(38%) sepia(91%) saturate(671%) hue-rotate(117deg) brightness(92%) contrast(105%);",
        "#00Beaf": "filter: invert(48%) sepia(95%) saturate(1167%) hue-rotate(137deg) brightness(96%) contrast(101%);",
        "#49B8ff": "filter: invert(64%) sepia(15%) saturate(1734%) hue-rotate(164deg) brightness(97%) contrast(112%);",
        "#6D95cc": "filter: invert(69%) sepia(5%) saturate(4602%) hue-rotate(184deg) brightness(83%) contrast(90%);",
        "#001958": "filter: invert(8%) sepia(73%) saturate(2958%) hue-rotate(216deg) brightness(100%) contrast(108%);",
        "#370850": "filter: invert(7%) sepia(55%) saturate(7401%) hue-rotate(278deg) brightness(74%) contrast(100%);",
        "#8C0d70": "filter: invert(11%) sepia(48%) saturate(7069%) hue-rotate(302deg) brightness(92%) contrast(98%);",
        "#d256a0": "filter: invert(75%) sepia(72%) saturate(5060%) hue-rotate(292deg) brightness(87%) contrast(88%);",
        "#ff72a4": "filter: invert(80%) sepia(41%) saturate(6602%) hue-rotate(302deg) brightness(105%) contrast(107%);",
        "#ef4748": "filter: invert(29%) sepia(75%) saturate(1513%) hue-rotate(332deg) brightness(109%) contrast(89%);",
        "#ff7132": "filter: invert(56%) sepia(20%) saturate(5895%) hue-rotate(339deg) brightness(104%) contrast(101%);",
        "#f7ec39": "filter: invert(91%) sepia(81%) saturate(638%) hue-rotate(349deg) brightness(99%) contrast(95%);",
        "#d8e25b": "filter: invert(76%) sepia(50%) saturate(446%) hue-rotate(20deg) brightness(103%) contrast(96%);"
    }
    colour = "#84da59";

    /**
     * creates the ImageDom element and adds an event listener for error handling and retries
     * Also adds a listener to the map drawing manager for click events
     * @param {String} mapURL - the map tiling url
     * @param {String} shapeID - the id of the shape to be drawn
     * @param {String} areaId - the id of the area to be drawn
     * @param {GoogleMapObject} map - the google map which this overlay is getting added to
     * @param {GoogleMaps} google - the google maps api
     */
    constructor(mapURL, shapeID, map, google, colour=null) {
        super(mapURL, map, google);
        this.shapeID = shapeID;
        this.id = shapeID
        this.removeHandler = () => {}
        this.overlay = this;
        this.visible = true;
        if(colour) {
            this.colour = colour;
        }
    }

    getTile(coord, zoom) {
        let image = super.getTile(coord, zoom);

        if(this.visible) {
            // set image style to be through filter so colour matches area it's assigned to
            if(this.colour in this.colourToFilterMap) {
                image.style = this.colourToFilterMap[this.colour];
            }
        }else {
            image.style = `opacity: 0;`
        }

        return image;
    }

    setRemoveHandler(handler) {
        this.removeHandler = handler;
    }

    /**
     * Removes overlay from map
     */
    remove() {
        this.removeHandler(this)
    }

    /**
     * sets colour of overlay
     * @param {String} colour - the colour to be set
     */
    setColour(colour) {
        console.log("setting colour", colour)
        this.colour = colour;

        let index = this.map.overlayMapTypes.indexOf(this.overlay)
        if(index > -1) {
            this.map.overlayMapTypes.setAt(index, this);
        }
    }

    /**
     * Sets the visiablity of the layer
     * @param {*} opacity 
     * @returns 
     */
    setVisible(visible) {
        this.visible = visible===true;
        let index = this.map.overlayMapTypes.indexOf(this.overlay)
        if(index > -1) {
            this.map.overlayMapTypes.setAt(index, this);
        }
        return this
    }

    /**
     * Called when the overlay is clicked
     * Finds the drawing managers currently selected tool and acts accordingly
     */
    overlayClicked() {
        let drawingMode = this.drawingManager.getDrawingMode();
        if(drawingMode === "delete") {
            this.drawingManager.setDrawingMode(null);
            this.remove();
            AreaService.removeShapeById(this.shapeID);
        } else if (drawingMode === "assign") {
            // TODO change colour of shape
            let newArea = AreaService.getSelectedArea();
            if(!newArea.id || !newArea.colour) {
                return;
            }
            AreaService.updateAreaIdOfShape(this.shapeID, newArea.id, this.overlay);
            this.setColour(newArea.colour);
        }
    }

    setEditable() {
        //noop intended
        //this.editable = editable;
        return this
    }
    setClickable(clickable) {
        this.clickable = clickable;
        return this
    }

    setOptions(options) {
        if(typeof options === 'object') {
            if(options.colour) {
                this.setColour(options.colour);
            }
            if(options.visible) {
                this.setVisible(options.visible);
            }
            if(options.editable) {
                this.setEditable(options.editable);
            }
            if(options.clickable) {
                this.setClickable(options.clickable);
            }
        }
        return this
    }
}