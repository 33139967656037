<template>
    <div v-if="user">
        <md-tabs md-sync-route>
            <md-tab 
                id="tab-users" 
                md-label="Users" 
                to="/admin/users" 
                exact
            >
                
            </md-tab>

            <!-- <md-tab 
                v-if="isSuperAdmin || isCustomerSupport || isAdmin"
                id="tab-org-groups" 
                md-label="Groups" 
                to="/admin/organisations/groups" 
                exact
            >
      
            </md-tab> -->

            <!-- <md-tab
                id="tab-features"
                md-label="Organisation features"
                to="/admin/features"
            >
                <router-view></router-view>
            </md-tab> -->

            <md-tab
                v-if="isSuperAdmin || isCustomerSupport"
                id="tab-organisations"
                md-label="Organisations"
                to="/admin/organisations"
            >
            </md-tab>

            <md-tab
                v-if="isSuperAdmin || isCustomerSupport"
                id="tab-debug"
                md-label="Debugger"
                to="/admin/debugger"
                >
            </md-tab>
        </md-tabs>
        <div class="my-4 mx-4">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import authMixin from '@/components/mixins/authMixin.js';
export default {
    name: "Admin",
    mixins: [authMixin]
};
</script>