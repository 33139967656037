/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/* eslint-disable no-unused-vars */
import {
  CONFIG_DISABLED_MODIFIER,
  BLOCKLY_DISABLED_ATTRIBUTE,
  BLOCKLY_CATEGORY_STYLE_ATTRIBUTE,
  CATEGORY_STYLE_DISABLED,
  TOOLBOX_ELEMENT_TYPE,
  CATEGORY_STYLE_SUFFIX,
  DISABLED_STYLE_SUFFIX
} from "./constants.js";
import { ConfigService } from "../services/config.service";
import { sumBy }from 'lodash';
import { default as et } from 'elementtree';

/**
 * Apply a JSON toolbox config to the base XML config, and return the result,
 * with features removed or disabled as appropriate.
 * Both categories and blocks can be enabled/disabled and shown/hidden, as can toolbox buttons.
 *
 * For example, apply a JSON config:
 * {
 *   "input.imagery.disabled": true,
 *   "input.terrain": false,
 *   "input.anthropogenic.nightlights": false
 * }
 *
 * to an XML base config:
 * <xml>
 *   <category name="Input" toolboxitemid="input" categorystyle="input_category">
 *     <category name="Imagery" toolboxitemid="input.imagery" categorystyle="input_category">
 *       <block type="data4"></block>
 *       <block type="radar"></block>
 *     </category>
 *     <category name="Terrain" toolboxitemid="input.terrain" categorystyle="input_category">
 *        <block type="dem"></block>
 *     </category>
 *     <category name="Anthropogenic" toolboxitemid="input.anthropogenic" categorystyle="input_category">
 *        <block type="anthropogenic"></block>
 *        <block type="nightlights" hidden="true"></block>
 *     </category>
 *   </category>
 * </xml>
 *
 * to produce:
 * <xml>
 *   <category name="Input"  toolboxitemid="input" categorystyle="input_category">
 *     <category name="Imagery" toolboxitemid="input.imagery" categorystyle="disabled_category">
 *       <block type="data4" disabled="true"></block>
 *       <block type="radar" disabled="true"></block>
 *     </category>
 *     <category name="Anthropogenic" toolboxitemid="input.anthropogenic" categorystyle="input_category">
 *        <block type="anthropogenic"></block>
 *        <block type="nightlights"></block>
 *     </category>
 *   </category>
 * </xml>
 *
 * Note that the blocks get a 'disabled' attribute, and the categories get a disabled style, defined in the blockly theme.
 *
 * If a category is disabled or enabled, so are all its descendant categories, blocks and buttons.
 * Configuration is ordered before processing, to try and process entries in descending order of specificity.
 * For example, disable a high level category before enabling blocks or categories within it.
 *
 * See https://github.com/racker/node-elementtree
 *
 * @param {*} jsonConfig a JSON configuration listing
 * @returns
 */
export function applyToToolboxXml(jsonConfig) {
    // Get the base XML configuration
    var toolboxXml = ConfigService.getBaseToolboxXml();
    if (!toolboxXml) {
        console.error(`There is no base XML toolbox configuration`);
        return "";
    }

    // Use elementtree to parse and modify the toolbox XML
    let toolboxTree = et.parse(toolboxXml);

    // Iterate the JSON config, after ordering the features by descending specificity (based on the number of dots in the feature key).
    // This is to try and provide a sensible hierarchy of configurations, which can be applied in order.
    // For example, disable a high level category before enabling blocks or categories within it.
    for (let feature of Object.keys(jsonConfig).sort(increasingToolboxSpecificity)) {
        let switchValue = jsonConfig[feature];
        let disable, show;
        if (feature.endsWith(CONFIG_DISABLED_MODIFIER)) {
            // If this is a '.disabled' config, we need to record that and strip off the modifier
            disable = switchValue;
            feature = feature.substring(0, feature.length - CONFIG_DISABLED_MODIFIER.length);
        } else {
            show = switchValue;
        }

        // Make xpaths from the feature key - in preferred order of search.
        // e.g. key input.imagery => ./category[@toolboxitemid='input.imagery']
        // e.g. block key input.anthropogenic.nightlights => ./category[@toolboxitemid='input.anthropogenic']/block[@type='nightlights']
        // (NOTE the use of a Map, which iterates in the order of entry insertion, and uses .set() and .set())
        let xpaths = new Map();
        xpaths.set(TOOLBOX_ELEMENT_TYPE.CATEGORY, `.//category[@toolboxitemid='${feature}']`);
        xpaths.set(TOOLBOX_ELEMENT_TYPE.BUTTON, `.//toolboxbutton[@name='${feature}']`);
        // If the string has dot separators it may be a block reference
        if (feature.indexOf(".") > -1) {
            let tokens = feature.split("."),
                categoryId = tokens.slice(0, tokens.length-1).join("."),
                blockType = tokens.slice(-1);
            xpaths.set(TOOLBOX_ELEMENT_TYPE.BLOCK, `.//category[@toolboxitemid='${categoryId}']/block[@type='${blockType}']`);
        }

        // Find the feature in the tree - try each type in the order they were inserted into the map
        let xmlFeature, featureType;
        for (let [elType, xpath] of xpaths.entries()) {
            // console.debug("Checking "+xpath);
            xmlFeature = toolboxTree.find(xpath);
            // Continue iterating if nothing found
            if (!xmlFeature) continue;
            // console.debug(`Found ${xmlFeature.attrib["name"]}`)
            // Feature found - set type and jump out of iteration
            featureType = elType;
            break;
        }

        if (!xmlFeature) {
            // If the feature was not found in the XML, continue iteration of JSON config
            console.warn(`Could not modify toolbox feature ${feature} as it was not found in the XML spec. It may have already been disabled at a higher level.`);
            continue;
        // If a 'disable' property is defined, apply it
        } else if (typeof(disable) !== 'undefined') {
            console.info(`${disable?'Disabling':'Enabling'} ${featureType} ${feature}`);
            xmlFeature.attrib["disabled"] = disable;
            if (featureType === TOOLBOX_ELEMENT_TYPE.CATEGORY) {
                // Modify the categorystyle on the category
                _updateToolboxCategory(xmlFeature, disable);
            }
            // If enabling an element, ensure its ancestor categories are enabled too
            if (!disable) {
                _enableAncestorCategories(toolboxTree, xpaths.get(featureType));
            }
        // If a 'show' property is defined, apply it
        } else if (typeof(show) !== 'undefined') {
            console.debug(`${show?'Showing':'Hiding'} ${featureType} ${feature}`);
            xmlFeature.attrib["hidden"] = !show;
            if (!show) {
                // Remove the feature from its parent
                console.debug(`Removing ${feature} element from toolbox XML tree`);
                toolboxTree.find(xpaths.get(featureType)+"/..").remove(xmlFeature);
            }
        }
    }

    // If there are any elements which are "hidden", remove them
    let path = "./*[@hidden='true']";
    toolboxTree.findall(path).forEach(feature => {
        console.debug(`Removing ${feature.attrib['name'] || feature.attrib['type']} element from toolbox XML tree`);
        toolboxTree.find(path+"/..").remove(feature);
    });

    // Return a string with the modified XML
    let xml = toolboxTree.write({'xml_declaration': false});
    return xml;
}

/**
 * Enable category ancestors that contain an enabled block/category, to ensure
 * that the categories back up to the the top of the hierarchy are also enabled.
 * The function recurses until there are no more parent categories.
 */
function _enableAncestorCategories(toolboxTree, matchPath) {
    matchPath += `/..[@${BLOCKLY_CATEGORY_STYLE_ATTRIBUTE}]`;
    let parentCat = toolboxTree.find(matchPath);
    if (parentCat) {
        console.debug(`Enabling ancestor category ${parentCat.attrib['toolboxitemid']}`);
        // Enable ancestor categories without then enabling their descendants!
        _updateToolboxCategory(parentCat, false, false);
        // Recurse until there are no more ancestor categories
        _enableAncestorCategories(toolboxTree, matchPath);
    } else return;
}

/**
 * Construct a category style for an enabled category element. This should be e.g. "input_category" for
 * any category contained in an input category. The style can be constructed by taking the first component
 * of the toolboxitemid and appending "_category" - e.g category "input.imagery" gets "input_category".
 * If this doesn't work, return the existing attribute as default.
 * This function relies on a consistent naming scheme for categories.
 */
function _constructCategoryStyle(categoryFeature) {
    try {
        return categoryFeature.attrib["toolboxitemid"].split(".", 1)[0] + CATEGORY_STYLE_SUFFIX;
    } catch {
        return categoryFeature.attrib[BLOCKLY_CATEGORY_STYLE_ATTRIBUTE];
    }
}

/**
 * Modify a category style to either disable or enable it.
 * Works by appending or removing the `DISABLED_STYLE_SUFFIX` 
 * to the `BLOCKLY_CATEGORY_STYLE_ATTRIBUTE` attribute, but
 * will not affect a style string that already has the correct value.
 *
 * @param {*} categoryFeature a category element
 * @param {boolean} [disable=true] whether to disable the category
 */
function _modifyCategoryStyleDisabledness(categoryFeature, disable=true) {
    let catStyle = categoryFeature.attrib[BLOCKLY_CATEGORY_STYLE_ATTRIBUTE];
    if (disable) {
        catStyle = catStyle.endsWith(DISABLED_STYLE_SUFFIX) ? catStyle : catStyle+DISABLED_STYLE_SUFFIX;
    } else {
        catStyle = catStyle.endsWith(DISABLED_STYLE_SUFFIX) ? catStyle.slice(0, -DISABLED_STYLE_SUFFIX.length) : catStyle;
    }
    console.debug(`Setting ${categoryFeature.attrib["toolboxitemid"]} style to ${catStyle}`);
    categoryFeature.attrib[BLOCKLY_CATEGORY_STYLE_ATTRIBUTE] = catStyle;
}

/**
 * Function to update a category element in a toolbox XML structure parsed by ElementTree.
 * Set the category disabled attribute, along with a matching style attribute, and then
 * update descendants if necessary.
 * @param {*} categoryFeature an element returned by an XPath on the ElememtTree structure
 * @param {*} disable whether to disable or enable the feature
 * @param {*} updateDescendants whether to enable/disable all descendants of the category (default true)
 */
function _updateToolboxCategory(categoryFeature, disable, updateDescendants=true) {
    console.info(`Modifying toolbox category ${categoryFeature.attrib["toolboxitemid"]}, disable ${disable}`);
    categoryFeature.attrib[BLOCKLY_DISABLED_ATTRIBUTE] = disable;
    _modifyCategoryStyleDisabledness(categoryFeature, disable);
    
    // Modify all the category, block or button descendants under a category
    if (updateDescendants) {
        categoryFeature.findall('.//block').forEach(block => block.attrib[BLOCKLY_DISABLED_ATTRIBUTE] = disable);
        categoryFeature.findall('.//toolboxbutton').forEach(button => button.attrib[BLOCKLY_DISABLED_ATTRIBUTE] = disable);
        categoryFeature.findall('.//category').forEach(cat => {
            cat.attrib[BLOCKLY_DISABLED_ATTRIBUTE] = disable;
            _modifyCategoryStyleDisabledness(cat, disable);
        });
    }
}

/**
 * A function to count the number of dots in a string.
 */
 function numdots(str) {
    return sumBy(str, c => c===".");
}

/**
 * Sort function to sort strings in increasing order of number of dots.
 */
function increasingToolboxSpecificity(a, b) {
    return numdots(a) - numdots(b);
}
