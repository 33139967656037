import MdReactive from './core/utils/MdReactive'
import MdTheme from './core/MdTheme'
// Import Vue3-Material Components
import MdApp from './MdApp/MdApp.vue'
import MdAppToolbar from './MdApp/MdAppToolbar.vue'
import MdAppContent from './MdApp/MdAppContent.vue'
import MdToolbar from './MdToolbar/MdToolbar.vue'
import MdButton from './MdButton/MdButton.vue'
import MdMenu from './MdMenu/MdMenu.vue'
import MdMenuItem from './MdMenu/MdMenuItem.vue'
import MdMenuContent from './MdMenu/MdMenuContent.vue'
import MdIcon from './MdIcon/MdIcon.vue'
import MdContent from './MdContent/MdContent.vue'
import MdList from './MdList/MdList.vue'
import MdListItem from './MdList/MdListItem/MdListItem.vue'
import MdListItemContent from './MdList/MdListItem/MdListItemContent.vue'
import MdListItemButton from './MdList/MdListItem/MdListItemButton.vue'
import MdListItemDefault from './MdList/MdListItem/MdListItemDefault.vue'
import MdListItemExpand from './MdList/MdListItem/MdListItemExpand.vue'
import MdListItemFakeButton from './MdList/MdListItem/MdListItemFakeButton.vue'
import MdListItemLink from './MdList/MdListItem/MdListItemLink.vue'
import MdListItemRouter from './MdList/MdListItem/MdListItemRouter.vue'
import MdAvatar from './MdAvatar/MdAvatar.vue'
import MdField from './MdField/MdField.vue'
import MdInput from './MdField/MdInput/MdInput.vue'
import MdTextarea from './MdField/MdTextarea/MdTextarea.vue'
import MdSelect from './MdField/MdSelect/MdSelect.vue'
import MdOption from './MdField/MdSelect/MdOption.vue'
import MdOptgroup from './MdField/MdSelect/MdOptgroup.vue'
import MdFile from './MdField/MdFile/MdFile.vue'
// import MdLayout from './MdLayout'
import MdSnackbar from './MdSnackbar/MdSnackbar.vue'
import MdTabs from './MdTabs/MdTabs.vue'
import MdTab from './MdTabs/MdTab.vue'
import MdDialog from './MdDialog/MdDialog.vue'
import MdDialogAlert from './MdDialog/MdDialogAlert/MdDialogAlert.vue'
import MdDialogPrompt from './MdDialog/MdDialogPrompt/MdDialogPrompt.vue'
import MdDialogConfirm from './MdDialog/MdDialogConfirm/MdDialogConfirm.vue'
import MdDialogActions from './MdDialog/MdDialogActions.vue'
import MdDialogContent from './MdDialog/MdDialogContent.vue'
import MdDialogTitle from './MdDialog/MdDialogTitle.vue'
//import MdDrawer from './MdDrawer/MdDrawer.vue'
import MdChips from './MdChips/MdChips.vue'
import MdChip from './MdChips/MdChip.vue'
import MdCheckbox from './MdCheckbox/MdCheckbox.vue'
import MdCard from './MdCard/MdCard.vue'
import MdCardActions from './MdCard/MdCardActions/MdCardActions.vue'
import MdCardArea from './MdCard/MdCardArea/MdCardArea.vue'
import MdCardExpandContent from './MdCard/MdCardExpand/MdCardExpandContent.vue'
import MdCardExpandTrigger from './MdCard/MdCardExpand/MdCardExpandTrigger.vue'
import MdSubheader from './MdSubheader/MdSubheader.vue'
import MdTooltip from './MdTooltip/MdTooltip.vue'
import MdTable from './MdTable/MdTable.vue'
import MdTableAlternateHeader from './MdTable/MdTableAlternateHeader.vue'
import MdTableCell from './MdTable/MdTableCell.vue'
import MdTableCellSelection from './MdTable/MdTableCellSelection.vue'
import MdTableHead from './MdTable/MdTableHead.vue'
import MdTableHeadSelection from './MdTable/MdTableHeadSelection.vue'
import MdTableRow from './MdTable/MdTableRow.vue'
import MdTableRowGhost from './MdTable/MdTableRowGhost.vue'
import MdTableThead from './MdTable/MdTableThead.vue'
import MdTableToolbar from './MdTable/MdTableToolbar.vue'
import MdTableEmptyState from './MdTable/MdTableEmptyState.vue'
import MdRadio from './MdRadio/MdRadio.vue'
import MdRipple from './MdRipple/MdRipple.vue'
import MdAutocomplete from './MdAutocomplete/MdAutocomplete.vue'
import MdProgressBar from './MdProgress/MdProgressBar.vue'
import MdProgressSpinner from './MdProgress/MdProgressSpinner.vue'
import MdSwitch from './MdSwitch/MdSwitch.vue'
import MdDivider from './MdDivider/MdDivider.vue'
import MdBadge from './MdBadge/MdBadge.vue'
import MdEmptyState from './MdEmptyState/MdEmptyState.vue'
import MdDatepicker from './MdDatepicker/MdDatepicker.vue'
import MdCardHeader from './MdCard/MdCardHeader/MdCardHeader.vue'
import MdCardContent from './MdCard/MdCardContent/MdCardContent.vue'
import MdCardExpand from './MdCard/MdCardExpand/MdCardExpand.vue'
// EO ImportVue3-Material Components

const init = () => {
    let material = new MdReactive({
        ripple: true,
        theming: {},
        locale: {
            startYear: 1900,
            endYear: 2099,
            dateFormat: 'yyyy-MM-dd',
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            shorterDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            shorterMonths: ['J', 'F', 'M', 'A', 'M', 'Ju', 'Ju', 'A', 'Se', 'O', 'N', 'D'],
            firstDayOfAWeek: 0,
            cancel: 'Cancel',
            confirm: 'Ok'
        },
        router: {
            linkActiveClass: 'router-link-active'
        }
    })

    Object.defineProperties(material.theming, {
        metaColors: {
            get: () => MdTheme.metaColors,
            set (metaColors) {
                MdTheme.metaColors = metaColors
            }
        },
        theme: {
            get: () => MdTheme.theme,
            set (theme) {
                MdTheme.theme = theme
            }
        },
        enabled: {
            get: () => MdTheme.enabled,
            set (enabled) {
                MdTheme.enabled = enabled
            }
        }
    })

    return material
}

const VueMaterialPlugin = {
    install(app) { 
        app.config.globalProperties.$material = init();
        app.component('md-app', MdApp);
        app.component('md-toolbar', MdToolbar);
        app.component('md-button', MdButton);
        app.component('md-menu', MdMenu);
        app.component('md-menu-item', MdMenuItem);
        app.component('md-menu-content', MdMenuContent);
        app.component('md-icon', MdIcon);
        app.component('md-content', MdContent);
        app.component('md-list', MdList);
        app.component('md-list-item', MdListItem);
        app.component('md-list-item-content', MdListItemContent);
        app.component('md-list-item-button', MdListItemButton);
        app.component('md-list-item-default', MdListItemDefault);
        app.component('md-list-item-expand', MdListItemExpand);
        app.component('md-list-item-fake-button', MdListItemFakeButton);
        app.component('md-list-item-link', MdListItemLink);
        app.component('md-list-item-router', MdListItemRouter);
        app.component('md-avatar', MdAvatar);
        app.component('md-field', MdField);
        app.component('md-input', MdInput);
        app.component('md-textarea', MdTextarea);
        app.component('md-select', MdSelect);
        app.component('md-option', MdOption);
        app.component('md-optgroup', MdOptgroup);
        app.component('md-file', MdFile);
        app.component('md-snackbar', MdSnackbar);
        app.component('md-tabs', MdTabs);
        app.component('md-tab', MdTab);
        app.component('md-dialog', MdDialog);
        app.component('md-dialog-alert', MdDialogAlert);
        app.component('md-dialog-prompt', MdDialogPrompt);
        app.component('md-dialog-title', MdDialogTitle)
        app.component('md-dialog-confirm', MdDialogConfirm);
        // app.component('md-drawer', MdDrawer);
        app.component('md-chips', MdChips);
        app.component('md-chip', MdChip);
        app.component('md-checkbox', MdCheckbox);
        app.component('md-card', MdCard);
        app.component('md-card-header', MdCardHeader);
        app.component('md-card-content', MdCardContent);
        app.component('md-card-expand', MdCardExpand);
        app.component('md-card-actions', MdCardActions);
        app.component('md-card-area', MdCardArea);
        app.component('md-card-expand-content', MdCardExpandContent);
        app.component('md-card-expand-trigger', MdCardExpandTrigger);
        app.component('md-subheader', MdSubheader);
        app.component('md-tooltip', MdTooltip);
        app.component('md-table', MdTable);
        app.component('md-table-alternate-header', MdTableAlternateHeader);
        app.component('md-table-cell', MdTableCell);
        app.component('md-table-cell-selection', MdTableCellSelection);
        app.component('md-table-head', MdTableHead);
        app.component('md-table-head-selection', MdTableHeadSelection);
        app.component('md-table-row', MdTableRow);
        app.component('md-table-row-ghost', MdTableRowGhost);
        app.component('md-table-thead', MdTableThead);
        app.component('md-table-toolbar', MdTableToolbar);
        app.component('md-table-empty-state', MdTableEmptyState);
        app.component('md-radio', MdRadio);
        app.component('md-ripple', MdRipple);
        app.component('md-autocomplete', MdAutocomplete);
        app.component('md-progress-bar', MdProgressBar);
        app.component('md-progress-spinner', MdProgressSpinner);
        app.component('md-switch', MdSwitch);
        app.component('md-divider', MdDivider);
        app.component('md-badge', MdBadge);
        app.component('md-empty-state', MdEmptyState);
        app.component('md-datepicker', MdDatepicker);
        app.component('md-dialog-actions', MdDialogActions);
        app.component('md-dialog-content', MdDialogContent);
        app.component('md-app-toolbar', MdAppToolbar);
        app.component('md-app-content', MdAppContent);
    }
};

export { VueMaterialPlugin };