<template>
  <md-dialog v-bind="$attrs" :md-fullscreen="false" @md-opened="setInputFocus">
    <md-dialog-title v-if="mdTitle">{{ mdTitle }}</md-dialog-title>
    <md-dialog-content v-if="mdContent" v-html="mdContent" />

    <md-dialog-content>
      <md-field>
        <md-input
          ref="input"
          v-model="inputValue"
          :id="mdInputId"
          :name="mdInputName"
          :maxlength="mdInputMaxlength"
          :placeholder="mdInputPlaceholder"
          @keydown.enter="onConfirm" />
      </md-field>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button class="md-primary" @click="onCancel">{{ mdCancelText }}</md-button>
      <md-button class="md-primary" @click="onConfirm">{{ mdConfirmText }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
	import MdButton from "../../MdButton/MdButton.vue"
	import MdDialog from "../MdDialog.vue"
	import MdDialogContent from "../MdDialogContent.vue"
	import MdDialogActions from "../MdDialogActions.vue"
	import MdDialogTitle from "../MdDialogTitle.vue"
  import MdField from "../../MdField/MdField.vue"
  import MdInput from "../../MdField/MdInput/MdInput.vue"

  export default {
    name: 'MdDialogPrompt',
		emits:['update:modelValue','md-cancel','update:mdActive','md-confirm'],
    components: {
      MdButton,
      MdDialogActions,
      MdDialogTitle,
      MdDialog,
      MdDialogContent,
      MdField,
      MdInput
    },
    props: {
      value: {},
      mdTitle: String,
      mdInputName: String,
      mdInputId: String,
      mdInputMaxlength: [String, Number],
      mdInputPlaceholder: [String, Number],
      mdContent: String,
      mdConfirmText: {
        type: String,
        default: 'Ok'
      },
      mdCancelText: {
        type: String,
        default: 'Cancel'
      }
    },
    data: () => ({
      inputValue: null
    }),
    watch: {
      value () {
        this.inputValue = this.value
      }
    },
    methods: {
      onCancel () {
        this.$emit('md-cancel')
        this.$emit('update:mdActive', false)
      },
      onConfirm () {
        this.$emit('update:modelValue', this.inputValue)
        this.$emit('md-confirm', this.inputValue)
        this.$emit('update:mdActive', false)
      },
      setInputFocus () {
        window.setTimeout(() => {
          this.$refs.input.$el.focus()
        }, 50)
      }
    },
    created () {
      this.inputValue = this.value
    }
  }
</script>
