<template>
    <div class="ebx-folder-card" @click="$emit('goToPage')" >
        <div class="ebx-folder-card--container">
            <div class="ebx-folder-card--content">
                <div class="ebx-folder-card--title">
                    <p class="ebx-primary-bold">{{details.title}}</p>
                </div>
                <!-- <div class="ebx-folder-card--description">
                    <p class="ebx-secondary">
                        {{details.description}}
                    </p>
                </div> -->
            </div>
            <div class="ebx-folder-card--footer" ref="cardFooter">
                <div class="ebx-folder-card--images">
                    <img v-for="(image, index) in thumbnails" :key="index" :src="image" alt="thumbnail">
                </div>
                <div v-if="overspillCount" class="ebx-folder-card--overspill">
                    <p class="ebx-primary-bold">+{{overspillCount}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import assets from '@/assets.js'

export default {
    name: 'EbxFolderCard',
    props: {
        details: {
            type: Object,
            required: false,
            default: function() {
                return {
                    title: 'Package title',
                    description: 'Description',
                    images: [
                        assets.icons.datasetImagePlaceholder,
                        assets.icons.datasetImagePlaceholder,
                        assets.icons.datasetImagePlaceholder,
                        assets.icons.datasetImagePlaceholder,
                        assets.icons.datasetImagePlaceholder,
                        assets.icons.datasetImagePlaceholder,
                    ]
                }
            }
        }
    },
    emits: [
        'goToPage'
    ],
    data() {
        return {
            screenWidth: window.innerWidth,
            fitsNThumbnails: 0
        }
    },
    watch: {
        screenWidth() {
            this.setThumbnailCount();
        }
    },
    computed: {
        overspillCount() {
            if (this.details && this.details.images && this.fitsNThumbnails < this.details.images.length) {
                return this.details.images.length + 1 - this.fitsNThumbnails;
            } else {
                return false;
            }
        },
        thumbnails() {
            if (this.fitsNThumbnails && this.details.images && this.details.images.length > this.fitsNThumbnails) {
                return this.details.images.slice(0, this.fitsNThumbnails - 1);
            } else {
                return this.details.images;
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
        this.setThumbnailCount();
    },
    methods: {
        onResize() {
            this.screenWidth = window.innerWidth;
        },
        setThumbnailCount() {
            let footerWidth = 0;
            const cardFooter = this.$refs.cardFooter;
            if (cardFooter && cardFooter.offsetWidth) {
                footerWidth = cardFooter.offsetWidth;
            }
            this.fitsNThumbnails = Math.floor(footerWidth / 36);
        }
    },
}
</script>