/*
* ---------------------------------------------------------------------------
* COMMERCIAL IN CONFIDENCE
*
* (c) Copyright Quosient Ltd. All Rights Reserved.
*
* See LICENSE.txt in the repository root.
* ---------------------------------------------------------------------------
*/
import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store/index';
import { bindBeforeEach } from './events.js';
import { routes } from './routes.js';

let router = null;

export const getRouter = () => {
    return router;
}

// Export Global Router Install
export const createGlobalRouter = (history = createWebHashHistory) => {
    return {
        install(app) {

            // Create Router
            const defaultRouter = createRouter({
                history: history(),
                routes
            });

            // Bind Events
            bindBeforeEach(defaultRouter, store)

            // Install on the App
            defaultRouter.install(app)

            // Bind to the Global
            router = defaultRouter;
        }
    }
}
