/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly/core';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock';
import {FieldNoTrimDropdown} from '@/fields/FieldNoTrimDropdown';
import {addDropdownInput} from '@/blocks/helper_functions'
import {NO_DATA_DROPDOWN_VALUE} from "@/constants/nextGenConstants";
import imageDatesModal from '@/modals/ImageDates.vue';
import moment from 'moment'
import { ContentService } from '@/services/content.service';
import assets from '@/assets.js';

const pixel_options = [
    ["Single value", "single_value"],
    ["Values from attribute", "attribute_values"],
    ["Proximity to nearest feature (m)", "proximity_to_feature"]
]

const options = {
    "showModal": false, 
    "selectedToDate": moment().format('YYYY-MM-DD'), //For period of time
    "selectedFromDate": moment().subtract(1, 'year').format('YYYY-MM-DD'), //for period of time 
}

var convertFeaturesJson = {
    "type": "analysis_convert_features",
    "message0": `%1 %2 %3 %4 %5 %6 %7`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "convert_features_title",
            "text": "%{BKY_ANALYSIS_CONVERT_FEATURES_TITLE} ", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name":"convert_features_pixel_label",
            "text": "%{BKY_ANALYSIS_CONVERT_FEATURES_PIXEL_LABEL} "
        },
        {
            "type": "field_dropdown",
            "name": "convert_features_pixel",
            "options": pixel_options
        },
        {
            "type":"input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name":"convert_features_options_label",
            "text": "%{BKY_ANALYSIS_CONVERT_FEATURES_OPTIONS_LABEL}"
        },
        {
            "type": "field_modal",
            "name": "dates_modal",
            "modal_component": imageDatesModal,
            "src": assets.blockly.settingsWhite24dp,
            "modal_data": options,
            "width": 24,
            "height": 24,
            "opt_alt": "ALT STRING"
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "geoprocessing",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['analysis_convert_features'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,

    onInit: function() {
        this.jsonInit(convertFeaturesJson);
        this.getField("convert_features_pixel").setValidator(this.PixelOptionValidator);
        this.about_block_url = ContentService.getAboutBlockUrl('analysis_convert_features')
    },

    accept: async function (visitor) {
        await visitor.visitAnalysisConvertFeaturesBlock(this);
    },
    PixelOptionValidator: function(newValue){
        let block = this.getSourceBlock();
        if (newValue === "attribute_values")  {
            const attributes = block.getState('attributes')
            if (attributes.length !== 0) { 
                addDropdownInput(block,"attributes_input","Attribute","attribute_options",attributes,null,null,FieldNoTrimDropdown)
            } else {
                addDropdownInput(block,"attributes_input","Attribute","attribute_options",[["No numeric attributes", NO_DATA_DROPDOWN_VALUE]],null,null,FieldNoTrimDropdown)
            }

        } else { 
            block.removeInputIfExists("attributes_input")
        }
        return newValue;
    },
    updateShape_: function() {
        if(this.hasStateChanged('attributes') && this.getField('attribute_options')){
            this.getField('attribute_options').updateOptions(this.getState('attributes'))
        }
    },
    ebxValidate: function(tooltips) {
        const isVector = this.getState('isVector')
        const hasDefinition = this.getState('hasDefinition')
        this.setWarningText(null, "not_vector_dataset")
        if (!hasDefinition || !isVector) {
            this.setWarningText(tooltips['not_vector_dataset'] || "This dataset doesn’t contain features. Use this block in workflows with a feature collection (vector) input","not_vector_dataset")
        } else {
            this.setWarningText(null, "not_vector_dataset")
        }
   }
}