/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["OUTPUT_MAP_LAYER_BLOCK_TITLE"] = "Create map layer";
Blockly.Msg["OUTPUT_MAP_LAYER_NAME_LABEL"] = "Layer name ";
// Blockly.Msg["OUTPUT_MAP_LAYER_OPACITY_LABEL"] = "Opacity "; //Hidden for now
// Blockly.Msg["OUTPUT_MAP_LAYER_ANIMATE_LABEL"] = "Animate ";

export default Blockly.Msg;