/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import moment from "moment";
import { AbstractBlock, AbstractFieldHelpers, AbstractVariableHelpers } from '../_abstractBlock';
import { ContentService } from '@/services/content.service';
import { VariablesService } from '@/services/variable.service';
import {FieldDateVariable} from '@/fields/FieldDateVariable';
import {FieldVariableDropdown} from '@/fields/FieldVariableDropdown';
import { range } from 'lodash'
import {CustomFieldDate} from '../../fields/blocklyFieldDate';
import {DATE_REGEX} from '@/constants/ebxStacConstants';
import {toRaw} from 'vue';

const FIELD = Object.freeze({
    FROM: 'from_date',
    TO: 'to_date',
    CADENCE: 'cadence',
    DEFAULT_DATES: 'default_dates',
})

const INPUT = Object.freeze({
    DATES: 'dates',
})

var modifierTimePeriodJson = {
    "type": "modifier_time_period",
    "message0":`%1 %2 %3 %4 %5 %6 %7`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "block_name",
            "text": "%{BKY_MODIFIER_TIME_PERIOD_BLOCK_TITLE}", 
            "class": "boldTitleField"
        },
        {
            "type":"input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "from_label",
            "text": "%{BKY_MODIFIER_TIME_PERIOD_FROM_LABEL}"
        },
        {
            "type": "field_date_variable",
            "name": FIELD.FROM,
            "textEdit": "true",
        },
        {
            "type": "field_label_serializable",
            "name": "to_label",
            "text": "%{BKY_MODIFIER_TIME_PERIOD_TO_LABEL}"
        },
        {
            "type": "field_date_variable",
            "name": FIELD.TO,
            "textEdit": "true",
        },
        {
            "type":"input_dummy",
            "name": INPUT.DATES
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "primaryFilter", 
    "tooltip": "",
    "helpUrl": ""
};

Blockly.Blocks['modifier_time_period'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    ...AbstractVariableHelpers,

    FIELD: FIELD,

    onInit: function() {
        this.jsonInit(modifierTimePeriodJson);

        this.about_block_url = ContentService.getAboutBlockUrl('modifier_time_period')
        this.getField(FIELD.FROM)
            .setValidator(this.validateStartDate.bind(this));

        
        // Example of how to set a handle display text. Commented off for now
        // this.getField(FIELD.FROM)
        //     .setHandleDisplayText(this.handleDisplayText);


        this.getField(FIELD.TO)
            .setValidator(this.validateEndDate.bind(this));
        // ensures state is set
        this.triggerValidator(FIELD.FROM);
        this.triggerValidator(FIELD.TO);
    },

    accept: async function (visitor) {
        await visitor.visitModifierTimePeriodBlock(this);
    },
    // Example of how to set a handle display text. Commented off for now
    // handleDisplayText: function() {
    //     return "2022-02-22"
    // },
    
    
    // returns true if valid, false if not
    validateDates(startDate, endDate, actionFieldId) {
        startDate = moment(startDate,'YYYY-MM-DD').unix();
        endDate = moment(endDate,'YYYY-MM-DD').unix();
        if(startDate > endDate) {
            let tooltips = ContentService.getWarningText('modifier_time_period')
            this.setWarningText(tooltips['date'], "date_warning");
            this.appendWarningToVariable(this.getFieldValue(actionFieldId), tooltips['date'], "date_warning")
            return false;
        }
        this.setWarningText(null, "date_warning")
        return true
    },

    /**
     * Start date validator - triggers source block date validation.
     * Does not return null as this is confusing for the user when
     * their inputted value does not take. Instead validate start/end 
     * in workspace validation.
     */
    validateStartDate: function(newValue) {  
        const field = this.getField(FIELD.TO)
        let endDate = field.getVariableValue(FIELD.TO);
        let startDate = newValue

        const type = this.getVariableTypeById(newValue)
        var variableDate = this.getVariableValueById(newValue)
        const isVariable = this.getAllVariableIds().includes(newValue)

        //If date is a date range variable  
        if(isVariable && type === 'date range' && variableDate !== undefined ) {
            startDate = variableDate[0]
            endDate = variableDate[1]
            if(newValue !== this.getFieldValue(FIELD.TO)) { 
                setTimeout(() => {
                    this.setFieldValue(newValue, FIELD.TO);
                }, 0);
            } 
        } else if (isVariable && type === 'date' && variableDate !== undefined) {
            startDate = variableDate
        } else { 
            const currentValueType = this.getVariableTypeById(this.getFieldValue(FIELD.FROM))
            const endDateType = this.getVariableTypeById(this.getFieldValue(FIELD.TO))

            if (currentValueType === 'date range' && type === null) {
                if (endDateType === 'date range') {
                    let defaultDates = this.getState(FIELD.DEFAULT_DATES);
                    setTimeout(() => {
                        this.setFieldValue(moment(defaultDates.end_date).format('YYYY-MM-DD'), FIELD.TO)
                    },0)        
                }
            }
        }

        // must set state before triggering validation as in memory
        this.validateDates(startDate, endDate, FIELD.TO);
        return newValue;
    },

    /**
     * End date validator - triggers source block date validation.
     * Does not return null as this is confusing for the user when
     * their inputted value does not take. Instead validate start/end 
     * in workspace validation.
     */
    validateEndDate: function(newValue) {
        const field = this.getField(FIELD.FROM)
        let startDate = field.getVariableValue(FIELD.FROM);
        let endDate = newValue

        const type = this.getVariableTypeById(newValue)
        var variableDate = this.getVariableValueById(newValue)
        const isVariable = this.getAllVariableIds().includes(newValue)

        if (isVariable && type === 'date range' && variableDate !== undefined)  {
            startDate = variableDate[0]
            endDate = variableDate[1]
            if(newValue !== this.getFieldValue(FIELD.FROM)) {
                setTimeout(() => {
                    this.setFieldValue(newValue, FIELD.FROM);
                }, 0);
            }
        } else if (isVariable && type === 'date' && variableDate !== undefined) { 
            endDate = variableDate
        } else { 
            const currentValueType = this.getVariableTypeById(this.getFieldValue(FIELD.TO))
            const startDateType = this.getVariableTypeById(this.getFieldValue(FIELD.FROM))

            if (currentValueType === 'date range' && type === null) {
                if (startDateType === 'date range') {
                    let defaultDates = this.getState(FIELD.DEFAULT_DATES);
                    setTimeout(() => {
                        this.setFieldValue(moment(defaultDates.start_date).format('YYYY-MM-DD'), FIELD.FROM)
                    },0)        
                }
            }

        }
        // must set state before triggering validation as in memory
        this.validateDates(startDate, endDate, FIELD.FROM)
        return newValue;
    },
    /**
     * Add ebxValidate method to check:
     * - If a variable date falls outside the default dates range raise a warning on the variable in question. 
     * - Check both start and end dates
     * - Only do this if a variable is set.
     * @param {*} errors 
     * @returns 
     */
    ebxValidate: function(errors) {
        const isRaster = this.getState('isRaster')
        if(!isRaster) {
            this.setWarningText(errors['not_raster'] || 'This block is only compatible with raster datasets.', 'not_raster')
            return
        } else {
            this.setWarningText(null, 'not_raster')
        }
        const startDateField = this.getField(FIELD.FROM)
        const endDateField = this.getField(FIELD.TO)
        let startDate = startDateField.getVariableValue()
        let endDate = endDateField.getVariableValue()

        let extentStartDate = this.getState('extentStartDateUnix')
        let extentEndDate = this.getState('extentEndDateUnix')
        const fieldCadence = this.getState(FIELD.CADENCE)
        if(fieldCadence && (fieldCadence.unit === 'year' || fieldCadence && fieldCadence.unit === 'epoch')){
            extentStartDate = moment.unix(extentStartDate).set({'month': 0, 'date': 1}).unix()
            extentEndDate = moment.unix(extentEndDate).set({'month': 11, 'date': 31}).unix()
        } 

        this.validateDates(startDateField.getValue(), endDateField.getValue(), FIELD.TO);
        this.validateDates(startDateField.getValue(), endDateField.getValue(), FIELD.FROM);

        this.setWarningText(null, "default_date_warning")
        
        if (extentEndDate && extentStartDate) {
            startDate = moment(startDate,'YYYY-MM-DD').unix();
            endDate = moment(endDate,'YYYY-MM-DD').unix();
            const invalidStart = startDate < extentStartDate || startDate > extentEndDate // check if the start date is outside the default range
            const invalidEnd = endDate < extentStartDate || endDate > extentEndDate // check if the end date is outside the default range

            // If we have a invalid start or end date raise a warning on the block
            // NOTE: Variable warning are cleared upon start of validation within lblocks_validation
            if(invalidStart || invalidEnd) {
                this.setWarningText(errors['default_dates'], "default_date_warning");
                // If start date is a variable and start date is invalid raise a warning on the variable
                if (invalidStart && startDateField.isVariable()) {
                    this.appendWarningToVariable(startDateField.getValue(), errors['default_dates'], "default_date_warning")
                }
                // If end date is a variable and end date is invalid raise a warning on the variable
                if (invalidEnd && endDateField.isVariable()) {
                    this.appendWarningToVariable(endDateField.getValue(), errors['default_dates'], "default_date_warning")
                }
            }
        }
    },

    updateShape_: function() {
        if(this.isLoadingState()) {
            this.setNonPersistentState('isLoadingState', true)
        }

        let startDate = this.getState(FIELD.FROM);
        let endDate = this.getState(FIELD.TO);
        let defaultDates = this.getState(FIELD.DEFAULT_DATES);

        if(this.hasStateChanged('selectableDateRanges')) {
            if(this.getField(FIELD.FROM) instanceof CustomFieldDate) {
                this.getField(FIELD.FROM).setValidRanges(this.getState('selectableDateRanges'))
            }
            if(this.getField(FIELD.TO) instanceof CustomFieldDate) {
                this.getField(FIELD.TO).setValidRanges(this.getState('selectableDateRanges'))
            }
        }

        //Check is state has changed for vectorVariables and update the start and end date fields
        if (this.hasStateChanged('dateVariables') || this.hasStateChanged('dateRangeVariables')) {
            let fromId = this.getFieldValue(FIELD.FROM)
            let toId = this.getFieldValue(FIELD.TO)

            let deletedFromVariables = VariablesService.getDeletedVariableById(fromId)
            let deletedToVariables = VariablesService.getDeletedVariableById(toId)

            if (deletedFromVariables !== undefined) {
                this.setFieldValue(moment(defaultDates.start_date).format('YYYY-MM-DD'), FIELD.FROM)
            }

            if (deletedToVariables !== undefined) {
                this.setFieldValue(moment(defaultDates.end_date).format('YYYY-MM-DD'), FIELD.TO)
            } 

            this.updateFieldTypes_(startDate, endDate)
        }
        
        if(!this.hasStateChanged(FIELD.FROM) && !this.hasStateChanged(FIELD.TO)) {
            if(this.hasStateChanged(FIELD.CADENCE)) {
                this.updateFieldTypes_(startDate, endDate)
                this.setFieldValue(moment(defaultDates.start_date).format('YYYY-MM-DD'), FIELD.FROM)
                this.setFieldValue(moment(defaultDates.end_date).format('YYYY-MM-DD'), FIELD.TO)
            }
            return;
        }

        if(this.hasStateChanged(FIELD.CADENCE) && this.hasState('isLoadingState') === false) {
            this.setFieldValue(moment(defaultDates.start_date).format('YYYY-MM-DD'), FIELD.FROM)
            this.setFieldValue(moment(defaultDates.end_date).format('YYYY-MM-DD'), FIELD.TO)
        }
        
        this.updateFieldTypes_(startDate, endDate)
        if(this.isLoadingState() === false && this.hasState('isLoadingState')) {
            this.removeState('isLoadingState')
        }

       
    },

    /**
     * Replace the blockly fields with dropdown if a year cadence is seen
     * If cadence is not a year then either switch back to date selection or do nothing.
     * Compares the type of field to know if to update the block
     * @param {*} startDate 
     * @param {*} endDate 
     */
    updateFieldTypes_: function(startDate, endDate) {
        const fieldCadence = toRaw(this.getState(FIELD.CADENCE))

        const dateInput = this.getInput(INPUT.DATES)
        const fieldsToCheck = [
            {fieldName: FIELD.FROM, inputPos: 1, validator: this.validateStartDate.bind(this)}, 
            {fieldName: FIELD.TO, inputPos: 3, validator: this.validateEndDate.bind(this)}
        ]
        if(fieldCadence && fieldCadence.unit === 'year' || fieldCadence && fieldCadence.unit === 'epoch') {
            const startYear = parseInt(moment(startDate).format('YYYY'))
            const endYear = parseInt(moment(endDate).format('YYYY'))
            if (fieldCadence.unit === 'year') {
                const yearRange = range(startYear, endYear + 1)
                fieldsToCheck[0].dropdownOptions = yearRange.map(year => [`${year}`, `${year}-01-01`])
                fieldsToCheck[1].dropdownOptions = yearRange.map(year => [`${year}`, `${year}-12-31`])
            } else { 
                fieldsToCheck[0].dropdownOptions = [[`${startYear}`, `${startYear}-01-01`]] //Only show start and end date and not the intervening years if cadence is epoch 
                fieldsToCheck[1].dropdownOptions = [[`${endYear}`, `${endYear}-12-31`]]
            }
            
            fieldsToCheck.forEach(({fieldName, inputPos, dropdownOptions, validator}) => {
                if(this.getField(fieldName) instanceof FieldDateVariable) {
                    let currentFieldValue = this.getFieldValue(fieldName)
                    const validDates = dropdownOptions.map(d => d[1])

                    // alter selected dates to start and end of the current selected year
                    if(DATE_REGEX.test(currentFieldValue)) {
                        if(fieldName == 'to_date') {
                            currentFieldValue = moment(currentFieldValue).endOf('year').format('YYYY-MM-DD')

                        }
                        if(fieldName == 'from_date') {
                            currentFieldValue = moment(currentFieldValue).startOf('year').format('YYYY-MM-DD')
                        }
                    } else {
                        if(fieldName == 'to_date') {
                            currentFieldValue = dropdownOptions[0][1]
                        }
                        if(fieldName == 'from_date') {
                            currentFieldValue = dropdownOptions[dropdownOptions.length - 1][1]
                        }
                    }

                    if(fieldName == 'to_date') {
                        if(validDates.indexOf(currentFieldValue) < 0) {
                            currentFieldValue = validDates[validDates.length -1]
                        }
                    }
                    if(fieldName == 'from_date') {
                        if(validDates.indexOf(currentFieldValue) < 0) {
                            currentFieldValue = validDates[0]
                        }
                    }

                    dateInput.removeField(fieldName)
                    const newField = new FieldVariableDropdown(dropdownOptions)
                    if (Array.isArray(this.getState('dateVariables')) && Array.isArray(this.getState('dateRangeVariables'))) {
                        newField.updateOptions([...dropdownOptions, ...this.getState('dateVariables'), ...this.getState('dateRangeVariables')])
                    }
                    newField.setValidator(validator)
                    newField.setValue(currentFieldValue)
                    dateInput.insertFieldAt(inputPos, newField, fieldName)
                } else {
                    if (this.getState('dateVariables') || this.getState('dateRangeVariables')){
                        this.getField(fieldName).updateOptions([...dropdownOptions, ...this.getState('dateVariables'), ...this.getState('dateRangeVariables')])
                    } else {
                        this.getField(fieldName).updateOptions(dropdownOptions)
                    }
                }
            })
        } else {
            fieldsToCheck.forEach(({fieldName, inputPos, validator}) => {
                if(this.getField(fieldName) instanceof FieldVariableDropdown) {
                    const currentFieldValue = this.getFieldValue(fieldName)
                    dateInput.removeField(fieldName)
                    const newField = new FieldDateVariable()
                    
                    newField.setValidator(validator)
                    newField.setValue(currentFieldValue)
                    newField.setValidRanges(this.getState('selectableDateRanges'))
                    dateInput.insertFieldAt(inputPos, newField, fieldName)
                }
                if (Array.isArray(this.getState('dateVariables') && this.getState('dateRangeVariables'))) {
                    const options = this.getState('dateVariables').concat(this.getState('dateRangeVariables')) 
                    this.getField(fieldName).updateOptions(options)

                }
            })
        }
    }

}; 
