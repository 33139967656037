/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import { ContentService } from '@/services/content.service';
import { addFieldNumberInput, removeInput } from '@/blocks/helper_functions'
import {AbstractBlock,AbstractFieldHelpers} from '@/blocks/_abstractBlock';
import {FieldNoTrimDropdown} from '@/fields/FieldNoTrimDropdown';
import {NO_DATA_DROPDOWN_VALUE} from '@/constants/nextGenConstants';


const compositeMethods = [
    ['Median', 'median'],
    ['Mean', 'mean'],
    ['Sum', 'sum'],
    ['Min', 'min'],
    ['Max', 'max'],
    ['Mode', 'mode'],
    ['Earliest', 'earliest'],
    ['Most Recent', 'mostRecent'],
    ["Last", 'last'],
    ['Greenest Pixel', 'greenestPixel'],
    ['Maximize Index', 'maximizeIndex'],
    ['Count', 'count'],
    ['Standard Deviation', 'stdDev'],
    ['Variance', 'variance']
]

const groupMethods = [
    ['Over entire time period', 'none'],
    ['By year', 'year'],
    ['By month', 'month'],
    ['By week', 'week'],
    ['By day', 'day'],
    // We have no datasets to support these. Commented off if incase we need to support this.
    // If we do, we need to update this block to raise a warning if the date period is over multiple days
    // ['By hour', 'hour'],
    // ['By minute','minute']
]

const FIELD = Object.freeze({
    GROUP: 'group',
    METHOD: 'method',
    INTERVAL: 'composite_interval',
    INTERVAL_LABEL: 'composite_interval_label'
})

const INPUT = Object.freeze({
    GROUP: 'group_input',
    METHOD: 'method_input',
    INTERVAL: 'interval_input'
})


var modifierCompositeJson = {
    "type": "modifier_composite",
    "message0": `%1 %2 %3 %4 %5 %6 %7`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "composite_title",
            "text": "%{BKY_MODIFIER_COMPOSITE_TITLE}", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy",
            "name": INPUT.GROUP
        },
        {
            "type": "field_label_serializable",
            "name": "group_label",
            "text": "%{BKY_MODIFIER_COMPOSITE_GROUP_LABEL}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.GROUP,
            "options": groupMethods
        },
        {
            "type": "input_dummy",
            "name": INPUT.METHOD
        },
        {
            "type": "field_label_serializable",
            "name": "method_label",
            "text": "%{BKY_MODIFIER_COMPOSITE_METHOD_LABEL}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.METHOD,
            "options": compositeMethods
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "combine",
    "tooltip": "",
    "helpUrl": ""
}


Blockly.Blocks['modifier_composite'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,

    FIELD: FIELD,
    INPUT: INPUT,

    onInit: function() {
        this.jsonInit(modifierCompositeJson);
        this.getField(FIELD.GROUP).setValidator(this.groupValidator.bind(this));
        this.getField(FIELD.METHOD).setValidator(this.methodValidator.bind(this));

        this.about_block_url = ContentService.getAboutBlockUrl('modifier_composite')
    },

    accept: async function (visitor) {
        await visitor.visitModifierCompositeBlock(this);
    },

    ebxValidate: function(errors) {
        this.setWarningText(null, 'not_raster')
        const isRaster = this.getState('isRaster')
        if(!isRaster) {
            this.setWarningText(errors['not_raster'] || 'This block is only compatible with raster datasets.', 'not_raster')
            return
        }
    },

    groupValidator: function(newValue) {
        // have bound this to the validator
        if (this.getState(FIELD.GROUP) !== newValue) {
            this.setState(FIELD.GROUP, newValue);
            this.updateShapeOnChange();
        }
        this.updateIntervalLabel_(newValue, this.getFieldValue(FIELD.INTERVAL))
    },

    methodValidator: function(newValue) {
        // have bound this to the validator
        this.setState(FIELD.METHOD, newValue);
        this.updateShapeOnChange();
    },
    intervalValidator: function(newValue){
        this.updateIntervalLabel_(this.getFieldValue(FIELD.GROUP), newValue)
        return newValue
    },

    compositeMethodGenerator: function() {
        // we require the default method to be the one recommended by ebx:defaultCompMethod in STAC
        // See EBX-1201
        // the default in blockly is the first one in the list, 
        // so just need to move the defaultCompositeMethod to position 0
        // get a copy of the default from the list
        const defaultCompMethod = this.getState('defaultCompositeMethod')
        var defaultMethodOption = compositeMethods.filter(item => item[1] === defaultCompMethod)[0];
        // then remove it from the list
        var mutatedCompositeMethods = compositeMethods.filter(item => item[1] !== defaultCompMethod);
        // then pop it back at the start of the list
        mutatedCompositeMethods.unshift(defaultMethodOption);
        return mutatedCompositeMethods;
    },
    compositeGroupGenerator: function() {
        // we require the default method to be the one recommended by ebx:defaultCompMethod in STAC
        // See EBX-1201
        // the default in blockly is the first one in the list, 
        // so just need to move the defaultCompositeMethod to position 0
        // get a copy of the default from the list
        const defaultCompPeriod = this.getState('defaultCompositePeriod')
        var defaultMethodOption = groupMethods.filter(item => item[1] === defaultCompPeriod)[0];
        // then remove it from the list
        var mutatedCompositeGroups = groupMethods.filter(item => item[1] !== defaultCompPeriod);
        // then pop it back at the start of the list
        mutatedCompositeGroups.unshift(defaultMethodOption);
        return mutatedCompositeGroups;
    },

    updateInterval_: function() {
        const group = this.getState(FIELD.GROUP, 'none');
        if (group !== 'none') {
            addFieldNumberInput(this, INPUT.INTERVAL, Blockly.Msg["MODIFIER_COMPOSITE_INTERVAL_LABEL"] + ' ', FIELD.INTERVAL, 1, 1)
            this.getField(FIELD.INTERVAL).setValidator(this.intervalValidator.bind(this))
            this.moveInputAfter(INPUT.INTERVAL, INPUT.METHOD)
            if(this.fieldExists(FIELD.INTERVAL_LABEL) === false) {
                this.getInput(INPUT.INTERVAL).appendField(this.getIntervalLabel(group, this.getFieldValue(FIELD.INTERVAL) || 0),FIELD.INTERVAL_LABEL)
            }
        } else {
            removeInput(this, INPUT.INTERVAL)
        }
    },

    updateIntervalLabel_: function(groupLabel, interval) {
        if(this.fieldExists(FIELD.INTERVAL_LABEL)) {
            this.getField(FIELD.INTERVAL_LABEL).setValue(this.getIntervalLabel(groupLabel, interval))
        }
    },

    getIntervalLabel: function(groupLabel, interval) {
        let label = groupLabel
        if(parseInt(interval) !== 1) {
            label += 's'
        }
        return label
    },

    /*
        This is called by the visitor (via the accept) when parent block changes
        The recommended method for compositing is different for different datasets
        So the idea here is that if the dataset changes (in the parent) then the recommended method should also
        */
    updateDefaultMethod_: function() { 
        const newDefaultMethod = this.getState('defaultCompositeMethod')

        var newOptions = this.compositeMethodGenerator();
        var methodField = this.getField(FIELD.METHOD);
        // Need to update value on the second param as the options could change order triggering a infinate loop
        methodField.updateOptions(newOptions, newDefaultMethod);
    },
    updateDefaultPeriod_: function() {
        const newDefaultMethod = this.getState('defaultCompositePeriod')

        // var newOptions = this.compositeGroupGenerator();
        var methodFieldValue = this.getFieldValue(FIELD.GROUP);
        if (methodFieldValue !== newDefaultMethod) {
            this.setFieldValue(newDefaultMethod, FIELD.GROUP)
        }
    },
    updateDefaultInterval_: function() {
        if(this.getField(FIELD.INTERVAL)) {
            const newDefaultInterval = this.getState('defaultCompositeInterval')
            this.getField(FIELD.INTERVAL).setValue(newDefaultInterval)
        }
    },
    createBandDropdown_: function() {
        const method = this.getState('method')
        const bandsArray = this.getState('bands') || []
        const bandDropdown = bandsArray.length > 0 ? bandsArray : [["No bands available", NO_DATA_DROPDOWN_VALUE]]

        if (method === 'greenestPixel') {
            this.removeInputIfExists('bandsMax')
            if(this.inputExists('bandsGreenest') === false) {
                this.appendDummyInput('bandsGreenest')
                    .appendField('Select band used to identify greenest pixel', "band_label")
                    .appendField(new FieldNoTrimDropdown(bandDropdown), 'band');
            }
        } else if (method === 'maximizeIndex') {
            this.removeInputIfExists('bandsGreenest')
            if(this.inputExists('bandsMax') === false) {
                this.appendDummyInput('bandsMax')
                    .appendField("Select band to maximize", "band_label")
                    .appendField(new FieldNoTrimDropdown(bandDropdown),'band');
            }
        } else {
            this.removeInputIfExists('bandsGreenest')
            this.removeInputIfExists('bandsMax')
        }
    },
    updateShape_: function() {
        if(this.hasStateChanged(FIELD.GROUP)) {
            this.updateInterval_()
        }
        if(this.hasStateChanged('defaultCompositeMethod')) {
            this.updateDefaultMethod_()
        }
        if(this.hasStateChanged('defaultCompositePeriod')) {
            this.updateDefaultPeriod_()
        }
        if(this.hasStateChanged('defaultCompositeInterval')) {
            this.updateDefaultInterval_()
        }
        if(this.hasStateChanged(FIELD.METHOD)) { 
            this.createBandDropdown_()
        }
        if(this.hasStateChanged('bands') && this.fieldExists('band')) { 
            this.getField('band').updateOptions(this.getState('bands'))
        }
    }
}
