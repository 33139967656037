/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/*
* COMPARE
*/

import * as Blockly from 'blockly/core';
import {togglePlayButton, addRunButtonInput, addStopButtonInput} from '@/blocks/mixins/togglePlayButtons'
import { AbstractBlock, AbstractFieldHelpers } from '../_abstractBlock';

var compareJson ={
    "type": "workflow_compare",
    "lastDummyAlign0": "RIGHT",
    "message0": "%1 %2 %3 %4 %5 %6 %7 %8 %9 %10 %11",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "compare_title",
            "text": "%{BKY_WORKFLOW_COMPARE_TITLE}",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "data_label",
            "text": "%{BKY_WORKFLOW_COMPARE_DATA_LABEL}"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_statement",
            "name": "datasets"
        },
        {
            "type": "field_label_serializable",
            "name": "data_compare_label",
            "text": "%{BKY_WORKFLOW_COMPARE_DATA_COMPARE_LABEL}"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_statement",
            "name": "compare_datasets"
        },
        {
            "type": "field_label_serializable",
            "name": "output_label",
            "text": "%{BKY_WORKFLOW_COMPARE_OUTPUT_LABEL}"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_statement",
            "name": "outputs"
        }
    ],
    "style": "analyse",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['workflow_compare'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    togglePlayButton,
    addRunButtonInput,
    addStopButtonInput,

    onInit: function() {
        this.jsonInit(compareJson);
        this.addRunButtonInput();
    },

    accept: async function (visitor) {
        await visitor.visitWorkflowCompareBlock(this);
    },

    updateShape_: function() {
        this.togglePlayButton()
    },
};