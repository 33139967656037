<script>
	import { h } from 'vue'
  export default {
    functional: true,
    props: {
      mdTag: {
        type: [String, Object],
        default: 'div'
      }
    },
    render ( ) {
      return h(this.mdTag, { class: this.$attrs.class },() => this.$slots.default())
    }
  }
</script>
