/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly/core';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock';
import {NO_DATA_DROPDOWN_VALUE} from "@/constants/nextGenConstants";
import { ContentService } from '@/services/content.service';

const FIELD = Object.freeze({
    BAND: 'band',
    NAME: 'name',
});
const defaultBandOptions = [["No bands", NO_DATA_DROPDOWN_VALUE]]

var convertFeaturesJson = {
    "type": "analysis_convert_images",
    "message0": `%1 %2 %3 %4 %5 %6 %7 %8`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "convert_features_title",
            "text": "%{BKY_ANALYSIS_CONVERT_IMAGES_TITLE}", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name":"band_label",
            "text": "%{BKY_ANALYSIS_CONVERT_IMAGES_BAND_LABEL}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.BAND,
            "options": defaultBandOptions
        },
        {
            "type": "input_dummy",
        },
        {
            "type": "field_label_serializable",
            "name": 'name_;abel',
            "text": "%{BKY_ANALYSIS_CONVERT_IMAGES_NAME_LABEL}"
        },
        {
            "type": "field_input",
            "name": FIELD.NAME,
        },
        {
            "type": "input_dummy",
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "geoprocessing",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['analysis_convert_images'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,

    onInit: function() {
        this.jsonInit(convertFeaturesJson);
        this.getField(FIELD.BAND).setValidator(this.validateBand);
        this.about_block_url = ContentService.getAboutBlockUrl('analysis_convert_images')
    },

    accept: async function (visitor) {
        await visitor.visitAnalysisConvertImagesBlock(this);
    },
    
    updateShape_: function() {
        if(this.hasStateChanged('bands') && this.getState('isVector') === false) {
            let bands = this.getState('bands').slice(0);
            if (bands.length === 0) {
                bands = defaultBandOptions
            }
            this.getField(FIELD.BAND).updateOptions(bands)
        }
    },
    validateBand: function(value) {
        const block = this.getSourceBlock();
        const oldBand = block.getFieldValue(FIELD.BAND);
        const oldName = block.getFieldValue(FIELD.NAME);
        if (oldName === oldBand || oldName === '') {
            block.setFieldValue(value, FIELD.NAME);
        }
        return value;
    },
    ebxValidate: function(tooltips) {
        this.setWarningText(null, 'no_band_selected')
        this.setWarningText(null, 'no_new_band_name')
        this.setWarningText(null, "not_raster_dataset")
        const isVector = this.getState('isVector')
        const band = this.getFieldValue(FIELD.BAND);
        const bandName = this.getFieldValue(FIELD.NAME);
    
        if(!band || band === NO_DATA_DROPDOWN_VALUE) {
            this.setWarningText(tooltips['no_band_selected'] || 'No band has been selected', 'no_band_selected')
        }
        if(!bandName || bandName === '') {
            this.setWarningText(tooltips['no_new_band_name'] || 'No new band name has been entered', 'no_new_band_name')
        }
        if(isVector) {
            this.setWarningText(tooltips['not_raster_dataset'] || 'This dataset doesn’t contain images. Use this block in workflows with a image collection (raster) input', 'not_raster_dataset')
        }
    }
}