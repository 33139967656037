/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import {FieldButton} from './FieldButton';
import Blockly from 'blockly'
import {BlockStopWorkflowEvent} from '@/events/stop_workflow'
import assets from "@/assets.js"
const eventUtils = Blockly.Events;


/**
 * @extends {FieldButton}
 */

const imageHeight = 30
const imageWidth = 30
const src = assets.stopIcon
const altText = "Press to stop workflow"
class FieldStopButton extends FieldButton {
    constructor(opt_flipRtl, opt_config) {
        super(src, imageWidth, imageHeight, altText, null, opt_flipRtl, opt_config);
        this.setOnClickHandler(this.playButtonClick)
    }

    playButtonClick() {
        const source =  this.getSourceBlock()
        if (source && eventUtils.isEnabled()) {
            eventUtils.fire(new BlockStopWorkflowEvent(source, 'field', this.name || null, this));
        }
    }

    /**
     * Construct a FieldPlayButton from a JSON arg object,
     * dereferencing any string table references.
     * @param {!Object} options A JSON object with options (src, width, height,
     *    alt, and flipRtl).
     * @return {!FieldImage} The new field instance.
     * @package
     * @nocollapse
    */
    static fromJson(options) {
        return new this(
            options['opt_flipRtl'], 
            options);
    }
}
export {FieldStopButton};