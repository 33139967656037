<template>
  <div>
    <h1>
      Organisation Groups
      <md-button @click="addGroup" class="md-fab md-mini md-accent">
        <md-icon>add</md-icon>
      </md-button>
    </h1>

    <div v-if="!groups">
      <md-progress-bar md-mode="indeterminate" />
    </div>
    <div v-else-if="groups.length === 0">
      <div class="assets-tab--empty">
            <div class="ebx-icon">
                <img :src="assets.icons.emptyStateAssets" alt="No groups">
            </div>
            <p class="ebx-primary">No organisation groups created</p>
            <div><EbxButton @click="addGroup">Create Group</EbxButton></div>
        </div>
    </div>
    <md-list v-else class="md-double-line">
      <md-list-item v-for="group in groups" :key="group.id">
        <md-icon class="md-primary">group</md-icon>

        <div class="md-list-item-text">
          <span>{{ group.name }}</span>
        </div>

        <md-button class="md-icon-button md-list-action" @click="editGroup(group)">
          <md-icon>edit</md-icon>
        </md-button>
        <md-button class="md-icon-button md-list-action" @click="deleteGroup(group)">
          <md-icon>delete</md-icon>
        </md-button>
      </md-list-item>
    </md-list>

    <md-snackbar
      :md-position="'center'"
      :md-duration="10000"
      v-model:md-active="showSnackbar"
      md-persistent
    >
      <span>{{ message }}</span>
    </md-snackbar>
    <OrganisationGroupsAddEditGroup ref="organisationGroupDialog" @group-save="onGroupSave" />
  </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/

import OrganisationGroupsAddEditGroup from "@/components/OrganisationGroupsAddEditGroup.vue";
import authMixin from "./mixins/authMixin";
import organisationsMixin from './mixins/organisationsMixin';
import {functions} from "@/firebaseFunctions";
import EbxButton from "@/components/EbxComponents/EbxButton.vue";
import assetsMixin from "@/components/mixins/assetsMixin.js" 

export default {
  name: "OrganisationGroupsList",
  mixins: [authMixin, organisationsMixin, assetsMixin],
  components: {
    OrganisationGroupsAddEditGroup,
    EbxButton
  },
  data: () => ({
    organisations: null,
    showSnackbar: false,
    message: "",
    groups: null
  }),
  methods: {
    async onGroupSave(data, isUpdate) {
        if(isUpdate) {
            await functions.orgUserGroups({action: 'update', id: data.id, name: data.name})
            this.message = "Group updated";
        } else {
            await functions.orgUserGroups({action: 'create', name: data.name})
            this.message = "Group added";
        }
        this.showSnackbar = true
        this.getGroups(true);
    },
    addGroup() {
        this.$refs.organisationGroupDialog.showDialog();
    },
    editGroup(group) {
        this.$refs.organisationGroupDialog.showDialog(group);
    },
    async deleteGroup(group) {
        const message = 'Are you sure you want to delete this group. Any user in this group will be removed from the group.'
        const confirmedLeave = await this.$confirmModal(message ,{title: 'Delete Organisation Group', okButtonText:'Delete Group'})
        if(!confirmedLeave) {
          return
        }
        await functions.orgUserGroups({action: 'delete', id: group.id})
        this.message = "Group deleted";
        this.showSnackbar = true
        this.getGroups(true);
    },
    async getGroups(force = false) { 
      this.groups = await this.loadOrgGroups(force)
    }
  },
  created: function() {
    this.getGroups();
  }
};
</script>

<style scoped lang="scss">
.md-fab {
  float: right;
  margin-top: -9px;
}
</style>
