<template>
    <div class="ebx-textarea">
        <div v-if="isEditing">
            <md-field class="ebx-textarea--field" >
                <md-textarea ref="textarea" v-model="newValue" :md-autogrow="true"></md-textarea>
            </md-field>
            <div class="ebx-textarea--actions">
                <button v-if="awaitingSave" class="ebx-button ebx-button--secondary__disabled">Saving</button>
                <button
                    v-else
                    @click="saveFieldAction"
                    class="ebx-button ebx-button--secondary ebx-button--secondary__highlight">Save</button>
                <button
                    v-if="!awaitingSave"
                    @click="cancelFieldAction"
                    class="ebx-button ebx-button--secondary">Cancel</button>
            </div>
        </div>
        <div v-else>
            <div class="ebx-textarea--view-only" :class="bypassInput ? 'no-hover' : ''" @click="enterEditMode" @keyup.enter="enterEditMode">
                <div v-if="newValue" v-html="newValue" class="ebx-primary">
                </div>
                <p v-else class="ebx-primary text-disabled">Add a description</p>
            </div>
        </div>
    </div>
</template>

<script>
import valueMixin from '@/components/mixins/valueMixin';

export default {
    props: {
        modelValue: {
            type: String,
            required: true
        },
        asyncSave: {
            type: Boolean,
            required: false,
            default: false
        },
        awaitingSave: {
            type: Boolean,
            required: false,
            default: false
        },
        bypassInput: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    mixins: [valueMixin],
    emits: [
        'save-description',
        'bypass-input',
        'update:modelValue'
    ],
    data() {
        return {
            isEditing: false,
            isInvalid: false,
            oldValue: '',
            newValue: '',
            tempValue: null
        }
    },
    watch: {
        awaitingSave(val) {
            if(val === false) {
                this.isEditing = false;
            }
        },
        value(val) {
            this.newValue = val;
        }
    },
    created() {
        this.newValue = this.value;
    },
    methods: {
        enterEditMode() {
            if (this.bypassInput) {
                this.$emit('bypass-input');
                return;
            }
            // take a copy of the current value
            this.oldValue = this.value;
            this.isEditing = true;
            // move focus into the input field
            setTimeout(() => {
                this.focusInput();
            });

        },
        saveFieldAction() {
            this.$emit('save-description', this.newValue, this.oldValue)
            this.tempValue = null;
            if (!this.asyncSave) {
                this.isEditing = false;
            }
        },
        cancelFieldAction() {
            this.newValue = this.oldValue;
            this.isEditing = false;
            this.tempValue = null;
        },
        focusInput() {
            // get id of the input field
            const id = this.$refs.textarea?.id;
            if (id) {
                // find the input field
                const input = document.getElementById(id);
                if (input) {
                    // focus the input field
                    input.focus();
                    input.click();
                }
            }
        }
    }
}
</script>