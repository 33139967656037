<template>
    <div ref="showDialog" class="modal-ms">
        
        <GoogleMapPolygonUploadFileSelection
            v-model="showDialog"
            :add-to-map="addToMap"
            :clear-files="clearFiles"
            :upload-error="uploadError"
            :upload-error-message="uploadErrorMessage"
            :upload-in-progress="isLoading"
            :hide-splitting-features="hideSplittingFeatures"
            @cancel-upload="cancelUpload"
            @clear-upload-error="clearUploadError"
            @dialog-closed="dialogClosed"
            @show-alert="handleShowAlert"
            @start-upload="startUpload"
            @new-cached-geojson="cachedGeoJSON = $event"
            />

        <md-dialog-alert
            v-model:md-active="alertActive"
            :md-content="alertMessage"
            :md-confirm-text="'OKAY'"
            class="dialog ebx-dialog ebx-dialog--alert"
            />
        <GoogleMapPolygonUploadSaveFile
            v-model="showSecondaryDialog"
            :secondary-dialog-text="secondaryDialogText"
            @upload-to-assets="uploadToAssets"
            />
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { AreaService } from "@/services/area.service";
import { AuthService } from "@/services/auth.service";
import { storage , usersCollection ,projectId} from "@/firebase.js";
import firebase from "firebase/compat/app"
import { v4 as uuidv4 } from 'uuid';
import {getGeoJson, getShapes, getBbox, simplifyGeoJson} from "@/scripts/ShapefileHandlers.js";
import { functions } from "@/firebaseFunctions.js";
import {MAP_AREA_DEFAULT_SPLIT_PROPERTY,MAP_AREA_DEFAULT_KEY } from "../../constants/nextGenConstants";
import { INGEST_TABLE_OP_TYPE } from '@/constants/appConstants'

import GoogleMapPolygonUploadSaveFile from '@/components/ResultMap/GoogleMapPolygonUploadSaveFile.vue';
import GoogleMapPolygonUploadFileSelection from '@/components/ResultMap/GoogleMapPolygonUploadFileSelection.vue';
import valueMixin from '@/components/mixins/valueMixin';

export default {
    name: 'GoogleMapPolygonUploadWrapper',
    props: {
        google: {
            type: Object,
            required: true
        },
        map: {
            type: Object,
            required: true
        },
        selectedArea: {
            type: Object,
            required: false,
            default: null
        },
        modelValue: {
            type: Boolean,
            default: false
        },
        polygonAddMode: {
            type: String,
            required: true
        },
        addToMap: {
            type: Boolean,
            required: false,
            default: false
        },
        addedToMapCallback: {
            type: Function,
            required: false,
            default: () => {}
        },
        hideSplittingFeatures: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    mixins: [valueMixin],
    emits: [
        'add-area',
        'create-upload-listener',
        'set-overlay-listeners',
        'set-polygon-listeners',
        'update:modelValue'
    ],
    components: {
        GoogleMapPolygonUploadSaveFile,
        GoogleMapPolygonUploadFileSelection
    },
    data() {
        return {
            clearFiles: false,
            filesToUpload: [],
            user: null,
            bucket: null,
            loadingStatus: 'Loading',
            operationPoller: null,
            isUpload: false,
            alertActive: false,
            alertMessage: "",
            MAP_AREA_DEFAULT_SPLIT_PROPERTY: MAP_AREA_DEFAULT_SPLIT_PROPERTY,
            selectedSplitProperty: MAP_AREA_DEFAULT_SPLIT_PROPERTY,
            cachedGeoJSON: null,
            unsubscribe: null,
            isLoading: false,
            showSecondaryDialog: false,
            secondaryDialogText: '',
            uploadError: false,
            uploadErrorMessage: "",
            csvColumnsInfo: null
        }
    },
    mounted() {
        const showDialogView = this.$refs.showDialog;
        this.map.controls[this.google.maps.ControlPosition.RIGHT_TOP].push(showDialogView)

        this.subscription = AuthService.loggedUser$.subscribe((user) => {
            this.user = user;
        });

        this.bucket = storage.refFromURL(`gs://unscanned-earthengine-upload-${projectId}/`);
    },
    beforeUnmount() {
        this.subscription.unsubscribe();
    },
    methods: {
        clearUploadError() {
            this.uploadError = false;
            this.uploadErrorMessage = "";
        },
        removeFile() {
            this.filesToUpload = []
            this.clearFiles = true
        },
        // function used to create polygons for specific area names in example workflows
        // area colours fixed in trial-291 (MR = https://gitlab.com/earth-blox-dev-team/earthbloxnextgen/-/merge_requests/311)
        renderAreas(coords, areaName) {

            let customAreas = AreaService.getCustomAreas();
            let selectedArea = this.selectedArea;

            // only add area if we don't already have that name
            if (!customAreas.map(a => a.id).includes(areaName)) {
                this.$emit('add-area', areaName);
                selectedArea = AreaService.getSelectedArea()
            }

            if(selectedArea) {
                const newPolygon = new this.google.maps.Polygon({
                    paths: coords,
                    fillOpacity: 0,
                    strokeWeight: 4,
                    strokeColor: selectedArea.colour,
                    clickable: false
                })

                newPolygon.setMap(this.map);

                AreaService.addUserDrawnShape(selectedArea.id, newPolygon);

                this.setListeners(newPolygon)

            }

        },
        cancelUpload() {
            this.showDialog = false;
        },
        cleanUpAfterUpload() {
            this.dialogClosed();
            this.isLoading = false;
            this.alertActive = false
            if (!this.showSecondaryDialog) {
                this.removeFile();
            }
        },
        dialogClosed(){
            this.loadingStatus = 'Loading';
            this.cachedGeoJSON = null
        },
        async createOperationDoc(filesToUpload, uploadJob, formData) {
            let docId = `${this.user.uid}_${uploadJob}`
            let docRef = this.userRef.collection("operations").doc(docId);

            // TODO replace with psuedo state enum
            let currentState = "UPLOADING"

            let docFields = {
                files: filesToUpload.map((file) => file.name), // array of file names (if we decide to allow multiple files)
                filename: filesToUpload[0].name, // for single file
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                state: currentState,
                submittedBy: this.user.uid,
                task_type: INGEST_TABLE_OP_TYPE,
                hasDismissedAssetNotification: {
                    isUploading: false,
                    isFinished: false,
                }
            }

           if (formData.csvColumnsInfo && formData.csvColumnsInfo.length > 0) {
                docFields.formData = {
                    csvColumnsInfo: formData.csvColumnsInfo
                }
            }

            if (formData.latColumn) { 
                docFields.formData = { 
                    ...docFields.formData,
                    latColumn: formData.latColumn
                }
            }

            if (formData.lonColumn) {
                docFields.formData = {
                    ...docFields.formData,
                    lonColumn: formData.lonColumn
                }
            }

            if (formData.selectedProjection) {
                docFields.formData = {
                    ...docFields.formData,
                    selectedProjection: formData.selectedProjection
                }
            }

            await docRef.set(docFields)
            
            this.loadingStatus = currentState

            return docRef
        },
        /**
         * Given a file, check if the user wants the file to be uploadded
         * to long term storage, or directly to the map. Uploads file or adds to map
         * using geojson
         */
        startUpload(filesToUpload, isUpload, selectedSplitProperty, formData, isCsv) {
            this.isUpload = isUpload;
            this.selectedSplitProperty = selectedSplitProperty;
            this.formData = formData;
            if(this.isUpload) {
                if (isCsv) { //If file is a csv, use the original csv file 
                    this.filesToUpload = [filesToUpload[1]];
                    this.uploadFiles(this.addToMap);
                } else { 
                    this.filesToUpload = [filesToUpload[0]];
                    this.uploadFiles();
                }
            } else {
                this.filesToUpload = [filesToUpload[0]];  
                this.parseShapeFileLocally()
            }
        },
        uploadToAssets() {
            this.uploadFiles(false);
        },
        uploadFiles(addToMap) {
            this.isLoading = true;

            let uploadJob = uuidv4();
            // slugify the filename
            let storagePath= `/${this.user.orgId}/${this.user.uid}/${uploadJob}`
            let fileList = this.filesToUpload

            this.createOperationDoc(fileList, uploadJob, this.formData).then(operationDocRef => {
                if(operationDocRef === false) {
                    this.isLoading = false
                    this.removeFile()
                    return
                }
                // this loops over each file and uploads them to storage
                // resolves if all files uploaded
                this.uploadToStorageBucket(fileList, uploadJob, storagePath).then(async () => {
                        // update operation doc, and create status "scanning"
                        let currentState = "SCANNING";
                        operationDocRef.update({
                            state: currentState,
                            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
                        })

                        this.loadingStatus = currentState
                        // now that files are uploaded we should listen to backend changes
                        // this.createOperationDocSnapshotListener(operationDocRef)
                        

                        if (addToMap === true) {
                            // add asset to map as well as upload for storage                                
                            let canLoadFileToMap = false;

                            if (fileList.length === 1 && fileList[0].size && fileList[0].size <= 1e+7) {
                                canLoadFileToMap = await this.checkIfFileCanSimplify()
                            } 

                            if (canLoadFileToMap === false) {
                                console.error("Error simplifying geojson");
                                this.isLoading = false;
                                this.showDialog = false;
                                this.cleanUpAfterUpload();
                                this.loadingStatus = "Error";
                                this.alertMessage = "Your file exceeded the maximum size of 800KB and couldn't be added to your project. Once your file has finished uploading to Assets (this may take a few minutes), you can add it to your project by clicking <b>Add Area</b> > <b>Choose from Assets</b>";
                                // show snackbar
                                this.alertActive = true;
                            } else {
                                await this.parseShapeFileLocally()
                            }
                        } else {
                            this.isLoading = false;
                            console.log("closing dialog")
                            this.showDialog = false;
                            this.cleanUpAfterUpload();
                        }
                        this.removeFile()
                    })
                    .catch((file) => {
                        console.error("File failed to upload", file)
                        this.removeFile()
                        // update operation doc and create status "ERROR"
                        // give error information
                        let currentState = "ERROR";
                        operationDocRef.update({
                            state: currentState,
                            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
                        })

                        this.isLoading = false;
                        this.loadingStatus = currentState
                        this.alertMessage = "There was an error uploading your file. Please try again."
                        this.alertActive = true;           
                    })
                });
        },
        createOperationDocSnapshotListener(operationDocRef) {
            this.$emit('create-upload-listener', operationDocRef, this)
            this.unsubscribe = operationDocRef.onSnapshot(async (doc) => {
                this.loadingStatus = doc.state
                this.alertMessage = "Loading asset...";
                this.alertActive = true;
                let data = {
                    "org_id": this.user.orgId,
                    "user_id": this.user.uid,
                    "asset_id": doc.assetID,
                    "class_property": this.selectedSplitProperty
                }
                try {
                    const response = await functions.getTable(data)
                    let bbox = response.data.bbox

                    const areas = this.addDefaultAreaMap()
                    response.data.mapAreas.forEach(mapArea => {
                        let areaName = this.selectedSplitProperty === MAP_AREA_DEFAULT_SPLIT_PROPERTY ? MAP_AREA_DEFAULT_KEY : mapArea.class;
                        if(areas[areaName] === undefined) {
                            areas[areaName] = this.addArea(areaName === MAP_AREA_DEFAULT_KEY ? null : areaName)
                        }
                        AreaService.addUserUploadedShape(areas[areaName], bbox, mapArea.mapURL, doc.assetID)
                    })
                } catch {
                    this.loadingStatus = 'ERROR'
                    this.alertMessage = "There was an error uploading your file. Please try again."
                    this.alertActive = true;       
                }
            
                this.unsubscribe();
            }, error => {
                console.error('op doc listener map', error);
            })
        },
        async checkIfFileCanSimplify() {
            let geojson = this.cachedGeoJSON
            if(geojson === null) {
                geojson = await getGeoJson(this.filesToUpload)
            }
            if(!geojson){
                throw Error("geojson undefined")
            }

            // file size limit in bytes, we need to be less that 0.8 Mb for firebase
            // which has a limit of 1Mb

            try {
                const fileSizeLimit = 800000;
                if (simplifyGeoJson(geojson, fileSizeLimit)) {
                    return true;
                }
                return false;
            } catch (error) {
                console.error("Cannot simplify geojson", error);
                return false;
            }
        },
        /**
         * Parses a shapefile locally and adds to area service and map via
         * polygons
         */
        async parseShapeFileLocally() {
            this.isLoading = true;

            this.loadingStatus = "Parsing"
            try {
                let geojson = this.cachedGeoJSON
                if(geojson === null) {
                    geojson = await getGeoJson(this.filesToUpload)
                }
                if(!geojson){
                    throw Error("geojson undefined")
                }

                // file size limit in bytes, we need to be less that 0.8 Mb for firebase
                // which has a limit of 1Mb

                let simplifyResponse = null;

                try {
                    const fileSizeLimit = 800000;
                    simplifyResponse = simplifyGeoJson(geojson, fileSizeLimit);
                } catch (error) {
                    console.error("Error simplifying geojson", error);
                    this.loadingStatus = "Error";
                    if (this.isUpload) {
                        this.alertMessage = "Your file exceeded the maximum size of 800KB and couldn't be added to your project. Once your file has finished uploading to Assets (this may take a few minutes), you can add it to your project by clicking <b>Add Area</b> > <b>Choose from Assets</b>";
                        this.alertActive = true;
                    } else {
                        this.isUpload = true;
                        this.showSecondaryDialog = true;
                        this.secondaryDialogText = 'Your file exceeded the maximum size of 800KB. To use this file, save it to Assets. (This may take a few minutes.)'
                    }
                    // show snackbar
                    // this.alertActive = true;
                    return;
                }
                
                if(simplifyResponse) {
                    geojson = simplifyResponse.geojson
                }

                if (simplifyResponse.tolerance) {
                    // show snackbar to say we simplified the file
                    this.alertMessage = `Your file exceeded the maximum size of 800KB, so we've simplified the polygons with a tolerance of ${simplifyResponse.tolerance}m. To use the original polygons, upload the file to Assets.`
                    this.alertActive = true;
                }

                let bbox = getBbox(geojson);
                const areas = this.addDefaultAreaMap()

                //new updated function to handle both points and polygons 
                let shapes = getShapes(geojson, (shape, feature) => {
                    let areaName = MAP_AREA_DEFAULT_KEY;
                    if(this.selectedSplitProperty !== MAP_AREA_DEFAULT_SPLIT_PROPERTY && feature.properties[this.selectedSplitProperty] !== undefined && String(feature.properties[this.selectedSplitProperty]).length > 0) {
                        areaName = feature.properties[this.selectedSplitProperty]
                    }
                    if(areas[areaName] === undefined) {
                        areas[areaName] = this.addArea(areaName === MAP_AREA_DEFAULT_KEY ? null : areaName)

                    }
                    //Checking type here to use correct "add to map " function 
                    if (feature.geometry.type === "Polygon") { 
                        this.addPolygonToMap(shape, areas[areaName])
                    } else if (feature.geometry.type === "Point") {
                        this.addPointToMap(shape, areas[areaName])
                    }
                });
                this.centerMapToBBox(bbox)
                this.isLoading = false;

                if(shapes === 0) {
                    this.alertMessage = "No shapes found in file";
                    this.alertActive = true;
                }
                if(typeof this.addedToMapCallback === 'function') {
                    this.addedToMapCallback(Object.values(areas))
                }
            }catch(error) {
                console.error("Error parsing shapefile", error)
                this.isLoading = false;
                this.loadingStatus = "Error"
                // show snackbar
                this.alertMessage = "There was an error parsing your file. Please try uploading your file to long term storage.";
                this.alertActive = true;
            } finally {
                this.isLoading = false;
                this.showDialog = false;
                this.cleanUpAfterUpload();
                
            }
        },
        addDefaultAreaMap() {
            const areas = {}
            if(this.selectedSplitProperty === MAP_AREA_DEFAULT_SPLIT_PROPERTY) {
                if(this.polygonAddMode === 'collection') {
                    areas[MAP_AREA_DEFAULT_KEY] = this.addArea()
                }
                if(this.selectedArea !== null) {
                    areas[MAP_AREA_DEFAULT_KEY] = this.selectedArea
                }
            }
            return areas
        },
        addArea(name) {
            this.$emit('add-area', name);
            return Object.assign({}, AreaService.getSelectedArea()) 
        },
        centerMapToBBox(bbox) {
            let bounds = new this.google.maps.LatLngBounds(
                new this.google.maps.LatLng(bbox[0], bbox[1]),
                new this.google.maps.LatLng(bbox[2], bbox[3])
            );
            this.map.fitBounds(bounds, 0);
        },
        addPolygonToMap(polygon, area) {
            let coords = []
            polygon.forEach(coordPair => {
                let lat = parseFloat(coordPair[0])
                let lng = parseFloat(coordPair[1])

                coords.push({
                    lat: lat,
                    lng: lng
                })
            })
            this.createPolygon(coords, area)  
        },
        createPolygon(coords, area) {
            let newPolygon = new this.google.maps.Polygon({
                paths: coords,
                fillOpacity: 0,
                strokeWeight: 4,
                strokeColor: area.colour,
                clickable: false
            });

            newPolygon.setMap(this.map);
            AreaService.addUserDrawnShape(area.id, newPolygon);

            this.setListeners(newPolygon,'polygon')
        },
        //New function to add points to map
        addPointToMap(point, area) {
            let coords = {lat:point[0], lng:point[1]}
            this.createPoint(coords, area)  
        },
        createPoint(coords,area) {
            let newPoint = new this.google.maps.Marker({
                position: coords,
                icon: {
                    path: this.google.maps.SymbolPath.CIRCLE, 
                    scale: 8, 
                    fillColor: area.colour,
                    fillOpacity: 1, 
                    strokeWeight: 0,
                },
                clickable: false
            });

            newPoint.setMap(this.map);
            AreaService.addUserDrawnShape(area.id, newPoint);
            this.setPointListeners(newPoint,'point')
        },
        setPointListeners(shape,type) {
            this.$emit('set-overlay-listeners', shape, type)
        },
        setListeners(shape, type) {
            this.$emit('set-overlay-listeners', shape, type)
            this.$emit('set-polygon-listeners', shape)
        },
        uploadToStorageBucket(fileList, uploadJob, storagePath) {
            // function that uploads file list to storage bucket

            return new Promise((resolve, reject) => {
                let totalFiles = fileList.length;
                let nUploadedFiles = 0

                // https://firebase.google.com/docs/storage/web/upload-files#full_example
                fileList.forEach((file) => {
                    let fileName = file.name
                    if (fileName.indexOf(' ') >= 0) {
                        fileName = fileName.replaceAll(' ', '_')    
                    } 
                    this.bucket.child(`${storagePath}/${fileName}`).put(file).on('state_changed',
                        (snapshot) =>{
                            // task progress
                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.info("file is " + progress + "% done")

                        },
                        (error) => {
                            // full error list here
                            // https://firebase.google.com/docs/storage/web/handle-errors
                            console.error("Error in uploading file, more details below:", file)
                            console.error("Error code", error.code)
                            console.error(error)
                            reject(file)
                        },
                        () => {
                            // upload complete
                            nUploadedFiles++;
                            // if all files have finished uploading inform uploadFiles
                            if(totalFiles === nUploadedFiles){
                                resolve();
                            }
                        }
                    );
                });
            })

        },
        handleShowAlert(alertMessage) {
            if (typeof alertMessage === 'string') {
                 this.alertMessage = alertMessage;
            }else{
                this.alertMessage = alertMessage.alertMessage;
            }
            this.alertActive = true;
        },
    },
    computed: {
        userRef() {
            return this.user.uid ? usersCollection.doc(this.user.uid) : null;
        },
        showDialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    watch: {
        showDialog(shown) {
            if(shown === false) {
                this.cleanUpAfterUpload()
                this.clearUploadError()
                this.removeFile()
            }
        }
    }
}
</script>