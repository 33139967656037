<template>
    <div>
    <md-dialog v-model:md-active="computedModalShown" class="blockly-modals focal-analysis">
        <md-dialog-title class="focal-analysis--title">
            <span>Focal Analysis</span>
        </md-dialog-title>
        <form>
            <md-field>
                <label>I'd like to</label> 
                <md-select name="option" v-model="formData.selectedOption">
                    <md-option v-for="option in dropdownOptions" :value="option[1]" :key="option[1]">
                        {{ option[0]}}
                    </md-option>
                </md-select>
            </md-field>
            <md-field v-if="selectedOption === 'focal_stats'">
                <label>Statistic to calculate </label> 
                <md-select name="stat" v-model="formData.selectedStat">
                    <md-option v-for="stat in statsOptions" :value="stat[1]" :key="stat[1]">
                        {{ stat[0]}}
                    </md-option>
                </md-select>

            </md-field> 
            <md-field>
                <label>Select bands</label> 
                <md-select name="bands" v-model="formData.selectedBands" multiple>
                    <md-option v-for="band in available_bands" :value="band[1]" :key="band[1]">
                        {{ band[0]}}
                    </md-option>
                </md-select>
            </md-field>
            <md-field :class="messageClass">
                    <label>Suffix for new band</label>
                    <md-input v-model="formData.suffix"></md-input>
                    <span class="md-error" v-if="!isNameValid">{{ errorMessage }}</span>
            </md-field>
             <md-field>
                <label>Kernel</label> 
                 <md-select name="kernel" v-model="formData.kernel" >
                    <md-option v-for="kernel in kernelOptions" :value="kernel[1]" :key="kernel[1]">
                        {{ kernel[0]}}
                    </md-option>
                </md-select>
            </md-field>
            <div v-if="selectedKernel == 'kernel'">
                <div class="radius-field"> 
                    <label class="radius-label">Radius</label>
                    <input type="range" name="radius" id="radius" :value="formData.selectedRadius" :min="radiusMin" :max="radiusMax" @input="changeRadius">
                    <p class="selected-radius">
                        <input v-model="formData.selectedRadius" type="number" @blur="checkRadius"/>   
                    </p>
                </div> 
                <md-field>
                    <label>Units</label> 
                    <md-select name="unit" v-model="formData.selectedUnit">
                        <md-option value="pixels">Pixels</md-option>
                        <md-option value="meters">Meters</md-option>
                    </md-select>
                </md-field>
            </div>
            <div v-else-if="selectedKernel == 'gaussian'">   
                <div> 
                    <label class="radius-label">Radius</label>
                    <input type="range" name="radius" :value="formData.selectedRadius" :min="radiusMin" :max="radiusMax" @input="changeRadius">
                    <p class="selected-radius">
                        <input v-model="formData.selectedRadius" type="number" @blur="checkRadius"/>  
                    </p>
                </div>
                <md-field>
                    <label>Sigma</label>
                    <md-input v-model="formData.sigma" type="number"></md-input>
                </md-field>
                <md-field>
                    <label>Units</label> 
                    <md-select name="unit" v-model="formData.selectedUnit">
                        <md-option value="pixels">Pixels</md-option>
                        <md-option value="meters">Meters</md-option>
                    </md-select>
                </md-field>
            </div>
            <div v-if="isCalculating === false">
                <div>
                    <label class="normalise-label">Normalise</label>
                     <md-checkbox v-model="formData.normalise"></md-checkbox>    
                </div>
                <md-field>
                    <label>Magnitude</label>
                    <md-input v-model="formData.selectedMagnitude" type="number"></md-input>                    
                </md-field>
            </div>
        </form>
        <md-dialog-actions>
            <md-button class="md-primary" @click="computedModalShown = false">Close</md-button>
            <md-button class="md-primary" @click="saveModal">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
    </div>
</template>

<script>
import { globalEventBus } from '@/eventbus.js'
import { VALID_BANDNAME_REGEX, INVALID_BANDNAME_ERROR} from "@/constants/nextGenConstants";
import blocklyModalsMixin from './blocklyModalsMixin.js'

export default {
    name: 'focalAnalysis',
    mixins: [blocklyModalsMixin],
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        selectedOption: {
            type: String,
            default: 'smooth'
        },
        selectedBands: {
            type:Array,
            default: () => []
        },
        selectedStat: {
            type:String,
            default: ''
        },  //Stats option selected in the dropdown 
        newBandName:{
            type:Array,
            default: () => []
        }, //band name suffixed with the analysis option
        suffix: {
            type:String,
            default: '_smooth'
        }, //Suffix selected in the dropdown used for validation of suffix outside modal
        kernel: {
            type:String,
            default: ''
        }, //Kernel selected in the dropdown 
        selectedRadius:{
            type:Number,
            default: 3
        
        }, //Radius selected in the slider
        selectedUnit: {
            type:String,
            default: 'pixels'
        
        },  //Units selected in the dropdown
        normalise: {
            type:Boolean,
            default: true
        
        }, //Normalise selected in the checkbox
        selectedMagnitude: {
            type:Number,
            default: 1
        
        }, //Magnitude selected in the input
        sigma: {
            type:Number,
            default: 1
        },//Sigma selected in the input
    },
    data() {
        return {
            gsd: null,
            dropdownOptions: [],
            available_bands: [], 
            statsOptions: [], 
            smoothKernelOptions: [], 
            sharpenKernelOptions: [],
            edgeDetectionKernelOptions: [],
            focalStatsKernelOptions: [],
            formData: {
                selectedOption: this.selectedOption, //Analysis option selected in the dropdown
                selectedBands: this.selectedBands, //Band names selected in the dropdown
                selectedStat: this.selectedStat,  //Stats option selected in the dropdown 
                newBandName:this.suffixedBands, //band name suffixed with the analysis option
                suffix: this.suffix, //Suffix selected in the dropdown used for validation of suffix outside modal
                kernel: this.kernel, //Kernel selected in the dropdown 
                selectedRadius: this.selectedRadius, //Radius selected in the slider
                selectedUnit: this.selectedUnit,  //Units selected in the dropdown
                normalise: this.normalise, //Normalise selected in the checkbox
                selectedMagnitude:this.selectedMagnitude, //Magnitude selected in the input
                sigma: this.sigma,//Sigma selected in the input
            }
        }
    },
    computed: {
        kernelOptions() {
            if(this.formData.selectedOption === "smooth") {
                return this.smoothKernelOptions
            } else if (this.formData.selectedOption ===  "sharpen") {
                return this.sharpenKernelOptions
            } else if (this.formData.selectedOption === "edge_detection") {
                return this.edgeDetectionKernelOptions
            } else if (this.formData.selectedOption === "focal_stats") { 
                return this.focalStatsKernelOptions
            }
            return null
        },
        isCalculating() {
            return this.formData.selectedOption === 'focal_stats'
        },
        gsdOrDefault() {
            return this.gsd || 2
        },
        radiusMin() {
            return this.formData.selectedUnit === 'pixels' ? 2 : this.gsdOrDefault
        },
        radiusMax() {
            let maxValue = 21
            if(this.formData.selectedUnit === 'meters') {
                maxValue =this.gsdOrDefault * 10
            }
            if (parseInt(this.formData.selectedRadius) > maxValue) {
                return this.formData.selectedRadius
            }
            return maxValue
        },
        selectedKernel() { //determine which options to display based on which kernel was selected
            const kernels = ['square','circle','euclidean','manhattan','chebyshev'] //radius,units,normalise,magnitude
            const gaussian = ['gaussian'] //guassian kernels require an extra parameter, sigma 
            const edgeDetection = ['prewitt','roberts','laplacian4','laplacian8','compass','kirsch']
            if(kernels.includes(this.formData.kernel)) {
                return 'kernel'
            } else if (gaussian.includes(this.formData.kernel)) { 
                return 'gaussian'
            } else if (edgeDetection.includes(this.formData.kernel)) { 
                return 'edge detection'
            } else {
                return ''
            }
        },
        isNameValid() {
            if(this.formData.suffix.length == 0) {
                return true;
            }

            const test = VALID_BANDNAME_REGEX.test(this.formData.suffix);

            if (!test) {
                return false;
            } 

            return true;
        },
        errorMessage() {
            if (this.isNameValid) {
                return "";
            }

            return INVALID_BANDNAME_ERROR;
        },
        messageClass() {
            return {
                "md-invalid": !this.isNameValid
            }
        }

    },
    methods: {
        getFieldValue() {
            return {
                selectedOption: this.formData.selectedOption, //Analysis option selected in the dropdown
                selectedBands: this.formData.selectedBands, //Band names selected in the dropdown
                selectedStat: this.formData.selectedStat,  //Stats option selected in the dropdown 
                newBandName:this.formData.suffixedBands, //band name suffixed with the analysis option
                suffix: this.formData.suffix, //Suffix selected in the dropdown used for validation of suffix outside modal
                kernel: this.formData.kernel, //Kernel selected in the dropdown 
                selectedRadius: this.formData.selectedRadius, //Radius selected in the slider
                selectedUnit: this.formData.selectedUnit,  //Units selected in the dropdown
                normalise: this.formData.normalise, //Normalise selected in the checkbox
                selectedMagnitude:this.formData.selectedMagnitude, //Magnitude selected in the input
                sigma: this.formData.sigma,//Sigma selected in the input
            };
        },
        saveModal() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        },
        setSuffixedBands() { 
            this.formData.suffixedBands = this.formData.selectedBands.map(x => x.concat(this.formData.suffix))
        }, 
        checkKernelOption() { 
            if(this.kernelOptions.includes(this.formData.kernel) === false) {
                this.formData.kernel = null
            }
        },
        changeRadius(event) {
            const value = parseInt(event.target.value)
            this.formData.selectedRadius = value
        },
        checkRadius(event) {
            const value = parseInt(event.target.value)
            if(isNaN(value)) {
                this.formData.selectedRadius = this.radiusMin
            }
        }
    },
    watch: {
        showModal(val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if(val) {
                this.formData = {
                    selectedOption: this.selectedOption, //Analysis option selected in the dropdown
                    selectedBands: this.selectedBands, //Band names selected in the dropdown
                    selectedStat: this.selectedStat,  //Stats option selected in the dropdown 
                    newBandName:this.suffixedBands, //band name suffixed with the analysis option
                    suffix: this.suffix, //Suffix selected in the dropdown used for validation of suffix outside modal
                    kernel: this.kernel, //Kernel selected in the dropdown 
                    selectedRadius: this.selectedRadius, //Radius selected in the slider
                    selectedUnit: this.selectedUnit,  //Units selected in the dropdown
                    normalise: this.normalise, //Normalise selected in the checkbox
                    selectedMagnitude:this.selectedMagnitude, //Magnitude selected in the input
                    sigma: this.sigma,//Sigma selected in the input
                }
            }
        },
        selectedOption(val) {
            this.checkKernelOption()
            this.formData.suffix = "_".concat(val)
            this.blockyEventCallback('option-changed',val)
        },
        suffix () { 
            this.setSuffixedBands()
        },
        selectedBands: {
            handler: function() {
                this.setSuffixedBands()
            },
            deep: true 
        },
        selectedUnit(newUnit) {
            if(newUnit === 'meters' && this.formData.selectedRadius < this.gsdOrDefault) {
                this.formData.selectedRadius = this.gsdOrDefault * 1.1
            }
        }
    }

}
</script>
