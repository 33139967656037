import {apiVersionCollection } from '@/firebase.js';

const confirmationDefaults = {
    shown: false,
    title: 'Are you sure?',
    hideTitle: false,
    message: 'Are you sure',
    cancelButtonText: 'Cancel',
    okButtonText: 'OK',
    okButtonClass: 'md-raised md-primary',
    cancelButtonClass: 'md-raised md-secondary',
    showOkButton: true,
    showCancelButton: true,
    hideCloseButton: false,
    closeOnEsc: true,
    closeOnClickOutside: true,
    closeButtonAsAction: false,
    callback:null,
    ifWarning: true
}

const APIConfigurationDefaults = {
    projectId : '',
    showModal: false,
}

const state = {
    confirmation: Object.assign({}, confirmationDefaults),
    APIConfiguration: Object.assign({}, APIConfigurationDefaults),
    currentAPIVersion: null

};

const getters ={}

const mutations = {
    setConfirmationDetails(state, details) {
        if (details.shown === false) {
            state.confirmation = Object.assign({}, confirmationDefaults, details)
        }else {
            state.confirmation = Object.assign({}, state.confirmation, details)
        }
    },
    setAPIConfigurationDetails(state, details) {
        if (details.showModal === false) {
            state.APIConfiguration = Object.assign({}, APIConfigurationDefaults, details)
        }else {
            state.APIConfiguration = Object.assign({}, state.APIConfiguration, details)
        }
    },
    setCurrentAPIVersion(state, apiVersion) {
        state.currentAPIVersion = apiVersion;
    }
}

const actions = {
    setConfirmationDetails({commit}, details) {
        return new Promise((resolve, reject) => { 
            commit('setConfirmationDetails', {
                ...details,
                callback: {resolve, reject}
            })
        })
    },
    setAPIConfigurationDetails({commit}, details) {
        return new Promise((resolve, reject) => { 
            commit('setAPIConfigurationDetails', {
                ...details,
                callback: {resolve, reject}
            })
        })
    },
    getAPIVersion(context) {
        apiVersionCollection.doc('current').get().then((doc) => {
            if (doc.exists && doc.data().ref.id) {
                context.commit('setCurrentAPIVersion', doc.data().ref.id);
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};