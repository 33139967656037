import {AbstractAbility} from "./abstractAbility"
import { VariableCollection } from "./collections/VariableCollection"
class ProvidesVariable extends AbstractAbility{
    constructor(state) {
        super(state)
        this.stateKey = 'variables'
    }

    static newCollection(abilites) {
        return new VariableCollection(abilites)
    }

    getVariableData() {
        return this.getState().variable.toJSONForSave()
    }
}

export {ProvidesVariable}