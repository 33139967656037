<template>
    <div class="area-variable">
        <!-- explorer version -->
        <VariableDetailExplorer
            :variable="variable"
            :are-warnings="areWarnings"
            >
            <div v-if="annualDateRange" class="date-range-pickers-explorer">
                <div class="ebx-inline-label-field" :class="{'disabled': areWarnings}">
                    <label class="ebx-primary">From</label>
                    <md-field :class="{'disabled-date': areWarnings}">
                        <md-select :disabled="areWarnings" v-model="currentStartYear" placeholder="Select default start date">
                            <md-option v-for="year in variable.available_years" :key="year" :value="year">{{year}}</md-option>
                        </md-select>   
                    </md-field>
                </div>
                <div class="ebx-inline-label-field" :class="{'disabled': areWarnings}">
                    <label class="ebx-primary" >To</label>
                    <md-field :class="{'disabled-date': areWarnings}">
                        <md-select :disabled="areWarnings" v-model="currentEndYear" placeholder="Select default end date">
                            <md-option v-for="year in variable.available_years" :key="year" :value="year">{{year}}</md-option>
                        </md-select>
                    </md-field>
                </div>
            </div>
            <template v-else>
                <div v-if="areWarnings" class="date-range-pickers-explorer">
                    <md-field class="md-datepicker disabled-date">
                        <md-icon>event</md-icon>
                        <md-input class="disabled-date-input" :disabled="areWarnings" type="date" ref="input" :value="variable.value[0]"/>
                    </md-field>
                    <md-field class="md-datepicker disabled-date">
                        <md-icon>event</md-icon>
                        <md-input class="disabled-date-input" :disabled="areWarnings" type="date" ref="input" :value="variable.value[1]"/>
                    </md-field>
                </div>
                <div v-else class="date-range-pickers-explorer">
                    <md-datepicker :model-value="variable.value[0]" :md-disabled-dates="handleDisabledDates" @update:modelValue="onStartDateChange">
                        <label>From</label>
                    </md-datepicker>
                    <md-datepicker  :model-value="variable.value[1]" :md-disabled-dates="handleDisabledDates" @update:modelValue="onEndDateChange">
                        <label>To</label>
                    </md-datepicker>
                </div>
            </template>
            
            <p class="md-error" v-if="showValidationWarning">start date must be before end date</p>
        </VariableDetailExplorer>
    </div>
</template>

<script>

import VariableDetailExplorer from '@/components/Variables/VariableDetail/VariableDetailExplorer.vue'
import moment from 'moment';
import { DateRangeVariable } from '../../../variables/dateRange';
import { VariablesService } from '@/services/variable.service';
import { useVuelidate } from '@vuelidate/core'
import DateRangeMixin from './helperMixin';

export default {
    name: 'DateVariableExplorer',
    mixins: [DateRangeMixin],
    setup: () => ({ v$: useVuelidate() }), // No actual validation used?
    components: {
        VariableDetailExplorer
    },
    props: {
        variable: {
            type: Object,
            required: true
        },
        hasValue: {
            type: Boolean,
            required: false,
            default: false
        },
        areWarnings : { 
            required: false, 
            default: false
        }
    },
    computed: {
        variableId() {
            return this.variable.id;
        },
        annualDateRange() {
            return this.variable.cadence === 'year'
        },
        currentStartDate: {
            get() {
                return this.variable.value[0]
            },
            set(value) {
                this.$emit('save-variable', {value: [value, this.variable.value[1]]});
            }
        },
        currentEndDate: {
            get() {
                return this.variable.value[1]
            },
            set(value) {
                this.$emit('save-variable', {value: [this.variable.value[0], value]});
            }
        },
        currentStartValue: {
            get() {
                return this.variable.value[0]
            },
            set(value) {
                this.currentStartDate = value
            }
        },
        currentEndValue: {
            get() {
                return this.variable.value[1]
            },
            set(value) {
                this.currentEndDate = value
            }
        }
    },
    methods: { 
        onStartDateChange(date) { 
            const formattedDate = moment(date).format('YYYY-MM-DD').toString()
            this.currentStartDate = formattedDate;
        }, 
        onEndDateChange(date) { 
            const formattedDate = moment(date).format('YYYY-MM-DD').toString()
            this.currentEndDate = formattedDate;
        },
        handleDisabledDates(date) {
            const variable = VariablesService.getVariableById(this.variableId)
            if(variable instanceof DateRangeVariable) {
                return !variable.isDateSelectable(date)
            }
            return false
        }
    }
}
</script>