import * as Blockly from 'blockly/core';
import { AbstractBlock, AbstractFieldHelpers } from './_abstractBlock';
import { ContentService } from '@/services/content.service';

var modifierOrbitJson = {
    "type": "modifier_orbit",
    "message0":`%1 %2 %3 %4`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "orbit_title",
            "text": "%{BKY_MODIFIER_ORBIT_TITLE}", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "dataset_label",
            "text": "%{BKY_MODIFIER_ORBIT_OPTION_LABEL}"
        },
        {
            "type": "field_dropdown",
            "name": "orbit_selection", 
            "options": [
                ["Ascending", "asc"],
                ["Descending", "desc"],
                ["Both", "both"]
            ]
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "secondaryFilter",
    "tooltip": "",
    "helpUrl": ""
};

Blockly.Blocks['modifier_orbit'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,

    onInit: function() {
        this.jsonInit(modifierOrbitJson)
        this.about_block_url = ContentService.getAboutBlockUrl('modifier_orbit')
    },

    accept: async function (visitor) {
        await visitor.visitModifierOrbitBlock(this);
    },

    ebxValidate: function(errors) {
        this.setWarningText(null, 'not_raster')
        const isRaster = this.getState('isRaster')
        if(!isRaster) {
            this.setWarningText(errors['not_raster'] || 'This block is only compatible with raster datasets.', 'not_raster')
            return
        }
    },
    
}; 
