<template>
    <div ref="measureButton" class="google-map-ebx-rounded-action measure-button">
        <md-button title="Measure" class="md-icon-button md-raised" :disabled="showDrawingManager" :class="{'active': mapMeasureMode, 'disabled': showDrawingManager}" @click="toggleMapMeasurementMode">           
            <md-icon>straighten</md-icon>
        </md-button>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import MeasureTool from 'measuretool-googlemaps-v3/dist/gmaps-measuretool.umd.js';
export default {
    props: {
        google: {
            type: Object,
            required: true
        },
        map: {
            type: Object,
            required: true
        },
        showDrawingManager: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mapMeasureMode:false,
            measureTool: null
        }
    },
    watch: {
        showDrawingManager: function (val) {
            if (val) {
                this.exitMapMeasure();
            }
        }
    },
    mounted() {
        this.map.controls[this.google.maps.ControlPosition.RIGHT_TOP].push( this.$refs.measureButton);
        this.measureTool = new MeasureTool(this.map, {
            showSegmentLength: true,
            contextMenu: false
        });
    },
    methods: {
        toggleMapMeasurementMode() {
            if (this.mapMeasureMode === true) {
                this.exitMapMeasure();
            } else {
                this.enterMapMeasure();
            }
        },
        enterMapMeasure() {
            if(this.showDrawingManager) {
                return
            }
            this.measureTool.start();
            this.map.setOptions({ draggableCursor: "crosshair" });
            this.mapMeasureMode = true;
        },
        exitMapMeasure() {
            this.measureTool.end();
            this.map.setOptions({ draggableCursor: "default" });
            this.mapMeasureMode = false;
        },
    }
}
</script>