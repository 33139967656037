<template>
    <div>
        <EbxSearchBar
        v-model="searchBar" 
        :filter-options="searchBarFilterOptions" 
        :hide-options="!canShareWorkflows" 
        class="mb-20">
            <EbxButton theme="action" icon="file_upload" @click="ImageUpload">Upload new image</EbxButton>
        </EbxSearchBar>
        <UserDatasetsList @remove-asset="handleRemoveAsset" :filter-by="searchBar.filterBy" :search-input="searchBar.searchInput" />
        <confirmation-modal ref="modal" :hide-title="true" ok-button-text="Delete" ok-button-class="md-raised md-danger">
            <p class="ebx-primary">
                Are you sure you want to delete this dataset?
            </p>
        </confirmation-modal>
        <ImageUpload
        ref="ImageUpload" 
        v-model="showImageUpload" 
        />
    </div>
</template>

<script>
import UserDatasetsList from '@/components/Assets/UserDatasetsList.vue'
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import ImageUpload from "@/modals/ImageUpload.vue";
import userDatasetsMixin from '@/components/mixins/userDatasetsMixin'
import authMixin from '@/components/mixins/authMixin.js'
import { sharedWorkflowMixin } from '@/components/mixins/projectMixin.js'
import EbxSearchBar from '@/components/EbxComponents/EbxSearchBar.vue'

export default {
    components: {
        UserDatasetsList,
        ConfirmationModal,
        ImageUpload,
        EbxSearchBar
    },
    mixins: [
        authMixin,
        sharedWorkflowMixin,
        userDatasetsMixin
    ],
    data() {
        return {
            showImageUpload: false,
            searchBar: {
                searchInput: '',
                filterBy: 'all'
            }
        }
    },
    methods: {
        async handleRemoveAsset(asset) {
            const confirmed = await this.$refs.modal.confirm()
            if(confirmed){
                this.$store.dispatch('userdatasets/removeDataset', asset);
            }
        },
        ImageUpload() {
            this.showImageUpload = true
        },
    }
}
</script>