<template>
    <div class="ebx-value" :class="themeClasses">
        <div class="ebx-value--label ebx-tertiary" v-show="label.length > 0">
            {{ label }}
        </div>
        <div class="ebx-value--value-wrapper">
            <div class="ebx-value--value ebx-secondary">
                {{ value }}
            </div>
            <button v-if="value && !copied" class="ebx-value--copy-paste">
                <span class="material-icons ebx-icon" @click="() => handleCopyClick(value)">content_copy</span>
            </button>
            <button v-else-if="value && copied" class="ebx-value--copy-paste">
                <span class="material-icons ebx-icon" @click="() => handleCopyClick(value)">check</span>
            </button>
        </div>
    </div>
</template>

<script>
import copyPasteMixin from '../mixins/copyPasteMixin';
import valueMixin from '@/components/mixins/valueMixin';

export default {
    name: "EbxValue",
    props: {
        label: {
            type: String,
            default: "",
        },
        modelValue: {
            type: String,
            default: "",
            required: true
        },
        theme: {
            type: String,
            default: null
        }
    },
    mixins: [copyPasteMixin, valueMixin],
    computed: {
        themeClasses() {
            if (this.theme === null) {
                return {}
            }

            return {
                [`ebx-value--${this.theme}`]: true
            }
        }
    }
}
</script>