<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item md-size-80">
                <md-field md-clearable>
                    <label>runid</label>
                    <md-input v-model="runId"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-20">
                <md-button class="md-raised md-primary" @click="getRunData">Get Run Data</md-button>
            </div>
        </div>
        <!-- Giant textbox with appearance of console -->
        <div class="console">
            <pre>{{ runData }}</pre>
        </div>

        <!-- Buttons for copying the entire document, workflow json or code -->
        <div class="md-layout">
            <div v-if="foundDoc">
                <md-button class="md-raised md-primary" @click="copyRunData">Copy Entire Doc</md-button>
            </div>
            <div v-if="showCopyWorkflowJsonButton">
                <md-button class="md-raised md-primary" @click="copyWorkflowJson">Copy Block Json</md-button>
            </div>
            <div v-if="showCopyCodeButton">
                <md-button class="md-raised md-primary" @click="copyCode">Copy Code</md-button>
            </div>
        </div>

        <!-- Giant textbox with appearance of console, containing code, only seen if hasCode is true-->

        <div v-if="hasCode">
            <br />
            <h3>Code:</h3>
            <div class="console" >
                <pre>{{ code }}</pre>
            </div>
        </div>
        

    </div>
</template>
<script>
import { functions } from "../firebaseFunctions.js";

export default {
    name: "WorkflowDebugger",
    data() {
        return {
            runId: "",
            runData: "",
            foundDoc: false,
            userId: "",
            hasCode: false,
        };
    },
    methods: {
        async getRunData() {
            this.foundDoc = false;
            this.hasCode = false
            let gotError = false;
            this.runData = "Loading...";
            this.userId = "";
            try {
                const runData = (await functions.explainRunWorkflow({id: this.runId})).data
                if(runData.run) {
                    if (runData.run.json) {
                        runData.run.json = JSON.parse(runData.run.json);
                    }
                    this.runData = runData.run
                    this.foundDoc = true;
                    if (runData.codeExec) {
                        this.hasCode = true;
                        this.code = runData.codeExec.code;
                    }
                }
            }catch(error) {
                gotError = true;
                this.runData = "Error getting document: " + error;
            }
            
            if (!this.foundDoc && !gotError) {
                this.runData = "No document found with id: " + this.runId;
            }
        },
        copyRunData() {
            this.copyToClipboard(JSON.stringify(this.runData, null, 2));
        },
        copyWorkflowJson() {
            this.copyToClipboard(JSON.stringify(this.runData.json, null, 2));
        },
        copyCode() {
            this.copyToClipboard(this.code);
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text).then(function() {
                console.log('Text copied to clipboard');
            }, function(err) {
                console.error('Failed to copy text: ', err);
                console.log('Falling back to legacy copy method');
                this.legacyCopyToClipboard(text);
            });
        },
        legacyCopyToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            textArea.remove();
            console.log('Text copied to clipboard');
        }
    },
    computed: {
        showCopyWorkflowJsonButton() {
            if(!this.foundDoc) {
                return false;
            }

            return Object.hasOwnProperty.call(this.runData, "json");
        },
        showCopyCodeButton() {
            return this.hasCode && this.foundDoc;
        }
    }
};

</script>

<style scoped>
.console {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-family: monospace;
    font-size: 12px;
    overflow: auto;
    height: 500px;
}

</style>