<template>
    <div>
    <md-dialog v-model:md-active="computedModalShown" class="blockly-modals">
        <md-dialog-title>
            <span>Training data options</span>
        </md-dialog-title>

        <form>
            <!-- <md-field>
                <label for="property">Class information is contained in property:</label> 
                <md-select name="property" v-model="formData.property">
                    <md-option v-for="property in properties" :value="property" :key="property">
                        {{ property}}
                    </md-option>
                </md-select>
            </md-field> -->
            <md-field>
                <label for="split">Split Training Data</label> 
                <md-select name="split" v-model="formData.split">
                    <md-option v-for="option in options" :value="option[1]" :key="option[1]">
                        {{ option[0]}}
                    </md-option>
                </md-select>
            </md-field>
            <md-field>
                <label for="ratio">Split ratio training/validation</label> 
                <md-select name="ratio" v-model="formData.ratio">
                    <md-option v-for="ratio in ratios" :value="ratio[1]" :key="ratio[1]">
                        {{ ratio[0]}}
                    </md-option>
                </md-select>
            </md-field>
        </form>

        <md-dialog-actions>
            <md-button class="md-primary" @click="computedModalShown = false">Close</md-button>
            <md-button class="md-primary" @click="saveModal">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
    </div>
</template>

<script>

import { globalEventBus } from '@/eventbus.js'
import blocklyModalsMixin from './blocklyModalsMixin.js'

export default {
    name: 'TrainingData',
    mixins: [blocklyModalsMixin],
    props: {
        property: {
            type: Array,
            default: () => []
        },
        split: {
            type: Array,
            default: () => ['random']
        },
        ratio: {
            type: String,
            default: '',
        },
        ratios: {
            type: Array,
            default: () => ['80/20']
        },
        showModal: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            formData: {
                property: this.property,
                split: this.split,
                ratio: this.ratio,
            }
        }
    },
    methods: {
        getFieldValue() {
            return {
                property: this.formData.property,
                split: this.formData.split,
                ratio: this.formData.ratio,
            };
        },
        saveModal() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        }
    },
    watch: {
        showModal(val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if(val) {
                this.formData = {
                    property: this.property,
                    split: this.split,
                    ratio: this.ratio,
                }
            }
        }
    }
}
</script>