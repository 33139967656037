/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import { AbstractBlock, AbstractFieldHelpers } from '../_abstractBlock';
import { ContentService } from '@/services/content.service';
import moment from "moment";
import { range } from 'lodash'; 
import {FieldMultiDropdown} from '@/fields/FieldMultiDropdown'; 
import { setDropdownPreviousValue } from '@/blocks/helper_functions'

const daterangeOptions = [
    ['Specific months', 'month'],
    ['Specific years', 'year'],
    ['Day of year', 'day_of_year'],
    ['Day of month', 'day_of_month']

]

// map for the daterange options to functions that add fields to the block
const daterangeFieldFunctions = {
    'month': (block) => {

        block.addMultipleDropdownfield(block.getMonthOptions(), 'Months')

    },
    'year': (block) => {

        block.addMultipleDropdownfield(block.getYearOptions(), 'Years')
    },
    'day_of_year': (block) => {
        let dayOptions = block.getDayOfYearOptions();

        block.addNumberFields(dayOptions[0], dayOptions[1]);
    },
    'day_of_month': (block) => {
        let dayOptions = block.getDayOfMonthOptions();

        block.addNumberFields(dayOptions[0], dayOptions[1]);
    }, 

}

const FIELD = Object.freeze({
    DATE_RANGE_OPTION: 'daterange_options',
    START: 'start_date',
    END: 'end_date', 
    MULTIPLE: 'multiple_dates'
});

const INPUT = Object.freeze({
    DATE_RANGE: 'daterange_fields'
});

const daterangeJson = {
    "type": "modifier_date_range",
    "message0": "%1 %2 %3 %4",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "block_title",
            "text": "%{BKY_DATERANGE_BLOCK_TITLE}", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "daterange_label",
            "text": "%{BKY_DATERANGE_OPTIONS_LABEL}"
        },
        {
            "type": "field_dropdown",
            "name": FIELD.DATE_RANGE_OPTION,
            "options": daterangeOptions
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "primaryFilter",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['modifier_date_range'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,

    FIELD: FIELD,
    INPUT: INPUT,

    onInit: function() {
        this.jsonInit(daterangeJson);

        this.about_block_url = ContentService.getAboutBlockUrl('modifier_date_range')

        this.setState(FIELD.DATE_RANGE_OPTION, null);
        this.getField(FIELD.DATE_RANGE_OPTION)
            .setValidator(this.validateDaterangeOptions.bind(this));
        this.triggerValidator(FIELD.DATE_RANGE_OPTION)

        // if there's still no date range, then add it
        if (!this.getInput(INPUT.DATE_RANGE)) {
            daterangeFieldFunctions[this.getState(FIELD.DATE_RANGE_OPTION)](this);
        }
    },

    accept: async function (visitor) {
        await visitor.visitModifierDaterangeBlock(this);
    },

    validateDaterangeOptions: function(newValue) {
        console.log("CALLING VALIDATE", newValue)
        this.setState(FIELD.DATE_RANGE_OPTION, newValue);
        // daterangeFieldFunctions[newValue](this);
        this.updateShapeOnChange()
    },

    // returns true if valid, false if not
    validateDates(startDate, endDate) {
        this.setWarningText(null, "date_warning");

        // don't validate if it's a month selector as they could
        // want September to January for example
        if(this.getFieldValue('daterange_options') === 'month') {
            return true
        }

        startDate = parseInt(startDate);
        endDate = parseInt(endDate);
        if(startDate > endDate) {
            let tooltips = ContentService.getWarningText('modifier_date_range')
            this.setWarningText(tooltips['date'], "date_warning") ;
            return false;
        }

        return true
    },

    /**
     * Start date validator - triggers source block date validation.
     * Does not return null as this is confusing for the user when
     * their inputted value does not take. Instead validate start/end 
     * in workspace validation.
     */
    validateStartDate: function(newValue) {
        this.setState(FIELD.START, newValue);
        let endDate = this.getFieldValue(FIELD.END);
        // Get source block to validate dates, and set any necessary warnings
        this.validateDates(newValue, endDate)
        return newValue;
    },

    /**
     * End date validator - triggers source block date validation.
     * Does not return null as this is confusing for the user when
     * their inputted value does not take. Instead validate start/end 
     * in workspace validation.
     */
    validateEndDate: function(newValue) {
        this.setState(FIELD.END, newValue);
        let startDate = this.getFieldValue(FIELD.START);
        // Get source block to validate dates, and set any necessary warnings
        this.validateDates(startDate, newValue)
        return newValue;
    }, 

    /**
     * Multiple dates validator - splits selected dates into an array
     */ 
    validateMultipleDates: function(newValue) {
        this.setState(FIELD.MULTIPLE, newValue.split(';'));
        return newValue;
    },

    getMonthOptions: function() {
        return [
            ['January', '1'],
            ['February', '2'],
            ['March', '3'],
            ['April', '4'],
            ['May', '5'],
            ['June', '6'],
            ['July', '7'],
            ['August', '8'],
            ['September', '9'],
            ['October', '10'],
            ['November', '11'],
            ['December', '12']
        ]
    },

    ebxValidate: function(tooltips) {
        const multiSelectionString = '' + this.getFieldValue(FIELD.MULTIPLE)
        const dateRangeOption = this.getFieldValue(FIELD.DATE_RANGE_OPTION)

        this.setWarningText(null, 'no_range_choices_month')
        this.setWarningText(null, 'no_range_choices_year')

        const isRaster = this.getState('isRaster')
        if(!isRaster) {
            this.setWarningText(tooltips['not_raster'] || 'This block is only compatible with raster datasets.', 'not_raster')
            return
        }

        if(multiSelectionString.length === 0) {
            if (dateRangeOption === 'month') {
                return this.setWarningText(tooltips['no_range_choices_month'] || 'Please select at least one month.', 'no_range_choices_month')
            }
            if (dateRangeOption === 'year') {
                return this.setWarningText(tooltips['no_range_choices_year'] || 'Please select at least one year.', 'no_range_choices_year')
            }
        }
    },
    /**
     * Generate a list of available years for the dataset based on the start and end date
     * Create  a blockly friendly list with a year as both the key and value
     */
    getYearOptions: function() {
        let startDate = this.getState('datasetStartDate');
        let endDate = this.getState('datasetEndDate');

        const startYear = parseInt(moment(startDate).format('YYYY'))
        const endYear = parseInt(moment(endDate).format('YYYY'))
        const yearRange = range(startYear, endYear + 1)
        return yearRange.map(year => [`${year}`, `${year}`])
    },


    getDayOfYearOptions: function() {
        // TODO: get metadata for the dataset and check what days are available
        // e.g. if the dataset has already been filtered between 3 months then only the start and end days of those months are valid
        return [1, 366]
    },

    getDayOfMonthOptions: function() {
        // TODO: get metadata for the dataset and check what days are available
        return [1, 31]
    },

    addDropdownfields: function(options, startLabel, endLabel) {
        this.appendDummyInput(INPUT.DATE_RANGE)
            .appendField(startLabel)
            .appendField(
                new Blockly.FieldDropdown(
                    options, 
                    this.validateStartDate.bind(this)
                    ), FIELD.START)
            .appendField(endLabel)
            .appendField(
                new Blockly.FieldDropdown(
                    options, 
                    this.validateEndDate.bind(this)
                    ), FIELD.END)
            
        // set end date to last value in options array
        this.getField(FIELD.END).setValue(options.at(-1)[1])
    },
    addMultipleDropdownfield: function(options, label) {
        this.appendDummyInput(INPUT.DATE_RANGE)
            .appendField(label)
            .appendField(
                new FieldMultiDropdown(
                    options, this.validateMultipleDates.bind(this), { delimiter: ';'}
                    ), FIELD.MULTIPLE)
    },
    addNumberFields: function(start, end) {
        this.appendDummyInput(INPUT.DATE_RANGE)
            .appendField('Start Day')
            .appendField(
                new Blockly.FieldNumber(
                    start, 
                    start, 
                    end, 
                    1, 
                    this.validateStartDate.bind(this)
                    ), FIELD.START)
            .appendField('End Day')
            .appendField(
                new Blockly.FieldNumber(
                    end, 
                    start, 
                    end, 
                    1, 
                    this.validateEndDate.bind(this)
                    ), FIELD.END)
    },

    updateShape_: function() {
        console.log("Calling update shape with", this.getState(FIELD.DATE_RANGE_OPTION))
        if (
            !this.hasStateChanged(FIELD.DATE_RANGE_OPTION) &&
            !this.hasStateChanged('datasetStartDate') &&
            !this.hasStateChanged('datasetEndDate')
        ) {
            return;
        }


        if (this.hasStateChanged('datasetStartDate') || this.hasStateChanged('datasetEndDate')) {
            if (this.getState(FIELD.DATE_RANGE_OPTION) === 'month') {
                this.updateDropdownField(this, FIELD.MULTIPLE, this.getMonthOptions())
            } else if (this.getState(FIELD.DATE_RANGE_OPTION) === 'year') { 
                this.updateDropdownField(this, FIELD.MULTIPLE, this.getYearOptions())
            }
        }

        if (this.hasStateChanged(FIELD.DATE_RANGE_OPTION)) {
            this.removeInput(INPUT.DATE_RANGE, true);
            
            const daterangeOption = this.getState(FIELD.DATE_RANGE_OPTION);
            daterangeFieldFunctions[daterangeOption](this);
    
        } 
    },
    updateDropdownField(block, fieldName, fieldOptions) {
        block.getField(fieldName).updateOptions(fieldOptions)
        setDropdownPreviousValue(block, fieldName, block.getFieldValue(fieldName))
    }

}
