import {AbstractAbility} from "./abstractAbility"
import { AttributeCollection } from "./collections/AttributeCollection"

class ProvidesAttributes extends AbstractAbility{
    constructor(state) {
        super(state)
        this.stateKey = 'dataset'
    }

    getAttributes() {
        return this.getState() && this.getState().attributes ? this.getState().attributes : []
    }

    getAttributeByName(name) {
        return this.getAttributes().find(a => (a['name']) === name)
    }

    getFilteredAttributes() {
        return this.getState().filtered_attributes
    }

    static newCollection(abilites) {
        return new AttributeCollection(abilites)
    }
}

export {ProvidesAttributes}