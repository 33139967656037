<template>
    <div>
        <div class="dataset-attributes-tab--empty" v-if="allProperties.length === 0">
            <div class="ebx-icon">
                <img :src="assets.icons.emptyStateAssets" alt="No assets">
            </div>
            <p class="ebx-primary">
                No attributes have been added.
            </p>
        </div>
        <div v-else>
            <md-table class="md-table-ebx">
                <md-table-row>
                    <md-table-head>Name</md-table-head>
                    <md-table-head>Type</md-table-head>
                </md-table-row>
                <md-table-row v-for="property in allProperties" :key="property.id">
                    <md-table-cell>{{ property.name }}</md-table-cell>
                    <md-table-cell>{{ property.type === 'float' ? 'DECIMAL' : property.type.toUpperCase()}}</md-table-cell>
                </md-table-row>
            </md-table>
        </div>
    </div>
</template>

<script>
import assetsMixin from '@/components/mixins/assetsMixin.js'

export default {
    name: 'FeatureAttributes',
    mixins: [
    assetsMixin
    ],
    props: {
        dataset: {
            type: Object,
            required: true
        },
    },
    computed: {
        allProperties() {
            return this.dataset.gee_schema;
        },
    }
}
</script>
