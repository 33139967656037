
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock';
import CreateTable from "@/modals/CreateTable.vue";
import VectorTable from "@/modals/VectorTable.vue";
import { AreaService } from "@/services/area.service";
import { ContentService } from '@/services/content.service';
import {togglePlayButton, addRunButtonInput, addStopButtonInput} from '@/blocks/mixins/togglePlayButtons'
import { keyBy } from 'lodash';
import {FieldModal} from '@/fields/FieldModal';
import {MAP_STUDY_AREA_COLLECTION_ID, NON_BLOCKING_BLOCKLY_WARNING_PREFIX,TABLE_DATE_CONSTANTS, } from '@/constants/nextGenConstants';
import { standariseVariableDates, validateGlobalVariableUsageOnDataset } from './helper_functions';
import assets from '@/assets.js';

const FIELD = Object.freeze({
    TABLE: 'TABLE',
    NAME: 'table_name'
})

const INPUT = Object.freeze({
    TABLE: 'TABLE',
    // VECTOR:"VECTOR",
    // RASTER: "RASTER",
});

const DEFAULT_MODAL_DATA = Object.freeze({
    createdRows: [],
    includeChart: false,
    tableDescription: '',
})

var addTableJson ={
    "type": "output_add_table",
    "lastDummyAlign0": "RIGHT",
    "message0": "%1 %2 %3 %4 %5 %6" ,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "table_title",
            "text": "%{BKY_OUTPUT_ADD_TABLE_BLOCK_TITLE}",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "table_label",
            "text": "%{BKY_OUTPUT_ADD_TABLE_NAME_LABEL}",
        },
        {
            "type": "field_input",
            "name": FIELD.NAME,
            "text": "Table 1",
        },
        {
            "type": "field_modal",
            "name": FIELD.TABLE,
            "modal_component": CreateTable,
            "modal_data": Object.assign({}, DEFAULT_MODAL_DATA),
            "src": assets.blockly.settingsWhite24dp,
            "width": 30,
            "height": 30,
            "opt_alt": "ALT STRING"
        },
        {
            "type": "input_dummy",
            "name": INPUT.TABLE
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "table",
    "tooltip": "",
    "helpUrl": ""
}


Blockly.Blocks['output_add_table'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    togglePlayButton,
    addRunButtonInput,
    addStopButtonInput,
    FIELD: FIELD,
    triggerVisitorOnDragStart: true,

    onInit: function() {
        this.jsonInit(addTableJson);
        this.addRunButtonInput();

        this.about_block_url = ContentService.getAboutBlockUrl('output_add_table')

        // flyout == toolbox
        if (this.isInFlyout) {
            return;
        }
        
        this.updateShape_();

        const updateOnAreaChange = () => {
            this.updateShape_();
            this.checkDefinedVariablesAreValid()
            this.ebxValidate(ContentService.getWarningText('lblock_validation'))
        }

        this.areaSubscription_ = AreaService.areaChange$.subscribe(updateOnAreaChange)
        this.collectionSubscription_ = AreaService.collectionChange$.subscribe(updateOnAreaChange)
        this.shapeSubscription_ = AreaService.shapeUpdated$.subscribe(updateOnAreaChange)
    },

    // Remaps old workflows to new format if there is no displayType variable
    onLoadedFieldData(state) {
        if (state['dataset_type'] !== 'raster') {
            return;
        }

        const tableData = this.getFieldValue(FIELD.TABLE)
        if (!tableData) {
            return;
        }

        if (!tableData.createdRows) {
            return;
        }

        const newRows = []
        let hasChanges = false
        tableData.createdRows.forEach(row => {
            if (row.displayType !== undefined) {
                newRows.push({ ...row, id: newRows.length})
                return;
            }

            hasChanges = true
            row.properties.forEach(property => {

                const supplimentedData = {}
                supplimentedData.properties = [ property ]

                if(property['ebx:datatype'] === 'thematic') {
                    if(row.aggregation === 'area') {
                        newRows.push({
                            ...row,
                            dataset: MAP_STUDY_AREA_COLLECTION_ID,
                            displayType: 'area-calculation',
                            aggregation: 'area',
                            role: 'area',
                            dates: [],
                            roleType: 'statistic',
                            type: 'feature_collection',
                            properties: [
                                {
                                    name: 'area'
                                }
                            ],
                            id: newRows.length
                        })
                    }

                    supplimentedData.aggregation = null
                    supplimentedData.displayType = 'group-image-collection'
                    supplimentedData.role = 'rows'
                    supplimentedData.roleType = 'role'

                    newRows.push({ ...row, ...supplimentedData, id: newRows.length})
                } else {

                    supplimentedData.displayType = 'statistic'
                    supplimentedData.role = 'columns'
                    supplimentedData.roleType = 'statistic'

                    newRows.push({ ...row, ...supplimentedData, id: newRows.length})
                }
            })
        })

        if (!hasChanges) {
            return;
        }

        tableData.createdRows = newRows;
        this.setFieldValue(tableData, FIELD.TABLE);
    },
    updateBlockBasedOnDatasetType: function() {
        const datasetType = this.getState('dataset_type') || 'raster'
        const oldField = this.getField(FIELD.TABLE)
        if(datasetType === 'vector') {
            if (oldField && oldField.modalComponent_.name === 'CreateTable') {
                this.getInput(INPUT.TABLE).removeField(FIELD.TABLE)
                const field = new FieldModal(VectorTable, {}, assets.blockly.settingsWhite24dp, 30, 30)
                this.getInput(INPUT.TABLE).appendField(field, FIELD.TABLE)
            }
            
        } 

        if(datasetType === 'raster') {
            if (oldField && oldField.modalComponent_.name === 'VectorTable') {
                this.getInput(INPUT.TABLE).removeField(FIELD.TABLE)
                const field = new FieldModal(CreateTable, DEFAULT_MODAL_DATA , assets.blockly.settingsWhite24dp, 30, 30)
                this.getInput(INPUT.TABLE).appendField(field, FIELD.TABLE)
            }
        }
    },

    accept: async function (visitor) {
        await visitor.visitOutputAddTableBlock(this);
    },

    /**
     * If the datasets (image collections and feature collections) have changed update
     * the modal to show those new datasets.
     */
    updateShape_: function() {
        const surroundParent = this.getSurroundParent()

        if(surroundParent && surroundParent.isEnabled() === false) {
            return
        }

        if(this.isEnabled() === false) {
            return
        }
        if(this.hasStateChanged('dataset_type')) {
            this.updateBlockBasedOnDatasetType()
        }

        if(!this.hasStateChanged('global_datasets')) {
            return;
        }

        const datasets = this.getState('global_datasets',[]);

        if(this.getField(FIELD.TABLE)) {
            this.getField(FIELD.TABLE).setModalData('datasets', datasets);
        }

        if(this.hasStateChanged('global_datasets') && this.fieldExists(FIELD.TABLE) && this.getState('dataset_type') !== 'vector') {
            const modalData = this.getFieldValue(FIELD.TABLE)
            if(modalData) {
                this.checkDefinedVariablesAreValid()
            }
        }
    },

    onDispose: function() {
        // fixes memory leak
        if(this.areaSubscription_) {
            this.areaSubscription_.unsubscribe();
        }

        if(this.collectionSubscription_) {
            this.collectionSubscription_.unsubscribe();
        }

        if(this.shapeSubscription_) {
            this.shapeSubscription_.unsubscribe();
        }
    },
    ebxValidate: function(tooltips) {
        const modalField = this.getFieldValue(FIELD.TABLE)

        this.setWarningText(null, 'duplicate_table_rows')
        this.setWarningText(null, 'no_areas_defined')
        this.setWarningText(null, 'no_shapes_defined')
        this.setWarningText(null, 'no_variables_defined')
        this.setWarningText(null, 'no_variable_dates_named')
        this.setWarningText(null, 'no_variable_properties_named')
        this.setWarningText(null, 'no_variable_aggregation')
        this.setWarningText(null, NON_BLOCKING_BLOCKLY_WARNING_PREFIX + 'grouping_by_composite')
        this.setWarningText(null, 'invalid_dataset_with_variable')

        if (!modalField) {
            return true
        }

        if (!modalField.createdRows) {
            return true
        }

        if (this.getState('dataset_type') !== 'raster') {
            return true
        }

        const statisticRows = modalField.createdRows.filter(row => ['statistic','area-calculation','feature-attribute'].indexOf(row.displayType) >= 0)
        const groupinFeatureRows = modalField.createdRows.filter(row => ['group-feature-collection'].indexOf(row.displayType) >= 0)
        const groupinImageRows = modalField.createdRows.filter(row => ['group-image-collection'].indexOf(row.displayType) >= 0)
        
        if(statisticRows.length === 0) {
            return this.setWarningText(tooltips['no_variables_defined'] || 'Add at least one value', 'no_variables_defined')
        }
        if(modalField.createdRows.filter(defVar => defVar.dates.length === 0 && defVar.type === 'image_collection').length > 0) {
            return this.setWarningText(tooltips['no_variable_dates_named'] || 'Add a date for the values in your table', 'no_variable_dates_named')
        }
        if(modalField.createdRows.filter(defVar => defVar.properties.length === 0 && defVar.displayType !== 'area-calculation' && defVar.displayType !== 'group-feature-collection').length > 0) {
            return this.setWarningText(tooltips['no_variable_properties_named'] ||  'Finish adding your value', 'no_variable_properties_named')
        }
        if(modalField.createdRows.filter(defVar => defVar.displayType === 'statistic' && ['',undefined, null].indexOf(defVar.aggregation) >= 0).length > 0) {
            return this.setWarningText(tooltips['no_variable_aggregation'] ||  'Please choose the statistic to calculate on all values', 'no_variable_aggregation')
        }
        
        // Check all group rows have at least 1 shape defined
        if(groupinFeatureRows.length > 0) {
            for(let i=0; i < groupinFeatureRows.length; i++) {
                const groupFeatureRow = groupinFeatureRows[i]
                let areas = AreaService.getAreasForCollection(groupFeatureRow.dataset)
                let collection = AreaService.getCollectionById(groupFeatureRow.dataset)
                if(collection) {
                    if(areas.length === 0) {
                        return this.setWarningText(tooltips['no_areas_defined'] ||  'No areas defined for chosen grouping selection', 'no_areas_defined')
                    }

                    for(let j=0; j < areas.length; j++) {
                        const area = areas[j]
                        if (area.limitedArea === true) { break } //If feature view, skip the validation as not relevant 
                        const shapes = AreaService.getShapesForArea(area.id)
                        if(shapes.length === 0) {
                            return this.setWarningText(tooltips['no_shapes_defined'] ||  'Please define at least one shape in your area of interest', 'no_shapes_defined')
                        }
                    }
                }
            }
        }
        // check for no duplicated variables
        const keys = modalField.createdRows.reduce((a,row) => {
            const keyToString = Object.assign({}, row)
            delete keyToString.id
            const key = JSON.stringify(keyToString)
            a[key] = a[key] ? a[key] + 1 : 1
            return a
        },{})
        const duplicates = Object.values(keys).filter(v => v > 1)
        if(duplicates.length > 0) {
            return this.setWarningText(tooltips['duplicate_table_rows'] ||  'There appears to be duplicate statistic or rows. Please remove these.', 'duplicate_table_rows')
        }

        // Check if thematic groups are used with an invalid composite method
        if(groupinImageRows.length > 0) {
            const imageCollections = keyBy(this.getState('image_collections',[]),'id');
            let firstCompositeMethod = null
            const invalidDatasets = groupinImageRows.filter(row => {
                if(imageCollections[row.dataset] && ['sum','mean'].indexOf(imageCollections[row.dataset].compositeMethod) >= 0){
                    if(firstCompositeMethod === null) {
                        firstCompositeMethod = imageCollections[row.dataset].compositeMethod
                    }
                    const thematicProps = row.properties.filter(property => property['ebx:datatype'] === 'thematic' && property['ebx:origin'] !== 'user')
                    return thematicProps.length > 0
                }
                return false
            })
            if (invalidDatasets.length > 0) {
                const message = tooltips['invalid_composite_thematic'] || 'Using a thematic band that has been aggregated using {method} may produce invalid results. Consider aggregating images using mode.'
                firstCompositeMethod = firstCompositeMethod === null ? 'an invalid method' :  firstCompositeMethod.toUpperCase()
                return this.setWarningText(message.replace('{method}', firstCompositeMethod), NON_BLOCKING_BLOCKLY_WARNING_PREFIX + 'grouping_by_composite')
            }
        }

        // Check Variables
        const datasets = this.getState('global_datasets')
        const rowErrors = validateGlobalVariableUsageOnDataset(datasets, modalField.createdRows, tooltips, this)
        if(rowErrors.length > 0) {
            const message = tooltips['invalid_dataset_with_variable'] || 'Workflows using a date variable cannot use individual dates'
            return this.setWarningText(message, 'invalid_dataset_with_variable')
        }
        
        return true
    },
    isTopBlock() {
        return this.getParent() === null
    },
    renameDefaultDataset(previousId, newBlockId) {
        
        const modalData = this.getFieldValue(FIELD.TABLE)
        if(modalData && Array.isArray(modalData.createdRows)) {
            modalData.createdRows = modalData.createdRows.map(v => {
                if(v.dataset.slice(0, previousId.length) === previousId) {
                    v.dataset = v.dataset.replace(previousId, newBlockId)
                }
                return v
            })
            this.setFieldValue(modalData, FIELD.TABLE)
            this.checkDefinedVariablesAreValid()
        }
    },
    checkDefinedVariablesAreValid() {
        const modalData = this.getFieldValue(FIELD.TABLE)

        if (this.getState('dataset_type') !== 'raster') {
            return;
        }

        if (!modalData) {
            return;
        }
            
        const filteredDefinedVariables = []
        const datasets = this.getState('global_datasets')
        if(datasets === undefined || datasets === null) {
            return 
        }
        const featureCollections = datasets.filter(d => d.type === 'feature_collection')
        const featureCollectionIds = Array.isArray(featureCollections) ? featureCollections.map(f => f.id) : []
        const validDatasets = keyBy(datasets,'id')

        
        modalData.createdRows.forEach(definedVariable => {

            if(definedVariable.type === 'image_collection' && validDatasets[definedVariable.dataset]) {
                const useDefinedVariable = Object.assign({}, definedVariable)
                const currentDataset = validDatasets[definedVariable.dataset]

                if(currentDataset.multi_image_temporal?.temporal) {
                    const currentDatasetDates = [...(currentDataset.multi_image_temporal.temporal || []).map(date => date.value), ...TABLE_DATE_CONSTANTS.map(d => d.value)]
                    const standardisedDates = standariseVariableDates(definedVariable.cadence, currentDataset.multi_image_temporal.cadence, definedVariable.dates)
                    useDefinedVariable.dates = standardisedDates.filter(d => currentDatasetDates.indexOf(d) >= 0)
                }
                     
                if(currentDataset.bands) {
                    const currentDatasetProperties = (currentDataset.bands || []).map(property => property['ebx:name'])
                    const bandsByName = keyBy(currentDataset.bands, 'ebx:name')
                    const newProperties = definedVariable.properties
                        .filter(d => currentDatasetProperties.indexOf(d['ebx:name']) >= 0)
                        .map(d => {
                            // Map the keys in the correct order as current property
                            const currentKeys = Object.keys(d)
                            const newObject = bandsByName[d['ebx:name']]
                            return currentKeys.reduce((a,key) => {
                                a[key] = newObject[key]
                                return a
                            },{})
                        })
                    useDefinedVariable.properties = newProperties
                }
                
                if(useDefinedVariable.cadence) {
                    useDefinedVariable.cadence = currentDataset.multi_image_temporal.cadence
                }

                filteredDefinedVariables.push(useDefinedVariable)
            }
            if(definedVariable.type === 'feature_collection') {
                if(featureCollectionIds.indexOf(definedVariable.dataset) >=0 || definedVariable.dataset === MAP_STUDY_AREA_COLLECTION_ID){
                    filteredDefinedVariables.push(definedVariable)
                }
            }
        })

        modalData.createdRows = filteredDefinedVariables
        this.setFieldValue(modalData, FIELD.TABLE)
    }
}; 