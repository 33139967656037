/**
 * JS class that contains the table preview in object form, enabling user to see the table.
 * 
 * Simplified version of the old version, removing the need to deal with pagination, column groups etc. see this if we want to add them back in.
 * 
 * Returns the table with getTable() method
 */
export class TablePreviewCreator {
    /**
     * Array of objects containing the statistics to be displayed in the table
     * @type {Array}
     * @property {string} name - name of the statistic e.g. "NDVI (mean)"
     * @property {string} aggregation - aggregation method e.g. "mean"
     * @property {string} property - property name e.g. "NDVI"
     */
    statistics = [];

    /**
     * Array of objects containing the groups to be displayed in the table
     * @type {Array}
     * @property {string} name - name of the group e.g. "LOSS"
     * @property {Array} values - array of values e.g. ["loss", "gain"]
     * @property {Array} [dates] - Optional array of dates
     */
    groups = [];

    /**
     * Maximum number of rows to display in the table
     * @type {int}
     * @default 10
     */ 
    maxRows = 10

    /**
     * Constructor for the TablePreviewCreator class
     * @param {int} maxRows - maximum number of rows to display
     */
    constructor(maxRows = 10) {
        this.statistics = [];
        this.groups = [];
        this.maxRows = maxRows;
    }

    /**
     * Generates a default value for the table
     * @returns {string}
     */
    generateDefaultValue = () => "-";

    /**
     * Adds a statistics to the preview
     * @param {string} property - property name e.g. "NDVI"
     * @param {string} aggregation - aggregation method e.g. "mean"
     * @param {Array[]} [dates] - Optional array of dates
     * @returns {TablePreviewCreator} - returns the table preview object
     */
    addStatistic(property, aggregation, dates=[]) {
        // if there are dates, recursively add a statistic for each date
        if (dates.length > 0) {
            dates.forEach(date => {
                this.addStatistic(`${date} ${property}`, aggregation);
            });
            return this;
        }

        this.statistics.push({
            name: `${property} (${aggregation})`,
            aggregation,
            property,
        });

        return this;
    }

    /**
     * Adds a new grouping method to the table, creating a new group
     * @param {string} property - property name e.g. "LOSS"
     * @param {array} values - array of values e.g. ["loss", "gain"]
     * @param {array} [dates] - Optional array of dates
     * @returns {TablePreviewCreator} - returns the table preview object
     */
    addGroup(property, values, dates=[]) { 
        if(!values.length) {
            values = Array.from({length: this.maxRows}, this.generateDefaultValue);
        }

        this.groups.push({
            name: property,
            values,
            dates
        })

        return this;
    }

    /**
     * Computes the maxmimum number of rows possible if you were to calculate the cartesian product
     * @returns {int} - maximum number of rows
     */
    computeMaxPossibleRows() {
        if (this.groups.length === 0) {
            return 1;
        }

        // compute cartesian product
        return this.groups.reduce((count, group) => {
            return count * group.values.length;
        }, 1)
    }

    /**
     * Gets the max number of rows to display in the table
     * @returns {int} - maximum number of rows
     */
    getMaxRows() {
        return Math.min(this.maxRows, this.computeMaxPossibleRows());
    }

    /**
     * Method for getting the values for a group, with a date column
     * @param {Object} group
     * @returns {Object} - object containing two keys, one for the dates, one for values
     */
    getGroupWithDates(group) {
        const { values, dates } = group;

        var rows = 0;
        var class_index = 0;
        var date_index = 0;

        var n_classes = values.length;
        var n_dates = dates.length;

        const col_dates = [];
        const col_values = [];

        while(rows < this.getMaxRows()) {
            class_index++;

            if (class_index == n_classes) {
                class_index = 0;
                date_index++;
            }

            if (date_index == n_dates) {
                // reset date index
                date_index = 0;
            }

            col_dates.push(dates[date_index]);
            col_values.push(values[class_index]);

            rows++;
        }

        return {
            dates: col_dates,
            values: col_values,
            name: `${group.name} Value`,
            date_name: `${group.name} Date`
        }
    }

    /**
     * Gets a group without dates
     * @param {Object} group
     * @returns {Object} - object containing one key, values
     */
    getGroupWithoutDates(group) {
        const { values } = group;

        const maxRows = this.getMaxRows();

        var iterations = Math.ceil(maxRows / values.length);

        const col_values = [];

        for (let i = 0; i < iterations; i++) {
            col_values.push(...values);
        }

        // limit to max rows
        return {
            values: col_values.slice(0, maxRows),
            name: group.name
        }
    }

    /**
     * Gets the values for a statistic
     * @param {Object} statistic
     * @returns {Object} - object containing one key, values
     */
    getStatistic(statistic) {
        const { name } = statistic;

        const maxRows = this.getMaxRows();

        const col_values = Array.from({length: maxRows}, this.generateDefaultValue);

        return {
            values: col_values,
            name
        }
    }

    /**
     * Computes the table and returns it
     * @returns {Object} - object containing the table
     */
    computeTable() {
        var table = {};

        if (this.statistics.length === 0) {
            return table;
        }

        if (this.groups.length === 0) {
            // no groups, just statistics
            this.statistics.forEach(statistic => {
                const stat = this.getStatistic(statistic);
                table[stat.name] = stat.values;
            });

            return table;
        }

        table = this.groups.reverse().reduce((table, group) => {
            if (group.dates.length > 0) {
                // group with dates
                const { dates, values, name, date_name } = this.getGroupWithDates(group);

                table[date_name] = dates;
                table[name] = values;

                return table;
            } 

            // group without dates
            const { values, name } = this.getGroupWithoutDates(group);

            table[name] = values;

            return table;
        }, table);

        // add statistics
        table = this.statistics.reduce((table, statistic) => {
            const { values, name } = this.getStatistic(statistic);

            table[name] = values;

            return table;
        }, table);

        return table;
    }

    /**
     * Gets the table
     * @returns {Object} - object containing the table
     */
    getTable() {
        return this.computeTable();
    }


}