// /*
//  * ---------------------------------------------------------------------------
//  * COMMERCIAL IN CONFIDENCE
//  *
//  * (c) Copyright Quosient Ltd. All Rights Reserved.
//  *
//  * See LICENSE.txt in the repository root.
//  * ---------------------------------------------------------------------------
//  */

// /**
//  * A front-end service to manage access to content info stored on ebx-core.
//  * This includes validation warnings and tooltip text. 
//*

import { coreDB } from "@/firebase.js";
import sizeof from "js-sizeof";
import {reactive} from 'vue'

// Service object
let service = reactive({
    initialised: false,
    block : {}, //Tooltips stored by block 
    file: {}, //Errors & warnings stored by file they are in (e.g workspace validation), 
});
/**
 * A service to manage access to content info stored on ebx-core.
 * Front-end code.
 */
const ContentService = {
    
    isInitialised: () => {
        return service.initialised;
    },

    init: async () => {
        const start = Date.now();
        console.info("Initialising ContentService");
        
        //Access to tooltips
        let tooltips = coreDB.collection("tooltip_content");
        let snapshotTooltips = await tooltips.get();
        console.log("Loading tooltip content");
        snapshotTooltips.forEach((doc) => { 
            let id = doc.id
            let data = doc.data()
            service.block[id] = data
        });

        //Access to errors and warning
        let validation = coreDB.collection("errors_warnings_content");
        let snapshotValidation = await validation.get();
        console.log("Loading validation content");
        snapshotValidation.forEach((doc) => { 
            let id = doc.id
            let data = doc.data()
            service.file[id] = data
        });


        service.initialised = true;

        const duration = Date.now() - start;
        console.info(`Finished loading content; took ${duration}ms`);
        console.info("ContentService initialised");
        console.info(`Service object is ${sizeof(service)} bytes`);
    },

    /**
     * Get the about block url associated with a block as an object, returns an empty string if no url is found
     * @returns {String}
     */
    getAboutBlockUrl(id) {
        if (!service.block[id]) {
            return ''
        }
        var aboutBlockUrl = service.block[id]['about_block_url']
        if (!aboutBlockUrl) {
            return ''
        }
        return aboutBlockUrl
    },

    /**
     * Gets the warning associated with a file as an object, returns an empty object if no warnings are found
     * @param {string} file_name
     * @returns {Object}
     */
    getWarningText(id) { 
        var warnings = service.file[id]
        if (!warnings) {
            return {}
        }
        return warnings
    },

    /**
     * Get block name in plain text to be substituted into validation warnings.
     * To make things a bit cleaner, all plain text name fields are called 'plain_name'
     * @param {*} file_name
     * @returns {String}
     */
    getBlockName(id) { 
        var blockName = service.file[id]['plain_name']
        return blockName
    }
};

export {
    ContentService
}


