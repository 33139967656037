<template>
    <button :class="buttonClasses" :title="titleText" @click="handleClick">
        <template v-if="saving">
            <div class="saved-container">
                <div>
                    {{ savingText }}
                </div>
            </div>
        </template>
        <template v-else-if="isReadOnly">
            <div class="read-only">
                Save as
            </div>
        </template>
        <template v-else-if="isSaved">
            <div class="saved-container">
                <div>
                    {{ savedText }}
                </div>
            </div>
        </template>
        <template v-else>
            <slot>
                Save
            </slot>
        </template>
    </button>
</template>

<script>

import { PROJECT_STATE } from '../mixins/projectMixin'

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        disabledTitle: {
            type: String,
            default: "Upgrade to Earth Blox Enterprise to access this functionality"
        },
        state: {
            type: String,
            required: true
        },
        savedText: {
            type: String,
            default: 'Saved'
        },
        saving: {
            type: Boolean,
            default: true
        },
        savingText: {
            type: String,
            default: 'Saving'
        }, 
        isReadOnly: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'click'
    ],
    computed: {
        buttonClasses() {
            const classNames = ['ebx-button', 'ebx-button--primary']
            if(this.disabled || this.isSaved) {
                classNames.push('greyed-out')
            }
            return classNames
        },
        titleText() {
            if(this.disabled) {
                return this.disabledTitle
            }
            return this.title
        },
        isSaved() {
            if (this.isReadOnly) {
                return false
            } 
            return this.state === PROJECT_STATE.SAVED
        },
    },
    methods: {
        handleClick(evt) {
            if(this.isReadOnly || !this.disabled && !this.saved && !this.saving) {
                this.$emit('click', evt)
            }
        }
    }
}
</script>