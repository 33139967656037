<!-- A dialogue box that warns the user ahead of their subscription ending -->
<template>
    <div>
        <md-dialog 
            :md-close-on-esc="false" 
            :md-click-outside-to-close="false"
            v-model:md-active="showTrialWarning" 
            class="dialog ebx-dialog ebx-dialog modal-ms">
            <md-dialog-title>
                <div class="top-actions--container">
                    <p class="ebx-header-2 top-actions--title">
                        {{modalTitle}}
                    </p>
                    <div class="close-button">
                        <md-button class="md-primary" @click="skipForNow"><md-icon>close</md-icon></md-button>
                    </div>
                </div>
            </md-dialog-title>
            <md-dialog-content>
                <p class="ebx-primary">To continue using Earth Blox after your trial has ended, add your payment details. You won't be charged until the end of your trial period.</p>
            </md-dialog-content>
            <md-dialog-actions>
                <button class="ebx-button ebx-button--secondary" @click="skipForNow">SKIP FOR NOW</button>
                <form v-if="firebaseUser" :action="stripePortalKey">
                    <input type="hidden" name="prefilled_email" :value="firebaseUser.email"/>
                    <input value="ADD PAYMENT DETAILS" type="submit" class="ebx-button ebx-button--primary" />
                </form>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
/*
    * ---------------------------------------------------------------------------
    * COMMERCIAL IN CONFIDENCE
    * 
    * (c) Copyright Quosient Ltd. All Rights Reserved.
    * 
    * See LICENSE.txt in the repository root.
    * ---------------------------------------------------------------------------
*/
import stripeMixin from "@/components/mixins/stripeMixin";
import authMixin from "@/components/mixins/authMixin";
import { orderBy } from 'lodash';
import { auth } from '@/firebase.js';

export default {
    name: "TrialWarning",
    mixins: [stripeMixin, authMixin],
    data() {
        return {
            subscription: null,
            skippedWarning: true,
            modalTitle: "Your trial is ending soon",
            firebaseUser: null,
        }
    },
    async mounted() {
        this.subscription = await this.getLatestSubscription();
        this.firebaseUser = auth.currentUser;

        if (this.subscription) {
            // subscribe to changes in the user document
            this.unsubscribeUserDoc = this.userRef?.onSnapshot(async (doc) => {
                let sub_id = this.subscription.id;
                if (sub_id) {
                    if (!doc.data().trialWillEndAcknowledged) {
                        // create the trialWillEndAcknowledged map if it doesn't exist
                        await this.userRef.set({
                            trialWillEndAcknowledged: {
                                [sub_id]: false
                            }
                        }, { merge: true });
                        this.skippedWarning = false;
                    } else {
                        if (typeof doc.data().trialWillEndAcknowledged[sub_id] === 'boolean') {
                            this.skippedWarning = doc.data().trialWillEndAcknowledged[sub_id];
                        } else {
                            // create the boolean flag named after the sub_id
                            await this.userRef.set({
                                trialWillEndAcknowledged: {
                                    [sub_id]: false
                                }
                            }, { merge: true });
                            this.skippedWarning = false;
                        }
                    }
                }
            }, error => {
                console.error('trial warning', error);
            });
        }
    },
    methods: {
        /**
         * Gets the latest stripe subscription from the collection of subscriptions
         */
        getLatestSubscription() {
            if(!this.isStripeActive) {
                return null;
            }

            const subscriptionCollection = this.userRef?.collection("stripeSubscriptions");
            if (!subscriptionCollection) {
                return null;
            }

            let subscriptionDocs = []
            return subscriptionCollection.get().then((snapshot) => {
                if (snapshot.empty) {
                    return null;
                }
                snapshot.forEach(doc => {
                    subscriptionDocs.push(doc.data())
                })
                
                // return last created doc in array
                return orderBy(subscriptionDocs, 'created', 'desc')[0]

            });
        },
        /**
         * Adds a skippedWarning flag
         */
        skipForNow() {
            this.skippedWarning = true;

            // update the firebase doc map with the sub_id and set the flag to true
            this.userRef.set({
                trialWillEndAcknowledged: {
                    [this.subscription.id]: true
                }
            }, { merge: true });
        },
        /**
         * Pushes the user to the subscription page
         */
        addPaymentDetails() {
            this.$router.push({ name: "MyAccount-Subscription" });
        }
    },
    computed: {
        /**
         * Shows the warning if the user has less than 3 days, they haven't skipped the dialog already and subscriptions are active
         */
        showTrialWarning() {
            if (!this.subscription || !this.isTrial || !this.trialWarning) {
                return false;
            }
            return this.trialWarning.daysLeft <= 3 && !this.skippedWarning && this.isStripeActive;
        },
        /**
         * Checks their latest subscription is a trial
         */
        isTrial() {
            return this.subscription?.status === "trialing";
        },
        /**
         * Generates an object with 2 keys, expiry date and days left
         */
        trialWarning() {
            if (!this.subscription || !this.isTrial) {
                return {
                    daysLeft: 0,
                    expiryDate: ""
                };
            }

            const subscription = this.subscription;
            // seconds timestamp
            const trial_end = subscription.trial_end;

            const expiryDate = new Date(trial_end * 1000);
            const today = new Date();

            const daysLeft = Math.floor((expiryDate - today) / (1000 * 60 * 60 * 24));

            return {
                daysLeft,
                expiryDate: expiryDate.toLocaleDateString()
            };
        },
    },
    watch: {
        trialWarning(newVal) {
            if (newVal.daysLeft === 1) {
                this.modalTitle = newVal.daysLeft + ' trial day remaining';
            } else if (newVal.daysLeft < 1) {
                this.modalTitle = "Less than a day remaining";
            } else if (newVal.daysLeft <= 3) {
                this.modalTitle = newVal.daysLeft + ' trial days remaining';
            } else {
                this.modalTitle = "Your trial is ending soon";
            }
        }
    },
    beforeUnmount () {
        // unsubscribe from the user document
        this.unsubscribeUserDoc();
    }
}

</script>