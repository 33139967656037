<template>
    <div class="date-variable">
        <!-- creator version -->
        <VariableDetailComponent
            varType="date"
            descHelperText="Add a description to provide guidance for users selecting a date."
            :variable="variable"
            :hasValue="hasValue"
            :isEditing="isEditingVariable"
            @cancel-editing="handleEditCancel"
            @edit-variable="handleEditVariable"
            @save-variable="handleVariableSaved"
            >
                <md-datepicker placeholder="Select default date" :model-value="currentValue" :md-disabled-dates="handleDisabledDates" @update:modelValue="onDateChange">
                    <label>Default date</label>
                </md-datepicker>
        </VariableDetailComponent>
    </div>
</template>

<script>
import VariableDetailComponent from '@/components/Variables/VariableDetail/VariableDetailComponent.vue'
import { VariablesService } from '@/services/variable.service';
import moment from 'moment';
import { DateVariable } from '../../../variables/date';

export default {
    name: 'AreaVariableCreator',
    components: {
        VariableDetailComponent
    },
    props: {
        variable: {
            type: Object,
            required: true,
        },
        isNew: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    emits: [
        'save-variable',
        'edit_variable',
        'save-variable'
    ],
    data() {
        return {
            isEditingVariable: false,
            currentValue: this.variable.value,
        }
    },
    created() {
        if (this.isNew) {
            this.isEditingVariable = true;
        }
    },
    computed: {
        variableId() {
            return this.variable.id;
        },
        hasValue() {
            return this.currentValue !== null && this.currentValue !== undefined;
        }
    },
    methods: {
        handleEditCancel() {
            this.$emit('cancel-editing');
            this.currentValue = this.variable.value;
            this.isEditingVariable = false;
        },
        handleEditVariable() {
            this.$emit('edit-variable');
            this.isEditingVariable = true;
        },
        onDateChange(date) {
            const formattedDate = moment(date).format('YYYY-MM-DD').toString()
            this.currentValue = formattedDate
        },
        handleVariableSaved(variable) {
            this.isEditingVariable = false;
            variable.value = this.currentValue;
            this.$emit('save-variable', variable);
        },
        handleDisabledDates(date) {
            const variable = VariablesService.getVariableById(this.variableId)
            if(variable instanceof DateVariable) {
                return !variable.isDateSelectable(date)
            }
            return false
        }
    }
}
</script>