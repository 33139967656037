export default [
  'click',
  'dblclick',
  'mousedown',
  'mouseup',
  'onClick',
  'onDblclick',
  'onMousedown',
  'onMouseup'
]
