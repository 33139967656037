/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["MODIFIER_ORBIT_TITLE"] = "Select orbit";
Blockly.Msg["MODIFIER_ORBIT_OPTION_LABEL"] = "Orbit: ";

export default Blockly.Msg;