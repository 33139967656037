/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/**
 * This module provides access to firebase configuration and services for the frontend UI.
 *
 * Note that there are sections of configuration below which are filled in by scripts.
 * These are run manually during local testing, or by the deploy script during deployment.
 *
 * The exports are imported into front end code to provide access to Firebase Functions deployed in the cloud.
 * Any logging performed here will appear in the user's browser's JS console.
 * Note also that therefore we have no control over environment variables.
 */

// Import Firebase production app:
import firebase from 'firebase/compat/app';
// Import the individual services that we will use in Firebase:
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

console.log("firebase-init");

// Name of the Firebase app with access to ebx-core services
const FIREBASE_CORE_NAME = 'core';

// ----------------------------------------------------------------------------
// Firebase app configuration
// ----------------------------------------------------------------------------
// XXX This config section will be filled in by insertFirebaseConfig.sh
const firebaseConfig = {
  "projectId": "ebx-trial-dev",
  "appId": "1:1056791888046:web:7923ad5946fa5236a66d3f",
  "databaseURL": "https://ebx-trial-dev-default-rtdb.europe-west1.firebasedatabase.app",
  "storageBucket": "ebx-trial-dev.appspot.com",
  "locationId": "europe-west2",
  "apiKey": "AIzaSyDk0MRqwiLVvxp2yAAeBx8yJ5VrP-N2_sU",
  "authDomain": "ebx-trial-dev.firebaseapp.com",
  "messagingSenderId": "1056791888046",
  "measurementId": "G-1NB3LZ031D"

};

// XXX This config section will be filled in by insertFirebaseConfig.sh
const firebaseCoreConfig = {
  "projectId": "ebx-core",
  "appId": "1:629678258428:web:9e56bf8665898a06983f79",
  "storageBucket": "ebx-core.appspot.com",
  "locationId": "europe-west2",
  "apiKey": "AIzaSyB6PuTvJ6koLP088rpSv4NbhrC46wvzmIs",
  "authDomain": "ebx-core.firebaseapp.com",
  "messagingSenderId": "629678258428",
  "measurementId": "G-HL5PCTYWDZ"

};

// XXX The value of the maps API key will be filled in by setMapsApiKey.sh
const mapsApiKey = '';

// Change authDomain if necessary to match our custom domain
const CUSTOM_DOMAIN_MAPPINGS = {
    "ebx-trial-dev": "dev.earthblox.io",
    "ebx-app": "app.earthblox.io",
}

const LOAD_BALANCER_DOMAINS = { // maps the projectId to a load balancer domain. Used by the functions so that the FE calls the LB instead of the function directly
    "ebx-trial-dev": "dev.lb.earthblox.io",
    "ebx-staging-server": "staging.lb.earthblox.io",
    "ebx-standby": "standby.lb.earthblox.io",
    "ebx-app": "lb.earthblox.io"
}

if (Object.hasOwn(CUSTOM_DOMAIN_MAPPINGS, firebaseConfig.projectId)) {
    firebaseConfig.authDomain = CUSTOM_DOMAIN_MAPPINGS[firebaseConfig.projectId]
}

// Initialize Firebase apps
firebase.initializeApp(firebaseConfig);
const coreApp = firebase.initializeApp(firebaseCoreConfig, FIREBASE_CORE_NAME);

// ----------------------------------------------------------------------------
// Service references
// ----------------------------------------------------------------------------

// Project-specific services
const projectId = firebaseConfig.projectId;
const app = firebase.app();
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const EmailAuthProvider = firebase.auth.EmailAuthProvider;
var LOAD_BALANCER_DOMAIN = null
if (LOAD_BALANCER_DOMAINS[projectId]){
    LOAD_BALANCER_DOMAIN = `https://${LOAD_BALANCER_DOMAINS[projectId]}`;
}
// ----------------------------------------------------------------------------
// Environment setup
// ----------------------------------------------------------------------------
// - settings for LTI projects
// - use emulators in dev mode
// ----------------------------------------------------------------------------
// Is this an LTI-integrated project? Note we don't include UOE in this, which has a MOOC organisation but is primarily a normal install.
let requiresLTIIntegration = projectId==="ebx-mooc" || projectId==="eb-edina"; // || projectId==="ee-uoe";
console.debug(`firebase.js setup: projectId ${projectId} requiresLTIIntegration ${requiresLTIIntegration}`);
// If this is an LTI-integrated project, set auth persistence to none; otherwise Firebase tries
// to persist the auth info locally on the broswser, which requires third party cookies
// when running in an IFrame within FutureLearn. Session does not work with Node.js
// See https://firebase.google.com/docs/auth/web/auth-state-persistence
// if (requiresLTIIntegration) {
//     auth.setPersistence(firebase.auth.Auth.Persistence.NONE);
// }

// Use the db and auth emulators if in development mode
if (process.env.NODE_ENV==="development" || location.hostname === "localhost") {
    console.info("Localhost environment: Using firestore and auth emulators");
    db.useEmulator("localhost", 8080);
    auth.useEmulator("http://localhost:9099/");
    storage.useEmulator("localhost", 9199);
    // XXX Note we enable force long polling in dev/emulated environment, in order to
    // make e2e work with Firebase.
    // See https://github.com/earthblox-io/earthbloxnextgen/commit/6f8e83744a36fd7448b987c598018098ba0c97b5
    db.settings({
        // Turn off detection, which is enabled by default from Firebase 9.22.0
        experimentalAutoDetectLongPolling: false,
        experimentalForceLongPolling: true,
        merge: true,
    });
}


// Firestore collections
const earthbloxCollection = db.collection('earthblox')
const googleMarketPlaceCollection = db.collection('googleMarketPlace')
const organisationsCollection = db.collection('organisations')
const usersCollection = db.collection('users')
const sharedCollection = db.collection('shared')
const apiVersionCollection = db.collection('api_version');

// EBX Core services
const coreDB = firebase.firestore(coreApp);
const coreStorage = firebase.storage(coreApp);

// NB This breaks emulation so only do it if we're not in a dev/localhost environment
if (process.env.NODE_ENV!=="development" && location.hostname !== "localhost") {
    // The setting experimentalAutoDetectLongPolling should detect polling issues
    // and turn on long polling when necessary. However reports suggest it is not
    // entirely reliable and we may need to decide ourselves when to enable
    // experimentalForceLongPolling instead.
    console.info("Enabling experimentalAutoDetectLongPolling on ebx-core Firestore service")
    coreDB.settings({ experimentalAutoDetectLongPolling: true, merge: true });
}

export {
    EmailAuthProvider,
    db,
    auth,
    app,
    earthbloxCollection,
    googleMarketPlaceCollection,
    organisationsCollection,
    sharedCollection,
    usersCollection,
    apiVersionCollection,
    storage,
    firebaseConfig,
    mapsApiKey,
    firebase,
    // Export ebx-core services
    coreDB,
    coreStorage,
    projectId,
    LOAD_BALANCER_DOMAIN
}
