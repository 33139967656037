import {VARIABLE_BLOCKLY_ERROR_PREFIX,NON_BLOCKING_BLOCKLY_WARNING_PREFIX} from '@/constants/nextGenConstants'

// Helper function to modify data
function removeVariableWarnings(blockWarnings) {
    const variableWarning = VARIABLE_BLOCKLY_ERROR_PREFIX;
    return blockWarnings
        .filter(item => !item.key.startsWith(variableWarning))
        .filter(item => !item.key.startsWith(NON_BLOCKING_BLOCKLY_WARNING_PREFIX));
        
}

const state = {
    blockWarnings:[],
}

//GET THINGS FROM STATE
//USAGE: this.$store.getters
const getters = {
    warningsPresent: (state) => {
        //Remove warnings originating from variables 
        const warnings = removeVariableWarnings(state.blockWarnings); 
        //If there are any warnings leftover, set to true
        if (warnings.length > 0 ) { 
            return true
        } else { 
            return false
        }
    },
} 

//SETS THE STATE 
//USAGE: this.$store.commit('setCount', value)
const mutations = {
    setBlockWarnings(state,warnings) { 
        state.blockWarnings = warnings
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}