/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import { 
    compareAndUpdateBlockDropdown,
    createThematicArrayDropdown,
    addDropdownInput,
    setDropdownPreviousValue,
    removeFieldFromInput
} from '@/blocks/helper_functions'
import {FieldNoTrimDropdown} from '@/fields/FieldNoTrimDropdown';
import {NO_DATA_DROPDOWN_VALUE, EBX_BAND_NAME,EBX_BAND_TYPE,EBX_THEMATIC_CLASSES,THEMATIC, NUMERIC } from '@/constants/nextGenConstants';
import {AbstractBlock, AbstractFieldHelpers} from '@/blocks/_abstractBlock';
import { ContentService } from '@/services/content.service';

var modifierMaskJson = {
    "type": "modifier_mask",
    "message0": `%1 %2 %3 %4`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "mask_title",
            "text": "%{BKY_MODIFIER_MASK_BLOCK_TITLE}",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy",
        },
        {
            "type": "field_label_serializable",
            "name": "dataset_label",
            "text": "%{BKY_MODIFIER_MASK_DEFINITION_LABEL}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": "dataset",
            "options": [["Select an option", NO_DATA_DROPDOWN_VALUE]], //These options will come from the define block
        },
    ],
    
    "previousStatement": null,
    "nextStatement": null,
    "style": "secondaryFilter",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['modifier_mask'] = {
    operators: [
        ["equals","eq"],
        ["not equal to", "neq"],
        ["is greater than","gt"],
        ["is greater than or equal to", "gte"],
        ["is less than","lt"],
        ["is less than or equal to", "lte"]
    ],
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    onInit: function() {
        this.jsonInit(modifierMaskJson);

        this.about_block_url = ContentService.getAboutBlockUrl('modifier_mask')
    },
    accept: async function (visitor) {
        console.log('accept modifier_mask visitor')
        await visitor.visitModifierMaskBlock(this);
    },
    updateShape_: function() {
        console.log('updateShape_ for MASK')

        if (this.hasStateChanged('datasets')) {
            let datasetOptions = this.getState('datasets')
            this.updateDropdownField(this, 'dataset', datasetOptions)
        }

        if(this.hasStateChanged('bandsDropdown')) {
            let bandsOptions = this.getState('bandsDropdown')
            if (this.getField("band_options")) {
                this.updateDropdownField(this, 'band_options', bandsOptions)
            } else {
                // add new input with bands dropdown
                this.appendDummyInput('options') //Add new input for options
                addDropdownInput(this, 'bands_input', Blockly.Msg.MODIFIER_MASK_BAND_LABEL, "band_options", bandsOptions, null, this.bandValidator, FieldNoTrimDropdown)
                this.getField("band_options").setValue(bandsOptions[0][1])
                this.moveInputBefore('bands_input','options')
            }
        }
    },
    
    ebxValidate: function(errors) {
        this.setWarningText(null, 'not_raster')
        const isRaster = this.getState('isRaster')
        if(!isRaster) {
            this.setWarningText(errors['not_raster'] || 'This block is only compatible with raster datasets.', 'not_raster')
            return
        }
    },

    bandValidator: function (newValue) {
        console.log('Bands validator, newValue ====' , newValue)
        let block = this.getSourceBlock()
        // check the type of band
        let datasetType = null
        let bandClasses = null
        // let bandValue = null
        if (block.getState('bands')) {
            const bands = block.getState('bands')
            for (let i = 0; i < bands.length; i++) {
                let thisBand = bands[i]
                if (thisBand[EBX_BAND_NAME] === newValue) {
                    datasetType = thisBand[EBX_BAND_TYPE]

                    bandClasses = thisBand[EBX_THEMATIC_CLASSES] ? 
                        thisBand[EBX_THEMATIC_CLASSES] :
                        null
                    break
                }
            }
        }

        if (bandClasses) {
            const values = Object.keys(bandClasses)
            // create classes dropdown
            let classesArray = []
            for (const classProps in bandClasses) {
                let bandClass = bandClasses[classProps]
                classesArray.push(bandClass['ebx:short_description'] || bandClass['description'])
            }

            block.classesDropdown = classesArray.length > 0 ? createThematicArrayDropdown(classesArray, values) : null

        }

        if (datasetType === THEMATIC && block.classesDropdown) {
            block.addClassBandFields(block, block.classesDropdown)
        }

        if (datasetType === NUMERIC ) {
            block.addIntegerBandFields(block)
        }
        return newValue
    },
    updateDropdownField(block, fieldName, fieldOptions) {
        block.getField(fieldName).updateOptions(fieldOptions)
        setDropdownPreviousValue(block, fieldName, block.getFieldValue(fieldName))
    },
    addClassBandFields: function(block, classDropdown) {
        // add operator and class dropdowns
        if (block.getInput('bands_input')) {
            let operationsInput = block.getInput('options')

            removeFieldFromInput(operationsInput, 'integer_field')
            
            block.getField('operator_dropdown') ?
                compareAndUpdateBlockDropdown(block, this.operators, 'operator_dropdown') :
                operationsInput.appendField(new FieldNoTrimDropdown(this.operators), 'operator_dropdown')
            
            block.getField('class_dropdown') ?
                compareAndUpdateBlockDropdown(block, classDropdown, 'class_dropdown'):
                operationsInput.appendField(new FieldNoTrimDropdown(classDropdown), 'class_dropdown')
        }
    },
    addIntegerBandFields(block, min=null, max=null) {
        // add operator and integer field
        if (block.getInput('bands_input')) {
            let operationsInput = block.getInput('options') 

            removeFieldFromInput(operationsInput, 'class_dropdown')
            
            block.getField('operator_dropdown') ?
                compareAndUpdateBlockDropdown(block, this.operators, 'operator_dropdown') :
                operationsInput.appendField(new FieldNoTrimDropdown(this.operators), 'operator_dropdown')
            
            if (block.getField('integer_field')) {
                let intField = block.getField('integer_field')

                if (intField.getMin() !== min){
                    intField.setMin(min)
                }
                if (intField.getMax() !== max) {
                    intField.setMax(max)
                }
            } else {
                operationsInput.appendField(new Blockly.FieldNumber(0, min, max), 'integer_field');
            }
        }
    }
};
