<template>
    <label class="ebx-switch" :class="{'ebx-switch--theme-dark':theme=='dark','ebx-switch--animated':animate}" @click.prevent="toggle">
        <input v-show="false" :id="id" :name="name" type="checkbox" :checked="computedValue" />
        <span v-if="animate" class="ebx-switch--slider" ref="slider"></span>
        <span class="ebx-switch--option ebx-switch--off" :class="optionClassValue('off')" ref="offState">{{offText}}</span>
        <span class="ebx-switch--option ebx-switch--on" :class="optionClassValue('on')" ref="onState">{{ onText }}</span>
    </label>
</template>

<script>
export default {
    name: 'EbxSwitch',
    data() {
        return {
            toggleState: false
        }
    },
    props: {
        offText: {
            type: String,
            default: 'Off'
        },
        onText: {
            type: String,
            default: 'On'
        },
        name: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        theme: {
            type: String,
            default: 'default'
        },
        value: {
            type: Boolean,
            default: null
        },
        modelValue: {
            type: Boolean,
            default: null
        },
        optionClass: {
            type: [String,Object],
            default: 'ebx-secondary'
        },
        animate: {
            type: Boolean,
            default: true
        },
        animateDuration: {
            type: Number,
            default: 0.2
        }   
    },
    emits: [
        'update:modelValue',
        'update'
    ],
    computed: {
        computedValue: {
            get() {
                return this.toggleState
            },
            set(value) {
                if(value === this.toggleState) return
                this.toggleState = value
                this.$emit('update:modelValue', value)
                this.$emit('update', value)
                if(this.animate) {
                    this.setSliderPositioning()
                }
            }
        },
    },
    watch: {
        modelValue: {
            handler(newValue) {
                this.toggleState = !!newValue
            },
            immediate: true
        },
        value: {
            handler(newValue) {
                this.toggleState = !!newValue
            },
            immediate: true
        }
    },
    methods: {
        toggle() {
            this.computedValue = !this.computedValue
        },
        optionClassValue(forOption) {
            let optionClasses = {}
            if(typeof this.optionClass === 'string') {
                this.optionClass.split(' ').forEach((optionClass) => {
                    optionClasses[optionClass] = true
                })
            } else {
                optionClasses = Object.assign({},this.optionClass)
            }
            if(forOption === 'on' && this.computedValue) {
                optionClasses['ebx-switch--option-active'] = true
            }
            if(forOption === 'off' && !this.computedValue) {
                optionClasses['ebx-switch--option-active'] = true
            }
            return optionClasses
        },
        setSliderPositioning() {
            if(this.animate) {
                this.$refs.slider.style.transition = `all ${this.animateDuration}s`

                this.$refs.slider.style.top = `${this.$refs[this.computedValue ? 'onState' : 'offState'].offsetTop}px`
                this.$refs.slider.style.left = `${this.$refs[this.computedValue ? 'onState' : 'offState'].offsetLeft}px`

                this.$refs.slider.style.width = `${this.$refs[this.computedValue ? 'onState' : 'offState'].offsetWidth}px`
                this.$refs.slider.style.height = `${this.$refs[this.computedValue ? 'onState' : 'offState'].offsetHeight}px`
            }
        
        }
    },
    mounted() {
        if(this.animate) {
            this.setSliderPositioning()
        }
    }
}
</script>