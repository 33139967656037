import Blockly from 'blockly'

Blockly.Events.BLOCK_RUN_WORKFLOW = "run_workflow";

export class BlockRunWorkflowEvent extends Blockly.Events.BlockBase {
    constructor(opt_block, opt_element, opt_name, opt_newValue) {
      super(opt_block);
      this.type = Blockly.Events.BLOCK_RUN_WORKFLOW;
      if (!opt_block) {
        return; // Blank event to be populated by fromJson.
      }
      this.element = typeof opt_element === "undefined" ? "" : opt_element;
      this.name = typeof opt_name === "undefined" ? "" : opt_name;
      this.newValue = typeof opt_newValue === "undefined" ? "" : opt_newValue;
      this.block = opt_block;
    }
}

Blockly.registry.register(Blockly.registry.Type.EVENT, Blockly.Events.BLOCK_RUN_WORKFLOW, BlockRunWorkflowEvent )