<template>
    <div>
    <md-dialog v-model:md-active="computedModalShown" class="blockly-modals calculator-modal" :md-click-outside-to-close="false" :md-close-on-esc="false">
        <div class="wraparound">
            <md-dialog-title class="title">
                <span>Define Your Variables</span>
            </md-dialog-title> 
            <div class="variables">
                <DefineVariables :datasets="datasets" :defined-variables="formData.definedVariables" @choose-variable="handleChooseVariable" @change-variable="handleChangeVariable" @variables-changed="handleVariablesChanged" @delete-calculator-row="handleDeleteVariable"/>  
                <Alert v-if="variblesDateSizesMismatch">
                    <p>There is a mismatch of date ranges. Any missing dates will use the last date selected for the variable.</p>
                </Alert>
            </div>
            <div class="md-layout md-layout-mt10-container">
                <div class="md-layout-item md-layout--operators">
                    <md-dialog-title class="title">
                        Select Operators
                    </md-dialog-title>
                    <Operators @add-operator="addOperator" />
                </div>
                <div class="md-layout-item md-layout--expression">
                    <md-dialog-title class="title">
                        Evaluate this expression
                    </md-dialog-title>
                    <Evaluation ref="evaluation" 
                    v-model="formData.expression" 
                    :checking-expression="checkingExpression && savingState === false" 
                    :expression-state="formData.expressionState" 
                    @selection="handleSelection" 
                    @check-expression="handleCheckExpression" />
                </div>
            </div>
            <div class="md-layout md-layout-mt10-container md-layout-column-container">
                <div class="md-layout-item md-size-80">
                    <SaveResult
                        :type="formData.type"
                        :name="formData.name"
                        @update-name="formData.name = $event"
                        @update-type="formData.type = $event"
                        @update-name-valid="isNameValid = $event"
                    />
                </div>
            </div>
        </div>
        <md-dialog-actions>
            <div class="md-layout-item calculator--actions md-size-20">
                <md-button class="md-primary" @click="handleClose">Close</md-button>
                <md-button class="md-primary" @click="handleSave" :disabled="isSaveButtonDisabled">
                    Save
                    <md-progress-spinner v-if="savingState" md-mode="indeterminate" class="expression-loader static-loading-spinner md-accent" :md-diameter="12" :md-stroke="1" /> 
                    
                </md-button>
            </div>
        </md-dialog-actions>
    </md-dialog>
    <md-dialog-alert
      v-model:md-active="showAlert"
      :md-content="alertMessage"
      md-confirm-text="Back" />
    </div>
</template>

<script>
import DefineVariables from '../components/CalculatorModal/DefineVariables.vue';
import Evaluation from '../components/CalculatorModal/Evaluation.vue';
import Operators from '../components/CalculatorModal/Operators.vue';
import SaveResult from '../components/CalculatorModal/SaveResult.vue';
import { uniq } from 'lodash'
import { functions } from "@/firebaseFunctions.js";
import { CALCULATOR_EXPRESSION_STATE, TABLE_DATE_CONSTANTS } from '../constants/nextGenConstants'
import Alert from '../components/Alert.vue'
import { globalEventBus } from '@/eventbus.js'
import blocklyModalsMixin from './blocklyModalsMixin.js'


export default {
    name: 'Calculator',
    mixins: [blocklyModalsMixin],
    components: {
        DefineVariables,
        Evaluation,
        Operators,
        SaveResult,
        Alert
    },
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        expression: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            defualt: 'band'
        },
        name: {
            type: String,
            defualt: ''
        },
        definedVariables: {
            type: Array,
            default: () => []
        },
        expressionState: {
            type: String,
            default: CALCULATOR_EXPRESSION_STATE.CHECK
        },
    },
    data() {
        return {
            textSelection: { start: 0, end: 0 },
            showAlert: false,
            alertMessage: 'Please enter a name',
            datasets: null,
            checkingExpression: false,
            savingState: false,
            validRangeDefaultUnits: ['year'],
            isNameValid: true,
            formData: {
                expression: this.expression,
                type: this.type,
                name: this.name,
                definedVariables: this.definedVariables,
                expressionState: this.expressionState
            }
        }
    },
    computed: {
        formDataExpression() {
            return this.formData.expression
        },
        hasSelectedText() {
            return this.textSelection.start > 0 || this.textSelection.end > 0
        },
        justCursorPosition() {
            return this.hasSelectedText && this.textSelection.start  === this.textSelection.end
        },
        currentDataset() {
            return this.datasets.length > 0 ? this.datasets[0] : null
        },
        invalidVariables() {
            const invalidMessages = []
            this.formData.definedVariables.forEach(v => {
                if(!v.variableName || v.variableName.length === 0) {
                    invalidMessages.push(`Variable needs a name on line ${v.index+1}`)
                    return true
                }
                if(!v.dataset || v.dataset.length === 0) {
                    invalidMessages.push(`Choose a dataset for: ${v.variableName}`)
                    return true
                }
                if(!v.properties || v.properties.length === 0) {
                    invalidMessages.push(`Choose at least 1 property for: ${v.variableName}`)
                    return true
                }
                if(!v.dates || v.dates.length === 0) {
                    invalidMessages.push(`Choose at least 1 date: ${v.variableName}`)
                    return true
                }
                if(this.formData.type === 'band') {
                    if(v.properties.length > 1 && v.propertiesTransform == null) {
                        invalidMessages.push(`You can only choose multiple bands if you transform the variable for: ${v.variableName}`)
                        return true
                    }
                }
                
                if(v.id === undefined) {
                    return true
                }
                return false
            })

            return invalidMessages
        },
        variblesCadencesUsed() {
            const candences = this.formData.definedVariables
                .filter(v => v.datesTransform === null)
                .filter(v => v.cadence !== 'single_composite')
                .map(v => v.cadence)
                .filter(v => v !== null)

            return uniq(candences)    
        },
        variblesCadenceRangeMismatch() {
            if(this.formData.type === 'band' &&  this.currentDataset.cadence === 'range') {
                const tableDateConstantValues = TABLE_DATE_CONSTANTS.map(c => c.value)
                const candences = this.formData.definedVariables.filter(v => {
                    if(v.datesTransform !== null){
                        return false
                    }
                    // Checks if a date is a constant date e.g. all, first, last, all-except-first, all-except-last
                    // if it is dont need to check cadence
                    if(v.dates.length === 1 && tableDateConstantValues.indexOf(v.dates[0]) >= 0) {
                        return false
                    }
                    if(v.cadence !== 'range') {
                        return false
                    }
                    if(v.dates[0] === this.currentDataset.multi_image_temporal?.temporal[0].value) {
                        return false
                    }
                    if(v.defaultUnit === undefined || v.defaultUnit === null) {
                        return true
                    }
                    if(this.validRangeDefaultUnits.indexOf(v.defaultUnit) >=0) {
                        return false
                    }
                    return true
                })
                return candences.length >= 1
            }
            return false
        },
        variblesDateSizesMismatch() {
            if(this.formData.type === 'band') {
                const candences = this.formData.definedVariables.map(v => {
                    if (v.datesTransform !== null) {
                        return 1
                    }
                    if (v.cadence === 'single_composite') {
                        return 1
                    }
                    if (v.cadence === 'range') {
                        if(this.validRangeDefaultUnits.indexOf(v.defaultUnit) >=0) {
                            return v.dates.length
                        }
                        return 99999
                    }
                    return v.dates.length
                })
                .filter(count => count > 1)
                return uniq(candences).length > 1
            }
            return false
        },
        availableBands() {
            let bands = []
            this.formData.definedVariables.forEach(v => {
                let properties = v.properties
                if(v.propertiesTransform !== null) {
                    properties = [v.propertiesTransform]
                }

                if(bands.length === 0) {
                    properties.forEach(property => bands.push([property['ebx:name']]))
                } else {
                    const newBands = []
                    properties.forEach(property => {
                        bands.forEach(b => {
                            const propBand = b.slice(0)
                            propBand.push(property['ebx:name'])
                            newBands.push(propBand)
                        })
                    })
                    bands = newBands
                }
            })
            return bands.map(b => b.join('_'))
        },
        isSaveButtonDisabled() {
            return !this.formData.name || this.formData.name.length == 0 || !this.isNameValid;
        }
    },
    watch: {
        formDataExpression() {
            this.formData.expressionState = CALCULATOR_EXPRESSION_STATE.CHECK
        },
        showModal(shown) {
            globalEventBus.$emit('supressDeleteAction', shown)
            if(shown) {
                this.formData = {
                    expression: this.expression,
                    type: this.type,
                    name: this.name,
                    definedVariables: this.definedVariables,
                    expressionState: this.expressionState
                }
            }
        }
    },
    methods: {
        getFieldValue() {
            return {
                expression: this.formData.expression,
                type: this.formData.type,
                name: this.formData.name,
                definedVariables: this.formData.definedVariables,
                expressionState: this.formData.expressionState
            };
        },
        handleChangeVariable(rowId, newVar, oldVar) {
            this.formData.expression = ((this.formData.expression + ' ').replace(new RegExp(oldVar+"([^(])", "g"), newVar+"$1")).trim()
        },
        handleChooseVariable(rowId, variableName) {
            this.addOperator(variableName)
        },
        handleVariablesChanged(newVariables) {
            this.formData.definedVariables = newVariables
        },
        handleDeleteVariable() {
            this.formData.expressionState = CALCULATOR_EXPRESSION_STATE.CHECK 
        },
        addOperator(operator) {
            const brokenDownExpression = {
                start: this.formData.expression,
                mid: '',
                end: ''
            }
            if(this.hasSelectedText) {
                brokenDownExpression.start = this.formData.expression.slice(0, this.textSelection.start)
                brokenDownExpression.mid = this.formData.expression.slice(this.textSelection.start, this.textSelection.end)
                brokenDownExpression.end = this.formData.expression.slice(this.textSelection.end, this.formData.expression.length)
                if (brokenDownExpression.start[brokenDownExpression.start.length -1] !== ' '){
                    brokenDownExpression.start += ' '
                }
                if (brokenDownExpression.end[0] !== ' '){
                    brokenDownExpression.end = ' ' + brokenDownExpression.end
                }
            }
            switch(operator) {
                case '(':
                    if(this.hasSelectedText) {
                        this.formData.expression = (brokenDownExpression.start + '(' + brokenDownExpression.mid + ')' + brokenDownExpression.end).trim()
                        this.setEvaluationCursor(brokenDownExpression.start.length + brokenDownExpression.mid.length + 1)
                    } else {
                        this.formData.expression = (this.formData.expression + ' (').trim()
                        this.setEvaluationCursor(this.formData.expression.length > 1 ? this.formData.expression.length - 1 : this.formData.expression.length)
                    }
                    break;
                case ')':
                    if(this.hasSelectedText && brokenDownExpression.mid.length > 0) {
                        this.formData.expression = (brokenDownExpression.start + '(' + brokenDownExpression.mid + ')' + brokenDownExpression.end).trim()
                        this.setEvaluationCursor(brokenDownExpression.start.length + brokenDownExpression.mid.length + 2)
                    } else {
                        this.formData.expression = (this.formData.expression + ' )').trim()
                        this.setEvaluationCursor(this.formData.expression.length)
                    }
                    break;    
                case 'sin':
                case 'cos':   
                case 'tan': 
                case 'asin':
                case 'acos':    
                case 'atan':
                case 'log10':
                case 'log':
                case 'max':
                case 'min':
                case 'abs':
                case 'sqrt':
                    if(this.hasSelectedText) {
                        this.formData.expression = (brokenDownExpression.start + operator + '(' + brokenDownExpression.mid + ')' + brokenDownExpression.end).trim()
                        if(this.justCursorPosition) {
                            this.setEvaluationCursor(brokenDownExpression.start.length + operator.length + brokenDownExpression.mid.length + 1)
                        }else {
                            this.setEvaluationCursor(brokenDownExpression.start.length + operator.length + brokenDownExpression.mid.length + 2)
                        }
                    } else {
                        this.formData.expression = (this.formData.expression + operator + '()').trim()
                        this.setEvaluationCursor(this.formData.expression.length - 1)
                    } 
                    break;
                default:
                    if(this.hasSelectedText) {
                        this.formData.expression = (brokenDownExpression.start + operator + brokenDownExpression.mid + brokenDownExpression.end).trim()
                        this.setEvaluationCursor(brokenDownExpression.start.length + operator.length + brokenDownExpression.mid.length + 1)
                    } else {
                        this.formData.expression = (this.formData.expression + ' ' + operator).trim()
                        this.setEvaluationCursor(this.formData.expression.length)
                    }
            }
            this.textSelection = { start: 0, end: 0}
        },
        handleSelection(textSelection) {
            this.textSelection = textSelection
        },
        sanitise(taintedData){
            /*
            Takes a string and detaints it, then base64 encodes it
            Assumption is that this is user input and could contain malicious content.
            This is decoded by our validateExpression function before checking it with Google
            This form is not saved to the run doc, rather the user's orginal input is preserved.
            eg see EBX-4067. The OWASP Cloud Armor rules regard line starting with a / as a threat vector
            These are often used by users as comments in calculator equations
            So we should remove these before sending over the wire to validateExpression
            more regexps here can be added as they are discovered
            Note that this string could be multiline, so the multi line and global flags are essential
            Caution: We don't want to exclude divide symbols in genuine equations!
            */
            let sanitisedData = taintedData.replace(/^\s*\/\/[^\n]+\n*/mg, '')
            let encodedSanitisedData = btoa(sanitisedData) // base64 encodes the string. This allows divide signs to pass through the Cloud Armor, amongst other things
            return encodedSanitisedData
        },
        async handleCheckExpression() {
            if(this.formData.expression.trim().length <= 0) {
                this.alertMessage = 'Please enter a expression'
                this.showAlert = true
            } else { //If there is an expression, validate it 
                let variableNames = this.formData.definedVariables.map(i => i.variableName)
                let requestData = {
                    variables: variableNames, 
                    expression: this.sanitise(this.formData.expression)
                }
                this.checkingExpression = true
                this.formData.expressionState = CALCULATOR_EXPRESSION_STATE.CHECK
                try {
                    const response = (await functions.validateExpression(requestData)).data
                    if(response.status === 200) {
                        this.formData.expressionState = CALCULATOR_EXPRESSION_STATE.VALID
                    } else if(response.status && response.response) {
                        this.formData.expressionState = CALCULATOR_EXPRESSION_STATE.INVALID
                        this.alertMessage = response.response
                        this.showAlert = true
                    } else {
                        this.formData.expressionState = CALCULATOR_EXPRESSION_STATE.INVALID
                        this.alertMessage = 'Could not validate expression (1). Please try again'
                        this.showAlert = true
                    }
                } catch(error) {
                    console.error(error)
                    this.formData.expressionState = CALCULATOR_EXPRESSION_STATE.INVALID
                    this.alertMessage = 'Could not validate expression (2). Please try again'
                    this.showAlert = true
                } finally {
                    this.checkingExpression = false
                }
            }
        },
        async handleSave() {
            try {
                this.savingState = true
                if(this.formData.name.length <= 0) {
                    this.alertMessage = 'Please enter a name'
                    this.showAlert = true
                    return
                } else if(this.formData.expression.trim().length <= 0) {
                    this.alertMessage = 'Please enter a expression'
                    this.showAlert = true
                    return
                } else if(this.invalidVariables.length > 0) {
                    this.alertMessage = this.invalidVariables.join("<br>")
                    this.showAlert = true
                    return
                // }else if(this.variblesCadencesUsed.length > 1) {
                //     this.alertMessage = 'Invalid mix of date formats, These cannot be compared. Please transform the variables or alter the dates to be consistent'
                //     this.showAlert = true  
                //     return
                }else if(this.variblesCadenceRangeMismatch) {
                    this.alertMessage = 'Variables should have the same date ranges for your calculation to work. Alternatively, select a single date for one of your variables or transform it - that date will be used against all dates from the other variable(s)'
                    this.showAlert = true  
                    return
                }else if (this.formData.expressionState !== CALCULATOR_EXPRESSION_STATE.VALID) {
                    await this.handleCheckExpression()
                    //Wait a second so to show the valid state to the user
                    if(this.formData.expressionState === CALCULATOR_EXPRESSION_STATE.VALID) {
                        await new Promise(resolve => setTimeout(resolve, 1000))
                    }
                }

                if(this.formData.expressionState === CALCULATOR_EXPRESSION_STATE.VALID) {
                    this.computedModalShown = false
                    this.blockyEventCallback('modal-save', this.getFieldValue());
                    this.blockyEventCallback('expression', this.formData.expression)
                    this.blockyEventCallback('type',this.formData.type)
                    this.blockyEventCallback('name',this.formData.name)
                }
            } catch(error) {
                console.error(error)
                this.alertMessage = 'Could not save calculator details.'
                this.showAlert = true
            } finally {
                this.savingState = false
            }
        },
        handleClose() {
            this.computedModalShown = false
        },
        setEvaluationCursor(pos) {
            this.$refs.evaluation.setPosition(pos)
        }
    }
}
</script>