<template>
    <div class="legend-component">
        <div class="gradient-wrapper" v-if="isGradientType">
            <p class="unit ebx-secondary" v-if="unit">Units: {{unit}}</p>
            <div class="gradient" :style="gradientStyle"></div>
            <div class="label-wrapper">
                <span class="label ebx-secondary">{{ firstLabel }}</span>
                <span class="label ebx-secondary">{{ lastLabel }}</span>
            </div>
        </div>
        <div class="list-wrapper" v-if="isListType">
            <div class="legend-value" v-for="value in legend.values" :key="value.label">            
                <div class="legend-colour-block" :style="{'background-color': value.colour }" :class="whiteHexValues.includes(value.colour) ? 'bordered' : ''"></div>
                <p class="ebx-secondary legend-label">{{ value.label }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        legend: {
            type: Object,
            required: true
        }
    },
    computed: {
        whiteHexValues() {
            return ['#ffffff', '#fff', '#FFFFFF', '#FFF']
        },  
        layerName() { 
            return this.legend.layerName
        },
        isGradientType() {
            return this.legend.type == 'gradient'
        },
        isListType() {
            return this.legend.type == 'list'
        },
        labelArray() {
            return this.legend.values ? this.legend.values.map(el => el.label) : []
        },
        colorsArray() {
            return this.legend.values ? this.legend.values.map(el => el.colour) : []
        },
        colorsArrayReversed() {
            const revArray = this.colorsArray.slice()
            revArray.reverse()
            return revArray
        },
        gradientStyle() {
            return `background-image: linear-gradient(-90deg, ${this.colorsArrayReversed.join(",")})`
        },
        lastLabel() {
            if(this.labelArray.length === 0) {
                return null
            }
            return this.labelArray[this.labelArray.length - 1].split(' ')[0]
        },
        firstLabel() {
            if(this.labelArray.length === 0) {
                return null
            }
            return this.labelArray[0].split(' ')[0]
        },
        unit() {
            if(this.labelArray.length === 0) {
                return null
            }
            // get the elements in string split by space
            const splitLabel = this.labelArray[0].split(' ')
            // stick together all the elements except the first one
            return splitLabel.slice(1).join(' ')
        }
    }
}
</script>