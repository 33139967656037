<script>
import { LAZYLOAD_MAP_TILE_IMAGES,MAP_TILE_PLACEHOLDER_IMAGE, LAZYLOAD_MAP_TILE_PRELOAD_NEXT, LAZYLOAD_MAP_TILE_PRELOAD_MODE, LAZYLOAD_MAP_TILE_PRELOAD_OFFSET,MAP_LAYER_TYPE } from '../../constants/nextGenConstants';
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
export default {
    props: {
        google: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
        layerID: {
            type: String,
            required: true,
        },
        mapId: {
            type: String,
            required: true,
        },
        opacity: {
            type: Number,
            required: false,
        },
        isMapMoving: {
            type: Boolean,
            required: false,
            default: false
        },
        layerIndex: {
            type: Number,
            required: true
        },
        displayLayer: {
            type: Boolean,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        createdLayer: {
            type: Object,
            required: false,
            default: null
        }
    },
    emits: [
        'tiles-loading'
    ],
    data() {
        return {
            layer: null,
            tilesLoading: false,
            transparentGif: MAP_TILE_PLACEHOLDER_IMAGE,
            layerDisplayed: this.displayLayer,
            lazyLoadLayers: LAZYLOAD_MAP_TILE_IMAGES,
            preloadNextLayer: LAZYLOAD_MAP_TILE_PRELOAD_NEXT && LAZYLOAD_MAP_TILE_IMAGES,
            preloadMode: LAZYLOAD_MAP_TILE_PRELOAD_MODE,
            preloadNumberOfLayers: LAZYLOAD_MAP_TILE_PRELOAD_OFFSET,
            listenerHandles: {
                titlesLoaded: null
            },
            preloadingImages: {}
        };
    },
    methods: {
        tilesLoaded() {
            this.tilesLoading = false
        },
        createOverlay(data) {
            const { ImageMapType } = this.google.maps;
            this.removeOverlay();

            if([MAP_LAYER_TYPE.BASEMAP, MAP_LAYER_TYPE.ASSET].indexOf(this.type) >= 0 && this.layer === null) {
                this.map.overlayMapTypes.push(this.createdLayer);
                this.layer = this.createdLayer
                return;
            }
            
            if (!data || !data.mapURL) {
                return;
            }

            if (!this.layer) {
                
                this.layer = new ImageMapType({
                    name: data.mapid,
                    getTileUrl: this.getTileUrl,
                    tileSize: new this.google.maps.Size(256, 256),
                    minZoom: 1,
                    maxZoom: 20,
                });

                // tilesloaded event fires when all tiles have loaded
                // (will still fire if there are 429 errors)
                this.listenerHandles.tilesLoaded = this.layer.addListener("tilesloaded",this.tilesLoaded);

                this.map.overlayMapTypes.push(this.layer);
                
            }
           
        },
        /**
         * removes layer from overlay but doesn't destroy the layer
         */
        removeOverlay(layerDisplayed = false) {
            this.tilesLoading = false
            if (this.layer) {
                let index = this.map.overlayMapTypes.indexOf(this.layer);
                let map = this.map
                if (index > -1) {
                    if(this.listenerHandles.tilesLoaded){
                        this.google.maps.event.removeListener(this.listenerHandles.tilesLoaded);
                    }
                    map.overlayMapTypes.removeAt(index);
                    this.layer = null;
                    this.layerDisplayed = layerDisplayed
                }
            }
        },
        getMap() {
            return this.map;
        },
        /**
         * removes layer from map and destroys layer in state
         */
        destroyLayer() {
            if(this.layer) {          
                this.removeOverlay()
            }
        },
        setLayerOpacity(opacity) {
            if(this.layer && typeof this.layer.setOpacity === 'function') {
                this.layer.setOpacity(opacity)
            }
        },
        refreshMapTiles(){
            // if it errors it will zoom out and zoom back in to force a refresh of just the broken tiles
            // all the other tiles are kept in cache
            console.debug("changing zoom to force refresh")
            this.map.setZoom(this.map.getZoom()+.000000000000001);
            //this will change the zoom again and load fresh tiles
            // user cannot see as the zoom change is tiny
            this.map.setZoom(Math.round(this.map.getZoom()));
        },
        preloadImage(url) {
            if(this.preloadNextLayer === false) {
                return
            }
            if(this.preloadLayer === false) {
                return
            }
            if( this.preloadingImages[url] !== undefined) {
                return
            }
            
            const preloadImage = new Image();
            preloadImage.onload = () => {
                delete this.preloadingImages[url]
            }
            preloadImage.onerror = () => {
                delete this.preloadingImages[url]
            }
            this.preloadingImages.url = preloadImage
            preloadImage.src = url;

        },
        getTileUrl(coord, zoom) {
            let url = this.layerData.mapURL
                .replace("{x}", coord.x)
                .replace("{y}", coord.y)
                .replace("{z}", zoom);

            if(this.isMapMoving === false && (this.lazyLoadLayers === false || this.displayLayer || this.layerDisplayed)) {
                // indicate we have started loading the tiles
                this.tilesLoading = true
                return url;
            }else {
                this.preloadImage(url)
                return this.transparentGif
            }
        }
    },
    beforeUnmount() {
        this.removeOverlay();
    },
    watch: {
        /**
         * checks for whenever layer data changes and creates a new overlay
         * @param {Object} - layer data object
         */
        layerData: {
            immediate: true,
            handler(data, previous) {
                if(!previous || data.id !== previous.id) {
                    this.createOverlay(data)
                }
            }
        },
        /**
         * watches computed opacity property and updates the layer opacity
         */
        opacity: {
            immediate: true,
            handler(opacity) {
                if (!isNaN(opacity) && this.layer && this.layerIndex === this.selectedLayer) {
                    this.setLayerOpacity(opacity)
                }
            },
        },
        selectedLayer: {
            immediate: true,
            handler(selectedLayer) {
                if (selectedLayer === this.layerIndex) {
                    this.setLayerOpacity(this.opacity)
                } else {
                    this.setLayerOpacity(0)
                }
            },
        },
        tilesLoading(current, previous){
            if(current !== previous) {
                this.$emit("tiles-loading", current);
            }
        },
        displayLayer(value) {
            if(value && this.layerDisplayed === false) {
                this.layerDisplayed = true
            }
        },
        layerDisplayed(displayed) {
            if(displayed && this.lazyLoadLayers) {
                this.refreshMapTiles()
            }
        },
        isMapMoving(current, previous) {
            if(previous === true && current === false) {
                this.refreshMapTiles()
            }
        }
    },
    computed: {
        layerGroup() {
            return this.$store.getters['maplayers/getMapLayerGroup'](this.layerID)
        },
        layerData() {
            if(this.layerGroup) {
                return this.layerGroup.layers.find(layer => layer.mapid === this.mapId)
            }
            return null
        },
        selectedLayer() {
            return this.$store.getters['maplayers/getSelectedLayer'][this.layerID]
        },
        preloadLayer() {
            if(this.preloadMode === 'parallel' && this.layerIndex > 0 && this.selectedLayer > 0) {
                return true
            }
            return this.selectedLayer !== this.layerIndex && this.selectedLayer + this.preloadNumberOfLayers >= this.layerIndex
        }
    },
    render() {
        return null
    }
};
</script>
