import {AbstractAbility} from "./abstractAbility"
import { CompositeGroupTimePeriodCollection } from "./collections/CompositeGroupTimePeriodCollection"
import {MONTHS} from "../../constants/nextGenConstants"
import moment from 'moment'
class ProvideCompositeGroupTimePeriod extends AbstractAbility{
    constructor(state) {
        super(state)
        this.stateKey = 'composite_time_period'
        this.months = MONTHS
    }

    getCadence() {
        return this.getState().cadence || 'none'
    }
    getInterval() {
        return this.getState().interval || 1
    }
    getMethod() {
        return this.getState().method || null
    }

    getOptionsForTimePeriodAbility(timePeriods, forSingleImages = false) {

        if(timePeriods.length === 0 || this.getCadence() === 'none') {
            return [['None','none']]
        }

        if(this.getCadence() === 'hour') {
            return this.reduceOptionsBasedOnInterval_(
                this.getCadence(),
                this.getValidHours_(),
                this.getInterval()
            )
        }
        if(this.getCadence() === 'minute') {
            return this.reduceOptionsBasedOnInterval_(
                this.getCadence(),
                this.getValidMinutes_(),
                this.getInterval()
            )
        }

        /**
         * If we have already calculated the options, return them
         * this.state._options is used as a cache which is calculated further down in this code.
         * the underscore denotes that this is a private variable and should not be accessed directly.
         * 
         * There are multiple calls to this method and we want to avoid recalculating the dates for each pass through the visitors.
         * e.g. global datasets will call this method multiple times each time we make a request to get all datasets.
         * Global Datasets is called multiple times in the populate state visitor.
         * 
         * This tries to mitigate issues with large date range datasets such as CMIP6.
         */

        const cachedOptionsKey = forSingleImages ? '_single_image_options' : '_options'
        if (this.getState()[cachedOptionsKey]) {
            return this.getState()[cachedOptionsKey]
        }

        const availableDates = this.getAvailableDates(timePeriods, true)

        if(this.getCadence() === 'year') {
            return this.getState()[cachedOptionsKey] = this.reduceOptionsBasedOnInterval_(
                this.getCadence(),
                this.getValidYears_(availableDates),
                this.getInterval()
            ).map(o => [o[0],o[1]])
        }
        if(this.getCadence() === 'month') {
            const reducedOptions = this.getState()[cachedOptionsKey] = this.reduceOptionsBasedOnInterval_(
                this.getCadence(),
                forSingleImages ? this.getValidMonthsAndYears_(availableDates) : this.getValidMonths_(availableDates),
                this.getInterval(),
            ) 

            if(forSingleImages) {
                // Work out which options do not have any valid dates and remove based on the filtered time periods
                const validMonths = this.getValidMonths_(this.getAvailableDates(timePeriods, false)).map(month => parseInt(month[1]) - 1)
                const futherFilterdOptions = this.reduceOptionsBasedOnFilteredPeriods_('month', 'month', reducedOptions, validMonths)
                return this.getState()[cachedOptionsKey] = futherFilterdOptions.map(o => [o[0],o[1]])
            }
            return reducedOptions.map(o => [o[0],o[1]])
        }
        if(this.getCadence() === 'week') {
            const reducedOptions = this.getState()[cachedOptionsKey] = this.reduceOptionsBasedOnInterval_(
                this.getCadence(),
                forSingleImages ? this.getValidWeeksAndYears_(availableDates) : this.getValidWeeks_(availableDates),
                this.getInterval()
            ) 
            if(forSingleImages) {
                // Work out which options do not have any valid dates and remove based on the filtered time periods
                const validMonths = this.getValidMonths_(this.getAvailableDates(timePeriods, false)).map(month => parseInt(month[1]) - 1)
                const futherFilterdOptions = this.reduceOptionsBasedOnFilteredPeriods_('week', 'week', reducedOptions, validMonths)
                return this.getState()[cachedOptionsKey] = futherFilterdOptions.map(o => [o[0],o[1]])
            }
            return reducedOptions.map(o => [o[0],o[1]])
        }
        if(this.getCadence() === 'day') {
            const reducedOptions =  this.getState()[cachedOptionsKey] = this.reduceOptionsBasedOnInterval_(
                this.getCadence(),
                forSingleImages ? this.getValidDaysAndYears_(availableDates) : this.getValidDays_(availableDates),
                this.getInterval()
            ) 
            if(forSingleImages) {
                // Work out which options do not have any valid dates and remove based on the filtered time periods
                const validMonths = this.getValidMonths_(this.getAvailableDates(timePeriods, false)).map(month => parseInt(month[1]) - 1)
                const futherFilterdOptions = this.reduceOptionsBasedOnFilteredPeriods_('dayOfYear','day', reducedOptions, validMonths)
                return this.getState()[cachedOptionsKey] = futherFilterdOptions.map(o => [o[0],o[1]])
            }
            return reducedOptions.map(o => [o[0],o[1]])
        }

        return  [['None','none']]
    }

    /**
     * Takes the reduced date range options and checks if a value in the existanceKeys exists in the range.
     * If it does then the range is allowed, otherwise the date range is removed.
     * @param {string} compareBy 
     * @param {string} advanceBy 
     * @param {array} options 
     * @param {array} existanceKeys 
     * @returns 
     */
    reduceOptionsBasedOnFilteredPeriods_(compareBy, advanceBy,  options, existanceKeys) {
        return options.filter(option => {
            if(option.length < 3) {
                return true
            }
            if(option[2].length === 2) {
                const startDate = moment(option[2][0] * 1000)
                const endDate = moment(option[2][1] * 1000)
                while(startDate.isBefore(endDate)) {
                    if(existanceKeys.indexOf(startDate[compareBy]()) >=0) {
                        return true
                    }
                    startDate.add(1, advanceBy)
                }
                return false
            }
            if(option[2].length === 1) {
                const startDate = moment(option[2][0] * 1000)
                return existanceKeys.indexOf(startDate[compareBy]()) >=0
            }
            return true
        })
    }

    reduceOptionsBasedOnInterval_(cadence, options, interval) {
        
        if(interval > 1) {
            const originalOptions = JSON.parse(JSON.stringify(options))
            const optionValuePositionMap = options.reduce((c,option, i) => {
                c[option[1]] = i
                return c
            },{})
            
            const removeFirstWord = (phrase) => {
                const words = phrase.split(' ')
                if(words.length > 1) {
                    return words.slice(1).join(' ')
                }
                return phrase
            }


            const filteredOptions = options
                .filter((_, i) => i % interval === 0)
                .map(option => {
                    const nextOption = originalOptions[Math.min(optionValuePositionMap[option[1]] + interval -1, originalOptions.length -1)];
                    if(nextOption[0] !== option[0]) {
                        switch(cadence) {
                            case 'month':
                            case 'year':
                                option[0] = option[0] + ' - ' + nextOption[0]
                                break;
                            case 'week':    
                            case 'day':    
                            case 'hour':    
                            case 'minute':
                                option[0] = cadence +'s ' + removeFirstWord(option[0]) + ' - ' + removeFirstWord(nextOption[0])
                                break;    
                        }
                        // Set the range value to the next option to compare if its not totally filtered
                        if(option.length >2) {
                            option[2] = [option[2], nextOption[2]]
                        }
                    }
                    return option
                })
            return filteredOptions;
        }
        if(['week','day'].indexOf(cadence) >= 0) {
            options.sort((a,b) => a[1] - b[1])
        }
        return options
    }

    getValidMinutes_() {
        const options = []
        for(let i=0;i<60;i++) {
            options.push([`${i}`,`${i}`])
        }
        return options
    }
    getValidHours_() {
        const options = []
        for(let i=0;i<24;i++) {
            options.push([`${i}`,`${i}`])
        }
        return options
    }
    getValidYears_(availableDates) {
        return availableDates
            .reduce((c, momentDate) => {
                const year = momentDate.year()
                if(c.indexOf(year) < 0) {
                    c.push(year)
                }
                return c
            },[])
            .map(year => [`${year}`,`${year}`])
    }

    getValidMonthsAndYears_(availableDates) {
        const monthMaps = availableDates
            .reduce((c, momentDate) => {
                const month = momentDate.month()
                const year = momentDate.year()
                const key = `${year}-${month}`
                if(c[key] === undefined) {
                    c[key] =  {month, year, date: momentDate.clone().startOf('month').unix()}
                }
                return c
            },{})

        return Object.keys(monthMaps).map(key => {
            const {month, year, date} = monthMaps[key]
            return [`${this.months[month]} ${year}`,`${year}-${month + 1}`, date]
        })   
    }

    getValidMonths_(availableDates) {
        return availableDates
            .reduce((c, momentDate) => {
                const month = momentDate.month()
                if(c.indexOf(month) < 0) {
                    c.push(month)
                }
                return c
            },[])
            .map(month => [`${this.months[month]}`,`${month + 1}`])
    }

    getValidWeeks_(availableDates) {
        return availableDates
            .reduce((c, momentDate) => {
                const week = momentDate.week()
                if(c.indexOf(week) < 0) {
                    c.push(week)
                }
                return c
            },[])
            .map(week => [`week ${week}`,`${week}`])
    }

    getValidWeeksAndYears_(availableDates) {
        const weekMaps = availableDates
            .reduce((c, momentDate) => {
                const week = momentDate.week()
                const year = momentDate.year()
                const key = `${year}-${week}`
                if(c[key] === undefined) {
                    c[key] =  {week, year}
                }
                return c
            },
        {})

        return Object.keys(weekMaps).map(key => {
            const {week, year} = weekMaps[key]
            return [`week ${week} ${year}`,`${year}-${week}`]
        })   
    }

    getValidDays_(availableDates) {
        return availableDates
            .reduce((c, momentDate) => {
                const doy = momentDate.dayOfYear()
                if(c.indexOf(doy) < 0) {
                    c.push(doy)
                }
                return c
            },[])
            .map(doy => [`day ${doy}`,`${doy}`])
    }

    getValidDaysAndYears_(availableDates) {
        const dayMaps = availableDates
            .reduce((c, momentDate) => {
                const doy = momentDate.dayOfYear()
                const year = momentDate.year()
                const key = `${year}-${doy}`
                if(c[key] === undefined) {
                    c[key] =  {doy, year}
                }
                return c
            },
        {})

        return Object.keys(dayMaps).map(key => {
            const {doy, year} = dayMaps[key]
            return [`day ${doy} ${year}`,`${year}-${doy}`]
        })   
    }

    getAvailableDates(timePeriods, removeFilterPeriods = false) {
        if(removeFilterPeriods) {
            const notFilteredTimePeriods = timePeriods.filter(period => period.getIsAFilter() === false)
            return notFilteredTimePeriods[notFilteredTimePeriods.length - 1].getAllAvailableDates();
        }
        return timePeriods[timePeriods.length - 1].getAllAvailableDates();
    }

    getTemporalTimePeriod(isInCurrentWorkflow, timePeriods, forSingleImages = false) {
        // Current time periods is always a ProvidesTimePeriodCollection
        const currentTimePeriods = isInCurrentWorkflow ? timePeriods.currentAbilities() : timePeriods.toArray()
        const periods = this.getOptionsForTimePeriodAbility(currentTimePeriods, forSingleImages);
		const cadence = this.getCadence()

        // If this block is a single composite, use the last known time period ability to workout the start and end date
        // format the temporal as a single composite 
        if(cadence === 'none' && currentTimePeriods.length > 1) {
            const lastPeriod = currentTimePeriods[currentTimePeriods.length - 1]
            const lastTemporal = lastPeriod.getTemporalTimePeriod('none','none')
            return {
                temporal: lastTemporal.temporal,
                cadence: 'single_composite',
                timePeriod: lastTemporal.timePeriod
            }
        }

		const currentPeriod = isInCurrentWorkflow ? timePeriods.current() : timePeriods.last()
		//Range cadence doesnt return any periods so we need to set cadence to use in the period section below
		const useCadence = ['range','none'].indexOf(cadence) >= 0 ? 'single_composite' : cadence
        if(periods.length > 0 && periods[0][1] !== 'none'){
			return {
				temporal: periods.map(period => {
					return {
						value: period[1],
						name: period[0],
						interval: {
							start: period[0],
							end: period[1]
						}
					}
				}),
				cadence: useCadence,
				timePeriod: {
					start_date: currentPeriod.getStartDate(),
					end_date: currentPeriod.getEndDate()
				}
			}
		}
        return null
    }

    static newCollection(abilites) {
        return new CompositeGroupTimePeriodCollection(abilites)
    }
}

export {ProvideCompositeGroupTimePeriod}