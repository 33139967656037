<template>
    <div>
        <div class="assets-tab--empty" v-if="filteredUserAssets.length === 0">
            <div class="ebx-icon">
                <img :src="assets.icons.emptyStateAssets" alt="No assets">
            </div>
            <template v-if="filterBy == 'org'">
                <p class="ebx-primary">Upload images to use across your projects</p>
            </template>
            <template v-else>
                <p class="ebx-primary">Upload images to use across your projects</p>
            </template>
        </div>
        <div v-if="userDatasetsLoading === false" class="asset-card--container">
            <div class="asset-card--item asset-card--item__action" v-for="(asset,i) in filteredUserAssets" :key="asset.id + '_' + asset.location + '_' + i">
                <div class="asset-card--item-body" @click="handleShowAsset(asset)">
                    <div class="asset-card--item-title">
                        <div class="asset-card--thumbnail">
                            <img :src="getImageUrl(asset)" alt="Folder">
                        </div>
                        <div class="ebx-primary-bold">
                            {{ asset.title }}
                        </div>
                    </div>
                </div>
                <div class="asset-card--item-actions">
                    <md-menu>
                        <md-button class="md-icon-button" md-menu-trigger>
                            <md-icon>more_vert</md-icon>
                        </md-button>
                        <md-menu-content class="asset-options-menu">
                            <template v-if="userDatasetCanEdit(asset.id, user.uid)">
                                <template v-if="canShareWorkflows">
                                    <md-menu-item v-if="userDatasetLocation(asset.id) === 'user'" class="asset-options-menu--option" @click="moveDataset(asset)">
                                        <span class="material-icons-outlined ebx-icon">drive_file_move</span>
                                        <span>Move to team</span>
                                    </md-menu-item>
                                    <md-menu-item v-if="userDatasetLocation(asset.id) === 'org' " class="asset-options-menu--option" @click="moveDataset(asset)">
                                        <span class="material-icons-outlined ebx-icon">drive_file_move</span>
                                        <span>Remove team access</span>
                                    </md-menu-item>
                                </template>
                                
                                
                                <md-menu-item class="asset-options-menu--option" @click="handleShowAsset(asset)">
                                    <span class="material-icons-outlined ebx-icon">mode</span>
                                    <span>Edit</span>
                                </md-menu-item>
                                <md-menu-item class="asset-options-menu--option" @click="handleRemoveAsset(asset)">
                                    <span class="material-icons-outlined ebx-icon">delete_outline</span>
                                    <span>Delete</span>
                                </md-menu-item>
                            </template>
                            <template v-else>
                                <md-menu-item class="asset-options-menu--option" @click="handleShowAsset(asset)">
                                    <span class="material-icons-outlined ebx-icon">mode</span>
                                    <span>View</span>
                                </md-menu-item>
                            </template>
                            
                        </md-menu-content>
                    </md-menu>
                </div>
            </div>
        </div>

        <!-- Needed to render the above for some reason -->
        <span v-for="(asset, i) in filteredUserAssets" :key="'dummy_' + i + '_' + asset.id"></span>


        <confirmation-modal ref="modal" :hide-title="!showPermission" :ok-button-text="buttonText" :if-warning="false">
            <template v-slot:title>
                <div class="ebx-header-2">Permissions</div>
            </template>
            <div v-if="!showPermission">
                {{confirmMessage}}
            </div>
            <div v-if="showPermission">
                <md-radio v-model="movePermission" value="viewable" class="ebx-md-radio">Team members can only view</md-radio>
                <md-radio v-model="movePermission" value="editable" class="ebx-md-radio ebx-md-radio-editable">Team members can edit</md-radio>
            </div>
        </confirmation-modal>
    </div>
</template>

<script>
import authMixin from '@/components/mixins/authMixin';
import userDatasetsMixin from '@/components/mixins/userDatasetsMixin';
import { sharedWorkflowMixin } from '@/components/mixins/projectMixin.js'
import { matchSorter } from 'match-sorter';
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import assetsMixin from '@/components/mixins/assetsMixin.js'

export default {
    name: 'UserDatasetsList',
    components: {
        ConfirmationModal
    },
    mixins: [
        authMixin,
        userDatasetsMixin,
        sharedWorkflowMixin,
        assetsMixin
    ],
    props: {
        filterBy: {
            type: String,
            default: 'all'
        },
        searchInput: {
            type: String,
            default: ''
        }
    },
    emits: ['remove-asset'],
    data() {
        return {
            confirmMessage: '',
            movePermission: 'viewable',
            showPermission: true,
            buttonText: "Share",
        }
    },
    methods: {
        getImageUrl(asset) {
            return asset.thumbnail_url ? asset.thumbnail_url : this.assets.icons.datasetImagePlaceholder
        },
        handleShowAsset(asset) {
            this.$router.push({ name: 'Projects-Dataset', params: { datasetId: asset.id } })
        },
        handleRemoveAsset(asset) {
            this.$emit('remove-asset', asset)
        },
        async moveDataset(asset) {
            
            if(this.userDatasetLocation(asset.id) === 'user') {
                this.buttonText = "Share"
                this.confirmMessage = `Are you sure you want to share this dataset with the team?`
                this.showPermission = true
                this.permission = 'viewable'
            } else {
                this.buttonText = "Remove access"
                this.confirmMessage = `Are you sure you want to remove team access to this dataset? Any workflows using this dataset in team projects, or your team’s private projects will no longer run.`
                this.showPermission = false
                this.permission = 'editable'
            }
            
            const confirmed = await this.$refs.modal.confirm();
            if (!confirmed) return;

            await this.$store.dispatch('userdatasets/moveDataset', {
                datasetId: asset.id,
                permission: this.movePermission
            })
        }
    },
    computed: {
        userAssets() {
            // filter out assets that don't have COMPLETED status
            return this.imageCollectionMetadata
        },
        filteredUserAssets() {
            let filteredUserAssets = this.userAssets

            if (this.filterBy === 'org') {
                filteredUserAssets = filteredUserAssets.filter(content => this.userDatasetLocation(content.id) === 'org')
            } else if (this.filterBy === 'user') {
                filteredUserAssets = filteredUserAssets.filter(content => this.userDatasetLocation(content.id) === 'user')
            }

            if (this.hasSearchText) {
                filteredUserAssets = matchSorter(filteredUserAssets, this.searchInput, { keys: ['title', 'keywords'] })
            }
            return filteredUserAssets
        }
    }
}
</script>