<template>
    <div :class="cardClasses">
        <div class="ebx-card--image">
            <slot name="image">
                <figure class="ebx-card--figure" :class="{'ebx-card--figure-globe': usingGlobeAsPlaceholder}">
                    <img :src="src" alt="Placeholder image">
                </figure>
            </slot>
        </div>
        <div class="ebx-card--content">
            <!--Card content including text and title-->
            <div class="ebx-card--media">
                <div class="ebx-card--media-content">
                    <p v-if="title" class="ebx-primary-bold ebx-card--title">{{title}}</p>
                    <p v-if="text" class="ebx-secondary ebx-card--text">{{text}}</p>
                </div>
            </div>
        </div>
        <div :class="actionsClasses" v-if="actions">
            <div class="ebx-card--actions-container">
                <button 
                    v-if="showLeftAction" 
                    @click="$emit('action-left-click')"
                    class="ebx-button ebx-button--secondary ebx-button--secondary__highlight ebx-button--secondary__compact">
                    {{actionLeftText}}
                </button>
                <button 
                    v-if="showRightAction" 
                    @click="$emit('action-right-click')"
                    class="ebx-button ebx-button--secondary ebx-button--secondary__compact">
                    {{actionRightText}}
                </button>
            </div>
        </div>
    </div>
</template>


<script>
import assets from '@/assets.js'
import assetMixin  from "@/components/mixins/assetsMixin.js"

export default {
    mixins: [
        assetMixin
    ],
    props: {
        imgSrc: {
            type: String,
            required: false,
            default: assets.icons.datasetImagePlaceholder
        },
        title: {
            type: String,
            required: false,
            default: ""
        },
        text: {
            type: String,
            required: false,
            default: ""
        },
        actionLeftText: {
            type: String,
            required: false,
            default: "Open"
        },
        actionRightText: {
            type: String,
            required: false,
            default: "Details"
        },
        showLeftAction: {
            type: Boolean,
            required: false,
            default: false
        },
        showRightAction: {
            type: Boolean,
            required: false,
            default: false
        },
        noHover: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholderType: {
            type: String,
            required: false,
            default: "image"
        }
    },
    emits: [
        'action-left-click',
        'action-right-click'
    ],
    computed: {
        usingGlobeAsPlaceholder() {
            return this.src === this.assets.icons.globe
        },
        src() {
            if (this.imgSrc === null || this.imgSrc === undefined || this.imgSrc === "") {
                if(this.placeholderType === "globe") {
                    return this.assets.icons.globe
                }
                return this.assets.icons.datasetImagePlaceholder
            }

            return this.imgSrc
        },
        actions() {
            if (this.showLeftAction || this.showRightAction) {
                return true
            } else {
                return false
            }
        },
        actionsClasses() {
            if (this.actionLeftText && this.actionRightText) {
                return "ebx-card--actions"
            } else if (this.actionLeftText) {
                return "ebx-card--actions ebx-card--actions__left"
            } else if (this.actionRightText) {
                return "ebx-card--actions ebx-card--actions__right"
            } else {
                return ""
            }
        },
        cardClasses() {
            if (this.noHover) {
                return "ebx-card ebx-card__no-hover"
            } else if (this.actions) {
                return "ebx-card ebx-card__with-actions"
            } else {
                return "ebx-card"
            }
        }
    }
}
</script>