/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["ANALYSIS_VECTOR_ATTRIBUTE_BLOCK_TITLE"] = "Calculate vector attribute  ";
Blockly.Msg["ANALYSIS_VECTOR_ATTRIBUTE_LABEL"] = "Add attribute";
Blockly.Msg["ANALYSIS_VECTOR_ATTRIBUTE_NAME_LABEL"] = "Attribute name";

export default Blockly.Msg;
