<template>
    <div>
        <md-dialog v-model:md-active="computedModalShown" class="blockly-modals ebx-dialog modal-ms">
            <md-dialog-title>
                <div class="top-actions--container">
                    <div class="title--container">
                        <span>Export data as GeoTIFF</span>
                    </div>
                    <div class="close-button">
                        <md-button class="md-primary" @click="computedModalShown = false"><md-icon>close</md-icon></md-button>
                    </div>
                </div>
            </md-dialog-title>
            <md-dialog-content class="p-0">
                <form>
                    <md-field>
                        <label for="resolution">Resolution</label>
                        <md-select name="resolution" v-model="formData.resolution" placeholder="Select">
                            <md-option v-for="option in resolutionOptions" :value="option[1]" :key="option[1]">
                            {{option[0]}}
                            </md-option>
                        </md-select>
                    </md-field>
                    <div class="muted smaller mb-20" v-if="hasDisabledResolutions">To export at a higher resolution, reduce the size of your Area of Interest</div>
                    <md-field>
                        <label for="date">Date</label>
                        <md-select name="date" v-model="formData.date" placeholder="Select">
                            <md-option v-for="option in dateOptions" :value="option[1]" :key="option[1]">
                            {{option[0]}}
                            </md-option>
                        </md-select>
                    </md-field>
                </form>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="computedModalShown = false">Cancel</md-button>
                <md-button class="md-accent md-raised ebx-button" :disabled="!canSaveExport" @click="saveModal">Save</md-button>
            </md-dialog-actions>
        </md-dialog>
        <md-dialog-alert
            v-model:md-active="showAlert"
            :md-content="alertMessage"
            md-confirm-text="Back" 
        />
    </div>
</template>

<script>
import { globalEventBus } from '@/eventbus.js'
import blocklyModalsMixin from './blocklyModalsMixin.js'

export default {
    name: 'ExportImage',
    mixins: [blocklyModalsMixin],
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        resolution: {
            type: String,
            default: null
        },
        date: {
            type: String,
            default: null
        },
        cadence: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            showAlert: false,
            alertMessage:'',
            resolutionOptions: [
                ['10m', 10],
                ['30m', 30],
                ['100m', 100],
                ['250m', 250],
                ['500m', 500],
                ['1km', 1000],
                ['5km', 5000]   
            ],
            dateOptions: [],
            datasetCadence: null,
            formData: {
                resolution: this.resolution,
                date: this.date,
                cadence: this.cadence
            },
        }
    },
    computed: {
        hasDisabledResolutions() {
            // TODO: check if the current AOI is too big for the selected resolution
            return false
        },
        canSaveExport(){
            return this.formData.resolution !== null && this.formData.date !== null
        }
    },
    methods: {
        getFieldValue() {
            return {
                resolution: this.formData.resolution,
                date: this.formData.date,
                cadence: this.formData.cadence || this.datasetCadence
            }
        },
        saveModal() {
            if(this.formData.resolution === null && this.formData.date === null) {
                this.showAlert = true
                this.alertMessage = 'Please select a resolution and date'
                return
            }
            if(this.formData.resolution === null) {
                this.showAlert = true
                this.alertMessage = 'Please select a resolution'
                return
            }
            if(this.formData.date === null) {
                this.showAlert = true
                this.alertMessage = 'Please select a date'
                return
            }
            this.blockyEventCallback('modal-save', this.getFieldValue());
        }
    },
    watch: {
        showModal: function (opened) {
            globalEventBus.$emit('supressDeleteAction', opened)
            if(opened) {
                this.formData = {
                    resolution: this.resolution,
                    date: this.date,
                    cadence: this.cadence
                }
            } else {
                this.blockyEventCallback('modal-close');
            }
        }
    }
}
</script>