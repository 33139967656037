<template>
    <div class="table-creator--row-body table-creator--row-statistic">
        <div class="md-table-cell--width-x2" colspan="2">
            <label>
                Coverage calculation
            </label>
            <md-field>
                <md-select v-model="propertySelection" placeholder="Select">
                    <md-option v-for="option in datasetOptions" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </md-option>
                </md-select>
            </md-field>
        </div>
        <div class="md-table-cell--width-x2" colspan="2">
            &nbsp;
        </div>
        <div class="md-table-cell--delete">
            <md-button class="md-icon-button" @click="$emit('delete-row', row.id)">
                <md-icon>delete</md-icon>
            </md-button>
        </div>
    </div>
</template>

<script>
import {MAP_STUDY_AREA_COLLECTION_ID} from '../../../constants/nextGenConstants'
import tableCreatorRowMixin from '../../mixins/tableCreatorRowMixin'

export default {
    name: 'TableCreatorAreaStatisticRow',
    mixins: [tableCreatorRowMixin],
    data() {
        return {
            selected: {
                dataset: this.row.dataset || null,
                dates: [],
                properties: [],
                role: this.row.aggregation || this.row.role || null,
                propertiesTransform: null,
                datesTransform: null,
                transformPreference: null,
                displayType: this.row.displayType
            },
        }
    },
    props: {
        row: {
            type: Object,
            default: () => { }
        },
        datasets: {
            type: Array,
            default: () => []
        }
    },
    emits: [
        'update-row',
        'delete-row'
    ],
    computed: {
        //override mixin
        datasetOptions() {
            return [
                {id: 'area', name: 'Coverage (hectares)'},
                {id: 'area_%', name: 'Percent of area'}
            ]
        },
        propertySelection: {
            get() {
                if(this.selected.properties.length === 0) {
                    return this.row.properties[0].name
                }
                return this.selected.properties[0].name
            },
            set(property) {
                this.selected.properties = [
                    { name: property}
                ]
            }
        }
    },
    watch: {
        /**
         * detect changes to transforms and update selected data with changes
         */
        row: {
            handler(newRow) {
                this.handleRowUpdated(newRow)
            },
            deep: true
        },
        selected: {
            handler() {
                const properties = this.selected.properties
                const dataset = this.getDataset(MAP_STUDY_AREA_COLLECTION_ID);
                var selected = this.computeSelectedRow(dataset, 'rows', 'statistic')
                selected.properties = properties
                selected.aggregation = this.propertySelection
                this.$emit('update-row', selected);
            },
            deep: true
        }
    }
}
</script>