import {ProvidesDataset} from "@/workflow/abilities/providesDataset";
import {NEXT_GEN_BLOCKS} from "@/constants/nextGenConstants";

class AbstractVisitor {
    constructor(workflowRegistry, globalDatasetService, blockId) {
        this._name = 'AbstractVisitor'
        this._id = blockId
        this._workflowRegistry = workflowRegistry;
        this._workflowState = workflowRegistry.getWorkflowForId(blockId);
        this._globalDatasetService = globalDatasetService
        this._delayedVisitBlocks = {}
    }
    /**
     * function to return the workflow state
     * @returns workflow state as javascript object
     */
     getWorkflowState() {
        return this._workflowState;
    }

    /**
     * function to recursively generates code from a top level block using a code visitor
     * @param {*} block top block in hierarchy
     * @returns generated code string
     */
    traverseBlockHierarchy = async (block) => {
        if(block.isEnabled()) {
            
            if (NEXT_GEN_BLOCKS.includes(block.type)) {
                this._workflowRegistry.appendCurrentBlockId(block.id)
                if(this._workflowState.isVisitorDelayed(block.id, this._name)) {
                    this._delayedVisitBlocks[block.id] = block
                } else {
                    //console.warn(`---- ${this._name} visitor -> traversing ${block.type}`)
                    await block.accept(this);
                }
            }
        }

        const delayedVisitors = this._workflowState.getDelayedVisitorBlockIds(block.id, this._name)
        for(let i = 0; i < delayedVisitors.length; i++) {
            const delayedVisitor = delayedVisitors[i]
            if(this._delayedVisitBlocks[delayedVisitor]) {
                // console.warn(`---- ${this._name} DELAYED visitor -> traversing ${this._delayedVisitBlocks[delayedVisitor].type}`)
                await this._delayedVisitBlocks[delayedVisitor].accept(this);
            }
        }

        let childblocks = block.getChildren(true);
        for (let i = 0; i < childblocks.length; i++) {
            block = childblocks[i];
            let childBlock = childblocks[i];
            await this.traverseBlockHierarchy(childBlock);
        }
        return this._workflowState;
    }

    hasBlockDuplicated(block) {
        if(this._workflowRegistry.currentEvent && this._workflowRegistry.currentEvent.type === 'duplicate_finished') {
            
            const event = this._workflowRegistry.currentEvent
            const eventBlockIndex = this._workflowRegistry.traversedBlocks.indexOf(event.block.id) 
            const currentBlockIndex = this._workflowRegistry.traversedBlocks.indexOf(block.id)
            // Depending on the order the top blocks are placed the traversed blocks can trigger the duplicate event
            // Use getDescendants to recursively get all children of a block. check their ids against the parameter block id
            // if the block is a child of the event block and the current block is after the event block in the traversed blocks
            // then assume its in a workflow has has been duplicated
            const childIds = [event.block.id, ...event.block.getDescendants().map(b => b.id)]
            if(childIds.includes(block.id) && eventBlockIndex >= 0 && currentBlockIndex >=0 && currentBlockIndex > eventBlockIndex) {
                return true
            } 
        }
        return false
    }

    delayVisitorExecution(blockId, visitorNames) {
        this._workflowState.delayVisitorExecution(blockId, visitorNames)
        return this
    }

    currentDataset() {
        return this._workflowState.gatherFirstAbility(ProvidesDataset);
    }
    async currentGlobalDataset() {
        const arrayItems = (await this.getGlobalDatasets(true)).onlyProjectOrigins().toArray()
        if(arrayItems.length > 0) {
            return arrayItems[0]
        }
        return null
    }


    async getGlobalDatasets(requireCurrentWorkflow = false) {
        const datasets = (await this._globalDatasetService.getForWorkflowState(this._workflowState, requireCurrentWorkflow));
        return datasets.distinct().notSubscribedTo(this._workflowState.id, this._workflowRegistry)
    }

    visitWorkflowClassifyBlock() {}
    visitWorkflowCompareBlock() {}
    visitWorkflowEmptyContainerBlock() {}
    visitWorkflowDefineDatasetBlock() {}
    visitAnalysisCalculatorBlock() {}
    visitDetectChangeBlock() {}
    visitIndicesBlock() {}
    visitAnalysisFocalAnalysisBlock() {}
    visitAnalysisZonalStatisticsBlock() {}
    visitRegressionBlock() {}
    visitAnalysisThematicProcessingBlock() {}
    visitBufferBlock() {}
    visitAnalysisVectorAttributeBlock() {}
    visitAnalysisGeoprocessingBlock() {}
    visitAnalysisConvertFeaturesBlock() {}
    visitInputDatasetBlock() {}
    visitModifierDaterangeBlock(){}
    visitModifierAttributeSelectBlock() {}
    visitModifierFilterAttributesBlock() {}
    visitModifierBandsBlock() {}
    visitModifierRemoveAttributesBlock() {}
    visitModifierRemoveBandsBlock() {}
    visitModifierCloudMaskBlock() {}
    visitModifierCompositeBlock() {}
    visitModifierMaskBlock() {}
    visitModifierOrbitBlock() {}
    visitModifierUnMaskBlock() {}
    visitModifierReAssignClassesBlock() {}
    visitModifierTimePeriodBlock() {}

    visitOutputAddTableBlock() {}
    visitAddMapLayerBlock() {}
    visitOutputSaveDatasetBlock() {}
    visitStudyAreaBlock() {}
    visitOutputMultitemporalBlock() {}
    visitWorkflowUnsupervisedClassificationBlock() {}
    visitOutputImage() {}
    visitAnalysisConvertImagesBlock() {}
    
}

export {AbstractVisitor}
