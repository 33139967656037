import {DatasetService} from '@/services/dataset.service.js'

const BLOCKS_WITH_OUTPUTS = {
    'output_map_layer': block=> {
        return {
            type: 'map_layer',
            description: block.fields.modal_field === undefined ? null : block.fields.modal_field.layerDescription,
            name: block.fields.layer_name,
        }
    }, 
    'output_add_table': block => {
        return {
            type: 'table',
            description: block.fields.TABLE === undefined ? null : block.fields.TABLE.tableDescription,
            name: block.fields.table_name,
        }
    }, 
}

const BLOCKS_WITH_DATASETS = {
    'workflow_insert_dataset': block => {
        return {
            id: block.fields.dataset_options,
        }
    }
}

const blockOutputHandler = (handlers) => (block) => {
    if (Object.keys(handlers).includes(block.type)) {
        return {
            id: block.id,
            block_type: block.type,
            ...handlers[block.type](block)
        }
    }
}

const parseBlocklyBlockJson = function (state, handler) {
    let outputs = [];
    if(state === null) {
        return outputs
    }
    const objectKeys = Object.keys(state);
    if (objectKeys.includes('type') && objectKeys.includes('fields')) {
        const result = handler(state)
        if (result) {
            outputs.push(result)
        }
    }
    objectKeys.forEach(key => {
        if (Array.isArray(state[key])) {
            const nestedOutputs = state[key].flatMap(item => parseBlocklyBlockJson(item, handler))
            outputs = outputs.concat(nestedOutputs)
        }else if (typeof state[key] === 'object') {
            const nestedOutputs = parseBlocklyBlockJson(state[key], handler)
            outputs = outputs.concat(nestedOutputs)
        } 
    })
    return outputs
}

export default {
    methods: {
        workflowGetOutputs(workflowBlockDefinition) {
            return parseBlocklyBlockJson(workflowBlockDefinition, blockOutputHandler(BLOCKS_WITH_OUTPUTS))
        },
        workflowDocGetDatasets(workflow) {
            let datasets = []
            if (workflow && workflow.datasets) { 
                datasets = workflow.datasets
            }
            return datasets
        },
        async getWorkflowDatasetsWithService(workflow) {
            const datasets = this.workflowDocGetDatasets(workflow)
            if (datasets.length > 0) {
                await DatasetService.loadDatasetList()
                const globalDatasets = await DatasetService.getDatasetModalContent()
                return globalDatasets.filter(globalDataset => datasets.some(dataset => dataset.id === globalDataset.id))
            } else {
                return []
            }
        }
    }
}

export const getWorkflowDatasetsIds = async function(workflowBlockDefinition) {
    // get dataset ids from workflow json
    return parseBlocklyBlockJson(workflowBlockDefinition, blockOutputHandler(BLOCKS_WITH_DATASETS))
}