/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import {AbstractVisitor} from "./helpers/AbstractVisitor";
import {NO_DATA_DROPDOWN_VALUE} from "@/constants/nextGenConstants";

class SubscribeWorkflowVisitor extends AbstractVisitor {
    constructor(workflowRegistry, globalDatasetService, blockId) {
        super(workflowRegistry, globalDatasetService, blockId)
        this._name = "SubscribeWorkflowVisitor";
    }

    /**
     * Function that stores the actions we perform when visitor visits define block
     * @param {*} block 
     */
    visitWorkflowDefineDatasetBlock(block) {
        console.log('Subscribe visitor -> workflow define dataset block', block)
        const selectedDataset = block.getFieldValue('dataset_options')
        this.subscribeToDatasetVariable(selectedDataset, true)
    }

    visitModifierAttributeSelectBlock(block) {
        console.log('Subscribe visitor -> attribute select block', block);
    }

    visitIndicesBlock(block) {
        console.log('Subscribe visitor -> indices block', block);
    }

    /**
     * Function that stores the actions we perform when visitor visits modifier_mask block
     * @param {*} block 
     */
    visitModifierMaskBlock(block) {
        console.log('Capture visitor -> modifier mask block', block)
        const selectedDataset = block.getFieldValue('dataset')
        if(
        selectedDataset !== undefined && 
        selectedDataset !== NO_DATA_DROPDOWN_VALUE &&
        this._id  !== selectedDataset) {
            this._workflowRegistry.subscribeHidden(this._id, selectedDataset)
        }
    }

    visitModifierCompositeBlock(block) {
        console.log('Subscribe visitor -> modifier composite block', block)
    }

    visitModifierDaterangeBlock(block) {
        console.log('Subscribe visitor -> date range block', block);
    }

    visitModifierOrbitBlock(block) {
        console.log('Subscribe visitor -> modifier orbit block', block)
    }

    visitStudyAreaBlock(block) {
        console.log('Subscribe visitor -> study area block', block)
        const studyAreaField = block.getField('study_area_options')
        if(studyAreaField && studyAreaField.getValue() !== NO_DATA_DROPDOWN_VALUE) {
            // If we have a variable selected we need to get its value
            let areaId = studyAreaField.getVariableValue() // falls back to field value if no variable is selected
            // Subsrcibe to the selected variable
            if(studyAreaField.isVariable()) {
                this.subscribeToDatasetVariable(studyAreaField.getVariable().getId())
            }
            // Regardless of subscribing to the variable we always need to subscribe to the selected area if done so
            if(areaId !== null) {
                this.subscribeToDatasetVariable(areaId)
            }
        }
        
    }
    visitAddMapLayerBlock(block) {
        console.log('Subscribe visitor -> add map layer block', block);
       
    }
    visitTimePeriodBlock(block) {
        console.log('Subscribe visitor -> time period block', block);
    }

    visitModifierCloudMaskBlock(block) {
        console.log('Subscribe visitor -> cloud mask block', block);
    }
    visitOutputSaveDatasetBlock(block) {
        console.log('Subscribe visitor -> save dataset block', block);
       
    }
    visitInputDatasetBlock(block) {
        console.log('Subscribe visitor -> input dataset block', block);
        const selectedDataset = block.getFieldValue('selected_dataset')
        this.subscribeToDatasetVariable(selectedDataset)
    }
    visitWorkflowCompareBlock(block) {
        console.log('Subscribe visitor -> workflow_compare block', block);
    }
    visitWorkflowEmptyContainerBlock(block) {
        console.log('Subscribe visitor -> workflow_empty_container block', block);
    }
    visitModifierFilterAttributesBlock(block) {
        console.log('Subscribe visitor -> modifier filter attributes block', block);
    }
    visitModifierBandsBlock(block) {
        console.log('Subscribe visitor -> modifier bands block', block);
    }
    visitModifierTimePeriodBlock(block) { 
        console.log('Subscribe visitor -> modifier bands block', block);
    }
    
    visitWorkflowClassifyBlock(block) {
        console.log('Subscribe visitor -> workflow classify block', block);
        const connection = block.getInput('dataset').connection
        if(connection.isConnected()) {
            const descendants = connection.targetConnection.getSourceBlock().getDescendants()
            this.delayVisitorExecution(descendants[descendants.length -1].id, ['CaptureStateVisitor','PopulateStateVisitor'])
        }
        this.subscribeToDatasetVariable(block.getFieldValue('training_data'), true)
    }
    visitWorkflowUnsupervisedClassificationBlock(block) {
        const connection = block.getInput('dataset').connection
        if(connection.isConnected()) {
            const descendants = connection.targetConnection.getSourceBlock().getDescendants()
            this.delayVisitorExecution(descendants[descendants.length -1].id, ['CaptureStateVisitor','PopulateStateVisitor'])
        }
    }

    visitOutputMultitemporalBlock(block) {
        console.log('Subscribe visitor -> output block', block);
    }
    visitOutputAddTableBlock(block) {
        const modal = block.getFieldValue(block.FIELD.TABLE)

        if (modal === null) {
            return;
        }

        const datasetType = block.getState('dataset_type');

        // non raster datasets don't have ability to subscribe to other datasets
        if (datasetType !== 'raster') {
            return;
        }

        const createdRows = block.getFieldValue(block.FIELD.TABLE).createdRows;

        // validate created rows exists
        if (!createdRows) {
            return;
        }
        
        var datasetIds = createdRows.map(row => row.dataset);

        datasetIds = datasetIds.filter(id => id !== this._id);
        datasetIds.forEach(id => this.subscribeToDatasetVariable(id, true));
    }
    visitAnalysisCalculatorBlock(block) {
        console.log('Subscribe visitor -> calculator block', block);
        const calculatorData = block.getFieldValue('calculator')
        if(calculatorData && calculatorData.definedVariables) {
            calculatorData.definedVariables
                .filter(definedVariable => [null, undefined].indexOf(definedVariable.dataset) < 0 )
                .forEach(definedVariable => {
                    this.subscribeToDatasetVariable(definedVariable.dataset, true)
                })
        }
    }
    visitAnalysisfocalAnalysisBlock(block) { 
        console.log('Subscribe visitor -> focal analysis block', block)
    }
    visitAnalysisZonalStatisticsBlock(block){
        console.log('Subscribe visitor -> zonal statistics block', block)
        this.subscribeToDatasetVariable(block.getFieldValue(block.FIELD.DATASET), true)
    }
    visitRegressionBlock(block) {
        console.log('Subscribe visitor -> regression block', block);
        this.subscribeToDatasetVariable(block.getFieldValue(block.FIELD.TRAINING_DATASET), true);
    }
    visitAnalysisVectorAttributeBlock(block){
        console.log('Subscribe visitor -> vector attribute block', block)
    }
    visitAnalysisGeoprocessingBlock(block){
        const geoprocessingType = block.getFieldValue(block.FIELD.PROCESSING_OPTIONS)
        // no collection to subscribe to
        if (block.NO_COLLECTION_VALS.includes(geoprocessingType)) {
            return;
        }

        this.subscribeToDatasetVariable(block.getFieldValue(block.FIELD.COLLECTION_OPTIONS), true)
    }
    visitAnalysisConvertFeaturesBlock(block){
        console.log('Subscribe visitor -> convert features block', block)
    }

    /**
     * Helper method to subscribt to a global dataset/variable
     * @param {*} selectedDataset 
     * @param {*} hidden 
     * @returns {this}
     */
    subscribeToDatasetVariable(selectedDataset, hidden=false) {
        if(selectedDataset !== undefined && selectedDataset !== NO_DATA_DROPDOWN_VALUE) {
            if (hidden) {
                this._workflowRegistry.subscribeHidden(this._id, selectedDataset)
            } else {
                this._workflowRegistry.subscribe(this._id, selectedDataset)
            }
        }
        return this
    }
}

export {SubscribeWorkflowVisitor}
