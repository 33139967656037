/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/*
* DEFINE L-Block
*/

import * as Blockly from 'blockly/core';
import { DatasetService } from '@/services/dataset.service';
import { ContentService } from '@/services/content.service';
import { AuthService } from '@/services/auth.service'
import {togglePlayButton, addRunButtonInput, addStopButtonInput} from '@/blocks/mixins/togglePlayButtons'
import {NO_DATA_DROPDOWN_VALUE} from '@/constants/nextGenConstants';
import Dataset from '@/modals/InsertDataset.vue';
import assets from '@/assets.js';
import { AbstractBlock, AbstractFieldHelpers,AbstractVariableHelpers } from '@/blocks/_abstractBlock';
import { sharedVariableMethodsMixin } from "@/fields/sharedVariableMethodsMixin.js";

var DatasetOptions = []

const FIELD = Object.freeze({
    DATASET: 'dataset_options'
})

const INPUT = Object.freeze({
    MODIFIERS: 'modifiers',
    DATASET_OPTIONS: 'extra_dataset_button',
    DATASET_MODAL_BUTTON: 'dataset_modal_button'
})

const emptyOption = ["Search datasets", NO_DATA_DROPDOWN_VALUE]

var defineDatasetJson = {
    "type": "workflow_insert_dataset",
    "lastDummyAlign0": "RIGHT",
    "message0": "%1 %2 %3 %4 %5 %6",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "block_title",
            "text": "%{BKY_WORKFLOW_INSERT_DATASET_LABEL}", 
            "class": "boldTitleField"
        },
        {
            "type": "field_input",
            "name": FIELD.DATASET,
            "options": emptyOption[1],
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_dataset_choose",
            "name": "dataset_modal_button",
            "modal_component": Dataset,
            "modal_data": DatasetOptions,
            "src": assets.blockly.travelExplore,
            "width": 250,
            "height": 36,
            "opt_alt": "ALT STRING"
        },
        {
            "type": "input_dummy",
            'name': INPUT.DATASET_MODAL_BUTTON
        },
        {
            "type": "input_statement",
            "name": INPUT.MODIFIERS
        }
    ],
    "previousStatement": null,
    "style": 'dataset',
    "tooltip": "",
    "helpUrl": "",
}

Blockly.Blocks['workflow_insert_dataset'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    ...AbstractVariableHelpers,
    togglePlayButton,
    addRunButtonInput,
    addStopButtonInput,

    onInit: function() {

        this.jsonInit(defineDatasetJson);
        this.addRunButtonInput();

        this.getField('dataset_options').setValidator(this.datasetValidator)

        Object.assign(this.getField('dataset_options'), sharedVariableMethodsMixin);

        this.getField('dataset_options').setVisible(false)
        this.getField('dataset_options').setVisible = function() {
            this.visible_ = false;
        }

        this.about_block_url = ContentService.getAboutBlockUrl('workflow_insert_dataset')

        this.user = null;

        this.authSubscription = AuthService.loggedUser$.subscribe((user) => {
            let result = user
            if(!result) {
                this.authSubscription.unsubscribe();
            }
            this.user = result
        });
        
        const datasetDropdownOptions = DatasetService.getDatasetTitlesIds();
        if (typeof datasetDropdownOptions.then === 'function') {
            this.setEnabled(false)
            datasetDropdownOptions
                .then(options => {
                    options = [emptyOption, ...options]
                    this.datasetDropdownOptions = options
                    this.setFieldValue(options[0][1], 'dataset_options')
                    this.setEnabled(true)
                })
                .catch(err => {
                    console.error(err)
                    this.setEnabled(true)
                }) 
        } else {
            this.datasetDropdownOptions = [emptyOption, ...datasetDropdownOptions]
            this.setFieldValue(this.datasetDropdownOptions[0][1], 'dataset_options')
        }

        this.getField('dataset_modal_button')
            .addEventListener('insert-dataset', (datasetId, blocksToAdd) => {
                this.setFieldValue(datasetId, 'dataset_options')

                if(blocksToAdd.length > 0) {
                    this.insertChildBlocks(blocksToAdd)
                }
            })
            .setModalData('block', this)
            .setModalData('selectedDatasetValue', this.getFieldValue('dataset_options'))

    },
    datasetValidator(newValue) {
        const block = this.getSourceBlock();
        block.updateDatasetLabelText(newValue)
        block.getField('dataset_modal_button').setModalData('selectedDatasetValue', newValue)
        return newValue
    },

    getDatasetLabel(datasetId) {
        const dataset_options = this.datasetDropdownOptions
        if(dataset_options && !this.isInFlyout) {
            const foundDatasets = dataset_options.find(op => op[1] === datasetId)
            if (foundDatasets !== undefined) {
                return foundDatasets[0]
            } else {
                const globalDatasets = this.getState('global_datasets')
                if (globalDatasets && globalDatasets.length > 0) {
                    const foundGlobalDataset = this.getState('global_datasets').find(op => op.id === datasetId)
                    if (foundGlobalDataset !== undefined) {
                        return foundGlobalDataset.title
                    }
                }
            }
        }
        return null
    },

    updateDatasetLabelText(newValue = null) {
        if(this.isInFlyout) {
            return
        }
        if (newValue === null) {
            newValue = this.getFieldValue('dataset_options')
        }
        const datasetLabel = this.getDatasetLabel(newValue);
        if(datasetLabel) {
            this.setDatasetLabel(this, datasetLabel, newValue)
        } else {
            this.setDatasetLabel(this, 'unknown', newValue)
        }
    },

    setDatasetLabel(block, datasetName = block.getFieldValue('dataset_options'), newKey = null) {
        if (datasetName.length > 40) {
            datasetName = datasetName.substring(0, 40) + '...'
        }
        block.getField('dataset_modal_button').setText(datasetName)
        if(this.inputExists(INPUT.DATASET_OPTIONS) === false && newKey !== NO_DATA_DROPDOWN_VALUE) {
            const input = block.appendDummyInput(INPUT.DATASET_OPTIONS)
            
            input.appendField("  View or change dataset")
            input.appendField(new Blockly.FieldImage(assets.blockly.travelExplore, 24,24, "*"));

            input.fieldRow.forEach(field => {
                if(field.imageElement_) {
                    field.imageElement_.style.cursor = 'pointer'
                }
                if(field.textElement_) {
                    field.textElement_.style.cursor = 'pointer'
                }
                field.showEditor_ = () => {
                    this.getField('dataset_modal_button').showEditor_()
                }
            })
            block.moveInputAfter(INPUT.DATASET_OPTIONS, INPUT.DATASET_MODAL_BUTTON)
        }
    },

    onSaveExtraState: function(extraState) {
        const dropdown_value = this.getFieldValue('dataset_options')
        let dropdown_options = []

        if (this.datasetDropdownOptions) {
            dropdown_options = this.datasetDropdownOptions.find(op => op[1] === dropdown_value)
        } else {
            dropdown_options = [emptyOption]
        }
        return {
            ...extraState,
            dropdown_value,
            dropdown_options
        }
    },
    
    accept: async function (visitor) {
        await visitor.visitWorkflowDefineDatasetBlock(this);
    },
    
    /**
     * Respond to a change in the define block options. 
     * Updates internal definition state.
     *
     * @param {string} newValue
     * @return {string} validated value 
     */
    
    updateShape_: function() {
        // check if block has parent, then remove the play button
        this.togglePlayButton()
        if(this.isLoadingState() && this.hasState('dropdown_value') && this.getState('dropdown_value') !== NO_DATA_DROPDOWN_VALUE) {
            const value = this.getState('dropdown_value')
            this.setFieldValue(value, 'dataset_options')
            this.removeState('dropdown_options')
        }
        if(this.hasStateChanged('global_datasets')) {
            this.updateDatasetLabelText()
            this.getField('dataset_modal_button').setModalData('allowedGlobalDatasetIds', this.getState('global_datasets').map(d => d.id))
        }
        
        // this.setDatasetLabel(this, this.getFieldValue('dataset_options').getText(), this.getFieldValue('dataset_options'))
    },

    /**
     * For a list of blocks, remove all children and add the new blocks
     * @param {*} blocksToAdd 
     */
    insertChildBlocks(blocksToAdd) {
        let connection = this.getInput(INPUT.MODIFIERS).connection
        
        this.getChildren().forEach(child => {
            child.dispose()
        })

        blocksToAdd.forEach(blockDetail => {

            var newBlock = this.workspace.newBlock(blockDetail.type);
            if (blockDetail.fields) {
                newBlock.recommendedFields = blockDetail.fields
            }
            connection.connect(newBlock.previousConnection);
            newBlock.initSvg();
            newBlock.render();

            connection = newBlock.nextConnection
        })
    
    },

    ebxValidate: function(tooltips) {
        const datasetLabel = this.getDatasetLabel(this.getFieldValue('dataset_options'));
        if(datasetLabel === null) {
            return this.setWarningText(tooltips['no_dataset_found'] || 'Choose a dataset to use in this workflow', 'no_dataset_found')
        } else {
            this.setWarningText(null, 'no_dataset_found')
        }
    }
};