<template>
    <div>
    <md-dialog v-model:md-active="computedModalShown" class="blockly-modals">
        <md-dialog-title>
            <span>Algorithm options</span>
        </md-dialog-title>

        <form v-if="algorithm == 'random_forest'">
            <md-field :class="getValidationClass('number_trees')">
                <label>Number of trees</label>
                <md-input v-model="formData.number_trees" type="number"></md-input>
                <p class="md-error" v-if="v$.formData.number_trees.$invalid">Number of trees must be entered and be a number</p>
            </md-field>
            <md-field :class="getValidationClass('variables')">
                <label>Variables per split</label>
                <md-input v-model="formData.variables" type="number"></md-input>
                <p class="md-error" v-if="v$.formData.variables.$invalid">Number of variables must be a number</p>
            </md-field>
            <md-field :class="getValidationClass('leaf_pop')">
                <label>Min leaf population</label>
                <md-input v-model="formData.leaf_pop" type="number"></md-input>
                <p class="md-error" v-if="v$.formData.leaf_pop.$invalid">Min leaf population must be entered and be a number</p>
            </md-field>
            <md-field :class="getValidationClass('bag_fraction')">
                <label>Bag fraction</label>
                <md-input v-model="formData.bag_fraction" type="number"></md-input>
                <p class="md-error" v-if="v$.formData.bag_fraction.$invalid">Bag fraction must be entered and be a number</p>
            </md-field>
            <md-field :class="getValidationClass('max_nodes')">
                <label>Max nodes</label>
                <md-input v-model="formData.max_nodes" type="number"></md-input>
                <p class="md-error" v-if="v$.formData.max_nodes.$invalid">Max nodes must be a number</p>
            </md-field>
            <md-field :class="getValidationClass('seed')">
                <label>Seed</label>
                <md-input v-model="formData.seed" type="number"></md-input>
                <p class="md-error" v-if="v$.formData.seed.$invalid">Seed must be entered and be a number</p>
            </md-field>
        </form>
        <form v-else-if = "formData.algorithm == 'cart'"> 
            <md-field :class="getValidationClass('max_nodes')">
                <label>Max nodes</label>
                <md-input v-model="formData.max_nodes" type="number"></md-input>
                <p class="md-error" v-if="v$.formData.max_nodes.$invalid">Max nodes must be a number</p>
            </md-field>
            <md-field :class="getValidationClass('leaf_pop')">
                <label>Min leaf population</label>
                <md-input v-model="formData.leaf_pop" type="number"></md-input>
                <p class="md-error" v-if="v$.formData.leaf_pop.$invalid">Min leaf population must be entered and be a number</p>
            </md-field>
        </form>
        <form v-else-if = "algorithm == 'min_distance'"> 
            <md-field :class="getValidationClass('metric')">
                <label for="metric">Metric</label> 
                <md-select name="metric" v-model="formData.metric">
                    <md-option v-for="metric in metrics" :value="metric[1]" :key="metric[1]">
                        {{ metric[0]}}
                    </md-option>
                </md-select>
                <p class="md-error" v-if="v$.formData.metric.$invalid">Metric must be chosen</p>
            </md-field>
            <md-field :class="getValidationClass('knearest')">
                <label>kNearest</label>
                <md-input v-model="formData.knearest" type="number"></md-input>
                <p class="md-error" v-if="v$.formData.knearest.$invalid">kNearest must be entered and be a number</p>
            </md-field>
        </form>
        <form v-else-if = "formData.algorithm == 'svm'"> 
            <md-field :class="getValidationClass('kernel')">
                <label for="kernel">Kernel</label> 
                <md-select name="kernel" v-model="formData.kernel">
                    <md-option v-for="kernel in kernels" :value="kernel[1]" :key="kernel[1]">
                        {{ kernel[0]}}
                    </md-option>
                    <p class="md-error" v-if="v$.formData.kernel.$invalid">kernel must be chosen</p>
                </md-select>
            </md-field>
            <md-field :class="getValidationClass('cost')">
                <label>Cost</label>
                <md-input v-model="formData.cost" type="number"></md-input>
                <p class="md-error" v-if="v$.formData.cost.$invalid">Cost must be entered and be a number</p>
            </md-field>
            <md-field :class="getValidationClass('gamma')">
                <label>Gamma</label>
                <md-input v-model="formData.gamma" type="number"></md-input>
                <p class="md-error" v-if="v$.formData.gamma.$invalid">Gamma must be entered and be a number</p>
            </md-field>
        </form>

        <md-dialog-actions>
            <md-button class="md-primary" @click="computedModalShown = false">Close</md-button>
            <md-button class="md-primary" :disabled="v$.$invalid" @click="saveModal">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
    </div>
</template>

<script>
import { globalEventBus } from '@/eventbus.js'
import { useVuelidate } from '@vuelidate/core'
import { required, integer, decimal, requiredIf } from '@vuelidate/validators'
import blocklyModalsMixin from './blocklyModalsMixin.js'

export default {
    name: 'Algorithm',
    setup: () => ({ v$: useVuelidate() }),
    mixins: [blocklyModalsMixin],
    props: {
        algorithm: {
            type: String,
            default: 'random_forest'
        },
        number_trees: {
            type:Number,
            default: () => {}
        },
        variables: {
            type:Number,
            default: () => {}
        },
        leaf_pop: {
            type:Number,
            default: () => {}
        },
        bag_fraction: {
            type:Number,
            default: () => {}
        },
        max_nodes: {
            type:Number,
            default: () => {}
        },
        seed: {
            type:Number, 
            default: () => {}
        },
        metric: {
            type: Array,
            default: () => ['euclidean']
        },
        knearest: {
            type:Number, 
            default: () => {}
        },
        showModal: {
            type: Boolean,
            default: false
        },
        kernel: {
            type: Array,
            default: () => ['rbf']
        },
        cost: {
            type:Number, 
            default: () => {}
        },
        gamma: {
            type:Number, 
            default: () => {}
        },
    },
    data() {
        return {
            formData: {
                algorithm: this.algorithm,
                number_trees: this.number_trees,
                variables: this.variables,
                leaf_pop: this.leaf_pop,
                bag_fraction: this.bag_fraction,
                max_nodes: this.max_nodes,
                seed: this.seed,
                metric: this.metric,
                knearest: this.knearest,
                kernel: this.kernel,
                cost: this.cost,
                gamma: this.gamma,
            },
        }
    },
    methods: {
        getFieldValue() {
            return {
                algorithm: this.formData.algorithm,
                number_trees: this.formData.number_trees,
                variables: this.formData.variables,
                leaf_pop: this.formData.leaf_pop,
                bag_fraction: this.formData.bag_fraction,
                max_nodes: this.formData.max_nodes,
                seed: this.formData.seed,
                metric: this.formData.metric,
                knearest: this.formData.knearest,
                kernel: this.formData.kernel,
                cost: this.formData.cost,
                gamma: this.formData.gamma,
            };
        },
        saveModal() {
            if (this.v$.$invalid) {
                return;
            }
            this.blockyEventCallback('modal-save', this.getFieldValue());
        },
        getValidationClass(fieldName) {
            const field = this.v$.formData[fieldName];
            if (field) {
                return {
                    "md-invalid": field.$invalid
                };
            }
        },
    },
    watch: {
        showModal(val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if(val) {
                this.formData = {
                    algorithm: this.algorithm,
                    number_trees: this.number_trees,
                    variables: this.variables,
                    leaf_pop: this.leaf_pop,
                    bag_fraction: this.bag_fraction,
                    max_nodes: this.max_nodes,
                    seed: this.seed,
                    metric: this.metric,
                    knearest: this.knearest,
                    kernel: this.kernel,
                    cost: this.cost,
                    gamma: this.gamma,
                }
            }
        }
    },
    validations () {
        return {
            formData: {
                algorithm: {
                    required
                },
                number_trees: {
                    requiredIfForest: requiredIf(this.formData.algorithm === 'random_forest'),
                    integer
                },
                variables: {
                    integer
                },
                leaf_pop: {
                    requiredIfForestCart: requiredIf(this.formData.algorithm === 'random_forest' || this.formData.algorithm === 'cart'),
                    integer
                },
                bag_fraction: {
                    requiredIfForest: requiredIf(this.formData.algorithm === 'random_forest'),
                    decimal
                },
                max_nodes: {
                    integer
                },
                seed: {
                    requiredIfForest: requiredIf(this.formData.algorithm === 'random_forest'),
                    integer
                },
                metric: {
                    requiredIfMinDistance: requiredIf(this.formData.algorithm === 'min_distance')
                },    
                knearest: {
                    requiredIfMinDistance: requiredIf(this.formData.algorithm === 'min_distance'),
                    integer
                },
                kernel: {
                    requiredIfSVM: requiredIf(this.formData.algorithm === 'svm')
                },
                cost: {
                    requiredIfSVM: requiredIf(this.formData.algorithm === 'svm'),
                    decimal
                },
                gamma: {
                    requiredIfSVM: requiredIf(this.formData.algorithm === 'svm'),
                    decimal
                },

            }
        }
    }
}
</script>