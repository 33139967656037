<template>
    <div>
        <md-dialog v-model:md-active="showDialog" :md-close-on-esc="false" :md-click-outside-to-close="false">
       <md-dialog-title v-if="tncsTitle">{{tncsTitle}} {{ tncsVersion }}</md-dialog-title>
      
        <md-dialog-content class="iframe-wrapper">
            
            <p v-if=" !iFrameLoaded">{{pendingTxt}}</p>
            <iframe :src="tncsDoc" v-on:load="onFrameLoaded" v-show="iFrameLoaded">
              
            </iframe>  

        </md-dialog-content>
        
        
        <md-dialog-actions>
            <md-button v-if="tncsRejectButtonTxt" class="md-primary" @click="rejectTerms()">{{tncsRejectButtonTxt}}</md-button>
            <md-button :disabled="computedDisableAccept" class="md-primary md-raised" @click="acceptTerms()">{{tncsAcceptButtonTxt}}</md-button>
        </md-dialog-actions>
        </md-dialog>

        <md-dialog-alert v-model:md-active="showAlert"
            :md-content="tncsRejectAlertContent"
            :md-confirm-text="tncsRejectAlertConfirm" />

    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
   // 

    import { functions } from "../firebaseFunctions.js";
    import { AuthService } from "@/services/auth.service";

    export default {
        name: 'TermsDialog',
        props: {
          
            tncsDoc:{type:String, required:true},
            tncsVersion:String,
            tncsTitle:String,
            tncsAcceptButtonTxt:{ type:String, default:"I have read and accept terms and conditions"},
            tncsRejectButtonTxt:{ type:String},
            tncsRejectAlertContent: { type:String, default:""},
            tncsRejectAlertConfirm:{ type:String, default:"OK"},
            disableAccept:{ type:Boolean, default:true}
        },
        emits: [
            'disable-accept'
        ],
        data: () => ({
            user: null,
            showDialog: true,  
            showAlert: false,
            iFrameLoaded: false,
            pendingTxt: "Loading...",
        }),
        computed: {
            computedDisableAccept: {
                get: function() {
                    return this.disableAccept;
                },
                set: function(newValue) {
                    this.$emit('disable-accept', newValue);
                }
            }
        },
        created() {
            let self = this;
            this.subscription = AuthService.loggedUser$.subscribe((user) => {
                self.user = user;
            })
        },
        beforeUnmount() {
            this.subscription.unsubscribe();
        },

         methods: {
            acceptTerms: function() {
                    
                    let userTermsClaims = {
                            termsAgreed: true,
                            termsVersion: this.tncsVersion
                            }

                    this.user = Object.assign(this.user, userTermsClaims);
            
                    this.pendingTxt = "Updating your credentials..."
                    this.iFrameLoaded = false ;
                    this.computedDisableAccept = true ;
                    functions.acceptTerms(this.user).then(() => {
                           AuthService.refreshCurrentUser(this.user);
                        });
                
            },
            rejectTerms: function() {        
                    this.showDialog = false;
                    if(this.tncsRejectAlertContent.length > 0){
                        this.showAlert = true ;
                    }
            },
            onFrameLoaded(ev){
                console.log(ev.target.tagName + " " + ev.type ) ;
                if(this.tncsDoc.length > 0) 
                {
                    this.iFrameLoaded = true ;
                    this.computedDisableAccept = false ;
                }
            },
          
         } //ends methods
    }
</script>

<style lang="scss" scoped>
    .md-dialog-content {
        width: 100%;
    }
    .md-dialog-content.iframe-wrapper {
        padding:0;
    }
    .md-dialog .md-dialog-container {
        max-width: 768px;
        width: 80%;
    }
    .md-dialog-content .error-text{
        color: #ff0000;
    }
    iframe {
        border: none;
        width: 100%;
        min-height: 400px;
    }

   


</style>
