<template>
    <div class="md-app-content md-flex md-layout-column md-theme-default"  :id="id" :tabindex="tabindex">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'MdAppContent',
    props: {
        id: {
            type: String,
            default: 'md-app-content'
        },
        tabindex: {
            type: String,
            default: ''
        }
    }
}
</script>
<style lang="scss">
  .md-app-content {
    //min-height: 100%;

    .md-card {
      margin-right: 16px;
      margin-left: 16px;
      overflow: visible;
    }
  }
</style>