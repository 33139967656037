/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import { strataZones, strataCountries, countries  } from '@/countryData/countryList.js';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { MAP_STUDY_AREA_COLLECTION_ID, NO_DATA_DROPDOWN_VALUE } from "@/constants/nextGenConstants";
import booleanContains from '@turf/boolean-contains';
import {polygon} from '@turf/helpers';
import { DatasetService } from './dataset.service';
import {GEE_SCHEMA, } from "@/constants/ebxStacConstants";
import { reactive, toRaw } from 'vue';

/**
 * service contains areas and shapes,
 * areas are collections of shapes, such that
 * areas: [
 *  {
 *      id: String,
 *      colour: String,
 *      collectionId: String(uuid),
 *      name: String
 *  }
 * ]
 *  collections: [
 *  {
 *      id: String(uuid),
 *      name: String
 *  }
 * ]
 * sometimes areas may not have an id field and instead have a name field,
 * these are the same
 *
 * shapes can be of two types (currently), "UserDrawn" and "UserUploaded"
 *
 * We may also consider adding country bounds so that when a user selects a country
 * we pan and outline it in the map (look at EarthMap for example)
 *
 * shapes: [{
 *   id: String, (uuid), required
 *   areaID: String, required - foreign key
 *   type: String (UserDrawn or UserUploaded), required,
 *   geometryType: String (Polygon, Point), required for UserDrawn,
 *   overlay: GoogleMapsOverlay, required for UserDrawn and will be created for UserUploaded,
 *   bbox: {
 *     'SW': [Number, Number],
 *     'NE': [Number, Number]
 *   }, required for UserUploaded
 *   mapURL: String, required for UserUploaded,
 *   assetID: String, required for UserUploaded
 *  }]
 */


let service = reactive({
    areas: [],
    collections: [],
    shapes: [],
    selectedArea: {},
    selectedCollection: null,

    // RXJS Subjects
    shapeUpdated: new Subject(),
    clearAreas: new Subject(),
    updatedDatasetBbox: new Subject(),
    areaChange: new Subject(),
    areaRemoved: new Subject(),
    collectionChange: new Subject(),
    userUploadedShape: new Subject(),
    clearCollections:new Subject(),
    selectedAreaUpdate: new Subject(),
    pinnedAreasUpdate: new Subject(),
    pendingEvents: [],
    mapReady: false,
    hasPinnedAreas: false
})

const awaitMapReady = (service, callback) => {
    if(service.mapReady) {
        callback()
    }else {
        service.pendingEvents.push(callback)
    }
}


const AreaService = {
    // GETTERS
    getHasPinnedAreas() {
        return service.hasPinnedAreas
    },
    /**
     * Returns all custom areas AND countries in blockly dropdown format
     *
     * @param {String} blockCategory optional - if not provided, returns all areas else returns areas for the given block category ("strata")
     * @returns
     */
    getAreas(blockCategory) {
        if(blockCategory === "strata") {
            return {'zones': strataZones.slice(), 'countries': Object.assign({}, strataCountries)};
        } else {
            return this.getUserAreas()
            // removed coutnries from area selection
            // return this.getUserAreas().concat(countries.slice());
        }
    },
    getFeatureViewAreas() {
        return service.areas;
    },
    getAreaName(areaId) {
        let idx = this.findAreaIndex(areaId)
        if (idx !== -1) {
            return service.areas[idx].name
        }
        return null
    },
    getCollectionName(collectionId) {
        return this.getCollectionById(collectionId).name
    },
    setMapReady(ready) {
        service.mapReady = ready === true
        if(service.mapReady) {
            service.pendingEvents.forEach(callback => callback())
            service.pendingEvents = []
        }
    },
    getStudyAreas() {
        if(service.areas.filter(area => area.collectionId === MAP_STUDY_AREA_COLLECTION_ID).length === 0) {
            // if there are no custom areas return NA
            return [["No area selected", NO_DATA_DROPDOWN_VALUE]];
        } else {
            let aoiAreas = service.areas
                .filter(area => area.collectionId === MAP_STUDY_AREA_COLLECTION_ID)
                .map(area => [area.name, area.id]);
            return aoiAreas;
        }
    },
    /**
     * Get an array of collections
     * @param {*} includeStudyArea 
     * @returns 
     */
    getCollections(includeStudyArea = false) {
        if(includeStudyArea) {
            return service.collections
        }
        return service.collections.filter(collection => collection.id !== MAP_STUDY_AREA_COLLECTION_ID)
    },

    /**
     * Returns all countries that are selectable
     * @returns countries
     */
    getCountries() {
        return countries.slice()
    },

    /**
     * Returns user defined areas
     *
     * @returns {Array} - array of user areas in blockly dropdown format
     */
    getUserAreas() {
        if(service.areas.length === 0) {
            // if there are no custom areas return NA
            return [["No area selected", NO_DATA_DROPDOWN_VALUE]];
        } else {
            return service.areas.map(area => [area.name, area.id]);
        }
    },
    /**
     * For a given area name get all shapes associated with it
     * @param {String} areaName
     * @returns {Array} - array of shapes
     */
    // supercedes getPolygonsForArea method
    getShapesForArea(areaId) {
        let shapes = [];
        service.shapes.forEach(shape => {
            if(shape.areaID === areaId) {
                shapes.push(shape);
            }
        });
        return shapes;
    },
     /**
     * For a given area name get all shapes associated with it
     * @param {String} areaName
     * @returns {Array} - array of shapes
     */
    // supercedes getPolygonsForArea method
    getClippedShapesForArea(areaId) {
        let shapes = [];
        service.shapes.forEach(shape => {
            if(shape.clippedBy === areaId) {
                shapes.push(shape);
            }
        });
        return shapes;
    },
    /**
     * For a given collection id get all areas associated with it
     * @param {String} collectionId 
     * @returns {Array} - array of areas
     */
    getAreasForCollection(collectionId) {
        let areas = []
        service.areas.forEach(area => {
            if (area.collectionId === collectionId) {
                areas.push(area)
            }
        });
        return areas
    },
    /**
     * Returns the first area for a given collection id
     * @param {*} collectionId 
     */
    getFirstAreaFromCollection(collectionId) {
        return service.areas.filter(area => area.collectionId === collectionId)[0];
    },
    /**
     * For a gives array of area ids get all the collection ids associated with them
     * @param {Array} areaIds 
     * @returns {Array} collection ids
     */
    getCollectionsIdForAreas(areaIds) {
        let collectionIds = []
        service.areas.forEach(area => {
            if (areaIds.includes(area.id)) {
                collectionIds.push(area.collectionId)
            }
        })
        return collectionIds
    },
    /**
     * Returns all user uploaded assets
    */
    // supercedes getUserUploadedAsset - the behaviour of which will need
    // to be updated to support multiple assets
    getUserUploadedAssets() {
        let assets = [];
        service.shapes.forEach(shape => {
            if(shape.type === 'UserUploaded') {
                assets.push(shape);
            }
        });
        return assets;
    },
    /**
     * Returns all custom area names
     */
    getCustomAreas() {
        return service.areas.map(area => area);
    },
    /**
     * Returns the first area that satisfies the id match
     * @param {*} areaId 
     */
    getUserAreaById(areaId) {
        return service.areas.find(area => areaId === area.id)
    },
    /**
     * Returns the first collection that satisfies the id match
     * @param {*} collectionId 
     */
    getCollectionById(collectionId) {
        const collection = service.collections.find(collection => collectionId === collection.id)
        return collection
    },
    /**
     * Returns the first shape that satisfies the id match
     * @param {*} shapeId 
     * @returns a shape object
     */
    getShapeById(shapeId) {
        return service.shapes.find(shape => shapeId === shape.id)
    },
    /**
     * Returns the coordinates for a given UserDrawnShape,
     * supports polygons and rectangles
     * @param {Object} shapeObject
     * @returns {Array} coordinates - 2D array of coordinates
     */
    getCoordinatesForUserDrawnShape(shapeObject){
        if(shapeObject.type !== "UserDrawn") {
            throw new Error("Incorrect Shape Type: Attempted to get coordinates for a non-UserDrawn shape")
        }
        if (shapeObject.overlay === undefined) {
            throw new Error("Shape overlay was not found")
        }
        let overlay = shapeObject.overlay;

        // TODO: update this to instead use class checks for Marker, Polygon etc.

        if(overlay.getBounds) {
            // if overlay has getBounds method, it is a rectangle
            let bounds = overlay.getBounds();
            var NE = bounds.getNorthEast();
            var SW = bounds.getSouthWest();

            return [
                [NE.lng(), NE.lat()],
                [SW.lng(), NE.lat()],
                [SW.lng(),SW.lat()],
                [NE.lng(),SW.lat()]
            ];
        } else if (overlay.getPath) {
            // if overlay has getPath method, it is a polygon
            let polygonPath = overlay.getPath();
            let coordinates = [];
            for (let i = 0; i < polygonPath.length; i++) {
                coordinates.push([
                    polygonPath.getAt(i).lng(),
                    polygonPath.getAt(i).lat()
                ]);
            }
            return coordinates;
        } else if (overlay.getPosition) {
            // if overlay has getPosition method, it is a marker
            const position = overlay.getPosition();
            return [position.lng(), position.lat()];
        }
    },
    /**
     * Process shape for use in EE
     */
    getProcessedShape(shape) {
        // make a copy of shape, so that we don't modify the original
        let shapeCopy = Object.assign({}, shape);
        if(shapeCopy.type === "UserDrawn") {
            shapeCopy.coordinates = this.getCoordinatesForUserDrawnShape(shape);
            
            shapeCopy.geometryType = shapeCopy.geometryType || "Polygon"; // default value for legacy workflows

            // remove overlay key as it's circular, which cause issues in JSON.stringify
            delete shapeCopy.overlay;
        } else if (shapeCopy.uploaded === true) {
            // remove unnecessary fields, bbox and mapURL
            delete shapeCopy.bbox;
            delete shapeCopy.mapURL;
            // remove overlay key as it's circular, which cause issues in JSON.stringify
            delete shapeCopy.overlay;
        }
        return shapeCopy
    },
    /**
     * Returns all the shapes in a given collection
    */
    getShapesForCollection(collectionId) {
        // first get areas for the collection
        let areas = this.getAreasForCollection(collectionId);

        let shapes = [];
        // get shapes for each area
        areas.forEach(area => {
            // gets valid shapes and if shape is UserDrawn, adds coordinates
            let validShapes = this.getShapesForArea(area.id)
            validShapes.forEach(shape => {
                // make a copy of shape, so that we don't modify the original
                let shapeProcessed = this.getProcessedShape(shape);
                shapes.push(shapeProcessed);
            });
        })

        return shapes
    },
    /**
     * Returns areas and shapes object in format for generating run doc.
     * Shapes object will contain a coordinates property which is a 2D array of coordinates if the shape is a UserDrawn shape
     * Optional param areaIds - if provided, only returns shapes for those areas
     * Shapes in the format: {
     *  id: String,
     *  areaID: String,
     *  type: String (UserDrawn or UserUploaded),
     *  coordinates: [[Number, Number], [Number, Number], ...]]
     * or if UserUploaded the normal fields
     * }
     * @param {Array} areaIds - optional field for filtering the areas to get
     * @returns {Object} - object containing areas and shapes
     */
    getAreasAndShapesForRunDoc(areaIds=[]) {
        let areasAndShapes = {
            areas: [],
            shapes: [],
            collections: []
        };
        let validAreaIds = []
        let validCollectionIds = []
        // check if we have area ids and collection ids

        if (areaIds.length === 0) {
            validAreaIds = this.getCustomAreas().map(a => a.id)
        }


        areaIds.forEach(id => {
            if (this.isValidAreaId(id)) {
                validAreaIds.push(id)
            }
            if (this.isValidCollectionId(id)) {
                validCollectionIds.push(id)
            }
        })

        // get areas for the collections if we have any and pushids to area ids array
        if (validCollectionIds.length > 0) {
            // let collectionAreas = []
            validCollectionIds.forEach(collectionId => {
                let collectionAreas = this.getAreasForCollection(collectionId)
                collectionAreas.forEach(area => {
                    validAreaIds.push(area.id)
                })
            })
        }

        if (validAreaIds.length === 0) {
            validAreaIds = this.getCustomAreas().map(a => a.id)
        }

        // gets valid areas
        service.areas.forEach(area => {
            if(validAreaIds.includes(area.id)) {
                areasAndShapes.areas.push(area);

                // gets valid shapes and if shape is UserDrawn, adds coordinates
                let validShapes = this.getShapesForArea(area.id)
                validShapes.forEach(shape => {
                    // make a copy of shape, so that we don't modify the original
                    let shapeProcessed = this.getProcessedShape(shape);
                    areasAndShapes.shapes.push(shapeProcessed);
                });
            }
        });

        service.collections.forEach(collection => {
            areasAndShapes.collections.push(collection)
        })

        return areasAndShapes;
    },
    /** returns the selected area
     * @returns {Object} - area object
     */
    getSelectedArea() {
        return service.selectedArea;
    },

    /** returns the selected collection
     * @returns {Object} - collection object
     */
    getSelectedCollection() {
        return service.selectedCollection;
    },

    
    /**
     * returns if there are any collections/areas/shapes within the service
     * @returns {Boolean}
     */
    hasItemsOnMap() {
        if(service.collections.length > 0) {
            return true
        }
        if(service.areas.length > 0) {
            return true
        }
        if(service.shapes.length > 0) {
            return true
        }
        return false
    },

    // ADDERS
    /**
     * For a given areaName and colour add the area to the service.areas array
     * @param {Object} areaObject
     */
    // supersedes the addCustomArea method
    addArea(areaObject) {
        service.areas.push(areaObject);
        awaitMapReady(service, () => service.areaChange.next(areaObject))
    },
    /**
     * For a given collection object, add it to the service.collections array
     * @param {*} collection 
     */
    addCollection(collection) {
        service.collections.push(collection)
        awaitMapReady(service, () => service.collectionChange.next(collection));
    },
    addStudyAreaIfNotExists() {
        const studyAreaCollection = service.collections.find(collection => collection.id === MAP_STUDY_AREA_COLLECTION_ID)
        if (studyAreaCollection === undefined) {
            const collection = {
                id: MAP_STUDY_AREA_COLLECTION_ID,
                name: 'All areas of interest',
                clippedAsset: false,
                clippedBy: ''
            }
            service.collections.push(collection)
            awaitMapReady(service, () => service.collectionChange.next(collection));
        }
    },
    /**
     * For a saved workflow get the shape.
     * @param {*} shape 
     */
    addUserDrawnShapeFromSaved(shape) {
        let shapeToAdd = {
            id: shape.id,
            areaID: shape.areaID,
            type: shape.type,
            coordinates: shape.coordinates,
            geometryType: shape.geometryType || "Polygon", // default value for legacy workflows
        }
        service.shapes.push(shapeToAdd);

        this.publishShapeUpdate(shapeToAdd);
    },
    /**
     * For a given UserDrawn overlay, add the shape to the service.shapes array
     * @param {String} areaID - foreign key to the area
     * @param {GoogleMapsOverlay} overlay - the overlay to be added
     * @returns {GoogleMapsOverlay} - the overlay that was added with areaId and shapeId properties
     */
    // supercedes the addPolygon method
    addUserDrawnShape(areaID, overlay) {

        const geometryType = overlay.getPosition ? "Point" : "Polygon";

        let shape = {
            id: this.generateUUID(),
            areaID: areaID,
            type: "UserDrawn",
            overlay: overlay,
            geometryType
        }

        service.shapes.push(shape);

        this.publishShapeUpdate(shape);

        overlay.areaId = areaID;
        overlay.shapeId = shape.id;

        return overlay;
    },
    /**
     * For a given UserUploaded shape, add the shape to the service.shapes array
     * If areaID is null, it will create a new area and assign the shape to it
     * @param {*} areaID - foreign key to the area
     * @param {*} bbox - bounding box of the shape
     * @param {*} mapURL - url of the map
     * @param {*} assetID - asset id of the shape
     * @param {*} meta - any meta to supply to the userUplaodedShapeEvent
     * @param {*} type - Type of the shape uploaded
     */
    // supercedes the polygonUploaded method
    addUserUploadedShape(areaID, bbox, mapURL, assetID, meta=null, type = 'UserUploaded', id=this.generateUUID) {
        // informs area service that it needs to create an area
        // then add the shape to that area
        // I'm not a fan of this pattern, I think we should be using state

        if(typeof id === 'function') {
            id = id();
        }

        let shape = {
            id: id,
            areaID: areaID,
            type: type,
            bbox: bbox,
            mapURL: mapURL,
            assetID: assetID,
            uploaded: true
        }
        if(typeof meta === 'object') {
            shape.meta = meta;
        }
        
        service.shapes.push(shape);

        this.publishShapeUpdate(shape);
        // inform asset service a new asset has been added
        // also informs google map areas view so we can add it to the map
        awaitMapReady(service, () => service.userUploadedShape.next(shape, meta));
    },
     /**
     * For a given feature view shape, add the shape to the service.shapes array
     * Very similar to addUserUploadedShape
     * If areaID is null, it will create a new area and assign the shape to it
     * @param {*} areaID - foreign key to the area
     * @param {*} bbox - bounding box of the shape
     * @param {*} mapURL - url of the map
     * @param {*} assetID - asset id of the shape
     * @param {*} meta - any meta to supply to the userUplaodedShapeEvent
     * @param {*} classProperty - the property to catagorise by
     * @param {*} type - Type of the shape uploaded
     */

     addFeatureViewShape(areaID, bbox, mapURL, assetID, meta, classProperty,  type = 'UserUploaded', id=this.generateUUID) {
        // informs area service that it needs to create an area
        // then add the shape to that area

        if(typeof id === 'function') {
            id = id();
        }

        let shape = {
            id: id,
            areaID: areaID,
            type: type,
            bbox: bbox,
            mapURL: mapURL,
            assetID: assetID,
            uploaded: true, 
            isFeatureView:true,
            classProperty: classProperty
        }

        if(typeof meta === 'object') {
            shape.meta = meta;
        }
        
        service.shapes.push(shape);

        this.publishShapeUpdate(shape);
        // inform asset service a new asset has been added
        // also informs google map areas view so we can add it to the map
        service.userUploadedShape.next(shape);
    },

    // REMOVERS
    /**
     * for a given area object such that {
     *  id: String,
     *  colour: String
     * }
     * It will remove the area the service.areas array
     * @param {Object} area
     */
    removeArea(area) {
        if(typeof area === 'string') {
            area = {id: area}
        }
        let index = this.findAreaIndex(area.id);
        if (index === -1) {
            return;
        }
        service.areas.splice(index, 1);

        // remove shapes associated with this area
        this.removeAreaShapes(area.id);

        if(service.selectedArea && service.selectedArea.id === area.id) {
            this.setSelectedArea({});
        }

        awaitMapReady(service, () => service.areaChange.next());
    },
    removeCollection(collection) {
        if(typeof collection === 'string') {
            collection = {id: collection}
        }
        let index = this.findCollectionIndex(collection.id);
        if (index === -1) {
            return;
        }
        service.collections.splice(index, 1)

        //remove areas associated with the collection
        this.removeCollectionAreas(collection.id)

        if(service.selectedCollection && service.selectedCollection.id === collection.id) {
            service.selectedCollection = null
        }
        awaitMapReady(service, () => service.collectionChange.next(collection));
    },
    /**
     * For a given shapeObject, remove it from the service.shapes array
     * @param {Object} shapeObject
     */
    removeShape(shapeObject) {
        let index = service.shapes.findIndex(shape => shape.id === shapeObject.id);
        if (index === -1) {
            return;
        }
        let shape = toRaw(service.shapes[index]);
        this.removeShapeFromMap(shape);
        service.shapes.splice(index, 1);

        this.publishShapeUpdate(shape)
    },
    removeCollectionAreas(collectionId) {
        // get collection areas
        let areas = this.getAreasForCollection(collectionId)

        // remove areas
        areas.forEach(area => {
            this.removeArea(area)
        })
    },
    /**
     * For a given areaName, remove all shapes associated with that area
     * @param {String} areaName
     */
    // supercedes the removeAreaPolygons method
    removeAreaShapes(areaName) {
        // get shapes associated with this area
        let shapes = this.getShapesForArea(areaName);
        // remove shapes
        shapes.forEach(shape => {
            this.removeShape(toRaw(shape));
        });
    },
    /**
     * Removes all areas
     */
    removeAllAreas() {
        service.areas = [];
        awaitMapReady(service, () => service.clearAreas.next());
        service.selectedArea = {};
    },
    removeAllCollections() {
        service.collections = [];
        awaitMapReady(service, () => service.clearCollections.next());
        awaitMapReady(service, () => service.collectionChange.next());
        service.selectedCollection = null;
    },
    /**
     * Removes all shapes
     */
    removeAllShapes() {
        // array can't be emptied only, we must remove one by one from the map
        // must create a copy of the array, as we are removing from the array as we go
        let shapesClone = [...service.shapes]
        shapesClone.forEach(shape => {
            this.removeShape(toRaw(shape));
        })
    },
    /**
     * For a given shapeObject, remove it from the map
     * @param {Object} shapeObject
     */
    removeShapeFromMap(shapeObject) {
        if(shapeObject.overlay) {
            const rawOverlay = toRaw(shapeObject.overlay);
            if(typeof rawOverlay.setMap === 'function'){
                rawOverlay.setMap(null);
            }
            if(typeof rawOverlay.remove === 'function'){
                rawOverlay.remove();
            }
            shapeObject.overlay = null
        }
    },
    /**
     * For a given shapeID, remove it from the map and service
     * @param {String} shapeID
     */
    removeShapeByID(shapeID) {
        let index = this.findShapeIndex(shapeID);
        if (index === -1) {
            return;
        }
        let shape = service.shapes[index];

        this.removeShape(shape);
    },

    // UPDATERS
    /**
     * For a given area object such that {
     * id: String,
     * colour: String
     * }
     * It will update the area in the service.areas array
     * @param {Object} area
     */
    // Supersedes the changedColour method
    updateArea(area) {
        let index = this.findAreaIndex(area.id);
        if (index === -1) {
            return;
        }
        service.areas[index] = area;
        this.updateShapesByArea(area.id, area.colour);
        awaitMapReady(service, () => service.areaChange.next());
    },

    updateCollection(collection, key=null, value=null) {
        let index = this.findCollectionIndex(collection.id);
        if (index === -1) {
            return;
        }
        
        service.collections[index] = collection
        if (key && value) {
            collection[key] = value
        }
        awaitMapReady(service, () => service.collectionChange.next( service.collections[index]));
    },

    /**
     * For a given shapeObject, update it in the service.shapes array
     * @param {Object} shapeObject
     */
    updateShape(shapeObject) {
        let index = this.findShapeIndex(shapeObject.id);
        if (index === -1) {
            return;
        }
        service.shapes[index] = shapeObject;
        this.publishShapeUpdate(shapeObject);
    },
    /**
     * For a given shapeID update it's areaID
     * @param {String} shapeID
     * @param {String} areaID
     * @param {GoogleMapsOverlay} overlay
     * @returns {GoogleMapsOverlay} - the overlay that was updated with areaId and shapeId properties
     */
    updateAreaIdOfShape(shapeID, areaID, overlay) {
        let index = this.findShapeIndex(shapeID);
        if (index === -1) {
            return;
        }
        
        service.shapes[index].areaID = areaID;

        overlay.areaId = areaID;
        service.shapes[index].overlay = overlay;
        // emit shape update event
        this.publishShapeUpdate(service.shapes[index]);
        return overlay
    },

    /**
     * For a given shape, update the colour of the shaope.
     * @param {*} shape 
     * @param {*} colour 
     */
    updateUserDrawnShapeColour(shape, colour) {
        
        switch (shape.geometryType) {
            case "Polygon": {
                const rawOverlay = toRaw(shape.overlay);
                rawOverlay.setOptions({
                    strokeColor: colour
                });
                break;
            }
            case "Point": {
                // the icon is actually going to be of type Symbol (https://developers.google.com/maps/documentation/javascript/reference/marker#Symbol)
                const symbol = shape.overlay.getIcon();

                symbol.fillColor = colour;
                const rawOverlay = toRaw(shape.overlay);
                rawOverlay.setIcon(symbol);
                break;
            }
            default: {
                const rawOverlay = toRaw(shape.overlay);
                rawOverlay.setOptions({
                    strokeColor: colour
                });
                break;
            }
        }
    },

    /**
     * For a given areaID and colour, update the
     * areaID and colours of all shapes associated with that area
     * @param {String} areaID
     * @param {String} colour
     */
    updateShapesByArea(areaID, colour) {
        // get shapes associated with this area
        let shapes = this.getShapesForArea(areaID);

        shapes.forEach(shape => {
            shape.areaID = areaID;

            if(shape.type === "UserDrawn") {
                this.updateUserDrawnShapeColour(shape, colour);
            } else if (shape.uploaded) {
                const rawOverlay = toRaw(shape.overlay);
                rawOverlay.setColour(colour);
            }
            this.publishShapeUpdate(shape);
        })
    },


    // SETTERS

    /** sets selected area
     * @param {Object} areaObject
     */
    setSelectedArea(areaObject) {
        if(areaObject === null) {
            areaObject = {}
        }
        service.selectedArea = areaObject;
        awaitMapReady(service, () => service.selectedAreaUpdate.next(service.selectedArea));
    },
    setSelectedCollection(collection) {
        if (typeof collection === 'string') {
            service.selectedCollection = this.getCollectionById(collection)
        }else {
            service.selectedCollection = collection;
        }
       
    },
    setHasPinnedAreas(value) {
        service.hasPinnedAreas = value;
        // update the observable
        service.pinnedAreasUpdate.next(value)
    },
    // UTILITY FUNCTIONS
    /**
     * For a given area name, find the index of the area, returns -1 if area doesn't exist
     * @param {String} areaId
     * @returns {Number} - index of area in service.areas or -1 if area doesn't exist
     */
    findAreaIndex(areaId) {
        return service.areas.findIndex(area => area.id === areaId);
    },
    /**
     * For a given collectionId, find the index of the collection, returns -1 if collection doesn't exist
     * @param {String} id
     * @returns {Number} - index of area in service.areas or -1 if area doesn't exist
     */
    findCollectionIndex(collectionId) {
        return service.collections.findIndex(collection => collection.id === collectionId);
    },
    /**
     * For a given shapeID, find the index of the shape, returns -1 if shape doesn't exist
     * @param {String} shapeID
     */
    findShapeIndex(shapeID) {
        return service.shapes.findIndex(shape => shape.id === shapeID);
    },

    /**
     * get an area by its Id.
     * @param {*} id 
     * @returns 
     */
    getAreaById(id) { 
        return service.areas.find(area => area.id === id)
    },
    /**
     * For a given area name, return the hex colour of the area, returns null if not found
     * @param {String} areaName
     * @returns {String} areaColour - null if area not found
     */
    getColour(areaName) {
        let index = this.findAreaIndex(areaName);
        if (index === -1) {
            return null;
        }
        return service.areas[index].colour;
    },
    /** Generates a UUID
     * @returns {String} - UUID
     */
    generateUUID() {
        return uuidv4();
    },
    /**
     * Clears the map of all shapes and areas, emits clearAreas event
     */
    clearMap() {
        this.removeAllCollections();
        this.removeAllAreas();
        this.removeAllShapes();
        awaitMapReady(service, () => service.clearAreas.next());
        awaitMapReady(service, () => service.areaChange.next());
    },
    /**
     * Loops over assets to see if there are any userUploadedAssets, will return true if there is at least one. Use getUserUploadedAssets to get the assets/
     */
    hasUserUploadedAssets() {
        return service.shapes.some(shape => shape.uploaded === true);
    },
    /**
     * Checks if given area (by area id) has shapes
     */
    areaHasShapes(areaId) {
        let hasShapes = false
        if (areaId === MAP_STUDY_AREA_COLLECTION_ID) {
            let aoiShapes = this.getShapesForCollection(areaId)
            if (aoiShapes.length > 0) {
                hasShapes = true
            }
        } else {
            let index = service.shapes.findIndex(shape => shape.areaID === areaId);
            if (index !== -1) {
                hasShapes = true
            }
        }
        return hasShapes
    },
    /**
     * Checks if collection has shapes within each area
     */
    collectionHasShapes(collectionId) {
        let areas = this.getAreasForCollection(collectionId)
        let areaHasShapes = areas.map(area => {
            return this.areaHasShapes(area.id);
          });
        if (areaHasShapes.includes(false)) {
            return false
        } else { 
            return true
        }
    },

    /**
     * Checks if areaName is a custom area
     * @param {String} areaName
     * @returns {Boolean}
     */
    isCustomArea(areaName) {
        return this.findAreaIndex(areaName) !== -1;
    },
    /**
     * Check if id is a valid area id
     * @param {*} id 
     */
    isValidAreaId(id) {
        return this.findAreaIndex(id) !== -1;
    },
    /**
     * Check if id is a valid collection id
     * @param {*} id
     */
    isValidCollectionId(id) {
        return this.findCollectionIndex(id) !== -1;
    },
    /**
     * isGeometryInAoi(collectionId, studyArea) returns list of true/false for each  if is in an area of interest
     * Todo: we need to check uploaded assets. If we detect and uploaded shape we currently always assume true
     * @param {String} collectionId
     */
    isGeometryInAoi(collectionId, studyArea) {

        const studyAreas = Array.isArray(studyArea) ? studyArea:[studyArea]

        if (collectionId && studyAreas.length > 0) { //If a collection and study area are present 
            let collectionShapes = this.getShapesForCollection(collectionId)
            let userUploadedShapes = collectionShapes.filter(shape => shape.type === "UserUploaded")
            // We have no way of detecting if a user uploaded shape is outside the AOI so return true
            if (userUploadedShapes.length > 0) {
                return true
            }

            //Get Shapes for the AOI's
            let uploadShapeCount = 0
            let allAOIShapesPolys = studyAreas
                .flatMap(area => {
                    if(this.isValidCollectionId(area)) {
                        return this.getShapesForCollection(area)
                    }
                    return this.getShapesForArea(area)
                })
                .filter(shape => {
                    if (shape.type === "UserUploaded") {
                        uploadShapeCount++
                    }
                    return shape.type === "UserDrawn" && shape.geometryType === "Polygon"
                })
                .map(shape => {
                    let coords = []
                    if (shape.coordinates) {
                        coords = shape.coordinates
                    } else {
                        coords = this.getCoordinatesForUserDrawnShape(shape)
                    }
                    coords.push(coords[0]);
                    return coords;
                })
                .map(coords => polygon([coords])); 

            // We have no way of detecting if a user uploaded shape is outside the AOI so return true
            if (uploadShapeCount > 0) {
                return true;
            }

            // find any collection shapes not in the AOI
            const outsideAOI = collectionShapes.filter(shape => {
                let contained = false
                allAOIShapesPolys.forEach(area => {
                    if (shape.type === "UserDrawn") {
                        let shapeCoords = shape.coordinates
                        shapeCoords.push(shape.coordinates[0])
                        const shapePoly = polygon([shapeCoords])
                        if (booleanContains(area,shapePoly) === true) {
                            contained = true
                        }
                    }
                })
                return !contained
            })

            return outsideAOI.length === 0
            
        }
        return false
    },


    /**
     * For a given area ID, check if some of the areas are user drawn
     * For those that user drawn check that they're made of polygons
     * @param {*} areaId 
     */
    isAreaEditable(areaId) {
        return this.getShapesForArea(areaId)
            .some(shape => shape.type === "UserDrawn" && shape.geometryType === "Polygon")
    },

    /**
     * For a given ID, check if it belongs to an area or collection
     * * @param {*} id
     */
    isAreaOrCollection(id) {
        if (this.isValidAreaId(id) === true) {
            return "area"
        } else if (this.isValidCollectionId(id) === true) {
            return "collection"
        } else {
            return null
        }
    },

    

    /**
     * Publish the updatedDatasetBbox event
     * @param {Object} bbox - bounding box of dataset
     * @param {String} blockId - ID of block
     */
    // supercedes updateDatasetBbox method
    publishUpdatedDatasetBbox(bbox, blockId) {
        awaitMapReady(service, () => service.updatedDatasetBbox.next({
            bbox: bbox,
            blockId: blockId
        }));
    },
    /**
     * For a given shape emit shapeUpdated event
     * Kept for legacy purposes, consider updating
     *
     * Supercedes the updatePolygon method
     */
    publishShapeUpdate(shape) {
        awaitMapReady(service, () => service.shapeUpdated.next({
            areaId: shape.areaID,
            areaID: shape.areaID,
            shapeId: shape.id,
            coordinates: shape.coordinates
        }));
    },


    /**
     * Set visiblitity for a given area or collection id
     * @param {*} id 
     * @param {*} visible 
     * @returns 
     */
    setVisiblityForId(id, visible) {
        const area = this.getUserAreaById(id)
        let areas = []
        if(!area) {
            areas = this.getAreasForCollection(id)
        } else {
            areas = [area]
        }
        areas.forEach(area => {
            area.visible = visible
            const shapes = this.getShapesForArea(area.id)
            shapes.forEach(shape => {
                if(shape.overlay) {
                    const mapOverlay = toRaw(shape.overlay)
                    if(typeof mapOverlay.setVisible === 'function') {
                        mapOverlay.setVisible(visible)
                    }
                }
            })
        })
        return this;
    },


    async getGlobalDatasetData() {
        let datasetDetails = []

        let aoiAreas = service.areas.filter(area => area.collectionId === MAP_STUDY_AREA_COLLECTION_ID)

        const uploadedMetaDataArray = await Promise.all(service.shapes
            .filter(shape => shape.type === 'UserUploaded')
            .map(shape => DatasetService.getMetadata(shape.assetID))
        )
        const uploadedMetaData = uploadedMetaDataArray.reduce((acc, meta) => {
            if(meta !== null) {
                acc[meta.id] = meta
            }  
            return acc
        }, {})

        aoiAreas.forEach(area => {
            let attributes = []
            let shapes = this.getShapesForArea(area.id)

            if(shapes && shapes.length > 0) {
                if(shapes[0].type === 'UserUploaded' && uploadedMetaData[shapes[0].assetID] && Array.isArray(uploadedMetaData[shapes[0].assetID][GEE_SCHEMA])) {
                    attributes = uploadedMetaData[shapes[0].assetID][GEE_SCHEMA]
                }else if(shapes[0].meta && shapes[0].meta.gee_schema) {
                    attributes = shapes[0].meta.gee_schema
                }else if(shapes[0].meta && shapes[0].meta.properties) {
                    attributes = shapes[0].meta.properties
                }
            }
        
            datasetDetails.push({
                title: area.name,
                type: "area",
                origin: "area_service",
                id: area.id,
                visContrast: {statistical:"p98"}, 
                attributes: attributes || []
            })
        })


        this.getCollections(true).map(collection => {
            let areas = this.getAreasForCollection(collection.id)

            const classes = areas.reduce((acc, area) => {
                return {...acc, [area.id]: {
                    color: area.colour,
                    description: area.name,
                    short_description: area.name,
                }}
            }, {})

            let attributes = [{
                name: "Areas of Interest",
                'ebx:datatype': "thematic",
                'gee:classes': classes
            }]

            //Need to refactor not to assume all shapes in an area of a collection are the same
            if(areas.length > 0) {
                const shapeAttributes = areas
                    .flatMap(area => {
                        return this.getShapesForArea(area.id).flatMap(shape => {
                            if(shape.type === 'UserUploaded' && uploadedMetaData[shape.assetID] && Array.isArray(uploadedMetaData[shape.assetID][GEE_SCHEMA])) {
                                return uploadedMetaData[shape.assetID][GEE_SCHEMA]
                            }
                            if(shape.meta && shape.meta.gee_schema) {
                                return shape.meta.gee_schema
                            }
                            if(shape.meta && shape.meta.properties) {
                                return shape.meta.properties
                            }
                            return null
                        })
                    })
                    .filter(attr => [undefined, null].indexOf(attr) < 0)

                const uniques = shapeAttributes.reduce((acc, attr) => {
                    if(acc.map(a => a.name).indexOf(attr.name) < 0) {
                        acc.push(attr)
                    }
                    return acc
                }, [])
                attributes = attributes.concat(uniques)
            }
            
			datasetDetails.push({
                title: collection.name,
                "ebx:table_title": "All ",
                type: "feature_collection",
                origin: "area_service",
                id: collection.id,
                visContrast: {statistical:"p98"},
                attributes: attributes || []
            })
        });

        return datasetDetails
    },

    attachGlobalDatasetUpdateListener(globalDatasetService) {
        this.collectionChange$.subscribe((data) => {
            globalDatasetService.change(data)
        });
        this.areaChange$.subscribe((data) => {
            globalDatasetService.change(data)
        });
    },

    /**
     * For a given shape, get the bounding box, for a point we return a box with zero area.
     * @param {} shape 
     * @retrns {Object} - bounding box of shape of the form {'SW': [lon, lat], 'NE': [lon, lat]}
     */
    getShapeBoundingBox(shape) {
        if (shape.type === "UserUploaded") {
            return shape.bbox;
        }

        if (!shape.overlay) {
            return null;
        }

        if (shape.geometryType == "Point") {
            const position = shape.overlay.getPosition()
            return {
                'SW': [position.lng(), position.lat()],
                'NE': [position.lng(), position.lat()]
            }
        }

        if (typeof shape.overlay.getPath === 'function') {
            const paths = shape.overlay.getPath();
            let minLat = paths.getAt(0).lat();
            let maxLat = paths.getAt(0).lat();
            let minLng = paths.getAt(0).lng();
            let maxLng = paths.getAt(0).lng();

            for (let i = 1; i < paths.getLength(); i++) {
                let vertex = paths.getAt(i);
                minLat = Math.min(vertex.lat(), minLat);
                maxLat = Math.max(vertex.lat(), maxLat);
                minLng = Math.min(vertex.lng(), minLng);
                maxLng = Math.max(vertex.lng(), maxLng);
            }

            return {
                'SW': [minLng, minLat],
                'NE': [maxLng, maxLat]
            }
        }

        if (typeof shape.overlay.getBounds === 'function') {
            let bounds = shape.overlay.getBounds();
            let sw = bounds.getSouthWest();
            let ne = bounds.getNorthEast();

            return {
                'SW': [sw.lng(), sw.lat()],
                'NE': [ne.lng(), ne.lat()]
            }
        }

        return null;
    },

    /**
     * Given an array of bboxes of the form {'SW': [lon, lat], 'NE': [lon, lat]} return a single bbox that contains all of them
     * @param {Array} bboxes 
     * @returns {Object} - bounding box of shape of the form {'SW': [lon, lat], 'NE': [lon, lat]}
     */
    combineBboxes(bboxes) {
        if (bboxes.length === 0) {
            throw new Error("No bboxes provided");
        }

        let minLat = bboxes[0].SW[1];
        let maxLat = bboxes[0].NE[1];
        let minLng = bboxes[0].SW[0];
        let maxLng = bboxes[0].NE[0];

        for (let i = 1; i < bboxes.length; i++) {
            minLat = Math.min(bboxes[i].SW[1], minLat);
            maxLat = Math.max(bboxes[i].NE[1], maxLat);
            minLng = Math.min(bboxes[i].SW[0], minLng);
            maxLng = Math.max(bboxes[i].NE[0], maxLng);
        }

        return {
            'SW': [minLng, minLat],
            'NE': [maxLng, maxLat]
        }
    },

    /**
     * Gets the bounding box for an area.
     * @param {String} areaId
     * @returns {Object} - bounding box of area of the form {'SW': [lon, lat], 'NE': [lon, lat]}
     */
    getAreaBoundingBox(areaId) {
        let shapes = this.getShapesForArea(areaId);

        const bboxes = shapes
            .map(shape => this.getShapeBoundingBox(shape))
            .filter(bbox => bbox !== null);

        return this.combineBboxes(bboxes);
    },

    /**
     * Sets all shapes to be editable, if an area id is provided, only shapes in that area will be editable.
     * 
     * Only applies to polygons
     * 
     * @param {Boolean} editable - optional
     * @param {String} areaId - optional
     */
    setShapesEditable(editable, areaId=null) {
        service.shapes.forEach(shape => {

            let shapeEditable = editable

            // check if the overlay type is a polygon
            if (shape.geometryType === "Point") {
                return;
            }

            if (!shape.overlay) {
                return;
            }

            const rawOverlay = toRaw(shape.overlay);

            if (typeof rawOverlay.setEditable !== 'function') {
                return;
            }

            if(areaId !== null && shape.areaID !== areaId) {
                shapeEditable = false;
            }

            rawOverlay.setOptions({
                editable: shapeEditable
            })
        });
    },

    /**
     * Sets all shapes to be clickable, if an area id is provided, only shapes in that area will be clickable.
     * 
     * Only applies to polygons
     * 
     * @param {Boolean} clickable - optional
     * @param {String} areaId - optional
     */
    setShapesClickable(clickable, areaId=null) {
        service.shapes.forEach(shape => {
            let shapeClickable = clickable
            
            if (shape.geometryType === "Point") {
                return;
            }

            if (!shape.overlay) {
                return;
            }

            if(shape.areaID !== areaId) {
                shapeClickable = false;
            }

            const rawOverlay = toRaw(shape.overlay);

            rawOverlay.setOptions({
                clickable: shapeClickable
            })
        });
    },

    // RXJS OBSERVABLES
    /**
     * shapeUpdated observable, used to inform blocks that one of the shapes has been updated
     * and that an area may have been updated too
     * emitted with the areaName the shape was associated too as well as the shapeId
     * Supercedes the polygonUpdated event
     */
    shapeUpdated$: service.shapeUpdated.asObservable(),
    /**
     * clearAreas observable, used to publish clear areas event which nukes the map completely from all polygons, areas, shapes and layers
     */
    clearAreas$: service.clearAreas.asObservable(),
    /**
     * clearCollection observable, used to publish clear collections event which nukes the map completely from all polygons, areas, shapes and layers
     */
    clearCollections$: service.clearCollections.asObservable(),
    /**
     * updatedDatasetBbox observable, used to publish updated dataset bbox event which creates a bbox overlay on the map for data selector blocks - we may want to update this to be more general and to allow for multiple overlays, this could be helpful for country bounds
     */
    updatedDatasetBbox$: service.updatedDatasetBbox.asObservable(),
    /**
     * areaChange observable, used to publish when a new area has been created to inform blocks they need to update their area dropdown
    */
    areaChange$: service.areaChange.asObservable(),

    /**
     * areaChange observable, used to publish when a new area has been created to inform blocks they need to update their area dropdown
    */
    collectionChange$: service.collectionChange.asObservable(),
    /**
     * userUploadedShape observable, used to publish user uploaded polygon event, currently
     * used in basemap view to render userUploadedShape but this is going to be phased out.
     * Most likely will be used to render userUploadedShape in the map
     * supercedes userUploadedPolygon event
     */
    userUploadedShape$: service.userUploadedShape.asObservable(),
    /**
     * selectedArea observable, used to inform components when selected area has changed
     */
    selectedAreaUpdate$: service.selectedAreaUpdate.asObservable(),
    /**
     * Pinned areas update
     */
    pinnedAreasUpdate$: service.pinnedAreasUpdate.asObservable(),
    /**
     * areaRemoved observable, used to inform components when an area has been removed
     */
    areaRemoved$: service.areaRemoved.asObservable(),
}

export {
    AreaService
}