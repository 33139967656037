export class WMTSMapType {
    name = "WMTS Tiles";
    alt = "WMTS Tiles";
    params = {
        SERVICE: "WMTS",
        VERSION: "1.0.0",
        REQUEST: "GetTile"
    }

    /**
     * Constructor for WMTSMapType
     * @param {GoogleMapsSize Class} tileSize
     * @param {String} url
     * @param {Number} minZoom
     * @param {Number} maxZoom
     * @param {Object} params
     */
    constructor(tileSize, url, minZoom, maxZoom, params) {
        this.tileSize = tileSize;
        if(url.slice(-1) !== "?") {
            url += "?";
        }
        this.url = url;
        this.minZoom = minZoom;
        this.maxZoom = maxZoom;
        this.params = { ...this.params, ...params };
        this.id = this.name
    }

    getTile = (coord, zoom) => {
        const img = new Image();
        if(zoom < this.minZoom || zoom > this.maxZoom) {
            return img;
        }

        const { url } = this;
        const params = {
            ...this.params,
            TileRow: coord.y,
            TileCol: coord.x,
            TileMatrix: `${this.params.TileMatrixSet ?  this.params.TileMatrixSet + ":" : ""}${zoom}`,
        };

        img.src = url + Object.entries(params).map(([k, v]) => `${k}=${v}`).join("&");
        return img
    }
}