
import { Subject } from 'rxjs';
/**
 * @class EbxEventBus
 * @description A simple event bus that allows you to emit and listen to events. Abstracted to use RxJS but be compatible with Vue2 $on, $emit, $off methods
 * @usage
 * ```javascript
import { globalEventBus } from '@/eventbus';
const callback = () => {}
globalEventBus.$on('test', callback)
globalEventBus.$emit('test')
globalEventBus.$off('test', callback)
```
 */
export class EbxEventBus {
  constructor() {
    this.observers = {};
    this.unsubscriptions = {};
  }
  $on(eventName, callback) {
    const subject = this.createObserver(eventName).subscribe(callback);
    if(!this.unsubscriptions[eventName]) {
      this.unsubscriptions[eventName] = [];
    }
    this.unsubscriptions[eventName].push({
        callback,
        subject
    });
    return this;
  }
  $emit(eventName, ...args) {
    if(this.observers[eventName]) {
        this.observers[eventName].next(...args);
    } else {
        console.warn('Event ' + eventName + ' does not exist')
    }
    return this;
  }
  $off(eventName, callback = null) {
    if(this.unsubscriptions[eventName] === undefined) {
        return;
    }
    if(callback === null) {
      this.unsubscriptions[eventName].forEach(subscription => {
        subscription.subject.unsubscribe();
      });
      delete this.unsubscriptions[eventName]
      delete this.observers[eventName]
      return;
    }
    const index = this.unsubscriptions[eventName].findIndex(subscription => subscription.callback === callback);
    if(index === -1) {
      return;
    }
    this.unsubscriptions[eventName][index].subject.unsubscribe();
    this.unsubscriptions[eventName].splice(index, 1);
    if(this.unsubscriptions[eventName].length === 0) {
        delete this.unsubscriptions[eventName]
        delete this.observers[eventName]
    }
    return this;
  }
  createObserver(eventName) {
    if(this.observers[eventName]) {
        return this.observers[eventName];
    }
    this.observers[eventName] = new Subject();
    return this.observers[eventName];
  }
}

export const globalEventBus = new EbxEventBus();