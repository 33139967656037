<template>
    <div ref="ebxTimeline" class="ebx-timeline" :style="dynamicWidth">
        <div class="ebx-timeline--container" ref="timelineContainer" :class="fadeClass">
            <div class="ebx-timeline--content" ref="timelineContent">
                <div class="ebx-timeline--track">
                    <div class="ebx-timeline--points-container">
                        <div class="ebx-timeline--points">
                            <ol ref="dataPoints">
                                <li v-for="(point, index) in options" :key="index" class="ebx-timeline--point" :style="dataPointWidth" :class="{'selected-point': selectedPoint === index}">
                                    <a href="#" @click.prevent="setSelectedPoint(index)">
                                        <span class="notch"><div class="marker"></div></span>
                                        {{point.name}}
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ebx-timeline--navigation">
            <div class="ebx-timeline--navigation-item nav-left" :class="!enableLeftNav ? 'disabled' : ''">
                <a href="#0" @click.prevent="moveBwd()" alt="Slide to previous">
                    <span class="material-icons-outlined ebx-icon">chevron_left</span>
                </a>
            </div>
            <div class="ebx-timeline--navigation-item nav-right" :class="!enableRightNav ? 'disabled' : ''">
                <a href="#0" @click.prevent="moveFwd()" alt="Slide to next">
                    <span class="material-icons-outlined ebx-icon">chevron_right</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import valueMixin from '@/components/mixins/valueMixin';
export default {
    name: 'EbxTimeline',
    props: {
        navigation: {
            type: Boolean,
            required: false,
            default: false
        },
        maxPointsShown: {
            type: Number,
            required: false,
            default: 4
        },
        options: {
            type: Array,
            required: false,
            default: () => []
        },
        modelValue: {
            type: Number,
            required: false,
            default: 0
        }
    },
    mixins: [valueMixin],
    emits: [
        'update:modelValue',
    ],
    data() {
        return {
            timelineComponent: null,
            timelineWidth: 0,
            timelineContentWidth: 0,
            pointWidth: 0,
            numPoints: 0,
            screenWidth: window.innerWidth,
            enableRightNav: true,
            enableLeftNav: false,
            selectedPoint: 0,
            isMounted: false
        }
    },
    watch: {
        screenWidth() {
            this.setTimelineWidth()
        },
        selectedPoint(newValue) {
            // enable/disable the navigation buttons when either end of numPoints is reached
            if (newValue === 0) {
                this.enableLeftNav = false
                this.enableRightNav = true
            } else if (newValue === this.numPoints-1) {
                this.enableRightNav = false
                this.enableLeftNav = true
            } else {
                this.enableLeftNav = true
                this.enableRightNav = true
            }
            this.$emit('update:modelValue', newValue)
            return newValue
        },
        options: {
            immediate: true,
            deep:true,
            handler(val) {
                this.numPoints = val.length
                if (this.isMounted) {
                    this.setTimelineWidth()
                } else {
                    // use a setTimeout to wait for the component to be mounted
                    setTimeout(() => {
                        this.setTimelineWidth()
                    })
                }
                return val
            }
        },
        value: {
            immediate: true,
            async handler(val) {
                // move to the selected point
                if (this.isMounted) {
                    await this.isElementInView(val, this.selectedPoint)
                    this.selectedPoint = val
                } else {
                    // use a setTimeout to wait for the component to be mounted
                    setTimeout(async () => {
                        await this.isElementInView(val, this.selectedPoint)
                        this.selectedPoint = val
                    })
                }
            }
        }
    },
    mounted() {
        this.isMounted = true
        // set timeline width
        this.setTimelineWidth()
    },
    created() {
        window.addEventListener('resize', this.onResize)
    },
    computed: {
        dynamicWidth() {
            return {
                width: `${this.timelineWidth}px`, // Bind the width to the data property
            }
        },
        dataPointWidth() {
            return {
                width: `${this.pointWidth}px`
            }
        },
        enableRightNavigation() {
            if (this.enableRightNav && this.navigation) {
                return true
            } else {
                return false
            }
        },
        enableLeftNavigation() {
            if (this.enableLeftNav && this.navigation) {
                return true
            } else {
                return false
            }
        },
        fadeClass() {
            if (this.selectedPoint === 0) {
                return 'solid-left'
            } else if (this.selectedPoint === this.numPoints-1) {
                return 'solid-right'
            } else {
                return ''
            }
        }
    },
    methods: {
        onResize() {
            this.screenWidth = window.innerWidth;
        },
        setSelectedPoint(index) {
            this.selectedPoint = index
        },
        setTimelineWidth() {
            // get the width of the parent element to set the width of the timeline component
            const parent = this.$el.parentElement
            const parentWidth = parent.offsetWidth

            // set the width of the timeline component
            this.timelineWidth = parentWidth
            // set the width of the timeline points
            this.setTimelinePointWidth()
        },
        setTimelinePointWidth() {
            // set the width of the timeline points
            // this.pointWidth = 0
            const intervals = this.maxPointsShown
            this.timelineContentWidth = this.timelineWidth - 56// 56 is the width of the navigation buttons + padding
            this.pointWidth = this.timelineContentWidth / intervals

        },
        async isElementInView(elementIndex, oldSelectedPoint) {
            // get the timeline points component
            const timelinePoints = this.$refs.dataPoints
            if (!timelinePoints || !timelinePoints.children) {
                return
            }
            const parentElement = this.$refs.timelineContainer

            // Initialize the Intersection Observer
            const observer = new IntersectionObserver(points => {
                points.forEach(point => {
                    if (!point.isIntersecting) {
                        // The element is not in view (next point is hidden)
                        this.slideTo(oldSelectedPoint, elementIndex)
                    }
                    observer.unobserve(point.target);
                });
                observer.disconnect();
            }, {
                root: parentElement, //Parent element
                rootMargin: '0px',  // No margin for the root
                threshold: 1  // Trigger the callback when at least 50% of the target is in view
            });

            // Observe the next timeline element
            const nextPoint = timelinePoints.children[elementIndex];
            if (!nextPoint || timelinePoints.children.length === 0) {
                return
            }
            
            observer.observe(nextPoint);
        },
        slideLeft(intervalDifference) {
            let timelineComponent = this.$refs.timelineContent
            // get current translate value
            let translateValue = this.getTranslateValue()

            let slideBy = 0
            // if intervalDifference is less than the maxPointsShown, slide by the pointWidth * maxPointsShown
            // else slide by the pointWidth * intervalDifference
            if (intervalDifference < this.maxPointsShown) {
                slideBy = this.pointWidth * this.maxPointsShown
            } else {
                slideBy = this.pointWidth * intervalDifference
            }
            const newTranslateValue = translateValue + slideBy
            const minTranslateValue = 0 - (this.pointWidth * this.maxPointsShown)
            if (newTranslateValue >= minTranslateValue) {
                // if translate value is greater than the min translate value
                timelineComponent.style.transform = `translateX(${0}px)`
            } else {
                // if translate value is less than the min translate value
                timelineComponent.style.transform = `translateX(${newTranslateValue}px)`
            }
        },
        slideRight(intervalDifference) {
            // get the timeline component
            let timelineComponent = this.$refs.timelineContent
            // get current translate value
            let translateValue = Math.abs(this.getTranslateValue())

            let slideBy = 0
            // if intervalDifference < maxPointsShown, slide the timeline by maxPointsShown
            // else slide the timeline by the intervalDifference
            if (intervalDifference < this.maxPointsShown) {
                slideBy = this.pointWidth * this.maxPointsShown
            } else {
                slideBy = this.pointWidth * intervalDifference
            }
            const newTranslateValue = translateValue + slideBy
            const totalWidth = this.getTotalWidth()
            const maxTranslateValue = totalWidth - this.timelineContentWidth
            // add translate style to the timeline component
            if (newTranslateValue <= maxTranslateValue) {
                // if translate value is less than the max translate value
                timelineComponent.style.transform = `translateX(-${(newTranslateValue)}px)`
            } else {
                // if translate value is greater than the max translate value
                timelineComponent.style.transform = `translateX(-${maxTranslateValue}px)`
            }
        },
        slideTo(oldSelectedPoint, newSelectedPoint) {
            if (oldSelectedPoint === newSelectedPoint) {
                return
            }
            const intervalDifference = Math.abs(newSelectedPoint-oldSelectedPoint)
            // work out whether we need to slide left or right from the selectedPoint differences
            if (newSelectedPoint > oldSelectedPoint) {
                // slide right
                this.slideRight(intervalDifference)
            } else {
                // slide left
                this.slideLeft(intervalDifference)
            }
        },
        async moveBwd() {
            const oldSelectedPoint = this.selectedPoint
            // update the selected point
            this.updateSelectedPoint(-1)
            if (oldSelectedPoint !== this.selectedPoint) {
                await this.isElementInView(this.selectedPoint, oldSelectedPoint)
            }
        },
        async moveFwd() {
            const oldSelectedPoint = this.selectedPoint
            // update the selected point
            this.updateSelectedPoint(1)
            if (oldSelectedPoint !== this.selectedPoint) {
                await this.isElementInView(this.selectedPoint, oldSelectedPoint)
            }
        },
        updateSelectedPoint(value) {
            const newSelectedPoint = this.selectedPoint + value
            // check if newSelectedPoint is within the range of points
            if (newSelectedPoint < 0 || newSelectedPoint > this.numPoints) {
                return
            } else {
                // update the selected point
                this.selectedPoint = newSelectedPoint
            }
        },
        getTotalWidth() {
            // total width = no of points * point width
            const totalWidth = this.numPoints * this.pointWidth
            return totalWidth
        },
        getTranslateValue() {
            const timelineComponent = this.$refs.timelineContent
            let translateValue = timelineComponent.style.transform
            if (translateValue === '') {
                translateValue = 0
            } else {
                // get the math.abs value of the translate value
                translateValue = parseFloat(translateValue.split('(')[1].split('px')[0])
            }
            return translateValue
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize)
    }
}
</script>