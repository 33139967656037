/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/

import Blockly from 'blockly';
import {CustomFieldDate}  from '@/fields/blocklyFieldDate';
import {NO_DATA_DROPDOWN_VALUE, BOOLEAN_DROPDOWN_OPTIONS} from '@/constants/nextGenConstants';
import { addFieldNumberInput, addFieldTextInput, addFieldDateInput, addDropdownInput, areaChange } from '@/blocks/helper_functions';
import { AreaService } from '@/services/area.service';
const DEFAULT_METHOD_OPTIONS = [["None", NO_DATA_DROPDOWN_VALUE]];
import { startCase } from 'lodash'
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock';
import { ContentService } from '@/services/content.service';

const MASK_DATATYPES = Object.freeze({
    FUNCTION: 'function',
    UNKNOWN: 'unknown'
})

const FIELD = Object.freeze({
    METHOD: 'method'
})

const INPUT = Object.freeze({
    METHOD: 'method'
})

const modifierCloudMaskJson = {
    "type": "modifier_cloud_mask",
    "message0": ` %1 %2 %3 %4 %5`,
    "args0": [
        {
            "type": "field_label",
            "name": "cloud_title",
            "text": "%{BKY_MODIFIER_CLOUD_MASK_BLOCK_TITLE} ",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label",
            "name": "method_label",
            "text": "%{BKY_MODIFIER_CLOUD_MASK_METHOD_LABEL}",
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.METHOD,
            "options": DEFAULT_METHOD_OPTIONS
        },
        {
            "type": "input_dummy",
            "name": INPUT.METHOD
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "secondaryFilter",
    "tooltip": "",
    "helpUrl": ""
}

const MaskHelpers = {
    maskParametersAsArray(maskKey) {
        if(this.maskHasParameters(maskKey) === false) {
            return []
        }
        const masks = this.getState('masks');
        const parameterKeys = Object.keys(masks[maskKey].parameters)
        return parameterKeys
            .map(key => {
                return {
                    key,
                    type: masks[maskKey].type,
                    parameter: masks[maskKey].parameters[key]
                }
            })
            .filter(param => {
                return param.user_defined === undefined || param.user_defined === false
            })
    },
    maskHasParameters(maskKey) {
        const masks = this.getState('masks');
        const keys = Object.keys(masks)
        if (keys.indexOf(maskKey) < 0) {
            return false
        }
        if (typeof masks[maskKey] !== 'object') {
            return false
        }
        if (masks[maskKey].parameters === undefined) {
            return false
        }
        return true
    },
    maskDataType(maskKey) {
        const masks = this.getState('masks');
        const keys = Object.keys(masks)
        if (keys.indexOf(maskKey) < 0) {
            return MASK_DATATYPES.UNKNOWN
        }
        if (typeof masks[maskKey] !== 'object') {
            return MASK_DATATYPES.UNKNOWN
        }
        if (masks[maskKey].type === undefined) {
            return MASK_DATATYPES.UNKNOWN
        }
        return masks[maskKey].type
    },
    addParameterField(paramData) {
        if(this.getField( paramData.key + '_param')){
            return
        }
        let inputName;
        switch(paramData.parameter.type) {
            case 'ee.Number':
                addFieldNumberInput(
                    this, 
                    paramData.key + '_param',
                    startCase(paramData.key), // Label 
                    paramData.key, // Field Name
                    paramData.parameter.default || 0, 
                    paramData.parameter.min || 0, 
                    paramData.parameter.max || 999999,
                    paramData.parameter.step || 1
                );
                break;
            case 'ee.String':
                addFieldTextInput(
                    this, 
                    paramData.key + '_param',
                    startCase(paramData.key),
                    '',
                    paramData.key
                )
                break;
            case 'ee.Date':
                addFieldDateInput(
                    this, 
                    paramData.key + '_param',
                    startCase(paramData.key),
                    '',
                    paramData.key
                )
                break;   
            case 'ee.DateRange':
                inputName = paramData.key + '_param'
                if(!this.getInput(inputName)) {
                    this.appendDummyInput(inputName)
                        .appendField(startCase(paramData.key))
                        .appendField(new CustomFieldDate(paramData.key + '_start_date'), paramData.key + '_start_date')
                        .appendField(new CustomFieldDate(paramData.key + '_end_date'), paramData.key + '_end_date');
                }
                break;        
            case 'ee.Boolean': 
                addDropdownInput(this, paramData.key + '_param', paramData.key, paramData.key, BOOLEAN_DROPDOWN_OPTIONS )      
                break;  
            case 'ee.Polygon': 
                inputName = paramData.key + '_param'
                if(!this.getInput(inputName)) {
                    let areas = AreaService.getAreas();
                        addDropdownInput(this, inputName, startCase(paramData.key) ,paramData.key,areas, null, null)
                        this.areaSubscription = AreaService.areaChange$.subscribe( () => {
                            let result = areaChange(this, inputName, paramData.key);
                            if(!result) {
                                this.areaSubscription.unsubscribe();
                            }
                        });     
                    } 
                break;      
            default:
                console.warn(paramData.parameter.type + 'Is Not a Valid Type')    
                addFieldTextInput(
                    this, 
                    paramData.key + '_param',
                    startCase(paramData.key),
                    '',
                    paramData.key
                )
        }
        
    }
}

Blockly.Blocks["modifier_cloud_mask"] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    ...MaskHelpers,
    onInit: function() {
        this.jsonInit(modifierCloudMaskJson);
        this.getField(FIELD.METHOD).setValidator(this.methodValidator)

        this.about_block_url = ContentService.getAboutBlockUrl('modifier_cloud_mask')
    },
    accept: async function (visitor) {
        console.log('accept modifier cloud mask visitor')
        await visitor.visitModifierCloudMaskBlock(this);
    },
    updateShape_() {

        if(this.hasStateChanged('masks') && typeof(this.getState('masks')) === 'object') {
            const maskKeys = Object.keys(this.getState('masks'));
            if(maskKeys.length === 0) {
                this.updateOptionsIfSet(FIELD.METHOD, DEFAULT_METHOD_OPTIONS)
            }else {
                this.updateOptionsIfSet(FIELD.METHOD, maskKeys.map(k => [k, k]))
            }
        }

        if(this.hasState('masks')) {
            if(this.hasStateChanged('masks') || this.hasStateChanged(FIELD.METHOD)) {
                if(this.getState(FIELD.METHOD) && this.maskDataType(this.getState(FIELD.METHOD)) === MASK_DATATYPES.FUNCTION ) {
                    this.updateShapeForFunction_()
                    return
                }
                this.removeFieldsNotIn([])
            }
        }
        
    },
    
    ebxValidate: function(errors) {
        this.setWarningText(null, 'not_raster')
        const isRaster = this.getState('isRaster')
        if(!isRaster) {
            this.setWarningText(errors['not_raster'] || 'This block is only compatible with raster datasets.', 'not_raster')
            return
        }
    },

    updateShapeForFunction_() {
        const newParamState = this.maskParametersAsArray(this.getState(FIELD.METHOD))
        newParamState.forEach(this.addParameterField.bind(this))
        this.removeFieldsNotIn(newParamState.map(p => p.key))
    },
    removeFieldsNotIn(paramKeys) {
        const inputNames = paramKeys.map(n => n + '_param')
        const inputsToRemove = this.inputList.filter(input => {
            if(input.name && input.name.substring(input.name.length - 6) === '_param' && inputNames.indexOf(input.name) < 0) {
                return true
            }
            return false
        })
        inputsToRemove.forEach(input => {
            this.removeInput(input.name)
        })
    },

    methodValidator(newValue) {
        const block = this.getSourceBlock()
        if(block.isLoadingState() === false) {
            block.setState(FIELD.METHOD, newValue)
        }
        return newValue
    }
}