<template>
    <div>
        <md-dialog v-model:md-active="showModal" class="ebx-dialog modal-m api-config-modal">
            <md-dialog-title class="api-config-modal--title">
                <div class="top-actions--container">
                    <div class="title--container">
                        <h1 class="ebx-header-2">Project API access</h1>
                    </div>
                    <div class="close-button">
                        <md-button class="md-primary" @click="closeModal"><md-icon>close</md-icon></md-button>
                    </div>
                </div>
            </md-dialog-title>
            <md-dialog-content>
                <div class="api-config--container">
                    <md-switch name="apiAccess" v-model="apiAccess" @change="handleChangeApiAccess">API access</md-switch>
                </div>
                <div class="api-config--container" v-if="apiAccess">
                    <p class="ebx-secondary">Project endpoint</p>
                    <div class="api-config--text-container">
                        <p class="ebx-primary-bold">{{endpoint}}</p>
                        <button class="ebx-icon-button">
                            <span class="material-icons-outlined ebx-icon" @click="handleEndpointCopy(endpoint)">content_copy</span>
                        </button>
                    </div>
                </div>
            </md-dialog-content>
            <md-dialog-actions>
                <EbxButton :theme="'primary'" @click="closeModal">Okay</EbxButton>
            </md-dialog-actions>
        </md-dialog>
        <md-snackbar
        :md-position="'center'"
        :md-duration="10000"
        v-model:md-active="showSnackbar"
        md-persistent
        >
            <span>{{ message }}</span>
        </md-snackbar>

    </div>
    
</template>


<script>
import { auth } from "@/firebase.js"
import { 
    saveWorkflowMixin, 
    sharedWorkflowMixin,
    PROJECT_STATE
} from '@/components/mixins/projectMixin'
import authMixin from '@/components/mixins/authMixin'
import valueMixin from '@/components/mixins/valueMixin'

export default {
    name: 'APIConfiguration',
    data: () => ({
        token: '',
        isExpanded: false,
        buttonText:'Show',
        showSnackbar: false,
        message: "",
        apiAccess: false,
        showModal: false
    }),
    mixins: [saveWorkflowMixin, sharedWorkflowMixin, authMixin, valueMixin],
    props: {
        modelValue: {
            type: Boolean, 
            default: false
        },
        apiProjectId: {
            type: String, 
            default: ''
        },
        apiProject: {
            type: Object, 
            default: () => {}
        }
    },
    emits: [
        'update:modelValue'
    ],
    computed: {
        endpoint() {
            return `https://api.earthblox.io/v1beta/projects/${encodeURIComponent(this.apiProjectId)}`;
            // TODO make prefix configuration item
        },
        apiVersion() {
            return this.$store.state.global.currentAPIVersion || 'beta';
        }
    },
    methods: {
        closeModal() {
            this.showModal = false;
        }, 
        openModal() {
            this.showModal = true;
        },
        handleSaveProject(value) {
            this.saveProjectSave({projectApiAccess: value, projectApiVersion: this.apiVersion}, PROJECT_STATE.DETAIL_UPDATE, false)
        },
        handleEndpointCopy(value) {
            navigator.clipboard.writeText(value);
            this.message = `Project endpoint copied to clipboard`;
            this.showSnackbar = true;
        },
        handleTokenCopy(value) {
            navigator.clipboard.writeText(value);
            this.message = `JWT token copied to clipboard`;
            this.showSnackbar = true;
        },
        async getApiToken() {
            if(auth.currentUser) {
                return await auth.currentUser.getIdToken() 
            }
        },
        toggleTokenExpansion() {
            this.isExpanded = !this.isExpanded;
            this.buttonText = this.isExpanded ? 'Hide' : 'Show';
        },
        async handleChangeApiAccess(value) {
            await this.handleSaveProject(value);
        }
    },
    watch: {
        showModal: async function (newVal) {
            if (newVal === true) {
                this.token = await this.getApiToken();
            } else {
                if(this.saveProjectClearLoadedWorkflowOnSave) {
                    this.resetProject()
                }
            }
            this.$emit('update:modelValue', newVal)
        },
        value: {
            immediate: true,
            handler(value) {
                this.showModal = value
            }
        },
        apiProject: {
            immediate: true,
            handler(value) {
                if (value && Object.keys(value).includes('api_access')) {
                    this.apiAccess = value.api_access;
                }
            }
        }
    }
}
</script>
