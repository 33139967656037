import {AbstractAbility} from "./abstractAbility"
import { snakeCase } from 'lodash'
import { BandCollection } from './collections/BandCollection'
class ProvidesBands extends AbstractAbility{
    constructor(state) {
        super(state)
        this.stateKey = 'dataset'
    }
    asExtraBand(extraBandData) {
        this.stateKey = 'extra_bands'
        const bands = this.getState() && this.getState().bands ? this.getState().bands : []
        bands.push(extraBandData)
        this.updateState({ bands })
        return this
    }
    removePreviousBands() {
        this.updateState({ removePreviousBands: true })
        return this;
    }
    prependBand() {
        this.updateState({ prependBand: true })
        return this;
    }
    getBandAsDropdownOptions(ignoreNamespace = false, valueKey = 'ebx:name', keyKey = 'ebx:name') {
        const isNamespaced = ignoreNamespace === true ? false : this.getIsNamespaced()
        const nameSpace = this.getNamespace()
        return this
            .getState()
            .bands
            .map(band => {
                const key = isNamespaced ? snakeCase(nameSpace + ' ' + band[keyKey]) : band[keyKey]
                const value = isNamespaced ? nameSpace + ': ' + band[valueKey] : band[valueKey]
                return [value, key]
            })
    }
    getIsExtraBand() {
        return this.stateKey === 'extra_bands'
    } 
    getIsNamespaced() {
        return false
        // Enable this to be able to prefix the datasets to choose from each dataset if more than one
        //return [undefined,null].indexOf(this.getState().user_defined_name) < 0
    }
    getNamespace() {
        return this.getState().user_defined_name
    }
    getFilteredBandNames() {
        const filteredBands = this.getState().filtered_bands
        if(filteredBands === undefined) {
            return []
        }
        return filteredBands[filteredBands.length -1].bands
    }
    getPrependBand() {
        return this.getState().prependBand === true
    }
    getBands() {
        return this.getState().bands
    }
    getRemovePreviousBands() {
        return this.getState().removePreviousBands === true
    }
    /**
     * Gets a selection of bands properties and returns them in an array of objects
     * @param {Array} propsArray  ["ebx:name", "ebx:datatype", "gee:classes"]
     * @returns [
     * {"ebx:name": name, "ebx:datatype": datatype, "gee:classes": classes},
     * ...
     * ]
     */
    getBandsProperties(propsArray) {
        let bandProps = []
        const bands = this.getBands().flat()
        bands.forEach(band => {
            let propsObj = {}
            propsArray.forEach(prop => {
                propsObj[prop] = band[prop]
            })
            bandProps.push(propsObj)
        });
        return bandProps
    }

    static newCollection(abilites) {
        return new BandCollection(abilites)
    }
}

export {ProvidesBands}