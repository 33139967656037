/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/*
* SELECT-ATTRIBUTE L-Block
*/

import * as Blockly from 'blockly/core';
import {NO_DATA_DROPDOWN_VALUE, GEE_SCHEMA_ATTR_TYPES} from '@/constants/nextGenConstants';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock'
import {FieldNoTrimDropdown} from '@/fields/FieldNoTrimDropdown';
import { ContentService } from '@/services/content.service';

import { find } from 'lodash';

const defaultSelectOptions = [["Select an option", NO_DATA_DROPDOWN_VALUE]]

const FIELD = Object.freeze({
    ATTRIBUTE: 'attribute_options',
    CONDITIONALS: 'attribute_conditional',
    VALUE: 'attribute_value'
})

const INPUT = Object.freeze( {
    ATTRIBUTE: 'attribute',
    CONDITIONALS: 'attribute_conditional',
    SELECTOR: 'attribute_selector',
    VALUE: 'attribute_value'
})

const conditionalOptions =  [
    ['Equals', '.eq'], 
    ['Doesn\'t equal', '.neq'],
    ['Greater than', '.gt'], 
    ['Less than', '.lt'], 
    ['Greater than or equals', '.gte'], 
    ['Less than or equals', '.lte'],
    ['List Contains','.listContains'],
    ['String Contains','.stringContains']
];


var selectAttributeJson = {
    "type": "modifier_attribute",
    "message0": ` %1 %2 %3 %4 %5`,
    "args0": [
        {
            "type": "field_label",
            "name": "attribute_title",
            "text": "%{BKY_SELECT_ATTRIBUTE_BLOCK_TITLE}",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label",
            "name": "attribute_label",
            "text": "%{BKY_ATTRIBUTE_LABEL}"
        },
        {
            "type": "field_notrim_dropdown",
            "name":  FIELD.ATTRIBUTE,
            "options": defaultSelectOptions
        },
        {
            "type": "input_dummy",
            "name": INPUT.ATTRIBUTE
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "secondaryFilter",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['modifier_attribute'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    onInit: function() {
        this.jsonInit(selectAttributeJson);
        let attributeOptionsField = this.getField(FIELD.ATTRIBUTE);
        attributeOptionsField.setValidator(this.attributeValidator);

        this.about_block_url = ContentService.getAboutBlockUrl('modifier_attribute')
    },
    accept: async function (visitor) {
        await visitor.visitModifierAttributeSelectBlock(this);
    },
    getSelectedGeeSchema() {
        return find(this.getState('gee_schema'), { name: this.getState(FIELD.ATTRIBUTE) })
    },
    isSelectedSchemaASelector() {
        const schema = this.getSelectedGeeSchema()
        return (schema && schema.selector_title !== undefined && schema.selector_options !== undefined) 
    },

    attributeValidator(newValue) { 
        this.getSourceBlock().setState(FIELD.ATTRIBUTE, newValue)
        return newValue
    },
    conditionalValidator(newValue) { 
        this.getSourceBlock().setState(FIELD.CONDITIONALS, newValue)
        return newValue
    },

    onSaveExtraState(state) {
        if(state.gee_schema && state[FIELD.ATTRIBUTE]) {
            state.gee_schema = state.gee_schema.filter(s => s.name === state[FIELD.ATTRIBUTE])
        }
        return state
    },
    ebxValidate: function(tooltips) {
        if(this.getState(FIELD.ATTRIBUTE) === NO_DATA_DROPDOWN_VALUE) {
            return this.setWarningText(tooltips['no_filter_attributes'] || 'Please choose an attribute to filter', 'no_filter_attributes')
        }
        this.setWarningText(null,'no_filter_attributes')
    },
    updateShape_() {
        if(this.hasStateChanged('gee_schema')) {
            const attributeOptions = this.getState('gee_schema').map(datum => [datum['ebx:name'] || datum.name, datum.name])
            this.updateOptionsIfSet(FIELD.ATTRIBUTE, [].concat(defaultSelectOptions).concat(attributeOptions))
        }
        if(this.hasStateChanged(FIELD.ATTRIBUTE)) {
            if(this.isSelectedSchemaASelector()) {
                this.updateSelectorFields_()
            }else {
                this.updateConditionalField_()
            }
        }
        if(this.hasStateChanged(FIELD.CONDITIONALS)) {
            this.updateValueField_()
        }
        if(this.hasStateChanged("isVector")) {
            const type = this.getState("isVector")
            if (type) {
                this.updateLabel("attribute_label","Keep features with")
            } else { 
                this.updateLabel("attribute_label","Keep images with")
            }
        }

    },
    updateSelectorFields_() {
        const schema = this.getSelectedGeeSchema()
        if(schema) {
            this.removeConditionalOptions_()
            const options = Object.keys(schema.selector_options).map(name => [name, schema.selector_options[name].value])
            if(this.inputExists(INPUT.SELECTOR) === false) {
                this.appendDummyInput(INPUT.SELECTOR)
                this.moveInputAfter(INPUT.SELECTOR, INPUT.ATTRIBUTE)
                const input = this.getInput(INPUT.SELECTOR)
                input.appendField(schema.selector_title, "label")
                input.appendField(new FieldNoTrimDropdown([].concat(defaultSelectOptions).concat(options)), FIELD.VALUE);
            } else {
                this.setFieldValue(schema.selector_title, "label")
                this.getField(FIELD.VALUE).updateOptions([].concat(defaultSelectOptions).concat(options))
            }
        }
    },
    updateConditionalField_() {
        const schema = this.getSelectedGeeSchema()
        if(schema) {
            this.removeInputIfExists(INPUT.SELECTOR)
            if(this.inputExists(INPUT.CONDITIONALS) === false) {
                this.appendDummyInput(INPUT.CONDITIONALS)
                this.moveInputAfter(INPUT.CONDITIONALS, INPUT.ATTRIBUTE)
                const input = this.getInput(INPUT.CONDITIONALS)

                input.appendField(new FieldNoTrimDropdown(conditionalOptions), FIELD.CONDITIONALS);
                this.getField(FIELD.CONDITIONALS).setValidator(this.conditionalValidator);
                this.triggerValidator(FIELD.CONDITIONALS)
            }
        } else {
            this.removeConditionalOptions_()
        }
    },
    
    updateValueField_(){
        const schema = this.getSelectedGeeSchema()
        if(schema) {
            if(this.inputExists(INPUT.CONDITIONALS) === false) {
                this.appendDummyInput(INPUT.CONDITIONALS)
                this.moveInputAfter(INPUT.CONDITIONALS, INPUT.SELECTOR)
            }
            const currentValue = this.getFieldValue(FIELD.VALUE) || 'Enter Value'
            const currentInput = this.getInput(INPUT.CONDITIONALS);
            if(this.fieldExists(FIELD.VALUE)) {
                currentInput.removeField(FIELD.VALUE)
            }
            let field;
            switch(schema.type) {
                case GEE_SCHEMA_ATTR_TYPES.STRING:
                    field = new Blockly.FieldTextInput(currentValue)
                    break;
                case GEE_SCHEMA_ATTR_TYPES.INT:   
                    field = new Blockly.FieldNumber(currentValue, null /* min */, null /* max */, 1 /* precision */)
                    break;
                case GEE_SCHEMA_ATTR_TYPES.DOUBLE:
                case GEE_SCHEMA_ATTR_TYPES.FLOAT:
                    field = new Blockly.FieldNumber(currentValue)
                    break;
                default:
                    console.warn('GEE Schema type not found (' + schema.type + ') defaulting to string');
                    field = new Blockly.FieldTextInput(currentValue)
            }
            currentInput.appendField( field, FIELD.VALUE )
        }
    },
    removeConditionalOptions_() {
        this.removeInputIfExists(INPUT.CONDITIONALS)
        this.removeInputIfExists(INPUT.VALUE)
        this.removeState(FIELD.CONDITIONALS)
    }
}