<template>
    <div class="progress-indicator" v-if="showProgressIndicator">
        <div class="progress-indicator--container">
            <div class="progress-indicator--title-section">
                <div class="progress-indicator--title">
                    <span class="ebx-primary text-primary-invert" v-if="userAssetsSuccessStates.includes(operation.state)">
                        {{isDownloadOperation ? 'Export' : 'Upload'}} completed
                    </span>
                    <span class="ebx-primary text-primary-invert" v-else-if="userAssetsFailedStates.includes(operation.state)">
                        {{isDownloadOperation ? 'Export' : 'Upload'}} failed
                    </span>
                    <span class="ebx-primary text-primary-invert" v-else>
                        {{isDownloadOperation? 'Exporting' : 'Uploading'}}
                        </span>
                </div>
                <button class="progress-indicator--dismiss ebx-icon-button ebx-icon-button__dark" @click="dismissIndicator">
                    <span class="material-icons-outlined ebx-icon">close</span>
                </button>
            </div>
            <div class="progress-indicator--progress-section">
                <div class="progress-indicator--filename">
                    <p class="ebx-secondary text-primary-invert" v-if="isImageUploadOperation">{{operation.formData.name}}</p>
                    <p class="ebx-secondary text-primary-invert" v-else-if="isAreaUploadOperation">{{operation.filename.split('.')[0]}}</p>
                    <p class="ebx-secondary text-primary-invert" v-else-if="isDownloadOperation">{{operation.description}}</p>
                </div>
                <div class="progress-indicator--link" v-if="userAssetsSuccessStates.includes(operation.state) && isImageUploadOperation">
                    <button class="ebx-link" @click="goToDataset(operation.stacID)">View in assets</button>
                </div>
                <div class="progress-indicator--link" v-else-if="userAssetsSuccessStates.includes(operation.state) && isAreaUploadOperation">
                    <button class="ebx-link" @click="goToAreasPage">View in Assets</button>
                </div>
                <div class="progress-indicator--link" v-else-if="userAssetsSuccessStates.includes(operation.state) && isDownloadOperation">
                    <button class="ebx-link" @click="goToExportsPage">View in Exports</button>
                </div>
                <div class="progress-indicator--error" v-else-if="userAssetsFailedStates.includes(operation.state)">
                    <p class="ebx-secondary" v-if="isDownloadOperation">Export failed</p>
                    <p class="ebx-secondary" v-else-if="isImageUploadOperation || isAreaUploadOperation">Upload failed</p>
                </div>
                <md-progress-bar class="md-accent" md-mode="indeterminate" v-else></md-progress-bar>
            </div>
        </div>
    </div>
</template>

<script>
import authMixin from './mixins/authMixin';
import userAssetsMixin from './mixins/userAssetsMixin';
import {globalEventBus} from '@/eventbus.js';
import { 
    INGEST_IMAGE_OP_TYPE,
    INGEST_TABLE_OP_TYPE,
    EXPORT_IMAGE_OP_TYPE,
    EXPORT_FEATURES_OP_TYPE
    } from '@/constants/appConstants'

export default {
    name: 'ProgressIndicator',
    mixins: [authMixin, userAssetsMixin],
    props: {
        operation: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
        }
    },
    methods: {
        dismissIndicator() {
            this.$store.dispatch('userassets/dismissAssetNotification', {userId: this.user.uid, operation: this.operation});
        },
        goToDataset(datasetId) {
            const currentRoute = this.$router.currentRoute;
            if (currentRoute.path !== `/projects/images/${datasetId}`) {
                this.$router.push({ name: 'Projects-Dataset', params: { datasetId: datasetId } })
            }
            this.dismissIndicator();
        },
        goToExportsPage() {
            const currentRoute = this.$router.currentRoute;
            if (currentRoute.path !== '/projects/exports') {
                this.$router.push({ name: 'Projects-Exports' })
            }
            this.dismissIndicator();
        },
        goToAreasPage() {
            const currentRoute = this.$router.currentRoute;
            if (currentRoute.path !== '/projects/assets') {
                this.$router.push({ name: 'Projects-Asset-Features' })
            }
            this.dismissIndicator();
        }
    },
    mounted() {
    },
    computed: {
        showProgressIndicator() {
            if (this.operation && this.operation.hasDismissedAssetNotification) {
                // for better code legibility
                let isFinishedNotification = this.operation.hasDismissedAssetNotification.isFinished;
                let isUploadingNotification = this.operation.hasDismissedAssetNotification.isUploading;

                if (isUploadingNotification && isFinishedNotification) {
                    // if user has already dismissed this when uploading and completed, 
                    // DON'T SHOW
                    return false;
                } 
                else if (this.operationIsSuccessful && !isFinishedNotification) {
                    // if upload is finished and user hasn't dismissed this while finished, 
                    // SHOW
                    return true;
                }
                else if (this.operationIsUploading && !isUploadingNotification) {
                    // if upload is in progress and user hasn't dismissed this while uploading,
                    // SHOW
                    return true;
                } else if (this.operationIsFailed && !isFinishedNotification) {
                    // if upload is failed and user hasn't dismissed this after it failed,
                    // SHOW
                    return true;
                } else {
                    // DON'T SHOW
                    return false;
                }
            } else {
                // DON'T SHOW
                return false;
            }
        },
        operationIsFailed() {
            return this.userAssetsFailedStates.includes(this.operation.state);
        },
        operationIsUploading() {
            return !this.userAssetsSuccessStates.includes(this.operation.state) && !this.userAssetsFailedStates.includes(this.operation.state);
        },
        operationIsSuccessful() {
            return this.userAssetsSuccessStates.includes(this.operation.state);
        },
        isAreaUploadOperation() {
            return this.operation.task_type === INGEST_TABLE_OP_TYPE;
        },
        isImageUploadOperation() {
            return this.operation.task_type === INGEST_IMAGE_OP_TYPE;
        },
        isImageDownloadOperation() {
            return this.operation.task_type === EXPORT_IMAGE_OP_TYPE;
        },
        isTableDownloadOperation() {
            return this.operation.task_type === EXPORT_FEATURES_OP_TYPE;
        },
        isDownloadOperation() {
            return this.isImageDownloadOperation || this.isTableDownloadOperation;
        }
    },
    watch: {
        operationIsSuccessful(newValue) {
            const currentRoute = this.$router.currentRoute;
            // if operation succeeded
            if (newValue === true) {
                // if task is INGEST_TABLE_OP_TYPE
                if (this.isAreaUploadOperation && (currentRoute.path === '/projects/assets' || currentRoute.path === '/workflow')) {
                    // refresh areas
                    globalEventBus.$emit('refresh-areas');
                } else if (this.isDownloadOperation && currentRoute.path === '/projects/exports') {
                    // refresh exports
                    globalEventBus.$emit('refresh-exports');
                }
            }
            return newValue;
        },
    }
}
</script>