<template>
    <div>
        <h1>Organisation Features</h1>

        <div>
            <legend class="md-subheading">Customise organisation features</legend>
            <div v-for="feature in orgFeatures" :key="feature.name">
                <br>
                <p class="md-body-2">{{feature.name}}</p>
                <div class="checkbox-group">
                    <md-checkbox v-model="feature.isAvailable">
                        {{feature.name + ' is available'}}
                    </md-checkbox>
                </div>
            </div>
            <md-button class="md-primary" @click="saveFeatures()">Save</md-button>
        </div>
    </div>
</template>

<script>
import { ConfigService } from '@/services/config.service';
import { AuthService } from "../services/auth.service";
import { CONFIG_TYPE } from "@/config/constants.js";

export default {
    name: "OrganisationFeatures",
    components: {
    },
    data: () => ({
        user: null,
        orgFeatures: null,
        featureList: null,
        orgConfig: null
    }),
    methods: {
        saveFeatures() {
            this.orgConfig.saveCustomConfigForOrg(this.orgFeatures, CONFIG_TYPE.FEATURES);
            console.log('saving', this.orgFeatures);
        },
    },
    created: function() {
        this.subscription = AuthService.loggedUser$.subscribe(
            (user) => (this.user = user)
        );

        ConfigService.getOrgConfig(this.user.orgId).then(function(config){
            this.orgConfig = config;
            console.log(this.orgConfig)

            this.orgFeatures = this.orgConfig.getEditableConfig(CONFIG_TYPE.FEATURES);
            console.log('this.orgFeatures', this.orgFeatures)

        }.bind(this));
    },
    beforeUnmount() {
        this.subscription.unsubscribe();
    },
};
</script>

<style scoped lang="scss">
.md-fab {
  float: right;
  margin-top: -9px;
}
</style>
