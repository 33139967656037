export default {
    computed: {
        isStripeActive() {
            return this.$store.state.stripe.isActive;
        },
        userHasActiveSubscription() {
            return this.$store.state.stripe.userHasActiveSubscription;
        },
        stripePortalKey() {
            return this.$store.state.stripe.stripePortalKey;
        }
    }
}