<template>
    <md-menu md-direction="bottom-start" md-align-trigger :md-offset-x="240" :md-offset-y="-36">
        <md-button ref="valueButton" class="md-secondary" :class="buttonClass" :disabled="!hasDatasets" md-menu-trigger>
            <md-icon>add</md-icon>
            <span> Add group</span>
        </md-button>
        <md-menu-content>
            <template v-if="hasImageCollectionDatasets">
                <md-menu-item class="md-menu-item--title">Image Band</md-menu-item>
                <md-menu-item v-for="dataset in imageCollectionDatasets" :key="dataset.id" @click="$emit('click', 'group-image-collection', dataset.id)">
                    {{ dataset.title }}
                </md-menu-item>
            </template>
            <template v-if="hasFeatureCollectionDatasets">
                <md-menu-item class="md-menu-item--title">Collections</md-menu-item>
                <md-menu-item v-for="dataset in featureCollectionDatasets" :key="dataset.id" @click="$emit('click', 'group-feature-collection', dataset.id)">
                    {{ dataset.title }}
                </md-menu-item>
            </template>    
        </md-menu-content>
    </md-menu>
</template>

<script>
export default {
    props: {
        datasets: {
            type: Array,
            default: () => []
        },
        buttonClass: {
            type: Object,
            default: () => {}
        }
    },
    emits: [
        'click'
    ],
    computed: {
        hasImageCollectionDatasets() {
            return this.imageCollectionDatasets.length > 0
        },
        hasFeatureCollectionDatasets() {
            return this.featureCollectionDatasets.length > 0
        },
        imageCollectionDatasets() {
            return this.datasetsWithGroups.filter(dataset => dataset.type === 'image_collection')
        },
        featureCollectionDatasets() {
            return this.datasetsWithGroups.filter(dataset => dataset.type === 'feature_collection')
        },
        datasetsWithGroups() {
            return this.datasets.filter(dataset => {
                if(dataset.type === 'feature_collection') {
                    return true
                }
                const thematicBands = dataset.bands.filter(b => ['thematic','string'].includes(b['ebx:datatype']))
                return thematicBands.length > 0
            })
        },
        hasDatasets() {
            return this.datasetsWithGroups.length > 0
        }
    }
}
</script>