<template>
    <div>
    <md-dialog v-model:md-active="computedModalShown" class="blockly-modals">
        <md-dialog-title>
            <span>WekaCascadeKMeans Options</span>
        </md-dialog-title>

        <form>
        
            <md-field>
                <label for="minClusters">Min Number of Clusters</label>
                <md-input name="minClusters" v-model="formData.minClusters" type="number" :placeholder="minClusters ? minClusters.toString() : ''"></md-input>
            </md-field>
            <md-field>
                <label for="maxClusters">Max Number of Clusters</label>
                <md-input name="maxClusters" v-model="formData.maxClusters" type="number" :placeholder="maxClusters ? maxClusters.toString() : ''"></md-input>
            </md-field>
            <md-field>
                <label for="restarts">Restarts</label>
                <md-input name="restarts" v-model="formData.restarts" type="number" :placeholder="restarts ? restarts.toString() : ''"></md-input>
            </md-field>
            <div>
                <md-switch name="init" v-model="formData.init">Initialize using the probabilistic farthest first</md-switch>
            </div>
            <md-field>
                <label for="distanceFunction">Distance Function</label> 
                <md-select name="distanceFunction" v-model="formData.distanceFunction">
                    <md-option v-for="option in distanceOptions" :value="option[1]" :key="option[1]">
                        {{ option[0]}}
                    </md-option>
                </md-select>
            </md-field>
            <md-field>
                <label for="maxIterations">Maximum Iterations</label>
                <md-input name="maxIterations" v-model="formData.maxIterations" type="number" :placeholder="maxIterations ? maxIterations.toString() : ''"></md-input>
            </md-field>
           
        </form>

        <md-dialog-actions>
            <md-button class="md-primary" @click="computedModalShown = false">Close</md-button>
            <md-button class="md-primary" @click="saveModal">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
    </div>
</template>

<script>
import { globalEventBus } from '@/eventbus.js'
import blocklyModalsMixin from '../blocklyModalsMixin.js'

export default {
    name: 'UnsupervisedClassificationWekaCascadeKMeans',
    mixins: [blocklyModalsMixin],
    props: {
        minClusters: {
            type: Number,
            default: 2
        },
        maxClusters: {
            type: Number,
            default: 10
        },
        restarts: {
            type: Number,
            default: 10
        },
        init: {
            type: Boolean,
            default:false
        },
        distanceFunction: {
            type: String,
            default: 'Euclidean'
        },
        maxIterations: {
            type: Number,
            default: null
        },
        showModal: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            distanceOptions: [
                ['Euclidean','Euclidean'],
                ['Manhattan','Manhattan']
            ],
            formData: {
                minClusters: this.minClusters,
                maxClusters: this.maxClusters,
                restarts: this.restarts,
                init: this.init,
                distanceFunction: this.distanceFunction,
                maxIterations: this.maxIterations,
            }
        }
    },
    methods: {
        getFieldValue() {
            return {
                minClusters: this.formData.minClusters,
                maxClusters: this.formData.maxClusters,
                restarts: this.formData.restarts,
                init: this.formData.init,
                distanceFunction: this.formData.distanceFunction,
                maxIterations: this.formData.maxIterations,
            };
        },
        saveModal() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        },
    },
    watch: {
        showModal(val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if (val) {
                this.formData = {
                    minClusters: this.minClusters,
                    maxClusters: this.maxClusters,
                    restarts: this.restarts,
                    init: this.init,
                    distanceFunction: this.distanceFunction,
                    maxIterations: this.maxIterations,
                }
            }
        }
    }
}
</script>