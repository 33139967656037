/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import { debounce } from 'lodash';

/**
 * EarthEngineMapType is a custom image map tiling map, it uses very similar code to that found in
 * google map layer, but standardised within its own class rather than mixing with Vue and state
 * This enables it to be a possibly cleaner and more abstracted map tiler
 */
// TODO update GoogleMapLayer.vue to use the EarthEngineMapType
export class EarthEngineMapType{
    // earth engine tile size is always 256
    // https://developers.google.com/earth-engine/guides/scale#image-pyramids
    minZoom = 1;
    maxZoom = 20;
    name = "EarthEngine"
    alt = "Earth Engine Tiling Basemap"
    google;
    map;
    mapURL;
    tileSize;

    /**
     * creates the ImageDom element and adds an event listener for error handling and retries
     * @param {string} mapURL - the map tiling url
     * @param {GoogleMapObject} map - the google map which this overlay is getting added to
     */
    constructor(mapURL, map, google) {
        this.mapURL = mapURL;
        this.map = map;
        this.google = google;
        this.id = this.name
        this.tileSize = new this.google.maps.Size(256, 256);
    }

    /**
     * Using a coordinate set and zoom, we produce an image by replacing the base URL with the XYZ coordinates
     * and creating an ImageDom object, creates a debounced reload function if the image fails to load
     * @param {Point} coord - contains x and y coord of the tile required to fill in
     * @param {Number} zoom - contains the zoom level of the tile required to fill in
     * @param {*} zoom 
     * @returns { HTMLElement } - return an html tile element
     */
    getTile(coord, zoom) {
        let url = this.mapURL
                    .replace("{x}", coord.x)
                    .replace("{y}", coord.y)
                    .replace("{z}", zoom);

        let image = this.loadImage(url);

        return image;
    }

    /**
     * Creates an image dom object, which it uses to track the browsers progress at loading
     * the tile if the image failst to load then it makes a call to refresh map tiles
     * @param {String} url - url to append to image object
     */
    loadImage(url) {
        let image = new Image();
        image.src = url;

        image.addEventListener('error', () => {
            console.debug('Earth Engine Basemap tile load error')
            // debounces for 1 second before refreshing tiles
            this.refreshMapTiles()
        })

        return image
    }

    /**
     * debounced function call which refreshes all map tiles by making a micro change to the
     * map zoom
     */
    refreshMapTiles = debounce(()=> {
        // if it errors it will zoom out and zoom back in to force a refresh of just the broken tiles
        // all the other tiles are kept in cache
        console.debug("changing zoom to force refresh")
        this.map.setZoom(this.map.getZoom()+.000000000000001);
        //this will change the zoom again and load fresh tiles
        // user cannot see as the zoom change is tiny
        this.map.setZoom(Math.round(this.map.getZoom()));
    }, 1000)

}