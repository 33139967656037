<template>
    <div class="ebx-color-value">
        <div class="ebx-color-value--label ebx-tertiary">
            {{ label }}
        </div>
        <div class="ebx-color-value--value-wrapper">
            <EbxColorPicker
                :model-value="color"
                :disabled="true"
                :id="`color-picker-${Math.random()}`"
                :includeHash="false"
                theme="thin"
            ></EbxColorPicker>
            <div v-if="colorDescription" class="ebx-color-value--description ebx-secondary">
                {{ colorDescription }}
            </div>
            <button v-if="colorDescription && !copied" class="ebx-color-value--copy-paste">
                <span class="material-icons ebx-icon" @click="() => handleCopyClick(colorDescription)">content_copy</span>
            </button>
            <button v-else-if="colorDescription && copied" class="ebx-color-value--copy-paste">
                <span class="material-icons ebx-icon" @click="() => handleCopyClick(colorDescription)">check</span>
            </button>
        </div>
    </div>
</template>

<script>
import EbxColorPicker from './EbxColorPicker.vue';
import copyPasteMixin from '../mixins/copyPasteMixin';

export default {
    name: "EbxColorValue",
    components: {
        EbxColorPicker
    },
    mixins: [copyPasteMixin],
    props: {
        label: {
            type: String,
            default: "",
            required: true
        },
        color: {
            type: String,
            default: "#000000",
            required: true
        },
        colorDescription: {
            type: String,
            default: "",
            required: false
        }
    },
    
}
</script>