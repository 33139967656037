<template>
    <div class="styleguide">
        <h1 class="ebx-header-1">StyleGuide</h1>

        <p class="ebx-primary mb-20">Where possible, the <span class="material-icons-outlined ebx-icon" >content_copy</span> icon is an action that will copy the required html for the user to paste into their code. On occasion this might be a Vue component, with some representative props to showcase the different style/display options. </p>

        <h2 class="ebx-header-2 mb-20">Ebx Typography</h2> 
        <StyleguideTypography />

        <h2 class="ebx-header-2 mb-20">Ebx Cards</h2> 
        <StyleguideCards />
        <!-- ICONS -->
        <h2 class="ebx-header-2 mb-20">Icons</h2>
        <a class="ebx-action-text ebx-action-text__icon mb-20" href="https://fonts.google.com/icons" target="_blank">Icon library <span class="material-icons-outlined ebx-icon ebx-icon__small">open_in_new</span></a>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Icon</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_icon)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <span class="material-icons-outlined ebx-icon">delete</span>
            </div>

            <div class="styleguide--component-header">
                <h3>Ebx Icon Medium</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_icon_medium)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <span class="material-icons-outlined ebx-icon ebx-icon__medium">delete</span>
            </div>

            <div class="styleguide--component-header">
                <h3>Ebx Icon Small</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_icon_small)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <span class="material-icons-outlined ebx-icon ebx-icon__small">delete</span>
            </div>

            <div class="styleguide--component-header">
                <h3>Ebx Icon Error</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_icon_error)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <span class="material-icons-outlined ebx-icon ebx-icon__error">delete</span>
            </div>
        </div>

        <!-- BUTTONS -->
        <h3 class="ebx-header-2 mb-20">Buttons</h3> 

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Run Button - Pine</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_run_button_pine)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="ebx-run-button">
                    <button type="button" name="Run" class="ebx-run-button--pine">
                        <span class="ebx-action">Run</span>
                        <img class="button-icon" :src="assets.icons.play" alt="Toggle expanded section">
                    </button>
                    <!-- Workflow settings menu and trigger -->
                    <md-menu :md-offset-x="0" :md-offset-y="-70">
                        <button class="ebx-run-button--options" md-menu-trigger>
                            <div class="carat">
                                <img :src="assets.icons.carat" alt="Toggle expanded section">
                            </div>
                        </button>
                        <md-menu-content class="ebx-options-menu">
                            <md-menu-item  class="ebx-options-menu--option">Workflow settings</md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </div>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Run Button - Pine:Disabled</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_run_button_pine_disabled)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="ebx-run-button">
                    <button type="button" disabled name="Run" class="ebx-run-button--pine">
                        <span class="ebx-action">Run</span>
                        <img class="button-icon" :src="assets.icons.playDisabled" alt="Toggle expanded section">
                    </button>
                    <!-- Workflow settings menu and trigger -->
                    <md-menu :md-offset-x="0" :md-offset-y="-70">
                        <button class="ebx-run-button--options" md-menu-trigger>
                            <div class="carat">
                                <img :src="assets.icons.carat" alt="Toggle expanded section">
                            </div>
                        </button>
                        <md-menu-content class="ebx-options-menu">
                            <md-menu-item class="ebx-options-menu--option">Workflow settings</md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </div>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Run Button - Error</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_run_button_error)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="ebx-run-button">
                    <button name="Stop" type="button" class="ebx-run-button--error">
                        <span class="ebx-action">Stop</span>
                        <img class="button-icon" :src="assets.icons.stop" alt="Toggle expanded section">
                    </button>
                    <!-- Workflow settings menu and trigger -->
                    <md-menu :md-offset-x="0" :md-offset-y="-70">
                        <button class="ebx-run-button--options" md-menu-trigger>
                            <div class="carat">
                                <img :src="assets.icons.carat" alt="Toggle expanded section">
                            </div>
                        </button>
                        <md-menu-content class="ebx-options-menu">
                            <md-menu-item  class="ebx-options-menu--option">Workflow settings</md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </div>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Button Primary</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_button_primary)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--primary">Primary</button>
            </div>
            <div class="styleguide--component-header">
                <h3>EbxButton Component - Primary</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebxbutton_primary)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <EbxButton :theme="'primary'">EbxButton</EbxButton>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Button Primary Warning</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_button_primary_warning)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--primary ebx-button--primary__warning">Primary Warning</button>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Button Primary Disabled</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_button_primary_disabled)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--primary__disabled">Primary Disabled</button>
            </div>
            <div class="styleguide--component-header">
                <h3>EbxButton Component - Primary Disabled</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebxbutton_primary_disabled)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <EbxButton :theme="'primary'" :disabled="true">EbxButton</EbxButton>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Button Circle</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_button_circle)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--circle" style="background-color: red;"></button>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Button Circle (with Icon)</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_button_circle_icon)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--circle">
                    <md-icon>add</md-icon>
                </button>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Button Circle (Active)</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_button_circle_active)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--circle is-active" style="background-color: lightgray;">
                </button>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Button Secondary</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_button_secondary)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--secondary">Secondary</button>
            </div>
            <div class="styleguide--component-header">
                <h3>EbxButton Component - Secondary</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebxbutton_secondary)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <EbxButton :theme="'secondary'">EbxButton</EbxButton>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Button Secondary Disabled</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_button_secondary_disabled)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--secondary__disabled">Secondary Disabled</button>
            </div>
            <div class="styleguide--component-header">
                <h3>EbxButton Component - Secondary Disabled</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebxbutton_secondary_disabled)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <EbxButton :theme="'secondary'" :disabled="true">EbxButton</EbxButton>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Button Secondary - Highlight</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_button_secondary_compact)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--secondary ebx-button--secondary__highlight">highlight</button>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Button Secondary - Compact</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_button_secondary_highlight)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--secondary ebx-button--secondary__compact">Compact</button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--secondary ebx-button--secondary__compact ebx-button--secondary__highlight">compact + highlight</button>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Button Spinner Disabled</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_button_spinner_disabled)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--spinner ebx-button--spinner__disabled">
                    <md-progress-spinner class="md-accent" md-mode="indeterminate" :md-diameter="20" :md-stroke="2"></md-progress-spinner>
                    Loading
                </button>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Link</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_link)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body styleguide--component-body__dark">
                <button class="ebx-link">Ebx link</button>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Link light</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_link_light)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-link ebx-link__light">Ebx link light</button>
            </div>
        </div>


        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Close Button (only for use within ebx-dialog!)</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.close_button)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="close-button">
                    <md-button class="md-primary"><md-icon>close</md-icon></md-button>
                </div>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Action Text</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_action_text)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="ebx-action-text">
                    <p>Back to Assets</p>
                </div>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Action Text Primary</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_action_text_primary)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="ebx-action-text ebx-action-text--primary">
                    <p>Back to Assets</p>
                </div>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Action Text Primary Icon</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_action_text_primary_icon)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="ebx-action-text ebx-action-text--primary ebx-action-text__icon">
                    <span class="material-icons-outlined ebx-icon">chevron_left</span>
                    <p>Back to Assets</p>
                </div>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Action Text Primary Icon-Right</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_action_text_primary_icon_right)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="ebx-action-text ebx-action-text--primary ebx-action-text__icon">
                    <p>Back to Assets</p>
                    <span class="material-icons-outlined ebx-icon">chevron_right</span>
                </div>
            </div>
        </div>

        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Icon Button</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_icon_button)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-icon-button"><span class="material-icons-outlined ebx-icon">thumb_up</span></button>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Icon Button - no shadow</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_icon_button_no_shadow)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-icon-button ebx-icon-button__no-shadow"><span class="material-icons-outlined ebx-icon">thumb_up</span></button>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Icon Button Dark</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_icon_button_dark)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body styleguide--component-body__dark">
                <button class="ebx-icon-button ebx-icon-button__dark"><span class="material-icons-outlined ebx-icon">thumb_up</span></button>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Icon Button Disabled</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_icon_button_disabled)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-icon-button ebx-icon-button__disabled"><span class="material-icons-outlined ebx-icon">thumb_up</span></button>
            </div>
            <br>
            <a class="ebx-action-text ebx-action-text__icon mb-20" href="https://fonts.google.com/icons" target="_blank">Icon library <span class="material-icons-outlined ebx-icon ebx-icon__small">open_in_new</span></a>
        </div>

        <!-- EBX Components -->
        <h2 class="ebx-header-2 mb-20">Components</h2> 

        <!-- EbxTimeline -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Timeline - Navigation</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_timeline)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <EbxTimeline 
                :navigation="true" 
                :options="timelineOptions" 
                :maxPointsShown="4"
                />
            </div>
        </div>

        <!-- Progress Indicator -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Progress Indicator - in progress</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.progress_indicator)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="progress-indicator" style="width: 270px">
                    <div class="progress-indicator--container">
                        <div class="progress-indicator--title-section">
                            <div class="progress-indicator--title">
                                <span class="ebx-primary text-primary-invert">Uploading</span>
                            </div>
                            <button class="progress-indicator--dismiss ebx-icon-button ebx-icon-button__dark" @click="dismissIndicator">
                                <span class="material-icons-outlined ebx-icon">close</span>
                            </button>
                        </div>
                        <div class="progress-indicator--progress-section">
                            <div class="progress-indicator--filename">
                                <p class="ebx-secondary text-primary-invert">file name.filetype</p>
                            </div>
                            <md-progress-bar class="md-accent" md-mode="indeterminate"></md-progress-bar>
                        </div>
                    </div>
                </div>
            </div>
            <div class="styleguide--component-header">
                <h3>Progress Indicator - completed</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.progress_indicator)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="progress-indicator" style="width: 270px">
                    <div class="progress-indicator--container">
                        <div class="progress-indicator--title-section">
                            <div class="progress-indicator--title">
                                <span class="ebx-primary text-primary-invert">Upload completed</span>
                            </div>
                            <button class="progress-indicator--dismiss ebx-icon-button ebx-icon-button__dark" @click="dismissIndicator">
                                <span class="material-icons-outlined ebx-icon">close</span>
                            </button>
                        </div>
                        <div class="progress-indicator--progress-section">
                            <div class="progress-indicator--link">
                                <a class="ebx-primary-bold ebx-link" href="#">Link</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="styleguide--component-header">
                <h3>Progress Indicator - failed</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.progress_indicator)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="progress-indicator" style="width: 270px">
                    <div class="progress-indicator--container">
                        <div class="progress-indicator--title-section">
                            <div class="progress-indicator--title">
                                <span class="ebx-primary text-primary-invert">Upload failed</span>
                            </div>
                            <button class="progress-indicator--dismiss ebx-icon-button ebx-icon-button__dark" @click="dismissIndicator">
                                <span class="material-icons-outlined ebx-icon">close</span>
                            </button>
                        </div>
                        <div class="progress-indicator--progress-section">
                            <div class="progress-indicator--error">
                                <p class="ebx-secondary">Error message</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- EBX Inline Input -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Inline Input - right position action btns</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_inline_input)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <EbxInlineInput 
                    v-model="titleExample" 
                    :id="'title_field'"
                    :input-type="`text`"
                    :input-style-classes="['ebx-header-2']"
                    :edit-actions-position="`right`"
                    :disabled="false">
                </EbxInlineInput>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Inline Input - invalid, bottom-right position action btns</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_inline_input_invalid)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body" style="width: 36%">
                <EbxInlineInput 
                    v-model="descriptionExample" 
                    :id="'description-example'"
                    :input-placeholder="'descriptionPlaceholder'" 
                    :input-type="`text`"
                    :input-style-classes="['ebx-primary-bold']"
                    :input-validation="testValidation"
                    :edit-actions-position="`bottom-right`"
                    :disabled="false">
                </EbxInlineInput>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Inline Input - disabled, bottom-right position action btns</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_inline_input_disabled)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <EbxInlineInput 
                    v-model="descriptionExample" 
                    :id="'description-example-disabled'"
                    :input-placeholder="'descriptionPlaceholder'" 
                    :input-type="`text`"
                    :input-style-classes="['ebx-primary-bold']"
                    :edit-actions-position="`bottom-right`"
                    :disabled="true">
                </EbxInlineInput>
            </div>
        </div>

        <!-- EBX Search Bar -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Search Bar - with filter by</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_search_bar)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <EbxSearchBar v-model="searchBar" :filter-options="searchBarOptions" />
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Search Bar - no filter by</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_search_bar_no_filter)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <EbxSearchBar v-model="searchBar" />
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Search Bar - no search bar</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_search_bar_no_search)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <EbxSearchBar v-model="searchBar" :hide-search="true" :filter-options="searchBarOptions" />
            </div>
        </div>

        <!-- Drop zone -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>File Drop Zone</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.file_drop_zone)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="polygon-upload">
                    <div class="upload-container">
                        <div v-if="filesToUpload.length === 0"
                            id="drop_zone"
                            class="upload-container--drop-zone"
                            @drop="dropHandler($event)"
                            @dragover="dragOverHandler($event)">
                            <p class="ebx-primary">Drag and drop a GeoJSON, KML, KMZ, or a zipped shape file</p>
                            <button class="ebx-button ebx-button--secondary" @click="triggerFileSelection()">Choose File</button>
                            <input
                                v-show="false"
                                ref="fileInput"
                                class="ebx-button md-button-secondary md-accent" 
                                type="file" 
                                :accept="acceptedTypes"
                                @change="addFile($event)"/>
                        </div>
                        <div class="upload-container--file-zone" v-if="filesToUpload.length>0">
                            <span 
                                v-for="file in filesToUpload"
                                :key="file.name"
                                class="ebx-primary">
                                {{file.name}}
                            </span>
                            <md-button class="md-icon-button md-primary" @click="removeFile"><md-icon>close</md-icon></md-button>
                        </div>
                        <EbxAlert theme="error" v-if="uploadError">
                            {{uploadErrorMessage}}
                        </EbxAlert>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODALS -->
        <!-- confirmation modal -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Confirmation Modal - non-destructive</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.confirmation_modal)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--primary" @click="confirmModal">Click me</button>
                <ConfirmationModal ref="confirm" v-model="showConfirm" title="Wanna confirm stuff?" ok-button-text="Yeah sure" 
                    cancel-button-text="Nah" :if-warning="false">
                    <p>This is a confirmation modal, do you want to confirm stuff?</p>
                </ConfirmationModal>
            </div>
            <div class="styleguide--component-header">
                <h3>Confirmation Modal - destructive</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.confirmation_modal_destructive)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--primary" @click="confirmDelete">Click me</button>
                <ConfirmationModal ref="confirm" v-model="showDeleteConfirm" title="Wanna delete stuff?" ok-button-text="Delete it" 
                    cancel-button-text="Nah" :if-warning="true">
                    <p>This is a confirmation modal, do you want to delete stuff?</p>
                </ConfirmationModal>
            </div>
        </div>

        <!-- ebx dialog -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Dialog - uses #toplayer</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_dialog)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--primary" @click="openEbxDialog">Click me</button>
                <EbxDialog :showEbxDialog="showEbxDialog" :cancellable="false" 
                    :linkDialog="{
                        isLink: true,
                        linkText: 'Manage your subscription',
                        linkTarget: 'www.google.com',
                        linkEmail: '/search?q=earthblox+support'
                    }" 
                    :dialogContent="{
                        title: 'Your trial has ended',
                        body: 'To continue using Earth Blox, add your payment details. '
                    }" 
                    :dialogClasses="['modal-ms']" />
            </div>
        </div>
            
        <!-- ebx error dialog -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Error Dialog</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_error_dialog)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <button class="ebx-button ebx-button--primary" @click="openEbxError">Click me</button>
                <EbxErrorDialog
                    :showErrorDialog="showErrorDialog"
                    :errorDialogMessage="'This is an error message'"
                    @error-dialog-active="handleErrorDialogActive"
                />
            </div>
        </div>

        <!-- Colours -->

        <!-- Empty State -->

        <!-- FORMS -->

        <!-- product update modal -->
        
        <!-- OTHERS -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Progress Bar (not in use yet)</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_progress_bar)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body styleguide--component-body__dark">
                <div class="ebx-progress-bar" style="width: 270px">
                    <div class="ebx-progress-bar--container">
                        <div class="ebx-progress-bar--progress" style="width: 33%"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- EBX Tabs-->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Tabs</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_tabs)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <EbxTabs>
                    <EbxTab id="tab1" title="Tab 1">
                        <p>Tab 1 content</p>
                    </EbxTab>
                    <EbxTab id="tab2" title="Tab 2">
                        <p>Tab 2 content</p>
                    </EbxTab>
                </EbxTabs>
            </div>
        </div>

        <!-- Pills-->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Pills</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_pills)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="ebx-pill">Default Pill</div>
                <div class="ebx-pill ebx-pill--pine">Pine Pill</div>
                <div class="ebx-pill ebx-pill--loch">Loch Pill</div>
                <div class="ebx-pill ebx-pill--gorse">Gorse Pill</div>
            </div>
        </div>

         <!-- EbxSwitch-->
         <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Switch</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_switch)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div>
                    Animated
                    <EbxSwitch on-text="Active" off-text="In-Active" :animate="true" />
                </div>
                <div>
                    Not Animated
                    <EbxSwitch on-text="Active" off-text="In-Active" :animate="false" />
                </div>
                <div>
                    Dark mode
                    <EbxSwitch on-text="Active" off-text="In-Active" theme="dark" />
                </div>
            </div>
        </div>

         <!-- EBX Alert-->
         <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Alerts</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_alerts)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <EbxAlert theme="success">Success message</EbxAlert>
                <EbxAlert theme="error">Error message</EbxAlert>
                <EbxAlert theme="warning">Warning message</EbxAlert>
                <EbxAlert theme="info">Info message</EbxAlert>
            </div>
        </div>

        <!-- Inline Form-->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Inline Form Labels</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_inline_field)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="ebx-inline-label-field">
                    <label class="ebx-primary">Select Field</label>
                    <md-field>
                        <md-select placeholder="Select default start date">   
                        </md-select>   
                    </md-field>
                </div>
                <div class="ebx-inline-label-field">
                    <label class="ebx-primary">Text Field</label>
                    <md-field>
                        <md-input placeholder="Select default start date" />
                    </md-field>
                </div>
                <div class="ebx-inline-label-field disabled">
                    <label class="ebx-primary">Text Field Disabled</label>
                    <md-field>
                        <md-input :disabled="true" placeholder="Select default start date" />
                    </md-field>
                </div>
            </div>
        </div>

        <!-- EbxConcertina -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Concertina</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_concertina)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="ebx-concertina">
                    <div class="ebx-concertina--container">
                        <transition name="concertina">
                            <div class="ebx-concertina--content" v-if="showConcertina">
                                Content to show or hide
                            </div>
                        </transition>
                        <div class="ebx-concertina--header">
                            <div class="ebx-concertina--header--title">
                                <div class="ebx-primary-bold" v-if="!showConcertina">Show columns</div>
                                <div class="ebx-primary-bold" v-else>Hide columns</div>
                            </div>
                            <div class="ebx-concertina--header--icon">
                                <button 
                                v-if="!showConcertina"
                                class="ebx-icon-button ebx-icon-button__no-shadow"
                                @click="showConcertina = true"><span class="material-icons-outlined ebx-icon">keyboard_arrow_down</span></button>
                                <button 
                                v-else
                                class="ebx-icon-button ebx-icon-button__no-shadow"
                                @click="showConcertina = false"><span class="material-icons-outlined ebx-icon">keyboard_arrow_up</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { styleguideConstants } from '@/constants/styleguideConstants.js'
import assetsMixin from "@/components/mixins/assetsMixin.js" 

import StyleguideTypography from '@/views/StyleGuide/StyleguideTypography.vue'
import StyleguideCards from '@/views/StyleGuide/StyleguideCards.vue'

import EbxInlineInput from '@/components/EbxComponents/EbxInlineInput.vue'
import EbxSearchBar from '@/components/EbxComponents/EbxSearchBar.vue'
import EbxDialog from '@/components/EbxComponents/EbxDialog.vue'
import EbxErrorDialog from '@/components/EbxComponents/EbxErrorDialog.vue'
import EbxButton from '@/components/EbxComponents/EbxButton.vue'
import EbxTabs from '@/components/EbxComponents/EbxTabs.vue'
import EbxTab from '@/components/EbxComponents/EbxTab.vue'
import EbxTimeline from '@/components/EbxComponents/EbxTimeline.vue'
import EbxSwitch from '@/components/EbxComponents/EbxSwitch.vue'

import ConfirmationModal from "@/components/ConfirmationModal.vue";

export default {
    name: 'StyleGuide',
    mixins: [assetsMixin],  
    components: {
        StyleguideTypography,
        StyleguideCards,
        EbxInlineInput,
        EbxSearchBar,
        EbxDialog,
        EbxErrorDialog,
        ConfirmationModal,
        EbxButton,
        EbxTabs,
        EbxTab,
        EbxTimeline,
        EbxSwitch
    },
    data() {
        return {
            styleguideConstants: styleguideConstants,
            titleExample: 'Title Input example',
            descriptionExample: 'Description Input example, is disabled',
            testValidation: {
                error: 'Example error message',
                active: true
            },
            searchBar: {
                searchInput: '',
                filterBy: 'all'
            },
            searchBarOptions: [
                {
                    value: 'all',
                    label: 'All'
                }
            ],
            filesToUpload: [],
            uploadError: true,
            uploadErrorMessage: 'File upload error message',
            acceptedTypes: ".png",
            showConfirm: false,
            showDeleteConfirm: false,
            showEbxDialog: false,
            showErrorDialog: false,
            showHeadingLarge: false,
            timelineOptions: [
                {
                    name: 'Data 1',
                },
                {
                    name: 'Data 2',
                },
                {
                    name: 'Data 3',
                },
                {
                    name: 'Data 4',
                },
                {
                    name: 'Data 5',
                },
                {
                    name: 'Data 6',
                },
                {
                    name: 'Data 7',
                },
                {
                    name: 'Data 8',
                },
                {
                    name: 'Data 9',
                },
            ],
            showConcertina: false
        }
    },
    mounted() {
        console.log('StyleGuide mounted', this.$refs)
    },
    methods: {
        dismissIndicator() {
            console.log('dismissIndicator')
        },
        handleComponentCopy(value) {
            navigator.clipboard.writeText(value);
        },
        removeFile(index) {
            this.filesToUpload.splice(index, 1);
        },
        triggerFileSelection() {
            this.$refs.fileInput.click();
        },
        dropHandler(event) {
            console.log("File(s) dropped");
            // Prevent default behavior (Prevent file from being opened)
            event.preventDefault();
            if (event.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                [...event.dataTransfer.items].forEach((item, i) => {
                    // If dropped items aren't files, reject them
                    if (item.kind === "file") {
                        const file = item.getAsFile();
                        console.log(`… file[${i}].name = ${file.name}`);
                    }
                });
            }
            else {
                // Use DataTransfer interface to access the file(s)
                [...event.dataTransfer.files].forEach((file, i) => {
                    this.addFile(event, [file]);
                    console.log(`… file[${i}].name = ${file.name}`);
                });
            }
        },
        dragOverHandler(event) {
            // Prevent default behavior (Prevent file from being opened)
            event.preventDefault();
        },
        addFile(event, files) {
            if (!files) {
                files = event.target.files;
            }
            this.filesToUpload = [files[0]];
            console.log('filesToUpload', this.filesToUpload)
        },
        confirmModal() {
            this.showConfirm = !this.showConfirm;
        },
        confirmDelete() {
            this.showDeleteConfirm = !this.showDeleteConfirm;
        },
        openEbxDialog() {
            this.showEbxDialog = !this.showEbxDialog;
        },
        openEbxError() {
            this.showErrorDialog = !this.showErrorDialog;
        },
        handleErrorDialogActive(value) {
            this.showErrorDialog = value;
        }
    }
}
</script>