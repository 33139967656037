/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock';
import {NO_DATA_DROPDOWN_VALUE,VALID_PROPNAME_REGEX} from '@/constants/nextGenConstants';
import { ContentService } from '@/services/content.service';

const FIELD = Object.freeze({
    CALCULATE: 'calculate_options',
})

const calculate_options = [
    ['Coverage (ha)', 'coverage'],
    ['Perimeter (km)', 'perimeter'],
    ["Percentage of Area of Interest", 'coverage_percentage'],
]

const DEFAULT_USER_ATTRIBUTE_NAME = "My Attribute";

var vectorAttributeJson = {
    "type": "analysis_vector_attribute",
    "message0": `%1 %2 %3 %4 %5 %6 %7`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "vector_attribute_title",
            "text": "%{BKY_ANALYSIS_VECTOR_ATTRIBUTE_BLOCK_TITLE}", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "vector_attribute_label",
            "text": "%{BKY_ANALYSIS_VECTOR_ATTRIBUTE_LABEL}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.CALCULATE,
            "options": [
                ["Select an option", NO_DATA_DROPDOWN_VALUE]
            ]
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "vector_attribute_name_label",
            "text": "%{BKY_ANALYSIS_VECTOR_ATTRIBUTE_NAME_LABEL}"
        },
        {
            "type": "field_input",
            "name": "vector_attribute_name_input",
            "text": DEFAULT_USER_ATTRIBUTE_NAME,
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "calculate",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['analysis_vector_attribute'] = {

    ...AbstractBlock,
    ...AbstractFieldHelpers,

    onInit: function() {
        this.jsonInit(vectorAttributeJson);
        this.getField(FIELD.CALCULATE).setValidator(this.attributeValidator);
        this.about_block_url = ContentService.getAboutBlockUrl('analysis_vector_attribute')
    },
    accept: async function (visitor) {
        await visitor.visitAnalysisVectorAttributeBlock(this);
    },
    attributeValidator: function(newValue){
        let block = this.getSourceBlock();
        const calculate_options_dict = Object.fromEntries(calculate_options)
        let key = Object.keys(calculate_options_dict).find((key) => calculate_options_dict[key] === newValue);
        if (typeof key === 'string') {
            key = key.toLowerCase().replace(/[^A-Za-z0-9 ]/g, '');
        }
        block.setAttributeName(key)
        return newValue;
    },
    updateShape_: function() {
        let type = this.getState('type');
        if (type === "vector") {
            const calculateField = this.getField(FIELD.CALCULATE);
            calculateField.updateOptions(calculate_options);
        }
    },
    setAttributeName(layerName, forceChange = false) {
        if(this.hasState('autoChangeLayerName') && forceChange === false) {
            return
        }
        if(this.hasState('userChangedLayerName') === false || forceChange === true) {
            this.setNonPersistentState('autoChangeLayerName', true)
            this.setFieldValue(layerName, 'vector_attribute_name_input')
            this.removeState('autoChangeLayerName')
        }
    },
    ebxValidate: function(tooltips) {
        this.setWarningText(null, 'invalid_attribute_name')
        if(VALID_PROPNAME_REGEX.test(""+this.getFieldValue('vector_attribute_name_input')) === false) {
            this.setWarningText(tooltips['invalid_attribute_name'] || "Attribute Name can only contain letters, numbers, underscores, dashes or a space", 'invalid_attribute_name')
        }
    }
}
