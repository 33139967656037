import * as Blockly from 'blockly/core';
import { AbstractBlock, AbstractFieldHelpers } from './_abstractBlock';
import ExportImage from '@/modals/ExportImage.vue'
import { ContentService } from '@/services/content.service';
import { AuthService } from '@/services/auth.service';
import {FieldNoTrimDropdown} from '@/fields/FieldNoTrimDropdown';
import {FieldModal} from '@/fields/FieldModal';
import { VALID_EXPORT_DESCRIPTON_REGEX, INVALID_EXPORT_DESCRIPTON_MESSAGE }  from '../constants/nextGenConstants';
import assets from '@/assets.js';

const FIELD = {
    NAME: 'name',
    SETTINGS: 'settings',
    TITLE: 'output_export_title',
    FILETYPE: 'filetype',
}

const INPUT = {
    VECTOR_OPTIONS: 'vector_options',
    EXPORT_NAME: 'export_name',
}

const DEFAULT_SETTINGS = {
    resolution: 1000,
    date: null,
    cadence: null,
}

var OutputImageJson = {
    "type": "output_export",
    "message0":`%1 %2 %3 %4 %5 %6`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": FIELD.TITLE,
            "text": "%{BKY_OUTPUT_IMAGE_TITLE}", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "table_label",
            "text": "%{BKY_OUTPUT_IMAGE_NAME_LABEL}",
        },
        {
            "type": "field_input",
            "name": FIELD.NAME,
            "text": "Dataset 1",
        },
        {
            "type": "field_modal",
            "name": FIELD.SETTINGS,
            "modal_component": ExportImage,
            "modal_data": DEFAULT_SETTINGS,
            "src": assets.blockly.settingsWhite24dp,
            "width": 24,
            "height": 24,
        },
        {
            "type": "input_dummy",
            "name": INPUT.EXPORT_NAME
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "export",
    "tooltip": "",
    "helpUrl": ""
};

Blockly.Blocks['output_export'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,

    onInit: function() {
        this.jsonInit(OutputImageJson)

        this.about_block_url = ContentService.getAboutBlockUrl('output_image')

        this.authServiceSubscription = AuthService.loggedUser$.subscribe(async (user) => {
            this.user = user
            await this.checkUserPermitted({})
        })

    },

    accept: async function (visitor) {
        await visitor.visitOutputImage(this);
    },
    ebxValidate: async function(errors) {
        if(errors === undefined) {
            errors = {}
        }
        await this.checkUserPermitted(errors)
        this.checkSettingAreValid()
        if(this.fieldExists(FIELD.SETTINGS)) {
            const settings = this.getFieldValue(FIELD.SETTINGS)
            if(!settings) {
                this.setWarningText(errors['no_settings'] || 'Please configure the settings for your geoTIFF export', 'no_settings')
                return
            }
            if(settings.resolution === null || settings.resolution === undefined || settings.resolution === '') { 
                this.setWarningText(errors['no_settings_resolution'] || 'Please choose a resolution', 'no_settings_resolution')
                return
            }
            if(settings.date === null || settings.date === undefined || settings.date === '') { 
                this.setWarningText(errors['no_settings_date'] || 'Choose a date', 'no_settings_date')
                return
            }
        }
        if(this.getFieldValue(FIELD.NAME) === '') { 
            this.setWarningText(errors['no_name'] || 'Please choose a export name', 'no_name')
            return
        }
        const test = VALID_EXPORT_DESCRIPTON_REGEX.test(this.getFieldValue(FIELD.NAME));
        if(!test) {
            return this.setWarningText(errors['invalid_name'] || INVALID_EXPORT_DESCRIPTON_MESSAGE, 'invalid_name')
        }

        this.setWarningText(null, 'no_settings')
        this.setWarningText(null, 'no_settings_resolution')
        this.setWarningText(null, 'no_settings_date')
        this.setWarningText(null, 'no_name')
        this.setWarningText(null, 'invalid_name')
    },
    updateShape_: function() {
        if(this.hasStateChanged('isVector')) {
            this.getState('isVector') ? this._updateVector() : this._updateRaster()
        }
        if(this.hasStateChanged('datasets') && this.fieldExists(FIELD.SETTINGS)) {
            const currentDatasets = this.getState('datasets')
            if(currentDatasets.length > 0) {
                this.getField(FIELD.SETTINGS)
                    .setModalData('dateOptions', this.getDatasetDateOptions())
                    .setModalData('datasetCadence', currentDatasets[0].single_image_temporal?.cadence)
                
                const settings = this.getFieldValue(FIELD.SETTINGS)
                if(settings && settings.cadence !== currentDatasets[0].single_image_temporal?.cadence) {
                    settings.cadence = currentDatasets[0].single_image_temporal?.cadence
                    settings.date = null
                    this.setFieldValue(settings, FIELD.SETTINGS)
                }
            }
        }
        
        this.checkSettingAreValid()
    },
    _updateVector: function() {
        this.setFieldValue(Blockly.Msg['OUTPUT_IMAGE_TITLE'], FIELD.TITLE)
        if(!this.inputExists(INPUT.VECTOR_OPTIONS)) {
            const input = this.appendDummyInput(INPUT.VECTOR_OPTIONS)
            input.appendField(Blockly.Msg['OUTPUT_IMAGE_VECTOR_OPTIONS'])
            input.appendField(new FieldNoTrimDropdown([
                [Blockly.Msg['OUTPUT_IMAGE_VECTOR_FILETYPE_GEOJSON'], 'GeoJSON'],
                [Blockly.Msg['OUTPUT_IMAGE_VECTOR_FILETYPE_SHP'], 'SHP'],
                [Blockly.Msg['OUTPUT_IMAGE_VECTOR_FILETYPE_KML'], 'KML'],
                [Blockly.Msg['OUTPUT_IMAGE_VECTOR_FILETYPE_KMZ'], 'KMZ'],
                [Blockly.Msg['OUTPUT_IMAGE_VECTOR_FILETYPE_TF'], 'TFRecord'],
                [Blockly.Msg['OUTPUT_IMAGE_VECTOR_FILETYPE_CSV'], 'CSV'],
                
            ]), FIELD.FILETYPE)
        }
        if(this.fieldExists(FIELD.SETTINGS) && this.inputExists(INPUT.EXPORT_NAME)) {
            this.getInput(INPUT.EXPORT_NAME).removeField(FIELD.SETTINGS)
        }
    },
    _updateRaster: function() {
        this.setFieldValue( Blockly.Msg['OUTPUT_IMAGE_TITLE_RASTER'], FIELD.TITLE)
        if(this.inputExists(INPUT.VECTOR_OPTIONS)) {
            this.removeInput(INPUT.VECTOR_OPTIONS)
        }
        if(this.fieldExists(FIELD.SETTINGS) === false && this.inputExists(INPUT.EXPORT_NAME)) {
            const field = new FieldModal(ExportImage, DEFAULT_SETTINGS, assets.blockly.settingsWhite24dp, 30, 30)
            this.getInput(INPUT.EXPORT_NAME).appendField(field,FIELD.SETTINGS)
        }
    },
    getDatasetDateOptions: function() {
        const currentDatasets = this.getState('datasets')
        if(Array.isArray(currentDatasets) && currentDatasets.length > 0 && currentDatasets[0].single_image_temporal !== null && currentDatasets[0].single_image_temporal.temporal !== null) {
            return currentDatasets[0].single_image_temporal.temporal.map(d => [d.name, d.value])
        }
        return []
    },
    checkSettingAreValid: function() {
        if (this.fieldExists(FIELD.SETTINGS) === false) {
            return false
        }
        const settings = this.getFieldValue(FIELD.SETTINGS)
        if(settings && settings.date) {
            const validOptions = this.getDatasetDateOptions().map(d => d[1])
            if(validOptions.indexOf(settings.date) < 0){
                settings.date = null
                this.setFieldValue(settings, FIELD.SETTINGS)
            }
        }
        return true
    },
    checkUserPermitted: async function(errors){
        if (!AuthService.isUserLoggedIn()){
            return false
        }
        if (this.user.roles.includes("superadmin")){
            return true;
        }
        let quotas = await AuthService.getExportQuotas()
        this.setWarningText(null, 'no_geotiff_quota')
        this.setWarningText(null, 'no_vector_quota')
        if(quotas) {
            if(this.fieldExists(FIELD.SETTINGS) ) {
                if (quotas.geoTIFFDownloadQuotaRemaining <= 0){
                    this.setWarningText(errors['no_geotiff_quota'] || 'You have insufficient quota to download GeoTIFFs. Contact support for more information.', 'no_geotiff_quota')
                    return
                }
            } else {
                if (quotas.vectorDownloadQuotaRemaining <= 0){
                    this.setWarningText(errors['no_vector_quota'] || 'You have insufficient quota to download dataset. Contact support for more information.', 'no_vector_quota')
                    return
                }
            }
        }
        
    },
    getSettings: function() {
        return this.getFieldValue(FIELD.SETTINGS)
    },
    getName: function() {
        return this.getFieldValue(FIELD.NAME)
    },
    onDispose: function() {
        // fixes memory leak
        this.authServiceSubscription.unsubscribe()
    }
}; 
