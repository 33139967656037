import { AuthService } from '../services/auth.service';

export const bindBeforeEach = (router, store) => {
    router.beforeEach(async (to, from, next) => {
        //console.warn(`defaultRouter.beforeEach FROM ${from.path} TO ${to.path}`)
        if (to.matched.some(record => record.meta.requiresAuth) || to.matched.some(record => record.meta.requiresAdminAuth)) {
            console.log(`route requires auth`)

            const checkRoles = (validRoles) => {
                if(Array.isArray(validRoles)) {
                    if(validRoles.includes('customer_support') && store.getters['auth/isCustomerSupport']) {
                        return true
                    }
                    if(validRoles.includes('group:admin:*') && store.getters['auth/isOrgGroupAdmin']) {
                        return true
                    }
                    if(validRoles.includes('admin') && store.getters['auth/isAdmin']) {
                        return true
                    }
                    if(validRoles.includes('superadmin') && store.getters['auth/isSuperAdmin']) {
                        return true
                    }
                    if(validRoles.includes('creator') && store.getters['auth/isCreator']) {
                        return true
                    }
                    if(validRoles.includes('explorer') && store.getters['auth/isExplorer']) {
                        return true
                    }
                    return false
                }
                return true
            }
            
            const requiresAdminAuth = to.matched.some(record => Array.isArray(record.meta.requiresRoles))

            // this route requires auth, check if logged in
            // if not, redirect to login page.
            if (AuthService.isUserLoggedIn()) {
                // wait for any preloading of user data
                await AuthService.waitForValidUser$()
                if(requiresAdminAuth) {
                    if(checkRoles(to.meta.requiresRoles)) {
                        console.log(`user has required roles`)
                        return next();
                    }
                    return next({ path: '/' });
                } else {
                    console.info("user logged in already ");
                    const isExplorer = store.getters['auth/isExplorer']
                    // redirect Explorer users if needed
                    if (isExplorer && to.meta && to.meta.explorerRedirect && to.meta.explorerRedirectPath) {
                        return next({ path: to.meta.explorerRedirectPath });
                    }
                    return next();
                }
            } else {

                if (to.query.firebaseToken) {
                    AuthService.signInWithCustomToken(to.query.firebaseToken)
                        .then((userCredential) => {
                            // Signed in
                            console.log("signed in with custom token user " + userCredential.user.uid);
                            next();
                        })
                        .catch((error) => {
                            var errorCode = error.code;
                            var errorMessage = error.message;
                            console.log("Error signing in with custom token:" + errorMessage + "code:" + errorCode);
                            next({
                                path: '/',
                            });
                        });
                }

                if (to.query.ltiToken) {
                    let workflowid = to.query.workflowid ? to.query.workflowid : '0';

                    AuthService.signInWithCustomToken(to.query.ltiToken)
                        .then((userCredential) => {
                            // Signed in
                            console.log("signed in with custom token user " + userCredential.user.uid);
                            next({
                                path: '/workflow/' + workflowid

                            });
                        })
                        .catch((error) => {
                            var errorCode = error.code;
                            var errorMessage = error.message;
                            console.log("Error signing in with custom token:" + errorMessage + "code:" + errorCode);
                            next({
                                path: '/',
                            });
                        });
                }
                next({
                    path: '/',
                });
            }
        }
        else {
            console.log(`route does not require auth`)

            // Login is not required; but if this is the home page or signin page and the user is logged in, redirect them to the workspace!
            if (AuthService.isUserLoggedIn() && ['SignIn','Home'].indexOf(to.name) >= 0) {
                next({
                    path: "/projects/",
                    replace: true,
                });
            } else {
                next(); // make sure to always call next()!
            }
            
        }

        if (to.matched.some(record => record.meta.title)) {
            const pageWithTitle = to.meta.title;
            if (pageWithTitle) {
                document.title = pageWithTitle;
            } else {
                document.title = 'Earth Blox';
            }

            next();

        } else {
            document.title = 'Earth Blox | Page Not Found';
            next(); // make sure to always call next()!
        }

    });
}