/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

 
import cmocean from '@/palettes/cmocean.json';
import colorbrewer from '@/palettes/colorbrewer.json';
import crameri from '@/palettes/crameri.json';
import ebx from '@/palettes/ebx.json';
import kovesi from '@/palettes/kovesi.json';
import matplotlib from '@/palettes/matplotlib.json';
import misc from '@/palettes/misc.json';
import niccoli from '@/palettes/niccoli.json';
import{ VIS_PALETTE_DROPDOWN } from '@/constants/nextGenConstants'
import chroma from 'chroma-js'

// palette assets
import assets from '@/assets.js';

let service = {
    paletteOptions: VIS_PALETTE_DROPDOWN,
    palettes: {
        cmocean,
        colorbrewer,
        crameri,
        ebx,
        kovesi,
        matplotlib,
        misc,
        niccoli
    }
};

const standardiseColourValues = rgb => rgb.substring(0, 1) === '#' ? rgb : '#' + rgb;

const PaletteService = {
    /**
     * Returns back an array of palettes for selection in a dropdown
     * @returns Array[Array[String, String]]
     */
    getDropdownOptions() {
        return service.paletteOptions
    },
    /**
     * Returns back an array of palettes for selection in a dropdown
     * @returns Array[String]
     */
    getExampleImages() {
        return assets.palette
    },
    /**
     * 
     * Returns back an array of hex values using an existing colour ramp to determin the colors based of the number required
     * @param {*} paletteName 
     * @param {*} numberofColoursRequired 
     * @returns Array[String]
     */
    getColourRampFromPaletteForNumberOfItems(paletteName, numberofColoursRequired) {
        const paletteParts = paletteName.split('.')
        if(paletteParts.length < 2) {
            return null
        }
        if(service.palettes[paletteParts[0]] && service.palettes[paletteParts[0]][paletteParts[1]]) {
            const selectedPalette = service.palettes[paletteParts[0]][paletteParts[1]]
            const selectedPaletteKeys = Object.keys(selectedPalette)
            if(selectedPaletteKeys.indexOf(`${numberofColoursRequired}`) >= 0) {
                return selectedPalette[`${numberofColoursRequired}`].map(standardiseColourValues)
            }
            let colors = null;
            if(typeof selectedPalette[0] === 'string') {
                colors = chroma.scale(selectedPalette.map(standardiseColourValues))
            } else {
                colors = chroma.scale(selectedPalette[selectedPaletteKeys[selectedPaletteKeys.length - 1]].map(standardiseColourValues))
            }
            return colors.colors(numberofColoursRequired)
        }
        return null;
    }
};

export {
    PaletteService
}
