import {AbstractAbility} from "./abstractAbility"
class ProvidesImageExport extends AbstractAbility{
    constructor(state) {
        super(state)
        this.stateKey = 'image_export'
    }
    getSettings() {
        return this.getState().settings
    }
    getName() {
        return this.getState().name
    }
    toRunDoc() {
        return {
            type: 'image',
            id: this.associatedBlockId,
            name: this.getName(),
            settings: this.getSettings()
        }
    }
}

export {ProvidesImageExport}