/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

const EBX_STAC_PREFIX = "ebx:";

function ebxstac(name) {
    return `${EBX_STAC_PREFIX}${name}`
}

export const EBX_MODIFIERS = ebxstac("default_modifiers");
export const EBX_HIDE_FROM_LIST = ebxstac("hide_from_list");
export const EBX_BLOCK_VALIDATION = ebxstac("block_validation");
export const EBX_CLOUDMASK = ebxstac("cloudmask");
export const EBX_IMPORT = ebxstac("import");
export const EBX_IMPORT_DETAILS = ebxstac("import_details");
export const EBX_IMPORT_CHECKS = ebxstac("import_checks");
export const EBX_PASS_SELECTION = ebxstac("pass_selection");
export const EBX_VISUALIZE_V1 = ebxstac("visualize");
export const EBX_VISUALIZE = ebxstac("visualize_v2");
export const EBX_VISUALIZE_DEFAULT = ebxstac("visualize_default");
export const EBX_TIME_ELAPSED_EQUATION = ebxstac("time_elapsed_equation");
export const EBX_VALID_INDICES = ebxstac("valid_indices");
export const EBX_DATASET_TITLE = ebxstac("title");
export const EBX_CORRELATION = "correlation_id";
export const START_YEAR = "startyear";
export const END_YEAR = "endyear";
export const START_DATE = "start_date";
export const END_DATE = "end_date";
export const EBX_START_DATE = ebxstac('start_date');
export const EBX_END_DATE = ebxstac('end_date');
export const DEFAULT_START_DATE = ebxstac('default_start_date');
export const DEFAULT_END_DATE = ebxstac('default_end_date');
export const MOMENT_DATE_FORMATS = ['YYYY-MM-DD', 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'YYYY-MM-DD HH:mm','YYYY-MM-DD HH:mm:ss'];
export const BANDS = "bands";
export const TITLE = "title";
export const KEYWORDS = 'keywords'; 
export const EBX_SHORT_DESCRIPTION = ebxstac('short_description');
export const GEE_SCHEMA = 'gee_schema';
export const THUMBNAIL_URL ='thumbnail_url'; 
export const IS_PAID ="isPaid";
export const IS_COMMERCIAL_USE = "ebx:license_allows_commercial_use";
export const BAND_NAME = ebxstac('name');
export const MIN = "min";
export const MAX = "max";
export const GEE_SCHEMA_CLASS_NAME = 'gee:classes';
export const VIS_PARAM = 'visparam';
export const DISPLAY_NAME = 'display_name';
export const VIS_BANDS = "bands";
export const VIS_BANDS_VALUE = "value";
export const VIS_BANDS_PALETTE = "palette";
export const VIS_BANDS_INVERT = "invert";
export const VIS_BANDS_R = "red";
export const VIS_BANDS_G = "green";
export const VIS_BANDS_B = "blue";
export const VIS_CONTRAST_TYPE = "contrast_type";
export const VIS_CONTRAST_FIXED = "fixed";
export const VIS_CONTRAST_FIXED_MIN = "min";
export const VIS_CONTRAST_FIXED_MAX = "max";
export const VIS_CONTRAST_STAT = "statistical";
export const VIS_CONTRAST_VALID_STATISTICAL = ["dataset-min-max", "1stdev", "2stdev", "p90", "p98", "histoeq"];
export const DUMMY_DROPDOWN_VALUE = "-dummy-";
export const EBX_CADENCE = ebxstac("cadence");
export const DATE_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
export const GSD = "gsd";
export const TYPES = ["ebx:type", "gee_type",'gee:type'];
export const VECTOR_TYPES = ['table','feature','featurecollection','feature_collection'];
export const CADENCES = {
    SECOND: "second",
    MINUTE: "minute",
    DAY: "day",
    HOUR: "hour",
    MONTH: "month",
    YEAR: "year",
    CUSTOM: "custom_time_unit",
};
export const EBX_DOMAINS = [
    ebxstac("domain1"),
    ebxstac("domain2"),
];
export const GEE_INTERVAL = "gee_interval";
export const DEFAULT_COMPOSITE_METHOD = ebxstac("defaultCompMethod");
export const DEFAULT_COMPOSITE_PERIOD = ebxstac("defaultCompPeriod");
export const DEFAULT_COMPOSITE_INTERVAL = ebxstac("defaultCompInterval");