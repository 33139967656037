<script>
export default {
    name: 'MdTableRowGhost',
    props: {
      mdIndex: [String, Number],
      mdId: [String, Number],
      mdItem: [Array, Object]
    },
    render () {
      const tableRow = this.$slots.default()[0]
      tableRow.props.mdIndex = this.mdIndex
      tableRow.props.mdId = this.mdId
      tableRow.props.mdItem = this.mdItem
      return tableRow
    }
  }
</script>
