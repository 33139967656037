/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

// helper functions

import { AreaService } from '@/services/area.service';
import * as Blockly from 'blockly/core';
import { getCurrentSatUsingRootBlock } from '@/blocks/dataset';
import {FieldNoTrimDropdown} from '@/fields/FieldNoTrimDropdown';
import {CustomFieldDate} from '@/fields/blocklyFieldDate';
import {NO_DATA_DROPDOWN_VALUE, TABLE_DATE_CONSTANTS,VARIABLE_BLOCKLY_WARNING_PREFIX } from '../constants/nextGenConstants';
import {VIS_CONTRAST_FIXED,VIS_CONTRAST_STAT } from '../constants/ebxStacConstants';
import {VariablesService} from '@/services/variable.service';
import { keyBy, isEqual } from 'lodash';

/**
 * Function to remove element from array
 */
function removeElementFromArray(array, element) {
    const index = array.indexOf(element);
    if (index !== -1) {
        array.splice(index, 1);
    }
    return array
}

/**
 * 
 * @param {*} indices 
 * @returns 
 */
function processValidIndices(indices) {
    let indicesObj = {
        categories_dropdown: [["Select an option", NO_DATA_DROPDOWN_VALUE]]
    }
    if (indices && Object.keys(indices).length > 0) {
        indicesObj.categories_dropdown = createBlocklyDropdownFromStringArray(Object.keys(indices))
        for (const cat in indices) {
			const display_names = indices[cat].map(element => {
				if (typeof element === 'string') {
					return element
				}
				return element["displayName"]
			})
			const acronym = indices[cat].map(element => {
				if (typeof element === 'string') {
					return element
				}
				return element["index"]
			}) 
			let dropdown = []
			display_names.forEach((option,idx) => {
				if (option ) {
					const index  = acronym[idx]
					dropdown.push([option,index])
				}
			}) 
            indicesObj[`${cat}`] = dropdown  
        }
    }
    
    return indicesObj
}

/**
 * Goes through definitions array and pick up just the names
 * [{name:"defname1", band: "band1", band_type:"class"}, {name:"defname2", band: "band1", band_type:"integer"}]
 * @param {Object} definitions 
 * @returns ["defname1", "defname2"]
 */
function getNamesArray(definitions, name="name") {
    let namesArray = []
    definitions.forEach((def) => {
		if (def[name] !== undefined) {
			namesArray.push(def[name])
		}
    })
    
    return namesArray
}
/**
 * 
 * @param {String} prefix 
 * @param {Object} namesArray 
 * @returns 
 */
function generateUniqueName(prefix='name-', namesArray) {
    let count = 1;
    while (namesArray.includes(`${prefix}${count}`) === true) {
        count++
    }
    return `${prefix}${count}`
}

/**
 * Make a blockly dropdown options array from an array of strings. eg: ["option 1", "new string", "option3"]
 * @param {*} stringArray 
 * @returns [["option 1", "option_1"], ["new string", "new_string"], ["option3", "option3"]]
 */
function createBlocklyDropdownFromStringArray(stringArray) {
    let dropdown = []
    if (stringArray && typeof stringArray === "object") {
        stringArray.forEach((option) => {
            if (option) {
                dropdown.push([option, option.replace(" ", "_")])
            }
        })
    }
    return dropdown
}
/**
 * Make a blockly dropdown options array from an array of strings. eg: ["option 1", "new string", "option3"] and their corresponding values eg: ["Trees", "10"]
 * @param {*} stringArray 
 * @param {*} values
 * @returns [["option 1", "value"], ["new string", "value"], ["option3", "value"]]
 */

function createThematicArrayDropdown(stringArray,values) {
    let dropdown = []	
	if (stringArray && typeof stringArray === "object") {
		stringArray.forEach((option, index) => {
			if (option) {
				const value = values[index]
				dropdown.push([option,value])
			}
		})
	}
    return dropdown
}

/**
 * Adds a new field number label inside input to block
 * @param {Object} block 
 * @param {String} fieldText 
 * @param {String} fieldLabel 
 * @param {Integer} value 
 * @param {Integer} min 
 * @param {Integer} max 
 * @param {Integer} precision 
 * 
 */
function addFieldNumberInput(block, inputName,fieldLabel,fieldName, value, min, max, precision) {
    if(!block.getInput(inputName)) {
        block.appendDummyInput(inputName)
            .appendField(fieldLabel)
            .appendField(new Blockly.FieldNumber(value, min, max, precision), fieldName );
    }
}

/**
 * Adds a new value input to block
 * Adds a new text field input inside input to block
 * @param {Object} block 
 * @param {String} inputName 
 * @param {String} fieldLabel 
 * @param {String} nextInput 
 */
function addFieldTextInput(block, inputName, fieldLabel, fieldText, fieldName, nextInput=null, validator=null) {
    if(!block.getInput(inputName)) {
        block.appendDummyInput(inputName)
            .appendField(fieldLabel)
            .appendField(new Blockly.FieldTextInput(fieldText, validator), fieldName);
        if (nextInput) {
            block.moveInputBefore(inputName, nextInput)
        }
    }
}

/**
 * Adds a new value input to block
 * Adds a new date field input inside input to block
 * @param {Object} block 
 * @param {String} inputName 
 * @param {String} fieldLabel 
 * @param {String} nextInput 
 */
 function addFieldDateInput(block, inputName, fieldLabel, fieldText, fieldName, nextInput=null, validator=null) {
    if(!block.getInput(inputName)) {
        block.appendDummyInput(inputName)
            .appendField(fieldLabel)
            .appendField(new CustomFieldDate(fieldText, validator), fieldName);
        if (nextInput) {
            block.moveInputBefore(inputName, nextInput)
        }
    }
}

/**
 * Helper function to add a new dropdown input
 * @param {Object} block 
 * @param {String} newInput 
 * @param {String} newInputLabel 
 * @param {String} newInputField 
 * @param {Array} dropdownOptions 
 * @param {String} nextInput 
 * @param {Function} validator 
 */
function addDropdownInput(block, newInput, newInputLabel, newInputField, dropdownOptions, nextInput=null, validator=null, fieldType = Blockly.FieldDropdown) {
    // add input
    if (!block.getInput(newInput)) {
        block.appendDummyInput(newInput)
        .appendField(newInputLabel)
        .appendField(new fieldType(dropdownOptions, validator), newInputField,)
    }
    if (nextInput) {
        console.debug(`Moving dropdown ${newInput.name} before ${nextInput.name}`)
        block.moveInputBefore(newInput, nextInput)
    }
}

function addMultiDropdownInput(block, newInput, newInputLabel, newFieldLabel1,newInputField1, newFieldLabel2, newInputField2,newFieldLabel3,newInputField3, dropdownOptions, nextInput=null, validator=null ) {
    // add input
    if (!block.getInput(newInput)) {
        block.appendDummyInput(newInput)
        .appendField(newInputLabel)
		.appendField(newFieldLabel1)
        .appendField(new Blockly.FieldDropdown(dropdownOptions, validator), newInputField1)
		.appendField(newFieldLabel2)
		.appendField(new Blockly.FieldDropdown(dropdownOptions, validator), newInputField2)
		.appendField(newFieldLabel3)
		.appendField(new Blockly.FieldDropdown(dropdownOptions, validator), newInputField3)
    }
    if (nextInput) {
        console.debug(`Moving dropdown ${newInput.name} before ${nextInput.name}`)
        block.moveInputBefore(newInput, nextInput)
    }
}

/**
 * Helper function to remove an input from the block
 * @param {*} block 
 * @param {*} inputName 
 */
function removeInput(block, inputName) {
    if (block.getInput(inputName)) {
        block.removeInput(inputName)
    }
}

/**
 * Removes field from given input
 * @param {*} input 
 * @param {*} field 
 */
function removeFieldFromInput(input, field) {
    let block = input.getSourceBlock()
    if(block.getField(field)) {
        input.removeField(field)
    }
}

/**
 * Function that checks if previous dropdown value is available in the dropdown options
 * Then sets the dropdown selection to it
 * If not, sets the dropdown selection to the first option
 * @param {Object} block
 * @param {String} fieldName
 * @param {String} oldValue
 */
function setDropdownPreviousValue(block, fieldName, oldValue) {
    let oldValueExists = false;
    // safety check: if the field exists and if the current value is already the one we want
    if (block.getField(fieldName) && block.getFieldValue(fieldName)!== oldValue) {
        let dropdownOptions = block.getField(fieldName).getOptions()
        for (const option of dropdownOptions) {
            if (option[1] === oldValue) {
                block.getField(fieldName).setValue(oldValue)
                oldValueExists = true;
                break
            }
        }
        if (!oldValueExists) {
            block.getField(fieldName).setValue(dropdownOptions[0][1])
        }
    }
}


function dropdownOptionsDiffer(oldOptions, newOptions) {
	oldOptions = oldOptions || []
	newOptions = newOptions || []
	const oldKeys = oldOptions.map(o => o[1])
	const newKeys = newOptions.map(o => o[1])
	const oldIntersect = oldKeys.filter(k => newKeys.indexOf(k) < 0)
	const newIntersect = newKeys.filter(k => oldKeys.indexOf(k) < 0)
	return oldIntersect.length > 0 || newIntersect.length > 0
}

/**
 * Compare and update block dropdown options
 * @param {*} fieldName 
 * @param {*} block 
 */
function compareAndUpdateBlockDropdown(block, newDefOptions, fieldName, preferredValue='', fieldType = Blockly.FieldDropdown) {
    let defField = block.getField(fieldName)
    let fieldValidator = defField.getValidator()
    let prevDefValue = defField.getValue()
    let defOptions = defField.getOptions()

    let hasDropdownChanged = false;
    //check if drodpdown options have changed
    if (defOptions.length !== newDefOptions.length) {
        hasDropdownChanged = true;
    } else {
        isEqual(defOptions, newDefOptions) ? hasDropdownChanged = false : hasDropdownChanged = true;
    }

    // TODO compare ALL block state obj and update ONLY if changes
    if (hasDropdownChanged) {
        //re-render the field with the new state options
        let parentInput = defField.getParentInput();
        parentInput.removeField(fieldName);
        parentInput.appendField(new fieldType(newDefOptions, fieldValidator), fieldName)

        // Set field value to previous if it exists = this triggers the validator
        setDropdownPreviousValue(block, fieldName, prevDefValue)
    }

    if (preferredValue && preferredValue !== defField.getValue()) {
        defField.setValue(preferredValue)
    }
	return hasDropdownChanged
}

function getYearsArrayFromDates(fromDate, toDate) {
    let yearsArray = []
    let fromYear = fromDate.substr(0, 4);
    let toYear = toDate.substr(0, 4);
    yearsArray = [fromYear]
    let i = Number(fromYear)
    while (i < Number(toYear)) {
        yearsArray.push(`${i+1}`)
        i++
    }
    return yearsArray
}

function number_children(block, child_id) {
	if (!(typeof block.getChildren()[0] === 'undefined') && (child_id !== block.getChildren()[0].id)) {
		return 1 + number_children(block.getChildren()[0]);
	} else {
		return 0;
	}
}

function areaChange(block, inputName, fieldName) {

	if(block.getInput(inputName) == null || block.getField(fieldName) == null) {
		return false;
	}

	let prev = block.getFieldValue(fieldName);
	let newAreas = AreaService.getStudyAreas();

	let input = block.getInput(inputName);
	input.removeField(fieldName);
	input.appendField(new FieldNoTrimDropdown(newAreas), fieldName);

	// TODO use getIndexOfValueInDropdownOptions
	let isThere = false;
	newAreas.forEach(area => {
		if(area.includes(prev)) {
			isThere = true;
		}
	});

	if(isThere) {
		block.getField(fieldName).setValue(prev);	
	} else {
		block.getField(fieldName).setValue(newAreas[0]);
	}
	
	return true;
}

function createContent(){
	return "\nglobal content" +
		"\ncontent = {" +
		"\n    'layers': []," +
		"\n    'charts': []," +
        "\n    'prompt': ''" +	
		"\n}";
}

function returnContent(legend, vis, layer) {
	return legend + "\nmapid = ee.Image(image).getMapId(" + vis + ")" +
		"\nmapURL = mapid['tile_fetcher'].url_format" +
		"\nprint('mapURL:', mapURL)" +
		"\ncontent['layers'].append({" +
		"\n    'mapURL': mapURL," +
		"\n    'mapid': mapid['mapid']," +
		"\n    'token': mapid['token']," +
		"\n    'opacity': 1," +
		"\n    'displayLayer': True," +
		"\n    'label': " + layer +
		"\n})" +
		"\ncontent['country'] = country" +
		"\ncontent['type'] = False" +
		"\ncontent['isGroup'] = False" +
		"\nif 'legend' in locals():" +
		"\n    content['legend'] = legend" +
		"\nif 'outputs' in locals():" +
		"\n    content['outputs'] = json.dumps(outputs, indent = 4)" +
		"\ncontent['layerName'] = " + layer +
		"\nbbox = boundaries.geometry().bounds().getInfo()['coordinates']" + 
		"\ncontent['bbox'] = {'SW':bbox[0][0], 'NE':bbox[0][2]}\n";
}


/*
* Helper function for generating Python code for the legend
* @param {string} type:    list/gradient
* @param {array}  palette: array of palette colors
* @param {array}  labels:  array of labels
*/

// legend title?
// no legend case: RGB
function generateLegendCode(type, palette, labels) {
	var code = "\nlegend = {}";
	code += `\nlegend['type'] = '${type}'`;
	code += "\nlegend['values'] = []";
	if (type == 'list') {
		for (let i = 0; i < palette.length; i++) {
			let str = "\nlegend['values'].append({'label': \"" + labels[i] + "\", 'colour': \"" + palette[i] + "\"})";
			code += str;
		}
	} else if (type == 'gradient') {
		for (let i = 0; i < palette.length; i++) {
			let str = "\nlegend['values'].append({'label': \"" + labels[i] + "\", 'colour': \"" + palette[i] + "\"})";
			code += str;
		}
	}

	return code;
}


function generateValueLegendCode(type, palette, labels) {
	var code = "\nlegend = {}";
	code += `\nlegend['type'] = '${type}'`;
	code += "\nlegend['values'] = []";
	if (type == 'list') {
		for (let i = 0; i < palette.length; i++) {
			let str = "\nlegend['values'].append({'label': " + labels[i] + ", 'colour': \"" + palette[i] + "\"})";
			code += str;
		}
	} else if (type == 'gradient') {
		for (let i = 0; i < palette.length; i++) {
			let str = "\nlegend['values'].append({'label': " + labels[i] + ", 'colour': \"" + palette[i] + "\"})";
			code += str;
		}
	}

	return code;
}


/*
* Helper function for stretching visualization parameters 
* based on percentile values
*/
// TO DO: visualization stretch for multiple bands
function stretchVisParams(percentile, palette = null, scale='30') {  //add scales for use in the change detection block
	let code = "\ndef AddLayerPercentStretch (img, percent):" +
		"\n    global boundaries" +
		"\n    global band" +
		"\n    lower_percentile = ee.Number(100).subtract(percent).divide(2)" +
		"\n    upper_percentile = ee.Number(100).subtract(lower_percentile)" +
		"\n    stats = img.select([band], ['value']).reduceRegion(reducer=ee.Reducer.percentile(percentiles=[lower_percentile, upper_percentile]).setOutputs(['lower', 'upper']), geometry=boundaries, scale="+scale+", bestEffort=True)" +
		"\n    vis_params = ee.Dictionary({'min': ee.Number(stats.get('value_lower')), 'max': ee.Number(stats.get('value_upper'))"
	if (palette !== null) {
		code += ", 'palette':"
		code += palette
	}
	code = code + "})" +
		"\n    return vis_params" +
		"\nvisualizParams = AddLayerPercentStretch(image, " + percentile + ").getInfo()";

	return code;
}

function multipleBandVisParamStretch(percentile) {
	var code = "\ndef AddLayerPercentStretch (img, bands, percent):" +
		"\n    global boundaries" +
		"\n    min_str = ''" +
		"\n    max_str = ''" +
		"\n    band_str = ''" +
		"\n    lower_percentile = ee.Number(100).subtract(percent).divide(2)" +
		"\n    upper_percentile = ee.Number(100).subtract(lower_percentile)" +
		"\n    for band in bands:" +
		"\n        stats = img.select([band], ['value']).reduceRegion(reducer=ee.Reducer.percentile(percentiles=[lower_percentile, upper_percentile]).setOutputs(['lower', 'upper']), geometry=boundaries, scale=30, bestEffort=True)" +
		"\n        min_str += str(ee.Number(stats.get('value_lower')).getInfo()) + \", \"" +
		"\n        max_str += str(ee.Number(stats.get('value_upper')).getInfo()) + \", \"" +
		"\n        band_str += band + \",\"" +
		"\n    vis_params = ee.Dictionary({'bands': band_str[:-1], 'min': min_str[:-2], 'max': max_str[:-2]})" +
		"\n    return vis_params" +
		"\nvisualizParams = AddLayerPercentStretch(image, image.bandNames().getInfo(), " + percentile + ").getInfo()";
	return code;
}

/*
* Helper function for generating array of export URLs for each output and polygon
*/
function urlGenerator(scale) {
	var code =
		"\nif polyg_export == True:" +
		"\n    exportArr = []" +
		"\n    file_names = []" +
		"\n    index = 1" +
		"\n    for polyg in polygons_export:" +
		"\n        download_url = image.getDownloadUrl({ 'scale': " + scale + ", 'region': polyg })" +
		"\n        exportArr.append(download_url)" +
		"\n        file_names.append('polygon_' + str(index))" +
		"\n        index += 1" +
		"\n    content['exportUrls'] = exportArr" +
		"\n    content['fileNames'] = file_names" +
		"\nelse:" +
		"\n    content['exportUrls'] = False" +
		"\n    content['fileNames'] = False";
	if (!code){/* */}
	return '';
}

function getCoordinatesForPolygon(polygon) {
	if (polygon.getBounds) {
		let bounds = polygon.getBounds();
		var NE = bounds.getNorthEast();
		var SW = bounds.getSouthWest();

		// checks for the special case that polygon crosses the antimeridian
		if(NE.lng() < SW.lng()) {
			// makes a new SW longitude that's across the antimeridian i.e < -180
			let oldSWLng = SW.lng()
			let newSWLng = -180 - (180 - oldSWLng)
			return [{
				lat: NE.lat(),
				lng: NE.lng()
			},
			{
				lat: SW.lat(),
				lng: NE.lng()
			},
			{
				lat: SW.lat(),
				lng: newSWLng
			},
			{
				lat: NE.lat(),
				lng: newSWLng
			},
			{
				lat: NE.lat(),
				lng: NE.lng()
			}]
		}

		return [{
			lat: NE.lat(),
			lng: NE.lng()
		},
		{
			lat: SW.lat(),
			lng: NE.lng()
		},
		{
			lat: SW.lat(),
			lng: SW.lng()
		},
		{
			lat: NE.lat(),
			lng: SW.lng()
		},
		{
			lat: NE.lat(),
			lng: NE.lng()
		}
		];
	} else if (polygon.getPath) {
		let polygonPath = polygon.getPath();
		let coordinates = [];
		for (let i = 0; i < polygonPath.length; i++) {
			coordinates.push({
				lat: polygonPath.getAt(i).lat(),
				lng: polygonPath.getAt(i).lng()
			});
		}

		if(doesPolygonCrossAntimeridian(coordinates)){
			coordinates.forEach(coord => {
				if(coord.lng > 0){
					coord.lng = -180 - (180 - coord.lng)
				}
			})
		}

		return coordinates;
	}

	return [];
}

function doesPolygonCrossAntimeridian(coordinates) {
	for(let i = 1; i < coordinates.length; i++){
		if(Math.abs(coordinates[i].lng - coordinates[i-1].lng) > 180.0){
			return true;
		}
	}
	return false;
}

// forces dynamicDropdown to update asap rather than waiting for the user to click on it
function refreshDynamicDropdownField(block, fieldName) {
    const field = block.getField(fieldName)
    if (field) {
        field.getOptions(false)
        // work around for https://github.com/google/blockly/issues/3553
        field.doValueUpdate_(field.getValue())
        field.forceRerender()
    }
}

/**
 * Takes the sat property and stores it in the blocks oldSat property
 * Gets the current sat and stores it in the blocks sat property
 * @param {Object} block 
 */
 function updateBlockSat(block) {
	if(block.sat === undefined) {
		block.sat = null;
	}
	block.oldSat = block.sat;

	block.sat = getCurrentSatUsingRootBlock(block);
}

/**
 * Compares the current sat to the old sat and returns true if they are the not the same
 * Use in conjunction with updateBlockSat
 * @example
 * updateBlockSat(block)
 * if(block.hasSatChanged()) {
 * 	// do something
 * }
 * @param {Object} block
 * @returns {Boolean} true when satellite from root block has changed
 */
function hasSatChanged(block) {
	if(block.oldSat !== block.sat) {
		return true;
	}
	return false;
}

/**
 * Helpful for when you've removed and added a dropdown field to change it's options and
 * you want to check if the users old selection is in the new options to remember it
 * Usage:
 * let index = getIndexOfValueInDropdownOptions(oldBandValue, bandOptions);
 * if(index !== -1) {
 *    this.setFieldValue(oldBandValue, fieldName);
 * } else {
 *     this.setFieldValue(defaultValue, fieldName);
 * }
 * @param {String} value - Machine readable value to check for within dropdown options i.e. only second option of the dropdown
 * @param {Array} dropdownOptions - Blockly field dropdown options, array of tuples e.g. [["Red", "r"], ["Green", "g"]]
 * @returns {Number} index of value in dropdown, or -1 if not found
 */
function getIndexOfValueInDropdownOptions(value, dropdownOptions) {
	let dropdownValues = dropdownOptions.map(option => option[1])
	return dropdownValues.indexOf(value);
}

function v2ContrastVisToValue(contrast) {
	// The contrast block can be in one of these two forms:
            // 
            //     {"statistical":"p98"}
            // 
            //     { "fixed": {
            //        "min": [0.0, n],
            //        "max": [0.3, n]
            //     }}
            // 
            // This latter format is not finalised and we ignore it for now.
	if (contrast) {
		if( Object.hasOwn(contrast, VIS_CONTRAST_FIXED)) {
			return'custom'
		} else if( Object.hasOwn(contrast, VIS_CONTRAST_STAT)) {
			return contrast[VIS_CONTRAST_STAT]
		}
	}
	return 'default'
}

/**
 * Attempts to process the dates of the variable and returns a mapping of old dates to new dates
 * If the old date does not exist in the new dates, it is not included in the mapping.
 * This is used in legacy saved workflows to upgrade the dates to the new cadence
 * @param {String} currentCadence - current cadence of the variable
 * @param {String} newCadence - new cadence of the variable
 * @param {Array} oldDates - array of old dates
 */
function standariseVariableDates(currentCadence ,newCadence, oldDates) {
	//Standardised dates from any saved workflows that utalise dates as object and return just the values
	const standarise = function(dates) {
		return dates.map(d => {
			if (typeof(d) === 'object' && d !== null) {
				return d.value;
			}
			return d
		})
	}
	let standarisedVariableDates = standarise(oldDates)

	// this is old functionality which is used to covert dates on very old saved workflows e.g. mid 2023.
	// Probably do not needed anymore but keeping it for now
	if (currentCadence === 'range' && newCadence === 'year') {
		standarisedVariableDates = standarisedVariableDates.map(d => d.substr(0,4))
	}

	return standarisedVariableDates
}


/**
 * Check table and calculator rows for datasets with variables. If a temporal date is chosen, that is not allowed
 * @param {*} globalDatasets 
 * @param {*} rows 
 * @returns 
 */
function validateGlobalVariableUsageOnDataset(globalDatasets, rows, tooltips, block) {
    const validDatasets = keyBy(globalDatasets,'id')
    const rowErrors = rows.filter(row => {
        if(validDatasets[row.dataset] === undefined) {
            return false
        }
        if(Array.isArray(validDatasets[row.dataset].variables) === false || validDatasets[row.dataset].variables.length <=0) {
            return false
        }
        if(row.dates.length === 0) {
            return false
        }
        const dateVariables = validDatasets[row.dataset].variables.filter(v => v.type === 'date')
        if(dateVariables.length === 0) {
            return false
        }
        const invalidDates = row.dates.filter(d => TABLE_DATE_CONSTANTS.map(c => c.value).includes(d) === false)
        if(invalidDates.length <=0) {
            return false
        }

		const variableMessage = tooltips['invalid_dataset_with_variable_variable'] || 'Variable has been used on a dataset within a table block with a date selection.'
        dateVariables.forEach(v => {
            VariablesService.getVariableById(v.id).appendWarning(variableMessage, VARIABLE_BLOCKLY_WARNING_PREFIX + '_' + block.id +'_invalid_dataset_with_variable')
        })
        return true
            
    })

	return rowErrors

}

export { 
	areaChange,
	multipleBandVisParamStretch,
	number_children,
	createContent,
	returnContent,
	generateLegendCode,
	generateValueLegendCode,
	stretchVisParams,
	urlGenerator,
	refreshDynamicDropdownField,
	getCoordinatesForPolygon,
	hasSatChanged,
	getIndexOfValueInDropdownOptions,
	updateBlockSat,
    getYearsArrayFromDates,
    compareAndUpdateBlockDropdown,
    addDropdownInput,
    removeInput,
    addFieldTextInput,
	addFieldNumberInput, 
    createBlocklyDropdownFromStringArray,
	createThematicArrayDropdown,
    generateUniqueName,
    getNamesArray,
    removeElementFromArray,
    setDropdownPreviousValue, 
	addMultiDropdownInput,
    processValidIndices,
	addFieldDateInput,
	dropdownOptionsDiffer,
    removeFieldFromInput,
	v2ContrastVisToValue,
	standariseVariableDates,
	validateGlobalVariableUsageOnDataset
};
