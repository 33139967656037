/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
const toolboxXML = `<xml>
    <toolboxtitle name="Toolbox"></toolboxtitle>

    <toolboxlabel name="Input"></toolboxlabel>
    <category name="Dataset" categorystyle="nextgen_input_category" toolboxitemid="nextgen.input">
        <block type="workflow_insert_dataset"/>
        <block type="input_dataset"/>
    </category>
    <toolboxlabel name="Filter"></toolboxlabel>
    <category name="Area" categorystyle="nextgen_area_dates_category" toolboxitemid="nextgen.area">
        <block type="study_area"/>
    </category>
    <category name="Time" categorystyle="nextgen_area_dates_category" toolboxitemid="nextgen.dates">
        <block type="modifier_time_period"/>
        <block type="modifier_date_range"/>
    </category>
    <category name="Filters" categorystyle="nextgen_filter_category" toolboxitemid="nextgen.filter">
        <block type="modifier_cloud_mask"/>
        <block type="modifier_orbit"/>
        <block type="modifier_attribute"/>
        <block type="modifier_mask"/>
        <block type="modifier_unmask"/>
        <block type="modifier_remove_bands"/>
        <block type="modifier_remove_attributes"/>
    </category>
    <category name="Combine" categorystyle="nextgen_collate_category" toolboxitemid="nextgen.combine">
        <block type="modifier_composite"/>
    </category>
    <toolboxlabel name="Analyse"></toolboxlabel>
    <category name="Calculate" categorystyle="nextgen_calculate_category" toolboxitemid="nextgen.calculate">
        <block type="analysis_indices"/>
        <block type="analysis_vector_attribute"/>
        <block type="analysis_calculator"/>
        <block type="analysis_focal_analysis"/>
        <block type="analysis_regression"/>
        <block type="analysis_zonal_statistics" toolboxitemid="nextgen.calculate.analysis_zonal_statistics"/>
    </category>
    <category name="Classify" categorystyle="nextgen_classify_category" toolboxitemid="nextgen.classify">
        <block type="workflow_classify_image" />
        <block type="workflow_unsupervised_classification" />
        <block type="analysis_thematic_processing" />
        <block type="modifier_reassign_classes"/>
    </category>
    <category name="Vector processing" categorystyle="nextgen_vector_processing_category" toolboxitemid="nextgen.geoprocessing">
        <block type="analysis_geoprocessing" />
        <block type="analysis_buffer"/>
        <block type="analysis_convert_features"/>
        <block type="analysis_convert_images"/>
    </category>
    <toolboxlabel name="Output"></toolboxlabel>
    <category name="Table" categorystyle="nextgen_output_category" toolboxitemid="nextgen.table">   
        <block type="output_add_table"/>   
    </category>
    <category name="Map layer" categorystyle="nextgen_output_category" toolboxitemid="nextgen.maplayer">
        <block type="output_map_layer"/>
        <block type="output_multitemporal"/>
    </category>
    <category name="Dataset" categorystyle="nextgen_output_export_category" toolboxitemid="nextgen.export">
        <block type="output_export"/>
        <block type="output_save_dataset"/>  
    </category>

    <category name="Deprecated blocks" categorystyle="nextgen_deprecated_category" toolboxitemid="nextgen.deprecated" hidden="true">
        <block type="modifier_bands"/>
        <block type="modifier_filter_attributes"/>
    </category>

</xml>`;

export { toolboxXML};
