<template>
    <div>
        <md-dialog v-model:md-active="computedModalShown" class="blockly-modals ebx-dialog table-creator-modal">
            <md-dialog-title>
                <div class="top-actions--container">
                    <div class="title--container">
                        <span>Create Table</span>
                    </div>
                    <div class="close-button">
                        <md-button class="md-primary" @click="computedModalShown = false"><md-icon>close</md-icon></md-button>
                    </div>
                </div>
            </md-dialog-title>
            <md-dialog-content>
                <div class="table-creator--description">
                    <h3 class="ebx-header-4">Description</h3>
                    <md-field :class="invalidClass">
                        <label hidden>Description</label>
                        <md-textarea v-model="formData.tableDescription" placeholder="Add a description" md-autogrow :md-counter="maxDescriptionLength"></md-textarea>
                        <span class="md-error">{{descriptionErrorMessage}}</span>
                        <span class="md-helper-text">Descriptions show alongside the table in the dashboard.</span>
                    </md-field>
                </div>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="computedModalShown = false">Cancel</md-button>
                <md-button class="md-primary md-raised" :disabled="!canSaveTable" @click="saveModal">Save</md-button>
            </md-dialog-actions>
        </md-dialog>
        <md-dialog-alert
            v-model:md-active="showAlert"
            :md-content="alertMessage"
            md-confirm-text="Back" />
    </div>
</template>

<script>

import { globalEventBus } from '@/eventbus.js'
import blocklyModalsMixin from './blocklyModalsMixin.js'

export default {
    name: 'VectorTable',
    mixins: [blocklyModalsMixin],
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        tableDescription: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showAlert: false,
            alertMessage: '',
            isDescriptionValid: true, 
            descriptionErrorMessage: '',
            maxDescriptionLength: 3000,
            invalidDescription: false,
            invalidClass: '',
            formData: {
                tableDescription: this.tableDescription
            }
        }
    },
    methods: {
        getFieldValue() {
            return {
                tableDescription: this.formData.tableDescription,
            }
        },
        saveModal() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        },
        validateDescription(description) {
            if (description.length > this.maxDescriptionLength) {
                this.invalidDescription = true
                this.invalidClass = 'md-invalid'
                this.descriptionErrorMessage = `Description must be less than ${this.maxDescriptionLength} characters'`
                return false
            }
            this.invalidDescription = false
            this.invalidClass = ''
            return true
        },
    },
    watch: {
        showModal: function (val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if(val){
                this.formData = {
                    tableDescription: this.tableDescription
                }
            }
        },
    },
    computed: {
        canSaveTable() {
            return this.validateDescription(this.formData.tableDescription);
        }
    }
}
</script>