<template>
    <div>
        <h2>
            <EbxInlineInput 
                v-model="band.name" 
                :disabled="!canEdit"
                :field-being-edited="fieldBeingEdited"
                :id="'band_' + band.name"
                :input-style-classes="['ebx-header-3']"
                :input-validation="nameValidation"
                :disable-blur="true"
                input-type="text"
                edit-actions-position="right"
                @editing-ebx-field="updateFieldBeingEdited"
                @save="$emit('save-band-name', band, 'name', currentBandName)"
            />
        </h2>
        <md-table class="md-table-ebx">
            <md-table-row>
                <md-table-head class="md-table--bands-value-head">Value</md-table-head>
                <md-table-head class="md-table--bands-colour-head">Colour</md-table-head>
                <md-table-head class="md-table--bands-description-head">Description</md-table-head>
                <md-table-head class="md-table-action">
                    <md-menu v-if="canEdit">
                                <md-button class="md-icon-button" md-menu-trigger>
                                    <md-icon>more_vert</md-icon>
                                </md-button>

                                <md-menu-content class="asset-options-menu">
                                    <md-menu-item class="asset-options-menu--option" @click="handleChangeType(band, 'numeric')">
                                        <span class="material-icons-outlined ebx-icon">mode</span>
                                        <span>Change to numeric band</span>
                                    </md-menu-item>
                                    <md-menu-item class="asset-options-menu--option" @click="handleDeleteBand(band)">
                                        <span class="material-icons-outlined ebx-icon">delete_outline</span>
                                        <span>Delete band</span>
                                    </md-menu-item>
                                </md-menu-content>
                            </md-menu>

                </md-table-head>
            </md-table-row>
            <md-table-row v-for="bandClass in sortedClasses" :key="bandClass.id" :class="rowClass(bandClass)">
                <template v-if="editingClass(bandClass.id)">
                    <md-table-cell>
                        <md-field>
                            <md-input v-model="editingClasses[bandClass.id].value" placeholder="Value" @blur="updateValue(bandClass, $event)" @keyup="validateEditedRows" />
                        </md-field>
                        
                        <div v-for="error in getErrorsForKey('value',editingClasses[bandClass.id].errors)" :key="error" class="ebx-error">{{ error }}</div>
                    </md-table-cell>
                    <md-table-cell class="md-table--bands-colour-cell">
                        <EbxColorPicker
                        v-model="editingClasses[bandClass.id].color" 
                        class="color-box" 
                        :include-hash="false" 
                        @blur="validateEditedRows" />
                        <div>{{ bandClass.color }}</div>
                        <div v-for="error in getErrorsForKey('color',editingClasses[bandClass.id].errors)" :key="error" class="ebx-error">{{ error }}</div>
                    </md-table-cell>
                    <md-table-cell>
                        <md-field>
                            <md-input v-model="editingClasses[bandClass.id].description" placeholder="Description" @click="handleDescriptionFieldClick(bandClass.id)" @blur="validateEditedRows" @keyup="validateEditedRows" />
                        </md-field>
                        <div v-if="editingClasses[bandClass.id].description !== null">
                            <div v-for="error in getErrorsForKey('description',editingClasses[bandClass.id].errors)" :key="error" class="ebx-error">{{ error }}</div>
                        </div>
                    </md-table-cell>
                    <md-table-cell class="md-table-action">
                        &nbsp;
                    </md-table-cell>
                </template>
                <template v-else>
                    <md-table-cell>
                        <EbxInlineInput 
                            :modelValue="bandClass.value.toString()" 
                            :disabled="!canEdit"
                            :id="'band_' + band.name + '_' + bandClass.value"
                            :input-style-classes="['ebx-primary-1']"
                            :input-validation="inlineValidation('value','Value')"
                            :update-value="false"
                            input-type="text"
                            edit-actions-position="right"
                            @save="handleIndividualSave(bandClass, 'value', $event)"
                        />
                    </md-table-cell>
                    <md-table-cell class="md-table--bands-colour-cell">
                        <EbxInlineColorPicker 
                            :modelValue="bandClass.color" 
                            :disabled="!canEdit"
                            :id="'band_' + band.name + '_' + bandClass.color"
                            @update:modelValue="handleIndividualSave(bandClass, 'color', $event)"
                        />
                    </md-table-cell>
                    <md-table-cell>
                        <EbxInlineInput 
                            v-model="bandClass.description" 
                            :disabled="!canEdit"
                            :id="'band_' + band.name + '_' + bandClass.description"
                            :input-style-classes="['ebx-primary-1']"
                            :input-validation="inlineValidation('description','Description')"
                            :update-value="false"
                            input-type="text"
                            edit-actions-position="right"
                            @save="handleIndividualSave(bandClass, 'description', $event)"
                        />
                    </md-table-cell>
                    <md-table-cell class="md-table-action">
                        <template v-if="bandClass.mode === 'delete'">
                            <md-button class="md-icon-button" @click="cancelEditing(bandClass.id)">
                                <md-icon>close</md-icon>
                            </md-button>
                        </template>
                        <template v-else-if="canEdit">
                            <md-menu>
                                <md-button class="md-icon-button" md-menu-trigger>
                                    <md-icon>more_vert</md-icon>
                                </md-button>

                                <md-menu-content class="asset-options-menu">
                                    <!-- <md-menu-item class="asset-options-menu--option"
                                        @click="handleEdit(bandClass, bandClass.id)">
                                        <span class="material-icons-outlined ebx-icon">mode</span>
                                        <span>Edit</span>
                                    </md-menu-item> -->
                                    <md-menu-item class="asset-options-menu--option"
                                        @click="handleDelete(bandClass, bandClass.id)">
                                        <span class="material-icons-outlined ebx-icon">delete_outline</span>
                                        <span>Delete</span>
                                    </md-menu-item>

                                </md-menu-content>
                            </md-menu>
                        </template>
                    </md-table-cell>

                </template>
            </md-table-row>
        </md-table>
        <div v-if="canEdit" class="md-layout">
            <div class="md-layout-item">
                <EbxButton v-if="editingCount === 0" icon="add" theme="tertiary" class="no-padding no-margin" @click="handleNewClass" >
                    Add class
                </EbxButton>
            </div>
            <div class="md-layout-item mt-8 text-right">
                <template v-if="hasChanges">
                    <EbxButton theme="secondary" :disabled="isSaving" @click="handleCancel">
                        Cancel
                    </EbxButton>
                    <EbxButton :loading="isSaving" class="ml-8" :disabled="isSaving || anyErrors" @click="handleSave">
                        Save
                    </EbxButton>       
                </template>
            </div>
        </div>
        <Alert v-if="hasErrored" theme="danger">
            {{ responseError }}
        </Alert>
        <confirmation-modal ref="modal" :hide-title="true" :ok-button-text="okButtonText" :ok-button-class="okButtonClass">
            <p class="ebx-primary">
                {{ confirmMessage }}
            </p>
        </confirmation-modal>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import Alert from '@/components/Alert.vue';
import EbxInlineInput from '@/components/EbxComponents/EbxInlineInput.vue'
import EbxInlineColorPicker from '../EbxComponents/EbxInlineColorPicker.vue';
import EbxColorPicker from '../EbxComponents/EbxColorPicker.vue';

export default {
    name: 'BandClasses',
    components: {
        ConfirmationModal,
        Alert,
        EbxInlineInput,
        EbxInlineColorPicker,
        EbxColorPicker
    },
    props: {
        band: {
            type: Object,
            required: true
        },
        fieldBeingEdited: {
            type: String,
            required: true
        },
        formState: {
            type: Object,
            required: true
        },
        canEdit: {
            type: Boolean,
            required: true
        }
    },
    emits: [
        'save-band-name',
        'update-band-name',
        'update',
        'editing-ebx-field',
        'update-type',
        'delete-band'
    ],
    data() {
        return {
            editingClasses: {},
            confirmMessage:'',
            okButtonText: 'Delete',
            okButtonClass: 'md-raised md-danger',
            currentBandName: null
        }
    },
    computed: {
        nameValidation() {
            return {
                error: this.responseError,
                active: this.hasErrored
            }
        },
        bandName: {
            get() {
                return this.band.name
            },
            set(value) {
                this.$emit('update-band-name', value)
            }
        },
        classes() {
            const existingIds = this.band.classes.map(p => p.id)
            const allProperties = this.band.classes.slice(0).map(p => {
                p.color = p.color[0] !== '#' ? '#'+p.color : p.color
                p.mode = 'existing'
                return p
            })
            Object.keys(this.editingClasses).forEach(k => {
                const p = this.editingClasses[k]
                if (!existingIds.includes(p.id)) {
                    allProperties.push(p)
                } else {
                    const index = allProperties.findIndex(ap => ap.id === p.id)
                    allProperties[index] = p
                }
            })
            return allProperties
        },
        sortedClasses() {
            return this.classes.slice(0).sort((a, b) => a.value - b.value).filter(b => b.mode !== 'delete')
        },
        hasChanges() {
            return this.editingCount > 0
        },
        editingCount() {
            return Object.keys(this.editingClasses).map(d => this.editingClasses[d].mode === 'editing').filter(d => d === true).length
        },
        isSaving() {
            return this.formState.state === 'saving' && this.formState.data.bandName === this.band.id
        },
        hasErrored() {
            return this.formState.state === 'errored' && this.formState.data.bandName === this.band.id
        },
        isIdle() {
            return this.formState.state === 'idle'
        },
        responseError() {
            return this.formState.error
        },
        nextId() {
            if (this.classes.length === 0) {
                return 10
            }
            return this.classes.map(c => parseInt(c.value)).sort().pop() + 1
        },
        anyErrors() {
            if (this.hasErrored) {
                return true
            }
            const errorCount = Object.keys(this.editingClasses).reduce((acc, key) => {
                const p = this.editingClasses[key]
                return acc + p.errors.length
            }, 0)
            return errorCount > 0
        }
    },
    watch: {
        isSaving(current, previous) {
            if (previous === true && current === false) {
                if(this.isIdle){
                    this.editingClasses = {}
                }
            }
        }
    },
    methods: {
        handleClickingHexColour(slotProp) {
            slotProp.enterEditMode();
            slotProp.focusInput()
        },
        inlineValidation(property, niceName = null) {
            return (newValue) => {
                if (property === 'value') {
                    return {
                        error: `${niceName || property} must be a number`,
                        active: isNaN(parseInt(newValue)) || newValue === null || newValue.length === 0
                    }
                }
                return {
                    error: `${niceName || property} is required`,
                    active: newValue === null || newValue.length === 0
                }
            }
        },
        updateValue(bandClass, event) {
            this.editingClasses[bandClass.id] = {
                ...bandClass,
                value: event.target.value
            }
            this.validateEditedRows()
        },
        handleNewClass() {
            const id = uuidv4()
            this.editingClasses[id] = {
                id,
                value: this.nextId,
                color: '#000000',
                description: null,
                mode: 'editing',
                errors:[]
            }
            this.validateEditedRows()
        },
        async handleDelete(property, i) {
            this.confirmMessage = `Are you sure you want to delete this class?`
            this.okButtonText = 'Delete'
            this.okButtonClass = 'md-raised md-danger'
            const confirmed = await this.$refs.modal.confirm()
            if (confirmed) {
                this.editingClasses[i] = {
                    ...property,
                    mode: 'delete',
                    errors: []
                }
                this.validateEditedRows()
                this.handleSave()
            } 
        },
        
        handleDescriptionFieldClick(id) {
            if(this.editingClasses[id] && this.editingClasses[id].description === null){
                this.editingClasses[id].description = ''
            }
        },
        editingClass(i) {
            return typeof this.editingClasses[i] === 'object' && this.editingClasses[i] !== null && this.editingClasses[i].mode !== 'delete' && this.editingClasses[i].mode !== 'existing'
        },
        cancelEditing(i) {
            delete this.editingClasses[i]
        },
        handleCancel() {
            this.editingClasses = {}
        },
        handleEdit(property, i) {
            this.editingClasses[i] = {
                ...property,
                mode: 'edit',
                errors: []
            }
        },
        handleIndividualSave(bandClass, key, value) {
            this.editingClasses[bandClass.id] = {
                id: bandClass.id,
                value: key === 'value' ? parseInt(value) : bandClass.value,
                color: key === 'color' ? value : bandClass.color,
                description: key === 'description' ? value : bandClass.description,
                mode: 'existing',
                errors:[]
            }
            this.validateEditedRows()
            if(this.editingClasses[bandClass.id].errors.length === 0){
                this.handleSave()
            }
            //this.editingClasses = {}
        },
        handleSave() {
            if(this.validateEditedRows() === false) {
                return
            }
            const classValues = Object.keys(this.classes)
                .map(key => {
                    const currentClass = this.classes[key]
                    const useClass = this.editingClasses[currentClass.id] ? this.editingClasses[currentClass.id] : currentClass
                    if (useClass.mode === 'delete') {
                        return null
                    }
                    return {
                        value: parseInt(useClass.value),
                        color: useClass.color.replace('#', ''),
                        description: useClass.description
                    }

                })
                .filter(c => c !== null)
                .reduce((acc, cur) => {
                    acc[cur.value] = cur
                    delete acc[cur.value].value
                    return acc
                }, {})
            this.$emit('update', this.band.id, classValues)
        },
        getErrorsForKey(key, errors) {
            return errors.filter(e => e.key === key).map(e => e.value)
        },
        rowClass(cls) {
            return {
                deleted: cls.mode === 'delete', 
                errored: this.editingClasses[cls.id] && this.editingClasses[cls.id].errors.length > 0,
                editing: this.editingClass(cls.id)
            }
        },
        validateEditedRows() {
            const keys = Object.keys(this.editingClasses)
            let errorCount = 0
            keys.forEach(key => {
                const p = Object.assign({},this.editingClasses[key])
                p.errors = []
                if (p.mode === 'delete') {
                    return
                }
                const values = this.classes.filter(p2 => p2.id !== p.id).map(p2 => parseInt(p2.value))
                if (values.includes(parseInt(p.value))) {
                    p.errors.push({key: 'value', value: 'Value must be unique'})
                }
                if (`${p.value}`.match(/[^0-9]/) || p.value === null || `${p.value}`.length === 0) {
                    p.errors.push({key: 'value', value: 'Value must be numeric'})
                }else {
                    if (parseInt(p.value) < 0) {
                        p.errors.push({key: 'value', value: 'Value must be greater than 0'})
                    }
                }
                if (p.description === null) {
                    p.errors.push({key: 'description', value: 'Description is required'})
                }else if (typeof(p.description) !== 'string') {
                    p.errors.push({key: 'description', value: 'Description must be a string'})
                }else if (p.description.length === 0) {
                    p.errors.push({key: 'description', value: 'Description is required'})
                }

                errorCount += p.errors.length
                this.editingClasses[key].errors = p.errors
            })
            return errorCount === 0
        },
        updateFieldBeingEdited(field) {
            this.$emit('editing-ebx-field', field);
        },
        async handleChangeType(band, type) {
            this.$emit('update-type', band, type)
        },
        async handleDeleteBand(band) {
            this.$emit('delete-band', band)
        },
    }
}
</script>