<template>
    <div class="date-variable">
        <!-- creator version -->
        <VariableDetailComponent
            varType="date range"
            descHelperText="Add a description to provide guidance for users selecting a date range."
            :variable="variable"
            :hasValue="hasValue"
            :isEditing="isEditingVariable"
            @cancel-editing="handleEditCancel"
            @edit-variable="handleEditVariable"
            @save-variable="handleVariableSaved"
            >
            <div>
                
                <md-checkbox class="ebx-checkbox" v-model="annualDateRange">
                    <div class="ebx-primary-bold">Annual Date Range</div>
                    <div class="ebx-secondary md-helper-text">E.g. {{ variable.available_years[0] }} - {{ variable.available_years[variable.available_years.length -1] }}</div>
                </md-checkbox>
            </div>

            <div class="date-range-pickers">
                <div v-show="annualDateRange" class="date-range-pickers--container">
                    <div class="ebx-inline-label-field">
                        <label class="ebx-primary">From</label>
                        <md-field>
                            <KeepAlive>
                                <md-select v-model="currentStartYear" placeholder="Select default start date">
                                    <md-option v-for="year in variable.available_years" :key="year" :value="year">{{year}}</md-option>
                                </md-select>  
                            </KeepAlive> 
                        </md-field>
                    </div>
                    <div class="ebx-inline-label-field">
                        <label class="ebx-primary">To</label>
                        <md-field>
                            <KeepAlive>
                                <md-select v-model="currentEndYear" placeholder="Select default end date">
                                    <md-option v-for="year in variable.available_years" :key="year" :value="year">{{year}}</md-option>
                                </md-select>
                            </KeepAlive>
                        </md-field>
                    </div>
                </div>
                <div v-show="!annualDateRange" class="date-range-pickers--container">
                    <md-datepicker v-model="currentStartValue" placeholder="Select default start date" :model-value="currentStartValue" :md-disabled-dates="handleDisabledDates" @update:modelValue="onStartDateChange">
                        <label>Default start date</label>
                    </md-datepicker>
                    <md-datepicker v-model="currentEndValue" placeholder="Select default end date" :model-value="currentEndValue" :md-disabled-dates="handleDisabledDates" @update:modelValue="onEndDateChange">
                        <label>Default end date</label>
                    </md-datepicker>
                </div>
                
            </div>
            <p class="md-error" v-if="showValidationWarning">start date must be before end date</p>
        </VariableDetailComponent>
    </div>
</template>

<script>
import VariableDetailComponent from '@/components/Variables/VariableDetail/VariableDetailComponent.vue'
import { VariablesService } from '@/services/variable.service';
import moment from 'moment';
import { DateRangeVariable } from '../../../variables/dateRange';
import { useVuelidate } from '@vuelidate/core'
import DateRangeMixin from './helperMixin';

export default {
    name: 'DateRangeVariableCreator',
    mixins: [DateRangeMixin],
    setup: () => ({ v$: useVuelidate() }), // No actual validation used?
    components: {
        VariableDetailComponent
    },
    props: {
        variable: {
            type: Object,
            required: true,
        },
        isNew: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    emits: [
        'cancel-editing',
        'edit-variable',
        'save-variable'
    ],
    data() {
        return {
            isEditingVariable: false,
            currentStartValue: Array.isArray(this.variable.value) ? this.variable.value[0] : null,
            currentEndValue: Array.isArray(this.variable.value) ? this.variable.value[1] : null,
            annualDateRange: this.variable.cadence === 'year'
        }
    },
    created() {
        if (this.isNew) {
            this.isEditingVariable = true;
        }
    },
    computed: {
        variableId() {
            return this.variable.id;
        },
        hasValue() {
            if(this.currentStartValue === null || this.currentStartValue === undefined) {
                return false
            }
            if(this.currentEndValue === null || this.currentEndValue === undefined) {
                return false
            }
            if(this.showValidationWarning) {
                return false
            }
            return new Date(this.currentEndValue) >= new Date(this.currentStartValue)
        }
    },
    methods: {
        handleEditCancel() {
            this.$emit('cancel-editing');
            this.currentValue = this.variable.value;
            this.isEditingVariable = false;
        },
        handleEditVariable() {
            this.$emit('edit-variable');
            this.isEditingVariable = true;
        },
        onStartDateChange(date) {
            const formattedDate = moment(date).format('YYYY-MM-DD').toString()
            this.currentStartValue = formattedDate
        },
        onEndDateChange(date) {
            const formattedDate = moment(date).format('YYYY-MM-DD').toString()
            this.currentEndValue = formattedDate
        },
        handleVariableSaved(variable) {
            variable.cadence = this.annualDateRange ? 'year' : null
            variable.value = [this.currentStartValue, this.currentEndValue]
            this.isEditingVariable = false;
            this.$emit('save-variable', variable);
        },
        handleDisabledDates(date) {
            const variable = VariablesService.getVariableById(this.variableId)
            if(variable instanceof DateRangeVariable) {
                return !variable.isDateSelectable(date)
            }
            return false
        }
    }
}
</script>