<template>
  <div id="register" class="register">
    <h1 v-if="registration.success" class="ebx-header-1">Success!</h1>
    <h1 v-else class="ebx-header-1">Register</h1>
    
    <!-- <div class="productInfo" v-if="product || price">
      <h2 class="product" v-if="product">{{ product.name }}</h2>
      <h2 class="price" v-if="price">&pound;{{ price.unit_amount / 100 }} per {{ price.recurring.interval_count }} {{ price.recurring.interval }}</h2>
    </div> -->
    <div v-if="registration.error" class="errorFeedback" role="alert">
      <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
      <span class="sr-only">Error:</span>
      {{registration.error}}
    </div>
    <form id="new-user" @submit.prevent="saveNewUser" v-if="!registration.success && !registration.requested">
        <md-field :class="getValidationClass('displayName')">
          <label for="displayName">Name</label>
          <md-input v-model="user.displayName" ref="displayName" @blur="v$.user.displayName.$touch"/>
          <span
            class="md-error"
            v-if="v$.user.displayName.required.$invalid"
          >Your name is required</span>
        </md-field>
        <md-field :class="getValidationClass('email')">
          <label for="email">Email</label>
          <md-input v-model="user.email" ref="email" @blur="v$.user.email.$touch"/>
          <span
            class="md-error"
            v-if="v$.user.email.required.$invalid"
          >An email is required</span>
          <span
            class="md-error"
            v-if="v$.user.email.email.$invalid"
          >A valid email is required</span>
        </md-field>
        <md-field v-if="gmpActive" :class="getValidationClass('companyName')">
          <label for="email">Company Name</label>
          <md-input v-model="user.companyName" ref="email" @blur="v$.user.companyName.$touch"/>
          <span
            class="md-error"
            v-if="v$.user.companyName.required.$invalid"
          >A company name is required</span>
        </md-field>
        <md-field :class="getValidationClass('password')">
          <label for="password">Choose a password</label>
          <md-input v-model="user.password" ref="password" type="password" @update:modelValue="v$.user.password.$touch"/>
          <span class="md-error" v-if="v$.user.password.required.$invalid">A password is required</span>
          <span class="md-error" v-else-if="owaspViolations.length > 0">{{ owaspViolations[0] }}</span>
        </md-field>
        <md-field :class="getValidationClass('confirmPassword')">
          <label for="confirmPassword">Please confirm your password</label>
          <md-input v-model="user.confirmPassword" ref="confirmPassword" type="password" @blur="v$.user.confirmPassword.$touch"/>
          <span
            class="md-error"
            v-if="v$.user.confirmPassword.required.$invalid"
          >Please retype the same password again.</span>
          <span
            class="md-error"
            v-else-if="v$.user.confirmPassword.passwordsMatch.$invalid"
          >Doesn't match</span>
        </md-field>
        <md-field :class="getValidationClass('captchaResponse')" class="recaptcha">
          <div v-bind:id="recaptchaElementID"></div>
          <md-input v-model="user.captchaResponse" ref="captchaResponse" type="hidden" @blur="v$.user.captchaResponse.$touch"/>
          <span class="md-error" v-if="v$.user.captchaResponse.required.$invalid">Please click the I'm not a robot box</span>
        </md-field>
        <md-button type="submit" class="md-primary--confirmation-button" :disabled="registration.requested && !registration.requestCompleted">Register</md-button>
        <md-field v-if="gmpActive" :class="getValidationClass('accountId')" class="accountId">
          <md-input v-model="user.accountId" ref="accountId" type="hidden" @blur="v$.user.accountId.$touch"/>
        </md-field>
        <md-field v-if="gmpActive" :class="getValidationClass('ebxToken')" class="ebxToken">
          <md-input v-model="user.ebxToken" ref="ebxToken" type="hidden" @blur="v$.user.ebxToken.$touch"/>
        </md-field>
    </form>
    <div class="success" v-if="registration.success" role="alert">
      <div>{{registration.success}}</div>
      <div>Having trouble? Contact <span class="supportEmail"><a href="mailto:support@earthblox.io">support@earthblox.io</a></span></div>
    </div>
    <md-progress-spinner class="md-accent" md-mode="indeterminate" v-show="registration.requested && !registration.requestCompleted"></md-progress-spinner>
  </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { validate as uuidValidate } from "uuid";
import { useVuelidate } from '@vuelidate/core'
import { required, email, requiredIf } from '@vuelidate/validators'
import { functions } from "../firebaseFunctions.js";
import { earthbloxCollection } from '@/firebase.js';
import { OWASP_CONFIG, DEFAULT_ACTIVITY_VALUES, STRIPE_PRICE_API, STRIPE_PRODUCT_API, DEFAULT_RECAPTCHA_SITE_KEY, DEFAULT_STRIPE_PUBLISHABLE_KEY} from "@/constants/appConstants.js";
import * as owasp from 'owasp-password-strength-test';
owasp.config(OWASP_CONFIG);

export default {
  name: "Register",
  setup: () => ({ v$: useVuelidate() }),
  props: {
    priceId: { type: String, required: false },
    accountId: { type: String, required: false },
    ebxToken: { type: String, required: false }
  },
  data: () => ({
    price: null,
    product: null,
    gmpActive: false,
    validActivityTypes: DEFAULT_ACTIVITY_VALUES, // populated from values in Firestore
    stripePublishableAPIKey: null, // is fetched from the firestore DB, should be a restricted key, NOT the full Stripe API key, a default test key will be used if absent from the db
    recaptchaKey: null, // this is a *public* key that will be fetched from Firestore, a default value will be used if absent, handy for dev
    user: {
      priceId: "",
      displayName: "",
      email: "",
      password: "",
      companyName: "",
      confirmPassword: "",
      captchaResponse: "",
      },
    owaspViolations: [],
    registration: {
        requested: false,
        requestCompleted: false,
        error: null,
        success: null,
        autoverified: false,
    },
    recaptchaElementID: 'ebx-recaptcha',
  }),
  validations: {
    user: {
      displayName: {
        required
      },
      email: {
        required, email
      },
      password: {
        required: required, 
        validOwaspPassword: function (value){
          let result = owasp.test(value);
          this.owaspViolations = result.errors; // store the errors messages returned by owasp to display
          return result.strong; // returning this will fail the validation if result.strong is false
        },
      },
      confirmPassword: {
        required: required, 
        passwordsMatch: function (value) {
          return value === this.user.password;
        }
      },
      captchaResponse: {
        required: required
      },
      companyName: {
        required: requiredIf(
          function(){
            return this.gmpActive;
          }
        )
      },
      accountId: {
        required: requiredIf(
          function(){
            return this.gmpActive;
          }
        )
      },
      ebxToken: {
        required: requiredIf(
          function(){
            return this.gmpActive;
          }
        )
      }
    }
  },
  watch:{
    price(after) {
      if (after != null){
        console.log(`Fetching product ${after.product}`);
        let headers = new Headers({
          'Authorization': 'Bearer '+ this.stripePublishableAPIKey
        });
        fetch(`${STRIPE_PRODUCT_API}/${after.product}`, {headers: headers})
        .then((response) => {  
          if (response.status != 200){
            throw new Error(`Stripe Product API responded with ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          this.product = data;
          console.info(`Product ${after.product} name confirmed as -${this.product.name}-`);
        })
        .catch((error) => {console.warn(`Unable to get product information: ${error}`)});
      }
    }
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.v$.user[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    resetRecaptcha(){
      console.log(`Reinstantiating the recaptcha in element ${this.recaptchaElementID}`);
      /* Recaptcha is odd. If it suspects any funny business it refuses to work
        In our form hitting register hides the form while it sends the request to the server.
        If an error comes back, it reshows the form. This is rare, but can happen if the user's email address is in use.
        This unfortunately makes recaptcha turn off, meaning the user cannot resubmit the form.
        The below actally blanks the completed recaptcha field, and loads the recaptcha script again.
        The more obvious approaches like using grecaptcha reset do not work.
      */
      this.user.captchaResponse = "";
      this.loadRecaptcha();
      //window.grecaptcha.reset(this.recaptchaWidgetID);
    },
    onRecaptchaLoad(){
      console.info("Recaptcha lib from Google has loaded");
      // render the recaptcha widget
      this.recaptchaWidgetID =  window.grecaptcha.render(this.recaptchaElementID, {
        'sitekey' : this.recaptchaKey,
        'callback' :  this.onRecaptchaSolved // called as soon as the user solves the captcha
      });
    },
    onRecaptchaSolved(){
        console.info("Captcha solved by user");
        // set the hidden form field to the response value so that normal form validation can check it's been done
        // Of course the value is properly validated server side by the google function
        this.user['captchaResponse'] = window.grecaptcha.getResponse(this.recaptchaWidgetID);
    },
    saveNewUser() {
      console.log('New User Details submitted');
      this.v$.$touch();
      if (this.v$.$invalid) {
        console.warn("Form validation failed, bailing out.");
        for (const field in this.user){
          console.log(field);
          if (field == 'priceId'){
            // special case, priceId is injected to the user object so if present is known to be valid
            continue;
          }
          if (this.v$.user[field].$invalid){
            console.warn("Validation failed for ", field);
          } else {
            console.info("Validation succeeded for ", field);
          }
        }
        return;
      }
      console.info("New user registration: validated form successfully");
      this.registration = {
        requested: true,
        requestCompleted: false,
        error: null,
        success: null,
      };
      // Proper validation of the recaptcha response occurs inside the Firebase function before the user is saved
      // That final validation of the captcha response is invisible to the client side, as is the secret key
      if (this.gmpActive){
        // remove unwanted keys
        delete this.user.priceId;
        functions.gmpSaasRegister(this.user)
          .then((response) => {
            console.log(`Successfully registered GMP user ${response}`);
            let regStatus = {
              requested: false,
              requestCompleted: true,
            }  
            console.log("GMP registration worked!");
            regStatus.success = "Registration Successful";
            regStatus.autoverified = true;        
            regStatus.error = null;
            this.registration = regStatus;
            // with gmp users we don't do email verification, just send them straight to signin
            // TODO add a different welcome page for GMP users, they don't need to verify email
            this.$router.push({ name: "SignIn", params: { welcome: 2 } });
          }, (error) => {
            console.warn(`Something is not happy with the GMP registration ${error}`);
            // important: reload the captcha otherwise it disappears (due to the form being hidden and unhidden) and the user would not be able to resubmit
            this.registration = {
              requested: false,
              requestCompleted: true,
              success: null,
              error: `Sorry. We couldn't complete the registration. '${error}'`
            }
            this.resetRecaptcha();
          });
      } else {
        functions
          .registerUser(this.user)
            .then((response) => {
                var autoverified = false;
                console.log(`Successfully registered user`);
                try {
                  autoverified = response.data.autoverified;
                } catch(err) {
                  console.log(`The format of the json response didn't contain autoverified, assuming false. Error was: ${err}`);
                }
                this.registration = {
                    requested: false,
                    requestCompleted: true,
                    error: null,
                    autoverified: autoverified,
                      // the above string is dynamic to allow us to add things like the displayName in, although this is not in the current design
                }
                console.log(`Autoverified: ${this.registration.autoverified}`);
                if (autoverified){
                  // redirect to login
                  this.$router.push({ name: "SignIn", params: { welcome: 1 } });
                } else {
                  // display email verification text
                  this.registration.success = `We’ve sent you an email with a verification link. Click the link to verify your account and log in.`
                  // the above string is dynamic to allow us to add things like the displayName in, although this is not in the current design
                }
            }, (error) => {
                console.warn(error);
                this.registration = {
                    requested: false,
                    requestCompleted: true,
                    success: null,
                    error: `Sorry. We couldn't complete the registration. '${error}'`
                }
                // important: reload the captcha otherwise it disappears (due to the form being hidden and unhidden) and the user would not be able to resubmit
                // 
                this.resetRecaptcha();
            });
      }
    },
    fetchStripeData(){
      // console.info(`Price passed in was ${this.priceId}. This will be used to identify the Stripe subscription to use.`);
      console.log("Fetching product info from stripe")
      if (this.priceId && this.stripePublishableAPIKey){
        console.log(`Fetching price`);
        let headers = new Headers({
          'Authorization': 'Bearer '+ this.stripePublishableAPIKey
        }
        )
        fetch(`${STRIPE_PRICE_API}/${this.priceId}`, {headers: headers})
        .then((response) => {  
          if (response.status != 200){
            throw new Error(`Stripe API responded with ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          this.price = data;
          // we know this must be a valid priceId now, so add it to the user object to be sent to the backend
          console.info(`A valid Stripe price id has been confirmed ${this.priceId}`);
          this.user.priceId = this.priceId;
        })
        .catch((error) => {console.warn(`Unable to get pricing information: ${error}`)});
      
      } else {
        console.warn("No stripe price passed in, or the publishable API key is missing. Stripe activity will not occur");
      }
    },
    fetchGMPData(){
      console.log("Looking for Google Market Place Data");
      // TODO Fetch these from the path, the user will visit like this:
      // https://dev.earthblox.io/#/register/[the account id]/[the ebx token]
      //var testAccountId = '120eb8bd-195d-4474-bfa9-f4d990ffaf44'; // only for dev
      //var ebxToken = "8e77f7e6-b940-45a9-842d-9add9dc3d1d6"
      // do a simple check that the account id and token are both a valid UUID
      if (uuidValidate(this.accountId) && this.ebxToken){
        console.log("We have a valid account id proceed with GMP flow.");
        this.user.accountId = this.accountId;
        this.user.ebxToken = this.ebxToken;
        this.gmpActive = true;
      } else {
        console.log("GMP information not looking good. Non GMP flow triggered.");
        this.user.accountId = null;
        this.user.ebxToken = null;
        this.gmpActive = false;
      }
    },
    loadRecaptcha(){
        // this inserts the google recaptcha js load into the DOM
      // Odd, but this is how the vue-recaptcha plugin does it
      // That plugin does not work with our version of Vue (2.6)
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=explicit&onload=onRecaptchaLoad';
      script.async = true;
      script.defer = true;
      // the callback function gets called the google recaptcha code in the main window scope
      // It cannot see our Vue componment callback method
      // The following line refeences the vue method to the window to make this callback work
      window.onRecaptchaLoad = this.onRecaptchaLoad;
      document.body.appendChild(script);
    }
  },

  created() {
    console.info("Loaded Register.vue component. In 'created'.");
  },

mounted() {   
    console.info("DOM has mounted");
    console.info("Getting configuration info");
    earthbloxCollection.doc('registration').get()
    .then(config_doc => {
      this.stripePublishableAPIKey = config_doc.get('stripePublishableKey');
      this.recaptchaKey = config_doc.get('recaptchaSiteKey');
      if (!this.stripePublishableAPIKey){
        console.warn("No stripe key found in DB, falling back to the test mode default key");
        this.stripePublishableAPIKey = DEFAULT_STRIPE_PUBLISHABLE_KEY;
      }
      if (!this.recaptchaKey){
        console.warn("Non-fatal: No recaptcha site key found in DB, a default will be used");
        this.recaptchaKey = DEFAULT_RECAPTCHA_SITE_KEY;
      }
    })
    .catch(error => {
      this.recaptchaKey = DEFAULT_RECAPTCHA_SITE_KEY;
      this.stripePublishableAPIKey = DEFAULT_STRIPE_PUBLISHABLE_KEY;
      console.warn(`Couldn't fetch the registration collection. Probably you are missing some config. Defaults will be used, and this is probably not what you want for production. ${error}`);
    })
    .finally(() => {
      this.fetchStripeData();
      this.fetchGMPData();
      this.loadRecaptcha();
    });
    
  }
};
</script>