import {SAVED_WORKFLOW_ID_SEPERATOR, EBX_BAND_TYPE, THEMATIC} from '../constants/nextGenConstants'
import { reactive } from 'vue'
/**
 * Collection class that allows to filter datasets by type and workflow state
 */

class GlobalDatasetCollection {
    constructor(datasets) {
        this.datasets = datasets
    }
    /**
     * returns the datasets as they are stored in the workflow state. however makes a copy of the array
     * @returns {Array} array of datasets
     */
    toArray() {
        return this.datasets.slice(0)
    }
    /**
     * return a new GlobalDatasetCollection with the datasets that are available for the given workflow state
     * @returns {GlobalDatasetCollection}
     */
    all() {
        return new GlobalDatasetCollection(this.getDatasets())
    }

    /**
     * 
     * @returns {GlobalDatasetCollection}
     */
    onlyProjectOrigins() {
        return this.onlyOrigins(['workflow','area_service'])
    }

    /**
     * 
     * @returns {GlobalDatasetCollection}
     */
    onlyWorkspaceVariables() {
        return this.onlyOrigins(['workspace_variables'])
    }

    /**
     * 
     * @param {*} origin 
     * @returns {GlobalDatasetCollection}
     */
    onlyOrigins(origin) {
        const origins = Array.isArray(origin) ? origin: [origin]
        return new GlobalDatasetCollection(this.datasets.filter(dataset => origins.includes(dataset.origin)))
    }

    /**
     * Filters only raster datasets
     * @returns {GlobalDatasetCollection}
     */
    onlyRasters(types = ['image_collection']) {
        return new GlobalDatasetCollection(this.datasets.filter(dataset => types.includes(dataset.type)))
    }
    /**
     * Filters only vector datasets
     * @returns {GlobalDatasetCollection}
     */
    onlyVectors(types = ['feature_collection']) {
        return new GlobalDatasetCollection(this.datasets.filter(dataset => types.includes(dataset.type)))
    }

    /**
     * 
     * @param {*} types 
     * @returns 
     */
    onlyWorkspaceVariablesOfType(types) {
        if (typeof types === 'string') {
            types = [types]
        }
        return new GlobalDatasetCollection(this.datasets.filter(dataset =>{
            if (typeof dataset.variable !== 'object' || dataset.variable === null) {
                return false
            }
            return types.includes(dataset.variable.type)
        }))
    }

    /**
     * remove a type from the datasets
     * @param {*} type 
     * @returns 
     */
    withoutType(type) {
        const types = Array.isArray(type) ? type: [type]
        return new GlobalDatasetCollection(this.datasets.filter(dataset => types.includes(dataset.type) === false))
    }

    /**
     * includ eonly types of dataset
     * @param {*} type 
     * @returns 
     */
    onlyType(type) {
        const types = Array.isArray(type) ? type: [type]
        return new GlobalDatasetCollection(this.datasets.filter(dataset => types.includes(dataset.type)))
    }

    /**
     * flter Datasets based on the number of thematic bands
     * @param {*} count 
     * @returns 
     */
    hasThematicBands(count = 1) {
        const filterCompare = dataset => {
            return dataset.bands && dataset.bands.filter(band => band[EBX_BAND_TYPE] == THEMATIC).length >= count
        }
        return new GlobalDatasetCollection(this.datasets.filter(filterCompare))
    }
        /**
     * flter Datasets based on if they are a collection or study area
     * @param {*} count 
     * @returns 
     */
       isCollection(id = 'STUDY_AREA') {
            const collections = dataset => {
                return dataset && dataset['id'] !== id
            }
            return new GlobalDatasetCollection(this.datasets.filter(collections))
        }
    /**
     * Removes any datasets saved or not that are within the state provided
     * @returns {GlobalDatasetCollection}
     */
    ignoreCurrentWorkflowState(state) {
        return new GlobalDatasetCollection(this.datasets.filter(dataset => dataset.id.startsWith(state.id) === false))
    }

    /**
     * Filters only datasets that are available for the given workflow state
     * @param {*} stateId 
     * @param {*} registry 
     * @returns {GlobalDatasetCollection}
     */
    notSubscribedTo(stateId, registry) {
        const compareFn = dataset => {
            const workflowId = dataset.id.split(SAVED_WORKFLOW_ID_SEPERATOR)[0]
            // We want to get all subscriptions including the hidden ones as hidden ones contain datasets.
            const subscriptions = registry.getSubscriptions(workflowId, true, true)
            return subscriptions.indexOf(stateId) < 0
        }
        return new GlobalDatasetCollection(this.datasets.filter(compareFn))
    }
    /**
     * Helper method to return the datasets as an array of options for a select in blockly
     * @returns 
     */
    asOptions() {
        return this.datasets.map(dataset => {
            return [dataset.title, dataset.id]
        })
    }

    /**
     * find a dataset by its id
     * @param {*} id 
     * @returns 
     */
    findById(id) {
        return this.datasets.find(dataset => {
            return dataset.id === id
        });
    }
    /**
     * get a distinct list of datasets
     * @returns {Array} array of datasets
     */
    distinct() {
        const keys = []
        return new GlobalDatasetCollection(this.datasets.filter(d => {
            if(keys.indexOf(d.id) < 0) {
                keys.push(d.id)
                return true
            }
            return false
        }))
    }
    /**
     * map
     */
    map(fn) {
        return new GlobalDatasetCollection(this.datasets.map(fn))
    }

    /**
     * filter
     * @param {*} fn 
     * @returns 
     */
    filter(fn) {
        return new GlobalDatasetCollection(this.datasets.filter(fn))
    }
}

class GlobalDatasetListener {
    constructor(service, provider) {
        this.service = service
        this.provider = provider
    }
    change(updatedData) {
        this.service.subscribers.forEach(subscriber => {
            subscriber(updatedData, this.provider, this.service)
        })
        return this
    }
}

/**
 * Service to get all the datasets that are available as global datasets in Workflow and Map Areas
 */
export class GlobalDatasetsService {
    constructor() {
        this.providers = []
        this.subscribers = []
        this._makeObservable()
    }

    /**
     * add a provider to the service that will be queried for global datasets. Must implement the getGlobalDatasetData method
     * @param {*} provider 
     * @returns 
     */
    addProvider(provider) {
        if(typeof provider.attachGlobalDatasetUpdateListener === 'function') {
            provider.attachGlobalDatasetUpdateListener(new GlobalDatasetListener(this, provider));
        }
        this.providers.push(provider)
        return this
    }

    /**
     * gets all the datasets that are available as global datasets in Workflow and Map Areas
     * @param {*} workflowState 
     * @returns {GlobalDatasetCollection}
     */
    async getForWorkflowState(workflowState, requireCurrentWorkflow = false,) {
        const arrayOfDatasets = Promise.all(this.providers.map(async provider => await provider.getGlobalDatasetData(workflowState, requireCurrentWorkflow)))
        const globalData = new GlobalDatasetCollection((await arrayOfDatasets).flat())
        return workflowState && requireCurrentWorkflow === false ? globalData.ignoreCurrentWorkflowState(workflowState) : globalData
    }

    /**
     * convenience method to get all the datasets that are available as global datasets in Workflow and Map Areas
     * @returns {GlobalDatasetCollection}
     */
    async getDatasets() {
        return this.getForWorkflowState(null)
    }
    
   
    /**
     * add a subscriber to the service that will be notified when the global datasets change
     * @param {*} subscriber 
     * @returns 
     */
    subscribe(subscriber) {
        this.subscribers.push(subscriber)
        return () => {
            this.subscribers = this.subscribers.filter(s => s !== subscriber)
        }
    }

    _makeObservable() {
        this.observable = reactive({
            datasets: []
        })
        this.subscribers.push(async () => {
            const datasets = (await this.getDatasets()).distinct().toArray()
            this.observable.datasets = datasets
        })
    }

    getObservable() {
        return this.observable
    }
    hasObservable() {
        return this.observable !== undefined
    }
    getWrappedObservable() {
        if(this.hasObservable()=== false) {
            return new GlobalDatasetCollection([]) 
        }
        const observable = this.getObservable();
        return new GlobalDatasetCollection(observable.datasets) 
        
    }
}