import { globalEventBus } from '../eventbus';

// Replace the default delete shortcut with a custom one that can be supressed
const replaceDeleteShortcut = (Blockly) => {
    let deleteActionState = true
    // Listen to supressDeleteAction event fired by the modal
    globalEventBus.$on('supressDeleteAction', (state) => {
        deleteActionState = state !== true
    });

    // Replace the delete shortcut with a custom one that can be supressed
    const oldDeleteObject = Blockly.ShortcutRegistry.registry.getRegistry().delete
    const newDeleteObject = {
        name: oldDeleteObject.name,
        preconditionFn: oldDeleteObject.preconditionFn,
        callback: function(workspace, e) {
            if(deleteActionState === false) {
                console.warn('Delete action supressed')
                return
            }
            return oldDeleteObject.callback(workspace, e)
        }
    }
    // Replace the old shortcut with the new one
    Blockly.ShortcutRegistry.registry.register(newDeleteObject, true)
}

// Replace the default copy shortcut with a custom one that can be supressed when the selection is not a blocklySvg element allowing text copy on the app via CMD+C
const replaceCopyShortcut = (Blockly) => {
    const oldCopyObject = Blockly.ShortcutRegistry.registry.getRegistry().copy
    const newCopyObject = {
        name: oldCopyObject.name,
        preconditionFn: oldCopyObject.preconditionFn,
        callback: function(workspace, e) {
            const selection = window.getSelection()
            // check if the selection is a blocklySvg element if so allow blockly copy. otherwise supress
            if (selection && selection.anchorNode && selection.anchorNode.tagName === 'svg' && selection.anchorNode.className.baseVal === 'blocklySvg') {
                return oldCopyObject.callback(workspace, e)
            }
        }
    }
    // Replace the old shortcut with the new one
    Blockly.ShortcutRegistry.registry.register(newCopyObject, true)
}

export const init = (Blockly) => {
    replaceDeleteShortcut(Blockly)
    replaceCopyShortcut(Blockly)
    return Blockly
}