import EbxButton from '@/components/EbxComponents/EbxButton.vue'
import EbxAlert from '@/components/EbxComponents/EbxAlert.vue'

const EbxPlugin = {
    install(app) { 
        app.component('EbxButton', EbxButton);
        app.component('ebx-button', EbxButton);
        app.component('EbxAlert', EbxAlert);
    }
};

export { EbxPlugin };