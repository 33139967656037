<template>
    <div class="ebx-alert ebx-secondary" :class="themeClasses">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'EbxAlert',
    props: {
        theme: {
            type: String,
            default: 'info',
            validator: function (value) {
                return ['info', 'success', 'warning', 'error'].indexOf(value) !== -1
            }
        }
    },
    computed: {
        themeClasses() {
            return {
                'ebx-alert--info': this.theme === 'info',
                'ebx-alert--success': this.theme === 'success',
                'ebx-alert--warning': this.theme === 'warning',
                'ebx-alert--error': this.theme === 'error'
            }
        }
    }
}
</script>