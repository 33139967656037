<template>
    <div  class="page-loading-overlay">
        <div class="ebx-primary">            
            <slot>Loading project</slot>
        </div>
        <div class="page-loading-overlay--progress">
            <md-progress-bar md-mode="indeterminate"></md-progress-bar>
        </div>
    </div>
</template>

<script>
export default{
}
</script>