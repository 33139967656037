/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Papa from 'papaparse';
import { USER_CREATOR_ROLE } from "@/constants/appConstants";

const CsvService = {
    readUsersFromCsv(file) {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                skipEmptyLines: true,
                complete(results) {
                    let users = [];

                    if (!results.data || results.data.length === 0) {
                        resolve(users);
                    }

                    // remove first row if it is column names
                    const firstRow = results.data[0];

                    const columnNames = ['email', 'displayname', 'password', 'usertype'];
                    const lowerCaseFirstRow = firstRow.map(column => column.toLowerCase());
                    const isColumnNames = lowerCaseFirstRow.filter((column, index) => column === columnNames[index]).length >= 3; // as userType is optional

                    if (isColumnNames) {
                        results.data.shift();
                    }

                    if (results.data) {
                        users = results.data.map(row => {
                            let email = row[0] || '';
                            let displayName = row[1] || '';
                            let password = row[2] || '';
                            let userType = row[3] || USER_CREATOR_ROLE;

                            let roles = [userType];

                            return { email, password, displayName, roles }
                        });
                    }

                    resolve(users);
                },
                error(error) {
                    reject(error);
                }
            });
        });
    },

    /**
     * Reads the csv file, returns data and headers, and a lat and lon column if found
     * @param {*} file 
     */
    readLatLonColumnsFromCsv(file) {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete(results) {
                    const headers = results.meta.fields

                    const validLatColumnNames = [
                        'latitude', 
                        'lat',
                        'y',
                        'northing',
                    ];

                    const validLonColumnNames = [
                        'longitude', 
                        'lon',
                        'long',
                        'x',
                        'easting',
                    ];

                    const foundLatColumnNames = headers.filter(header => validLatColumnNames.includes(header.toLowerCase()));
                    const foundLonColumnNames = headers.filter(header => validLonColumnNames.includes(header.toLowerCase()));

                    let response = {
                        results: results.data,
                        headers: headers,
                    }

                    if (foundLatColumnNames.length > 0 && foundLonColumnNames.length > 0) {
                        response.latColumn = foundLatColumnNames[0];
                        response.lonColumn = foundLonColumnNames[0];
                    }

                    resolve(response);
                },
                error(error) {
                    reject(error);
                }
            })
        })
    },
}

export {
    CsvService
}