<template>
    <div class="sign-in">
        <h1 v-if="welcome" class="ebx-header-1">Success!</h1>
        <h1 v-else class="ebx-header-1">Sign in</h1>
        
        <div class="sign-in--component" v-if="welcome == 1">
            <p class="ebx-primary">We’ve sent you an email with a verification link. Click the link to verify your account and log in.</p>
            <p class="ebx-primary">Having trouble? Contact <a class="ebx-link ebx-link__light" href="mailto:support@earthblox.io">support@earthblox.io</a></p>
        </div>
        <div class="sign-in--component" v-else-if="welcome == 2">
            <p class="ebx-primary">We're verifying your account, and will send you an email with a link to sign in. This may take up to five minutes.</p>
            <p class="ebx-primary">Having trouble? Contact <a class="ebx-link ebx-link__light" href="mailto:support@earthblox.io">support@earthblox.io</a></p>
        </div>
        <form v-else id="this.form_id" @submit.prevent="signin">
            <Transition tag="div" name="slide">
                <div class="sign-in--component" v-show="showEmail">
                    <md-field :class="getValidationClass('email')" >
                        <label for="email">Email</label>
                        <md-input id="ebx-email-input" type="email" name="email" autocomplete="email" v-model="email" />
                        <span class="md-error" v-if="v$.email.required.$invalid">The email is required</span>
                        <span class="md-error" v-else-if="v$.email.email.$invalid">Invalid email</span>
                    </md-field>
                    <EbxButton :theme="'primary'" @click="handleEmailCheck">Next</EbxButton>
                </div>
            </Transition>
            <Transition tag="div" name="slide2">
                <div class="sign-in--component" v-show="showPassword">
                    <md-field :class="getValidationClass('password')">
                        <label>Password</label>
                        <md-input id="ebx-password-input" v-model="password" type="password"></md-input>
                        <span class="md-error" v-if="v$.password.required.$invalid">Password is required</span>
                    </md-field>
                    <div class="sign-in--actions">
                        <router-link class="ebx-link ebx-link__light" to="/reset">Forgot your password?</router-link>
                        <button class="ebx-button ebx-button--primary" type="submit">Sign In</button>
                    </div>
                </div>
            </Transition>
            <Transition tag="div" name="slide2">
                <div class="sign-in--component" v-show="showSSO">
                    <p class="ebx-primary">Use your {{ssoName}} account to sign in to Earth Blox.</p>
                    <button class="sso-sign-in" @click="handleSSOSignIn">
                        <img v-if="ssoIcon" :src="ssoIcon" :alt="ssoName+' logo'">
                        <span>Sign in with {{ssoName}}</span>
                    </button>
                </div>
            </Transition>
        </form>

        <md-snackbar :md-position="'center'" :md-duration="5000" v-model:md-active="showSnackbar" md-persistent>
            <span>{{ errorMessage }}</span>
        </md-snackbar>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { AuthService } from "@/services/auth.service";
import { functions } from '@/firebaseFunctions';
import { firebase } from '@/firebase.js';
import authMixin from "@/components/mixins/authMixin";

export default {
    name: "SignIn",
    mixins: [authMixin],
    setup: () => ({ v$: useVuelidate() }),
    data: () => ({
        email: "",
        password: "",
        errorMessage: "",
        showSnackbar: false,
        showEmail: true,
        showPassword: false,
        showSSO: false,
        ssoName: "",
        ssoIcon: "",
        ssoProvider: ""
    }),
    validations() {
        return {
            email: {
                required,
                email
            },
            password: {
                required
            }
        }
    },
    props: {
        welcome: { type: String, required: false }
    },
    methods: {
        getValidationClass(fieldName) {
            const field = this.v$[fieldName];

            if (field) {
                return {
                    "md-invalid": field.$invalid && field.$dirty
                };
            }
        },
        async handleEmailCheck() {
            this.v$.$touch();
            // check if valid email
            if (this.v$.email.$invalid) {
                return;
            }

            // check if email is sso email
            let ssoResponse = await this.verifyEmail();
            console.log("ssoResponse", ssoResponse);

            // set showEmail and showPassword accordingly
            if (ssoResponse.sso_type) {
                this.showEmail = false;
                this.showPassword = false;
                this.ssoName = ssoResponse.name || '';
                this.ssoIcon = ssoResponse.icon || '';
                this.ssoProvider = ssoResponse.provider_id || '';
                this.showSSO = true;
            } else {
                this.showEmail = false;
                this.showSSO = false;
                this.showPassword = true;
            }
            
        },
        handleSSOSignIn() {
            if (this.ssoProvider) {
                const provider = new firebase.auth.SAMLAuthProvider(this.ssoProvider);
                firebase.auth().signInWithRedirect(provider);
            } else {
                // TO DO: handle an error message? push to register/Sign in?
                console.error("No SSO provider found");
            }
        },
        signin() {
            console.log('signin')
            this.v$.$touch();

            let self = this;
            self.errorMessage = "";
            
            if (!this.v$.$invalid) {
                AuthService.signInWithEmailAndPassword(this.email, this.password)
                .then(function(credential) {
                    if (credential.user.emailVerified){
                        console.log("Signin: user " + credential.user.uid);
                    } else {
                        // The authservice onAuthStateChanged will log the user out now
                        return Promise.reject(new Error("Email address is not verified"));
                    }            
                })
                .catch(function(error) {
                    console.error("Login failed: ", error);
                    self.errorMessage = error.message;
                    self.showSnackbar = true;
                });
            }
        },
        async verifyEmail() {
            const email = this.email;
            const ssoResponse = await functions.sso_get_record_for_email(email);
            const ssoRecord = ssoResponse.data;
            // Check if SSO record has any content
            if (Object.keys(ssoRecord).length !== 0) {
                console.log(`We should get the user to login using ${ssoRecord['sso_type']} SSO to ${ssoRecord['name']}`)
            } else {
                console.log(`No action required`)
            }
            return ssoRecord
        },
    },
    mounted: function() {
        console.log("SignIn:mounted");
    },
    created: function() {
        console.log("SignIn:created");
        // Redirect user if already logged in.
        // Note this also affects users who have just logged in via SSO; the AuthService handlers 
        // will pick the auth change up and complete the sign up if necessary.
        if (AuthService.isUserLoggedIn()) {
            console.info("User logged in: routing to Home")
            if (this.isExplorer) {
                this.$router.push({ name: "Projects-Explore" });
            } else {
                this.$router.push({ name: "Projects" });
            }
        }
    }
};
</script>