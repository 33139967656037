/**
 * A simple mixin for copy and paste behaviour in state.
 */
export default {
    data() {
        return {
            copied: false,
            timeout: null
        }
    },
    methods: {
        handleCopyClick(copyValue) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.copied = true;
            navigator.clipboard.writeText(copyValue);
            this.timeout = setTimeout(() => {
                this.copied = false;
                this.timeout = null;
            }, 2000);
        }
    }
}