<template>
    <div>
    <md-dialog v-model:md-active="computedModalShown" class="blockly-modals">
        <md-dialog-title>
            <span>WekaKMeans Options</span>
        </md-dialog-title>

        <form>
        
            <md-field>
                <label for="nClusters">Number Of Clusters</label>
                <md-input name="nClusters" v-model="formData.nClusters" type="number" :placeholder="nClusters ? nClusters.toString() : ''"></md-input>
            </md-field>

            <md-field>
                <label for="init">Init</label> 
                <md-select name="init" v-model="formData.init">
                    <md-option v-for="option in initOptions" :value="option[1]" :key="option[1]">
                        {{ option[0]}}
                    </md-option>
                </md-select>
            </md-field>
            <div>
                <md-switch name="canopies" v-model="formData.canopies">Use Canopies</md-switch>
            </div>

            <md-field>
                <label for="maxCandidates">Max Candidates</label>
                <md-input name="maxCandidates" v-model="formData.maxCandidates" type="number" :placeholder="maxCandidates ? maxCandidates.toString() : ''"></md-input>
            </md-field>
            <md-field>
                <label for="periodicPruning">Periodic Pruning</label>
                <md-input name="periodicPruning" v-model="formData.periodicPruning" type="number" :placeholder="periodicPruning ? periodicPruning.toString() : ''"></md-input>
            </md-field>
            <md-field>
                <label for="minDensity">Minimum Canopy Density</label>
                <md-input name="minDensity" v-model="formData.minDensity" type="number" :placeholder="minDensity ? minDensity.toString() : ''"></md-input>
            </md-field>
            <md-field>
                <label for="t1">T1 Distance</label>
                <md-input name="t1" v-model="formData.t1" type="number" :placeholder="t1 ? t1.toString() : ''"></md-input>
            </md-field>

            <md-field>
                <label for="t2">T2 Distance</label>
                <md-input name="t2" v-model="formData.t2" type="number" :placeholder="t2 ? t2.toString() : ''"></md-input>
            </md-field>
            <md-field>
                <label for="maxIterations">Maximum Iterations</label>
                <md-input name="maxIterations" v-model="formData.maxIterations" type="number" :placeholder="maxIterations ? maxIterations.toString() : ''"></md-input>
            </md-field>
            <div>
                <md-switch name="preserveOrder" v-model="formData.preserveOrder">Preserve Order</md-switch>
            </div>
            <div>
                <md-switch name="fast" v-model="formData.fast">Fast Distance Calculations</md-switch>
            </div>
            <md-field>
                <label for="seed">Randomization Seed</label>
                <md-input name="seed" v-model="formData.seed" type="number" :placeholder="seed ? seed.toString() : ''"></md-input>
            </md-field>


            <md-field>
                <label for="distanceFunction">Distance Function</label> 
                <md-select name="distanceFunction" v-model="formData.distanceFunction">
                    <md-option v-for="option in distanceOptions" :value="option[1]" :key="option[1]">
                        {{ option[0]}}
                    </md-option>
                </md-select>
            </md-field>
        </form>

        <md-dialog-actions>
            <md-button class="md-primary" @click="computedModalShown = false">Close</md-button>
            <md-button class="md-primary" @click="saveModal">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
    </div>
</template>

<script>
import { globalEventBus } from '@/eventbus.js'
import blocklyModalsMixin from '../blocklyModalsMixin.js'

export default {
    name: 'UnsupervisedClassificationWekaKMeans',
    mixins: [blocklyModalsMixin],
    props: {
        distanceFunction: {
            type: String,
            default: 'Euclidean'
        },
        nClusters: {
            type: Number,
            default: 0
        },
        init: {
            type: Number,
            default: 0
        },
        canopies: {
            type: Boolean,
            default: false
        },
        maxCandidates: {
            type: Number,
            default:100
        },
        periodicPruning: {
            type: Number,
            default: 10000
        },
        minDensity: {
            type: Number,
            default:2
        },
        t1: {
            type: Number,
            default: -1.5
        },
        t2: {
            type: Number,
            default: -1
        },
        maxIterations: {
            type: Number,
            default: null
        },
        preserveOrder: {
            type: Boolean,
            default: false,
        },
        fast: {
            type: Boolean,
            default: false,
        },
        seed: {
            type: Number,
            default: 10,
        },
        showModal: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            distanceOptions: [
                ['Euclidean','Euclidean'],
                ['Manhattan','Manhattan']
            ],
            initOptions: [
                ['Random',0],
                ['K-Means++',1],
                ['Canopy',2],
                ['Farthest First',3],
            ],
            formData: {
                distanceFunction: this.distanceFunction,
                nClusters: this.nClusters,
                init: this.init,
                canopies: this.canopies,
                maxCandidates: this.maxCandidates,
                periodicPruning: this.periodicPruning,
                minDensity: this.minDensity,
                t1: this.t1,
                t2: this.t2,
                maxIterations: this.maxIterations,
                preserveOrder: this.preserveOrder,
                fast: this.fast,
                seed: this.seed
            }
        }
    },
    methods: {
        getFieldValue() {
            return {
                distanceFunction: this.formData.distanceFunction,
                nClusters: this.formData.nClusters,
                init: this.formData.init,
                canopies: this.formData.canopies,
                maxCandidates: this.formData.maxCandidates,
                periodicPruning: this.formData.periodicPruning,
                minDensity: this.formData.minDensity,
                t1: this.formData.t1,
                t2: this.formData.t2,
                maxIterations: this.formData.maxIterations,
                preserveOrder: this.formData.preserveOrder,
                fast: this.formData.fast,
                seed: this.formData.seed
            };
        },
        saveModal() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        },
    },
    watch: {
        showModal(val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if(val) {
                this.formData = {
                    distanceFunction: this.distanceFunction,
                    nClusters: this.nClusters,
                    init: this.init,
                    canopies: this.canopies,
                    maxCandidates: this.maxCandidates,
                    periodicPruning: this.periodicPruning,
                    minDensity: this.minDensity,
                    t1: this.t1,
                    t2: this.t2,
                    maxIterations: this.maxIterations,
                    preserveOrder: this.preserveOrder,
                    fast: this.fast,
                    seed: this.seed
                }
            }
        }
    }
}
</script>