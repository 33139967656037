import { findIndex } from 'lodash'
export default {
    props: {
        datasets: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            showTransformDialog: false,
            tableCreatorRows: [],
            selectedRowId: -1,
            transformColumn: null,
            rowsForSave: {
                type: Array,
                default: () => []
            },
            transformChangedData: {
                transformColumn: null,
                transform: null,
                preference: null
            }
        }
    },
    computed: {
        highestRowId() {
            return this.tableCreatorRows.reduce((c, row) => {
                if(c < row.id) {
                    c = row.id
                }
                return c
            },0)
        },
        transformedColumnsArray() {
            const transformed = []
            if(this.selectedRow) {
                if(this.selectedRow.propertiesTransform !== null) {
                    transformed.push('properties')
                }
                if(this.selectedRow.datesTransform !== null) {
                    transformed.push('dates')
                }
            } 
            return transformed
        },
        selectedTransform() {
            if(this.transformChangedData.transform) {
                return this.transformChangedData.transform
            }
            if(this.selectedRow) {
                const selectedRowKey = this.transformColumn === 'properties' ? 'propertiesTransform' : 'datesTransform'
                return this.selectedRow[selectedRowKey]
            }
            return undefined
        },
        selectedPreference() {
            if(this.transformChangedData.preference) {
                return this.transformChangedData.preference
            }
            if(this.selectedRow) {
                return this.selectedRow.transformPreference
            }
            return undefined
        },
        selectedRow() {
            if(this.getSelectedRowIndexFromId(this.selectedRowId) >= 0) {
                return this.tableCreatorRows[this.getSelectedRowIndexFromId(this.selectedRowId)]
            }
            return undefined
        }
    },
    methods: {
        getSelectedRowIndexFromId(id) {
            return findIndex(this.tableCreatorRows, {id})
        },
        resetTransformChangedData() {
            this.transformChangedData = {
                transformColumn: null,
                transform: null,
                preference: null
            }
        },
        handleTransformDialog(column, rowId) {
            this.selectedRowId = rowId
            this.transformColumn = column
            this.showTransformDialog = true
        },
        updateRow(row) {
            this.tableCreatorRows.forEach((r,i) => {
                if (r.id === row.id) {
                    this.tableCreatorRows[i] = row
                }
            });
        },
        deleteRow(id) {
            this.tableCreatorRows = this.tableCreatorRows.filter(r => r.id !== id);
        },
        getDataset(id) {
            return this.datasets.find(d => d.id === id);
        },
        getDatasetPropertiesIndexes(datasetId, savedProperties) {
            const dataset = this.getDataset(datasetId);
            
            const selectedPropNames = Array.isArray(savedProperties) ? savedProperties.map(prop => prop['ebx:name'] || prop.name) : savedProperties

            if(dataset.type === "image_collection") {
                return dataset.bands
                .map((b,i)=> { 
                    return {name: b.name, id: i,'ebx:name': b['ebx:name'] || b.name}
                })
                .filter(b => selectedPropNames === 'all' || selectedPropNames.includes(b['ebx:name']))
                .map(b => b.id);
            } 
            if(dataset.type === "feature_collection") {
                return dataset.attributes
                .map((b,i)=> { 
                    return {name: b.name, id: i,'ebx:name': b['ebx:name'] || b.name}
                })
                .filter(p => selectedPropNames === 'all' || selectedPropNames.includes(p['ebx:name']))
                .map(b => b.id);
            }

            return [];
        },
        getSelectedDateIndexes(datasetId, savedDates) {
            if(!savedDates.length) {
                return [];
            }

            const dataset = this.getDataset(datasetId);
            const selectedDateNames = Array.isArray(savedDates) ? savedDates.map(date => date.name) : savedDates

            return dataset.multi_image_temporal.temporal
            .map((d,i)=> { 
                return {name: d.name, id: i}
            })
            .filter(d => selectedDateNames === 'all' || selectedDateNames.includes(d.name))
            .map(d => d.id);
        },
        filterDatasetProperties(dataType, filterAttribute, datasetId) {
            if(!datasetId) {
                return [];
            }
            const dataset = this.getDataset(datasetId);
            if(!dataset) {
                return [];
            }
            if(dataset[filterAttribute] === undefined) {
                return [];
            }
            const mapItems = (item, index) => {
                return {
                    name: item['ebx:name'] || item.name,
                    datatype: item['ebx:datatype'],
                    id: index,
                    value: item.value
                }
            }
            const filterNumericBands = item => {
                return item['ebx:datatype'] === dataType
            }
            

            return dataset[filterAttribute]
                .filter(filterNumericBands)
                .map(mapItems);
        },
        updateSelectedTransform(reducer, transformColumn) {
            this.transformChangedData.transformColumn = transformColumn
            this.transformChangedData.transform = reducer 
        },
        updateSelectedPreference(preference) {
            this.transformChangedData.preference = preference 
        }
    },
}