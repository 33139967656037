
/**
 * Checks if a given value is not null or undefined
 * @param {*} value 
 * @returns {Boolean} true when the value is not null or undefined
 */

function isNotNullOrUndefined(value) { 
    return value !== undefined && value !== null
} 

export {
    isNotNullOrUndefined
}