<template>
    <div>
        <md-dialog v-model:md-active="computedModalShown" class="blockly-modals modal-m" :md-click-outside-to-close="false"
            :md-close-on-esc="false">
            <md-dialog-title class="title">
                <span>Classify Image Using Thresholds</span>
            </md-dialog-title>
            <md-dialog-content>
                <md-content>
                    <md-field>
                        <label for="band">Use Band</label>
                        <md-select name="band" v-model="formData.band">
                            <md-option v-for="option in bandOptions" :value="option[1]" :key="option[1]">
                                {{ option[0] }}
                            </md-option>
                        </md-select>
                    </md-field>
                    <md-field :class="messageClass">
                        <label for="band">Save as new band called</label>
                        <md-input v-model="formData.newBandName"></md-input>
                        <span class="md-error" v-if="!isNameValid">{{ errorMessage }}</span>
                    </md-field>
                </md-content>
                <md-content>
                    <TableRange 
                    v-model="formData.thresholds" 
                    @add-threshold="addNewThreshold" 
                    @remove-threshold="removeThreshold" />
                </md-content>
            </md-dialog-content>
            <md-dialog-actions>
                <div class="md-layout-item md-size-20 mt-4">
                    <md-button class="md-primary" @click="handleClose">Close</md-button>
                </div>
                <div class="md-layout-item md-size-20 mt-4">
                    <md-button class="md-primary" @click="handleSave">Save</md-button>
                </div>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import TableRange from '../components/ThematicProcessingModal/TableRange.vue';
import { globalEventBus } from '@/eventbus.js'
import { VALID_BANDNAME_REGEX,INVALID_BANDNAME_ERROR } from "@/constants/nextGenConstants";
import blocklyModalsMixin from './blocklyModalsMixin.js'

export default {
    name: 'ThematicProcessing',
    components: {
        TableRange
    },
    mixins: [blocklyModalsMixin],
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        band: {
            type: String,
            default: null
        },
        newBandName: {
            type: String,
            default: null
        },
        thresholds: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            bands: [],
            bandSuffix: '_Thresholded',
            formData: {
                thresholds: this.thresholds,
                band: this.band,
                newBandName: this.newBandName
            },
        }
    },
    computed: {
        bandOptions() {
            return this.bands.map(band => {
                return [
                    band['ebx:name'] || band.name,
                    band['ebx:name'] || band.name,
                ]
            })
        },
        selectedBand() {
            return this.bands.filter(band => (band['ebx:name'] || band.name) === this.band)
        }, 
        isNameValid() {
            if(this.formData.newBandName == null) {
                return true;
            }

            const test = VALID_BANDNAME_REGEX.test(this.formData.newBandName);

            if (!test) {
                return false;
            } 

            return true;
        },
        errorMessage() {
            if (this.isNameValid) {
                return "";
            }

            return INVALID_BANDNAME_ERROR;
        },
        messageClass() {
            return {
                "md-invalid": !this.isNameValid
            }
        },
        changedBandFormData() {
            return this.formData.band;
        },
    },
    watch: {
        changedBandFormData(newValue, oldValue) {
            const oldBandName = oldValue + this.bandSuffix
            if(oldValue === null || this.formData.newBandName === oldBandName) {
                this.formData.newBandName = newValue + this.bandSuffix
            }
        },
        showModal(opened) {
            globalEventBus.$emit('supressDeleteAction', opened)
            if(opened) {
                this.formData = {
                    thresholds: this.thresholds,
                    band: this.band,
                    newBandName: this.newBandName
                }
            }
        }
    },
    methods: {
        handleClose() {
            this.blockyEventCallback('modal-close');
        },
        handleSave() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        },
        getFieldValue() {
            return {
                thresholds: this.formData.thresholds,
                band: this.formData.band,
                newBandName: this.formData.newBandName
            };
        },
        removeThreshold(row) {
            this.formData.thresholds = this.formData.thresholds.filter(threshold => threshold.id !== row.id)
        },
        addNewThreshold(insertData) {
            const newThresholds = []
            for (let i=0; i<this.formData.thresholds.length; i++) {
                const currentRow = this.formData.thresholds[i]
                const nextId = this.nextRowId()
                if(currentRow.id === insertData.after) {
                    let nextMaxThreshold = insertData.maxThreshold
                    if(nextMaxThreshold < currentRow.maxThreshold) {
                        const tmpThreshold = currentRow.maxThreshold
                        currentRow.maxThreshold = nextMaxThreshold
                        nextMaxThreshold = tmpThreshold
                    }
                    newThresholds.push(currentRow)

                    newThresholds.push({
                        id: nextId,
                        value: insertData.value,
                        color: insertData.color,
                        maxThreshold: nextMaxThreshold,
                        description: 'Class ' + (nextId + 1)
                    })
                } else {
                    newThresholds.push(currentRow)
                }
            }
            let valueIndex = -1
            this.formData.thresholds = newThresholds.map(row => {
                row.value = ++valueIndex
                return row
            })
        },
        nextRowId() {
            return Math.max(...this.formData.thresholds.map(row => row.id)) + 1
        }
    }
}
</script>