<template>
    <div>
        <h1>
        Users
        <md-button @click="bulkAddUsers" class="md-fab md-mini md-accent">
            <md-icon>post_add</md-icon>
            <md-tooltip md-direction="top">Add from spreadsheet</md-tooltip>
        </md-button>
          <md-button id="ebx-add-user-button" @click="addUser" class="md-fab md-mini md-accent">
            <md-icon>add</md-icon>
            <md-tooltip md-direction="top">Add a user</md-tooltip>
        </md-button>     
        </h1>

        <div v-if="loading && !hasUsers">Loading...</div>

        <md-list class="md-double-line">
            <md-list-item v-for="user in pagedUsers" :key="user.uid">
                <md-icon class="md-primary">person</md-icon>

                <div class="md-list-item-text">
                <span>{{ user.displayName }} {{ user.email }}</span>
                <span v-if="isSuperAdmin || isCustomerSupport">{{ user.uid }}</span>
                <span v-if="isSuperAdmin || isCustomerSupport">{{ user.orgId }}</span>
                <span v-if="isSuperAdmin || isCustomerSupport">Email verified: {{ user.emailVerified ? "yes" : "no" }}</span>
                <span v-if="isSuperAdmin || isCustomerSupport">User Doc Exists: {{ user.userDocExists ? "yes" : "no" }}</span>
                <span v-if="isSuperAdmin || isCustomerSupport">Subscription Claim: {{ user.activeSubscription ? "yes" : "no" }}</span>
                </div>
                <md-chip v-for="role in user.roles" :key="role">{{ remapRole(role) }}</md-chip>

                <div>
                <md-button :id="user.displayName" class="md-icon-button md-list-action" @click="editUser(user)" >
                <md-icon>edit</md-icon>
                </md-button>
                </div>
            </md-list-item>
        </md-list>

        <div class="text-center mb-4">
            <md-button v-if="hasNextPage" @click="getUsers" class="md-raised md-primary" :disabled="loading">
                <template v-if="loading">
                    Loading
                </template>
                <template v-else>
                    Load More
                </template>
            </md-button>
        </div>
        
        <UserListAddEditUser ref="addUserDialog" :groups="groups || []" @user-save="onUserAdd" :isEdit="false"/>
        <UserListAddEditUser ref="editUserDialog" :groups="groups || []" @user-save="onUserEdit" @user-delete="onUserDelete" :isEdit="true"/>
        <UserListBulkAddUsers ref="bulkAddUsersDialog" @dialog-closed="onBulkDialogClosed" />

        <md-snackbar
        :md-position="'center'"
        :md-duration="10000"
        v-model:md-active="showSnackbar"
        md-persistent
        >
        <span>{{ message }}</span>
        </md-snackbar>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { functions } from "../firebaseFunctions.js";
import { AuthService } from "../services/auth.service";
import UserListAddEditUser from "@/components/UserListAddEditUser.vue";
import UserListBulkAddUsers from "@/components/UserListBulkAddUsers.vue";
import authMixin from '@/components/mixins/authMixin.js';
import organisationsMixin from './mixins/organisationsMixin';

export default {
    name: "UserList",
    mixins: [authMixin, organisationsMixin],
    components: {
        UserListAddEditUser,
        UserListBulkAddUsers
    },
    data: () => ({
        users: null,
        showSnackbar: false,
        message: "",
        groups: null,
        page: 0,
        perPage: 150,
        pageToken: null,
        loading: false
        
    }),
    computed: {
        pagedUsers() {
            if (!this.usersArePaginated) {
                return this.users
            }
            return this.users === null ? [] : this.users.slice(0, (this.page) * this.perPage)
        },
        hasNextPage() {
            return this.pageToken !== null
        },
        hasUsers() {
            return this.users !== null && this.users.length > 0
        },
        usersArePaginated() {
            return this.isSuperAdmin
        }
    },
    methods: {
        remapRole(role) {
            if(Array.isArray(this.groups) && (role.startsWith('group:admin:') || role.startsWith('group:user'))) {
                const id = role.split(':')[2]
                for(let i = 0; i < this.groups.length; i++) {
                    if(this.groups[i].id === id) {
                        const type = role.startsWith('group:admin') ? 'Admin' : 'User' 
                        return `Group ${type}: ${this.groups[i].name}`
                    }
                }
            } 
            return role
        },
        async getUsers() {
            try {
                this.loading = true
                let dataRequest = {}
                if(this.usersArePaginated) {
                    dataRequest = {
                        perPage: this.perPage,
                        pageToken: this.pageToken
                    }
                }
                const response = (await functions.getAllUsers(dataRequest)).data
                if (this.users === null) {
                    this.users = response.users
                }else {
                    this.users = this.users.concat(response.users)
                }
                if (this.usersArePaginated) {
                    this.page = this.page + 1
                    this.pageToken = response.nextPageToken
                }
            } finally {
                this.loading = false
            } 
        },
        addUser() {
            this.$refs.addUserDialog.showDialog();
        },
        editUser(user) {
            this.$refs.editUserDialog.showDialog(user);
        },
        bulkAddUsers() {
            this.$refs.bulkAddUsersDialog.showDialog();
        },
        onUserAdd(user) {
            user.sendEmail = true;
            functions
                .addUser(user)
                .then(() => {
                    this.resetUsers();
                    this.getUsers();
                    this.message = `User '${user.email}' successfully added`;
                    this.showSnackbar = true;
                }, (error) => {
                    console.error(error);
                    this.message = `Could not add the user. Error occured: '${error}'`;
                    this.showSnackbar = true;
                });
        },
        onUserEdit(user) {
            functions
                .updateUser(user)
                .then(() => {
                    this.resetUsers();
                    this.getUsers();
                    this.refreshUserToken(user)
                    this.message = `User '${user.email}' successfully updated`;
                    this.showSnackbar = true;
                }, (error) => {
                    console.error(error);
                    this.message = `Could not update the user. Error occured: '${error}'`;
                    this.showSnackbar = true;
                });
        },
        onUserDelete(user) {
            functions
                .deleteUser(user)
                .then(() => {
                    this.resetUsers();
                    this.getUsers();
                    this.message = `User '${user.email}' successfully deleted`;
                    this.showSnackbar = true;
                }, (error) => {
                    console.error(error);
                    this.message = `Could not update the user. Error occured: '${error}'`;
                    this.showSnackbar = true;
                })
        },
        onBulkDialogClosed() {
            this.users = null
            this.getUsers();
        },
        async getGroups(force = false) { 
            this.groups = await this.loadOrgGroups(force)
        },
        resetUsers() {
            this.users = null
            this.page = 0
            this.pageToken = null
        },
        refreshUserToken(user) {
            if(this.user.uid === user.uid) {
                AuthService.refreshCurrentUser(user)
            }
            
        }
    },
    mounted: function() {
        this.subscription = AuthService.loggedUser$.subscribe(user => {
            this.authenticatedUser = user;
        });
        this.resetUsers();
        this.getUsers();
        this.getGroups();
    },
    beforeUnmount() {
        this.subscription.unsubscribe();
    }
};
</script>

<style scoped lang="scss">
    .md-fab {
        float: right;
        margin-top: -9px;
    }
</style>