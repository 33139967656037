/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["MODIFIER_UNMASK_BLOCK_TITLE"] = "Unmask part of image     ";
Blockly.Msg["MODIFIER_UNMASK_BAND_LABEL"] = "Band ";
Blockly.Msg["MODIFIER_UNMASK_VALUE_LABEL"] = "Set value to ";

export default Blockly.Msg;