/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["ANALYSIS_INDICES_BLOCK_TITLE"] = "Calculate index";
Blockly.Msg["ANALYSIS_INDICES_CATEGORY_LABEL"] = "Category ";
Blockly.Msg["ANALYSIS_INDICES_INDEX_LABEL"] = "Index ";

export default Blockly.Msg;
