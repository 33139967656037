/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["MODIFIER_CLOUD_MASK_BLOCK_TITLE"] = "Mask out clouds";
Blockly.Msg["MODIFIER_CLOUD_MASK_METHOD_LABEL"] = "Method";

export default Blockly.Msg;