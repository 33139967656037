<template>
    <div ref="saveButton" class="google-map-ebx-rounded-action save-button">
        <md-button title="Save" class="md-icon-button md-raised" @click="getCanvas">
            <md-progress-spinner v-if="loading" md-mode="indeterminate" class="static-loading-spinner md-accent" :md-diameter="18" :md-stroke="2" />               
            <md-icon v-else>photo_camera</md-icon>
        </md-button>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import html2canvas from "html2canvas";

export default {
    props: {
        google: {
            type: Object,
            required: true
        },
        map: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false
        }
    },
    mounted() {
        let saveButton = this.$refs.saveButton;

        this.map.controls[this.google.maps.ControlPosition.RIGHT_TOP].push(
            saveButton
        );
    },
    methods: {
        getCanvas(){
            if(this.loading){
                return
            }
            //TODO: work out if we can access the maps data (in the maps prop) as an element
            const mapContainer = document.getElementById("googleMap");

            // this width/height is rounded up
            let mapWidth = mapContainer.offsetWidth
            let mapHeight = mapContainer.offsetHeight

            this.loading = true

            html2canvas(mapContainer, {
                useCORS: true,
                allowTaint: true,
				scale: 2,
                backgroundColor: null,
                logging: true,
                width: mapWidth-1, // width gets rounded so we subtract one to ensure no empty pixels either side
                height: mapHeight,
                scrollY: 123, // without scrolling up the canvas gets an extra 248 pixels (rounded) below which become null pixels,
                onclone(document) {
                    // loops through all elements on google map and hides them for the screenshot                 
                    function hideMapElements(){
                        Array.prototype.forEach.call(document.getElementsByClassName('basemap-dropdown'), function(element) {
                            element.style.display = 'none';
                        });

                        Array.prototype.forEach.call(document.getElementsByClassName('gmnoprint'), function(element) {
                            element.style.display = 'none';
                        });

                        Array.prototype.forEach.call(document.getElementsByClassName('extra-modes'), function(element) {
                            element.style.display = 'none';
                        });
                        
                        Array.prototype.forEach.call(document.getElementsByClassName('gm-fullscreen-control'), function(element) {
                            element.style.display = 'none';
                        });
                    }

                    hideMapElements();

                    let cloneMapContainer = document.getElementById("googleMap");

                    //display legend if it exists
                    // let legend = document.getElementById("legendContainer")
                    // legend.style.display = "block";

                    cloneMapContainer.style.width = mapWidth + "px";
                    cloneMapContainer.style.height = mapHeight + "px";
                },
                }).then(function(canvas) {
                    // function drawNorthArrow(context, fromx, fromy, tox, toy){
                    //     var headlen = 10; // length of head in pixels
                    //     var dx = tox - fromx;
                    //     var dy = toy - fromy;
                    //     var angle = Math.atan2(dy, dx);
                    //     context.moveTo(fromx, fromy);
                    //     context.lineTo(tox, toy);
                    //     context.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
                    //     context.moveTo(tox, toy);
                    //     context.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
                    // }

                    
                    // let midpoint = canvas.width / 2;

                    // ctx.beginPath();
                    // drawNorthArrow(ctx, 300, 100, 700, 800);
                    // drawNorthArrow(ctx, 10, 30, 200, 150);
                    // drawNorthArrow(ctx, 100, 200, 400, 50);
                    // drawNorthArrow(ctx, 200, 30, 10, 150);
                    // drawNorthArrow(ctx, 400, 200, 100, 50);
                    // ctx.stroke();
                    
                    // let ctx = canvas.getContext("2d");

                    // let image = new Image();
                    // image.src = "@/assets/EBLogo.png"; // can not load this asset
                    // image.onload = function() { // will never get called
                    //     ctx.drawImage(this, 900, 900);
                    //     let dataUrl= canvas.toDataURL("image/png");
                    //     let link = document.createElement("a");
                    //     link.download = "EarthBloxImage.png";
                    //     link.href = dataUrl;
                    //     document.body.appendChild(link);
                    //     link.click();
                    //     document.body.removeChild(link);
                    // }
                    // image.onerror = function(e) { // always errors
                    //     console.log(e)
                    //     console.log("Error")
                    // } 

                    let dataUrl= canvas.toDataURL("image/png");
                    let link = document.createElement("a");
                    link.download = "EarthBloxImage.png";
                    link.href = dataUrl;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).finally(() => {
                    this.loading = false
                })
        },
    }
}
</script>