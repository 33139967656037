/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["MODIFIER_REASSIGN_CLASSES_BLOCK_TITLE"] = "Re-assign classes  ";

export default Blockly.Msg;