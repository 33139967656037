/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

export const COLOR_POINT = "rgb(243,114,114)"
export const COLOR_POINT_FILL = "rgb(255,255,255)"
export const COLOR_LANDSCAPE = "#FFAD00"
export const COLOR_BORDERS = "rgb(195,230,255)"
export const COLOR_LINE = "rgb(255,255,255)"
export const COLOR_SELECTED_POINT = "rgb(0,184,83)"

export const COLOR_BROWN = "rgb(139,69,19)"
export const COLOR_BLACK = "rgb(0,0,0)"
export const COLOR_BLUE = "rgb(77,109,155)"
export const COLOR_LIGHT_BLUE = "rgb(124,156,201)"
export const COLOR_WHITE = "rgb(255,255,255)"
export const COLOR_WHITEY = "rgb(240,240,240)"
export const COLOR_GREEN = "rgb(0,155,77)"
export const COLOR_TOMATO = "rgb(243,114,114)"