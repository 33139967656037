
import { RxEvents } from './events';
import { Subject } from 'rxjs';
import {VARIABLE_PROVIDERS} from '../../constants/nextGenConstants';

/**
 * @class ProjectVariablesProvider
 * This class is intended to be used as a provider for variables that are specific to a project.
 * The project will be the saved workflows within the firestore document.
 * 
 * The provider will trigger an update event when a variable is added/updated/deleted
 * 
 * To add to the service use the addProvider method on the VariablesService
 * ```
 * import { VariablesService }
 * import { ProjectVariablesProvider }
 * 
 * const projectVariables = new ProjectVariablesProvider()
 * VariablesService.addProvider(projectVariables)
 * ```
 */
export class ProjectVariablesProvider extends RxEvents {
  constructor() {
    super()
    this.variables = [];
    this.variableService = null;
    this.scope = VARIABLE_PROVIDERS.PROJECT.id; // 'project'
    this.title = VARIABLE_PROVIDERS.PROJECT.title; // 'Project Variables'
    this.events = {
      update: new Subject(),
      updatedValidateMessages: new Subject(),
      updatedExtraState: new Subject(),
      removed: new Subject(),
    }
  }

  /**
   * Returns the title of the provider
   * @returns {String} the title of the provider
   */
  getTitle() {
    return this.title;
  }
  /**
   * Returns the scope of the provider
   * @returns {String} the scope of the provider
   */
  getScope() {
    return this.scope;
  } 

  /**
   * Returns an array of variable instances
   * @returns {Array} array of variables
   */
  getVariables() {
    return this.variables;
  }

  /**
   * Returns a variable by its id
   * @param {String} id 
   * @returns 
   */
  getVariableById(id) {
    return this.variables.find(variable => variable.getId() === id)
  }

  /**
   * Clears all variables from the provider
   * @returns 
   */
  clearVariables() {
    this.variables = [];
    this.events.update.next(this.variables);
    return this
  }

  /**
   * Sets the variable service on the provider
   * @param {*} variableService 
   * @returns 
   */
  setVariableService(variableService) {
    this.variableService = variableService;
    return this
  }

  /**
   * Removes a variable by its id
   * @param {String} id 
   * @returns 
   */
  removeVariable(id) {
    const variableToRemove = this.getVariableById(id)
    this.variables = this.variables.filter(variable => variable !== variableToRemove)
    this.events.update.next(this.variables);
    this.events.removed.next(variableToRemove);
    return this
  }

  /**
   * Returns an array of variables with the data needed to save for later reloading.
   * This is used to save the variables data in to a saved workflow on the firestore document
   * @returns Array of variables
   */
  toJSONForSave() {
    return this.variables.map(variable => variable.toJSONForSave())
  }

  /**
   * Loads the variables from a json object that was saved in a firestore document
   * @param {Array} json
   * @param {Boolean} silent
   * @returns 
   */
  fromJSONForSave(json, silent = false) {
    json.forEach(variable => {
      this
        // add the variable to the provider silently
        .addVariable(variable.type, variable.id, variable.title, variable.description, variable.value, true)
        // rehydrate the variable from the json object
        this
          .getVariableById(variable.id)
          .fromJSONSave(variable)
          .validate()
    })
    // trigger the update event
    if(silent === false) {
      this.events.update.next(this.variables);
    }
  }

  /**
   * Creates a new variable and adds it to the provider using the constructor provided
   * @param {*} constructor 
   * @param {*} id 
   * @param {*} title 
   * @param {*} description 
   * @param {*} value 
   * @param {*} silent 
   * @returns 
   */
  addVariable(constructor, id, title, description, value, silent = false) {
    const serviceVariables = this.variableService.getVariableTypes().reduce((acc, variableType) => {
      acc[variableType.type] = variableType
      return acc
    },{})

    if (typeof constructor === 'string' && serviceVariables[constructor]) {
      constructor = serviceVariables[constructor].constructor
    }
    if (!serviceVariables[constructor.TYPE]) {
        throw new Error(`Variable constructor with type ${constructor.TYPE} not found`)
    }

    // If the value is undefined set it to null
    if(value === undefined) {
      value = null
    }

    // Create a new instance of the constructor
    const variable = new constructor(id, title, description, value);
    // Set the scope so we know where the variable lives
    variable.setScope(this.scope);

    // on variable update trigger the update event for the provider
    const self = this;
    variable.addEventListener('update', (variable) => {
      self.events.update.next(variable)
    })
    variable.addEventListener('updatedValidateMessages', (variable) => {
      self.events.updatedValidateMessages.next(variable)
    })
    variable.addEventListener('updatedExtraState', (variable) => {
      self.events.updatedExtraState.next(variable)
    })
    
    // add the variable to the provider
    this.variables.push(variable);

    // trigger the update event
    if (silent === false) {
      this.events.update.next(variable);
    } 
    return variable
  }

}

