/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import { AbstractBlock, AbstractFieldHelpers } from './_abstractBlock';
import { ContentService } from '@/services/content.service';

var detectChangeJson ={
    "type": "analysis_detect_change",
    "lastDummyAlign0": "RIGHT",
    "message0": "%1 %2 %3 %4 %5 %6 %7 %8 %9 %10 %11 %12 %13",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "change_title",
            "text": "%{BKY_ANALYSIS_DETECT_CHANGE_BLOCK_TITLE}",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "before_label",
            "text": "%{BKY_ANALYSIS_DETECT_CHANGE_BEFORE_LABEL}"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_statement",
            "name": "before_datasets"
        },
        {
            "type": "field_label_serializable",
            "name": "after_label",
            "text": "%{BKY_ANALYSIS_DETECT_CHANGE_AFTER_LABEL}"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_statement",
            "name": "after_datasets"
        },
        {
            "type": "field_label_serializable",
            "name": "output_label",
            "text": "%{BKY_ANALYSIS_DETECT_CHANGE_OUTPUT_LABEL}"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_statement",
            "name": "outputs"
        },
        {
            "type": "field_label_serializable",
            "name": "run_button_label",
            "text": "Run"
        },
        {
            "type": "field_play_button",
            "name": "run_button_field",
        }
    ],
    "style": "analyse",
    "tooltip": "",
    "helpUrl": ""
}


Blockly.Blocks['analysis_detect_change'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,

    onInit: function() {
        this.jsonInit(detectChangeJson);
        this.about_block_url = ContentService.getAboutBlockUrl('analysis_detect_change')
    },

    accept: async function (visitor) {
        await visitor.visitDetectChangeBlock(this);
    },
}; 
