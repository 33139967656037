<template>
    <div class="asset-card--item" :class="{'asset-card--item__no-action': !canShowMenu, 'asset-card--item__action': canShowMenu}">
        <div class="asset-card--item-body"  @click.prevent="handleClick">
            <div class="asset-card--item-title">
                <p class="ebx-primary-bold">{{ content.name || content.id }} </p>
                <div v-if="projectIsOrgTemplate" class="ebx-pill ebx-pill--pine ebx-tertiary">Template</div>
                <div v-if="projectIsGlobalTemplate" class="ebx-pill ebx-pill--pine ebx-tertiary">Earth Blox Template</div>
            </div>
        </div>
        <div v-if="canShowMenu" class="asset-card--item-actions">
            <md-menu md-direction="bottom-start">
                <md-button class="md-icon-button" md-menu-trigger>
                    <md-icon>more_vert</md-icon>
                </md-button>
                <md-menu-content class="project-options-menu">
                    <md-menu-item class="project-options-menu--option" v-if="canEdit" @click="$emit('edit', content)">
                        Edit name and description
                    </md-menu-item>
                    <md-menu-item class="project-options-menu--option" v-if="canShareWorkflows && !projectIsGlobalTemplate && projectIsPrivate" @click="$emit('move-to-team', content)">
                        Move to team
                    </md-menu-item>
                    <md-menu-item class="project-options-menu--option" v-if="userVariablesEnabled && canShareWorkflows && !projectIsOrgTemplate" @click="$emit('move-to-published', content)">
                        Publish as template
                    </md-menu-item>
                    <md-menu-item class="project-options-menu--option" v-if="userVariablesEnabled && canShareWorkflows && projectIsOrgTemplate && (isAdmin || isOwnerOfProject)" @click="$emit('unpublish-org-template', content)">
                        Unpublish as template
                    </md-menu-item>
                    <md-menu-item class="project-options-menu--option" v-if="isSuperAdmin && projectIsGlobalTemplate === false" @click="$emit('move-to-global-templates', content)">
                        Publish as Earth Blox template
                    </md-menu-item>
                    <md-menu-item class="project-options-menu--option" v-if="isSuperAdmin && projectIsGlobalTemplate" @click="$emit('unpublish-global-template', content)">
                        Unpublish Earth Blox template
                    </md-menu-item>
                    
                    <md-menu-item class="project-options-menu--option" v-if="canMakeCopy" @click="$emit('duplicate', content)">
                        Make a copy
                    </md-menu-item>
                    <md-menu-item class="project-options-menu--option" v-if="canEditApiAccess" @click="$emit('api-access', content)">
                        API access
                    </md-menu-item>
                    <md-menu-item class="project-options-menu--option" v-if="canEdit" @click="$emit('delete', content)"> 
                        Delete
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
        </div>

    </div>
    
</template>

<script>
import { PROJECT_MODE } from '../../mixins/projectMixin';
import authMixin from '@/components/mixins/authMixin'

export default {
    mixins: [authMixin],
    data() {
        return {
            showModal: false,
        }
    },
    props: {
        content: {
            type: Object,
            required: true
        },
        canEdit: {
            type: Boolean,
            required: true
        },
        canMakeCopy: {
            type: Boolean,
            required: true
        },
        canEditApiAccess: {
            type: Boolean,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        },
        canShareWorkflows: {
            type: Boolean,
            required: true
        },
        projectIsGlobalTemplate: {
            type: Boolean,
            required: true
        },
        projectIsOrgTemplate: {
            type: Boolean,
            required: true
        },
        projectIsPrivate: {
            type: Boolean,
            required: true
        }
    },
    emits: [
        'edit',
        'move-to-team',
        'move-to-published',
        'unpublish-org-template',
        'move-to-global-templates',
        'unpublish-global-template',
        'duplicate',
        'api-access',
        'delete',
        'load'
    ],
    computed: {
        canShowMenu() {
            return this.canEdit || this.canMakeCopy || this.canEditApiAccess || (this.canShareWorkflows && !this.projectIsTemplateType && this.projectIsPrivate)
        },
        isSelected:{
            get() {
                return this.selected ? this.content.id : false
            },
            set() {
                this.handleClick()
            }
        },
        hasThumbnail() {
            return this.content.thumbnail !== undefined && this.content.thumbnail !== '' && this.content.thumbnail !== null
        }
    },
    methods: {
        handleClick() {
            this.$emit('load', this.content, this.content.mode || PROJECT_MODE.READONLY)
        },
        isOwnerOfProject() {
            return this.content.uid === this.user.id
        }
    }
}
</script>
