/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["ANALYSIS_DETECT_CHANGE_BLOCK_TITLE"] = "Detect Change";
Blockly.Msg["ANALYSIS_DETECT_CHANGE_BEFORE_LABEL"] = "Before";
Blockly.Msg["ANALYSIS_DETECT_CHANGE_AFTER_LABEL"] = "After";
Blockly.Msg["ANALYSIS_DETECT_CHANGE_OUTPUT_LABEL"] = "Output";

export default Blockly.Msg;