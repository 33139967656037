
export class AbstractCollection {
    abilities = []
    constructor(abilities) {
        this.abilities = abilities
    }

    get length() {
        return this.abilities.length
    }

    flatMap(callable) {
        return this.abilities.flatMap(callable)
    }
    map(callable) {
        return this.abilities.map(callable)
    }
    filter(callable) {
        return this.abilities.filter(callable)
    }
    forEach(callable) {
        return this.abilities.forEach(callable)
    }

    concat(newAbilities) {
        return this.abilities.concat(newAbilities)
    }

    toArray() {
        return this.abilities
    }

    first() {
        return this.abilities.length > 0 ? this.abilities[0] : null
    }

    last() {
        return  this.abilities.length > 0 ? this.abilities[this.abilities.length - 1] : null
    }

    currentAbilities() {
        return this.abilities.filter(ability => {
            return ability.workflowState.registry.traversedBlocks.indexOf(ability.associatedBlockId) >=0
        })
    }
    current() {
        const currentAbilities = this.currentAbilities()
        if(currentAbilities.length > 0) {
            return currentAbilities[currentAbilities.length -1]
        }
        return null
    }
    before(blockID) {
        return this.abilities.filter(ability => {
            return ability.workflowState.registry.traversedBlocks.indexOf(ability.associatedBlockId) < ability.workflowState.registry.traversedBlocks.indexOf(blockID)
        })
    }

}