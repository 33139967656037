<template>
    <md-table-row>
        <md-table-cell>
            <md-field>
                <md-select v-model="selectedDataset">
                    <md-option
                        v-for="option in datasetOptions"
                        :key="option.id"
                        :value="option.id"
                        >
                        {{ option.name }}
                    </md-option>
                </md-select>
            </md-field>
        </md-table-cell>
        <md-table-cell>
            <TableDateSelect 
                :options="datasetDates"
                :display-text="tranformedDateText"
                v-model="selectedDates"
            >
                <template v-slot:after="data">
                    <template v-if="selectedMultipleDates">
                        <md-divider/>
                        <li>
                            <button type="button" class="md-button ebx-action-text ebx-action-text--primary ebx-action-text__icon md-theme-default" @click.prevent="handleLoadTransformDialog('dates', data)">
                                <div class="md-button-content">
                                    <span class="material-icons-outlined ebx-icon">mode_edit</span>
                                    <template v-if="hasDateTransform">
                                        Edit Aggregation
                                    </template>
                                    <template v-else>
                                        Aggregate
                                    </template>
                                </div>
                            </button>
                        </li>
                    </template>
                </template>
                
            </TableDateSelect>
        </md-table-cell>
        <md-table-cell>
            <TableMultiSelect 
                :options="datasetProperties"
                :allName="'All bands'"
                :display-text="selectedBandsDisplayText"
                v-model="selectedProperties"
                ref="propertySelector"
                class="bands"
            >
                <template v-slot:after="data">
                    <div v-if="selectedMultipleProperties" class="md-multi-select--dropdown">
                        <md-divider/>
                        <li>
                            <button type="button" class="md-button ebx-action-text ebx-action-text--primary ebx-action-text__icon md-theme-default" @click.prevent="handleLoadTransformDialog('properties', data)">
                                <div class="md-button-content">
                                    <span class="material-icons-outlined ebx-icon">mode_edit</span>
                                    <template v-if="hasPropertiesTransform">
                                        Edit Aggregation
                                    </template>
                                    <template v-else>
                                        Aggregate
                                    </template>
                                </div>
                            </button>
                        </li>
                    </div>
                </template>
            </TableMultiSelect>
        </md-table-cell>
        <md-table-cell>
            <md-field>
                <md-input v-model="userVariableName"  @keyup.enter.prevent="$event.target.blur()" @blur="handleUserUpdatedVariable"  />
            </md-field>
        </md-table-cell>
        <md-table-cell>
            <md-button
                class="md-icon-button"
                @click="$emit('choose-variable', row.id, variableName)"
                >
                <md-icon>add_box</md-icon>
            </md-button>
            <md-button
                class="md-icon-button"
                @click="$emit('delete-row', row.id, variableName)"
                >
                <md-icon>delete</md-icon>
            </md-button>
            
        </md-table-cell>
    </md-table-row>
</template>

<script>
import tableCreatorRowMixin from '../../mixins/tableCreatorRowMixin'
import TableMultiSelect from '../../TableCreator/TableMultiSelect.vue'
import TableDateSelect from '../../TableCreator/TableDateSelect.vue';
import { snakeCase, find, keyBy } from 'lodash'
export default {
    mixins: [
        tableCreatorRowMixin
    ],
    components: {
        TableMultiSelect,
        TableDateSelect
    },
    props: {
        existingVariables: {
            type: Array,
            required: true
        }
    },
    emits: [
        'choose-variable',
        'delete-row',
        'update-row',
        'change-variable'
    ],
    data() {
        return {
            userVariableName: this.row.variableName,
            selected: Object.assign({}, this.row)
        }
    },
    computed: {
        tranformedDateText() {
            if(this.hasDateTransform) {
                const count = this.selected.dates ? this.selected.dates.length : 0
                if(count === this.datasetDates.length) {
                    return this.selected.datesTransform + ' of all dates'
                }
                return this.selected.datesTransform + ' of [' + count + '] dates'
            }
            return null
        },
        selectedDataset: {
            get() {
                return this.selected.dataset
            },
            set(value) {
                this.selected.dataset = value
                const dataset = find(this.datasets, {id: value})
                if(dataset) {
                    this.selected.cadence = dataset.multi_image_temporal.cadence
                }
                this.$emit('update-row', this.selected)
            }
        },
        selectedDates: {
            get() {
                return this.selected.dates
            },
            set(value) {
                this.selected.dates = value
                this.$emit('update-row', this.selected)
            }
        },
        selectedProperties: {
            get() {
                return this.selected.properties
            },
            set(value) {
                this.selected.properties = value
                this.$emit('update-row', this.selected)
            }
        },
        variableName() {
            return this.row.variableName
        }
    },
    methods: {
        handleUserUpdatedVariable() {
            this.userVariableName = snakeCase(this.userVariableName.trim()).toUpperCase()
            if(this.userVariableName.length <=0 || this.existingVariables.indexOf(this.userVariableName ) >= 0) {
                this.userVariableName = this.row.variableName
            } else if(this.row.variableName !== this.userVariableName) {
                this.$emit('change-variable', this.row.id, this.userVariableName, this.row.variableName, true)
            }
        },
        computeAndUpdateVariableName() {
            if(this.row.userChangedVariableName) {
                return
            }
            const varNameParts = ['VAR_' + this.row.id]
            const dateMap = keyBy(this.datasetDates,'id')
            const bandMap = keyBy(this.datasetProperties,'id')

            const displayDates = this.selected.dates.filter(id => dateMap[id] !== undefined).map(id => dateMap[id].name)
            const displayBands = this.selected.properties.filter(id => bandMap[id] !== undefined).map(id => bandMap[id].name)

            if(displayDates.length >=1) {
                varNameParts.push(snakeCase(displayDates[0]))
            }
            if(displayDates.length > 1) {
                varNameParts.push(snakeCase(displayDates[displayDates.length - 1]))
            }

            if(displayBands.length >=1) {
                varNameParts.push(snakeCase(displayBands[0]))
            }
            if(displayBands.length > 1) {
                varNameParts.push(snakeCase(displayBands[displayBands.length - 1]))
            }
            this.userVariableName = varNameParts.join('_').toUpperCase()
            this.$emit('change-variable', this.row.id, this.userVariableName, this.row.variableName, false)
        }
    },
    watch: {
        row: {
            deep: true,
            handler(row) { 
                if(this.selected.datesTransform !== row.datesTransform) {
                    this.selected.datesTransform = row.datesTransform
                }
                if(this.selected.propertiesTransform !== row.propertiesTransform) {
                    this.selected.propertiesTransform = row.propertiesTransform
                }
            }
        },
        selectedDataset(current, previous) {
            if(previous === null && current !== previous) {
                this.$nextTick(() => {
                    this.$refs.propertySelector.updateSelection(this.datasetProperties.map(d => d.id))
                })
            }
        },
        selectedDates: {
            handler: function() {
                this.computeAndUpdateVariableName();
            },
            deep: true 
        },

        selectedProperties: {
            handler: function() {
                this.computeAndUpdateVariableName();
            },
            deep: true 
        },
    
    }
}
</script>