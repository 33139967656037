<template>
    <div>
    <md-dialog v-model:md-active="computedModalShown" class="ebx-dialog modal-l reassign-classes-modal" :md-click-outside-to-close="false" :md-close-on-esc="false">
        <md-dialog-title>
            <div class="top-actions--container">
                Re-assign classes
                <div class="close-button">
                    <md-button class="md-primary" @click.prevent="handleClose"><md-icon>close</md-icon></md-button>
                </div>
            </div>
        </md-dialog-title>
        <md-dialog-content>
            <div class="reassign-classes-modal--band-detail">
                <div>
                    <md-field>
                        <label for="band">Use band</label>
                        <md-select :value="formData.band" name="band" id="band" @md-selected="handleChangedBand" ref="bandSelect">
                            <md-option v-for="band in bandOptions" :key="band.value" :value="band.value">
                                {{ band.label }}
                            </md-option>
                        </md-select>
                    </md-field> 
                </div>
                <div>
                    <md-field :class="messageClass">
                        <label for="band">New band name</label>
                        <md-input v-model="formData.newBandName" name="newBandName" id="newBandName"></md-input>
                        <div class="md-error" v-if="!isNameValid">{{errorMessage}}</div>
                    </md-field> 
                </div>
            
            </div>

            <div>
                <h3 class="md-title md-title-2 my-4">New classes</h3>

                <div>
                    <div v-for="cls in formData.newClasses" :key="cls.uuid">
                        <NewClass 
                            :cls="cls" 
                            :editing="isEditingClass && cls === editingClass" 
                            :band-classes="selectedBandClasses"
                            :all-new-classes="formData.newClasses"
                            @save="handleSaveNewClass" 
                            @close="handleCloseNewClass"
                            @move="handleMove" 
                            @edit="handleEditClass"
                            @remove="handleRemoveClass"
                        />
                    </div>
                </div>

                <div class="ebx-reassign-new-class-button">
                    <EBXButton theme="tertiary" @click="createNewClass()" icon="add" :disabled="!hasSelectedBand">Create new class</EBXButton>
                </div>
            </div>

            <div>
                <h3 class="md-title md-title-2 my-4">Existing classes</h3>
                    
                    <md-list v-if="hasSelectedBand">
                        <draggable 
                        item-key="value"
                        :model-value="selectedBandClassesMinusSelected" 
                        draggable=".draggable" 
                        group="reassign_classes" 
                        handle=".handle" 
                        class="ebx-dragger--container"
                        >
                            <template #header>
                                <div v-if="selectedBandClassesMinusSelected.length ==0" class="ebx-dragger--no-options-message">
                                    Drag and drop existing classes
                                </div>
                            </template>
                            <template #item="{element}">
                                <ClassDragger 
                                    :key="element.value" 
                                    :cls="element" 
                                    :all-new-classes="allNewClasses" 
                                    @move="handleMove"
                                />
                            </template>
                            
                        </draggable>
                    </md-list>
                    <div v-else class="reassign-classes-modal--no-band-selected">
                        Select a band to re-assign existing classes
                    </div>
            </div>
            


        </md-dialog-content>
        <md-dialog-actions>
            <div class="md-layout-item validation-messages">
                <div class="md-error" v-if="hasDuplicateClassValues">You have duplicate class values. Please make them unique</div>
                
                
            </div>
            <div class="md-layout-item calculator--actions md-size-20">
                <EBXButton theme="secondary" @click="handleClose">Cancel</EBXButton>
                <EBXButton theme="primary" :disabled="!isValid" @click="handleSave">Save</EBXButton>
            </div>
        </md-dialog-actions>
    </md-dialog>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import { v4 as uuidv4 } from 'uuid';
import NewClass from '@/components/ReassignClassesModal/NewClass.vue'
import ClassDragger from "../components/ReassignClassesModal/ClassDragger.vue";
import EBXButton from "../components/EbxComponents/EbxButton.vue";
import { VALID_BANDNAME_REGEX, INVALID_BANDNAME_ERROR} from "@/constants/nextGenConstants";
import blocklyModalsMixin from './blocklyModalsMixin.js'

export default {
    name: 'ReassignClasses',
    mixins: [blocklyModalsMixin],
    components: {
        draggable,
        NewClass,
        ClassDragger,
        EBXButton
    },
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        band: {
            type: String,
            default: ''
        },
        newBandName: {
            type: String,
            default: ''
        },
        newClasses:{
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            bands: [],
            formData: {
                band: null,
                newBandName: '',
                newClasses: []
            },
            editingClassIndex: null, 
        }
    },
    computed: {
        bandOptions() {
            return this.bands
            .filter(band => band['ebx:datatype'] === 'thematic')
            .map(band => {
                return {
                    label: band['ebx:name'],
                    value: band['ebx:name']
                }
            })
        },
        allNewClasses() {
            return this.formData.newClasses
        },
        selectedBandDetails() {
            return this.bands.find(band => band['ebx:name'] === this.formData.band)
        },
        selectedBandClasses() {
            if(this.hasSelectedBand) {
                const geeClasses = this.selectedBandDetails['gee:classes']
                if (geeClasses) {
                    return Object.keys(geeClasses).map(key => {
                        return {
                            value: key,
                            name: geeClasses[key]['ebx:short_description'],
                            ...geeClasses[key],
                            color: geeClasses[key]['color'][0] === '#' ? geeClasses[key]['color'] : '#'+geeClasses[key]['color']
                        }
                    })
                }
            }
            return null
        },
        selectedBandClassesMinusSelected() {
            if (this.selectedBandClasses === null) {
                return []
            }
            return this.selectedBandClasses.filter(cls => {
                return this.formData.newClasses.some(nc => nc.classes.some(c => c === cls.value)) === false
            })
        },
        hasSelectedBand() {
            return this.selectedBandDetails !== undefined
        },
        editingClass() {
            if(this.isEditingClass) {
                return this.formData.newClasses[this.editingClassIndex]
            }
            return null
        },
        isEditingClass() {
            return this.editingClassIndex !== null
        },
        hasDuplicateClassValues() {
            if (this.formData.newClasses.length === 0) {
                return false
            }
            const values = this.formData.newClasses.map(cls => cls.value)
            return new Set(values).size !== values.length
        },
        hasNewClasses() {
            return this.formData.newClasses.length > 0
        },
        hasNewBandName() {
            return this.formData.newBandName !== ''
        },
        isValid() {
            return (
                this.hasDuplicateClassValues === false && 
                this.hasNewClasses && 
                this.hasNewBandName && 
                this.hasSelectedBand
            )
        }, 
        isNameValid() { 
            if(!this.hasSelectedBand && !this.hasNewBandName) {
                return true;
            }
            if (!this.hasSelectedBand && this.hasNewBandName) {
                return false
            }
            if (this.hasSelectedBand && !this.hasNewBandName) { 
                return false
            } 
            const test = VALID_BANDNAME_REGEX.test(this.formData.newBandName);

            if (!test) { 
                return false
            }

            return true;
        }, 
        errorMessage() {
            if (this.isNameValid) {
                return "";
            }
            if (!this.hasSelectedBand && this.hasNewBandName) {
                return "Select a band first"
            }
            if (this.hasSelectedBand && !this.hasNewBandName) { 
                return "New band name is required"
            } 
            const test = VALID_BANDNAME_REGEX.test(this.formData.newBandName);

            if (!test) { 
                return INVALID_BANDNAME_ERROR
            }

            return INVALID_BANDNAME_ERROR
        },
        messageClass() {
            return {
                "md-invalid": !this.isNameValid
            }
        }
    },
    methods: {
        getFieldValue() {
            return {
                band: this.formData.band,
                newBandName: this.formData.newBandName,
                newClasses: this.formData.newClasses
            }
        },
        async handleClose() {
            const message = 'Closing this will erase any new classes you’ve made. Are you sure you want to continue?'
            const oldChanged = JSON.stringify(this.newClasses)
            const newChanges = JSON.stringify(this.formData.newClasses)
            const confirmed = this.hasSelectedBand === false || this.hasNewClasses === false || oldChanged === newChanges ? true : await this.$confirmModal(message, { okButtonText: 'Continue' })
            if (confirmed) {
                this.blockyEventCallback('modal-close');
            }
        },
        handleSave() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        },
        createNewClass(value ='', description = '', color = '#000000', classes = []) {
            this.formData.newClasses.push({
                uuid: uuidv4(),
                value: value,
                description: description,
                color: color,
                classes: classes
            })
            this.editingClassIndex = this.formData.newClasses.length - 1
        },
        handleSaveNewClass(cls) {
            if (cls.uuid) {
                const newClasses = this.formData.newClasses.map(oldCls =>{
                    if(oldCls.uuid === cls.uuid) {
                        return cls
                    }
                    return oldCls
                })
                this.formData.newClasses = newClasses
            } else {
                this.formData.newClasses.push(cls)
            }
            this.editingClassIndex = null
        },
        handleCloseNewClass() {
            this.editingClassIndex = null
        },
        handleMove({moveTo, current, cls}) {
            if (moveTo === '__new__') {
                this.formData.newClasses = this.formData.newClasses.map(nc => {
                    if (nc.uuid === current) {
                        nc.classes = nc.classes.filter(c => c !== cls.value)
                    }
                    return nc
                })
                const newValue = this.formData.newClasses.length > 0 ? (Math.max(...this.formData.newClasses.map(c => parseInt(c.value))) + 1).toString() : '0'
                this.createNewClass(newValue, cls.name, cls.color, [cls.value])
                // do not make editable - comment off to leave editable
                // this.editingClassIndex = null
                return
            }
            this.formData.newClasses = this.formData.newClasses.map(nc => {
                if (nc.uuid === current) {
                    nc.classes = nc.classes.filter(c => c !== cls.value)
                }
                if (nc.uuid === moveTo) {
                    nc.classes.push(cls.value)
                }
                return nc
            })
        },
        handleEditClass(uuid) {
            this.editingClassIndex = this.formData.newClasses.findIndex(cls => cls.uuid === uuid)
        },
        handleRemoveClass(uuid) {
            this.formData.newClasses = this.formData.newClasses.filter(cls => cls.uuid !== uuid)
        },
        async handleChangedBand(bandName) {
            if (bandName === this.formData.band) {
                return
            }
            const message = 'Switching between bands will erase any new classes you’ve made. Are you sure you want to continue?'
            const confirmed = this.hasSelectedBand === false || this.hasNewClasses === false ? true : await this.$confirmModal(message, { okButtonText: 'Switch Bands' })
            if (confirmed) {
                this.formData.band = bandName
                this.formData.newBandName = bandName + '_re-assigned'
                this.formData.newClasses = []
            } else {
                this.$refs.bandSelect.setValue(this.changedData.band)
                
            }
        }
    },
    watch: {
        showModal(opened) {
            if(opened) {
                this.formData = {
                    band: this.band,
                    newBandName: this.newBandName,
                    newClasses: this.newClasses
                }
            }
        },
        'formData.band'(newBand, oldBandName) {
            if(newBand ==='' && oldBandName === null) {
                this.formData.newBandName = ''
                return
            }
            if (newBand === '' || newBand === null) {
                this.formData.newBandName = ''
                return 
            }
            this.formData.newBandName = newBand + '_re-assigned'
        }
    }
}
</script>