<template>
    <md-dialog :md-active="showDialog" @md-closed="showDialog = false" class="ebx-dialog dialog choose-area-dialog modal-ms">
        <md-dialog-title>
            <div class="top-actions--container">
                <p class="ebx-header-2 top-actions--title">
                    Add example area
                </p>
                <div class="close-button">
                    <md-button class="md-primary" @click="showDialog = false"><md-icon>close</md-icon></md-button>
                </div>
            </div>
        </md-dialog-title>
        <md-dialog-content class="dialog-content">
            <md-field>
                <label for="area">Select an area</label>
                <md-select v-model="selectedArea" name="area" id="area">
                    <md-option v-for="(area, index) in areasAndCollections()" :value="area[1]" :key="index">{{area[0]}}</md-option>
                </md-select>
            </md-field>
        </md-dialog-content>
        <md-dialog-actions>
            <button class="ebx-button ebx-button--secondary" @click="showDialog = false">
                Cancel
            </button>
            <button 
                class="ebx-button ebx-button--primary" 
                @click="chooseSelection" 
                >
                Okay
            </button>
        </md-dialog-actions>
    </md-dialog>
    
</template>

<script>
import { AreaService }  from '@/services/area.service';
import valueMixin from '@/components/mixins/valueMixin'

export default {
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
    },
    mixins: [valueMixin],
    emits: [
        'update:modelValue',
        'area-selected'
    ],
    data() {
        return {
            selectedArea: null
        }
    },
    computed: {
        showDialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
    },
    methods: {
        areasAndCollections() { 
            const areas = AreaService.getStudyAreas();
            const collections = AreaService.getCollections(); 
            const collectionsArray = collections.map(obj => [obj.name, obj.id]);
            if (areas[0][0] === "No area selected") {
                return collectionsArray
            } else if (collectionsArray.length === 0) {
                return areas
            } else { 
                return areas.concat(collectionsArray)
            }
        }, 
        chooseSelection() {
            this.$emit('area-selected', this.selectedArea)
            this.showDialog = false
        }
    },
    mounted() {
        const areasAndCollections = this.areasAndCollections();
        if (areasAndCollections && areasAndCollections.length > 0) {
            this.selectedArea = areasAndCollections[0][1] || null;
        }
    }
}    
</script>