import {v4 as uuidv4} from 'uuid';
import {reactive} from 'vue'

/**
 * images schema:
 * {
 *  ID: String,
 *  userSelectedName: String,
 *  userSelectedBand: String/null,
 *  imageValue: String,
 *  rootBlockID: String,
 *  imageBlockID: String,
 *  areaName: String
 * }
 * listeners schema: 
 * {
 *     blockID: String,
 *     onImageServiceChange: Function
 * }
 * Array of block IDs
 */
let service = reactive({
    images: [],
    workspace: null,
    listeners: []
})

const ImageService = {
    /**
     * simple get function, returning entire images array
     * @returns {Object} images
     */
    getImages() {
        return service.images;
    },

    /**
     * given a block ID of a image return the image index or -1 if no image found
     * @param {String} imageBlockID 
     */
    getIndexOfImageUsingImageBlockID(imageBlockID) {
        return service.images.findIndex(image => image.imageBlockID === imageBlockID);
    },

    /**
     * for a given uuid return the image index or -1 if no image found
     * @param {String} ID 
     */
    getIndexOfImageUsingID(ID) {
        return service.images.findIndex(image => image.ID === ID)
    },

    /**
     * generates a unique image name of the form "Image x" where x is a unique integer
     * @returns {String} uniqueImageName
     */
    generateUniqueImageName() {
        let imageNames = service.images.map(image => image.userSelectedName)

        let i = 1;

        while(imageNames.includes(`Image ${i}`)){
            i++
        }

        return `Image ${i}`
    },

    /**
     * generates a unique image value for blockly dropdowns of the form "Image-x" where x is a unique integer,
     * cannot be modified by the user unlike the image name
     * @returns {String} uniqueImageValue
     */
    generateUniqueImageValue() {
        let imageValues = service.images.map(image => image.imageValue);
        
        let i = 1;
        
        while(imageValues.includes(`Image-${i}`)) {
            i++
        }

        return `Image-${i}`
    },

    /**
     * searches for if name exists in service already, if it does return false
     * @param {*} imageName 
     * @returns {Boolean} 
     */
    isImageNameUnique(imageName) {
        let doesNameExist = service.images.some(image => image.userSelectedName === imageName)
        return !doesNameExist
    },

    /**
     * called when a new image is created, generates a UUID for that image
     * and a new default name based on previous names, adds that image to the service
     * and returns a copy of the image
     * @param {String} imageBlockID
     * @param {String} rootBlockID - can be null
     */
    createImage(imageBlockID, rootBlockID) {
        let imageIndex = this.getIndexOfImageUsingImageBlockID(imageBlockID)
        if(imageIndex !== -1) {
            throw Error("Image already exists for the imageBlockID", imageBlockID);
        }

        let uuid = uuidv4().replaceAll('-', '');
        let userSelectedName = this.generateUniqueImageName();
        let imageValue = this.generateUniqueImageValue();
        // TODO: verify this is correct choice, what happens if user drags block straight into another
        // maybe default will always be null in first dropdown
        let userSelectedBand = null;
        let image = {
            ID: uuid,
            userSelectedName: userSelectedName,
            userSelectedBand: userSelectedBand,
            imageValue: imageValue,
            rootBlockID: rootBlockID,
            imageBlockID: imageBlockID,
            areaName: null
        }
        service.images.push(image)
        ImageService.publishImages();
        return image
    },
    
    /**
     * called when image block is deleted, removes the image from the service
     * @param {String} ID
     */
    removeImage(ID) {
        let index = this.getIndexOfImageUsingID(ID)
        if(index !== -1) {
            service.images.splice(index, 1)
            ImageService.publishImages();
        } else {
            throw Error(`Image with uuid ${ID} could not be found`)
        }
    },

    /**
     * called when image block should be removed at an index, removes from the service
     * @param {Number} index 
     */
    removeImageAtIndex(index){
        if(index < service.images.length && index > -1) {
            service.images.splice(index, 1);
            ImageService.publishImages();
        } else {
            throw Error(`Image at index ${index} could not be removed`)
        }
    },

    /**
     * Takes a image object, gets it's ID and finds it's position in the service
     * updates the service object with the new object
     * @param {*} newImage
     */
    updateImage(newImage) {
        let id = newImage.ID;
        let index = ImageService.getIndexOfImageUsingID(id)
        if(index !== -1) {
            service.images[index] = newImage;
            ImageService.publishImages();
        } else {
            throw Error(`Image with ID ${id} could not be found or updated`);
        }
    },

    /**
     * Takes a Blockly Workspace object and registers it to the service to listen for Blockly events
     * @param {Object} workspace 
     */
    registerWorkspace(workspace) {
        service.workspace = workspace;
        service.workspace.addChangeListener(this.onWorkspaceChange)
    },

    /**
     * registers a blockID to be updated whenever there is an update to the image service
     * @param {*} blockID 
     */
    registerImageListener(blockID, onImageServiceChange) {
        service.listeners.push({
            blockID: blockID,
            onImageServiceChange: onImageServiceChange
        })
    },

    /**
     * takes index of listener, checks if it exists and removes it
     * @param {*} index 
     */
    removeImageListenerAtIndex(index) {
        if(index < service.listeners.length && index > -1) {
            service.listeners.splice(index, 1)
        } else {
            throw Error(`Image Listener at index ${index} could not be removed`)
        }
    },

    /**
     * loops over each listener of the images, checks if they still exist and if so publish
     * the blockly events to them
     * as explained in the init function of analyse_change_detection, init functions are called everytime
     * the block moves, where a hidden copy of the block is created and destroyed without being added to the workspace
     * because of this we check the block is on the workspace before calling the event listener function on the block
     */
    publishImages() {
        service.listeners.forEach((listener, index) => {
            // get block and check it still exists
            let block = service.workspace.getBlockById(listener.blockID);
            if(block) {
                listener.onImageServiceChange(service.images, block)
            } else {
                // if block no longer exists, remove the listener from the service
                ImageService.removeImageListenerAtIndex(index);
            }
        })
    },

    /**
     * Function to be called by a change listener attached to a blockly workspace
     * filters for deletion and creation of blocks to register new images to the service
     * @param {Object} event - blockly event 
     */
    onWorkspaceChange(event) {
        // if there is a delete event and it's the image block or root block, remove it from the image service
        if(event.type == 'delete'){
            let blockID = event.blockId;
            let index = service.images.findIndex(image => image.imageBlockID === blockID || image.rootBlockID === blockID);
            if(index !== -1) {
                ImageService.removeImageAtIndex(index)
            }

            let listenerIndex = service.listeners.indexOf(blockID)
            if(listenerIndex !== -1) {
                ImageService.removeImageListenerAtIndex(index)
            }
        }

        // check if block has been created or loaded from XML
        if((event.type == 'create') && event.blockId){
            let block = service.workspace.getBlockById(event.blockId);
            // if block exists and has type band selection
            if(block) {
                // check for the simple case the block is the root block
                if(block.type === 'output_band_selection'){
                    // if the block doesn't have a image
                    // TODO: if the image is saved, do we need to re register the image?
                    if (block.image === null) {
                        let newImage = ImageService.createImage(block.id, block.getRootBlock().id);
                        block.updateBlockImage(newImage);
                    }
                } else {
                    // now check if the block has been created pre-attached
                    // to another block so it isn't the rootblock
                    let descendants = block.getDescendants();
                    descendants.forEach(descendant => {
                        if(descendant.type === 'output_band_selection'){
                            if(descendant.image === null){
                                let newImage = ImageService.createImage(descendant.id, descendant.getRootBlock().id);
                                descendant.updateBlockImage(newImage);
                            }
                        }
                    })
                }

            }
        }
    },

    // TODO: consider updating the schema of the entire image service to reflect the firestore doc
    /**
     * Required for appending to the firestore doc so it can be updated easily during runtime
     * returns an object instead of an array of images such that images is like so:
     * images: {
     *     id: {
     *         userSelectedName: String,
     *         userSelectedBand: String,
     *         rootBlockID: String,
     *         imageBlockID: String
     *     }
     * }
     */
    getImagesInObjectForm() {
        let objectImages = {}
        service.images.forEach(image => {
            objectImages[image.ID] = {
                userSelectedName: image.userSelectedName,
                userSelectedBand: image.userSelectedBand,
                rootBlockID: image.rootBlockID,
                imageBlockID: image.imageBlockID, 
                imageValue: image.imageValue,
                areaName: image.areaName
            }
        })
        return objectImages;
    }

}

export {
    ImageService
}