/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["OUTPUT_MULTITEMPORAL_BLOCK_TITLE"] = "Create multi-temporal map layer   ";
Blockly.Msg["OUTPUT_MULTITEMPORAL_NAME_LABEL"] = "Layer name ";
Blockly.Msg["OUTPUT_MULTITEMPORAL_BAND_LABEL"] = "Use band ";
Blockly.Msg["OUTPUT_MULTITEMPORAL_R_LABEL"] = "R ";
Blockly.Msg["OUTPUT_MULTITEMPORAL_G_LABEL"] = "G ";
Blockly.Msg["OUTPUT_MULTITEMPORAL_B_LABEL"] = "B ";

export default Blockly.Msg;