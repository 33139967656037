
import * as Blockly from 'blockly/core';

/**
 * An implementation of the FieldImage class which specifically doesn't use the
 * value_ property to allow inherited classes to use setValue without breaking the 
 * image functionality. This is useful whenever you want to create a button, but
 * set a hidden field value.
 * 
 * Overrides the doClassValidation_ and doValueUpdate_ methods to prevent the value_ property from being set.
 * Implementations should override these methods.
 */
class FieldButton extends Blockly.FieldImage {
    constructor(src, width, height, opt_alt, opt_onClick, opt_flipRtl, opt_config) {
        super(src, width, height, opt_alt, opt_onClick, opt_flipRtl, opt_config);

        this.value_ = null;
        
        /**
         * The image src.
         * @type {string}
         * @private
         */
        this.src_ = Blockly.utils.parsing.replaceMessageReferences(src)
    }

    /**
     * Create the block UI for this image.
     * @override
     */
    initView() {
        this.imageElement_ = Blockly.utils.dom.createSvgElement(
            Blockly.utils.Svg.IMAGE, {
              'height': this.imageHeight_ + 'px',
              'width': this.size_.width + 'px',
              'alt': this.altText_,
            },
            this.fieldGroup_);
 
        this.imageElement_.setAttributeNS(
            Blockly.utils.dom.XLINK_NS, 'xlink:href', /** @type {string} */ (this.src_));
    
        if (this.clickHandler_) {
          this.imageElement_.style.cursor = 'pointer';
        }
    }

    /**
     * Validates the field's value, should be overriden by subclasses.
     * @param {*=} opt_newValue The input value.
     * @return {?string} A string, or null if invalid.
     * @protected
     * @override
     */
    // eslint-disable-next-line no-unused-vars
    doClassValidation_(opt_newValue) {
        return '';
    }

    /**
     * Set the value of this field, should be overriden by subclasses.
     * @protected
     * @override
     */
    // eslint-disable-next-line no-unused-vars
    doValueUpdate_(newValue) {
        return;
    }

    /**
     * Ensure that the input value (the source URL) is a string.
     * @param {*=} opt_newValue The input value.
     * @return {?string} A string, or null if invalid.
     * @protected
     */
    doSrcValidation_(opt_newValue) {
        if (typeof opt_newValue !== 'string') {
            return null;
        }
        return opt_newValue;
    }

    /**
     * Updates the image source.
     * @param {String} src - the new image src
     */
    setSrc(src) {
        this.src_ = this.doSrcValidation_(src);
        if (this.imageElement_) {
          this.imageElement_.setAttributeNS(
              Blockly.utils.dom.XLINK_NS, 'xlink:href', String(this.src_));
        }
    }   
}

export {FieldButton};