<template>
    <div class="md-layout-item md-layout md-gutter md-size-100">
        <md-table class="md-layout-item md-size-100">
            <md-table-row>
                <md-table-head>
                    <md-icon>dataset</md-icon>
                    <span> Datasets </span>
                </md-table-head>
                <md-table-head>
                    <md-icon>calendar_month</md-icon>
                    <span> Dates </span>
                </md-table-head>
                <md-table-head>
                    <md-icon>layers</md-icon>
                    <span> Bands </span>
                </md-table-head>
                <md-table-head>
                    <md-icon>add_box</md-icon>
                    <span> Name </span>
                </md-table-head>
                <md-table-head>
                </md-table-head>
            </md-table-row>
            <TableCreatorRow
                v-for="row in tableCreatorRows"
                :key="row.id"
                :row="row"
                :existing-variables="otherVariables(row.id)"
                :datasets="datasets"
                @update-row="(row) => handleUpdateRow(row)"
                @delete-row="(id) => handleDeleteRow(id)"
                @choose-variable="handleChooseVariable"
                @change-variable="handleChangeVariable"
                @show-transform-dialog="handleTransformDialog"
                />
        </md-table>
        <div class="md-layout-item md-layout md-size-100 md-alignment-center-center">
            <md-button
                class="md-layout-item"
                @click="addRow()"
            >
                <span> Add new variable </span>
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <TransformDialog
        v-model="showTransformDialog" 
        @save="handleSaveTransform">
            <Transformation 
                :selected-row="selectedRow" 
                :transform="selectedTransform" 
                :column="transformColumn" 
                :transformed-columns="transformedColumnsArray" 
                :preference="selectedPreference" 
                :preference-options="tranformPreferenceOptions"
                @update-transform="updateSelectedTransform"
                @update-preference="updateSelectedPreference"
            >
                <template v-slot:text-icon>
                    <md-icon>info_outlined</md-icon>
                </template>
                <template v-slot:text>
                    <template v-if="transformColumn==='properties'">
                         This allows you to aggregate all bands into a single band
                    </template>
                    <template v-if="transformColumn==='dates'">
                        This allows you to aggregate all dates into a single image
                    </template>
                </template>
                <template v-slot:transformOrderText-icon>
                    <md-icon class="warning">warning</md-icon>
                </template>
                <template v-slot:transformOrderText>
                    <template v-if="transformColumn==='properties'">
                        You're also transforming all dates from this dataset into a single image.
                        The order you perform these transformations may affect the result.
                        Would you like to transform bands first, or dates first?
                    </template>
                    <template v-if="transformColumn==='dates'">
                        You're also transforming all bands from this dataset into a single image.
                        The order you perform these transformations may affect the result.
                        Would you like to transform dates first, or bands first?
                    </template>
                </template>
            </Transformation>
        </TransformDialog>
    </div>
</template>

<script>
import TableCreatorRow from './TableCreatorRow.vue';
import TransformDialog from '../../TableCreator/TransformDialog.vue'
import Transformation from '../../TableCreator/Transformation.vue'
import tableWorkspaceMixin from '../../mixins/tableWorkspaceMixin'

export default {
    mixins: [
        tableWorkspaceMixin
    ],
    components: {
        TableCreatorRow,
        TransformDialog,
        Transformation
    },
    props: {
        definedVariables: {
            type: Array,
            default: () => []
        }
    },
    emits: [
        'choose-variable',
        'change-variable',
        'delete-calculator-row',
        'variables-changed'
    ],
    data() {
        return {
            rowsForSave: {
                type: Array,
                default: () => []
            },
            tranformPreferenceOptions: [
                {id: 'properties', name: 'Bands First'},
                {id: 'dates', name: 'Dates First'}
            ]
        }
    },
    created() {
        this.updateTableCreatorRows()
    },
    methods: {
        updateTableCreatorRows() {
            const newRows = this.definedVariables.map(defVar => {
                return {
                    ...defVar,
                    properties: this.getDatasetPropertiesIndexes(defVar.dataset, defVar.properties),
                    dates: defVar.dates
                }
            })
            this.tableCreatorRows = newRows
        },
        otherVariables(excludeRowId) {
            return this.tableCreatorRows
                .filter(tableCreatorRow => tableCreatorRow.id !== excludeRowId)
                .map(tableCreatorRow => tableCreatorRow.variableName)
        },
        addRow() {
            const nextRowId = this.highestRowId +1
            this.tableCreatorRows.push({
                id: nextRowId,
                dataset: null,
                properties: [],
                dates: ['all'],
                variableName: `VAR_${nextRowId}`,
                propertiesTransform: null,
                datesTransform: null,
                transformPreference: null,
                cadence: null
            });
        },
        handleChooseVariable(rowId, variableName) {
            this.$emit('choose-variable', rowId, variableName )
        },
        handleUpdateRow(row) {
            this.updateRow(row)
            var idx = this.tableCreatorRows.findIndex(r => r.id === row.id)
            if(idx >= 0) {
                if(this.tableCreatorRows[idx].properties.length <= 1) {
                    this.tableCreatorRows[idx].propertiesTransform = null
                    this.tableCreatorRows[idx].transformPreference = null
                }
                const dateMin = this.tableCreatorRows[idx].cadence === 'range' ? 0 : 1
                if(this.tableCreatorRows[idx].dates.length <= dateMin) {
                    this.tableCreatorRows[idx].datesTransform = null
                    this.tableCreatorRows[idx].transformPreference = null
                }
                this.resetTransformChangedData()
            }
        },
        handleChangeVariable(rowId, newVar, prevVar, userChanged) {
            var idx = this.tableCreatorRows.findIndex(r => r.id === rowId)
            if(idx >= 0) {
                this.tableCreatorRows[idx].variableName = newVar
                this.tableCreatorRows[idx].userChangedVariableName = userChanged === true
                this.$emit('change-variable', rowId, newVar, prevVar, userChanged === true)
            }
            
        },
        handleSaveTransform() {
            const selectedRowKey = this.transformColumn === 'properties' ? 'propertiesTransform' : 'datesTransform'
            this.tableCreatorRows[this.getSelectedRowIndexFromId(this.selectedRowId)][selectedRowKey] = this.transformChangedData.transform
            this.tableCreatorRows[this.getSelectedRowIndexFromId(this.selectedRowId)].transformPreference = this.transformChangedData.preference
        },
        handleDeleteRow(id) {
            this.deleteRow(id)
            this.$emit('delete-calculator-row')
        },
    },
    watch: {
        tableCreatorRows: {
            deep: true,
            handler(newValue, oldValue) {
                this.$emit('variables-changed', newValue, oldValue)
            }
        }
    }
}
</script>
