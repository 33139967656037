<template>
    <div>
        <div :id="chartContainerId"></div>
        <EbxCardExpand
            v-model="expanded"
            v-if="isAIChartEnabled"
            >
            <md-card-header>
                <div class="md-layout md-gutter">
                    <div class="md-layout-item md-size-30">
                        <div class="md-body-2">Generate new chart (experimental feature)</div>
                        <md-card-expand-trigger>
                            <md-button class="md-icon-button">
                                <md-icon>keyboard_arrow_down</md-icon>
                            </md-button>
                        </md-card-expand-trigger>
                    </div>
                </div>
            </md-card-header>
            <md-card-expand-content>
                <!-- Text input followed by a "Create new chart button"-->
                <div class="md-layout md-gutter">
                    <div class="md-layout-item md-size-100">
                        <md-field :class="errorClass">
                            <md-input 
                            v-model="chartPrompt" 
                            placeholder="Try a prompt like 'create a bar chart for each area'"
                            maxlength="100"
                            ></md-input>
                            <span class="md-error">The chart could not be generated, try again with a different prompt.</span>
                        </md-field>
                    </div>
                    <div class="md-layout-item">
                        <md-button class="ebx-button--spinner md-raised md-accent" 
                            @click="createChart" 
                            :class="loading ? 'ebx-button--spinner__disabled' : ''"
                            :disabled="isButtonDisabled"
                            >
                            <md-progress-spinner v-show="loading" class="md-accent" md-mode="indeterminate" :md-diameter="20" :md-stroke="2"></md-progress-spinner>
                            {{loading ? 'Loading' : 'Create new chart'}}
                        </md-button>
                    </div>
                </div>
            </md-card-expand-content>
        </EbxCardExpand>
    </div>
</template>

<script>
    import Plotly from 'plotly.js-dist';
    import EbxCardExpand from "@/components/EbxComponents/EbxCardExpand.vue";
    import authMixin from "@/components/mixins/authMixin";
    import { OrgConfig } from "@/config/main";
    import * as configConstants from "@/config/constants";

    const CHART_CONFIG = {
        responsive: true, // make the chart responsive
        displayModeBar: true, // always show the mode bar
        displaylogo: false, // hide the plotly logo
        modeBarButtonsToRemove: [ // remove everything but the download button
            'zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d',
            'autoScale2d', 'resetScale2d', 'toggleSpikelines', 'hoverCompareCartesian',
            'hoverClosestCartesian', 'zoom3d', 'pan3d', 'orbitRotation', 'tableRotation',
            'resetCameraDefault3d', 'resetCameraLastSave3d', 'hoverClosest3d', 'zoomInGeo',
            'zoomOutGeo', 'resetGeo', 'hoverClosestGeo', 'hoverClosestGl2d',
            'hoverClosestPie', 'toggleHover', 'resetViews', 'sendDataToCloud'
        ]
    }

    export default {
        name: 'PlotlyChart',
        mixins: [authMixin],
        components: {
            EbxCardExpand
        },
        emits: [
            'create-chart'
        ],
        methods: {
            createChart() {
                this.$emit('create-chart', this.chartPrompt, this.outputId, this.resetChart, this.handleChartFailed);
                this.loading = true;
                this.hasError = false;
                this.oldPrompt = null;
            },
            resetChart() {
                this.loading = false;
                this.expanded = false;
                this.chartPrompt = "";
                this.hasError = false;
                this.oldPrompt = null;
            },
            handleChartFailed() {
                // run when chart fails to load
                console.warn("Chart failed to load")
                this.loading = false;
                this.hasError = true;
                this.oldPrompt = this.chartPrompt;
            }
        },
        data() {
            return {
                expanded: false,
                loading: false,
                chartPrompt: "",
                hasError: false,
                oldPrompt: null
            }
        },
        props: {
            chartData: [Array, Object],
            outputId: Number
        },
        mounted() {
            const data = this.chartData.data;
            const layout = this.chartData.layout; // layout is defined in DataEngine - see backend

            layout.height = 500;

            Plotly.newPlot(this.chartContainerId, data, layout, CHART_CONFIG);

            new ResizeObserver(() => {
                let chartElement = document.getElementById(this.chartContainerId);
                Plotly.Plots.resize(chartElement);
            }).observe(this.$el);
        },
        computed: {
            chartContainerId() {
                return `chart-container-${this.outputId}`;
            },
            errorClass() {
                if (!this.hasError) {
                    return ''
                }

                if (this.oldPrompt === null) {
                    return ''
                }

                if (this.chartPrompt !== this.oldPrompt) {
                    return ''
                }

                return 'md-invalid'
            },
            isButtonDisabled() {
                return this.loading || this.chartPrompt === "" || this.hasError
            },
            isAIChartEnabled(){
                return OrgConfig.isFeatureShown(this.orgConfig, configConstants.FEATURES.AI_CHARTS)
            }
        },
        watch: {
            chartPrompt: function (newVal) {
                if(!this.hasError) {
                    return
                }

                if (newVal !== this.oldPrompt) {
                    this.hasError = false;
                    this.oldPrompt = null;
                }
            },
        }
    }
</script>