/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import {AbstractVisitor} from "@/blocks/visitors/helpers/AbstractVisitor";
import {ProvideTimePeriod} from '@/workflow/abilities/providesTimePeriod';
import {ProvidesDataset} from '@/workflow/abilities/providesDataset';
import {FlowManager} from "./helpers/FlowManager";
import { DatasetService } from "../../services/dataset.service";
import moment from 'moment';

/**
 * This visitor is triggered when a block is placed into the workspace when its connected to another block.
 * It is assumed that when a block is connected it is new and should be able to set default values.
 * 
 * This Visitor is not triggered the same way as the other visitors. Thus gatherAbilities has no knowlegde of current position.
 * Use the before method on gatherAbilities to achieve the same functionality.
 * 
 * workflowState.gatherAbilities(ProvideTimePeriod, false).before(block.id)[0];
 * is the same as Int other workflows:
 * workflowState.gatherAbilities(ProvideTimePeriod, false).current();
 * 
 * It is intended that this visitor only be used to set default values on blocks.
 * 
 * block.recommendedBlocks is added in /blocks/workflow_blocks/workflow_insert_dataset.js
 * This is from the modal to identify that fields should be added at this time.
 * 
 * Note: block.recommendedBlocks should be removed after the fields are added.
 * This is handled in FlowManager.applyRecommendedFields
 * 
 */
class DefaultStateVisitor extends AbstractVisitor {
    constructor(workflowRegistry, globalDatasetService, blockId, workspace) {
        super(workflowRegistry, globalDatasetService, blockId)
        this._name = "DefaultStateVisitor";
        this.workspace = workspace;
    }

    getWorkflowStateForBlockId(blockId) {
        let idx = this._workflowRegistry.traversedBlocks.indexOf(blockId)
        while(idx > -1) {
            const toCheckBlockId = this._workflowRegistry.traversedBlocks[idx]
            let workflowState = this._workflowRegistry.getWorkflowForId(toCheckBlockId)
            if(workflowState) {
                return workflowState
            }
            idx--;
        }
        return null
    }

    /**
     * Attempt to apply recommended fields to a block if a block has a block.recommendedFields parameter set on it.
     * @param {*} block 
     * @returns {DefaultStateVisitor}
     */
    _applyRecommendedFields(block) {
        const flowManager = FlowManager.applyRecommendedFields(this, this.workspace, block)
        flowManager.run()
        return this
    }

    async visitModifierAttributeSelectBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitModifierCompositeBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitModifierDaterangeBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitModifierFilterAttributesBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitModifierBandsBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitModifierRemoveAttributesBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitModifierRemoveBandsBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitModifierCloudMaskBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitModifierMaskBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitModifierOrbitBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitModifierUnMaskBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitModifierReAssignClassesBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitBufferBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitAnalysisConvertFeaturesBlock(block) {
        this._applyRecommendedFields(block)
    }
    
    async visitAnalysisConvertImagesBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitDetectChangeBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitAnalysisFocalAnalysisBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitAnalysisGeoprocessingBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitIndicesBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitRegressionBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitAnalysisThematicProcessingBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitAnalysisZonalStatisticsBlock(block) {
        this._applyRecommendedFields(block)
    }
    
    async visitAddMapLayerBlock(block) {
        this._applyRecommendedFields(block)
    }

    async visitModifierTimePeriodBlock(block) {
        const workflowState = this.getWorkflowStateForBlockId(block.id)
        if(!block.recommendedFields) {
            const dateRanges = workflowState.gatherAbilities(ProvideTimePeriod, false).before(block.id);

            if(dateRanges) {
                const dateRange = dateRanges[0];
                let startDate = dateRange.getStartDate().format('YYYY-MM-DD');
                let endDate = dateRange.getEndDate().format('YYYY-MM-DD');
                block.setState(block.FIELD.FROM, startDate);
                block.setState(block.FIELD.TO, endDate);
                block.setState('cadence', dateRange.getCadenceAndInterval())
                block.setState('default_dates', dateRange.getDefaultDates());
                block.updateShapeOnChange();
            }
            
        } else {
            const datasets = workflowState.gatherAbilities(ProvidesDataset, false).before(block.id);
            if(datasets) {
                const dataset = datasets[0];
                const datasetId = dataset.getDatasetId()
                const cadence = await DatasetService.getDatasetDefaultCadenceAndInterval(datasetId)
                
                const defaultDates = DatasetService._getDatasetDefaultTimeRange(
                    dataset.getDatasetId(),
                    block.recommendedFields[0].value,
                    block.recommendedFields[1].value,
                    await DatasetService.getStartDate(datasetId),
                    await DatasetService.getEndDate(datasetId),
                    cadence
                )

                const startDate = moment(defaultDates.start_date).format('YYYY-MM-DD')
                const endDate = moment(defaultDates.end_date).format('YYYY-MM-DD')
                block.recommendedFields = [
                    {
                        name: block.FIELD.FROM,
                        value: startDate
                    },
                    {
                        name: block.FIELD.TO,
                        value: endDate
                    }
                ]
                const flowManager = FlowManager.applyRecommendedFields(this, this.workspace, block)
                flowManager.addStep(() => {
                    block.setState('cadence', cadence)
                    block.setState(block.FIELD.FROM, startDate)
                    block.setState(block.FIELD.TO, endDate)
                    block.setState(block.FIELD.DEFAULT_DATES,{start_date:startDate, end_date:endDate})
                    block.updateShapeOnChange()
                })
                flowManager.run()

                block.setState('dataset', dataset.id)
            
            }
        }
    }

    async visitAnalysisCalculatorBlock(block) {
        
        if(block.recommendedFields) {
            const flowManager = new FlowManager(this, this.workspace);
            const workflowState = this.getWorkflowStateForBlockId(block.id)
            if(workflowState){
                block.recommendedFields.forEach(field =>{
                    flowManager.addStep(() => {
                        if (block.getField(field.name)) {
                            if(field.name === 'calculator' && typeof(field.value) === 'object' && Array.isArray(field.value.definedVariables)){
                                field.value.definedVariables = field.value.definedVariables.map(variable => {
                                    /**
                                     * default variables for the calculator block are tricky. They rely on the block id to be able to determine
                                     * the dataset used for a variable. Block ID's are unique every time you load a workflow so we need to update this.
                                     * the quick fix is to use the workflow id and the default string. replacing the dataset id in stac with {default}
                                     * will apply the current dataset to the variable.
                                     * 
                                     * Note this will only work for variables with the current dataset the block is place in. other datasets do not work at the moment.
                                     * 
                                     * We may need to rethink how we do this, but without reworking the how we construct our serilised blocks
                                     * this is the least friction way to get this working.
                                     */
                                    if(variable.dataset === '{default}') {
                                        variable.dataset = workflowState.id + ' - default' 
                                    }
                                    return variable
                                })
                            }
                            block.setFieldValue(field.value, field.name);
                            block.setState('expression', field.value.expression)
                            block.setState('type', field.value.type)
                            block.setState('name', field.value.name)
                            block.updateShapeOnChange()
                        }
                    });
                })
            }
            /**
             * Its **important** to remove the recommended fields after they have been applied
             * to save re-applying them on any subsequent changes of the blockly workflow.
             * 
             * example: dragging a block out of a workflow into another will cause this visitor to be called again.
             */
            flowManager.addStep(() => block.recommendedFields = null)
            flowManager.run()
        }
    }
}




export {DefaultStateVisitor}