<template>
    <div class="asset-card--item asset-card--item__action">
        <div class="asset-card--item-body">
            <div class="asset-card--item-title">
                <p class="md-body-2">
                    {{asset.name}}
                </p>
            </div>
            <div class="assets-tab--item-subtitle" v-if="showRemainingTime">
                <div class="md-caption">Expires in {{remainingTime}}</div>
            </div>
        </div>
        <div class="assets-tab--item-actions">
            <md-button
                class="md-icon-button"
                md-menu-trigger
                @click.stop="getDownloadURL(asset)"
            >
                <md-icon>file_download</md-icon>
            </md-button>
        </div>
    </div>
</template>

<script>
import { storage, usersCollection, earthbloxCollection } from "@/firebase.js";
import moment from 'moment'

export default {
    name: "ExportedAsset",
    props: {
        export: {
            type: Object,
            required: true
        },
        uid: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            remainingTime: '...',
            showRemainingTime: false
        }
    },
    created() {
        this.getTimeRemaining(this.asset.runDocId)

        // refresh remaining time every 1 hour
        setInterval(() => {
            this.getTimeRemaining(this.asset.runDocId)
        }, 3600000)
    },
    watch: {
        export: {
            deep: true,
            immediate: true,
            handler: function(newVal) {
                this.getTimeRemaining(newVal.runDocId)
                return newVal
            }
        }
    },
    methods: {
        getDownloadURL(file){
            // Create a storage reference from our storage service
            const storageRef = storage.ref();
            const downloadfileRef = storageRef.child(file.fullPath);

            downloadfileRef.getDownloadURL().then( (url)  => {
                console.log("generated downlaod url:" + url) ;
                file.href = url ;

                let element = document.createElement('a');

                element.setAttribute('download', '');
                element.setAttribute('target', '_blank');
                element.setAttribute('href', file.href);
                element.setAttribute('type', 'image/tiff');
                element.style.display = 'none';

                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);

                file.filedownloaded = true ;
            })
            .catch((error) => {
                console.log("error obtaining downlaodurl:" + error ) ;
            });
        },
        async getTimeRemaining(runDocId) {
            // get purge delay from firestore document
            let purgeDelay = 172800000 // 48 hours in ms

            await earthbloxCollection.doc('exports').get().then((doc) => {
                if (doc.exists) {
                    purgeDelay = doc.data().exportPurgeDelayMs || 172800000 // 48 hours in ms as default
                }
            }).catch((error) => {
                console.log("Error getting purge delay:", error);
            });
    
            if (!runDocId) {
                return
            }
            
            // in the user's operations collection, find the document with the rundocId
            usersCollection.doc(this.uid).collection('operations').where('rundocId', '==', runDocId).get().then((snapshot) => {
                if (snapshot.docs && snapshot.docs.length > 0) {
                    let docs = snapshot.docs
                    let updatedTimestamp = docs[0].data().update_timestamp_ms
                    // get epoch timestamp + 48 hours
                    let expiryTimestamp = updatedTimestamp + purgeDelay
                    this.remainingTime = expiryTimestamp - Date.now()
                    // get remaining time in hours and days
                    this.remainingTime = moment.duration(this.remainingTime).humanize()
                    this.showRemainingTime = true
                }
            }).catch((error) => {
                console.log("Error getting purge delay:", error);
            });
        },
    },
    computed: {
        asset() {
            return this.export
        }
    }
}
</script>