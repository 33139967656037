import moment from 'moment'
export default {
    computed: {
        currentStartYear: {
            get() {
                if(typeof this.currentStartValue === 'string') {
                    return parseInt(this.currentStartValue.split('-')[0])
                }
                return this.currentStartValue;
            },
            set(year) {
                // if start year is the same as the current start year then set the date to the extent start date
                // this avoids warning messages when the start date is greater then the start of year
                const startDate = new Date(this.variable.extent_dates.start)
                if(startDate.getFullYear() === year){
                    this.currentStartValue = moment(startDate).format('YYYY-MM-DD')
                    return
                }
                this.currentStartValue = `${year}-01-01`;
            }
        },
        currentEndYear: {
            get() {
                if(typeof this.currentEndValue === 'string') {
                    return parseInt(this.currentEndValue.split('-')[0])
                }
                return this.currentEndValue;
            },
            set(year) {
                // if end year is the same as the current end year then set the date to the extent end date
                // this avoids warning messages when the end date is smaller then the end of year
                const endDate = new Date(this.variable.extent_dates.end)
                if(endDate.getFullYear() === year){
                    this.currentEndValue = moment(endDate).format('YYYY-MM-DD')
                    return
                }
                this.currentEndValue = `${year}-12-31`;
            }
        },
        showValidationWarning(){ 
            return (this.v$.currentStartValue.$invalid || this.v$.currentEndValue.$invalid)
        }
    },
    validations: {
        currentStartValue: {
            date: function (value) {
                if (value == null) {
                    return true;
                }
                if (this.currentEndValue == null) {
                    return true;
                }
                const startDate = new Date(this.variable.extent_dates.start)
                if(new Date(value) < startDate){
                    return false
                }
                return new Date(value) <= new Date(this.currentEndValue);
            },
        },
        currentEndValue: {
            date: function (value) {
                if (value == null) {
                    return true;
                }
                if (this.currentStartValue == null) {
                    return true;
                }
                const endDate = new Date(this.variable.extent_dates.end)
                if(new Date(value) > endDate){
                    return false
                }
                return new Date(value) > new Date(this.currentStartValue);
            },
        },
    }
}