<template>
    <div>
        <md-dialog class="ebx-dialog modal-vector-map-single" v-model:md-active="computedModalShown">
            <span class="md-dialog-title md-title">
                <div class="top-actions--container">
                    <div class="title--container">
                        <span>Visualisation options</span>
                    </div>
                    <div class="close-button">
                        <md-button class="md-primary" @click="computedModalShown = false"><md-icon>close</md-icon></md-button>
                    </div>
                </div>
            </span>
            <form>
                <div class="vector-modal">
                <div class="vector-colours standard-colours">
                    <div class="colour-option" v-for="colourOption in colourOptions" :key="colourOption.value">
                        <button 
                            class="ebx-button ebx-button--circle" 
                            :class="{'is-active': formData.colour === colourOption.value}" 
                            :style="{ backgroundColor: colourOption.value }" 
                            :title="colourOption.label"
                            @click.prevent="formData.colour = colourOption.value"
                        >
                            <v-check v-if="colourOption.value === formData.colour" />
                        </button>
                    </div>
                </div>
                <p class="custom-colours-title ebx-primary-bold">Custom colours</p>
                <div class="vector-colours custom-colours">
                    <div class="colour-option" v-for="colourOption in customColours" :key="colourOption.value">
                            <button 
                            class="ebx-button ebx-button--circle" 
                            :class="{'is-active': formData.colour === colourOption.value}" 
                            :style="{ backgroundColor: colourOption.value }" 
                            :title="colourOption.label"
                            @click.prevent="formData.colour = colourOption.value"
                        >
                            <v-check v-if="colourOption.value === formData.colour" />
                        </button>
                    </div>
                    <div class="choose-colour-option">
                        <button 
                            class="ebx-button ebx-button--circle" 
                            @click.prevent="triggerColourPicker"
                        >
                            <md-icon>add</md-icon>
                        </button>
                        <input ref="colourPicker" :value="formData.colour" type="color" @change="handleColourChoice" />
                    </div>
                        
                </div>
                <div class="single-vector--description">
                    <h3 class="ebx-header-4">Description</h3>
                    <md-field :class="invalidClass">
                        <label hidden>Description</label>
                        <md-textarea v-model="formData.layerDescription" placeholder="Add a description" md-autogrow :md-counter="maxDescriptionLength"></md-textarea>
                        <span class="md-error">{{descriptionErrorMessage}}</span>
                        <span class="md-helper-text">Descriptions show alongside the map layer.</span>
                    </md-field>
                </div>  
                </div>
            </form>

            <md-dialog-actions>
                <button class="ebx-button ebx-button--secondary" @click="computedModalShown = false">Close</button>
                <button class="ebx-button ebx-button--primary" @click="saveModal">Save</button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import { globalEventBus } from '@/eventbus.js'
import VCheck from '../components/ColourPickerCheckmark.vue'
import { DEFAULT_FEATURE_COLOURS } from '@/constants/nextGenConstants.js'
import blocklyModalsMixin from './blocklyModalsMixin.js'

export default {
    name: 'SingleVectorMapLayer',
    mixins: [blocklyModalsMixin],
    components: {
        VCheck
    },
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        colour: {
            type: String,
        },
        customColours: {
            type: Array,
        }, 
        layerDescription: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            colourOptions: DEFAULT_FEATURE_COLOURS, 
            maxDescriptionLength:500, 
            descriptionErrorMessage: '',
            invalidClass: '',
            invalidDescription: false,
            formData: {
                colour: this.colour,
                customColours: this.customColours,
                layerDescription: this.layerDescription
            }

        }
    },
    computed: {
        isDefaultSelectedColour() {
            return this.colourOptions.find(colourOption => colourOption.value === this.formData.colour) !== undefined;
        },
        layerDescriptionFormData() {
            return this.formData.layerDescription;
        }
    },
    methods: {
        getFieldValue() {
            return { 
                colour: this.formData.colour,
                customColours: this.formData.customColours, 
                layerDescription: this.formData.layerDescription
            };
        },
        copyPropsToData() {
            this.formData.colour = this.colour;
            this.formData.customColours = this.customColours;
        },
        saveModal() {
            this.blockyEventCallback('modal-save', this.getFieldValue());
        },
        handleColourChoice(event) {
            this.formData.colour = event.target.value;
            if(!this.isDefaultSelectedColour) {
                this.formData.customColours.push({
                    value: this.formData.colour,
                    label: this.formData.colour
                });
            }
        },
        triggerColourPicker() {
            this.$refs.colourPicker.click();
        }, 
        validateDescription(layerDescription) {
            if (layerDescription.length > this.maxDescriptionLength) {
                this.invalidDescription = true
                this.invalidClass = 'md-invalid'
                this.descriptionErrorMessage = `Description must be less than ${this.maxDescriptionLength} characters'`
                return false
            }
            // if (nextGenConstants.ESCAPE_SEQUENCE_REGEX.test(description)) {
            //     this.invalidDescription = true
            //     this.invalidClass = 'md-invalid'
            //     this.descriptionErrorMessage = 'Description cannot contain escape sequences'
            //     return false
            // }
            this.invalidDescription = false
            this.invalidClass = ''
            return true
        },
    },
    watch: {
        showModal(val) {
            globalEventBus.$emit('supressDeleteAction', val)
            if (val) {
                this.copyPropsToData()
            }
        }, 
        layerDescriptionFormData: {
            handler: function (val) {
                this.validateDescription(val)
                return val
            },
            deep: true
        },
    }
}
</script>