/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/**
 * @fileoverview Extension of the field-date control to allow for custom styling
 * and for removal of the simple navigation menu.
 * https://google.github.io/closure-library/api/goog.ui.DatePicker.html
 * https://github.com/google/closure-library/blob/master/closure/goog/ui/datepicker.js
 * @author Jake Wilkins
 */

import * as Blockly from 'blockly/core';
import FieldDate from "@blockly/field-date";

class CustomFieldDate extends FieldDate {
    /**
     * Creates the date dropdown editor with simple navigation disabled.
     * @returns {any} google ui date picker
     * @private
     * @override
     */
    dropdownCreate_() {
        this.picker_ = super.dropdownCreate_();
        this.picker_.setUseSimpleNavigationMenu(false);
        this.picker_.setUserSelectableDateRange(this.userSelectableDateRange_())
        return this.picker_;
    }

    /**
     * Applies the custom styling to the date picker.
     * @private
     * @override
     */
    applyColour() {
        this.todayColour_ = 'rgba(0, 0, 0, 0.88)';
        this.selectedColour_ = '#00FFEB';
        this.updateEditor_();
    }

    /**
     * Set the date ranges to be used to check if a date can be selectable
     * Format of ranges is an array of arrays, each containing a start and end date
     * example: [[start1, end1], [start2, end2]]
     * @param {*} ranges 
     * @returns 
     */
    setValidRanges(ranges) {
        this._dateRanges = ranges
        return this
    }

    /**
     * Private method to setup setUserSelectableDateRange on the closure date picker
     * If we have a date range then do the filtering otherwise ignore
     * Imnot sure getStartDate and getEndDate are ever called, but where in the API for the closure date picker
     * @returns 
     */
    userSelectableDateRange_() {
        const self = this;
        return {
            /**
             * Checks if a date is within the valid date ranges.
             * If a data is wthin an range, consider this date selectable.
             * @param {*} date 
             * @returns 
             */
            contains(date) {
                if(this.hasValidDateRanges() === false) {
                    return true;
                }
                for (let i = 0; i < self._dateRanges.length; i++) {
                    const range = self._dateRanges[i]
                    const start = new Date(range[0])
                    const end = new Date(range[1])
                    if(date >= start && date <= end) {
                        return true
                    }
                }
                return false
            },
            /**
             * Get the earliest start date from the date ranges
             * @returns 
             */
            getStartDate() {
                if(this.hasValidDateRanges() === false) {
                    return new Date('1000-01-01') // any datasets before this date?
                }
                return self._dateRanges.reduce((acc, range) => {
                    const start = new Date(range[0])
                    if(acc === null || start < acc) {
                        acc = start
                    }
                    return acc
                }, null)
            },
            /**
             * Get the latest end date from the date ranges
             * @returns 
             */
            getEndDate() {
                if(this.hasValidDateRanges() === false) {
                    return new Date('9999-12-31') // end of time.
                }
                return self._dateRanges.reduce((acc, range) => {
                    const end = new Date(range[1])
                    if(acc === null || end > acc) {
                        acc = end
                    }
                    return acc
                }, null)
            },
            // never seems to be called
            iterator() {
                return []
            },
            hasValidDateRanges() {
                return (!self._dateRanges || !Array.isArray(self._dateRanges) || self._dateRanges.length === 0) === false
            }
        }
    }
}

Blockly.fieldRegistry.register('custom_field_date', CustomFieldDate);

export {CustomFieldDate}