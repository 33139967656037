
import * as Blockly from 'blockly/core';
import { without } from 'lodash';
const Menu = Blockly.Menu;
const MenuItem = Blockly.MenuItem;
const aria = Blockly.utils.aria;
const Coordinate = Blockly.utils.Coordinate;
const dropDownDiv = Blockly.DropDownDiv;
const dom = Blockly.utils.dom


/**
 * Class for an editable multi section dropdown field.
 * @extends {Blockly.FieldDropdown}
 * @alias Blockly.FieldMultiDropdown
 */
class FieldMultiDropdown extends Blockly.FieldDropdown {
  constructor(menuGenerator, opt_validator, opt_config) {
    super(menuGenerator, opt_validator, opt_config)
    this.selectedMenuItems_ = [];
    this.delimiter = opt_config.delimiter || ';';
    this.setValue('')
  }

  /**
   * Tear down and update the options on a dropdown without needing to re-create the field
   * selected values are kept if the supplying options contains a matching value.
   * @param {Array[Array[string, string]]} options 
   * @returns this
   */
  updateOptions(options) {
    this.menuGenerator_ = options
    this.generatedOptions_ = null;
    this.selectedMenuItems_ = [];
    const newOptions = options.map(o => o[1])
    const dropdownValues = this
      .valueAsArray(this.value_)
      .filter(v => newOptions.indexOf(v) >= 0);

    const newValue = dropdownValues.join(this.delimiter)
    if (newValue !== this.value_) {
      this.setValue(newValue)
    }
    return this
  }

  /**
   * Create a dropdown menu under the text.
   * @param {Event=} opt_e Optional mouse event that triggered the field to
   *     open, or undefined if triggered programmatically.
   * @protected
   */
  showEditor_(opt_e) {
    this._opt_e = opt_e
    this.dropdownCreate_();
    if (opt_e && typeof opt_e.clientX === 'number') {
      this.menu_.openingCoords = new Coordinate(opt_e.clientX, opt_e.clientY);
    } else {
      this.menu_.openingCoords = null;
    }

    // Remove any pre-existing elements in the dropdown.
    dropDownDiv.clearContent();
    // Element gets created in render.
    this.menu_.render(dropDownDiv.getContentDiv());
    const menuElement = this.menu_.getElement();
    dom.addClass(menuElement, 'blocklyDropdownMenu');

    if (this.getConstants().FIELD_DROPDOWN_COLOURED_DIV) {
      const primaryColour = (this.sourceBlock_.isShadow()) ?
        this.sourceBlock_.getParent().getColour() :
        this.sourceBlock_.getColour();
      const borderColour = (this.sourceBlock_.isShadow()) ?
        this.sourceBlock_.getParent().style.colourTertiary :
        this.sourceBlock_.style.colourTertiary;
      dropDownDiv.setColour(primaryColour, borderColour);
    }

    dropDownDiv.showPositionedByField(this, this.dropdownDispose_.bind(this));

    // Focusing needs to be handled after the menu is rendered and positioned.
    // Otherwise it will cause a page scroll to get the misplaced menu in
    // view. See issue #1329.
    this.menu_.focus();

    this.applyColour();
  }

  /**
   * Use the `getText_` developer hook to override the field's text
   * representation.  Get the selected option text. If the selected option is an
   * image we return the image alt text.
   * @return {?string} Selected option text.
   * @protected
   * @override
   */
  getText_() {
    const selectedValues = this.valueAsArray(this.value_)
    return selectedValues.length + ' Selected'
  }

  /**
   * split the value of the dropdown by delimter and return array
   * @returns {Array[string]}
   */
  valueAsArray() {
    return this.value_.split(this.delimiter).filter(v => v.length > 0)
  }

  /**
   * Create the dropdown editor.
   * @private
   */
  dropdownCreate_() {
    const menu = new Menu();
    menu.setRole(aria.Role.LISTBOX);
    this.menu_ = menu;
    const dropdownValues = this.valueAsArray(this.value_)

    const options = this.getOptions(false);
    this.selectedMenuItems_ = [];
    for (let i = 0; i < options.length; i++) {
      let content = options[i][0];  // Human-readable text or image.
      const value = options[i][1];  // Language-neutral value.
      if (typeof content === 'object') {
        // An image, not text.
        const image = new Image(content['width'], content['height']);
        image.src = content['src'];
        image.alt = content['alt'] || '';
        content = image;
      }
      const menuItem = new MenuItem(content, value);
      menuItem.setRole(aria.Role.OPTION);
      menuItem.setRightToLeft(this.sourceBlock_.RTL);
      menuItem.setCheckable(true);
      menu.addChild(menuItem);
      menuItem.setChecked(dropdownValues.indexOf(value) >= 0);
      if (dropdownValues.indexOf(value) >= 0) {
        this.selectedMenuItems_.push(menuItem);
      }
      menuItem.onAction(this.handleMenuActionEvent_, this);
    }
  }


  /**
   * Handle an action in the dropdown menu.
   * @param {!MenuItem} menuItem The MenuItem selected within menu.
   * @private
   */
  onItemSelected_(_menu, menuItem) {
    let currentValue = this.valueAsArray(this.getValue());
    if (currentValue.indexOf(menuItem.getValue()) >= 0) {
      currentValue = without(currentValue, menuItem.getValue())
      this.selectedMenuItems = without(this.selectedMenuItems, menuItem)
    } else {
      currentValue.push(menuItem.getValue())
      this.selectedMenuItems_.push(menuItem);
    }
    this.setValue(currentValue.join(this.delimiter));
  }

  /**
   * Ensure that the input value is a valid language-neutral option.
   * @param {*=} opt_newValue The input value.
   * @return {?string} A valid language-neutral option, or null if invalid.
   * @protected
   */
  doClassValidation_(opt_newValue) {
    return opt_newValue
  }

  /**
   * Handle an action in the dropdown menu.
   * @param {!MenuItem} menuItem The MenuItem selected within menu.
   * @private
   */
  handleMenuActionEvent_(menuItem) {
    dropDownDiv.clearContent();
    const dropdownValues = this.valueAsArray(this.value_)
    menuItem.setChecked(dropdownValues.indexOf(menuItem.getValue() === false));
    this.onItemSelected_(this.menu_, menuItem);
    this.showEditor_(this.opt_e)
  }
}

export {FieldMultiDropdown};