
import {AbstractVariable} from './abstract';
import { AreaService } from '@/services/area.service';
/**
 * Area Variable. Used to store an id of a study area or collection that resides within the AreaService.
 * This should be used to store the id of the area or collection and not the actual data.
 */
export class AreaVariable extends AbstractVariable {
    // Unique identifier for the variable
    static TYPE = 'area';
    static NAME = 'Area';

    // constructor(id, title, description = null, value = null) {
    //     super(id, title, description, value);
    // }

    /**
     * Used to get the display type of the variable that could be used to mutate the UI
     * use `variable.display_type` within the VueJS component
     * @returns {String}
     */
    getDisplayType() {
        return 'area' // or collection?
    }

    /**
     * The name of the vue component to show when the user is a creator.
     * It is intented that `vue_component` will be used to dynamically load the component based on the user type
     * ```
     * <component :is="variable.vue_component" :variable="variable"></component>
     * ```
     * @returns {String}
     */
    getVueJSCreatorComponent() {
        return 'AreaVariableCreator'
    }
    /**
     * The name of the vue component to show when the user is a explorer
     * It is intented that `vue_component` will be used to dynamically load the component based on the user type
     * ```
     * <component :is="variable.vue_component" :variable="variable"></component>
     * ```
     * @returns {String}
     */
    getVueJSExplorerComponent() {
        return 'AreaVariableExplorer'
    }

    /**
     * Get the display text used in Blockly Fields
     * @returns {String}
     */
    getDisplayText() {
        //return this.getBlocklyText() // this is the default Blockly Text is used as the dropdown value.
        return this.getTitle();
    }

    /**
     * Validate the variable to ensure it meets the requirements. Must appendError if there is an issue
     * @returns 
     */
    validate() {
        super.validate()
        if (this.getValue() === null) {
            this.appendError('Choose an area for your variable','no_area');
        } else {
            const type = AreaService.isAreaOrCollection(this.getValue())

            if (type === "area") {
                let areaHasShapes = AreaService.areaHasShapes(this.getValue()); 
                if (!areaHasShapes) {
                    let areaName = AreaService.getAreaName(this.getValue()) ; 
                    this.appendError(`Area ${areaName} has no shapes associated with it.`, 'no_shapes');
                } 
            } else { 
                let collectionHasShapes = AreaService.collectionHasShapes(this.getValue());
                if (!collectionHasShapes) {
                    let collectionName = AreaService.getCollectionName(this.getValue()) ; 
                    this.appendError(`Collection ${collectionName} has no shapes associated with it.`, 'no_shapes');
                }
            }
        }
        return this
    }

    getGlobalDatasetData() {
        return {
            visContrast: {statistical:"p98"},
            attributes: []
        }
    }
}