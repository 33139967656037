/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import Blockly from "blockly";

const colours = {
    blockGreen: '#006641',
    strokeGreen: '#73E5BB',
    shadowGreen: '#A3CCBD',
    blockPurple: '#991F7E',
    strokePurple: '#E573CC',
    shadowPurple: '#E5B8DB',
    blockBlue: '#244CB2',
    strokeBlue: '#7393E5',
    shadowBlue: '#B2C8FF',
    pureWhite: '#ffffff',
    pureBlack: '#000000',
    solid_black_08: '#EBEBEB',
    black_08: 'rgba(0, 0, 0, 0.08)',
    black_88: 'rgba(0, 0, 0, 0.88)',
    lightGrey: '#cccccc',
    disabledGrey: '#919191'
}

const coloursLight = {
    blockGreen: '#A3CCBD',
    strokeGreen: '#006641',
    shadowGreen: '#fff',
    blockPurple: '#E5B8DB',
    strokePurple: '#E573CC',
    shadowPurple: '#fff',
    blockBlue: '#B2C8FF',
    strokeBlue: '#244CB2',
    shadowBlue: '#fff',
    pureWhite: '#ffffff',
    pureBlack: '#000000',
    black_08: 'rgba(0, 0, 0, 0.08)',
    black_88: 'rgba(0, 0, 0, 0.88)',
    lightGrey: '#cccccc',
    disabledGrey: '#919191'
}

const toolboxColours = { 
    slate50: '#f3f3f3', 
    slate100: '#dcdcdc'
}


const ebxTheme = {
    'base': Blockly.Themes.Modern,
    'blockStyles':  {
        "input": {
            "colourPrimary": colours.blockGreen,
            "colourSecondary": colours.shadowGreen,
            "colourTertiary": colours.strokeGreen
        },
        "analysis": {
            "colourPrimary": colours.blockPurple,
            "colourSecondary": colours.shadowPurple,
            "colourTertiary": colours.strokePurple
        },
        "output": {
            "colourPrimary": colours.blockBlue,
            "colourSecondary": colours.shadowBlue,
            "colourTertiary": colours.strokeBlue
        },
    },
    'componentStyles': {
        'workspaceBackgroundColour': colours.pureWhite,
        'toolboxBackgroundColour': toolboxColours.slate50,
        'toolboxForegroundColour': colours.black_88,
        'flyoutBackgroundColour': toolboxColours.slate100,
        'flyoutForegroundColour': colours.black_88,
        'flyoutOpacity': 1,
        'scrollbarColour': '#797979',
        'insertionMarkerColour': colours.pureWhite,
        'insertionMarkerOpacity': 0.3,
        'scrollbarOpacity': 0.4,
        'cursorColour': '#d0d0d0',
    },
    'fontStyle': {
        "family": "Montserrat, sans-serif",
        // "weight": "regular",
        "size": 12,
        "color": colours.black_88
    },
    'categoryStyles': {
        'input_category': {
            'colour': colours.blockGreen
        },
        'analyse_category': {
            'colour': colours.blockPurple
        },
        'output_category': {
            'colour': colours.blockBlue
        },
        'disabled_category': {
            'colour': colours.disabledGrey
        }
    },
    'startHats': false
};

// ---------------------------------------------------------------------------
// Blockly Palette
// 
// This is the palette defined by NileHQ in:
// https://www.figma.com/file/Ibi7Ip1ZP8uZNl9WUw0pcp/EarthBlox-UX%2FUI-Designs
// ---------------------------------------------------------------------------

// colour gradation from dark(08) to light(01)
const purpleColours = {
    purple08: '#6375E0',
    purple07: '#6578E7',
    purple06: '#6786F7',
    purple05: '#6C98FE',
    purple04: '#7DABFF',
    purple03: '#9EC2FF',
    purple02: '#C3D9FF',
    purple01: '#E5F0FF'
}

// colour gradation from dark(08) to light(01)
const blueColours = {
    blue08: '#00ACC5',
    blue07: '#00C2E1',
    blue06: '#00D3F6',
    blue05: '#00DBF9',
    blue04: '#41E2FD',
    blue03: '#7DECFF',
    blue02: '#B3F3FF',
    blue01: '#E1FAFF'
}

// colour gradation from dark(08) to light(01)
const tealColours = {
    teal08: '#00917B',
    teal07: '#009E86',
    teal06: '#00A892',
    teal05: '#00B39E',
    teal04: '#30C1B0',
    teal03: '#76D3C7',
    teal02: '#AEE4DD',
    teal01: '#DFF4F2'
}

// colour gradation from dark(08) to light(01)
const yellowColours = {
    yellow08: '#E7A200',
    yellow07: '#E7BA00',
    yellow06: '#E6D200',
    yellow05: '#E5E500',
    yellow04: '#E7E83E',
    yellow03: '#EBEC65',
    yellow02: '#F1F292',
    yellow01: '#FBFCE5'
}

const DISABLED_STYLE_MODIFIER = '_disabled';

/**
 * EBX NextGen Block Theme (v2.0)
 */
// block colours

import colourTheme from '@/styles/variables/colours.module.scss';

const geoprocessingColour = colourTheme.salmon400

// category colours
const inputCategoryColour = colourTheme.loch400
const areaDatesCategoryColour = colourTheme.loch300
const filterCategoryColour = colourTheme.loch200
const collateCategoryColour = colourTheme.sky300

const analyseCalculateColour = colourTheme.gorse500
const analyseClassifyColour = colourTheme.daffodil300
const outputCategoryColour = colourTheme.fern500
const outputCategoryExportColour = colourTheme.fern500

const nextGen = {
    'base': Blockly.Themes.Modern,
    'blockStyles':  {
        // new new colours
        "dataset": {
            "colourPrimary": colourTheme.loch300,
            "colourTertiary": colours.pureWhite,
        },
        "primaryFilter": {
            "colourPrimary": colourTheme.loch200,
            "colourTertiary": colours.pureWhite
        },
        "secondaryFilter": {
            "colourPrimary": colourTheme.loch100,
            "colourTertiary": colours.pureWhite
        },
        "combine": {
            "colourPrimary": colourTheme.sky400,
            "colourTertiary": colours.pureWhite
        },
        "calculate": {
            "colourPrimary": colourTheme.gorse500,
            "colourTertiary": colours.pureWhite
        },
        "classify": {
            "colourPrimary": colourTheme.daffodil400,
            "colourTertiary": colours.pureWhite
        },
        "geoprocessing": {
            "colourPrimary": colourTheme.salmon400,
            "colourTertiary": colours.pureWhite
        },
        "table": {
            "colourPrimary": colourTheme.fern400,
            "colourTertiary": colours.pureWhite
        },
        "mapLayer": {
            "colourPrimary": colourTheme.fern400,
            "colourTertiary": colours.pureWhite
        },
        "export": {
            "colourPrimary": colourTheme.fern400,
            "colourTertiary": colours.pureWhite
        }
    },
    'componentStyles': {
        'workspaceBackgroundColour': coloursLight.pureWhite,
        'toolboxBackgroundColour': colourTheme.slate50,
        'toolboxForegroundColour': coloursLight.black_88,
        'flyoutBackgroundColour': '#f3f3f3',
        'flyoutForegroundColour': coloursLight.black_88,
        'flyoutOpacity': 1,
        'scrollbarColour': '#797979',
        'insertionMarkerColour': '#FDF200',
        'insertionMarkerOpacity': 0.3,
        'scrollbarOpacity': 0.4,
        'cursorColour': '#d0d0d0',
        'startHats': true
    },
    'fontStyle': {
        "family": "Montserrat, sans-serif",
        "size": 12,
        "weight":"normal"
    },
    'categoryStyles': {
        // next gen toolbox categories
        'nextgen_input_category': {
            'colour': inputCategoryColour
        },
        'nextgen_area_dates_category': {
            'colour': areaDatesCategoryColour
        },
        'nextgen_filter_category': {
            'colour': filterCategoryColour
        },
        'nextgen_collate_category': {
            'colour': collateCategoryColour
        },
        'nextgen_calculate_category': {
            'colour': analyseCalculateColour
        },
        'nextgen_classify_category': {
            'colour': analyseClassifyColour
        },
        'nextgen_vector_processing_category': {
            'colour': geoprocessingColour
        },
        'nextgen_output_category': {
            'colour': outputCategoryColour
        },
        'nextgen_output_export_category': {
            'colour': outputCategoryExportColour
        },
        'nextgen_deprecated_category': {
            'colour': colours.disabledGrey
        },
        // EO next gen toolbox categories
        'input_category': {
            'colour': purpleColours.purple08
        },
        'biomass_category': {
            'colour': purpleColours.purple08
        },
        'forest_category': {
            'colour': purpleColours.purple08
        },
        'fire_category': {
            'colour': purpleColours.purple07
        },
        'landcover_category': {
            'colour': purpleColours.purple07
        },
        'water_category': {
            'colour': purpleColours.purple06
        },
        'anthropogenic_category': {
            'colour': purpleColours.purple06
        },
        'atmosphere_category': {
            'colour': purpleColours.purple05
        },
        'terrain_category': {
            'colour': purpleColours.purple04
        },
        'imagery_category': {
            'colour': purpleColours.purple03
        },
        'dataDownloader_category': {
            'colour': purpleColours.purple02
        },

        'analyse_category': {
            'colour': yellowColours.yellow08
        },
        'timeSeries_category': {
            'colour': yellowColours.yellow08
        },
        'comparison_category': {
            'colour': yellowColours.yellow07
        },
        'classification_category': {
            'colour': yellowColours.yellow06
        },
        'indices_category': {
            'colour': yellowColours.yellow05
        },
        'bandMath_category': {
            'colour': yellowColours.yellow04
        },
        'changeDetection_category': {
            'colour': yellowColours.yellow03
        },
        'cloudFilter_category': {
            'colour': yellowColours.yellow02
        },
        'output_category': {
            'colour': tealColours.teal06
        },
        'mapVisualisation_category': {
            'colour': tealColours.teal06
        },
        'download_category': {
            'colour': tealColours.teal05
        },
        'dashboards_category': {
            'colour': tealColours.teal04
        },
        
        'solutions_category': {
            'colour': blueColours.blue08
        },
        // Solutions subcategories
        'solutions_deforestation_category': {
            'colour': blueColours.blue08
        },
        'solutions_forestcover_category': {
            'colour': blueColours.blue07
        },
        'solutions_landcover_category': {
            'colour': blueColours.blue06
        },
        'solutions_fires_category': {
            'colour': blueColours.blue05
        },
        'solutions_biomass_category': {
            'colour': blueColours.blue04
        },
        'solutions_windblow_category': {
            'colour': blueColours.blue03
        },
        'solutions_fire_category': {
            'colour': blueColours.blue02
        },
    },
    'startHats': false
};

Object.keys(nextGen['categoryStyles']).forEach(style => {
    nextGen['categoryStyles'][style+DISABLED_STYLE_MODIFIER] = {
        'colour': colours.disabledGrey
    }
});

Blockly.Themes.Ebx = Blockly.Theme.defineTheme('ebx', ebxTheme);
Blockly.Themes.NextGen = Blockly.Theme.defineTheme('nextgen', nextGen);