<template>
    <div>
        <div class="layer-wrapper">
            <div class="layer-content">
                <slot name="text" />
            </div>
        </div>

        <div class="layer-wrapper">
            </div>
            <div class="layer-content">
                <md-field>
                    <label>Using statistic</label>
                    <md-select v-model="selectedAggregation">
                        <md-option v-for="reducer in reducers" :key="reducer.id" :value="reducer.id">{{reducer.name}}</md-option>
                    </md-select>
                </md-field>
        </div>
       
        <template v-if="hasOtherAggregations">
            <div class="layer-wrapper">
                <div class="layer-icon">
                    <slot name="transformOrderText-icon" />
                </div>
                <div class="layer-content">
                    <slot name="transformOrderText" />
                </div>
            </div>
            <div class="layer-wrapper">
                <div class="layer-icon">
                
                </div>
                <div class="layer-content">
                    <md-field>
                        <md-select v-model="selectedPreference">
                            <md-option v-for="col in preferenceFilteredOptions" :key="col.id" :value="col.id">{{ col.name }}</md-option>
                        </md-select>
                    </md-field>
                </div>
            </div>
        </template>
    </div>
</template>

<script>

export default {
    props: {
        selectedRow: {
            type: Object,
            default: undefined
        },
        aggregate: {
            type: String,
            default: undefined
        },
        column: {
            type: String,
            default: undefined
        },
        preference: {
            type: String,
            default: undefined
        },
        aggregatedColumns: {
            type: Array,
            default: () => []
        },
        preferenceOptions: {
            type: Array,
            required: true
        }
    },
    emits: [
        'update-transform',
        'update-preference'
    ],
    data() {
        return {
            reducers: [
                {
                    id:'count',
                    name: 'Count'
                },
                {
                    id:'max',
                    name: 'Max'
                },
                {
                    id:'mean',
                    name: 'Mean'
                },
                {
                    id:'median',
                    name: 'Median'
                },
                {
                    id:'min',
                    name: 'Min'
                },
                {
                    id:'mode',
                    name: 'Mode'
                },
                {
                    id:'stdDev',
                    name: 'Standard Deviation'
                },
                {
                    id:'sum',
                    name: 'Sum'
                },
                {
                    id:'variance',
                    name: 'Variance'
                },
            ]
                
        }
    },

    computed: {
        preferenceFilteredOptions() {
            return this.preferenceOptions.filter(op => this.aggregatedColumnsIncludingSelf.indexOf(op.id) >=0 )
        },
        aggregatedColumnsIncludingSelf() {
            const aggregates = this.aggregatedColumns.slice()
            if(aggregates.indexOf(this.column) < 0) {
                aggregates.unshift(this.column)
            }
            return aggregates
        },
        hasOtherAggregations() {
            return this.aggregatedColumns.filter(k => k !== this.column).length > 0
        },
        hasSelectedRow() {
            return this.selectedRow !== undefined
        },
        selectedAggregation: {
            get() {
                return this.aggregate
            },
            set(aggregate) {
                this.$emit('update-transform', aggregate, this.column)
                if(aggregate) {
                    this.$emit('update-preference', this.selectedPreference, this.column)
                } else {
                    this.$emit('update-preference', null, this.column)
                }
            }
        },
        selectedPreference: {
            get() {
                return this.preference
            },
            set(preference) {
                this.$emit('update-preference', preference, this.column)
                this.$emit('update-transform', this.selectedAggregation, this.column)
            }
        }
    }
}
</script>