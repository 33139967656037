import {FieldModal} from '@/fields/FieldModal';
import assets from '@/assets.js';

export const addModalInput = function(block, modal, modalData, inputName, fieldName = 'modal_field') {
    const field = new FieldModal(modal, modalData, assets.blockly.settingsWhite24dp, 30, 30)
    block.appendDummyInput(inputName)
        .appendField(field,fieldName)
    return field
}

export const singleChannelModalData_ = {
    // dropdown options with colour ramps go here
    "palette": null,
    "contrastValue": '',
    "rampValue": "colorbrewer.Spectral",
    "invertRamp": false,
    "min": null,
    "max": null,
}

export const multiChannelModalData_ = {
    "minR": null,
    "maxR": null,
    "minG": null,
    "maxG": null,
    "minB": null,
    "maxB": null,
    "contrastValue": '',
}