<template>
    <div class="account">
        <h1 class="ebx-header-1">Account</h1>
        <md-tabs md-sync-route>
            <md-tab
                id="tab-profile" 
                md-label="Profile"
                to="/myaccount/profile"
            >
                <md-list class="md-double-line">
                    <md-list-item>
                        <md-icon class="md-primary">person</md-icon>

                        <div class="md-list-item-text">
                            <p>{{currentUser.displayName}}</p>
                            <p>{{getUserType}}</p>
                        </div>

                        <md-tooltip md-direction="left">User</md-tooltip>
                    </md-list-item>

                    <md-list-item v-if="!isIndividualOrganisation">
                        <md-icon class="md-primary">business</md-icon>

                        <div class="md-list-item-text">
                            <p>Organisation: {{ orgName }}</p>
                        </div>

                        <md-tooltip md-direction="left">Organisation</md-tooltip>
                    </md-list-item>

                    <md-list-item>
                        <md-icon class="md-primary">email</md-icon>

                        <div class="md-list-item-text">
                            <span>{{ currentUser.email }}</span>
                        </div>

                        <md-tooltip md-direction="left">Email</md-tooltip>
                    </md-list-item>

                    <md-list-item v-if="getUserRole && getUserRole.length">
                        <md-icon class="md-primary">admin_panel_settings</md-icon>

                        <md-chip v-for="role in getUserRole" :key="role">{{ role }}</md-chip>

                        <div class="md-list-item-text"></div>

                        <md-tooltip md-direction="left">Roles</md-tooltip>
                    </md-list-item>

                </md-list>
                <div class="account--profile-actions">
                    <button class="ebx-button ebx-button--secondary" @click="editProfile()">Update profile</button>
                    <button class="ebx-button ebx-button--secondary" v-if="!currentUser.isLtiUser" @click="changeEmail()">Change email</button>
                    <button class="ebx-button ebx-button--secondary" v-if="!currentUser.isLtiUser" @click="changePassword()">Change password</button>
                    <button class="ebx-button ebx-button--secondary" v-if="isSuperAdmin" @click="getApiToken()">Copy API token</button>
                </div>
            </md-tab>

            <md-tab
                v-if="isStripeActive"
                id="tab-subscription"
                md-label="Subscription"
                to="/myaccount/subscription"
            >
                <div class="account--tab-container">
                    <div class="account--user-info" v-for="stripeSubscription in orderedStripeSubscriptions" :key="stripeSubscription.id">
                        <div class="account--user-info__top-section">
                            <div class="account--user-info__lhs" v-if="stripeSubscription">
                                <div class="account--user-info__title" v-if="stripeSubscription.name">{{ stripeSubscription.name }}</div>
                                <div class="account--user-info__subtitle" v-if="stripeSubscription.plan.metadata.activity === 'commercial'">{{ stripeSubscription.plan.metadata.activity }}</div>
                                <div class="account--user-info__subtitle" v-else-if="stripeSubscription.plan.metadata.activity === 'non-commercial'">For educational, non-commercial use</div>
                                <div class="account--user-info__subtitle" v-else>{{ stripeSubscription.plan.metadata.activity }}</div>
                            </div>
                            <div class="account--user-info__rhs">
                                <div class="account--user-info__sub-info" v-if="stripeSubscription.plan">
                                    <span v-html="getCurrencySymbol(stripeSubscription)"></span>{{ stripeSubscription.plan.amount / 100 }}  (billed {{ stripeSubscription.plan.bill_interval }})
                                </div>
                            </div>
                        </div>
                        <div class="account--user-info__bottom-section" v-if="stripeSubscription.status == 'trialing'">
                            <p class="account--user-info__sub-info">Your free trial ends in {{ convertStripeTimestamp(stripeSubscription.trial_end) }}</p>
                        </div>
                        <div class="account--user-info__bottom-section inactive" v-if="!activeStatuses.includes(stripeSubscription.status)">
                            <p class="account--user-info__sub-info">Your subscription is {{ stripeSubscription.status }}</p>
                        </div>
                    </div>
                    <div v-if="!stripeSubscriptions.length">
                        <p class="account--user-info__sub-info">You don't have a subscription</p>
                    </div>
                    <form :action="stripePortalKey">
                        <input type="hidden" name="prefilled_email" :value="this.firebaseUser.email"/>
                        <input value="Manage subscription" type="submit" class="ebx-button ebx-button--primary" />
                    </form>
                </div>
            </md-tab>
        </md-tabs>

        <UserListAddEditUser ref="editProfileDialog" :groups="[]" @user-save="onProfileEdit" :isEdit="false" />
        <MyAccountChangeEmail ref="changeEmailDialog"  @save="onChangeEmail" />
        <MyAccountChangePassword ref="changePasswordDialog"  @save="onChangePassword" />

        <md-snackbar
        :md-position="'center'"
        :md-duration="10000"
        v-model:md-active="showSnackbar"
        md-persistent
        >
            <span>{{ message }}</span>
        </md-snackbar>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { AuthService } from "@/services/auth.service";
import { functions } from "../firebaseFunctions.js";
import { 
    auth, 
    earthbloxCollection, 
    usersCollection, 
    organisationsCollection } from "@/firebase.js"
import stripeMixin from '@/components/mixins/stripeMixin'
import authMixin from '@/components/mixins/authMixin.js'
import UserListAddEditUser from "@/components/UserListAddEditUser.vue";
import MyAccountChangeEmail from "@/components/MyAccountChangeEmail.vue";
import MyAccountChangePassword from "@/components/MyAccountChangePassword.vue";
import { orderBy } from 'lodash';
import moment from 'moment'

import { 
    DEFAULT_STRIPE_PUBLISHABLE_KEY, 
    STRIPE_PRODUCT_API, 
    STRIPE_SESSION_API, 
    STRIPE_CUSTOMERS_API, 
    STRIPE_SUBSCRIPTIONS_API, 
    STRIPE_PRICE_API, 
    STRIPE_SUBSCRIPTION_SUB_COLLECTION, 
    STRIPE_ACTIVE_SUB_STATUS
} from "@/constants/appConstants.js";

export default {
    name: "MyAccount",
    mixins: [
        stripeMixin,
        authMixin
    ],
    components: {
        UserListAddEditUser,
        MyAccountChangeEmail,
        MyAccountChangePassword
    },
    data: () => ({
        // user: null,
        currentUser: null,
        showSnackbar: false,
        message: "",
        orgName: '',
        // below are imported from sub page
        isDialogVisible: false,
        firebaseUser: null,
        customerId: null,
        stripePublishableAPIKey: null,
        availableStripeProducts: [],
        availableStripePrices: [],
        stripeSubscriptions: [],
        sessionURL: null,
        successURL: null, 
        paymentMethods: [],
        activeStatuses: STRIPE_ACTIVE_SUB_STATUS,
    }),
    methods: {
        getApiToken() {
            if(auth.currentUser) {
                auth.currentUser.getIdToken().then(function(idToken) {
                    navigator.clipboard.writeText(idToken);
                    this.message = `API token copied to clipboard`;
                    this.showSnackbar = true;
                }.bind(this)).catch(function(error) {
                    console.error(error)
                });
            }
        },
        editProfile() {
            this.$refs.editProfileDialog.showDialog(this.user);
        },
        onProfileEdit(user) {
            functions
                .updateUser(user)
                .then(() => {
                    this.resetUser();
                    this.setUser(user);
                    this.message = `Profile was updated successfully`;
                    this.showSnackbar = true;
                }, (error) => {
                    console.error(error);
                    this.message = `Could not update profile. Error occured: '${error}'`;
                    this.showSnackbar = true;
                });
        },
        resetUser() {
            this.currentUser.orgId = null
        },
        setUser(user) {
            this.currentUser.orgId = user.orgId
            AuthService.updateProfile(user)
            this.$store.dispatch('auth/setUser', user)
            this.getOrgInfoFromId(this.currentUser.orgId);
        },
        changeEmail() {
            this.$refs.changeEmailDialog.showDialog();
        },
        managageSubscription() {
            this.$refs.manageSubscriptionDialog.showDialog();
        },
        onChangeEmail(model) {      
            AuthService.updateEmail(model.currentPassword, model.newEmail).then(
                () => {
                this.message = `Email was updated successfully`;
                this.showSnackbar = true;
                },
                error => {
                this.message = `Could not update email. Error occured: '${error}'`;
                this.showSnackbar = true;
                }
            );
        },
        changePassword() {
            this.$refs.changePasswordDialog.showDialog();
        },
        onChangePassword(model) {
            AuthService.updatePassword(model.currentPassword, model.newPassword).then(
                () => {
                    this.message = `Password was updated successfully`;
                    this.showSnackbar = true;
                },
                error => {
                    this.message = `Could not update password. Error occured: '${error}'`;
                    this.showSnackbar = true;
                }
            );
        },
        getOrgInfoFromId(orgId) {
            if (orgId) {
                organisationsCollection.doc(orgId).get().then(doc => {
                    if (doc.exists) {
                        let org_data = doc.data()
                        this.orgName = org_data.name;
                    }
                });
            }
        },
        fetchUsersSubscriptions(){
            if (this.userRef){
                console.log(`Fetching users sub for user ${this.userRef.id}`)
                this.userRef.get().then((result) => {
                this.customerId = result.data().stripe_customer.id;
                console.log(`Got user customer id ${this.customerId}`);
                
                }).catch((err) => {
                console.warn(`This user has no Stripe information. Cannot proceed due to: ${err}`);
                });
                // get the most recent subscription belonging to the user
                this.userRef.collection(STRIPE_SUBSCRIPTION_SUB_COLLECTION)
                    // .where("status", "in", STRIPE_ACTIVE_SUB_STATUS)
                    // .orderBy("start_date","desc") note that orderBy requires an index, and this cannot be created for every user due to hard limits in firestore
                    .get()
                    .then(querySnapshot => {
                    querySnapshot.forEach((doc) => {
                        let sub = doc.data();
                        if (sub.plan.interval && sub.plan.interval_count) {
                            sub.plan.bill_interval = this.calculateBillInterval(sub.plan.interval, sub.plan.interval_count);
                        }
                        // we now have the subscription information, but annoyingly this doesn't contain the related product name
                        // so we need to make another call to get product 
                        this.fetchStripeProductData(sub);
                    })
                    })
            } else {
                console.warn(`No logged in user, cannot fetch subs`);
            }
        },
        calculateBillInterval(interval, interval_count) {
            let bill_interval = "";
            if (interval_count === 1) {
                if (interval === "month") {
                    bill_interval = "monthly";
                } else if (interval === "year") {
                    bill_interval = "annually";
                } else {
                    bill_interval = `every ${interval_count} ${interval}`;
                }
            } else if (interval_count === 0 || !interval_count) {
                bill_interval = `every ${interval}`;
            } else if (interval_count){
                bill_interval = `every ${interval_count} ${interval}`;
            } else {
                bill_interval = `every ${interval_count} ${interval}s`;
            }
            
            return bill_interval;
        },
        async getStripeSessionURL(){
            // no longer used, but maybe useful in future
            if (this.stripeSubscription){
                console.log("Getting a stripe session URL for payment detail collection");
                // Here we provide a Stripe session which collects credit card details
                // We need to provide a success_url because the user will leave the EBX app, go to Stripe, and be returned to the success URL
                // use https://stripe.com/docs/api/checkout/sessions/create to get a session
                let headers = new Headers({
                    'Authorization': 'Bearer '+ this.stripePublishableAPIKey,
                    'Content-Type': 'application/x-www-form-urlencoded',
                });
                let body = new URLSearchParams({
                    success_url: this.successURL,
                    cancel_url: this.successURL,
                    mode: 'setup',
                    customer: this.customerId,
                    'payment_method_types[0]': 'card',
                    //payment_intent_data: {setup_future_usage: 'off_session'}
                });

                fetch(STRIPE_SESSION_API, {method:'POST', headers: headers, body: body})
                    .then((response) => {  
                        if (response.status != 200){
                            throw new Error(`Stripe Session API responded with ${response.status}`);
                        } else {
                            return response.json()
                        }
                    })
                    .then((data) => {
                    // console.log(data);
                        this.sessionURL = data.url;
                        //alert(data.error.request_log_url);
                    })
                    .catch((error) => {console.warn(`Unable to get session information: ${error}`)});
            } else {
                console.warn("No session information avaiable")
            }
        },

        async changeSubscription(){
            // Unused, left for reference if we want to do our own implementation rather than use the portal
            if (this.selectedPrice == this.usersPrice) {
                console.info("Ignoring, no change");
            } else {
                console.log(`Updating subscription to ${this.selectedPrice} for customer ${this.customerId}`);
                // the following async methods can both be called simultaneously, no need to wait for one to finish before calling the other.
                this.cancelSubscriptionInStripe(this.stripeSubscription.id);
                this.createSubscriptionInStripe(this.selectedPrice);
            }
        },

        async createSubscriptionInStripe(priceId){
            // Unused, left for reference if we want to do our own implementation rather than use the portal
            // if required, you will need to add the relevant write perms to the limited API key
            console.log(`Creating subscription with price ${priceId} with ${STRIPE_SUBSCRIPTIONS_API}`);
            let headers = new Headers({
                'Authorization': 'Bearer '+ this.stripePublishableAPIKey
            });
            let params = new URLSearchParams({
                customer: this.customerId,
                'items[0][price]': priceId,
            });
            fetch(`${STRIPE_SUBSCRIPTIONS_API}`, {method: 'POST', headers: headers, body: params})
                .then((response) => {  
                    if (response.status != 200){
                    throw new Error(`Stripe Subscription Create API responded with ${response.status}. If this is a 400 it might mean the customer has no payment method.`);
                    } else {
                    response.json()
                    .then((info => {
                        console.log(`Subscription created with id ${info.id}. Status is ${info.status}`);
                    }))
                    
                    }
                })
                .catch((error) => {console.warn(`Unable to create subscription: ${error}`)});
        },
        cancelSubscriptionInStripe(subscriptionId){
            // Unused, left for reference if we want to do our own implementation rather than use the portal
            console.log(`Cancelling subscription ${subscriptionId} with ${STRIPE_SUBSCRIPTIONS_API}`);
            let headers = new Headers({
                'Authorization': 'Bearer '+ this.stripePublishableAPIKey
            });
            fetch(`${STRIPE_SUBSCRIPTIONS_API}/${subscriptionId}`, {method:'DELETE', headers: headers})
                .then((response) => {  
                    if (response.status != 200){
                    throw new Error(`Stripe Subscription Delete API responded with ${response.status}`);
                    } else {
                    response.json()
                    .then((info => {
                        console.log(`Sstatus of ${info.id} is now ${info.status}`);
                    }))
                    
                    }
                })
                .catch((error) => {console.warn(`Unable to cancel subscription: ${error}`)});
        },
        fetchStripePaymentMethods(){
            // doesn't return any confidential information, only last for digits of card
            // However this is non essential, so am removing the perms for the key for now.
            // Add the payment method read permission if this is really needed in future
            // Code left here for reference
            console.log(`Fetching payment info`);
            let headers = new Headers({
                'Authorization': 'Bearer '+ this.stripePublishableAPIKey
            });
            fetch(`${STRIPE_CUSTOMERS_API}${this.customerId}/payment_methods`, {headers: headers})
                .then((response) => {  
                    if (response.status != 200){
                    throw new Error(`Stripe Customer API responded with ${response.status}`);
                    } else {
                    response.json()
                    .then((info => {
                        this.paymentMethods = info.data;
                    }))
                    
                    }
                })
                .catch((error) => {console.warn(`Unable to get payment information: ${error}`)});
        },
        getCurrencySymbol(sub){
            /**
             * pass in a subscription object and this will return the currency symbol appropriate for the subscription currency
             * it uses the sub.currency value to derive the symbol, and this is a three letter code like gbp or usd
             * note that the value returned here may contain entities, so needs to in a v-html directive to prevent the Vue template from escaping it
             */
            let symbol = '$';
            if (sub.currency && sub.currency == 'gbp') {
                symbol = '&pound;'
            }
            return symbol;
        },
        async fetchStripeProductData(sub){
            /**
             * This function is an additional call that needs to be made to augement the information in the subscription data before 
             * pushing it onto the stripeSubscriptions list
             * Specifically the subscription info does NOT contain the name of the product for some reason known only to Stripe
             * We want to display the product name to the user, so this fetches that and adds it to the subscription info
             * sub is the subscription information that we want to look up the product for
             */
            console.log(`Fetching product ${sub.plan.product}`);
            let headers = new Headers({
                'Authorization': 'Bearer '+ this.stripePublishableAPIKey
            });
            fetch(`${STRIPE_PRODUCT_API}/${sub.plan.product}`, {headers: headers})
            .then((response) => {  
                if (response.status != 200){
                throw new Error(`Stripe Product API responded with ${response.status}`);
                } else {
                response.json()
                .then((productInfo => {
                    sub.name = productInfo.name;
                    this.stripeSubscriptions.push(sub);
                }))
                
                }
            })
            .catch((error) => {console.warn(`Unable to get product information: ${error}`)});
        },

        fetchAvailableProductData(){
            // unused method, but may well be useful in future
            console.log(`Fetching available product list`);
            let headers = new Headers({
                'Authorization': 'Bearer '+ this.stripePublishableAPIKey
            });
            let params = new URLSearchParams({
                active: true
            });
            fetch(`${STRIPE_PRODUCT_API}?` + params, {headers: headers})
                .then((response) => {  
                    if (response.status != 200){
                    throw new Error(`Stripe Product API responded with ${response.status}`);
                    } else {
                    response.json()
                    .then((info => {
                        this.availableStripeProducts = info.data;
                        //(this.availableStripeProducts);
                    }))
                    
                    }
                })
                .catch((error) => {console.warn(`Unable to get product information: ${error}`)});
        },

        fetchAvailablePrices(){
            // unused method, but may well be useful in future
            console.log(`Fetching available prices for product ${this.selectedProduct}`);
            let headers = new Headers({
                'Authorization': 'Bearer '+ this.stripePublishableAPIKey
            });
            let params = new URLSearchParams({
                active: true,
                product: this.selectedProduct
            });
            fetch(`${STRIPE_PRICE_API}?` + params, {headers: headers})
                .then((response) => {  
                    if (response.status != 200){
                    throw new Error(`Stripe Price API responded with ${response.status}`);
                    } else {
                    response.json()
                    .then((info => {
                        this.availableStripePrices = info.data;
                        console.log(this.availableStripePrices);
                    }))
                    
                    }
                })
                .catch((error) => {console.warn(`Unable to get product information: ${error}`)});
        },
        convertStripeTimestamp(stripeTimeStamp){
            var date = (stripeTimeStamp * 1000) - Date.now(); // in ms
            return moment.duration(date).humanize()
        }
    },
    created() {
        this.subscription = AuthService.loggedUser$.subscribe(user => {
            this.currentUser = user;
            if (!this.currentUser || !this.currentUser.uid) {
                return
            }
            this.currentUser.isCurrentUser = true;
            this.$store.dispatch('stripe/checkStripeIntegrationStatus',this.currentUser.orgId)
            this.getOrgInfoFromId(this.currentUser.orgId);
            this.$store.dispatch('organisations/checkOrganisationStatus',this.currentUser.orgId);
        });

        console.log("Created manage subscription component");
        this.firebaseUser = auth.currentUser;
        console.info("Getting configuration info");
        earthbloxCollection.doc('registration').get()
            .then(config_doc => {
                this.stripePublishableAPIKey = config_doc.get('stripePublishableKey');
                if (!this.stripePublishableAPIKey){
                    console.warn("No stripe key found in DB, falling back to the test mode default key");
                    this.stripePublishableAPIKey = DEFAULT_STRIPE_PUBLISHABLE_KEY;
                }
            })
            .catch(error => {
                this.stripePublishableAPIKey = DEFAULT_STRIPE_PUBLISHABLE_KEY;
                console.warn(`Couldn't fetch the registration collection. Probably you are missing some config. Defaults will be used, and this is probably not what you want for production. ${error}`);
            })
            .finally( () => {
                this.fetchUsersSubscriptions();
                this.successURL = document.location //this.$route.fullPath;
                console.log(`Success URL ${this.successURL}`);
            })
    },
    computed: {
        userRef() {
            return this.firebaseUser.uid ? usersCollection.doc(this.firebaseUser.uid) : null;
        },
        isPasswordUser() {
            let isPasswordUser = false;
            if (this.firebaseUser && this.firebaseUser.providerData && this.firebaseUser.providerData.length > 0) {
                this.firebaseUser.providerData.forEach((provider) => {
                    if (provider.providerId === 'password') {
                        isPasswordUser = true;
                    }
                })
            }
            return isPasswordUser
        },
        orderedStripeSubscriptions() {
            return orderBy(this.stripeSubscriptions, 'start_date', 'desc')
        },
        isIndividualOrganisation(){
            return this.$store.state.organisations.isIndividual;
        },
        getUserRole() { 
            const roles = ['superadmin', 'admin']; 
            const userRoles = this.currentUser.roles.filter(item => roles.includes(item));
            return userRoles;
        }, 
        getUserType() { 
            const types = ['creator', 'explorer']; 
            const type = this.currentUser.roles.filter(item => types.includes(item))[0];
            const userType = type.charAt(0).toUpperCase() + type.slice(1)
            return userType;
        }
    },
    beforeUnmount() {
        this.subscription.unsubscribe();
    }
};
</script>