/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly'

Blockly.Msg["ANALYSIS_CONVERT_FEATURES_TITLE"] = "Convert features to image ";
Blockly.Msg["ANALYSIS_CONVERT_FEATURES_PIXEL_LABEL"] = "Set pixels as";
Blockly.Msg["ANALYSIS_CONVERT_FEATURES_OPTIONS_LABEL"] = "Image options";

export default Blockly.Msg;