<template>
<!-- this is a vue-material component for now, but with Denny's ongoing work to review modals, it might be changed to a custom ebx component -->
    <md-dialog-alert
        class="ebx-error-dialog"
        v-model:md-active="showDialog"
        :md-content="dialogMessage"
        md-confirm-text="OKAY" />
</template>

<script>
export default {
    props: {
        showErrorDialog: {
            type: Boolean,
            required: true,
            default: false
        },
        errorDialogMessage: {
            type: String,
            required: true,
            default: ""
        }
    },
    emits: [
        'error-dialog-active'
    ],
    computed: {
        dialogMessage: {
            get() {
                return this.errorDialogMessage;
            }
        },
        showDialog: {
            set(value) {
                this.$emit('error-dialog-active', value)
            },
            get() {
                return this.showErrorDialog;
            }
        }
    }
}
</script>