/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
/**
 * Planet map type is a specific map type that can formulate requests for basemap tiles from Planets Tiling service
 * it can be added as an overlay layer or as a basemap depending on the tileset used
 * Docs can be seen here https://developers.planet.com/docs/basemaps/tile-services/
 * Docs for MapTypes can be seen here https://developers.google.com/maps/documentation/javascript/maptypes#CustomMapTypes
 */
export class PlanetMapType {
    tileSize;
    maxZoom = 18;
    name = "NICFI";
    alt = "Planet NICFI Basemap";
    baseURL = "https://tiles{0-3}.planet.com/basemaps/v1/{item_type}/{item_id}/gmap/{z}/{x}/{y}.png?api_key={api-key}"
    requestURL;
    /**
     * 
     * @param {GoogleMapsSize Class} tileSize
     * @param {String} tileServer - tiling server to use between 0-3, if left blank Planet fills in
     * @param {String} itemType - type of Planet product
     * @param {String} itemID - ID of specific product
     * @param {String} apiKey - API key to be used for the request - soon to be replaced with reverse proxy
     */
    constructor(tileSize, tileServer = '', itemType, itemID, apiKey, map) {
        this.tileSize = tileSize;
        this.tileServer = tileServer;
        this.itemType = itemType
        this.itemID = itemID
        this.apiKey = apiKey
        this.map = map
        this.id = itemID
        this.constructUrl()
    }

    constructUrl = () => {
        this.requestURL = this.baseURL
            .replace("{0-3}", this.tileServer)
            .replace("{item_type}", this.itemType)
            .replace("{item_id}", this.itemID)
            .replace("{api-key}", this.apiKey)
    }

    /**
     * Using a coordinate set and zoom, we produce an image by replacing the base URL with the XYZ coordinates
     * and creating an ImageDom object, also set some error handling if the web requests fail or the endpoint is down
     * @param {Point} coord - contains x and y coord of the tile required to fill in
     * @param {Number} zoom - contains the zoom level of the tile required to fill in
     * @param {Document} ownerDocument (optional)
     * @returns { HTMLElement } - return an html tile element
     */
    getTile(coord, zoom) {

        let url = this.requestURL
            .replace("{x}", coord.x)
            .replace("{y}", coord.y)
            .replace("{z}", zoom);

        // testing ability to return asynchronously
        // function asyncLoadImage() {
        //     let img = new Image();
        //     img.src = function() {
        //         return new Promise(resolve => {
        //             let returnImage = () => {
        //                 console.log("returning url")
        //                 resolve(url)
        //             }
        //             setTimeout(returnImage, 1000)
        //         })
        //     }();
        //     return img;
        // }

        /**
         * creates the ImageDom element and adds an event listener for error handling and retries
         * @returns {HTMLElement} - returns an HTML Image Dom
         */
        function loadImage() {
            let img = new Image();
            img.src = url;
            const self = this;

            let retries = 0;
            let maxRetries = 5;
            img.addEventListener("error", function() {
                // check to stop infinite loop
                if(retries < maxRetries){
                    // if it errors it will zoom out and zoom back in to force a refresh of just the broken tiles
                    // all the other tiles are kept in cache
                    console.debug(
                        "tile failed to load, trying again using micro zoom"
                    );
                    // user cannot see as the zoom change is tiny
                    self.map.setZoom(
                        self.map.getZoom() + 0.000000000000001
                    );
                    //this will change the zoom again and load fresh tiles
                    self.map.setZoom(Math.round(self.map.getZoom()));
                    retries += 1
                }
            })

            return img;
        }

        let image = loadImage();
        return image;
    }
    /**
     * (Optional) Called whenever the API determines that the map needs to remove a tile as it falls out of view
     * @param {*} tile - the tile that is to be removed - can be used to remove event listeners etc.
     */
    // releaseTile(tile) { }
}