<template>
    <div>
        <component 
            :is="variableComponent"
            :varType="variable.type"
            :variable="variable"
            :is-new="isNew"
            :are-warnings="areWarnings"
            @save-variable="handleVariableSaved"
            @edit-variable="handleEditVariable"
            @cancel-editing="handleEditCancel"
            />
    </div>
</template>

<script>

import DateVariableExplorer from './DateVariableExplorer.vue'
import DateVariableCreator from './DateVariableCreator.vue'
import {VARIABLE_NEW_PREFIX} from '@/constants/nextGenConstants';
export default {
    name: 'DateVariable',
    components: {
        DateVariableExplorer,
        DateVariableCreator
    },
    props: {
        variable: {
            type: Object,
            required: false,
            default: () => {
                return {
                    type: 'date',
                    description: null,
                    id: VARIABLE_NEW_PREFIX,
                    title: undefined,
                    value: null,
                }
            },
        },
        isExplorer: {
            type: Boolean,
            required: false
        }, 
        areWarnings: { 
            type: Boolean, 
            required: false 
        },
        isNew: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    emits: [
        'save-variable',
        'edit-variable',
        'cancel-editing'
    ],
    computed: {
        variableComponent() {
            if (this.isNew) {
                return 'DateVariableCreator'
            } else {
                return this.variable.vue_component
            }
        }
    },
    methods: {
        handleVariableSaved(variable) {
            if(this.isNew === false) {
                variable.id = this.variable.id;
            }
            this.$emit('save-variable', variable, 'date');
        },
        handleEditVariable() {
            this.$emit('edit-variable');
            this.isEditingVariable = true;
        },
        handleEditCancel() {
            this.$emit('cancel-editing');
            this.isEditingVariable = false;
        }
    }
}
</script>