import {AbstractAbility} from "./abstractAbility"
import { DatasetCollection } from "./collections/DatasetCollection"
import { Definition } from '../definition'
import {ProvidesBands} from './providesBands'
import {ProvideTimePeriod} from "./providesTimePeriod"
import {ProvidesDatasetType} from "./providesDatasetType"
import {ProvideCompositeGroupTimePeriod} from "./providesCompositeGroupTimePeriod"
import {ProvidesAttributes} from "./providesAttributes"
import {ProvidesVariable} from "./providesVariable"
import { DatasetService } from '@/services/dataset.service';
class ProvidesDataset extends AbstractAbility{
    constructor(state) {
        super(state)
        this.stateKey = 'dataset'
    }
    isNewDataset() {
        return this.getDefinitionState().newDataset !== undefined
    }
    getState() {
        if (this.isNewDataset()) {
            return this.getDefinitionState().newDataset
        }
        return super.getState()
    }

    getDefinitions(onlySaved = false, ignoreIfSubscribed = null, useStateDefininitions = false) {
        const defs = useStateDefininitions ? this.getStateDefinitions() : super.getDefinitions()
        return defs
            .filter(ability => {
                if(onlySaved === false) {
                    return true
                }
                return ability.isSaved()
            })
            .filter(() => {
                if(ignoreIfSubscribed === null) {
                    return true
                }
                const subscriptions = this.workflowState.registry.getSubscriptions(this.getWorkflowStateId())
                return subscriptions.indexOf(ignoreIfSubscribed) < 0
            })
    }

    getDatasetAsOptions(onlySaved = false, ignoreIfSubscribed = null) {
        return this
            .getDefinitions(onlySaved, ignoreIfSubscribed, true)
            .map(definitionState => {
                const option = [definitionState.getNameForDisplay(), definitionState.getWorkflowStateIdWithDefinition()]
                this.resetCurrentDefinition()
                return option
            } )
    }

    createDefinition(state) {
        return new Definition(state, this.currentDefinitionKey, this.dataset_id || 'default')
    }

    getDatasetId() {
        return this.getState().dataset_id;
    }
    getBands() {
        return this.getState().bands
    }
    getVis() {
        return this.getState().vis
    }
    getVisBands() {
        return this.getState().visBands
    }
    getVisContrast() {
        return this.getState().visContrast
    }
    getIndices() {
        return this.getState().indices
    }
    getMinMax() {
        return this.getState().min_max
    }
    getGsd() {
        return this.getState().gsd
    }
    hasBandsUpdated() {
        return this.getState().bands_updated === true
    }
    getDatasetName() {
        return this.getState().dataset_name
    }
    getNameForDisplay() {
        return this.getUserDefinedName() || this.getDatasetName()
    }
    /**
     * Get the type of the dataset that is being used
     * @returns {string} 'raster' or 'vector'
     */
    getType() {
        const workflowState = this.workflowState;
        const defId = this.isNewDataset() ? this.getDefinitionState().savedBlockId : 'default';
		let abilities = workflowState.gatherAbilities(ProvidesDatasetType, false, false, defId);
        if(abilities.length === 0) {
            abilities = workflowState.gatherAbilitiesForDefinition(ProvidesDatasetType, this.getCurrentDefinitionKey(), false, true);
        }
        if(abilities.length === 0) {
            return this.getState().type
        }
		return abilities.last().getType()
    }
    /**
     * Helper method to determine if the dataset is a raster
     * @returns {boolean} true if the dataset is a raster
     */
    isRaster() {
        return this.getType() === 'raster'
    }
    /**
     * Helper method to determine if the dataset is a vector
     * @returns {boolean} true if the dataset is a vector
     */
    isVector() {
        return this.getType() === 'vector'
    }

    setAttributes(attributes, blockId) {
        if (attributes.length > 0) {
            this.updateState({ attributes, blockId })
        }
    }

    /**
     * Removes all bands from the dataset. This is useful when a image collection is converted to a feature collection
     * @param {*} blockId 
     * @returns {self}
     */
    removeAllBands(blockId) {
        this.setFilteredBands([], blockId)
        return this
    }

    /***
     * Sets the bands that are available for the dataset
     * The input bands are the bands you WANT to keep
     * @param {Array} bands
     * @param {string} blockId
     */
    setFilteredBands(bands, blockId) {
        if(Array.isArray(bands)) {
            const filteredBands = this.getState().filtered_bands ? this.getState().filtered_bands : []
            filteredBands.push({
                bands,
                blockId
            })
            this.updateState({ filtered_bands:filteredBands })
        }
        return this
    }

    /**
     * get the data that is provided to the global dataset service
     * @returns {Array} An array of band names
     */
    async getGlobalDatasetData(currentWorkflowStateId) {
        return {
			title: this.getNameForDisplay(),
			type: this.isRaster() ? "image_collection" : 'feature_collection',
            attributes: this.isVector() ? this.getAvailableAttributesForWorkflowState(currentWorkflowStateId) : undefined,
            origin: "workflow",
			id: this.getWorkflowStateIdWithDefinition(),
			bands: this.getAvailableBandsForWorkflowState(currentWorkflowStateId),
			...await this.getDatasetTimePeriod(currentWorkflowStateId),
            ...await this.getDatasetVariables()
		}
    }

    /**
     * Gets the available bands for the workflow state
     * @param {*} currentWorkflowStateId 
     * @returns {Array} An array of band names
     */
    getAvailableBandsForWorkflowState(currentWorkflowStateId) {
        if(this.isNewDataset()){
            return this.getState().bands
        }
		const workflowState = this.workflowState;
		const useCurrentBlock = currentWorkflowStateId === null ? true : workflowState.id !== currentWorkflowStateId
		// reverse order so the top bands are one the user has added
		const abilities = workflowState.gatherAbilitiesForDefinition(ProvidesBands, this.getCurrentDefinitionKey(), false, useCurrentBlock);
		
		return abilities
			.availableBands(false, this.getCurrentDefinitionKey())
			.filter(band => band['ebx:display'])
			.reverse();
	}

    /**
     * Gets the available attributes for the workflow state
     * @param {*} currentWorkflowStateId 
     * @returns {Array} An array of atributes
     */
    getAvailableAttributesForWorkflowState(currentWorkflowStateId) {
        if(this.isNewDataset()){
            return this.getState().attributes
        }
		const workflowState = this.workflowState;
		const useCurrentBlock = currentWorkflowStateId === null ? true : workflowState.id !== currentWorkflowStateId
		// reverse order so the top bands are one the user has added
		const abilities = workflowState.gatherAbilitiesForDefinition(ProvidesAttributes, this.getCurrentDefinitionKey(), false, useCurrentBlock);
		
		return abilities.allAttributes(true);
	}

    calculateCompositeGroupTimePeriod_(compositeAbility, timePeriods, isCurrentWorkflow) {

        if(compositeAbility instanceof ProvideCompositeGroupTimePeriod === false) {
            console.error("Supplied ability is not a composite group time period")
        }

        const cadence = compositeAbility.getCadence()
        const useCadence = ['range','none'].indexOf(cadence) >= 0 ? 'single_composite' : cadence
        const multi_image_temporal = compositeAbility.getTemporalTimePeriod(isCurrentWorkflow, timePeriods)
        const single_image_temporal = compositeAbility.getTemporalTimePeriod(isCurrentWorkflow, timePeriods, true)
        if (multi_image_temporal !== null) {
            return {
                multi_image_temporal,
                single_image_temporal,
                useCadence
            }
        }
        return null;
    }

    /**
     * Attempts to get the current time period for the dataset
     * @param {*} currentWorkflowStateId 
     * @returns {Object}
     */
    async getDatasetTimePeriod(currentWorkflowStateId) {
        const isCurrentWorkflow  = this.getWorkflowStateId() === currentWorkflowStateId

        // default value for the temporal object if no time period is found
        const defaultValue = {
            temporal: [],
            defaultUnit: 'range',
            timePeriod: {
                start_date: null,
                end_date: null
            }
        }

        const ignoreBlockPositioning = isCurrentWorkflow === false
        const includeSubscriptions = false
        const timeAbilities = this.workflowState.gatherAbilitiesForDefinitions(
            [ProvideTimePeriod, ProvideCompositeGroupTimePeriod], 
            this.getCurrentDefinitionKey(), 
            includeSubscriptions, // subscriptions dont matter here as the time period is added to the dataset when its in a save block
            ignoreBlockPositioning // if in current workflow we need to know what periods have been processed before the current block
        );

        if(timeAbilities.length === 0) {
            return {
                multi_image_temporal: defaultValue,
                single_image_temporal: defaultValue,
            }
        }

        let useCadence = 'range'

        const reversedTimeAbilities = timeAbilities
            .slice(0) // Clone The array so we dont reverse the original as it affects timePeriodsBeforeComposite & allTimePeriodsAsCollection
            .reverse() // Reverse the array so we can find the last composite block

        const compositeIndex = reversedTimeAbilities.findIndex(ability => ability instanceof ProvideCompositeGroupTimePeriod); // find the last composite block

        const timePeriodsBeforeComposite = ProvideTimePeriod.newCollection(timeAbilities.slice(0, timeAbilities.length - 1).filter(ability => ability instanceof ProvideTimePeriod))
        const allTimePeriodsAsCollection = ProvideTimePeriod.newCollection(timeAbilities.filter(ability => ability instanceof ProvideTimePeriod))

        const theLastAbility = timeAbilities[timeAbilities.length - 1]
        // last period is a composite. to save computation we can run the calculation on everything up to this block.
        if(theLastAbility instanceof ProvideCompositeGroupTimePeriod && timeAbilities.length > 1) {
            const computedTimes = this.calculateCompositeGroupTimePeriod_(theLastAbility, timePeriodsBeforeComposite, isCurrentWorkflow)
            if(computedTimes !== null) {
                useCadence = computedTimes.useCadence
                if(useCadence !== 'single_composite') {
                    return {
                        multi_image_temporal: computedTimes.multi_image_temporal,
                        single_image_temporal: computedTimes.single_image_temporal,
                    }
                }
            }
        }

        

        // check we have a composite block if so the last period is after this.
        // we need to re-compute the composite based on the last time period
        // this is kinda backawards where it takes the last previous composite, but computes based of a later time period
        if(compositeIndex >= 0 && useCadence !== 'single_composite') {
            // re-apply the composite block based on the last time period
            const compositeAbility = reversedTimeAbilities[compositeIndex]
            const computedTimes = this.calculateCompositeGroupTimePeriod_(compositeAbility, allTimePeriodsAsCollection, isCurrentWorkflow)
            if(computedTimes !== null) {
                useCadence = computedTimes.useCadence
                if(computedTimes.multi_image_temporal !== null) {
                    return {
                        multi_image_temporal: computedTimes.multi_image_temporal,
                        single_image_temporal: computedTimes.single_image_temporal,
                    }
                }
            }
        }


        // We dont appear to have a composite in this workflow. fallback to time period
        const defaultCadence = (await DatasetService.getDatasetDefaultCadenceAndInterval(this.getDatasetId())) || 'range'
        const theLastTimePeriod = allTimePeriodsAsCollection.last()
        const temporal = []
        const defaultUnit = defaultCadence.unit
        if (theLastTimePeriod !== null) {
            const temporals = {defaultUnit, ...theLastTimePeriod.getTemporalTimePeriod(useCadence, defaultCadence)}
            return {
                multi_image_temporal: temporals,
                single_image_temporal: temporals,
            }
        }
        const default_temporal = {
            temporal,
        }
        return {
            multi_image_temporal: default_temporal,
            single_image_temporal: default_temporal,
        }

    }

    /**
     * gets variables used on the dataset
     * @returns 
     */
    async getDatasetVariables() {
        const variables = this.workflowState.gatherAbilitiesForDefinition(ProvidesVariable, this.getCurrentDefinitionKey(), false, true);
        return {
            variables: variables.getVariables()
        }
    }

    static newCollection(abilites) {
        return new DatasetCollection(abilites)
    }

}

export {ProvidesDataset}