/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import Blockly from "blockly";

Blockly.Icon.prototype.SIZE = 24;

Blockly.Warning.prototype.drawIcon_ = function(group) {
    Blockly.utils.dom.createSvgElement(
        Blockly.utils.Svg.PATH,
        {
            'class': 'blocklyIconSymbol',
            'd': 'M 15,4.5 26,23.5 4,23.5z', 
            'transform': 'translate(-3 -9)'
        },
    group);
    
    Blockly.utils.dom.createSvgElement(
        Blockly.utils.Svg.PATH,
        {
            'class': 'blocklyIconShape warningIcon',
            'd': 'M5.83,22.33H24.17L15,6.5Zm10-2.5H14.17V18.17h1.66Zm0-3.33H14.17V13.17h1.66Z',
            'transform': 'translate(-3 -9)'
        },
    group);
}
