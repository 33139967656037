export class WMSMapType {
    name = "WMS Tiles";
    alt = "WMS Tiles";
    params = {
        service: "WMS",
        version: "1.1.1",
        request: "GetMap",
        format: "image/png",
        transparent: true,
    };

    /**
     * Constructor for WMSMapType
     * @param {GoogleMapsSize Class} tileSize
     * @param {String} url
     * @param {Number} minZoom
     * @param {Number} maxZoom
     * @param {Object} layers
     * @param {Object} styles
     * @param {Object} params
     */
    constructor(
        tileSize,
        url,
        minZoom,
        maxZoom,
        params,
        google,
        map
    ) {
        this.tileSize = tileSize;
        this.url = url;
        this.minZoom = minZoom;
        this.maxZoom = maxZoom;
        this.params = { ...this.params, ...params };
        this.google = google;
        this.map = map;
        this.id = this.name
    }

    getTile = (coord, zoom) => {
        const img = new Image();
        if (zoom < this.minZoom || zoom > this.maxZoom) {
            return img;
        }

        const { url } = this;
        const params = {
            ...this.params,
            BBOX: this.getTileBBox(coord, zoom),
            WIDTH: this.tileSize.width,
            HEIGHT: this.tileSize.height,
        };

        img.src = `${url}?${Object.entries(params)
            .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
            .join("&")}`;
        return img;
    };

    getTileBBox = (coord, zoom) => {
        const proj = this.map.getProjection();
        const zpow = Math.pow(2, zoom);
        const ul = proj.fromPointToLatLng(
            new this.google.maps.Point(coord.x * this.tileSize.width / zpow, (coord.y + 1) * this.tileSize.height / zpow)
        );
        const lr = proj.fromPointToLatLng(
            new this.google.maps.Point((coord.x + 1) * this.tileSize.width / zpow, coord.y * this.tileSize.height / zpow)
        );
        return `${ul.lng()},${ul.lat()},${lr.lng()},${lr.lat()}`;
    };
}
