<template>
    <div class="dataset-page" v-if="displayDataset">
        <div class="dataset-page--back-action ebx-action-text ebx-action-text--primary ebx-action-text__icon" @click="exitDatasetView">
            <span class="material-icons-outlined ebx-icon">chevron_left</span>
            <p>Back to Assets</p>
        </div>
        <EbxInlineInput 
            v-model="titleText" 
            :field-being-edited="fieldBeingEdited"
            :id="'dataset-title'"
            :input-type="`text`"
            :input-style-classes="['ebx-header-2']"
            :input-validation="titleValidation"
            :edit-actions-position="`right`"
            :disabled="!canEdit"
            @editing-ebx-field="updateFieldBeingEdited"
            @save="updateTitle">
        </EbxInlineInput>
        <EbxInlineInput 
            v-model="descriptionText" 
            :field-being-edited="fieldBeingEdited"
            :id="'dataset-summary'"
            :input-placeholder="descriptionPlaceholder" 
            :input-type="`text`"
            :input-style-classes="['ebx-primary']"
            :input-validation="descriptionValidation"
            :edit-actions-position="`right`"
            :disabled="!canEdit"
            @editing-ebx-field="updateFieldBeingEdited"
            @save="updateDescription">
        </EbxInlineInput>
        
        <md-tabs v-if="displayDataset" :md-active-tab="activeTab">
            <md-tab id="tab-project-dataset-overview" md-label="Overview">
                <Overview 
                    v-if="userDatasetsHasLoadedImages(datasetId) || isFeatureCollection" 
                    :dataset="displayDataset" 
                    :dataset-image="getFirstDatasetImage" 
                    :can-edit="canEdit"
                    :field-being-edited="fieldBeingEdited"
                    @editing-ebx-field="updateFieldBeingEdited"
                />
            </md-tab>
            <md-tab v-if="!isFeatureCollection" id="tab-project-dataset-bands" md-label="Bands" >
                <Bands 
                    :dataset="displayDataset" 
                    :field-being-edited="fieldBeingEdited"
                    :form-state="userDatasetsFormState('updateBand')"
                    :can-edit="canEdit"
                    @editing-ebx-field="updateFieldBeingEdited"
                    @update="updateBand"
                    @update-type="updateBandType"
                    @reset="userDatasetsResetFormState('updateBand')"
                />
            </md-tab>
            <md-tab id="tab-project-dataset-attributes" md-label="Attributes">
                <FeatureAttributes v-if="isFeatureCollection" :dataset="displayDataset"></FeatureAttributes>
                <Attributes 
                    v-else-if="userDatasetsHasLoadedImages(datasetId)" 
                    :dataset="displayDataset" 
                    :dataset-image="getFirstDatasetImage" 
                    :form-state="userDatasetsFormState('updateImageProperties')"
                    :can-edit="canEdit"
                    @update-properties="updateProperties"
                    @reset="userDatasetsResetFormState('updateImageProperties')"
                />
                <div v-else>
                    No image available
                </div>
            </md-tab>
        </md-tabs>
    </div>
</template>
<script>
import userDatasetsMixin from '@/components/mixins/userDatasetsMixin'
import Overview from '@/components/Dataset/Overview.vue'
import Attributes from '@/components/Dataset/Attributes.vue'
import FeatureAttributes from '@/components/Dataset/FeatureAttributes.vue'
import Bands from '@/components/Dataset/Bands.vue'
import authMixin from '@/components/mixins/authMixin';
import EbxInlineInput from '@/components/EbxComponents/EbxInlineInput.vue'
import {globalEventBus} from '@/eventbus.js'
import { isNotNullOrUndefined } from '@/helpers/generalHelperFunctions.js'


export default {
    components: {
        Overview,
        Attributes,
        Bands,
        EbxInlineInput,
        FeatureAttributes
    },
    mixins: [
        userDatasetsMixin,
        authMixin
    ],
    data() {
        return {
            activeTab:'tab-project-dataset-overview',
            descriptionText: '',
            descriptionPlaceholder: 'Add a short summary',
            descriptionValidation: {
                error: '',
                active: false
            },
            titleValidation: {
                error: '',
                active: false
            },
            titleText: '',
            fieldBeingEdited: '',
            currentPath: '',
        }
    },
    computed: {
        datasetId() {
            return this.$route.params.datasetId
        },
        getFirstDatasetImage() {
            return this.$store.getters['userdatasets/getFirstDatasetImage'](this.datasetId) || null
        },
        canEdit() {
            return this.userDatasetCanEdit(this.datasetId, this.user.uid)
        },
        displayDataset() {
            return this.$store.getters['userdatasets/getDisplayDataset'](this.datasetId);
        },
        isFeatureCollection() {
            return this.displayDataset && this.displayDataset.type === 'FeatureCollection';
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (isNotNullOrUndefined(to)) {
                    if (this.currentPath !== to.path) {
                        this.currentPath = to.path
                        // cancel or save field being edited
                        globalEventBus.$emit('page-exit', this.fieldBeingEdited)
                    }
                }
            },
        },
        descriptionText(newVal, oldVal) {
            // validate description
            const validation = this.validateDescription(newVal)
            if (validation === true) {
                this.descriptionValidation.error = ''
                this.descriptionValidation.active = false
                return newVal
            } else {
                this.descriptionValidation.error = validation
                this.descriptionValidation.active = true
                return oldVal
            }
        },
        titleText(newVal, oldVal) {
            // validate title
            const validation = this.validateTitle(newVal)
            if (validation === true) {
                this.titleValidation.error = ''
                this.titleValidation.active = false
                return newVal
            } else {
                this.titleValidation.error = validation
                this.titleValidation.active = true
                return oldVal
            }
        },
        displayDataset(newVal) {
            if (newVal) {
                this.descriptionText = newVal['ebx:short_description'] || ''
                this.titleText = newVal.title || ''
                return newVal
            }
        },
    },
    methods: {
        updateProperties(properties, datasetImageId) {
            this.$store.dispatch('userdatasets/updateImageProperties', {
                datasetId: this.datasetId,
                imageId: datasetImageId,
                properties
            })
        },
        updateBand(bandName, data) {
            this.$store.dispatch('userdatasets/updateDatasetBandByName', {
                datasetId: this.datasetId,
                bandName,
                data
            })
        },
        updateBandType(bandName, type) {
            const thematicPayload = {type, classes: {}}
            const numericPayload = { type }
            const payload = {
                datasetId: this.datasetId,
                bandName,
                data: type === 'thematic' ? thematicPayload : numericPayload,
                queryParams: {
                    calculateClassValues: type === 'thematic'
                }
            }
            this.$store.dispatch('userdatasets/updateDatasetBandByName', payload)
        },
        updateTitle() {
            this.$store.dispatch('userdatasets/updateDatasetProperties', {
                datasetId: this.datasetId,
                properties: { title: this.titleText}
            })
        },
        updateDescription() {
            this.$store.dispatch('userdatasets/updateDatasetProperties', {
                datasetId: this.datasetId,
                properties: { shortDescription: this.descriptionText}
            })
        },
        updateFieldBeingEdited(field) {
            this.fieldBeingEdited = field
        },
        exitDatasetView() {
            if(this.isFeatureCollection) {
                this.$router.push({ name: 'Projects-Asset-Features' })
            } else {
                this.$router.push({ name: 'Projects-Asset-Images' })
            }
            // cancel or save field being edited
            globalEventBus.$emit('page-exit', this.fieldBeingEdited)
        },
        // TODO: actual validation rules to apply
        validateDescription(text) {
            // validate description
            if (text.length > 0) {
                if (text.length > 100) {
                    return 'Description must be less than 100 characters'
                } else {
                    return true
                }
            }
        },
        // TODO: actual validation rules to apply
        validateTitle(text) {
            // validate title
            if (text.length > 0) {
                return true
            }
        }
    },
    async mounted () {
        this.$store.commit('userdatasets/resetSaveState')
        if (this.userDatasetExists(this.$route.params.datasetId) === false) {
            await Promise.all([
                this.$store.dispatch('userdatasets/queryOrgDatasetsMetadata', this.user.orgId),
                this.$store.dispatch('userdatasets/queryUserDatasetsMetadata', this.user.uid),
                this.$store.dispatch('userdatasets/queryUserDatasets',{userId: this.user.uid, orgId: this.user.orgId}),
            ])
        }
        
        if (this.isFeatureCollection === false && this.userDatasetsHasLoadedImages(this.datasetId) === false) {
            this.$store.dispatch('userdatasets/loadDatasetImages', this.datasetId)
        }
        
        this.descriptionText = this.displayDataset['ebx:short_description'] || ''
        this.titleText = this.displayDataset.title || ''
    },
}
</script>