import {AbstractAbility} from "./abstractAbility"
import { TimePeriodCollection } from "./collections/TimePeriodCollection"
import moment from 'moment'
class ProvideTimePeriod extends AbstractAbility{
    /**
     * ProvideTimePeriod constructor, for datasets which there is a known time period
     * @param {*} state 
     */
    constructor(state) {
        super(state)
        this.stateKey = 'time_period'
    }

    /**
     * Set the range of the dataset
     * @param {string} cadence - The dataset cadence (year, month, etc.)
     * @param {string} start - The start date
     * @param {string} end - The end date
     * @param {string} start_year - The start year only needed for 'month' cadence
     * @param {string} end_year - The end year only needed for 'month' cadence
     * @return {Object} - The dataset object
     */
    setRange(cadence, start, end, start_year=null, end_year=null) {
        let start_date;
        let end_date;

        if(start === null || end === null) {
            return this
        }

        switch(cadence) {
            case 'year':
                start_date = moment(start + '-01-01','YYYY-MM-DD')
                end_date = moment(end + '-12-31','YYYY-MM-DD')
                break;
            case 'dataset':    
            case 'custom':    
                start_date = moment(start,'YYYY-MM-DD')
                end_date = moment(end,'YYYY-MM-DD')
                break;
            case 'month':
                if (start_year !== null && end_year !== null) {
                    start_date = moment(start_year + '-' + start + '-01','YYYY-MM-DD')
                    end_date = moment(end_year + '-' + end + '-10','YYYY-MM-DD').endOf('month')
                } else {
                    let current_year = moment().year()
                    start_date = moment('1970-' + (start.length === 1 ? '0'+start : start) + '-01','YYYY-MM-DD')
                    end_date = moment(current_year + '-' + (end.length === 1 ? '0'+end : end) + '-10','YYYY-MM-DD').endOf('month')
                }
                break;
            case 'day_of_year':
                start_date = moment(start,'DDD')
                end_date = moment(end,'DDD')
                break;
            case 'day_of_month':
                start_date = moment(start,'DD')
                end_date = moment(end,'DD')
                break; 
        }
    

        this.updateState({
            cadence,
            start,
            end,
            start_date,
            end_date
        })

        return this;
    }

    setMultipleRanges(cadence, dates, def_start_date=null, def_end_date=null) { 
        let start_date;
        let end_date;

        if(dates.length === 0) {
            return this
        }

        let years_array = []
        let def_start_year = def_start_date ? def_start_date.year() : null
        let def_end_year = def_end_date ? def_end_date.year() : null
        // populate the years array by adding the years in between the start and end year
        for (let i = def_start_year; i <= def_end_year; i++) {
            years_array.push(i)
        }

        let date_ranges = []

        dates = dates.sort()

        if (cadence === 'year') {
            // check if years dates options are included in the allowed years_array
            dates.forEach(date => {
                date = parseInt(date)
                if (years_array.includes(date)) {
                    // if the date is the first option in years_array, set the start date to start_date
                    if (date === years_array[0]) {
                        start_date = def_start_date
                        // if first date is the only date in the array, set the end date to end_date
                        if (date === years_array[years_array.length-1]) {
                            end_date = def_end_date
                            // and add the date range to the date_ranges array
                            date_ranges.push([start_date, end_date])
                            return
                        }
                    }
        
                    // if the date is the last option in years_array, set the end date to end_date 
                    if (date === years_array[years_array.length-1]) {
                        end_date = def_end_date
                    } else {
                        /** construct the date ranges array to be
                         * date_ranges = [
                         *    [start_date, end_date],
                         *    [start_date, end_date],
                         *    [start_date, end_date],
                         * ...
                         * ]
                         * 
                         * */
                        date_ranges.push([moment(date + '-01-01', 'YYYY-MM-DD'), moment(date + '-12-31','YYYY-MM-DD')])
                    }
                }
            })

            if (!start_date) {
                // set start_date to be the first date in date_ranges
                start_date = date_ranges[0][0]
            }
            if (!end_date) {
                // set end_date to be the last date in date_ranges
                end_date = date_ranges[date_ranges.length-1][1]
            }
        }

        // let months_array = []
        if (cadence === 'month') {
            /** construct the date_ranges array to have 2 entries per month selected in dates per year in years_array
             * date_ranges = [
             *   [month_start_date, month_end_date],
             *   [month_start_date, month_end_date],
             *   ...
             * ]
            */
            let def_start_month = def_start_date ? def_start_date.month() + 1 : null
            let def_end_month = def_end_date ? def_end_date.month() + 1 : null
            let def_start_year = def_start_date ? def_start_date.year() : null
            let def_end_year = def_end_date ? def_end_date.year() : null

            const def_start_unix = def_start_date ? def_start_date.unix() : null
            const def_end_unix = def_end_date ? def_end_date.unix() : null

            const months = dates.map(date => parseInt(date))
            months.sort()

            years_array.forEach(year => {
                // loop through the months in the given dates selection
                months.forEach(month => {
                    let rangeStartDate = moment(year + '-' + (month < 10 ? '0'+month : month) + '-01','YYYY-MM-DD')
                    let rangeEndDate = moment(year + '-' + (month < 10 ? '0'+month : month) + '-01','YYYY-MM-DD').endOf('month')

                    // out of range
                    if(year < def_start_year || (month < def_start_month && year === def_start_year)) {
                        return
                    }
                    //out of range
                    if(year > def_end_year || (month > def_end_month && year === def_end_year)) {
                        return
                    }

                    if (rangeStartDate.unix() < def_start_unix) {
                        rangeStartDate = def_start_date
                    }
                    if (rangeEndDate.unix() > def_end_unix) {
                        rangeEndDate = def_end_date
                    }
                    date_ranges.push([rangeStartDate, rangeEndDate])
                })
            })
            if (!start_date) {
                // set start_date to be the first date in date_ranges
                start_date = date_ranges[0][0]
            }
            if (!end_date) {
                // set end_date to be the last date in date_ranges
                end_date = date_ranges[date_ranges.length-1][1]
            }
        }

        // sort the date ranges, (only by start date)
        date_ranges = date_ranges.sort((a, b) => a[0].unix() - b[0].unix())

        this.updateState({
            date_ranges, 
            start_date,
            end_date,
            cadence
        })

        return this; 
    }

    setAsFilter(isAFilter = true) {
        this.updateState({
            isAFilter: isAFilter === true
        })
        return this;
    }

    setDefaultDates(defaultDates) {
        const { start_date, end_date } = defaultDates || {}
        this.updateState({
            default_start_date: start_date,
            default_end_date: end_date
        })
        return this;
    }

    setCadenceAndInterval(defaultCadence) {
        this.updateState({
            defaultCadence
        })
        return this;
    }

    getCadenceAndInterval() {
        return this.getState()?.defaultCadence
    }

    getIsAFilter() {
        return (this.getState()?.isAFilter) === true
    }

    getDefaultDates() {
        return {
            start_date: this.getState()?.default_start_date,
            end_date: this.getState()?.default_end_date,
        }
    }


    /**
     * @return {Number} Time in milliseconds
     */
    getDuration() {
        return this.getState()?.duration;
    }

    /**
     * @return {Number} Time in milliseconds
     */
    getCadence() {
        return this.getState()?.cadence
    }
    
    /**
     * @return {Date} Start date
     */
    getStartDate() {
        return this.getState()?.start_date;
    }
    
    /**
     * @return {Date} End date
     */
    getEndDate() {
        return this.getState()?.end_date;
    }

    /**
     * Returns the raw start date
     * @return {String} Start date
     */
    getStart() {
        return this.getState()?.start;
    }

    /**
     * Returns the raw end date
     * @return {String} End date
     */
    getEnd() {
        return this.getState()?.end;
    }

    /**
     * get the start and end date of the dataset standardise for range support
     * @returns Array<Array<Date,Date>>
     */
    getDateRanges(asString = false) {
        const ranges = this.getState()?.date_ranges;
        if(ranges) {
            return ranges
        }
        if(asString) {
            return [[this.getStartDate().toISOString(), this.getEndDate().toISOString()]]
        }
        return [[this.getStartDate(), this.getEndDate()]]
    }


    /**
     * Gets the available dates for the dataset
     * @returns 
     */
    getAllAvailableDates() {
        let availableDates = []
        if(this.getState().calculatedDates) {
            return this.getState().calculatedDates
        }

        if (this.getCadence() === 'year' || this.getCadence() === 'month') {
                let dates = this.getDateRanges()
                dates.forEach(date_range => {
                    let startDate = date_range[0].clone()
                    while(startDate.unix() <= date_range[1].unix()) {
                        availableDates.push(startDate.clone())
                        startDate = startDate.add(1, 'days')
                    }
                })
                
        } else { 
            let currentDate = this.getStartDate().clone()
            while(currentDate.unix() <= this.getEndDate().unix()) {
                availableDates.push(currentDate.clone())
                currentDate = currentDate.add(1, 'days')
            }

        }
        // Push Dates onto the current state as its a reference we can add to the object
        this.getState().calculatedDates = availableDates;
        return availableDates;
    }

    getTemporalTimePeriod(useCadence, defaultCadence) {
        const temporal = []
        if (this.getStartDate() !== undefined && this.getEndDate() !== undefined) {
            if(useCadence === 'range' && defaultCadence && defaultCadence.unit === 'year') {
                useCadence = 'year'
                let iterDate = this.getStartDate().clone()
                const endDate = this.getEndDate()
                while(iterDate.unix() <= endDate.unix()) {
                    const iterYear = iterDate.format('YYYY')
                    temporal.push({
                        value: `${iterYear}`,
                        name: `${iterYear}`,
                        interval: {
                            start: iterYear + '-01-01',
                            end: iterYear + '-12-31'
                        }
                    })
                    iterDate.add(1, 'years')
                }

            } else {
                const startDate = this.getStartDate().format('YYYY-MM-DD');
                const endDate = this.getEndDate().format('YYYY-MM-DD');
                temporal.push({
                    value: `${startDate},${endDate}`,
                    name: `${startDate} to ${endDate}`,
                    interval: {
                        start: startDate,
                        end: endDate
                    }
                })
            }
        }
        return {
            temporal,
            cadence: useCadence,
            timePeriod: {
                start_date: this.getStartDate(),
                end_date: this.getEndDate()
            }
        }
    }

    static newCollection(abilites) {
        return new TimePeriodCollection(abilites)
    }
}

export {ProvideTimePeriod}